import React, { useMemo } from 'react';
import { Modal, TextInput } from 'carbon-components-react';
import { t } from '@baffle/translate';
import { TenantStore } from '@baffle/manager/src/stores';
import { Observer } from 'mobx-react-lite';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import { createTenantInputScheme } from './createTenantInputScheme';
import { TenantInfo } from '@baffle/graphql/src/models';

interface RenameTenantProps {
    open: boolean;
    onClose: () => void;
    onSuccess: (role: TenantInfo) => void;
}

const RenameTenant = ({ open, onClose, onSuccess }: RenameTenantProps) => {
    return (
        <Observer>
            {() => {
                const tenantRenameSchemes = useMemo(
                    () => createTenantInputScheme(TenantStore.tenantList as TenantInfo[], TenantStore.tenant?.id),
                    []
                );
                //@ts-ignore
                const { inputState, inputErrors, inputActions } = useInputState(tenantRenameSchemes);
                const isValid = inputActions.validateIndividualField({ field: 'name', skipNull: false });
                const renameTenant = () => {
                    // Take a shallow copy of the object, modify and send it to BE.
                    let updatedTenant: TenantInfo = { ...(TenantStore.tenant as TenantInfo) };
                    updatedTenant.name = inputState.name as string;
                    inputActions.reset();
                    onSuccess(updatedTenant);
                };
                return (
                    <Modal
                        id="tenant-rename-modal"
                        className="modal-container-white"
                        open={open}
                        modalHeading={t('tenants.renameHeading')}
                        onRequestClose={() => {
                            inputActions.reset();
                            onClose();
                        }}
                        primaryButtonText={t('main.rename')}
                        secondaryButtonText={t('main.cancel')}
                        size="sm"
                        onRequestSubmit={() => renameTenant()}
                        primaryButtonDisabled={!isValid}>
                        <TextInput
                            id="tenant-rename-input"
                            className="text-input-gray10"
                            name="tenant-rename-input"
                            data-testid="tenant-rename-input"
                            labelText={t('main.name')}
                            invalid={Boolean(inputErrors.name)}
                            invalidText={inputErrors.name}
                            value={(inputState.name as string) || ''}
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                inputActions.validateField({
                                    field: 'name',
                                    skipNull: true,
                                });
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                inputActions.setField('name', val);
                            }}
                        />
                    </Modal>
                );
            }}
        </Observer>
    );
};

export default RenameTenant;
