import Joi from '@hapi/joi';
import { t } from '@baffle/translate';
import { DatabasePayloadV2 } from '@baffle/graphql/src/models';

export function databaseInputScheme(databases: DatabasePayloadV2[], id: string | undefined) {
    const databaseInputSchema = {
        ns: 'database',
        keys: [
            'name',
            'environment',
            'type',
            'dbType',
            'version',
            'hostname',
            'port',
            'configDbName',
            'username',
            'password',
            'ssl',
        ],
        validationSchema: {
            name: Joi.string()
                .trim()
                .min(1)
                .max(30)
                .required()
                .pattern(/^[a-zA-Z0-9_-\s]+$/)
                .custom((val, helper) => {
                    if (
                        databases.some(
                            (p: DatabasePayloadV2) =>
                                p.id !== id && p.name.trim().toLowerCase() === val.trim().toLowerCase()
                        )
                    ) {
                        return helper.message({
                            custom: t('database.duplicateDbNameError'),
                        });
                    }
                })
                .messages({
                    'string.max': t('database.dbNameInvalidError'),
                    'string.empty': t('database.nameEmptyError'),
                    'string.pattern.base': t('database.dbNameInvalidError'),
                }),
            environment: Joi.string()
                .min(1)
                .required(),
            type: Joi.string()
                .min(1)
                .required(),
            version: Joi.string().allow('', null),
            hostname: Joi.string()
                .trim()
                .min(1)
                .required()
                .messages({
                    'string.empty': t('database.hostnameEmptyError'),
                }),
            port: Joi.number()
                .port()
                .required()
                .messages({
                    'number.port': t('database.portInputInvalid'),
                    'number.base': t('database.portInputInvalid'),
                }),
            configDbName: Joi.string().allow('', null),
            username: Joi.string()
                .trim()
                .min(1)
                .required()
                .messages({
                    'string.empty': t('database.usernameEmptyError'),
                }),
            password: Joi.string()
                .min(1)
                .required()
                .messages({
                    'string.empty': t('database.passwordEmptyError'),
                }),
            ssl: Joi.boolean().allow(null),
        },
    };
    return databaseInputSchema;
}

export function databaseRenameScheme(databases: DatabasePayloadV2[], id: string | undefined) {
    const databaseRenameSchema = {
        ns: 'database',
        keys: ['name'],
        validationSchema: {
            name: Joi.string()
                .trim()
                .min(1)
                .max(30)
                .required()
                .pattern(/^[a-zA-Z0-9_-\s]+$/)
                .custom((val, helper) => {
                    if (
                        databases.some(
                            (p: DatabasePayloadV2) =>
                                p.id !== id && p.name.trim().toLowerCase() === val.trim().toLowerCase()
                        )
                    ) {
                        return helper.message({
                            custom: t('database.duplicateDbNameError'),
                        });
                    }
                })
                .messages({
                    'string.max': t('database.dbNameInvalidError'),
                    'string.empty': t('database.nameEmptyError'),
                    'string.pattern.base': t('database.dbNameInvalidError'),
                }),
        },
    };
    return databaseRenameSchema;
}

export function databaseEditScheme() {
    const databaseEditSchema = {
        ns: 'database',
        keys: ['configDbName', 'username', 'password'],
        validationSchema: {
            configDbName: Joi.string()
                .trim()
                .allow('', null),
            username: Joi.string()
                .trim()
                .min(1)
                .required()
                .messages({
                    'string.empty': t('database.usernameEmptyError'),
                }),
            password: Joi.string()
                .min(1)
                .required()
                .messages({
                    'string.empty': t('database.passwordEmptyError'),
                }),
        },
    };
    return databaseEditSchema;
}
