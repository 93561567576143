import React, { useState /* useEffect */ } from 'react';
import { t } from '@baffle/translate';
import { Observer } from 'mobx-react-lite';
import cx from 'classnames';
import { JsonKeyDetail } from '@baffle/graphql/src/models';
import { Search } from 'carbon-components-react';

interface JsonFieldsViewProps {
    jsonFields: JsonKeyDetail[];
}

const JsonFieldsView = ({ jsonFields }: JsonFieldsViewProps) => {
    return (
        <Observer>
            {() => {
                const cellClass =
                    'h-full py-1 whitespace-nowrap text-xs border-b border-solid border-gray-300 flex items-center truncate';
                const initialResults: JsonKeyDetail[] = [];
                const [filtered, setFiltered] = useState({ results: initialResults, query: '' });
                const filterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                    const query = e.target.value || '';
                    const results = jsonFields.filter((field: JsonKeyDetail) =>
                        field.jsonKeyName.toLowerCase().includes(query.toLowerCase())
                    );
                    setFiltered({ results, query });
                };
                const filteredJsonFields = filtered.query !== '' ? filtered.results : jsonFields;
                const showScrollbar = filteredJsonFields.length > 10;
                return (
                    <div>
                        <Search
                            size="sm"
                            closeButtonLabelText={t('main.ariaCloseSearch')}
                            className="modelibrary-grid-filter"
                            labelText=""
                            placeHolderText={t('encryption.modeLibrary.jsonPolicy.ariaSearchPlaceholder')}
                            id="json-policy-search"
                            onChange={filterChange}
                            autoComplete="none"
                        />
                        <table className="w-116 mt-2">
                            <thead className={cx({ block: showScrollbar })}>
                                <tr className="font-bold text-left">
                                    <th>
                                        <div className={cx(cellClass, 'pl-4 py-2')}>
                                            <div className="w-36 flex-none">
                                                {t('encryption.modeLibrary.jsonPolicy.jsonFieldLabel')}
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div className={cx(cellClass, 'py-2 pl-2')}>
                                            <div className={cx('w-24 flex-none', { 'w-28': showScrollbar })}>
                                                {t('encryption.modeLibrary.jsonPolicy.datatypeLabel')}
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div className={cx(cellClass, 'py-2')}>
                                            <div className={cx('w-28 flex-none', { 'w-30': showScrollbar })}>
                                                {t('encryption.modeLibrary.jsonPolicy.encryptionModeLabel')}
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div className={cx(cellClass, 'py-2')}>
                                            <div className={cx('w-12 flex-none', { 'w-14': showScrollbar })}>
                                                {t('encryption.modeLibrary.jsonPolicy.keyIdLabel')}
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody
                                className={cx('border-l border-r border-solid border-gray-300', {
                                    'border-b h-83 overflow-y-auto block overflow-x-hidden': showScrollbar,
                                })}>
                                {filteredJsonFields.map((field, index) => {
                                    const finalClassName = cx(cellClass, {
                                        'border-none': showScrollbar && index === jsonFields.length - 1,
                                    });
                                    return (
                                        <tr key={index} className="text-left">
                                            <td>
                                                <div
                                                    className={cx(finalClassName, 'pl-4')}
                                                    data-testid={`jsonKeyName-${field.jsonKeyName}`}
                                                    title={field.jsonKeyName}>
                                                    <p className="w-36 flex-none truncate text-xs">
                                                        {field.jsonKeyName}
                                                    </p>
                                                </div>
                                            </td>
                                            <td>
                                                <div
                                                    className={cx(finalClassName, 'pl-2')}
                                                    data-testid={`datatype-${field.jsonKeyName}`}>
                                                    <p
                                                        className={cx('w-24 flex-none truncate text-xs', {
                                                            'w-28': showScrollbar,
                                                        })}>
                                                        {field.datatype}
                                                    </p>
                                                </div>
                                            </td>
                                            <td>
                                                <div
                                                    className={finalClassName}
                                                    data-testid={`encryptionMode-${field.jsonKeyName}`}>
                                                    <p
                                                        className={cx('w-28 flex-none truncate text-xs', {
                                                            'w-30': showScrollbar,
                                                        })}>
                                                        {field.encryptionMode.name}
                                                    </p>
                                                </div>
                                            </td>
                                            <td>
                                                <div
                                                    className={finalClassName}
                                                    data-testid={`keyId-${field.jsonKeyName}`}>
                                                    <p className="w-12 flex-none truncate text-xs">{field.keyId}</p>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                );
            }}
        </Observer>
    );
};

export default JsonFieldsView;
