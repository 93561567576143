import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError, ErrorResponse } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { RestLink } from 'apollo-link-rest';
import resolvers from './resolvers';
import typePatcher from './typePatcher';
import qs from 'qs';
import { TYPE_NAMES } from '../queries';
import storage from '@baffle/utilities/src/storage';
import { InitEnums } from '@baffle/utilities/src/enums';
import { baffleApi, getAuthToken } from './clientUtils';
import { setContext } from 'apollo-link-context';

/**
 * The initialization of RestLink could be done at the app level instead of at the package level...
 * which will maximize portability
 * for now, this works...
 */
const restLink = new RestLink({
    uri: baffleApi,
    endpoints: {
        v0: baffleApi + '/v1.0/',
        v1: baffleApi + '/api/v1.0/',
        v2: baffleApi + '/api/public/v1.0/',
        //adding a space is a hack that allows the default uri to be empty so that the provided path can be a full url
        // see https://github.com/apollographql/apollo-link-rest/issues/192
        empty: ' ',
    },
    typePatcher,
    responseTransformer: async response => {
        if (response && response.json) {
            return response
                .json()
                .then((json: object) => {
                    //Convert responses that are strings, bools, null, array of strings to an object
                    //for gql queries to properly operate

                    if (Array.isArray(json) && json.length > 0 && json.every(entry => typeof entry === 'string')) {
                        json = { data: json };
                    }

                    if (typeof json !== 'object' && !Array.isArray(json)) {
                        json = { data: json };
                    }
                    return json;
                })
                .catch((err: any) => {
                    // @ts-ignore
                    if (window.Cypress && err.message.includes('Unexpected end of JSON input')) {
                        return;
                        //This message will occur in FF for empty response bodies, which a lot of our endpoints have
                    } else if (err.message.includes('unexpected end of data at line 1 column 1 of the JSON data')) {
                        return;
                    } else {
                        throw err;
                    }
                });
        }
    },
});

// Fix for BM3-1205, otherwise all the apis through apollo rest client uses old JWT instead of fresh ones.
const authLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            Authorization: 'Bearer ' + getAuthToken(),
        },
    };
});

const cache = new InMemoryCache();
const client = new ApolloClient({
    link: ApolloLink.from([
        authLink,
        onError(({ graphQLErrors, networkError, response }: ErrorResponse) => {
            if (graphQLErrors)
                graphQLErrors.map(({ message, locations, path }) =>
                    console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
                );
            if (networkError) {
                console.log(`[Network error]: ${networkError}`);
            }
        }),
        restLink,
    ]),
    cache,
    resolvers,
});

const initialCache = {
    tenant: {
        name: storage.get(InitEnums.BAFFLE_TENANT) || null,
        __typename: TYPE_NAMES.Tenant,
    },
    adminUser: {
        email: storage.get(InitEnums.BAFFLE_ADMIN_USER) || null,
        __typename: TYPE_NAMES.AdminUser,
    },
};

cache.writeData({
    data: initialCache,
});

//clear the cache when the store is reset
//@ts-ignore
client.onResetStore(() => cache.writeData({ data: initialCache }));

/**
 *
 * formSerializerWithStringify allows you to submit form encoded data via apollo link rest mutation
 * that needs to be normalized as query string params
 * @param data
 * @param headers
 */
export const formSerializerWithStringify = (data: any, headers: Headers) => {
    headers.set('Content-Type', 'application/x-www-form-urlencoded');

    return { body: qs.stringify(data), headers };
};

/**
 *
 * formSerializerWithFormData allows you to submit form data via apollo link rest mutation
 * @param data
 * @param headers
 */
export const formSerializerWithFormData = (data: any, headers: Headers) => {
    const formData = new FormData();
    Object.keys(data).forEach(k => {
        const d = data[k];
        if (d instanceof File || d instanceof Blob) {
            formData.append(k, d);
        } else {
            formData.append(k, JSON.stringify(data[k]));
        }
    });

    return { body: formData, headers };
};

/**
 *
 * formSerializerWithFormDataForInitialCredentialStore... because it needs its own custom format
 * @param data
 * @param headers
 */
export const formSerializerForInitialCredentialStore = (data: any, headers: Headers) => {
    const formData = new FormData();
    Object.keys(data).forEach(k => {
        const d = data[k];
        if (d instanceof File || d instanceof Blob) {
            formData.append(k, d);

            //the backend does not like these types to be stringified...
        } else if (typeof d === 'string' || typeof d === 'number' || typeof d === 'boolean') {
            formData.append(k, data[k]);
        } else {
            formData.append(k, JSON.stringify(data[k]));
        }
    });

    return { body: formData, headers };
};

//this is not a done via a query because
//we need to be able to get the response headers
//to set the session storage
export const postLogin = (data: any) => {
    return fetch(`${baffleApi}/api/public/v2/auth`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const postIbmLogin = (data: any) => {
    return fetch(`${baffleApi}/api/public/v2/auth/ibm-token`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export default client;
