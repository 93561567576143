import { TYPE_NAMES } from '.';
import gql from 'graphql-tag';

export const GET_MIGRATION_STATUS = gql`
    query GetMigrations($id: String!) {
        migrations(id: $id) @rest(type: "${TYPE_NAMES.MigrationStatus}", path: "application/mgrstatus/{args.id}", endpoint: "v1") {
            status
            mgrTableList
        }
    }
`;

export const GET_MIGRATION_PLAN = gql`
    query GetMigrationPlan($id: String!) {
        migrationPlan(id: $id) @rest(type: "${TYPE_NAMES.MigrationPlan}", path: "application/migrationProperties/{args.id}", endpoint: "v1") {
            id
            failScope
            parallel
            batchSize
            batch
            cleanTmp
            migrationType
        }
    }
`;

export const ADD_MIGRATION_PLAN = gql`
    mutation AddMigrationPlan($id: String!,$input: Object!) {
        migrationPlan(id: $id, input: $input) @rest(type: "${TYPE_NAMES.MigrationPlan}", path: "application/migrationProperties?applicationId={args.id}", method: "PUT", endpoint: "v1") {
            id
        }
    }
`;
