import React, { useEffect } from 'react';
import { Observer } from 'mobx-react-lite';
import Content from '@baffle/components/src/content/Content';
import { Overview, OverviewCard } from '@baffle/components/src/overview/Overview';
import PageHeader from '@baffle/components/src/pageheader/pageheader';
import { UserGroupStore } from '@baffle/manager/src/stores';
import useAwait from '@baffle/api-client/src/useAwait';
import { useHistory, useParams } from 'react-router-dom';
import { t } from '@baffle/translate';
import { useManageModals } from '@baffle/components/src/modal/Modal';
import EditUserGroup from './EditUserGroup';
import DeleteUserGroup from './DeleteUserGroup';
import SpinnerOverlay from '@baffle/components/src/loader/SpinnerOverlay';
import { NotificationStore } from '../../../stores/NotificationStore';
import { Associations } from '@baffle/graphql/src/models';
import DataGrid from '@baffle/components/src/grid/data-grid';

const RbacUserGroupDetails = () => {
    const { id } = useParams() as { id: string };
    const history = useHistory();
    if (!id) {
        history.push('/rbac');
        return null;
    }

    const { status, run } = useAwait();

    useEffect(() => {
        run(Promise.all([UserGroupStore.getRbacUserGroup(id), UserGroupStore.getRbacUserGroups()]));
    }, []);

    const emptyCol = {
        id: '',
        header1: '',
        header2: '',
    };

    const headers = [
        {
            key: 'header2',
            header: t('globalPolicies.rbacUserGroups.resources'),
            sortable: true,
        },
        {
            key: 'header1',
            header: t('globalPolicies.rbacUserGroups.name'),
            sortable: false,
        },
    ];

    return (
        <Observer>
            {() => {
                const userGroup = UserGroupStore.rbacUserGroup;

                const rows =
                    status === 'loading' || status === 'idle'
                        ? //add  skeleton row on initial load
                          [...Array(Math.floor(1)).keys()].map(() => emptyCol)
                        : userGroup?.associations?.map((association: Associations, i: number) => {
                              return {
                                  selectedId: association?.id,
                                  id: association.id,
                                  header1: association.name,
                                  header2: association.type,
                                  link: '/rbac/rbac-policy/' + association.id,
                              };
                          });

                interface UserGroupModals {
                    editUserGroup: boolean;
                    deleteUserGroup: boolean;
                }

                const initialModalState = {
                    editUserGroup: false,
                    deleteUserGroup: false,
                };

                const [modals, setModals] = useManageModals<UserGroupModals>(initialModalState);

                const actions = [
                    {
                        id: '1',
                        label: t('globalPolicies.rbacUserGroups.editUserGroup'),
                    },
                    {
                        id: '2',
                        label: t('globalPolicies.rbacUserGroups.deleteUserGroup'),
                    },
                ];

                const breadcrumbData = [
                    {
                        label: t('globalPolicies.rbacLabel') + ' - ' + t('globalPolicies.rbacUserGroups.userGroups'),
                        href: '/rbac',
                    },
                ];

                const onSelectAction = (item: any) => {
                    switch (item.selectedItem.label) {
                        case t('globalPolicies.rbacUserGroups.editUserGroup'):
                            setModals({ editUserGroup: true });
                            break;
                        case t('globalPolicies.rbacUserGroups.deleteUserGroup'):
                            setModals({ deleteUserGroup: true });
                            break;
                        default:
                            break;
                    }
                };

                return (
                    <>
                        <Content>
                            {modals.editUserGroup ? (
                                <EditUserGroup
                                    open={modals.editUserGroup}
                                    onClose={() => setModals({ editUserGroup: false })}
                                    onSuccess={(name: string) => {
                                        NotificationStore.push({
                                            'data-testid': 'user-group-edit-success',
                                            kind: 'success',
                                            title: t('globalPolicies.rbacUserGroups.editUserGroupSuccess', {
                                                name: name,
                                            }),
                                        });
                                    }}
                                />
                            ) : null}

                            {modals.deleteUserGroup ? (
                                <DeleteUserGroup
                                    open={modals.deleteUserGroup}
                                    onClose={() => setModals({ deleteUserGroup: false })}
                                    onSuccess={(name: string) => {
                                        NotificationStore.push({
                                            kind: 'success',
                                            'data-testid': 'user-group-delete-success',
                                            title: t('globalPolicies.rbacUserGroups.deletedUserGroupName', {
                                                name: name,
                                            }),
                                        });
                                    }}
                                />
                            ) : null}

                            {(status === 'loading' || status === 'idle') && <SpinnerOverlay description="" />}
                            {userGroup && (
                                <>
                                    <PageHeader
                                        title={userGroup.name}
                                        actions={actions}
                                        breadcrumbData={breadcrumbData}
                                        onActionSelect={onSelectAction}
                                    />
                                    <div className="px-8 mt-4 text-lg">
                                        <div
                                            className={
                                                'pb-2 w-40 border-solid border-b-2 border-blue-60 cursor-pointer'
                                            }>
                                            <div className="ml-4">{t('globalPolicies.rbacUserGroups.overview')}</div>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="w-1/2 med:w-3/5">
                                            <Overview
                                                title={t('globalPolicies.rbacUserGroups.userGroupDetails')}
                                                info={UserGroupStore.getUserGroupOverview(userGroup)}
                                                topic="rbacUserGroup"
                                            />
                                        </div>
                                        <div className="w-1/2 med:w-2/5">
                                            <OverviewCard
                                                title={t('globalPolicies.attachedResources')}
                                                topic="userGroupAttachedResources">
                                                <DataGrid
                                                    addMargin={false}
                                                    idPrefix="userGroupAssociation"
                                                    rows={rows || []}
                                                    headers={headers}></DataGrid>
                                            </OverviewCard>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Content>
                    </>
                );
            }}
        </Observer>
    );
};

export default RbacUserGroupDetails;
