import { CreateModal, CreateModalBody, CreateModalHeader } from '@baffle/components/src/CreateModal/CreateModal';
import { TextInput, Select, SelectItem } from 'carbon-components-react';
import {
    DataProtectionItem,
    DatatypeGroup,
    RbacPolicyDefaultPermission,
    RbacPolicyRuleV2,
    RbacPolicyV2,
} from '@baffle/graphql/src/models';
import { t } from '@baffle/translate';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import React, { useEffect, useMemo, useState } from 'react';
import useAwait from '@baffle/api-client/src/useAwait';
import rbacPolicyClient from '@baffle/api-client/src/rbacPolicyClient';
import RbacPolicyInputScheme from './RbacPolicyInputScheme';
import { MaskingPolicyStore, RbacPolicyStore } from '@baffle/manager/src/stores';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';

interface EditRbacPolicyModalProps {
    open: boolean;
    id: string;
    onClose?: () => void;
    onSuccess?: (name: string) => void;
}

const EditRbacPolicyModal = ({ open, onClose, id, onSuccess }: EditRbacPolicyModalProps) => {
    const rbacPolicyInputSchemes = useMemo(() => RbacPolicyInputScheme(RbacPolicyStore.rbacPolicies, id), [
        RbacPolicyStore.rbacPolicies,
        id,
    ]);
    const [openModal, setOpen] = useState(open);
    const { run, reset, error } = useAwait();
    const selectRbacPolicy = RbacPolicyStore.selectedRbacPolicy;
    //@ts-ignore
    const { inputState, inputErrors, inputActions } = useInputState(rbacPolicyInputSchemes);
    const isValid = inputActions.validateAll(true);
    const permissionItems = Object.keys(RbacPolicyDefaultPermission).map(key => ({
        id: key,
        label: key,
        'data-testid': `policy-permission-li-${key}`,
    }));

    const maskModeItems = MaskingPolicyStore.maskingPolicies.map(mode => ({
        id: mode.id,
        label: mode.name,
        datatypeGroup: mode.datatypeGroup,
        'data-testid': `policy-mask-li-${mode.name}`,
    }));

    const datatypeGroupItems = Object.keys(DatatypeGroup).map(key => ({
        id: key,
        label: key,
        'data-testid': `datatype-li-${key}`,
    }));

    useEffect(() => {
        setOpen(open);
        run(
            MaskingPolicyStore.getMaskingPolicies().then(() => {
                inputActions.setFields({
                    name: selectRbacPolicy?.name,
                    defaultPermission: selectRbacPolicy?.defaultPermission,
                    rules: RbacPolicyStore.selectedRbacPolicyRules,
                    datatypeGroup: selectRbacPolicy?.datatypeGroup,
                    defaultMaskPolicy:
                        selectRbacPolicy?.defaultPermission === 'MASK'
                            ? ({
                                  id: selectRbacPolicy?.defaultMaskPolicy?.id,
                                  name: selectRbacPolicy?.defaultMaskPolicy?.name,
                              } as DataProtectionItem)
                            : null,
                });
            })
        );
    }, [open]);

    useEffect(() => {
        if (error) {
            NotificationStore.push({ title: error.message, kind: 'error' });
        }
    }, [error]);

    const handleClose = () => {
        if (!openModal) {
            return;
        }
        setOpen(false);
        reset();
        setTimeout(() => {
            if (onClose) {
                onClose();
            }
        }, 200);
    };

    const handleSave = () => {
        let payload: RbacPolicyV2 = rbacPolicyInputSchemes.keys.reduce((acc: any, c: any) => {
            //This creates a new object mapping the keys from the input scheme
            //to the key/values from the inputState. And send only those values which are present
            if (inputState[c]) {
                if (c === 'rules') {
                    acc['rules'] = (inputState.rules as Array<RbacPolicyRuleV2>).map(rule => ({
                        name: rule.name,
                        order: +rule.order,
                        userGroups: rule.userGroups,
                        permission: rule.permission,
                        maskPolicy: rule.maskPolicy,
                        id: rule.id,
                    }));
                } else if (c === 'defaultMaskPolicy') {
                    acc[c] = inputState.defaultMaskPolicy
                        ? {
                              id: (inputState.defaultMaskPolicy as DataProtectionItem)?.id,
                              name: (inputState.defaultMaskPolicy as DataProtectionItem)?.name,
                          }
                        : null;
                } else {
                    acc[c] = (inputState[c] as string).trim();
                }
            }
            return acc;
        }, {});
        run(
            rbacPolicyClient.updateRbacPolicy(id, payload).then(() => {
                handleClose();
                if (onSuccess) {
                    onSuccess(payload.name);
                }
            })
        );
    };

    return (
        <CreateModal
            open={openModal}
            modalId={'edit-rbac-policy'}
            isValid={isValid}
            onClose={handleClose}
            primaryButtonText={'Save'}
            primaryButtonTheme={'primary'}
            primaryButton={() => handleSave()}>
            <CreateModalHeader titleText={t('rbac.rbacPolicy.editRbacPolicyModalHeading')}></CreateModalHeader>
            <CreateModalBody>
                <div className="flex flex-wrap mb-6 items-end">
                    <div className="flex-1 name-input-container">
                        <TextInput
                            id="edit-rbac-policy-name-input"
                            data-testid="edit-rbac-policy-name-input"
                            value={(inputState.name as string) || ''}
                            labelText={t('encryption.modeLibrary.rbac.rbacPolicy.policyNameLabel')}
                            placeholder={t('encryption.modeLibrary.rbac.rbacPolicy.policyNamePlaceholder')}
                            invalid={Boolean(inputErrors.name)}
                            invalidText={inputErrors.name}
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                inputActions.validateField({
                                    field: 'name',
                                    skipNull: true,
                                });
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                inputActions.setField('name', val);
                            }}
                        />
                    </div>
                </div>
                <div className="flex flex-wrap mb-6 items-end">
                    <div className="flex-1 name-input-container">
                        <Select
                            id="rbac-policy-data-type-groups"
                            className="select-white select-menu-white"
                            labelText={t('globalPolicies.maskingPolicy.maskingPolicyDatatypeLabel')}
                            disabled
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                inputActions.setFields({
                                    datatypeGroup: val as string,
                                    defaultMaskPolicy: null,
                                });
                            }}
                            value={inputState.datatypeGroup || 'placeholder-item'}>
                            <SelectItem disabled hidden text={t('main.chooseOption')} value="placeholder-item" />
                            {datatypeGroupItems.map((datatype, i) => {
                                return <SelectItem key={i} text={datatype.label} value={datatype.id} />;
                            })}
                        </Select>
                    </div>
                </div>
                <div className="flex flex-wrap mb-6 items-end">
                    <div className="flex-1 name-input-container">
                        <Select
                            id="rbac-policy-permission"
                            labelText={t('encryption.modeLibrary.rbac.rbacPolicy.defaultPermission')}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                inputActions.setFields({
                                    defaultPermission: val as string,
                                    defaultMaskPolicy: null,
                                });
                            }}
                            value={inputState.defaultPermission || ''}>
                            <SelectItem disabled hidden text={t('main.chooseOption')} value="placeholder-item" />
                            {permissionItems.map((permissionItem, i) => {
                                return <SelectItem key={i} text={permissionItem.label} value={permissionItem.id} />;
                            })}
                        </Select>
                    </div>
                </div>
                {inputState.defaultPermission === RbacPolicyDefaultPermission.MASK ? (
                    <div className="flex flex-wrap mb-6 items-end">
                        <div className="flex-1 name-input-container">
                            <Select
                                id="rbac-policy-masking-mode"
                                labelText={t('rbac.rbacPolicy.maskPolicy')}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const val = e.target.value || '';
                                    const value = MaskingPolicyStore.maskingPolicies.find(
                                        mode => mode.id === val
                                    ) as DataProtectionItem;
                                    inputActions.setField('defaultMaskPolicy', value);
                                }}
                                value={(inputState.defaultMaskPolicy as DataProtectionItem)?.id || 'placeholder-item'}>
                                <SelectItem disabled hidden text={t('main.chooseOption')} value="placeholder-item" />
                                {maskModeItems
                                    .filter((maskMode: any) => {
                                        return inputState.datatypeGroup == maskMode.datatypeGroup;
                                    })
                                    .map((maskModeItem, i) => {
                                        return <SelectItem key={i} text={maskModeItem.label} value={maskModeItem.id} />;
                                    })}
                            </Select>
                        </div>
                    </div>
                ) : null}
            </CreateModalBody>
        </CreateModal>
    );
};

export default EditRbacPolicyModal;
