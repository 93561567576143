import client from '.';
import { ApplicationEncryptionPayload } from '@baffle/graphql/src/models';

export async function createSchema(payload: ApplicationEncryptionPayload) {
    return client(`api/v1.0/application/decryption`, { useForm: true, body: payload, method: 'POST' });
}

export default {
    createSchema,
};
