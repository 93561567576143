import React from 'react';
import { Dropdown } from 'carbon-components-react';
import TextInput from '@baffle/components/src/forms/TextInput';
import { t } from '@baffle/translate';
import { DEKStorageTypeAzureValues, DEKStorageTypeAzure } from '@baffle/graphql/src/models';

const AddAzureKeystore = ({ inputState, inputErrors, inputActions }: any) => {
    const dekItems = Object.values(DEKStorageTypeAzure).map((key: DEKStorageTypeAzure) => ({
        id: DEKStorageTypeAzureValues[key],
        label: DEKStorageTypeAzure[key],
    }));
    return (
        <>
            <div className="flex flex-wrap mb-6 items-start">
                <div className="flex-1 pr-4">
                    <TextInput
                        name="ks-keyVaultName-input"
                        data-testid="ks-keyVaultName-input"
                        labelText={t('keystore.keyVaultNameInputLabel')}
                        placeholder={t('keystore.keyVaultNamePlaceholder')}
                        type="text"
                        invalid={Boolean(inputErrors.keyVaultName)}
                        invalidText={t('keystore.keyVaultNameInputInvalid')}
                        defaultValue={inputState.keyVaultName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputActions.setField('keyVaultName', e.target.value.trim() || '')
                        }
                        onBlur={() => {
                            inputActions.validateField({ field: 'keyVaultName', skipNull: true });
                        }}
                    />
                </div>
                <div className="flex-1 pl-4">
                    <TextInput
                        name="ks-masterKeyId-input"
                        data-testid="ks-masterKeyId-input"
                        labelText={t('keystore.masterKeyIdInputLabel')}
                        placeholder={t('keystore.masterKeyIdPlaceholder')}
                        type="text"
                        invalid={Boolean(inputErrors.masterKeyId)}
                        invalidText={t('keystore.masterKeyIdInputInvalid')}
                        value={inputState.masterKeyId ?? ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const value = e.target.value.trim() || '';
                            inputActions.setField('masterKeyId', value);
                        }}
                        onBlur={() => {
                            inputActions.validateField({ field: 'masterKeyId', skipNull: true });
                        }}
                    />
                </div>
            </div>
            <div className="flex flex-wrap mb-6 items-start">
                <div className="flex-1 pr-4">
                    <TextInput
                        name="ks-clientId-input"
                        data-testid="ks-clientId-input"
                        labelText={t('keystore.clientIdInputLabel')}
                        invalidText={t('keystore.clientIdInputInvalid')}
                        placeholder={t('keystore.clientIdPlaceholder')}
                        type="text"
                        defaultValue={inputState.clientId}
                        invalid={Boolean(inputErrors.clientId)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputActions.setField('clientId', e.target.value.trim() || '')
                        }
                        onBlur={() => {
                            inputActions.validateField({ field: 'clientId', skipNull: true });
                        }}
                    />
                </div>
                <div className="flex-1 pl-4">
                    <TextInput
                        name="ks-azureTenantId-input"
                        data-testid="ks-azureTenantId-input"
                        labelText={t('keystore.azureTenantIdInputLabel')}
                        placeholder={t('keystore.azureTenantIdPlaceholder')}
                        type="text"
                        invalid={Boolean(inputErrors.azureTenantId)}
                        invalidText={t('keystore.azureTenantIdInputInvalid')}
                        value={inputState.azureTenantId ?? ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const value = e.target.value.trim() || '';
                            inputActions.setField('azureTenantId', value);
                        }}
                        onBlur={() => {
                            inputActions.validateField({ field: 'azureTenantId', skipNull: true });
                        }}
                    />
                </div>
            </div>

            <div className="flex flex-wrap mb-6 items-start">
                <div className="flex-1 pr-4">
                    <TextInput
                        name="ks-clientSecret-input"
                        data-testid="ks-clientSecret-input"
                        labelText={t('keystore.clientSecretInputLabel')}
                        invalidText={t('keystore.clientSecretInputInvalid')}
                        placeholder={t('keystore.clientSecretPlaceholder')}
                        type="password"
                        defaultValue={inputState.clientSecret}
                        invalid={Boolean(inputErrors.clientSecret)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputActions.setField('clientSecret', e.target.value.trim() || '')
                        }
                        onBlur={() => {
                            inputActions.validateField({ field: 'clientSecret', skipNull: true });
                        }}
                    />
                </div>
                <div className="flex-1 pl-4">
                    <Dropdown
                        id="ks-dekStore-input"
                        data-testid="ks-dekStore-input"
                        titleText={t('keystore.dekStoreInputLabel')}
                        label={t('main.chooseOption')}
                        initialSelectedItem={
                            inputState.dekStore ? dekItems.find(d => d.id === inputState.dekStore) : null
                        }
                        onChange={({ selectedItem }: { selectedItem: { id: string; label: string } }) => {
                            inputActions.setField('dekStore', selectedItem.id);
                        }}
                        items={dekItems}
                    />
                </div>
            </div>

            {inputState.dekStore === DEKStorageTypeAzureValues['Azure Blob'] ? (
                <div>
                    <div className="flex flex-wrap mb-6 items-start">
                        <div className="flex-1 pr-4">
                            <TextInput
                                name="ks-dekContainer-input"
                                data-testid="ks-dekContainer-input"
                                labelText={t('keystore.dekContainerInputLabel')}
                                placeholder={t('keystore.dekContainerPlaceholder')}
                                type="text"
                                invalid={Boolean(inputErrors.dekContainer)}
                                invalidText={t('keystore.dekContainerInputInvalid')}
                                value={inputState.dekContainer ?? ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    inputActions.setField('dekContainer', e.target.value.trim() || '')
                                }
                                onBlur={() => {
                                    inputActions.validateField({ field: 'dekContainer', skipNull: true });
                                }}
                            />
                        </div>
                        <div className="flex-1 pl-4">
                            <TextInput
                                name="ks-appNamespace-input"
                                data-testid="ks-appNamespace-input"
                                labelText={t('keystore.appNamespaceInputLabel')}
                                placeholder={t('keystore.appNamespacePlaceholder')}
                                type="text"
                                invalid={Boolean(inputErrors.appNamespace)}
                                invalidText={t('keystore.appNamespaceInputInvalid')}
                                value={inputState.appNamespace ?? ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    inputActions.setField('appNamespace', e.target.value.trim() || '')
                                }
                                onKeyUp={() => {
                                    inputActions.validateField({ field: 'appNamespace', skipNull: true });
                                }}
                                onBlur={() => {
                                    inputActions.validateField({ field: 'appNamespace', skipNull: true });
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap mb-6 items-start">
                        <div className="flex-1 pr-4">
                            <TextInput
                                name="ks-storageAccount-input"
                                data-testid="ks-storageAccount-input"
                                labelText={t('keystore.storageAccountInputLabel')}
                                placeholder={t('keystore.storageAccountPlaceholder')}
                                type="text"
                                invalid={Boolean(inputErrors.storageAccount)}
                                invalidText={t('keystore.storageAccountInputInvalid')}
                                value={inputState.storageAccount ?? ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    inputActions.setField('storageAccount', e.target.value.trim() || '')
                                }
                                onBlur={() => {
                                    inputActions.validateField({ field: 'storageAccount', skipNull: true });
                                }}
                            />
                        </div>
                        <div className="flex-1 pl-4">
                            <TextInput
                                name="ks-accountKey-input"
                                data-testid="ks-accountKey-input"
                                labelText={t('keystore.accountKeyInputLabel')}
                                placeholder={t('keystore.accountKeyPlaceholder')}
                                type="text"
                                invalid={Boolean(inputErrors.accountKey)}
                                invalidText={t('keystore.accountKeyInputInvalid')}
                                value={inputState.accountKey ?? ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    inputActions.setField('accountKey', e.target.value.trim() || '')
                                }
                                onBlur={() => {
                                    inputActions.validateField({ field: 'accountKey', skipNull: true });
                                }}
                            />
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default AddAzureKeystore;
