import { BaseCredentials } from './credentials';

export type MigrationTypes = 'MGR_ENC' | 'MGR_CLEAR' | 'None'; //mgr_enc for encryption and mgr_clear for decryption
export type CryptoActionType = 'encryption' | 'decryption';
export type EditActionType = 'database-3' | 'database-4' | 'schema' | 'table-3' | 'table-4';
export type TableType = 'VIEW' | 'TABLE';

export enum ModeBuilderType {
    MASKING = 'MASKING',
    ENCRYPTION = 'ENCRYPTION',
}

export enum RbacConfigSchema {
    SESSION = 'SESSION',
    SQL_COMMENT_RAW = 'SQL_COMMENT_RAW',
    SQL_COMMENT_JWT = 'SQL_COMMENT_JWT',
}

export enum RbacModesEnum {
    DISABLED = 'DISABLED',
    SUPPORTED = 'SUPPORTED',
    REQUIRED = 'REQUIRED',
}

export enum MaskingModes {
    FIXED = 'FIXED',
    PATTERN = 'PATTERN',
    NUMERIC = 'NUMERIC',
    CHARACTER = 'CHARACTER',
}

export const EncModes: { [mode: string]: string[] } = {
    AES_FPE: [
        'INT',
        'LATIN1',
        'WIN1252',
        'CC',
        'EMAIL1',
        'EMAIL2',
        'DECIMAL',
        'DATETIME',
        'SMALLDATETIME',
        'HEXADECIMAL',
        'ALPHANUM',
    ],
    AES_CTR: ['DET', 'RND'],
};

export type DataEncFormat = 'Mask' | 'FPE' | 'OFF' | 'OTHER'; //allows ui to distinguish what section this should be in

export enum DatabaseOwnerType {
    DATABASE = 'DATABASE',
    SCHEMA = 'SCHEMA',
    TABLE = 'TABLE',
}

//DEPRECATION WARNING: dataformats have been replaced by modes
export interface DataFormatItem {
    id: string;
    name: string;
    description?: string;
    dataType: string;
    dataFormat: string;
    appID?: string;
    dataEncFormat: DataEncFormat;
    existingFormat: boolean;
    default?: boolean;
    type?: string;
}

export interface ColumnMetaData {
    name: string;
    application: string;
    database: string;
    dbName: string;
    scName: string;
    table: string;
    dataType: string;
    touched: boolean;
    policy: string;
    encSupport: boolean;
    keyID: number;
    migration: MigrationTypes;
    defaultValue: string;
    primaryKey: boolean;
    nullable: boolean;
    disable: boolean; //the backend will determine if this columns is disables or not
    deploymentState: DeploymentState;
    decryptionDeploymentState?: DeploymentState;
    charset?: string;
    collation?: string;
    rbacPolicies: DataProtectionItem[];
    policies: DataProtectionItem[];
    encryptionModes: EncryptionMode[];
    maskingModes: MaskingMode[];
    selections: SelectedModes;
    datatypeGroup: string[];
    jsonPolicies: DataProtectionItem[];
    //compiled on client
    path: string; //is in database/table/col format
    __typename?: string;
    new?: boolean;
    tableType: TableType;
}

export interface SelectedColumn extends ColumnMetaData {}

export interface EncryptionDatabaseTable {
    name: string;
    touched: boolean;
    //compiled on client
    path: string; //is in database/table format
    application: string;
    new?: boolean;
}

export interface EncryptionDatabaseView extends EncryptionDatabaseTable {}

export interface EncryptionDatabase {
    name: string;
    dbName: string;
    touched: boolean;
    new?: boolean;
}

export interface EncryptionDatabaseSchema {
    name: string;
    touched: boolean;
    schemaName: string;
    //compiled on client
    new?: boolean;
}

export interface EncryptionDatabaseOptions {
    keystoreID: string;
    keystoreName: string;
    search: string;
    clusterID: string;
    enable: boolean;
}

export interface ApplicationBindingDTO {
    id: string;
    columns: ColumnMetaData[];
    mgrShieldId?: string;
}

export interface SchemaColumn {
    name: string;
    dataType: string;
    touched: boolean;
    policy: string;
    encSupport: boolean;
    keyID: number;
    migration: MigrationTypes;
    deploymentState: DeploymentState;
    defaultValue: string;
    primaryKey: boolean;
    nullable: boolean;
    disable: boolean; //the backend will determine if this columns is disables or not
    charset?: string;
    collation?: string;
    //compiled on client
    path?: string; //is in database/table/col format
    applicationID?: string;
}

export interface SchemaTable {
    name: string;
    touched: boolean;
    columns: SchemaColumn[];
    //compiled on client
    path?: string; //is in database/table format
    applicationID?: string;
}

export interface SchemaDatabase {
    tables: SchemaTable[];
    name: string;
    touched: boolean;
    //compiled on client
    applicationID?: string;
}
export interface EncryptionSchema {
    id: string;
    columns?: ColumnMetaData[];
    mgrShieldId?: string;
}

export interface EncryptionDatabaseServer {
    id: string;
    databases: EncryptionDatabase[];
    dbLabel: string;
    tableLabel: string;
    columnLabel: string;
    enable: boolean;
    options: EncryptionDatabaseOptions;
    chefNodeName: string;
    newKeys: any[];
}

export interface ApplicationEncryptionPayload {
    id: string;
    isDataSync?: boolean;
    deploymentState?: DeploymentState;
    applicationBindingDTO: ApplicationBindingDTO;
}

export interface ColumnsList {
    [key: string]: ColumnMetaData;
}

export interface CreateNewDatabaseDTO {
    appID: string;
    database: string;
}

export interface CreateNewSchemaDTO {
    appID: string;
    database: string;
    schema: string;
}

export interface CreateNewTableDTO {
    appID: string;
    database: string;
    table: string;
    columns?: string[];
    schema?: string;
}

export interface CreateNewDbOwner extends BaseCredentials {
    id: string;
    key: string;
    type: DatabaseOwnerType;
}

export type DeploymentState = 'save_draft' | 'save_deploy' | 'save_migrate' | 'none';

export type KeyLength = 'AES-256' | 'AES-128';
export const KeyLengthEnum = {
    AES_256: 'AES-256',
    AES_128: 'AES-128',
};

export interface DataProtectionItem {
    id: string;
    name: string;
    type?: string;
}

export interface ParialFpe {
    start: number;
    stop: number;
}

export interface DataProtectionResponse {
    policies: DataProtectionItem[];
    encryptionModes: DataProtectionItem[] | null;
    maskingModes: DataProtectionItem[] | null;
    rbacUserGroups: DataProtectionItem[] | null;
    rbacPolicies: DataProtectionItem[] | null;
    jsonPolicies: DataProtectionItem[] | null;
    enableJsonPolicies: boolean;
}

export interface MaskingMode {
    id: string;
    name: string;
    description?: string;
    mode: string;
    format: string;
    datatypeGroups: string[];
    default?: boolean;
}

export interface EncryptionMode {
    id: string;
    name: string;
    description?: string;
    mode: string;
    type: string;
    format: string;
    partialFpe?: ParialFpe;
    datatypeGroups: string[];
}

export interface SelectedModes {
    policy: DataProtectionItem | null;
    maskingMode: DataProtectionItem | null;
    encryptionMode: DataProtectionItem | null;
    rbacPolicy: DataProtectionItem | null;
    jsonPolicy: DataProtectionItem | null;
}

export type RbacModes = 'DISABLED' | 'REQUIRED' | 'SUPPORTED';
export type RbacUserDetermination = 'SESSION' | 'SQL_COMMENT_RAW' | 'SQL_COMMENT_JWT';
export enum RbacPolicyDefaultPermission {
    READ = 'READ',
    READ_WRITE = 'READ_WRITE',
    MASK = 'MASK',
}
export enum RbacPolicyAssociations {
    RBAC_POLICY = 'RBAC_POLICY',
    MASKING_POLICY = 'MASKING_POLICY',
}

export interface KeyValuePairsMap {
    [index: string]: string;
}

export interface RbacJwtProperties {
    tid?: string;
    aud?: string;
    secretKey?: string;
    providerUrl?: string;
    cacheCapacity?: number;
    keyValuePairs?: KeyValuePairsMap;
}
export interface RbacConfig {
    id?: string;
    appId: string;
    mode: RbacModes;
    userDetermination: RbacUserDetermination;
    sqlCommentFormat?: string;
    rbacJwtProperties?: RbacJwtProperties;
}

export interface RbacConfigV2 {
    mode: RbacModes;
    userDetermination: RbacUserDetermination;
    sqlCommentPrefix: string;
    jwtSecretKey: string;
    jwksProviderUrl: string;
    jwtClaims: KeyValuePairsMap;
    enabled?: boolean;
}

export interface RbacUsrGrp {
    id?: string;
    name: string;
    description?: string;
    appId: string;
    global?: boolean;
    users?: string[] | null;
    subnets?: string[];
}

export interface RbacUsrGrpV2 {
    id?: string;
    name: string;
    description?: string;
    appId: string;
    global?: boolean;
    users?: string[] | null;
    subnets?: string[];
    associations: Associations[];
    audit: Audit;
}

export interface Associations {
    type: string;
    id: string;
    name: string;
}

export interface JsonKeyDetail {
    jsonKeyName: string;
    datatype: string;
    encryptionMode: DataProtectionItem;
    keyId: number;
}
export interface JsonPolicy {
    id?: string;
    appId: string;
    name: string;
    description?: string;
    jsonKeysList: JsonKeyDetail[];
    associations?: DataProtectionItem[] | null;
}
export interface RbacPolicyRule {
    name: string;
    order: number;
    permission: string;
    maskMode: DataProtectionItem | null;
    userGroups: DataProtectionItem[];
}
export interface RbacPolicy {
    id?: string;
    appId: string;
    name: string;
    description?: string;
    defaultPermission: string;
    defaultMaskMode: DataProtectionItem | null;
    rules: RbacPolicyRule[];
}

export interface RbacPolicyRuleV2 {
    id: string;
    name: string;
    order: number;
    permission: string;
    maskPolicy: DataProtectionItem | null;
    userGroups: DataProtectionItem[];
    rbacPolicyId: string;
    associations: Associations[];
    audit: Audit;
}

export interface Audit {
    createdDate: number;
    createdBy: string;
    lastModifiedDate: number;
    lastModifiedBy: string;
}

export interface RbacRuleOrder {
    id: string;
    order: number;
}

export interface RbacPolicyV2 {
    id: string;
    name: string;
    defaultPermission: string;
    defaultMaskPolicy: DataProtectionItem | null;
    ruleRefs: DataProtectionItem[];
    audit: Audit;
    associations: Associations[];
    datatypeGroup: string;
}

export interface RbacPolicyV2Payload {
    name: string;
    defaultPermission: string;
    defaultMaskPolicy: DataProtectionItem | null;
    rules: RbacPolicyRuleV2[];
    datatypeGroup: string;
}

export interface EncryptionModeV2 {
    id: string;
    name: string;
    description: string;
    audit: Audit;
    associations: Associations[];
}
