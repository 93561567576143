import { ApplicationEnums } from '@baffle/utilities/src/enums';
import {
    ApplicationActionsEnum,
    ApplicationDetailsV2,
    ApplicationV2,
    MigrationStatus,
    Shield,
} from '@baffle/graphql/src/models';
import appShieldsState from './appShieldsState';

export const buildApplicationCanViewV2 = (application: ApplicationV2) => {
    const canView = {
        encryption: application.allowedActions.actions.some(a => a.action.includes(ApplicationActionsEnum.ENCRYPT)),
        migration: application.allowedActions.actions.some(a =>
            a.action.includes(ApplicationActionsEnum.MIGRATION_DETAILS)
        ),
        decryption: application.allowedActions.actions.some(a => a.action.includes(ApplicationActionsEnum.DECRYPT)),
    };
    return canView;
};

const buildApplicationCanView = (
    application: ApplicationDetailsV2 | undefined | null,
    shields: Shield[],
    migrations: MigrationStatus | undefined
) => {
    const allShieldHostsRunningOrEmpty = appShieldsState.areRunningV2(shields);

    const canView = {
        encryption:
            [ApplicationEnums.SUCCESS, ApplicationEnums.FAILED, ApplicationEnums.NA].includes(
                migrations?.status ?? ''
            ) && allShieldHostsRunningOrEmpty,
        migration: [
            ApplicationEnums.SUCCESS,
            ApplicationEnums.FAILED,
            ApplicationEnums.IN_PROGRESS,
            ApplicationEnums.INITIATED,
            ApplicationEnums.STARTED,
        ].includes(migrations?.status ?? ''),
        decryption:
            [ApplicationEnums.SUCCESS, ApplicationEnums.FAILED].includes(migrations?.status ?? '') &&
            allShieldHostsRunningOrEmpty,
    };
    if (application?.wlc) {
        canView.encryption = false;
        canView.migration = false;
        canView.decryption = false;
    }

    return canView;
};

export default buildApplicationCanView;
