import { makeObservable, observable, action, computed } from 'mobx';
import { Shield, ShieldListItem, ShieldResourceType } from '@baffle/graphql/src/models';
import createLookupTable from '@baffle/utilities/src/createLookupTable';
import shieldClient from '@baffle/api-client/src/shieldClient';
import { RequestOpts } from '../types/StoreTypes';
import { HTTP_POLL_INTERVAL } from '@baffle/utilities/src/enums/TimeEnum';
/**
 * ShieldStore is the beginning of ResourceStore, which manages application state and connections to the transport layer
 *
 * ShieldStore features:
 * - network Policy - allows ability to do cache combined with network operations
 * - network redundancy - will ensure we only fetch a given endpoint at one time, esp if many consumers try to request the same resource.
 * - polling redundancy - will ensure we only have one poller at a time for a given resource
 * - polling subcribers - will allow multiple consumers to 'subscribe' to a given resource and keep track of 'who' is subscribed
 * - state management - will manage state for the given resources (in this case, the various shield data models)
 */

interface ShieldOpts {}

interface ListShieldOpts extends ShieldOpts, RequestOpts {
    resource?: ShieldResourceType;
}
interface GetShieldOpts extends ShieldOpts, RequestOpts {
    resource: ShieldResourceType;
}

interface PollStats {
    subscribers: string[];
    poller: NodeJS.Timeout;
}

interface SubscribeConfig {
    resource: ShieldResourceType;
    id?: string;
    pollInterval?: number; //will override the default of 5 seconds until this one is unsubscribed
}

/**
 * shieldPollers is used to manage and keep track of consumers polling a given resource
 */
const shieldPollers = createLookupTable();
/**
 * shieldFetchers is used to lock/unlock ability to fire off a network request on a given resource
 * - it prevents consumers from making redundant requests for the same resource
 */
const shieldFetchers = createLookupTable();

class ShieldStore {
    constructor() {
        makeObservable(this, {
            anyLoading: computed,
            shieldListLoading: observable,
            shieldLoading: observable,
            shields: observable,
            setShields: action,
            shieldListItems: observable,
            setShieldListItems: action,
            setLoading: action,
        });
    }

    get anyLoading() {
        return this.shieldLoading || this.shieldListLoading;
    }
    //TODO: remove loading from all stores and use status from useAwait instead
    shieldListLoading: boolean = false;
    shieldLoading: boolean = false;
    setLoading(loading: boolean, key: ShieldResourceType) {
        switch (key) {
            case 'ShieldListItem':
                this.shieldListLoading = loading;
                break;
            case 'Shield':
                this.shieldLoading = loading;
                break;
            default:
                break;
        }
    }

    shieldListItems: ShieldListItem[] = [];
    setShieldListItems(shieldListItems: ShieldListItem[]) {
        //@ts-ignore
        this.shieldListItems.replace(shieldListItems);
    }
    shields: Shield[] = [];
    setShields(shield: Shield) {
        const shields = this.shields.slice();
        const index = shields.findIndex(s => s.id === shield.id);
        if (index > -1) {
            shields[index] = shield;
        } else {
            shields.push(shield);
        }
        //@ts-ignore
        this.shields.replace(shields);
    }

    get(shieldId: string) {
        return this.shields.find(shield => shield.id === shieldId);
    }
    getShieldsByApplicationId(appId: string) {
        return this.shields.filter(shield => shield?.applications?.includes(appId));
    }

    //lists all the shields
    async list(opts: ListShieldOpts = {}) {
        const { resource = 'ShieldListItem' } = opts;
        if (opts.networkPolicy === 'cache-first') {
            switch (resource) {
                case 'ShieldListItem':
                    if (this.shieldListItems.length) {
                        return;
                    }
                    break;
                default:
                    break;
            }
        }

        const key = `list_${resource}`;
        if (shieldFetchers.has(key)) {
            return;
        }
        shieldFetchers.set(key, true);
        this.setLoading(true, resource);
        const doFinally = () => {
            this.setLoading(false, resource);
            shieldFetchers.remove(key);
        };
        try {
            switch (resource) {
                case 'ShieldListItem':
                    const shieldListItems: ShieldListItem[] = await shieldClient.list();
                    this.setShieldListItems(shieldListItems);
                    break;

                default:
                    break;
            }
        } catch (error) {
            throw error;
        } finally {
            doFinally();
        }
    }
    //read a shield by id and resource type
    async read(shieldID: string, opts: GetShieldOpts = { resource: 'Shield' }) {
        //check cache first
        if (opts.networkPolicy === 'cache-first') {
            switch (opts.resource) {
                case 'ShieldListItem':
                    if (
                        opts.networkPolicy === 'cache-first' &&
                        this.shieldListItems.findIndex(s => s.id === shieldID) >= 0
                    ) {
                        return;
                    }
                case 'Shield':
                    if (opts.networkPolicy === 'cache-first' && this.shields.findIndex(s => s.id === shieldID) >= 0) {
                        return;
                    }
                default:
                    break;
            }
        }
        const fetchKey = `get_${shieldID}`;
        if (shieldFetchers.has(fetchKey)) {
            return;
        }
        shieldFetchers.set(fetchKey, true);
        this.setLoading(true, opts.resource);
        const doFinally = () => {
            shieldFetchers.remove(fetchKey);
            this.setLoading(false, opts.resource);
        };
        try {
            switch (opts.resource) {
                case 'ShieldListItem':
                    const shieldListItems: ShieldListItem[] = await shieldClient.list();
                    this.setShieldListItems(shieldListItems);
                    break;
                case 'Shield':
                    const shield = await shieldClient.read(shieldID, {});
                    this.setShields(shield as Shield);
                    break;

                default:
                    break;
            }
        } catch (error) {
            throw error;
        } finally {
            doFinally();
        }
    }

    async remove(shieldID: string) {
        //TODO: add optimistic update?
        try {
            await shieldClient.remove(shieldID);
        } catch (error) {
            console.log(error);
        }
    }

    //subscribe tells the shield store that we need to listen (poll in our case) to the given resources
    subscribe(configs: SubscribeConfig[], componentName: string) {
        type unsubscribeType = () => void;
        const unsubscribeFns: unsubscribeType[] = [];
        configs.forEach((config: SubscribeConfig) => {
            switch (config.resource) {
                case 'ShieldListItem':
                    //Add initial poller if lookup doesnt have any
                    if (!shieldPollers.has('ShieldListItem')) {
                        shieldPollers.set('ShieldListItem', {
                            subscribers: [componentName],
                            poller: setInterval(() => {
                                this.list();
                            }, HTTP_POLL_INTERVAL),
                        } as PollStats);
                    } else {
                        //Add component to existing subscribers
                        const pollStats = shieldPollers.get('ShieldListItem');
                        pollStats.subscribers.push(componentName);
                        shieldPollers.set('ShieldListItem', pollStats);
                    }
                    //Add unsubscribe functions to remove subscribers
                    unsubscribeFns.push(() => {
                        const pollStats = shieldPollers.get('ShieldListItem');
                        const subscriberIndex = pollStats.subscribers.findIndex((s: string) => s === componentName);
                        pollStats.subscribers.splice(subscriberIndex, 1);
                        //remove poller if no more subscribers
                        if (pollStats.subscribers.length === 0) {
                            clearInterval(pollStats.poller);
                            shieldPollers.remove('ShieldListItem');
                        } else {
                            shieldPollers.set('ShieldListItem', pollStats);
                        }
                    });
                    break;
                case 'Shield':
                    //Add initial poller if lookup doesnt have any
                    const shieldKey = `Shield_${config.id}`;
                    if (!shieldPollers.has(shieldKey)) {
                        shieldPollers.set(shieldKey, {
                            subscribers: [componentName],
                            poller: setInterval(() => {
                                this.read(config.id as string, { resource: 'Shield' });
                            }, HTTP_POLL_INTERVAL),
                        } as PollStats);
                    } else {
                        //Add component to existing subscribers
                        const pollStats = shieldPollers.get(shieldKey);
                        pollStats.subscribers.push(componentName);
                        shieldPollers.set(shieldKey, pollStats);
                    }
                    //Add unsubscribe functions to remove subscribers
                    unsubscribeFns.push(() => {
                        const pollStats = shieldPollers.get(shieldKey);
                        const subscriberIndex = pollStats.subscribers.findIndex((s: string) => s === componentName);
                        pollStats.subscribers.splice(subscriberIndex, 1);
                        //remove poller if no more subscribers
                        if (pollStats.subscribers.length === 0) {
                            clearInterval(pollStats.poller);
                            shieldPollers.remove(shieldKey);
                        } else {
                            shieldPollers.set(shieldKey, pollStats);
                        }
                    });
                    break;
                default:
                    break;
            }
        });
        const unsubscribe = () => {
            unsubscribeFns.forEach(fn => fn());
        };
        return unsubscribe;
    }
}

export default ShieldStore;
