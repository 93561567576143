import React, { useEffect, useState } from 'react';
import Header from '@baffle/components/src/nav/Header';
import Content from '@baffle/components/src/content/Content';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@baffle/components/src/buttons/Button';
import { useHistory, useParams } from 'react-router-dom';
import {
    MigrationPlan,
    EncryptionSchema,
    DeploymentState,
    MigrationStatus,
    ApplicationDetailsV2,
    ApplicationBindingDTO,
} from '@baffle/graphql/src/models';
import EncryptionConfirmationTable from '../encryption/EncryptionConfirmationTable';
import decryptionClient from '@baffle/api-client/src/decryptionClient';
import { t } from '@baffle/translate';
import '../encryption/EncryptionConfirmation.scss';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import { Observer } from 'mobx-react-lite';
import { EncryptionStore, ApplicationStore, MigrationStore, MigrationPlanStore, ShieldStore } from '../../stores';
import migrationPlanClient from '@baffle/api-client/src/migrationPlanClient';
import { ToastStore } from '../../stores/ToastStore';
import {
    compileColumnDTOPayload,
    compileEncryptionPayload,
} from '@baffle/utilities/src/migration/compileColumnDTOPayload';
import MigrationSettingsPanel from '../encryption/MigrationSettingsPanel';
import { autorun } from 'mobx';
import useAwait from '@baffle/api-client/src/useAwait';
import { useManageModals } from '../../../../components/src/modal/Modal';
import DecryptionConfirmationModal from './DecryptionConfirmationModal';
import { migrationPlanHasChanged } from '../../../../utilities/src/migration/migrationUtils';
import Joi from '@hapi/joi';
import SpinnerOverlay from '@baffle/components/src/loader/SpinnerOverlay';

const DecryptionConfirmation = () => {
    const { id } = useParams() as { id: string | undefined };
    const history = useHistory();

    const [deploymentPlan, setDeploymentPlan] = useState<DeploymentState>('save_draft');
    const [migrationShield, setMigrationShield] = useState('');
    const [saving, setSaving] = useState(false);

    if (!id) {
        return null;
    }

    const { status, run, error } = useAwait();

    interface DecryptionConfirmationModals {
        decryptionConfirmation: boolean;
    }
    const initialConfirmationModalState = {
        decryptionConfirmation: false,
    };
    const [modals, setModals] = useManageModals<DecryptionConfirmationModals>(initialConfirmationModalState);

    useEffect(() => {
        if (error) {
            ToastStore.push({ type: 'danger', title: t('main.unexpectedError') });
        }
    }, [error]);

    const { inputState, inputErrors, inputActions } = useInputState({
        keys: ['migrationType', 'migrationTargetDB', 'batch', 'batchSize', 'failScope', 'parallel', 'cleanTmp'],
        ns: 'decryption',
        defaultValues: {
            migrationType: 'table_to_table',
            batch: true,
            batchSize: 2000,
            failScope: 'SERVER',
            parallel: true,
            cleanTmp: false,
        },
        validationSchema: {
            batch: Joi.boolean().required(),
            batchSize: Joi.number()
                .min(1)
                .max(100000)
                .when('.batch', { is: true, otherwise: Joi.number() })
                .error(new Error(t('encryption.migration.batchSizeInvalid'))),
        },
    });

    return (
        <Observer>
            {() => {
                const [initialized, setInitialized] = useState(false);
                const loading = status === 'loading' || status === 'idle';

                const application = ApplicationStore.get(id) as ApplicationDetailsV2;

                const selectedDecryptionColumns = EncryptionStore.selectedDecryptionColumns.filter(
                    d => d.application === application.id
                );

                const applicationBindingDTO: ApplicationBindingDTO = {
                    //migration is set by selected column resolvers in resolvers.ts
                    columns: compileColumnDTOPayload(
                        'decryption',
                        EncryptionStore.schema as EncryptionSchema,
                        selectedDecryptionColumns,
                        deploymentPlan
                    ),
                    id,
                };

                useEffect(() => {
                    run(
                        Promise.all([
                            ApplicationStore.read(id as string),
                            EncryptionStore.getSchema(id as string),
                            MigrationStore.read(id),
                            MigrationPlanStore.read(id),
                            EncryptionStore.readDeployAndMigrate(applicationBindingDTO, 'decryption'),
                        ])
                    );
                    //This is how we can load other dependecies when application is loaded
                    return autorun(() => {
                        if (application?.shields?.length) {
                            for (let i = 0; i < application.shields.length; i++) {
                                const shieldId = application.shields[i];
                                ShieldStore.read(shieldId);
                            }
                        }
                    });
                }, []);

                const shields = ShieldStore.shields.filter(s => (application?.shields ?? []).includes(s.id));
                const migration = MigrationStore.get(id) as MigrationStatus;
                const migrationPlan = MigrationPlanStore.get(id) as MigrationPlan;
                const isEnrolled = Boolean(migration?.status === 'NA');

                if (!initialized && !loading) {
                    setInitialized(true);
                }

                const doHandleDecrypt = async () => {
                    setSaving(true);
                    try {
                        const decPayload = compileEncryptionPayload(
                            deploymentPlan,
                            EncryptionStore.schema as EncryptionSchema,
                            selectedDecryptionColumns,
                            id,
                            'decryption',
                            'createSchema',
                            migrationShield
                        );
                        // Update migration type if applicable
                        if (deploymentPlan === 'save_migrate') {
                            if (
                                migrationPlanHasChanged(migrationPlan, inputState) ||
                                migrationPlan.migrationType === 'server_to_server'
                            ) {
                                await migrationPlanClient.create(application.id, {
                                    migrationType: 'table_to_table',
                                    batch: inputState.batch as boolean,
                                    batchSize: inputState.batchSize as number,
                                    failScope: inputState.failScope as string,
                                    parallel: inputState.parallel as boolean,
                                    cleanTmp: inputState.cleanTmp as boolean,
                                });
                            }
                        }
                        await decryptionClient.createSchema(decPayload);
                        let toastTitle = t('decryption.decryptionSuccess');
                        switch (deploymentPlan) {
                            case 'save_deploy':
                                toastTitle = t('decryption.deployPolicySuccess');
                                break;
                            case 'save_draft':
                                toastTitle = t('decryption.savePolicySuccess');
                                break;

                            default:
                                break;
                        }
                        ToastStore.push({
                            type: 'success',
                            title: toastTitle,
                        });
                        EncryptionStore.resetSelected({ actionType: 'decryption' });
                        history.replace('/applications');
                    } catch (error) {
                        ToastStore.push({ type: 'danger', title: error?.message });
                        console.log(error);
                    } finally {
                        setSaving(false);
                    }
                };
                return (
                    <>
                        {saving && <SpinnerOverlay description={t('decryption.' + deploymentPlan)} />}
                        <Content>
                            <Header>
                                <div className="flex-1 px-4 flex justify-between items-center">
                                    <h1 className="text-lg">
                                        <span className="text-gray-100">{t('encryption.confirmationTitle')}:</span>{' '}
                                        {application?.appName}
                                    </h1>
                                </div>
                                <button
                                    onClick={() => {
                                        EncryptionStore.resetSelected({
                                            actionType: 'decryption',
                                        });
                                        history.replace('/applications');
                                    }}
                                    className="bx--btn bx--btn--icon-only-transparent mx-4"
                                    aria-label={t('encryption.ariaCloseEncryption')}>
                                    <CloseIcon />
                                </button>
                            </Header>
                            <div className="flex p-4">
                                <MigrationSettingsPanel
                                    inputErrors={inputErrors}
                                    deploymentPlan={deploymentPlan}
                                    setDeploymentPlan={setDeploymentPlan}
                                    migrationShield={migrationShield}
                                    setMigrationShield={setMigrationShield}
                                    isEnrolled={isEnrolled}
                                    loading={loading}
                                    inputActions={inputActions}
                                    inputState={inputState}
                                    application={application}
                                    shields={shields}
                                    migrationPlan={migrationPlan}
                                />
                                <div
                                    id="decryption-confirmation-grid"
                                    className="confirmation-datagrid-container flex-1">
                                    <div className="flex w-full bg-white border-solid border border-gray-300 py-2 px-3">
                                        <p className="font-thin">
                                            <span className="text-gray-100 font-bold">
                                                {selectedDecryptionColumns.length}
                                            </span>{' '}
                                            {t('decryption.columnsSelected')}
                                        </p>
                                    </div>
                                    <EncryptionConfirmationTable
                                        type="decryption"
                                        selectedColumns={selectedDecryptionColumns}
                                        loading={loading && !initialized}
                                    />
                                </div>
                            </div>
                            <div className="flex w-full justify-end items-center mt-4 bg-white border-t-2 border-solid border-gray-300 h-20">
                                <Button
                                    theme="tertiary"
                                    className="w-48 h-full"
                                    onClick={() => {
                                        history.push(`/application/decryption/${id}`);
                                    }}
                                    size="lg">
                                    {t('main.back')}
                                </Button>
                                <Button
                                    theme="secondary"
                                    className="w-48 h-full"
                                    onClick={() => {
                                        EncryptionStore.resetSelected({
                                            actionType: 'decryption',
                                        });
                                        history.replace('/applications');
                                    }}
                                    data-testid="decrypt-confirm-cancel"
                                    size="lg">
                                    {t('main.cancel')}
                                </Button>
                                <Button
                                    id="do-decrypt-btn"
                                    className="w-48 h-full"
                                    theme={selectedDecryptionColumns.length === 0 ? 'disabled' : 'primary'}
                                    onClick={() => {
                                        if (['save_deploy', 'save_migrate'].includes(deploymentPlan)) {
                                            setModals({ decryptionConfirmation: true });
                                        } else {
                                            doHandleDecrypt();
                                        }
                                    }}
                                    data-testid="decrypt-confirm-save"
                                    size="lg">
                                    {t('main.save')}
                                </Button>
                            </div>
                        </Content>
                        <div>
                            <DecryptionConfirmationModal
                                open={modals.decryptionConfirmation}
                                onClose={() => {
                                    setModals({ decryptionConfirmation: false });
                                }}
                                doHandleDecrypt={doHandleDecrypt}
                                deploymentPlan={deploymentPlan}
                            />
                        </div>
                    </>
                );
            }}
        </Observer>
    );
};

export default DecryptionConfirmation;
