import React, { useState, useEffect } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import cx from 'classnames';
import './Toast.scss';
import { t } from '@baffle/translate';
import { motion } from 'framer-motion';
import Button from '../buttons/Button';

export type ToastType = 'danger' | 'success';

export type ToastState = {
    show: boolean;
    title: string;
    type: ToastType;
};

export const useSetToast = (
    initialToastState: ToastState = {
        show: false,
        title: '',
        type: 'success',
    }
) => {
    const [toast, setToast] = useState(initialToastState);
    return { toast, setToast };
};

interface ToastProps {
    title: string;
    description?: string;
    duration?: number;
    onDismiss?: () => void;
    type: ToastType;
}

interface CssType {
    [key: string]: string;
}

interface IconType {
    [key: string]: React.Component | React.ReactFragment | React.ReactPortal | null | undefined;
}
const Toast = ({ title, description, type, duration = 3000, onDismiss = () => null }: ToastProps) => {
    const [dismiss, setDismiss] = useState(false);

    useEffect(() => {
        const doDismiss = setTimeout(() => {
            setDismiss(true);
            onDismiss();
        }, duration);
        return () => {
            clearTimeout(doDismiss);
        };
    });

    if (dismiss) {
        return null;
    }
    const cssTypes: CssType = {
        danger: 'danger',
        success: 'success',
    };
    const iconTypes: IconType = {
        danger: <InfoIcon fontSize="small" className="danger-icon" />,
        success: <CheckCircleIcon fontSize="small" className="success-icon" />,
    };

    return (
        <motion.div
            animate={{ top: 58 }}
            transition={{ duration: 0.3 }}
            className={cx('toast flex justify-left items-center', cssTypes[type])}>
            <div className="toast-icon-container">{iconTypes[type]}</div>
            <div className="toast-message-container">
                <p className="toast__title" dangerouslySetInnerHTML={{ __html: title }} />
                {description ? <p className="toast__description">{description}</p> : null}
            </div>
            <div className="toast__dismiss-container">
                <Button
                    onClick={() => {
                        setDismiss(true);
                        onDismiss();
                    }}
                    theme="icon-transparent"
                    tabIndex={0}
                    aria-label={t('database.ariaDismissToast')}
                    data-testid="dismiss-toast"
                    type="button">
                    <CloseIcon fontSize="small" />
                </Button>
            </div>
        </motion.div>
    );
};

export default Toast;
