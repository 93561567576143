import roleClient from '@baffle/api-client/src/roleClient';
import { RoleInfo, RoleEffects } from '@baffle/graphql/src/models';
import { observable, action, makeObservable } from 'mobx';
import { RequestOpts } from '../types/StoreTypes';
import { format } from '@baffle/utilities/src/date';

class RoleStore {
    constructor() {
        makeObservable(this, {
            roleList: observable,
            role: observable,
            setRoleList: action,
            setRole: action,
        });
    }
    roleList = observable<RoleInfo>([]);
    role: RoleInfo | null = null;

    effects: string[] = [
        RoleEffects.ENCRYPT,
        RoleEffects.DECRYPT,
        RoleEffects.FPE_ENCRYPT,
        RoleEffects.FPE_DECRYPT,
        RoleEffects.MASK,
    ];

    setRoleList(roleList: RoleInfo[]) {
        this.roleList.replace(roleList);
    }

    setRole(role: RoleInfo | null) {
        this.role = role;
    }

    async addRole(role: RoleInfo) {
        try {
            await roleClient.addRole(role);
            await this.getRoles();
        } catch (error) {
            throw error;
        }
    }

    async updateRole(role: RoleInfo) {
        try {
            await roleClient.updateRole(role);
            await this.getRoles();
        } catch (error) {
            throw error;
        }
    }

    async getRoles(opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first' && this.roleList.length > 0) {
            return;
        }
        try {
            const res = await roleClient.getRoles();
            this.setRoleList(res.data);
        } catch (error) {
            throw error;
        }
    }

    async getRole(id: string, opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first') {
            return;
        }
        try {
            const role = await roleClient.getRole(id);
            this.setRole(role);
        } catch (error) {
            throw error;
        }
    }

    async deleteRole(id: string) {
        try {
            await roleClient.deleteRole(id);
            await this.getRoles();
        } catch (error) {
            throw error;
        }
    }

    get(name: string) {
        return this.roleList.find(m => m.name === name);
    }
    getById(id: string) {
        return this.roleList.find(m => m.id === id);
    }

    getRoleOverview(role: RoleInfo) {
        return {
            id: role.id,
            name: role.name,
            effects: role.effects.join(', '),
            dataPolicies: role.dataPolicies,
            created: format(role.createdTime, 'MMM dd, yyyy H:mm:ss a'),
            createdBy: role.createdBy,
        };
    }
}

export default RoleStore;
