import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { MaskingMode, ModeBuilderType } from '@baffle/graphql/src/models';
import '../Encryption.scss';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import { t } from '@baffle/translate';
import Alert from '@baffle/components/src/alerts/Alert';
import TextInput from '@baffle/components/src/forms/TextInput';
import TextArea from '@baffle/components/src/forms/TextArea';
import Button from '@baffle/components/src/buttons/Button';
import DropdownSelect, { getDefaultToggleButtonProps } from '@baffle/components/src/dropdown/Dropdown';
import createModeInputScheme from './modeInputSchemes/createModeInputScheme';
import { DataFormatStore, EncryptionStore } from '@baffle/manager/src/stores';
import dataProtectionClient from '@baffle/api-client/src/dataProtectionClient';
import useAwait from '@baffle/api-client/src/useAwait';
import EditIcon from '@baffle/components/src/icons/EditIcon';
import { MaskingModes } from '@baffle/graphql/src/models';

interface AddMaskingModeProps {
    onClose?: () => void;
    onSuccess?: (name: string, action: string) => void;
    existingMode?: MaskingMode | null;
}

const AddMaskingMode = ({
    onClose = () => null,
    onSuccess = (name: string, action: string) => null,
    existingMode,
}: AddMaskingModeProps) => {
    let { id } = useParams() as { id: string };
    const modeInputSchemes = useMemo(() => createModeInputScheme(DataFormatStore.maskingModes, existingMode?.id), [
        DataFormatStore.maskingModes,
        existingMode,
    ]);
    const { run, error, reset } = useAwait();
    const [validationError, setValidationError] = useState(0);
    const [editMode, setEditMode] = useState(false);

    //@ts-ignore
    const { inputState, inputErrors, inputActions } = useInputState(modeInputSchemes[ModeBuilderType.MASKING]);
    const isValid = inputActions.validateAll(true);
    const modeItems = Object.keys(MaskingModes).map(key => ({
        id: key,
        label: key,
        'data-testid': `maskmode-li-${key}`,
    }));

    const handleSave = () => {
        const payload: MaskingMode = modeInputSchemes[ModeBuilderType.MASKING].keys.reduce((acc: any, c: any) => {
            //This creates a new object mapping the keys from the input scheme
            //to the key/values from the inputState
            acc[c] = (inputState[c] as string)?.trim();
            return acc;
        }, {});
        run(
            (existingMode
                ? dataProtectionClient.editMaskingMode(existingMode.id, payload)
                : dataProtectionClient.createMaskingMode(payload)
            ).then(() => {
                onSuccess(inputState.name as string, existingMode ? 'updated' : 'added');
                EncryptionStore.setSelectedTable('encryption', '');
                DataFormatStore.listAll(id as string);
                inputActions.reset();
            })
        );
    };

    const handleClose = () => {
        onClose();
        inputActions.reset();
        reset();
    };

    const handleEdit = () => {
        setEditMode(false);
    };

    //Sync state from props
    useEffect(() => {
        if (existingMode) {
            inputActions.reset();
            setEditMode(true);
            const { name, description, mode, format } = existingMode;
            inputActions.setFields({
                name,
                description,
                mode,
                format,
            });
        } else {
            setEditMode(false);
            inputActions.reset();
        }
    }, [existingMode]);

    //Check for input errors
    useEffect(() => {
        let validationErrorCount = 0;
        Object.keys(inputErrors).forEach(key => {
            if (inputErrors[key] != null) {
                validationErrorCount += 1;
            }
        });
        setValidationError(validationErrorCount);
    }, [inputErrors]);

    return (
        <div className="flex-grow bg-white">
            <div className="bg-gray-200 px-4 h-8 border-solid border-t-2 border-b-2 border-gray-300">
                <div className="relative flex items-center h-full uppercase w-112 font-bold text-xs">
                    {existingMode ? (
                        <div className="flex-1 flex justify-between items-center">
                            <span>
                                {t('encryption.modeLibrary.addMaskingMode')}: {existingMode.name}
                            </span>
                            <button aria-label={t('main.edit')} onClick={handleEdit}>
                                <EditIcon />
                            </button>
                        </div>
                    ) : (
                        <span>{t('encryption.modeLibrary.maskingModeBuilder')}</span>
                    )}
                </div>
            </div>
            <div className="w-116 mt-8 pl-4">
                {error ? (
                    <div className="flex flex-wrap">
                        <div className="flex-1">
                            <Alert
                                type="danger"
                                title={t('encryption.modeLibrary.newMaskModeAddError')}
                                description={error?.message || ''}
                            />
                        </div>
                    </div>
                ) : null}
                {validationError ? (
                    <div className="flex flex-wrap">
                        <div className="flex-1">
                            <Alert type="danger" title={t('keystore.inputError', { count: validationError })} />
                        </div>
                    </div>
                ) : null}
                <div className="flex flex-wrap mb-6 items-end">
                    <div className="flex-1 name-input-container">
                        <TextInput
                            id="maskmode-name-input"
                            name="maskmode-name-input"
                            data-testid="maskmode-name-input"
                            labelText={t('encryption.modeLibrary.maskModeName')}
                            placeholder={t('encryption.modeLibrary.maskModeNamePlaceholder')}
                            invalid={Boolean(inputErrors.name)}
                            invalidText={inputErrors.name}
                            value={(inputState.name as string) || ''}
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                inputActions.validateField({ field: 'name', skipNull: true });
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                inputActions.setField('name', val);
                            }}
                            disabled={editMode}
                        />
                        <span className="input-char-counter right-0">
                            {(inputState.name as string)?.length ?? 0} / 30
                        </span>
                    </div>
                </div>
                <div className="flex flex-wrap mb-6">
                    <div className="flex-1 relative">
                        <TextArea
                            id="maskmode-description-input"
                            name="maskmode-description-input"
                            data-testid="maskmode-description-input"
                            labelText={t('encryption.modeLibrary.descriptionInputLabel')}
                            invalidText={t('encryption.modeLibrary.descriptionInputInvalid')}
                            placeholder={t('encryption.modeLibrary.descriptionPlaceholder')}
                            value={(inputState.description as string) || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                if (val.length > 100) {
                                    e.target.value = val.slice(0, 100);
                                }
                                inputActions.setField('description', e.target.value);
                            }}
                            disabled={editMode}
                        />
                        <span className="input-char-counter right-0">
                            {(inputState.description as string)?.length ?? 0}/ 100
                        </span>
                    </div>
                </div>
                <div className="flex flex-wrap mb-6 items-start">
                    <div className="flex-1">
                        <DropdownSelect
                            id="maskmode-select-input"
                            placeholder={t('main.chooseOption')}
                            label={t('encryption.modeLibrary.maskMode')}
                            value={
                                inputState.mode
                                    ? modeItems.find(m => m.id === inputState.mode)
                                    : { id: 'chooseOption', label: t('main.chooseOption') }
                            }
                            handleSelectedItemChange={({ selectedItem }) => {
                                inputActions.setField('mode', selectedItem?.id as string);
                            }}
                            items={modeItems}
                            getToggleButtonProps={() => {
                                const { className: btnClasses, ...rest } = getDefaultToggleButtonProps();
                                return {
                                    disabled: Boolean(existingMode),
                                    className: `${btnClasses}`,
                                    ...rest,
                                };
                            }}
                        />
                    </div>
                </div>
                <div className="flex flex-wrap mb-6 items-end">
                    <div className="flex-1">
                        <TextInput
                            id="maskmode-format-input"
                            name="maskmode-format-input"
                            labelText={t('encryption.modeLibrary.maskModeFormat')}
                            placeholder={t('encryption.modeLibrary.maskModeFormatPlaceholder')}
                            invalid={Boolean(inputErrors.format)}
                            invalidText={inputErrors.format}
                            value={(inputState.format as string) || ''}
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                inputActions.validateField({ field: 'format', skipNull: true });
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                inputActions.setField('format', val);
                            }}
                            disabled={Boolean(existingMode)}
                        />
                    </div>
                </div>
                {editMode ? (
                    <div className="flex w-full justify-end h-8">
                        <Button
                            theme="primary"
                            className="py-2 w-24 uppercase font-semibold text-xs"
                            data-testid="edit-mask-mode-btn"
                            onClick={() => {
                                handleEdit();
                            }}>
                            {t('main.edit')}
                        </Button>
                    </div>
                ) : (
                    <div className="flex w-full justify-end h-8">
                        <Button
                            theme="secondary"
                            className="py-2 w-24 font-semibold text-xs uppercase"
                            data-testid="close-mask-mode-btn"
                            onClick={() => {
                                handleClose();
                            }}>
                            {t('main.cancel')}
                        </Button>
                        <Button
                            className="py-2 w-24 font-semibold ml-2 text-xs uppercase"
                            data-testid="add-mask-mode-btn"
                            theme={!isValid ? 'disabled' : 'primary'}
                            onClick={handleSave}>
                            {t('main.save')}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AddMaskingMode;
