import cx from 'classnames';
import React from 'react';
import './Content.scss';

interface ContentProps {
    className?: string;
    children: React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
    getContentProps?: () => any;
}

export const getDefaultContentProps: () => any = () => {
    return {
        className: 'flex-1 relative overflow-hidden focus:outline-none mt-12',
    };
};

const Content = ({
    className: customClassName,
    children,
    getContentProps = getDefaultContentProps,
    ...rest
}: ContentProps) => {
    const { className: defaultClassName, ...props } = getContentProps();

    const className = cx(defaultClassName, customClassName);

    return (
        <main tabIndex={0} {...rest} className={className} {...props}>
            {children}
        </main>
    );
};

export default Content;
