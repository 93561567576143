import React from 'react';
import CircleIcon from '@material-ui/icons/Brightness1';
import ProgressIcon from '@material-ui/icons/Autorenew';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import { BaseCompoundComponentProps } from '../types/react';

// type StatusType = 'success' | 'progress' | 'registered' | 'failure' | 'warning';

interface StatusProps extends BaseCompoundComponentProps {
    title?: string;
    type: string;
    'data-testid'?: string;
}

interface IconType {
    [key: string]: React.Component | React.ReactFragment | React.ReactPortal | null | undefined;
}
const Status = ({ title, type, children, 'data-testid': dataTestId }: StatusProps) => {
    const iconTypes: IconType = {
        success: <CheckCircleIcon className="w-4 h-4 mr-2 text-green-60" />,
        progress: <ProgressIcon className="w-4 h-4 mr-2 text-blue-70" />,
        idle: <CircleIcon className="w-4 h-4 mr-2 text-blue-70" />,
        danger: <ErrorIcon className="w-4 h-4 mr-2 text-red-60" />,
        warning: <WarningIcon className="" />,
    };

    return (
        <div className="flex justify-between items-center" data-testid={dataTestId ?? ''}>
            {iconTypes[type]}
            <div className="flex-1">{title ? <p className="text-sm">{title}</p> : null}</div>
            {children ? children : null}
        </div>
    );
};

export default Status;
