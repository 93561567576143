import React from 'react';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOffOutlined';
import { AgentError } from '@baffle/graphql/src/models';
import { t } from '@baffle/translate';
import { ToastStore } from '../stores/ToastStore';
import Button, { getDefaultButtonProps } from '@baffle/components/src/buttons/Button';
import agentClient from '@baffle/api-client/src/agentClient';
import { format } from '@baffle/utilities/src/date';

interface props {
    didClearNotification?: () => void;
    agentErrors?: AgentError[];
    idPrefix?: string;
}
const ShieldErrors = ({ agentErrors = [], idPrefix = '', didClearNotification = () => null }: props) => {
    return (
        <>
            {agentErrors.map((s: AgentError) => {
                return (
                    <div key={s.id} data-testid="shield-error" className="flex justify-between py-4 w-full">
                        <div className="flex flex-wrap min-w-0">
                            <div className="flex mb-2 w-full min-w-0">
                                <div className="text-gray-600 mr-2 w-20">{t('notifications.created')}: </div>
                                <div className="truncate flex-1 text-xs">
                                    {format(s.lastTimestamp, 'yyyy-M-d H:mm:ss')}
                                </div>
                            </div>

                            <div className="flex mb-2 w-full min-w-0">
                                <div className="text-gray-600 mr-2 w-20">{t('notifications.baffleShield')}: </div>
                                <div className="truncate flex-1 text-xs">{s.agentDetails.name}</div>
                            </div>

                            <div className="flex mb-2 w-full min-w-0">
                                <div className="text-gray-600 mr-2 w-20">{t('notifications.application')}: </div>
                                <div className="truncate flex-1 text-xs">{s.appDetails.name}</div>
                            </div>

                            <p className="text-red-60 text-xs leading-tight break-all">{s.errorMsg}</p>
                        </div>
                        <div className="font-light flex justify-end items-center h-full">
                            <Button
                                onClick={async () => {
                                    try {
                                        await agentClient.update({ errorId: s.id });
                                        didClearNotification();
                                    } catch (error) {
                                        ToastStore.push({
                                            title: t('shield.dismissFailure'),
                                            type: 'danger',
                                        });
                                    }
                                }}
                                getButtonProps={() => {
                                    const btnProps = {
                                        ...getDefaultButtonProps(),
                                        className:
                                            'flex items-center justify-center items-center border-solid border hover:text-red focus:text-red text-gray-400',
                                        'aria-label': t('notifications.dismissNotification'),
                                        title: t('application.addShield'),
                                        'data-testid': `${idPrefix}-dismiss-notifications-${s.id}`,
                                    };
                                    return btnProps;
                                }}
                                id={`${idPrefix}-dismiss-notifications-${s.id}`}
                                theme="icon-transparent"
                                size="none">
                                <NotificationsOffIcon className="w-4 h-4" />
                            </Button>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default ShieldErrors;
