import React from 'react';

import {
    Button,
    DataTable,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    TableContainer,
    TableToolbar,
    TableToolbarContent,
    TableToolbarSearch,
    TableExpandRow,
    TableExpandedRow,
    TableExpandHeader,
    Link,
    OverflowMenuItem,
    OverflowMenu,
    DataTableSkeleton,
} from 'carbon-components-react';
import SyncIcon from '@material-ui/icons/Sync';
import AddIcon from '@material-ui/icons/Add';
//@ts-ignore
import { Save, Edit, TrashCan } from '@carbon/icons-react';
import { t } from '@baffle/translate';
import './data-grid.scss';
import { DefaultConfig } from '@baffle/graphql/src/models';

/*
  Few things to take care when using this component:
  1. The first column header key should be named as 'header1' as this component looks for this to add link attribute. If the link is not needed then it is fine to have any name for the key.
  2. It looks for the 'Delete' in overflow menu options to add attribute 'isDelete' to overflow menu item. So always add the delete option as the last one in the array.
  3. If using row expansions - pass rowExpansionMethod prop with the required expanded section component and rowExpansionItems with the list of row info (example usage in CaCertificates.tsx file)
*/

interface props {
    headers: { key: string; header: string }[];
    rows: any;
    addButtonCaption?: string;
    saveButtonCaption?: string;
    showSearch?: boolean;
    onAdd?: () => void;
    onSave?: () => void;
    onRowEdit?: (id: string) => void;
    onRowDelete?: (id: string) => void;
    onSync?: () => void;
    onRowClick?: (idx: number, row: any) => void;
    idPrefix?: string;
    addMargin?: boolean;
    rowExpansionItems?: any[];
    rowExpansionMethod?: (details: any) => React.ReactElement<any, any>;
    children?: React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
}

function DataGrid({
    addButtonCaption,
    saveButtonCaption,
    showSearch,
    headers,
    rows,
    onAdd,
    onSave,
    onRowEdit,
    onRowDelete,
    onSync,
    onRowClick,
    idPrefix = 'data-table',
    addMargin = true,
    rowExpansionItems = [],
    rowExpansionMethod,
    children,
}: props) {
    const renderCellWithLink = (cell: any, row: any) => {
        if (cell.info.header === 'header1') {
            const curRow = rows.filter((r: any) => {
                return r.id === row.id;
            });

            if (curRow && curRow[0] && curRow[0].link) {
                return <Link href={curRow[0].link}>{cell.value}</Link>;
            }
        }
        return cell.value;
    };

    const renderOverFlowMenu = (row: any) => {
        const curRow = rows.filter((r: any) => {
            return r.id === row.id;
        });

        if (curRow && curRow[0] && curRow[0].overFlowMenu) {
            return curRow[0].overFlowMenu?.map((action: any) => {
                if (action.name === 'Delete') {
                    return (
                        <OverflowMenuItem
                            disabled={action.disabled == undefined ? false : action.disabled}
                            id={curRow[0].id}
                            data-testid={`${action.name}-${curRow[0].id}`}
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation();
                                action.onClick(curRow[0].id, curRow[0].header1);
                            }}
                            itemText={action.name}
                            key={action.name}
                            isDelete></OverflowMenuItem>
                    );
                } else {
                    return (
                        <OverflowMenuItem
                            id={curRow[0].id}
                            data-testid={`${action.name}-${curRow[0].id}`}
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation();
                                action.onClick(curRow[0].id, curRow[0].header1);
                            }}
                            itemText={action.name}
                            key={action.name}></OverflowMenuItem>
                    );
                }
            });
        } else {
            return null;
        }
    };

    const overFlowMenuExists = (id: string) => {
        const curRow = rows.filter((r: any) => {
            return r.id === id;
        });
        if (curRow && curRow[0] && curRow[0].overFlowMenu) {
            return true;
        } else {
            return false;
        }
    };
    return (
        <div className={`flex text-xsmall ${addMargin ? 'ml-10 mr-10 mb-10 mt-5' : ''}`}>
            <div id="data-table-container" className="flex-grow">
                {rows ? (
                    <DataTable
                        rows={rows}
                        headers={headers}
                        isSortable={true}
                        id={idPrefix}
                        overflowMenuOnHover={false}>
                        {({
                            rows,
                            headers,
                            getTableProps,
                            getHeaderProps,
                            getRowProps,
                            onInputChange,
                        }: {
                            rows: [];
                            headers: [];
                            getTableProps: any;
                            getHeaderProps: any;
                            getRowProps: any;
                            onInputChange: any;
                        }) => (
                            <TableContainer>
                                {(showSearch || addButtonCaption) && (
                                    <TableToolbar>
                                        <TableToolbarContent>
                                            {showSearch && (
                                                <TableToolbarSearch
                                                    defaultExpanded={true}
                                                    expanded={true}
                                                    placeholder={t('main.searchTable')}
                                                    className="grid-search"
                                                    tabIndex={0}
                                                    onChange={onInputChange}
                                                />
                                            )}
                                            {onSync && (
                                                <Button className="dg-sync" tabIndex={0} kind="ghost" onClick={onSync}>
                                                    <SyncIcon fontSize="small" />
                                                </Button>
                                            )}
                                            {saveButtonCaption && (
                                                <Button
                                                    id="save-button"
                                                    className="pr-5"
                                                    tabIndex={0}
                                                    onClick={onSave}
                                                    size="sm"
                                                    kind="tertiary">
                                                    {saveButtonCaption}
                                                    <Save size="20" className="pl-1" />
                                                </Button>
                                            )}
                                            {addButtonCaption && (
                                                <Button
                                                    id="add-button"
                                                    className="pr-5"
                                                    tabIndex={0}
                                                    onClick={onAdd}
                                                    size="sm"
                                                    kind="primary">
                                                    {addButtonCaption}
                                                    <AddIcon className="pl-1" />
                                                </Button>
                                            )}
                                        </TableToolbarContent>
                                    </TableToolbar>
                                )}
                                <Table {...getTableProps()}>
                                    <TableHead>
                                        <TableRow>
                                            {Boolean(rowExpansionItems?.length) ? (
                                                <TableExpandHeader id="expand" />
                                            ) : (
                                                <></>
                                            )}
                                            {headers.map((header: any) => (
                                                <TableHeader
                                                    {...getHeaderProps({ header })}
                                                    id={idPrefix + '-' + header.key}
                                                    isSortable={header.sortable == undefined ? true : header.sortable}>
                                                    {header.header}
                                                </TableHeader>
                                            ))}
                                            <TableHeader />
                                            {onRowDelete && <TableHeader />}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rowExpansionMethod && rowExpansionItems?.length
                                            ? rows.map((row: any, index: number) => (
                                                  <React.Fragment key={row.id}>
                                                      <TableExpandRow expandHeader="expand" {...getRowProps({ row })}>
                                                          {row.cells.map((cell: any, index: number) => (
                                                              <TableCell
                                                                  key={cell.id}
                                                                  data-testid={`${(headers[index] as any).header}-${
                                                                      row.id
                                                                  }`}>
                                                                  {renderCellWithLink(cell, row)}
                                                              </TableCell>
                                                          ))}
                                                          {overFlowMenuExists(row.id) && (
                                                              <TableCell className="cds--table-column-menu">
                                                                  <OverflowMenu
                                                                      size="sm"
                                                                      flipped
                                                                      ariaLabel="OverFlowMenu"
                                                                      data-testid={`overflowMenu-${row.id}`}>
                                                                      {renderOverFlowMenu(row)}
                                                                  </OverflowMenu>
                                                              </TableCell>
                                                          )}
                                                      </TableExpandRow>
                                                      <TableExpandedRow
                                                          colSpan={
                                                              overFlowMenuExists(row.id)
                                                                  ? headers.length + 2
                                                                  : headers.length + 1
                                                          }>
                                                          {rowExpansionMethod(rowExpansionItems[index])}
                                                      </TableExpandedRow>
                                                  </React.Fragment>
                                              ))
                                            : rows.map((row: any) => (
                                                  <TableRow
                                                      {...getRowProps({ row })}
                                                      onClick={(e: any) =>
                                                          onRowClick ? onRowClick(row.id, row) : null
                                                      }>
                                                      {row.cells.map((cell: any, index: number) => (
                                                          <TableCell
                                                              key={cell.id}
                                                              data-testid={`${(headers[index] as any).header}-${
                                                                  row.id
                                                              }`}>
                                                              {renderCellWithLink(cell, row)}
                                                          </TableCell>
                                                      ))}
                                                      {overFlowMenuExists(row.id) && (
                                                          <TableCell className="cds--table-column-menu">
                                                              <OverflowMenu
                                                                  size="sm"
                                                                  flipped
                                                                  ariaLabel="OverFlowMenu"
                                                                  data-testid={`overflowMenu-${row.id}`}>
                                                                  {renderOverFlowMenu(row)}
                                                              </OverflowMenu>
                                                          </TableCell>
                                                      )}
                                                      {onRowEdit && (
                                                          <TableCell className="cds--table-column-menu">
                                                              <Button
                                                                  data-testid={`edit-${row.id}`}
                                                                  className="border-none bg-transparent"
                                                                  tabIndex={0}
                                                                  onClick={() => onRowEdit(row.id)}
                                                                  size="sm"
                                                                  kind="ghost">
                                                                  <Edit />
                                                              </Button>
                                                          </TableCell>
                                                      )}
                                                      {onRowDelete && (
                                                          <TableCell className="cds--table-column-menu">
                                                              <Button
                                                                  data-testid={`delete-${row.id}`}
                                                                  className="border-none bg-transparent"
                                                                  disabled={Object.keys(DefaultConfig).includes(row.id)}
                                                                  tabIndex={0}
                                                                  onClick={() => onRowDelete(`delete-${row.id}`)}
                                                                  size="sm"
                                                                  kind="ghost">
                                                                  <TrashCan />
                                                              </Button>
                                                          </TableCell>
                                                      )}
                                                  </TableRow>
                                              ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </DataTable>
                ) : (
                    <DataTableSkeleton headers={headers} title="" description="" />
                )}
            </div>
            {children}
        </div>
    );
}

export default DataGrid;
