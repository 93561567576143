import React from 'react';
import { Observer } from 'mobx-react-lite';
import SystemConfigTable from './SystemConfigTable';
import ConfigureSMTP from './ConfigureSMTP';
import ConfigureLogger from './ConfigureLogger';
import TLSConfigTable from './TLSConfigTable';
import usePageHeight from '@baffle/utilities/src/usePageHeight';

const GlobalSettings = () => {
    return (
        <Observer>
            {() => {
                // to offset the header areas
                const pageHeight = usePageHeight() - 200;
                return (
                    <>
                        <div
                            className="flex bg-gray-10 flex-col px-10 mb-10 mt-5 overflow-y-auto"
                            style={{ height: pageHeight }}>
                            <SystemConfigTable />
                            <TLSConfigTable />
                            <ConfigureSMTP />
                            <ConfigureLogger />
                        </div>
                    </>
                );
            }}
        </Observer>
    );
};

export default GlobalSettings;
