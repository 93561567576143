import { ApplicationDetailsShield, Shield } from '@baffle/graphql/src/models';
import { SHIELD_STATES } from './enums';

const getAppShields = (app: any) => {
    const shields = app?.shields ? app.shields : app?.details?.shields ? app?.details?.shields : [];
    return shields;
};

export const areRunning = (app: any) => {
    const shields = getAppShields(app);
    return shields.every((s: ApplicationDetailsShield) => s?.shieldInfo?.state === SHIELD_STATES.RUNNING);
};

export const areRunningV2 = (shields: Shield[]) => {
    return shields.every((s: Shield) => s?.state === SHIELD_STATES.RUNNING); //every allows for empty arrays
};

export default {
    areRunning,
    areRunningV2,
};
