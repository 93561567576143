import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { BaffleIconProps } from '../componentTypes';

const css = `.st0{fill:none;}`;

const ArrowDownIcon = ({
    width = 32,
    height = 32,
    viewBox = '0 0 32 32',
    fontSize = 'small',
    className,
}: BaffleIconProps) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} fontSize={fontSize} className={className}>
            <style>{css}</style>
            <title>arrows--vertical</title>
            <polygon points="27.6,20.6 24,24.2 24,4 22,4 22,24.2 18.4,20.6 17,22 23,28 29,22 " />
            <polygon points="9,4 3,10 4.4,11.4 8,7.8 8,28 10,28 10,7.8 13.6,11.4 15,10 " />
            <rect id="_Transparent_Rectangle_" className="st0" width="32" height="32" />
        </SvgIcon>
    );
};

export default ArrowDownIcon;
