import React from 'react';
import cx from 'classnames';

interface CellProps {
    value: string | number | object | boolean;
    row: any;
}

//Cell is to be used with react data grid
const Cell = (props: CellProps) => {
    const { value, row } = props;
    const title = typeof value !== 'object' ? value.toString() : '';
    const additonalAttrs = row?.getAdditionalAttrs ? row?.getAdditionalAttrs(value) : {};
    return (
        <div
            className={cx('py-6 pl-2', {
                'bg-gray-20 border border-solid border-gray-20': row.selectedId && row.selectedId === row.id,
                disabled: row.disabled,
                italic: row.new,
            })}
            data-testid={row?.dataTestId ?? ''}
            title={title}
            {...additonalAttrs}>
            {value}
            {row.new && !title ? row.newOptions : !title && row.showAddOwnerModal ? row.addDbOwner : null}
        </div>
    );
};

export default Cell;
