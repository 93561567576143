import React from 'react';
import Divider from '@baffle/components/src/content/Divider';
import { t } from '@baffle/translate';
import { Checkbox, Dropdown } from 'carbon-components-react';
import SkeletonText from '@baffle/components/src/skeleton/SkeletonText';
import { InputActions, InputError, InputState } from '@baffle/utilities/src/inputHooks';
import { Application, Database, ApplicationDetailsV2 } from '@baffle/graphql/src/models';
import { useQuery } from '@apollo/react-hooks';
import { LIST_ALL_DATABASES, makeGql, TYPE_NAMES, MAX_PAGE_SIZE } from '@baffle/graphql';
import { FeatureFlagStore } from '../../stores';
import { Observer } from 'mobx-react-lite';
import TextInput from '@baffle/components/src/forms/TextInput';
import DropdownSelect, { getDefaultToggleButtonProps } from '@baffle/components/src/dropdown/Dropdown';

export const LIST_APPLICATIONS_FOR_DB = makeGql(`
    query ListApplications {
        applications @rest(type: "[${TYPE_NAMES.Application}]", path: "application?pagenum=1&pagesize=${MAX_PAGE_SIZE}", endpoint: "v1") {
            id @export(as: "id")
            details @rest(type: "${TYPE_NAMES.ApplicationDetails}", path: "application/{exportVariables.id}", endpoint: "v1") {
                db
            }
        }
    }
`);

interface MigrationPlanFormProps {
    isEnrolled: boolean;
    application: ApplicationDetailsV2;
    inputState: InputState;
    inputErrors: InputError;
    inputActions: InputActions;
}

const makeTargetDBDropdown = (
    canMake: boolean,
    application: ApplicationDetailsV2,
    applicationsData: any,
    databasesData: any
) => {
    if (!canMake) {
        return [];
    }

    const applications = applicationsData ? applicationsData.applications || [] : [];
    const databases: Database[] = databasesData.databases || [];
    const takenDBIds = applications.reduce((acc: any, c: Application) => {
        if (c.details.db && !acc[c.details.db]) {
            acc[c.details.db] = true;
        }
        return acc;
    }, {});

    const appDB = databases.find(db => db.id === application.db);
    const availableDatabases = databases.filter(
        (db: Database) => db.type === appDB?.type && !takenDBIds[db.id as string]
    );

    return availableDatabases.map((db: Database) => {
        return {
            val: db.id,
            label: db.dbName,
        };
    });
};

const MigrationPlanForm = ({
    inputState,
    inputErrors,
    inputActions,
    application,
    isEnrolled,
}: MigrationPlanFormProps) => {
    const migrationTypeItems = [
        { id: 'table_to_table', label: t('encryption.migration.sameDatabase'), disabled: false },
    ];
    // const canChooseServer = [DatabaseEnums.MYSQL, DatabaseEnums.SQL_SERVER, DatabaseEnums.AZURE_SQL].includes(
    //     //@ts-ignore
    //     application?.databaseDetails?.type
    // );
    const { data: databasesData, loading: databasesLoading } = useQuery(LIST_ALL_DATABASES, {
        skip: inputState.migrationType !== 'server_to_server',
    });
    const { data: applicationsData, loading: applicationsLoading } = useQuery(LIST_APPLICATIONS_FOR_DB, {
        skip: inputState.migrationType !== 'server_to_server',
    });

    const loading = databasesLoading || applicationsLoading;

    const targetDBItems = makeTargetDBDropdown(
        !loading && inputState.migrationType === 'server_to_server',
        application,
        applicationsData,
        databasesData
    );

    return (
        <div className="migration-plan flex-col w-64 pr-6">
            {inputState.migrationType === 'server_to_server' ? (
                loading ? (
                    <div className="mt-6">
                        <SkeletonText />
                    </div>
                ) : (
                    <div className="mt-6">
                        <p className="text-sm font-bold mb-2">
                            {t('encryption.migration.migrationTargetDBInputLabel')}
                        </p>
                        <Dropdown
                            id="migration-migrationTargetDB-input"
                            label={t('main.chooseOption')}
                            onChange={({ selectedItem }: { selectedItem: { val: string; label: string } }) => {
                                inputActions.setField('migrationTargetDB', selectedItem.val);
                            }}
                            items={targetDBItems}
                        />
                    </div>
                )
            ) : null}
            <Divider />
            <p className="text-sm font-bold mb-2">{t('encryption.migration.properties')}</p>
            <Checkbox
                id={'migration-batchEnabled-input'}
                labelText={t('encryption.migration.batchEnabledInputLabel')}
                className="text-xs mb-2"
                checked={inputState.batch}
                onChange={(event: any, { checked, id }: { checked: boolean; id: any }) => {
                    inputActions.setField('batch', checked);
                }}
            />
            {FeatureFlagStore.batchSize ? (
                <TextInput
                    id="migration-batchSize-input"
                    name="migration-batchSize-input"
                    className="mb-3"
                    labelHidden
                    labelText={t('encryption.migration.batchSizeInputLabel')}
                    disabled={!inputState.batch}
                    invalid={Boolean(inputErrors.batchSize)}
                    invalidText={inputErrors.batchSize}
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                        inputActions.validateField({
                            field: 'batchSize',
                            skipNull: true,
                        });
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const val = e.target.value || '';
                        inputActions.setField('batchSize', val);
                    }}
                    value={(inputState.batchSize as string) ?? 2000}
                    theme="secondary"
                />
            ) : (
                <DropdownSelect
                    id="migration-batchSize-input"
                    aria-label={t('encryption.migration.batchSizeInputLabel')}
                    label=""
                    placeholder={t('main.chooseOption')}
                    defaultSelectedItem={
                        inputState.batchSize
                            ? { id: inputState.batchSize as string, label: inputState.batchSize as string }
                            : { id: 'chooseOption', label: t('main.chooseOption') }
                    }
                    handleSelectedItemChange={({ selectedItem }) => {
                        inputActions.setField('batchSize', selectedItem?.id);
                    }}
                    items={[
                        { id: '2000', label: '2000' },
                        { id: '50000', label: '50000' },
                        { id: '100000', label: '100000' },
                    ]}
                    getToggleButtonProps={() => {
                        const { className: btnClasses, ...rest } = getDefaultToggleButtonProps();
                        return {
                            disabled: Boolean(!inputState.batch),
                            className: `${btnClasses}`,
                            ...rest,
                        };
                    }}
                    containerClasses="w-full mb-2"
                    theme="secondary"
                />
            )}
            <DropdownSelect
                id="migration-failScopeVal-input"
                aria-label={t('encryption.migration.failScopeInputLabel')}
                label={t('encryption.migration.failScopeInputLabel')}
                placeholder={t('main.chooseOption')}
                defaultSelectedItem={{ id: 'SERVER', label: t('encryption.migration.server') }}
                handleSelectedItemChange={({ selectedItem }) => {
                    inputActions.setField('failScope', selectedItem?.id as string);
                }}
                items={[
                    { id: 'SERVER', label: t('encryption.migration.server') },
                    { id: 'DATABASE', label: t('encryption.migration.database') },
                    { id: 'TABLE', label: t('encryption.migration.table') },
                ]}
                theme="secondary"
            />
            <Checkbox
                id={'migration-parallel-input'}
                labelText={t('encryption.migration.parallelInputLabel')}
                className="mt-3 text-xs"
                checked={inputState.parallel}
                onChange={(event: any, { checked, id }: { checked: boolean; id: any }) => {
                    inputActions.setField('parallel', checked);
                }}
            />
            <Checkbox
                id={'migration-cleanTmp-input'}
                labelText={t('encryption.migration.cleanTmpInputLabel')}
                className="mt-1 text-xs"
                checked={inputState.cleanTmp}
                onChange={(event: any, { checked, id }: { checked: boolean; id: any }) => {
                    inputActions.setField('cleanTmp', checked);
                }}
            />
            <Divider />
            <Observer>
                {() => {
                    //Keeping this seperate in case we need to conditionally add it in the near future
                    if (isEnrolled) {
                        migrationTypeItems.push({
                            id: 'server_to_server',
                            label: t('encryption.migration.sourceToTarget'),
                            disabled: !FeatureFlagStore.stsMigration,
                        });
                    }
                    return (
                        <DropdownSelect
                            id="migration-migrationType-input"
                            placeholder={t('main.chooseOption')}
                            defaultSelectedItem={
                                inputState.migrationType
                                    ? migrationTypeItems.find(mt => mt.id === inputState.migrationType)
                                    : migrationTypeItems[0]
                            }
                            handleSelectedItemChange={({ selectedItem }) => {
                                inputActions.setField('migrationType', selectedItem?.id as string);
                            }}
                            items={migrationTypeItems}
                            label={t('encryption.migration.migrationTypeInputLabel')}
                            theme="secondary"
                        />
                    );
                }}
            </Observer>
        </div>
    );
};

export default MigrationPlanForm;
