import Joi from '@hapi/joi';
import { t } from '@baffle/translate';
import { DataProtectionItem } from '@baffle/graphql/src/models';

export default function RbacRuleInputScheme(rules: DataProtectionItem[], id: string | undefined) {
    const rbacRuleInputSchema = {
        ns: 'rbacRules',
        keys: ['name', 'order', 'userGroups', 'permission', 'maskPolicy'],
        defaultValues: {
            userGroups: [],
            order: 1,
        },
        validationSchema: {
            name: Joi.string()
                .trim()
                .min(1)
                .max(30)
                .required()
                .pattern(/^[a-zA-Z0-9_-\s]+$/)
                .custom((val, helper) => {
                    if (
                        rules.some(
                            (p: DataProtectionItem) =>
                                p.id !== id && p.name.trim().toLowerCase() === val.trim().toLowerCase()
                        )
                    ) {
                        return helper.message({
                            custom: t('rbac.rbacPolicy.duplicateRuleNameError'),
                        });
                    }
                })
                .messages({ 'string.empty': t('encryption.modeLibrary.rbac.jsonFieldEmptyError') }),
            order: Joi.number()
                .required()
                .messages({ 'string.empty': t('encryption.modeLibrary.rbac.datatypeEmptyError') }),
            userGroups: Joi.array()
                .min(1)
                .required(),
            permission: Joi.string()
                .min(1)
                .required(),
            maskPolicy: Joi.object().when('permission', {
                is: 'MASK',
                then: Joi.required(),
                otherwise: Joi.allow(null),
            }),
        },
    };
    return rbacRuleInputSchema;
}
