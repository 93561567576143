import Joi from '@hapi/joi';
import { MaskingPolicy } from '@baffle/graphql/src/models';
import { t } from '@baffle/translate';

export function createMaskingPolicyInputScheme(maskingPolicies: MaskingPolicy[], id: string | undefined) {
    const maskingPolicyInputScheme = {
        ns: 'maskingPolicy',
        keys: ['name', 'description', 'datatypeGroup', 'mode', 'value'],
        defaultValues: {
            datatypeGroup: 'STRING',
            mode: 'FIXED',
            value: '',
        },
        validationSchema: {
            name: Joi.string()
                .min(1)
                .max(30)
                .pattern(new RegExp(/^[^~`!@#$%^&()={}[\]:;,.<>+\/\\?]+$/))
                .required()
                .custom((val, helper) => {
                    if (
                        maskingPolicies.some(
                            (m: MaskingPolicy) =>
                                m.id !== id && m.name.trim().toLowerCase() === val.trim().toLowerCase()
                        )
                    ) {
                        return helper.message({
                            custom: t('globalPolicies.maskingPolicy.duplicateMaskingPolicyError'),
                        });
                    }
                })
                .messages({
                    'string.max': t('main.invalidNameLength'),
                    'string.pattern.base': t('globalPolicies.maskingPolicy.nameInputInvalid'),
                    'string.empty': t('globalPolicies.maskingPolicy.nameInputInvalid'),
                }),
            datatypeGroup: Joi.string()
                .min(1)
                .required(),
            mode: Joi.string()
                .min(1)
                .required(),
            value: Joi.string()
                .min(1)
                .required()
                .allow(null)
                .messages({
                    'string.empty': t('globalPolicies.maskingPolicy.formatInputInvalid'),
                }),
        },
    };
    return maskingPolicyInputScheme;
}

export function maskingPolicyRenameScheme(maskingPolicies: MaskingPolicy[], id: string | undefined) {
    const maskingPolicyRenameScheme = {
        ns: 'database',
        keys: ['name'],
        validationSchema: {
            name: Joi.string()
                .trim()
                .min(1)
                .max(30)
                .required()
                .pattern(/^[a-zA-Z0-9_-\s]+$/)
                .custom((val, helper) => {
                    if (
                        maskingPolicies.some(
                            (p: MaskingPolicy) =>
                                p.id !== id && p.name.trim().toLowerCase() === val.trim().toLowerCase()
                        )
                    ) {
                        return helper.message({
                            custom: t('globalPolicies.maskingPolicy.duplicateMaskingPolicyError'),
                        });
                    }
                })
                .messages({
                    'string.max': t('main.invalidNameLength'),
                    'string.pattern.base': t('globalPolicies.maskingPolicy.nameInputInvalid'),
                    'string.empty': t('globalPolicies.maskingPolicy.nameInputInvalid'),
                }),
        },
    };
    return maskingPolicyRenameScheme;
}
