import React from 'react';
import cx from 'classnames';
import './SkeletonText.scss';
import { t } from '@baffle/translate';
interface SkeletonTextProps {
    getSkeletonProps?: () => any;
}

export const getDefaultSkeletonTextProps = () => {
    return {
        className: 'skeleton relative overflow-hidden inline-block bg-gray-300',
        width: 'w-48',
        height: 'h-5',
    };
};

const SkeletonText = ({ getSkeletonProps = getDefaultSkeletonTextProps }: SkeletonTextProps) => {
    const { className, width = 'w-48', height = 'h-5' } = getSkeletonProps();

    return <div aria-label={t('main.skeletonText')} className={cx(className, width, height)}></div>;
};

export const getDefaultSkeletonParagraphProps = () => {
    return {
        className: 'flex flex-col',
        width: 'w-48',
        height: 'h-48',
    };
};

interface SkeletonParagraphProps {
    getSkeletonParagraphProps?: () => any;
    getSkeletonProps?: () => any;
}

export const SkeletonParagraph = ({
    getSkeletonParagraphProps = getDefaultSkeletonParagraphProps,
    getSkeletonProps = getDefaultSkeletonTextProps,
}: SkeletonParagraphProps) => {
    const { className, width = 'w-48', height = 'h-5' } = getSkeletonParagraphProps();

    const doGetSkeleltonProps = () => ({
        ...getSkeletonProps(),
        className: 'skeleton relative overflow-hidden inline-block bg-gray-300 mb-1',
    });
    return (
        <div className={cx(className, width, height)}>
            <SkeletonText getSkeletonProps={doGetSkeleltonProps} />
            <SkeletonText getSkeletonProps={doGetSkeleltonProps} />
            <SkeletonText getSkeletonProps={doGetSkeleltonProps} />
        </div>
    );
};

export default SkeletonText;
