import React, { useEffect } from 'react';
import { Modal } from 'carbon-components-react';
import { t } from '@baffle/translate';
import { Observer } from 'mobx-react-lite';
import { CertificateStore } from '@baffle/manager/src/stores';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';
import useAwait from '@baffle/api-client/src/useAwait';
import { CertificateDetails } from '@baffle/graphql/src/models';

interface DeleteCACertificateProps {
    open: boolean;
    onClose: () => void;
    onSuccess: (name: string) => void;
}

const DeleteCACertificate = ({ open, onClose, onSuccess }: DeleteCACertificateProps) => {
    const { run, error, reset } = useAwait();

    useEffect(() => {
        if (error) {
            NotificationStore.push({
                'data-testid': 'certificate-delete-failure',
                kind: 'error',
                title: t('certificate.deleteCertificateError'),
                description: error.message ?? t('main.genericError'),
            });
        }
    }, [error]);

    const handleClose = () => {
        reset();
        setTimeout(() => {
            if (onClose) {
                onClose();
            }
        }, 200);
    };

    const currentCert: CertificateDetails | null = CertificateStore.selectedCertificate;
    const handleSave = () => {
        run(
            CertificateStore.delete(currentCert?.id as string).then(() => {
                handleClose();
                if (onSuccess) {
                    onSuccess(currentCert?.name as string);
                }
            })
        );
    };
    return (
        <Observer>
            {() => {
                return (
                    <Modal
                        id="database-delete-modal"
                        className="modal-container-white"
                        open={open}
                        modalHeading={t('certificate.deleteCertificateTitle', {
                            name: currentCert?.name ?? '',
                        })}
                        danger={true}
                        onRequestClose={onClose}
                        primaryButtonText={t('main.delete')}
                        secondaryButtonText={t('main.cancel')}
                        size="sm"
                        onRequestSubmit={handleSave}>
                        <p>{t('certificate.deleteCertificateCopy')}</p>
                    </Modal>
                );
            }}
        </Observer>
    );
};

export default DeleteCACertificate;
