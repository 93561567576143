import React from 'react';
import { t } from '@baffle/translate';
import ModeLibraryTree from './ModeLibraryTree';
import TreeAddIcon from '@baffle/components/src/icons/TreeAddIcon';
import OverflowMenu from '@baffle/components/src/menu/OverflowMenu';
import { DataFormatStore } from '@baffle/manager/src/stores';

const ModeLibrary = () => {
    const menuItemsHeader = [
        {
            text: t('encryption.modeLibrary.addEncryptionMode'),
            'data-testid': 'enc-mode-add-btn',
            onClick: (e: React.MouseEvent) => {
                e.stopPropagation();
                DataFormatStore.resetModeBuilder();
                DataFormatStore.setView(true, 'encryption');
            },
        },
        {
            text: t('encryption.modeLibrary.addMaskingMode'),
            'data-testid': 'mask-mode-add-btn',
            onClick: (e: React.MouseEvent) => {
                e.stopPropagation();
                DataFormatStore.resetModeBuilder();
                DataFormatStore.setView(true, 'masking');
            },
        },
    ];
    return (
        <div>
            <header className="relative z-10 flex-shrink-0 flex shadow h-8 text-xs" role="banner">
                <div className="flex-1 px-4 flex justify-between bg-gray-200 items-center border-solid border-t-2 border-r-2 border-gray-300">
                    <span className="text-black font-bold">{t('encryption.modeLibrary.header')}</span>
                    <OverflowMenu
                        data-testid="data-protection-header-menu"
                        actuator={{
                            'data-testid': 'add-data-protection-btn',
                            text: <TreeAddIcon />,
                            className: 'p-1 focus:outline-none focus:bg-white hover:bg-gray-300',
                            onClick: (e: React.MouseEvent) => {
                                e.stopPropagation();
                            },
                        }}
                        menuItems={menuItemsHeader}
                    />
                </div>
            </header>
            <ModeLibraryTree />
        </div>
    );
};

export default ModeLibrary;
