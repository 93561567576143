import React from 'react';
import { BaseCompoundComponentProps } from '../types/react';

export const getDefaultCellProps = () => {
    return {
        className:
            'h-full pl-2 whitespace-nowrap text-xs border-b border-solid border-gray-300 flex items-center truncate',
    };
};
export interface CellProps extends BaseCompoundComponentProps {
    getCellProps?: () => any;
}

const Cell = ({ children, getCellProps = getDefaultCellProps }: CellProps) => {
    return (
        <div role="cell" {...getCellProps()}>
            {children}
        </div>
    );
};

export default Cell;
