import React, { useState, useEffect } from 'react';
import { Observer } from 'mobx-react-lite';
import { Overview, OverviewCard } from '@baffle/components/src/overview/Overview';
import { DatabaseProxiesStore } from '@baffle/manager/src/stores';
import useAwait from '@baffle/api-client/src/useAwait';
import { useHistory, useParams } from 'react-router-dom';
import { t } from '@baffle/translate';
import SpinnerOverlay from '@baffle/components/src/loader/SpinnerOverlay';
import { DatabaseProxyConfiguration, DebugLevel, DefaultConfig, KeyValuePairsMap } from '@baffle/graphql/src/models';
import DataGrid from '@baffle/components/src/grid/data-grid';
import { useManageModals } from '@baffle/components/src/modal/Modal';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';
import {
    AddConfig,
    DeleteConfig,
    EditConfig,
    UpdateDebugLevel,
    UpdateRecordLevelEncryption,
    UpdateRbac,
} from './database-proxy-configurations';
import usePageHeight from '@baffle/utilities/src/usePageHeight';

const DatabaseProxyOverview = () => {
    const { id } = useParams() as { id: string };
    const history = useHistory();
    if (!id) {
        history.push('/database-proxies');
        return null;
    }

    const { status, run } = useAwait();

    useEffect(() => {
        run(
            Promise.all([
                DatabaseProxiesStore.readDatabaseProxy(id),
                DatabaseProxiesStore.readDatabaseProxies(),
                DatabaseProxiesStore.readDatabaseProxyConfiguration(id),
            ])
        );
    }, []);

    const headers = [
        {
            key: 'header1',
            header: t('databaseProxies.databaseProxy.config'),
            sortable: false,
        },
        {
            key: 'header2',
            header: t('databaseProxies.databaseProxy.value'),
            sortable: false,
        },
    ];

    const pageHeight = usePageHeight() - 200;

    return (
        <Observer>
            {() => {
                interface DatabaseProxyOverviewModals {
                    addConfig: boolean;
                    editDebugLevel: boolean;
                    editRle: boolean;
                    editRbacConfig: boolean;
                    editAdvanceConfig: boolean;
                    deleteConfig: boolean;
                }

                const initialModalState = {
                    addConfig: false,
                    editDebugLevel: false,
                    editRle: false,
                    editRbacConfig: false,
                    editAdvanceConfig: false,
                    deleteConfig: false,
                };

                const [modals, setModals] = useManageModals<DatabaseProxyOverviewModals>(initialModalState);

                const databaseProxy = DatabaseProxiesStore.databaseProxy;
                const databaseProxyConfiguration = DatabaseProxiesStore.databaseProxyConfiguration;

                const defaultConfigurations = databaseProxyConfiguration;
                delete defaultConfigurations?.advancedConfig;

                const [configKey, setConfigKey] = useState<string | null>(null);

                const onAddConfig = () => {
                    setModals({ addConfig: true });
                };

                const onConfigEdit = (config: string) => {
                    let [configType, configKey] = config.split('-');
                    switch (configType) {
                        case 'debugLevel':
                            setModals({ editDebugLevel: true });
                            break;
                        case 'rle':
                            setModals({ editRle: true });
                            break;
                        case 'rbacConfig':
                            setModals({ editRbacConfig: true });
                            break;
                        case 'delete':
                            setConfigKey(configKey);
                            setModals({ deleteConfig: true });
                            break;
                        default:
                            setConfigKey(config);
                            setModals({ editAdvanceConfig: true });
                            break;
                    }
                };

                const rows =
                    status === 'loading' || status === 'idle'
                        ? null
                        : Object.entries((defaultConfigurations as DatabaseProxyConfiguration) || {})
                              .map(([config, value]) => {
                                  switch (config) {
                                      case 'debugLevel':
                                          value = DebugLevel[value as keyof typeof DebugLevel];
                                          break;
                                      case 'rle':
                                          value = value ? 'Enabled' : 'Disabled';
                                          break;
                                      case 'rbacConfig':
                                          value = value?.enabled ? 'Enabled' : 'Disabled';
                                          break;
                                      default:
                                          break;
                                  }
                                  return {
                                      selectedId: config,
                                      id: config,
                                      header1: DefaultConfig[config as keyof typeof DefaultConfig] as string,
                                      header2: value,
                                  };
                              })
                              .concat(
                                  Object.entries(DatabaseProxiesStore.advanceConfigurations as KeyValuePairsMap).map(
                                      ([config, value]) => {
                                          return {
                                              selectedId: config,
                                              id: config,
                                              header1: config,
                                              header2: value,
                                          };
                                      }
                                  )
                              );
                return (
                    <>
                        {(status === 'loading' || status === 'idle') && <SpinnerOverlay description="" />}
                        {modals.addConfig ? (
                            <AddConfig
                                id={id}
                                open={modals.addConfig}
                                onClose={() => setModals({ addConfig: false })}
                                onSuccess={(name: string) => {
                                    NotificationStore.push({
                                        'data-testid': 'add-advance-config-success',
                                        title: t('databaseProxies.databaseProxyConfigurations.addAdvanceConfigSucess', {
                                            name,
                                        }),
                                        kind: 'success',
                                    });
                                }}
                            />
                        ) : null}

                        {modals.editDebugLevel ? (
                            <UpdateDebugLevel
                                id={id}
                                open={modals.editDebugLevel}
                                onClose={() => setModals({ editDebugLevel: false })}
                                onSuccess={(debugLevel: string) => {
                                    NotificationStore.push({
                                        'data-testid': 'udpate-debug-level-success',
                                        title: t(
                                            'databaseProxies.databaseProxyConfigurations.updateDebugLevelSuccess',
                                            { debugLevel }
                                        ),
                                        kind: 'success',
                                    });
                                }}
                            />
                        ) : null}

                        {modals.editRle ? (
                            <UpdateRecordLevelEncryption
                                id={id}
                                open={modals.editRle}
                                onClose={() => setModals({ editRle: false })}
                                onSuccess={(rle: boolean) => {
                                    const status: string = rle ? 'enabled' : 'disabled';
                                    NotificationStore.push({
                                        'data-testid': 'udpate-record-level-encryption-success',
                                        title: t(
                                            'databaseProxies.databaseProxyConfigurations.updateRecordLevelEncryptionSuccess',
                                            { status }
                                        ),
                                        kind: 'success',
                                    });
                                }}
                            />
                        ) : null}

                        {modals.editRbacConfig ? (
                            <UpdateRbac
                                id={id}
                                open={modals.editRbacConfig}
                                onClose={() => setModals({ editRbacConfig: false })}
                                onSuccess={() => {
                                    NotificationStore.push({
                                        'data-testid': 'udpate-rbac-config-success',
                                        title: t('databaseProxies.databaseProxyConfigurations.updateRbacConfigSuccess'),
                                        kind: 'success',
                                    });
                                }}
                            />
                        ) : null}

                        {modals.editAdvanceConfig ? (
                            <EditConfig
                                id={id}
                                configKey={configKey}
                                open={modals.editAdvanceConfig}
                                onClose={() => setModals({ editAdvanceConfig: false })}
                                onSuccess={(name: string) => {
                                    NotificationStore.push({
                                        'data-testid': 'edit-advance-config-success',
                                        title: t(
                                            'databaseProxies.databaseProxyConfigurations.editAdvanceConfigSucess',
                                            { name }
                                        ),
                                        kind: 'success',
                                    });
                                }}
                            />
                        ) : null}

                        {modals.deleteConfig ? (
                            <DeleteConfig
                                id={id}
                                configKey={configKey}
                                open={modals.deleteConfig}
                                onClose={() => setModals({ deleteConfig: false })}
                                onSuccess={(name: string) => {
                                    NotificationStore.push({
                                        'data-testid': 'delete-advance-config-success',
                                        title: t(
                                            'databaseProxies.databaseProxyConfigurations.deleteAdvanceConfigSucess',
                                            { name }
                                        ),
                                        kind: 'success',
                                    });
                                }}
                            />
                        ) : null}

                        {databaseProxy && (
                            <div className="overflow-y-auto" style={{ height: pageHeight }}>
                                <div className="flex flex-col lg:flex-row">
                                    <div className="w-full lg:w-1/2">
                                        <Overview
                                            title={t('databaseProxies.databaseProxy.databaseProxyDetails')}
                                            info={DatabaseProxiesStore.getDatabaseProxyOverview(databaseProxy)}
                                            topic="databaseProxyDetail"
                                            cn_list={['syncId']}
                                            wm_list={['syncId']}
                                            nv_list={{
                                                database: `/databasesV2/${databaseProxy?.database?.id}`,
                                                keystore: `/keystore/${databaseProxy?.keystore?.id}`,
                                            }}
                                        />
                                    </div>
                                    <div className="w-full lg:w-1/2">
                                        <OverviewCard
                                            title={t('databaseProxies.databaseProxy.configurations')}
                                            topic="databaseProxyConfigurations"
                                            onAdd={onAddConfig}
                                            addButtonCaption={t('databaseProxies.databaseProxy.addConfig')}>
                                            <DataGrid
                                                onRowEdit={onConfigEdit}
                                                onRowDelete={onConfigEdit}
                                                addMargin={false}
                                                idPrefix="databaseProxyConfiguration"
                                                rows={rows || []}
                                                headers={headers}></DataGrid>
                                        </OverviewCard>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                );
            }}
        </Observer>
    );
};

export default DatabaseProxyOverview;
