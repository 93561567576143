import Joi from '@hapi/joi';
import { t } from '@baffle/translate';
import { RoleInfo } from '@baffle/graphql/src/models';

export default function roleInputScheme(roles: RoleInfo[], id: string | undefined) {
    const roleInputScheme = {
        ns: 'roles',
        keys: ['name', 'effects'],
        defaultValues: {
            effects: [],
        },
        validationSchema: {
            name: Joi.string()
                .trim()
                .min(1)
                .max(30)
                .required()
                .pattern(/^[a-zA-Z0-9_-\s]+$/)
                .custom((val, helper) => {
                    if (
                        roles.some(
                            (p: RoleInfo) => p.id !== id && p.name.trim().toLowerCase() === val.trim().toLowerCase()
                        )
                    ) {
                        return helper.message({
                            custom: t('roles.duplicateRoleNameError'),
                        });
                    }
                })
                .messages({
                    'string.max': t('main.invalidNameLength'),
                    'string.empty': t('roles.RoleNameEmptyError'),
                    'string.pattern.base': t('roles.nameInputInvalid'),
                }),
            effects: Joi.array()
                .min(1)
                .required(),
        },
    };
    return roleInputScheme;
}
