import databaseClient from '@baffle/api-client/src/databaseClientV2';
import { DatabasePayloadV2, DbEnvTypes } from '@baffle/graphql/src/models';
import { observable, action, makeObservable } from 'mobx';
import moment from 'moment';

class DatabaseStore {
    constructor() {
        makeObservable(this, {
            database: observable,
            dbEnvTypes: observable,
            setDatabase: action,
            setDatabases: action,
            setDbEnvTypes: action,
        });
    }

    databases = observable<DatabasePayloadV2>([]);
    dbEnvTypes: DbEnvTypes = {};
    database: DatabasePayloadV2 | null | undefined = null;

    setDatabase(databasePayload: DatabasePayloadV2) {
        this.database = databasePayload;
    }

    setDatabases(databasePayloadList: DatabasePayloadV2[]) {
        this.databases.replace(databasePayloadList ?? []);
    }

    setDbEnvTypes(types: DbEnvTypes) {
        this.dbEnvTypes = types;
    }

    async readDatabases() {
        try {
            const res = await databaseClient.readDatabases();
            this.setDatabases(res.data);
        } catch (error) {
            throw error;
        }
    }

    async readDatabase(id: string) {
        try {
            const databasePayload = await databaseClient.readDatabase(id);
            this.setDatabase(databasePayload);
        } catch (error) {
            throw error;
        }
    }

    async readDbEnvTypes() {
        try {
            const types = await databaseClient.readDbEnvTypes();
            this.setDbEnvTypes(types);
        } catch (error) {
            throw error;
        }
    }

    async renameDatabase(id: string, payload: { newName: string }) {
        try {
            const databasePayload = await databaseClient.renameDatabase(id, payload);
            this.setDatabase(databasePayload);
        } catch (error) {
            throw error;
        }
    }

    async updateDatabase(id: string, payload: DatabasePayloadV2) {
        try {
            const databasePayload: DatabasePayloadV2 = await databaseClient.updateDatabase(id, payload);
            const databasesCopy = this.databases.slice();
            if (databasePayload) {
                const index = databasesCopy.findIndex(db => db.id === databasePayload.id);
                databasesCopy[index] = databasePayload;
                this.setDatabases(databasesCopy);
            }
            this.setDatabase(databasePayload);
        } catch (error) {
            throw error;
        }
    }

    getDbOverviewPayload(dbDetail: DatabasePayloadV2) {
        return {
            id: dbDetail.id,
            name: dbDetail.name,
            environment: dbDetail.environment,
            type: dbDetail.type,
            version: dbDetail.version,
            hostname: dbDetail.hostname,
            port: dbDetail.port,
            configDbName: dbDetail.configDbName,
            username: dbDetail.username,
            ssl: dbDetail.ssl ? 'Yes' : 'No',
            createTime: dbDetail.createTime ? moment(dbDetail.createTime).format('MMMM DD, YYYY h:mm:ss A') : null,
            createdBy: dbDetail.createdBy,
        };
    }
}

export default DatabaseStore;
