import React, { useState, useMemo, useEffect } from 'react';
import { useCombobox, UseComboboxState, UseComboboxStateChangeOptions } from 'downshift';
import { motion, useAnimation } from 'framer-motion';
import cx from 'classnames';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextInput from '@baffle/components/src/forms/TextInput';
import { t } from '@baffle/translate';
import SearchIcon from '@material-ui/icons/Search';
import {
    DropdownSearchComboBoxProps,
    getDefaultComboBoxProps,
    getDefaultListItemProps,
    getDefaultMenuProps,
} from '@baffle/components/src/dropdown/DropdownSearchComboBox';
import { ComboDropdownItem } from '@baffle/components/src/dropdown/dropdownTypes';
import { ColumnMetaData, DataProtectionItem } from '@baffle/graphql/src/models';
import './DataFormatComboBox.scss';
import { deselectDataProtectionItem } from '@baffle/utilities/src/column/dataFormat';
import CheckIcon from '@material-ui/icons/Check';

export interface DataFormatComboDropdownItem extends DataProtectionItem, ComboDropdownItem {}

interface ItemIndexMap {
    [key: string]: number;
}

interface DataProtectionComboBoxProps extends DropdownSearchComboBoxProps<DataFormatComboDropdownItem> {
    col: ColumnMetaData;
    onChange: ({ selectedItems }: { selectedItems: DataFormatComboDropdownItem[] }) => void;
}

function dataFormatStateReducer(
    state: UseComboboxState<DataFormatComboDropdownItem>,
    actionAndChanges: UseComboboxStateChangeOptions<DataFormatComboDropdownItem>
) {
    const { type, changes } = actionAndChanges;
    switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
            return {
                ...changes,
                isOpen: true, // keep menu open after selection.
                highlightedIndex: state.highlightedIndex,
                inputValue: '', // don't add the item string as input value at selection.
            };
        case useCombobox.stateChangeTypes.InputBlur:
            //This allows the user to click add data format again
            //after canceling the modal
            if (changes.selectedItem?.name === 'add_format') {
                //@ts-ignore
                changes.selectedItem = undefined;
            }
            //TODO: fix InputBlur is causing the menu to close after item selection due to
            //isOpen being set to false.
            return {
                ...changes,
                // isOpen: true, // keep menu open after selection.
                inputValue: '', // don't add the item string as input value at selection.
            };
        default:
            return changes; // otherwise business as usual.
    }
}

const createItems = (items: DataFormatComboDropdownItem[], firstItem: DataFormatComboDropdownItem[]) => {
    const itemsCopy = items.slice();
    itemsCopy.unshift(...firstItem);
    return itemsCopy;
};

export default function DataProtectionComboBox({
    items,
    col,
    defaultItems,
    onChange,
    getToggleButtonProps = () => {},
    getListItemProps = getDefaultListItemProps,
    getComboBoxProps = getDefaultComboBoxProps,
    getMenuProps = getDefaultMenuProps,
}: DataProtectionComboBoxProps) {
    const firstItem: DataFormatComboDropdownItem[] = [
        {
            id: 'OFF',
            name: 'Clear Selections',
        },
    ];
    const [selectedItems, setSelectedItems] = useState<Array<DataFormatComboDropdownItem>>([]);
    const [inputItems, setInputItems] = useState(() => createItems(items, firstItem));
    useEffect(() => {
        setInputItems(createItems(items, firstItem));
    }, [items]);

    useEffect(() => {
        if (defaultItems) {
            setSelectedItems(defaultItems);
        }
    }, [defaultItems]);

    const itemIndexMap: ItemIndexMap = useMemo(() => {
        const accumulator: ItemIndexMap = {};
        inputItems.forEach((item, index) => (accumulator[item.name] = index));
        return accumulator;
    }, [inputItems]);

    const [didAnimate, setDidAnimate] = useState(false);

    const controls = useAnimation();

    const {
        getToggleButtonProps: getToggleButtonPropsFromUseComboBox,
        // getLabelProps,
        isOpen,
        getMenuProps: getMenuPropsFromUseComboBox,
        getInputProps,
        getComboboxProps: getComboboxPropsFromUseComboBox,
        highlightedIndex,
        getItemProps,
    } = useCombobox({
        onIsOpenChange: ({ isOpen }) => {
            if (isOpen) {
                controls.start({ height: itemHeight, opacity: 1 }).then(() => setDidAnimate(true));
            } else {
                controls.start({ height: 0, opacity: 0 }).then(() => setDidAnimate(false));
            }
        },
        selectedItem: undefined,
        items: inputItems,
        onSelectedItemChange: ({ selectedItem }) => {
            if (!selectedItem) {
                return;
            }
            if (selectedItem.name === 'Clear Selections') {
                setSelectedItems([]);
                onChange({ selectedItems: [] });
                return;
            }
            const incomingItems = selectedItems.slice();
            const index = selectedItems.findIndex(s => s.id === selectedItem.id);
            let isEncryptionFormat = false;
            //dirty check to select either policy OR encryption mode
            if (selectedItem.type === 'policy' || selectedItem.type === 'encryption') {
                isEncryptionFormat = selectedItems.some(s => s.type === 'policy' || s.type === 'encryption');
            }
            const sameFormatIndex = selectedItems.findIndex(
                s => s.id !== selectedItem.id && (s.type === selectedItem.type || isEncryptionFormat)
            );

            if (sameFormatIndex >= 0) {
                incomingItems.splice(sameFormatIndex, 1);
            }

            if (index >= 0) {
                incomingItems.splice(index, 1);
                //set 'selections' value to null for column
                deselectDataProtectionItem(selectedItem, col);
            } else {
                incomingItems.push(selectedItem);
            }
            setSelectedItems(incomingItems);
            onChange({ selectedItems: incomingItems });
        },
        onInputValueChange: ({ inputValue }) => {
            const copy = items.slice(); //use items here to
            const newItems =
                inputValue === ''
                    ? copy
                    : copy.filter(item => item.name.toLowerCase().includes((inputValue ?? '').toLowerCase()));
            setInputItems(newItems);
        },
        stateReducer: dataFormatStateReducer,
    });

    const getListItemPropsShadow = ({ item, index }: { item: DataFormatComboDropdownItem; index: number }) => {
        const { className, disabled, ...rest } = getListItemProps({ item, index });
        const { onClick, ...itemProps } = getItemProps({ item, index });

        return {
            ...itemProps,
            ...rest,
            onClick: (e: any) => {
                //isOpen conditional fixes: https://baffle.atlassian.net/browse/BM3-1611
                // the issue is if another DataProtectionComboBox is present with the same exact list of items,
                //onClick will trigger the dispatch event for it's stateReducer, since the item and index will be exactly the same
                //see: https://github.com/downshift-js/downshift/blob/master/src/hooks/useCombobox/index.js#L272
                if (isOpen) {
                    onClick(e);
                }
            },
            className: cx(className, {
                'bg-gray-200 border-blue-60': highlightedIndex === index,
                'cursor-not-allowed text-gray-400': Boolean(disabled),
                'text-gray-600': !Boolean(disabled),
                'bg-blue-100': Boolean(selectedItems.find(s => s.name === item.name)),
            }),
        };
    };

    const { className: menuClassName, ...menuProps } = getMenuProps();

    const itemHeight = 38;
    const menuHeight =
        inputItems.length < 5
            ? (inputItems.length + 2) * //1 is to offset the input and the first format label
              itemHeight
            : itemHeight * 5; //5 caps the list size height

    const { className: comboBoxClassName, ...comboBoxProps } = getComboBoxProps();
    const encItems = inputItems.filter(item => item.type === 'encryption');
    const maskItems = inputItems.filter(item => item.type === 'mask');
    const policies = inputItems.filter(item => Boolean(item.type && item.type === 'policy'));
    const jsonPolicies = inputItems.filter(item => item.type && item.type === 'jsonPolicy');
    const rbacPolicies = inputItems.filter(item => Boolean(item.type && item.type === 'rbacPolicy'));
    return (
        <>
            {/* <label {...getLabelProps()}>Choose an element:</label> */}
            <div {...getComboboxPropsFromUseComboBox()} {...comboBoxProps} className={`w-full ${comboBoxClassName}`}>
                <div className="flex justify-between items-center">
                    <button
                        className={cx(
                            'pl-2 w-full h-7 flex items-center justify-between bg-white border border-solid border-gray-300 focus:outline-blue',
                            {
                                'text-red-500': !Boolean(selectedItems.length),
                            }
                        )}
                        type="button"
                        {...getToggleButtonPropsFromUseComboBox()}
                        {...getToggleButtonProps()}
                        tabIndex="0"
                        aria-label={t('main.ariaToggleMenu')}>
                        <span className="h-auto truncate pr-2 py-2">
                            {selectedItems.length ? selectedItems.map(s => s.name).join(', ') : 'UNPROTECTED'}
                        </span>
                        <ExpandMoreIcon
                            className={cx('transform transition ease-in-out duration-250 focus:color-blue', {
                                'rotate-180': isOpen,
                            })}
                        />
                    </button>
                </div>
                <div className="relative">
                    <ul
                        style={{ height: menuHeight }}
                        {...getMenuPropsFromUseComboBox()}
                        className={cx(menuClassName + ' w-full', {
                            hidden: !isOpen,
                        })}
                        {...menuProps}>
                        <>
                            <motion.li
                                className="font-bold border border-solid border-gray-300 bg-white focus:border-blue focus:outline-none flex"
                                animate={controls}
                                transition={{ duration: 0.3 }}
                                style={{ height: 0, opacity: 0 }}>
                                <div className="relative flex w-full">
                                    <SearchIcon className="text-gray-600 absolute text-base m-auto inset-y-0 ml-1" />
                                    <TextInput
                                        name="data-protection-selection-input"
                                        labelHidden
                                        placeholder={t('main.search')}
                                        labelText={t('main.chooseOption')}
                                        getInputContainerProps={() => {
                                            return {
                                                className: 'w-full flex',
                                            };
                                        }}
                                        getInputProps={() => {
                                            return {
                                                className:
                                                    'shadow-sm focus:ring-blue focus:border-blue focus:text-gray-700 block w-full text-sm px-6 focus:rounded-none flex-grow focus: outline-none ',
                                                ...getInputProps(),
                                            };
                                        }}
                                    />
                                </div>
                            </motion.li>
                            <motion.li
                                {...getListItemPropsShadow({
                                    item: firstItem[0],
                                    index: 0,
                                })}
                                animate={controls}
                                transition={{ duration: 0.3 }}
                                style={{ height: 0, opacity: 0 }}
                                data-testid={`${col.name}_select_clear`}>
                                <div className="font-bold text-xs text-blue">{t('main.clear')}</div>
                            </motion.li>
                            {policies.length > 0 ? (
                                <motion.li
                                    className="font-bold flex items-center p-2 border-t-2 border-solid border-gray-300 bg-white focus:border-blue focus:outline-none"
                                    animate={controls}
                                    transition={{ duration: 0.3 }}
                                    style={{ height: didAnimate ? itemHeight : 0, opacity: didAnimate ? 1 : 0 }}>
                                    {t('encryption.policies')}
                                </motion.li>
                            ) : null}
                            {policies.map((item, i) => (
                                <motion.li
                                    animate={controls}
                                    transition={{ duration: 0.3 }}
                                    data-testid={`${i}_${col.name}_select_policy`}
                                    style={{
                                        height: didAnimate ? itemHeight : 0,
                                        opacity: didAnimate ? 1 : 0,
                                    }}
                                    key={`${item.name}${itemIndexMap[item.name]}`}
                                    {...getListItemPropsShadow({ item, index: itemIndexMap[item.name] })}>
                                    {Boolean(selectedItems.find(s => s.name === item.name)) ? (
                                        <CheckIcon fontSize="small" />
                                    ) : null}
                                    <span className="ml-7 absolute">{item.name}</span>
                                </motion.li>
                            ))}

                            {jsonPolicies.length > 0 ? (
                                <motion.li
                                    className="font-bold flex items-center p-2 border-t-2 border-solid border-gray-300 bg-white focus:border-blue focus:outline-none"
                                    animate={controls}
                                    transition={{ duration: 0.3 }}
                                    style={{ height: didAnimate ? itemHeight : 0, opacity: didAnimate ? 1 : 0 }}>
                                    {t('encryption.jsonPolicies')}
                                </motion.li>
                            ) : null}
                            {jsonPolicies.map((item, i) => (
                                <motion.li
                                    animate={controls}
                                    transition={{ duration: 0.3 }}
                                    data-testid={`${i}_${col.name}_select_json_policy`}
                                    style={{
                                        height: didAnimate ? itemHeight : 0,
                                        opacity: didAnimate ? 1 : 0,
                                    }}
                                    key={`${item.name}${itemIndexMap[item.name]}`}
                                    {...getListItemPropsShadow({ item, index: itemIndexMap[item.name] })}>
                                    {Boolean(selectedItems.find(s => s.name === item.name)) ? (
                                        <CheckIcon fontSize="small" />
                                    ) : null}
                                    <span className="ml-7 w-auto absolute">{item.name}</span>
                                </motion.li>
                            ))}

                            {rbacPolicies.length > 0 ? (
                                <motion.li
                                    className="font-bold flex items-center p-2 border-t-2 border-solid border-gray-300 bg-white focus:border-blue focus:outline-none"
                                    animate={controls}
                                    transition={{ duration: 0.3 }}
                                    style={{ height: didAnimate ? itemHeight : 0, opacity: didAnimate ? 1 : 0 }}>
                                    {t('encryption.rbacPolicies')}
                                </motion.li>
                            ) : null}
                            {rbacPolicies.map((item, i) => (
                                <motion.li
                                    animate={controls}
                                    transition={{ duration: 0.3 }}
                                    data-testid={`${i}_${col.name}_select_rbac_policy`}
                                    style={{
                                        height: didAnimate ? itemHeight : 0,
                                        opacity: didAnimate ? 1 : 0,
                                    }}
                                    key={`${item.name}${itemIndexMap[item.name]}`}
                                    {...getListItemPropsShadow({ item, index: itemIndexMap[item.name] })}>
                                    {Boolean(selectedItems.find(s => s.name === item.name)) ? (
                                        <CheckIcon fontSize="small" />
                                    ) : null}
                                    <span className="ml-7 w-auto absolute">{item.name}</span>
                                </motion.li>
                            ))}

                            {encItems.length > 0 ? (
                                <motion.li
                                    className="font-bold flex items-center p-2 border-t-2 border-solid border-gray-300 bg-white focus:border-blue focus:outline-none"
                                    animate={controls}
                                    transition={{ duration: 0.3 }}
                                    style={{ height: didAnimate ? itemHeight : 0, opacity: didAnimate ? 1 : 0 }}>
                                    {t('encryption.encModes')}
                                </motion.li>
                            ) : null}
                            {encItems.map((item, i) => (
                                <motion.li
                                    animate={controls}
                                    transition={{ duration: 0.3 }}
                                    data-testid={`${i}_${col.name}_select_encmode`}
                                    style={{
                                        height: didAnimate ? itemHeight : 0,
                                        opacity: didAnimate ? 1 : 0,
                                    }}
                                    key={`${item.name}${itemIndexMap[item.name]}`}
                                    {...getListItemPropsShadow({ item, index: itemIndexMap[item.name] })}>
                                    {Boolean(selectedItems.find(s => s.name === item.name)) ? (
                                        <CheckIcon fontSize="small" />
                                    ) : null}
                                    <span className="ml-7 w-auto absolute">{item.name}</span>
                                </motion.li>
                            ))}
                            {maskItems.length > 0 ? (
                                <motion.li
                                    className="font-bold flex items-center p-2 border-t-2 border-solid border-gray-300 bg-white focus:border-blue focus:outline-none"
                                    animate={controls}
                                    transition={{ duration: 0.3 }}
                                    style={{ height: didAnimate ? itemHeight : 0, opacity: didAnimate ? 1 : 0 }}>
                                    {t('encryption.maskModes')}
                                </motion.li>
                            ) : null}

                            {maskItems.map((item, i) => (
                                <motion.li
                                    data-testid={`${i}_${col.name}_select_maskmode`}
                                    animate={controls}
                                    transition={{ duration: 0.3 }}
                                    style={{ height: didAnimate ? itemHeight : 0, opacity: didAnimate ? 1 : 0 }}
                                    key={`${item.name}_${itemIndexMap[item.name]}`}
                                    {...getListItemPropsShadow({ item, index: itemIndexMap[item.name] })}>
                                    {Boolean(selectedItems.find(s => s.name === item.name)) ? (
                                        <CheckIcon fontSize="small" />
                                    ) : null}
                                    <span className="ml-7 w-auto absolute">{item.name}</span>
                                </motion.li>
                            ))}
                        </>
                    </ul>
                </div>
            </div>
        </>
    );
}
