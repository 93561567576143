import useAwait from '@baffle/api-client/src/useAwait';
import Alert from '@baffle/components/src/alerts/Alert';
import Button from '@baffle/components/src/buttons/Button';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@baffle/components/src/modal/Modal';
import { t } from '@baffle/translate';
import React, { useState, useEffect } from 'react';
import { SystemEmailStore } from '@baffle/manager/src/stores';

interface DeleteLoggerModalProps {
    open: boolean;
    id: string;
    name: string;
    onClose?: () => void;
    onSuccess?: () => void;
}
const DeleteLoggerModal = ({ open, id, name, onClose, onSuccess }: DeleteLoggerModalProps) => {
    const [openModal, setOpen] = useState(open);
    const { run, reset, error } = useAwait();

    const handleClose = () => {
        if (!openModal) {
            return;
        }
        setOpen(false);
        reset();
        setTimeout(() => {
            if (onClose) {
                onClose();
            }
        }, 200);
    };

    const doDeleteLogger = () => {
        run(
            SystemEmailStore.deleteLogger(id as string).then(() => {
                handleClose();
                if (onSuccess) {
                    onSuccess();
                }
            })
        );
    };

    //Sync state from props
    useEffect(() => {
        setOpen(open);
    }, [open]);
    return (
        <Modal open={openModal} onClose={handleClose}>
            <ModalHeader onClose={handleClose}>
                <div className="flex items-start">
                    <div className="mt-0 ml-2">
                        <h3 className="text-xl font-medium text-gray-100" id="modal-headline">
                            {t('settings.deleteLoggerModalHeading', { name: name })}
                        </h3>
                    </div>
                </div>
            </ModalHeader>
            <ModalBody>
                <div className="flex flex-col px-4">
                    {error ? (
                        <div className="flex flex-wrap mb-4">
                            <div className="flex-1 px-4">
                                <Alert
                                    type="danger"
                                    title={t('settings.deleteLogConfigError')}
                                    description={error?.message || ''}
                                />
                            </div>
                        </div>
                    ) : null}
                    <p
                        className="text-lg mb-4 py-8"
                        dangerouslySetInnerHTML={{
                            __html: t('settings.deleteLoggerConfirmationMsg'),
                        }}></p>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex justify-end items-center h-16 w-full">
                    <Button theme="secondary" className="h-full w-1/2" onClick={() => handleClose()} size="lg">
                        {t('main.cancel')}
                    </Button>
                    <Button
                        className="h-full w-1/2"
                        data-testid="do-delete-logger-btn"
                        theme="danger"
                        id="do-delete-logger-btn"
                        onClick={doDeleteLogger}
                        size="lg">
                        {t('main.delete')}
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default DeleteLoggerModal;
