import { ConfigFileAssociations, DeploymentState } from '@baffle/graphql/src/models';
import client from '.';

export async function list(appId: string): Promise<Array<ConfigFileAssociations>> {
    return client(`api/v1.0/application/config/files/${appId}`);
}

export async function read(appId: string, fileName: string, shieldId?: string): Promise<string> {
    return client(`api/v1.0/application/config/${appId}/${fileName}${shieldId ? `/${shieldId}` : ''}`, {
        responseContentType: 'text/plain',
    });
}

interface CreateParams {
    fileName: string;
    config: string;
    shieldId?: string;
    deploymentState?: DeploymentState;
}
interface CreatePayload extends Omit<CreateParams, 'config'> {
    fileName: string;
    applicationConfigFile: File;
    shieldId?: string;
}
export async function create(appId: string, { fileName, config, shieldId, deploymentState }: CreateParams) {
    const url = `api/v1.0/application/config/file/${appId}`;
    const file = new File([config], fileName);
    const body: CreatePayload = Object.assign({}, { applicationConfigFile: file, fileName });

    if (shieldId) {
        body.shieldId = shieldId;
    }
    if (deploymentState) {
        body.deploymentState = deploymentState;
    }

    return client(url, {
        body,
        useForm: true,
        method: 'POST',
    });
}

export default {
    list,
    read,
    create,
};
