import client from '.';

export async function read() {
    return client('/api/v1.0/user/list');
}

export async function remove(uid: string) {
    return client(`/api/v1.0/user/delete/${uid}`, { method: 'DELETE' });
}

export default {
    read,
    remove,
};
