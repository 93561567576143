import { InitEnums } from '@baffle/utilities/src/enums';
import { TYPE_NAMES } from '..';
import storage from '@baffle/utilities/src/storage';
import { signOut } from './clientUtils';

const resolvers = {
    Mutation: {
        signOut: (_root: any) => {
            signOut();
            return null;
        },
        setTenantLocal: (
            //@ts-ignore
            _root,
            variables: {
                tenant: string;
            },
            //@ts-ignore
            { cache }
        ) => {
            const { tenant } = variables;

            const data = { tenant: { name: tenant, __typename: TYPE_NAMES.Tenant } };
            storage.set(InitEnums.BAFFLE_TENANT, tenant);
            cache.writeData({ data });
            return data;
        },
        setAdminUser: (
            //@ts-ignore
            _root,
            variables: {
                email: string;
            },
            //@ts-ignore
            { cache }
        ) => {
            const { email } = variables;

            const data = { adminUser: { email, __typename: TYPE_NAMES.AdminUser } };
            storage.set(InitEnums.BAFFLE_ADMIN_USER, email);
            cache.writeData({ data });
            return data;
        },
    },
};

export default resolvers;
