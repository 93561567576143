import useAwait from '@baffle/api-client/src/useAwait';
import { CreateModal, CreateModalBody, CreateModalHeader } from '@baffle/components/src/CreateModal/CreateModal';
import { t } from '@baffle/translate';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import React, { useState, useEffect, useMemo } from 'react';
import { MaskingPolicyStore, RbacPolicyStore, UserGroupStore } from '@baffle/manager/src/stores';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';
import RbacRuleInputScheme from './RbacRuleInputScheme';
import Select from 'react-select';
import { TextInput, Select as CarbonSelect, SelectItem } from 'carbon-components-react';
import { DataProtectionItem, RbacPolicyDefaultPermission, RbacPolicyRuleV2 } from '@baffle/graphql/src/models';
import rbacPolicyClient from '@baffle/api-client/src/rbacPolicyClient';
import '../../../styles/theme-g10.scss';

interface AddRbacRuleModalProps {
    open: boolean;
    onClose?: () => void;
    onSuccess?: () => void;
    rules: RbacPolicyRuleV2[];
    editMode: boolean;
    selectedRbacPolicyId: string;
    datatypeGroup: string;
}
const AddRbacRuleModal = ({
    open,
    onClose,
    onSuccess,
    rules,
    editMode,
    selectedRbacPolicyId,
    datatypeGroup,
}: AddRbacRuleModalProps) => {
    const [openModal, setOpen] = useState(open);
    const { run, error, reset } = useAwait();
    const rbacRuleInputSchemes = useMemo(() => RbacRuleInputScheme(rules, RbacPolicyStore.selectedRbacRule?.id), [
        rules,
        RbacPolicyStore.selectedRbacRule?.id,
    ]);
    const selectedRbacRule = RbacPolicyStore.selectedRbacRule;
    //@ts-ignore
    const { inputState, inputErrors, inputActions } = useInputState(rbacRuleInputSchemes);
    const isValid = inputActions.validateAll(true);

    useEffect(() => {
        if (error) {
            NotificationStore.push({
                'data-testid': 'rbac-rule-add-failure',
                kind: 'error',
                title: t('rbac.rbacPolicy.addRbacRuleError'),
                description: error.message ?? '',
            });
        }
    }, [error]);

    useEffect(() => {
        run(UserGroupStore.getRbacUserGroups());
        run(MaskingPolicyStore.getMaskingPolicies());
        if (selectedRbacRule && editMode) {
            inputActions.setFields({
                name: selectedRbacRule.name,
                userGroups: selectedRbacRule.userGroups,
                permission: selectedRbacRule.permission,
                maskPolicy: selectedRbacRule.maskPolicy,
                order: selectedRbacRule.order,
                datatypeGroup: datatypeGroup,
            });
        }
    }, []);

    const handleClose = () => {
        if (!openModal) {
            return;
        }
        setOpen(false);
        reset();
        inputActions.reset();
        setTimeout(() => {
            if (onClose) {
                onClose();
            }
        }, 200);
    };

    //Sync state from props
    useEffect(() => {
        setOpen(open);
    }, [open]);

    const permissionItems = Object.keys(RbacPolicyDefaultPermission).map(key => ({
        id: key,
        label: key,
        'data-testid': `policy-permission-li-${key}`,
    }));

    const maskModeItems = MaskingPolicyStore.maskingPolicies.map(mode => ({
        id: mode.id,
        label: mode.name,
        datatypeGroup: mode.datatypeGroup,
        'data-testid': `policy-mask-li-${mode.name}`,
    }));

    const handleSave = () => {
        let payload: RbacPolicyRuleV2 = rbacRuleInputSchemes.keys.reduce((acc: any, c: any) => {
            acc['name'] = inputState.name;
            acc['userGroups'] = inputState.userGroups;
            acc['order'] = editMode ? inputState.order : rules.length ? rules[rules.length - 1].order + 1 : 1;
            acc['permission'] = inputState.permission;
            acc['maskPolicy'] = inputState.maskPolicy
                ? {
                      id: (inputState.maskPolicy as DataProtectionItem)?.id,
                      name: (inputState.maskPolicy as DataProtectionItem)?.name,
                  }
                : null;
            acc['datatypeGroup'] = datatypeGroup;
            acc['rbacPolicyId'] = selectedRbacPolicyId;
            return acc;
        }, {});
        {
            editMode
                ? run(
                      rbacPolicyClient.updateRbacRule(selectedRbacRule?.id as string, payload).then(() => {
                          inputActions.reset();
                          if (onSuccess) {
                              onSuccess();
                          }
                          NotificationStore.push({
                              kind: 'success',
                              title: t('rbac.rbacPolicy.updateRbacRuleSuccess', { name: payload.name }),
                              'data-testid': 'rbac-rule-update-success',
                          });
                      })
                  )
                : run(
                      rbacPolicyClient.addRbacRule(payload).then(() => {
                          inputActions.reset();
                          if (onSuccess) {
                              onSuccess();
                          }
                          NotificationStore.push({
                              kind: 'success',
                              title: t('rbac.rbacPolicy.createRbacRuleSuccess', { name: payload.name }),
                              'data-testid': 'rbac-rule-create-success',
                          });
                      })
                  );
        }
    };
    return (
        <CreateModal
            open={openModal}
            onClose={handleClose}
            isValid={isValid}
            modalId="add-rbac-rule-modal"
            primaryButtonText={editMode ? t('main.save') : t('rbac.rbacPolicy.createButtonText')}
            primaryButtonTheme="primary"
            primaryButton={() => handleSave()}>
            <CreateModalHeader
                titleText={
                    editMode ? t('rbac.rbacPolicy.editRuleButtonText') : t('rbac.rbacPolicy.createRuleModalHeading')
                }
            />
            <CreateModalBody>
                <div className="flex flex-wrap mb-6 items-end">
                    <div className="flex-1 name-input-container">
                        <TextInput
                            id="rbac-rule-name-input"
                            data-testid={`rbac-rule-name-input`}
                            value={(inputState.name as string) || ''}
                            labelText={t('main.name')}
                            placeholder={t('encryption.modeLibrary.rbac.rbacPolicy.ruleNamePlaceholder')}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                inputActions.setField('name', val);
                            }}
                        />
                    </div>
                </div>
                <div className="flex flex-wrap mb-6 items-end">
                    <div className="flex-1 name-input-container">
                        <label className="mb-2 block text-xxs">{t('rbac.rbacPolicy.rulesHeader2')}</label>
                        {(inputState.userGroups as DataProtectionItem[]) ? (
                            <Select
                                inputId={`rbac-rule-user-groups-dropdown`}
                                value={(inputState.userGroups as DataProtectionItem[]).map(group => ({
                                    value: group.id,
                                    label: group.name,
                                }))}
                                isMulti
                                maxMenuHeight={98}
                                name="rbac-rule-user-groups-dropdown"
                                options={UserGroupStore.rbacUserGroups?.map(group => ({
                                    value: group.id as string,
                                    label: group.name,
                                    'data-testid': `policy-permission-li-${group.name}`,
                                }))}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                isClearable={false}
                                isSearchable={true}
                                placeholder={t('main.chooseOption')}
                                onChange={(selected: any) => {
                                    inputActions.setField(
                                        'userGroups',
                                        selected.length
                                            ? selected.map((group: any) => ({
                                                  id: group.value,
                                                  name: group.label,
                                              }))
                                            : []
                                    );
                                }}
                            />
                        ) : null}
                    </div>
                </div>
                <div className="flex flex-wrap mb-6 items-end">
                    <div className="flex-1 name-input-container">
                        <CarbonSelect
                            id="rbac-rule-permission"
                            labelText={t('rbac.rbacPolicy.rulesHeader3')}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                inputActions.setFields({ permission: val, maskPolicy: null });
                            }}
                            value={inputState.permission || 'placeholder-item'}>
                            <SelectItem disabled hidden text={t('main.chooseOption')} value="placeholder-item" />
                            {permissionItems.map((permissionItem, i) => {
                                return <SelectItem key={i} text={permissionItem.label} value={permissionItem.id} />;
                            })}
                        </CarbonSelect>
                    </div>
                </div>
                {inputState.permission === 'MASK' ? (
                    <div className="flex flex-wrap w-full mb-6 items-start">
                        <div className="flex-1 relative">
                            <CarbonSelect
                                id="rbac-rule-mask-mode"
                                labelText={t('rbac.rbacPolicy.maskPolicy')}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const val = e.target.value || '';
                                    const value = MaskingPolicyStore.maskingPolicies.find(
                                        mode => mode.id === val
                                    ) as DataProtectionItem;
                                    inputActions.setField('maskPolicy', value);
                                }}
                                value={(inputState.maskPolicy as DataProtectionItem)?.id || 'placeholder-item'}>
                                <SelectItem disabled hidden text={t('main.chooseOption')} value="placeholder-item" />
                                {maskModeItems
                                    .filter((maskMode: any) => {
                                        return datatypeGroup == maskMode.datatypeGroup;
                                    })
                                    .map((maskModeItem, i) => {
                                        return <SelectItem key={i} text={maskModeItem.label} value={maskModeItem.id} />;
                                    })}
                            </CarbonSelect>
                        </div>
                    </div>
                ) : null}
            </CreateModalBody>
        </CreateModal>
    );
};

export default AddRbacRuleModal;
