export type SupportedTypes =
    | 'LOCAL'
    | 'AWS_KMS'
    | 'SAFENET_KEY_SECURE'
    | 'CLOUD_HSM'
    | 'AZURE'
    | 'IBM'
    | 'HASHI_VAULT'
    | 'GENERIC_HSM';

export enum DEKStorageType {
    AWS_S3_BUCKET = 'AWS_S3_BUCKET',
}

export enum DEKStorageTypeValues {
    AWS_S3_BUCKET = 'AWS S3 bucket',
}

export enum DEKStorageTypeAzure {
    'Azure Blob' = 'Azure Blob',
}

export enum DEKStorageTypeAzureValues {
    'Azure Blob' = 'azureblob',
}

export enum DEKStorageTypeHashicorp {
    vault = 'vault',
}

export enum DEKStorageTypeHashicorpValues {
    vault = 'vault',
}

export interface KeystoreType {
    type: SupportedTypes;
    name: string;
}

export enum KeystoreTypeEnum {
    LOCAL = 'LOCAL',
    AWS_KMS = 'AWS_KMS',
    SAFENET_KEY_SECURE = 'SAFENET_KEY_SECURE',
    CLOUD_HSM = 'CLOUD_HSM',
    IBM = 'IBM',
    IBM_HPCS = 'IBM_HPCS',
    AZURE = 'AZURE',
    HASHI_VAULT = 'HASHI_VAULT',
    GENERIC_HSM = 'GENERIC_HSM',
}
export interface KeystoreListItem {
    id?: string;
    name: string;
    description: string;
    type: SupportedTypes;
    details: Keystore;
    appNameSpace?: string;
}
export interface Keystore {
    id?: string;
    name: string;
    description: string;
    type: SupportedTypes;
    status?: boolean;
    location?: string;
    hostName?: string;
    hostPort?: number;
    keys: number;
    hostUser?: string;
    hostPassword?: string;
    keyStoreAlias?: string;
    keyStorePassword?: string;
    appNameSpace?: string;
    //AWS properties
    awsAccessKeyId?: string;
    awsSecretAccessKey?: string;
    awsRegion?: string;
    awsRootArn?: string;
    awsS3Bucket?: string;
    awsKmsAlias?: string;
    dekStore?: string;
    createTime: number;
    createdBy: string;
    //safenet
    ssl?: boolean;
    certIssuerDN?: string;
    certSubjectSAN?: string;
    certSubjectDN?: string;
    certExpiry?: number;

    expiresSoon?: boolean; //added by the client
    expired?: boolean; //added by the client
    applicationId?: string; //added by the client
}

export interface KeystoreInputLocal {
    name: string;
    description: string;
    secretKey: string;
    type: SupportedTypes;
}
export interface KeystoreInputAWS {
    name: string;
    description: string;
    type: SupportedTypes;
    appNamespace: string;
    awsAccessKeyId: string;
    awsSecretAccessKey?: string;
    awsRegion?: string;
    awsRootArn?: string;
    awsS3Bucket?: string;
    awsKmsAlias?: string;
    dekStore?: string;
}
export interface KeystoreInputSafenet {
    name: string;
    description: string;
    type: SupportedTypes;
    cert: File;
    hostName: string;
    hostPort: number;
    hostUser: string;
    hostPassword: string;
    keyStorePassword: string;
    keyStoreAlias: string;
    appNamespace: string;
}

export interface KeystoreInputHSM {
    name: string;
    description: string;
    type: SupportedTypes;
    hsmUser: string;
    hsmPassword: string;
    hsmPartition: string;
    appNamespace: string;
    awsAccessKeyId: string;
    awsSecretAccessKey?: string;
    awsRegion?: string;
    awsRootArn?: string;
    awsS3Bucket?: string;
    awsKmsAlias?: string;
    dekStore?: string;
}

export interface KeystoreDTOPayload {
    name: string;
    description: string;
    type: SupportedTypes;
    details:
        | Omit<KeystoreInputLocal, 'name' | 'description'>
        | Omit<KeystoreInputAWS, 'name' | 'description'>
        | Omit<KeystoreInputHSM, 'name' | 'description'>
        | Omit<KeystoreInputSafenet, 'name' | 'description' | 'cert'>;
}

export interface KeystorePayload {
    jks?: File;
    keystore: KeystoreDTOPayload;
}

export interface KeyDetails {
    keyId: number;
    keyUUID: string;
    keyType: 'DEK' | 'MK' | 'BAF' | string;
    creationDate: number;
    isRotated?: boolean;
}
