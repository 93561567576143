import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ApplicationV2 } from '../../../graphql/src/models';
import { buildApplicationCanViewV2 } from '../../../utilities/src/buildApplicationCanView';
import { ButtonSkeleton, InlineLoading } from 'carbon-components-react';
import OverflowMenu from '@baffle/components/src/menu/OverflowMenu';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import ArrowDropDownSharp from '@material-ui/icons/ArrowDropDownSharp';
import Lock from '@material-ui/icons/Lock';
import LockOutlined from '@material-ui/icons/LockOutlined';
import LockOpenOutlined from '@material-ui/icons/LockOpenOutlined';
import cx from 'classnames';
import { ApplicationEnums } from '../../../utilities/src/enums';
import { t } from '@baffle/translate';

interface BtnActionsProps {
    loading: boolean;
    application: ApplicationV2;
}

const ApplicationButtonActions = ({ loading, application }: BtnActionsProps) => {
    let history = useHistory();
    const primaryBtnClasses = cx(
        'w-full border border-blue-60 text-blue-60 bg-white px-1 py-2 text-lg flex items-center justify-center hover:bg-blue-60-hover hover:text-white active:bg-blue-80 active:text-white focus:bg-blue-60 focus:text-white focus: border-white'
    );

    const canView = useMemo(() => buildApplicationCanViewV2(application), [application?.allowedActions]);

    if (loading) {
        return <ButtonSkeleton />;
    }

    const renderPrimaryAction = () => {
        if (ApplicationEnums.statusMap[application.migrationDetails.status] === 'migrating') {
            return (
                <button
                    className={primaryBtnClasses}
                    data-testid="migration-status-btn"
                    onClick={() => history.push(`/application/migrationstatus/${application.id}`)}
                    tabIndex={0}
                    type="button">
                    <InlineLoading className="mr-2 w-8" />
                    {t('encryption.migrating')}
                </button>
            );
        }
        if (canView.migration) {
            return (
                <button
                    data-testid="migration-details-btn"
                    className={primaryBtnClasses}
                    onClick={() => history.push(`/application/migrationstatus/${application.id}`)}
                    tabIndex={0}
                    type="button">
                    <RemoveRedEyeIcon className="mr-2 w-5 h-5" /> {t('encryption.migrationDetails')}
                </button>
            );
        }
        return (
            <button
                disabled={!canView.encryption}
                data-testid="encryption-btn"
                title={!canView.encryption ? application.allowedActions.messages.join(',') : t('encryption.encrypt')}
                className={cx(primaryBtnClasses, {
                    disabled: !canView.encryption,
                })}
                onClick={() => {
                    if (canView.encryption) {
                        history.push(`/application/encryption/${application.id}`);
                    }
                }}
                tabIndex={0}
                type="button">
                <LockOutlined className="mr1" /> {t('encryption.encrypt')}
            </button>
        );
    };
    const menuItems = [];
    if (canView.encryption) {
        menuItems.push({
            text: (
                <>
                    <Lock className="mr-1 w-5 h-5" /> {t('encryption.encrypt')}
                </>
            ),
            onClick: () => {
                history.push(`application/encryption/${application.id}`);
            },
        });
    }
    if (canView.decryption) {
        menuItems.push({
            text: (
                <>
                    <LockOpenOutlined className="mr-1 w-5 h-5" /> {t('encryption.decrypt')}
                </>
            ),
            onClick: () => {
                history.push(`application/decryption/${application.id}`);
            },
        });
    }

    return (
        <div className="bx--btn-set">
            {renderPrimaryAction()}
            {canView.migration &&
            !loading &&
            ApplicationEnums.statusMap[application.migrationDetails.status] !== 'migrating' ? (
                <OverflowMenu
                    data-testid="application-sidepanel-enc-menu"
                    menuItems={menuItems}
                    actuator={{
                        'data-testid': 'sidepanel-dropdown-btn',
                        text: <ArrowDropDownSharp />,
                        className: cx(primaryBtnClasses, 'border-l-0 px-2 h-full'),
                    }}
                />
            ) : null}
        </div>
    );
};
export default ApplicationButtonActions;
