import { format as dateFnFormat, parseISO } from 'date-fns';

const dateParse = (d: string | number) => {
    switch (typeof d) {
        case 'string':
            return parseISO(d);
        default:
            return new Date(d);
    }
};

export function format(date: string | number | null | undefined, format: string) {
    if (!Boolean(date)) {
        return 'n/a';
    }

    const d = dateParse(date as string | number);
    return dateFnFormat(d, format);
}
