import Joi from '@hapi/joi';
import { RbacConfigSchema } from '@baffle/graphql/src/models';
import { t } from '@baffle/translate';

export default function createRbacConfigInputScheme() {
    const rbacConfigInputSchemes = {
        [RbacConfigSchema.SESSION]: {
            ns: 'rbacConfig',
            keys: ['mode', 'userDetermination'],
            validationSchema: {
                mode: Joi.string()
                    .min(1)
                    .required(),
                userDetermination: Joi.string()
                    .min(1)
                    .required(),
            },
        },
        [RbacConfigSchema.SQL_COMMENT_RAW]: {
            ns: 'rbacConfig',
            keys: ['mode', 'userDetermination', 'sqlCommentFormat'],
            validationSchema: {
                mode: Joi.string()
                    .min(1)
                    .required(),
                userDetermination: Joi.string()
                    .min(1)
                    .required(),
                sqlCommentFormat: Joi.string()
                    .min(1)
                    .required()
                    .pattern(new RegExp(/[^'"%&();<>+]+$/))
                    .messages({
                        'string.pattern.base': t('encryption.modeLibrary.rbac.rbacConfig.sqlCommentInvalidError'),
                        'string.empty': t('encryption.modeLibrary.rbac.rbacConfig.sqlCommentEmptyError'),
                    }),
            },
        },
        [RbacConfigSchema.SQL_COMMENT_JWT]: {
            ns: 'rbacConfig',
            keys: [
                'mode',
                'userDetermination',
                'sqlCommentFormat',
                'tid',
                'aud',
                'keyValuePairs',
                'secretKey',
                'providerUrl',
                'cacheCapacity',
            ],
            validationSchema: {
                mode: Joi.string()
                    .min(1)
                    .required(),
                userDetermination: Joi.string()
                    .min(1)
                    .required(),
                sqlCommentFormat: Joi.string()
                    .min(1)
                    .required()
                    .pattern(new RegExp(/[^'"%&();<>+]+$/))
                    .messages({
                        'string.pattern.base': t('encryption.modeLibrary.rbac.rbacConfig.sqlCommentInvalidError'),
                        'string.empty': t('encryption.modeLibrary.rbac.rbacConfig.sqlCommentEmptyError'),
                    }),
                cacheCapacity: Joi.number()
                    .integer()
                    .allow(0, null, '')
                    .greater(0)
                    .messages({
                        'number.base': t('encryption.modeLibrary.rbac.rbacConfig.cacheCapacityInvalidError'),
                        'number.integer': t('encryption.modeLibrary.rbac.rbacConfig.cacheCapacityInvalidError'),
                        'number.greater': t('encryption.modeLibrary.rbac.rbacConfig.cacheCapacityInvalidError'),
                    }),
            },
        },
    };
    return rbacConfigInputSchemes;
}
