import Joi from '@hapi/joi';
import { t } from '@baffle/translate';

export default function createIBMHpcsScheme() {
    return {
        ns: 'keystore',
        keys: [
            'name',
            'description',
            'type',
            'appNamespace',
            'awsRegion',
            'instanceId',
            'iamApiKey',
            'dekStore',
            'ibmRegion',
            'ibmKmsAlias',
            'awsS3Bucket',
            'awsS3Endpoint',
            'awsAccessKeyId',
            'awsSecretAccessKey',
            'keyMgtUrl',
        ],
        validationSchema: {
            name: Joi.string()
                .min(1)
                .max(30)
                .pattern(new RegExp(/^[^~`!@#$%^&()={}[\]:;,.<>+\/\\?]+$/))
                .required()
                .messages({
                    'string.max': t('main.invalidNameLength'),
                    'string.pattern.base': t('keystore.nameInputInvalid'),
                    'string.empty': t('keystore.nameInputInvalid'),
                }),
            type: Joi.string().required(),
            ibmKmsAlias: Joi.string()
                .min(1)
                .required(),
            ibmRegion: Joi.string()
                .min(1)
                .required(),
            iamApiKey: Joi.string()
                .min(1)
                .required(),
            instanceId: Joi.string()
                .min(1)
                .required(),
            awsS3Bucket: Joi.string()
                .min(1)
                .required(),
            awsS3Endpoint: Joi.string()
                .min(1)
                .required(),
            dekStore: Joi.string()
                .min(1)
                .required(),
            appNamespace: Joi.string()
                .min(1)
                .required(),
            awsKmsAlias: Joi.string().optional(),
            awsAccessKeyId: Joi.string()
                .min(1)
                .required(),
            awsSecretAccessKey: Joi.string()
                .min(1)
                .required(),
            keyMgtUrl: Joi.string()
                .min(1)
                .required(),
        },
    };
}
