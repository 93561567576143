import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import '../Encryption.scss';
import { EncryptionMode, ModeBuilderType } from '@baffle/graphql/src/models';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import { t } from '@baffle/translate';
import Alert from '@baffle/components/src/alerts/Alert';
import TextInput from '@baffle/components/src/forms/TextInput';
import TextArea from '@baffle/components/src/forms/TextArea';
import Button from '@baffle/components/src/buttons/Button';
import DropdownSelect, { getDefaultToggleButtonProps } from '@baffle/components/src/dropdown/Dropdown';
import createModeInputScheme from './modeInputSchemes/createModeInputScheme';
import { DataFormatStore, EncryptionStore } from '@baffle/manager/src/stores';
import dataProtectionClient from '@baffle/api-client/src/dataProtectionClient';
import useAwait from '@baffle/api-client/src/useAwait';
import EditIcon from '@baffle/components/src/icons/EditIcon';
import { EncModes } from '@baffle/graphql/src/models';

interface AddEncryptionModeProps {
    onClose?: () => void;
    onSuccess?: (name: string, action: string) => void;
    existingMode?: EncryptionMode | null;
}

const AddEncryptionMode = ({
    onClose = () => null,
    onSuccess = (name: string, action: string) => null,
    existingMode,
}: AddEncryptionModeProps) => {
    let { id } = useParams() as { id: string };
    const modeInputSchemes = useMemo(() => createModeInputScheme(DataFormatStore.encryptionModes, existingMode?.id), [
        DataFormatStore.encryptionModes,
        existingMode,
    ]);
    const { run, error, reset } = useAwait();
    const [validationError, setValidationError] = useState(0);
    const [editMode, setEditMode] = useState(Boolean(existingMode));
    const [showPfpe, toggleShowPfpe] = useState(false);

    //@ts-ignore
    const { inputState, inputErrors, inputActions } = useInputState(modeInputSchemes[ModeBuilderType.ENCRYPTION]);
    const isValid = inputActions.validateAll(true);
    const modeItems = Object.keys(EncModes).map(key => ({
        id: key,
        label: key,
        'data-testid': `encmode-li-${key}`,
    }));

    const handleSave = async () => {
        const payload = modeInputSchemes[ModeBuilderType.ENCRYPTION].keys.reduce((acc: any, c: any) => {
            //This creates a new object mapping the keys from the input scheme
            //to the key/values from the inputState
            if (c === 'pfpeStart' || c === 'pfpeEnd') {
                acc['partialFpe'] = {
                    start: inputState.pfpeStart ? +inputState.pfpeStart : null,
                    stop: inputState.pfpeEnd ? +inputState.pfpeEnd : null,
                };
            } else {
                acc[c] = (inputState[c] as string)?.trim();
            }
            return acc;
        }, {});
        run(
            (existingMode
                ? dataProtectionClient.editEncryptionMode(existingMode.id, payload)
                : dataProtectionClient.createEncryptionMode(payload)
            ).then(() => {
                onSuccess(inputState.name as string, existingMode ? 'updated' : 'added');
                EncryptionStore.setSelectedTable('encryption', '');
                DataFormatStore.listAll(id as string);
                inputActions.reset();
            })
        );
    };

    const handleClose = () => {
        onClose();
        inputActions.reset();
        reset();
    };

    const handleEdit = () => {
        setEditMode(false);
    };

    //Sync state from props
    useEffect(() => {
        if (existingMode) {
            inputActions.reset();
            setEditMode(true);
            const { name, description, mode, type, partialFpe } = existingMode;
            inputActions.setFields({
                name,
                description,
                mode,
                type,
                pfpeStart: partialFpe?.start,
                pfpeEnd: partialFpe?.stop,
            });
        } else {
            setEditMode(false);
            inputActions.reset();
        }
    }, [existingMode]);

    //Check for input errors
    useEffect(() => {
        let validationErrorCount = 0;
        Object.keys(inputErrors).forEach(key => {
            if (inputErrors[key] != null) {
                validationErrorCount += 1;
            }
        });
        setValidationError(validationErrorCount);
    }, [inputErrors]);

    useEffect(() => {
        if (!existingMode) {
            if (
                inputState.mode === 'AES_FPE' &&
                (inputState.type === 'DECIMAL' || inputState.type === 'ALPHANUM' || inputState.type === 'CC')
            ) {
                inputActions.setFields({ ...inputState, pfpeStart: 0, pfpeEnd: 0 });
                toggleShowPfpe(true);
            } else {
                inputActions.setFields({ ...inputState, pfpeStart: null, pfpeEnd: null });
                toggleShowPfpe(false);
            }
        } else {
            toggleShowPfpe(true);
        }
    }, [inputState.mode, inputState.type]);

    return (
        <div className="flex-grow bg-white">
            <div className="bg-gray-200 px-4 h-8 border-solid border-t-2 border-b-2 border-gray-300">
                <div className="relative flex items-center h-full uppercase w-112 font-bold text-xs">
                    {existingMode ? (
                        <div className="flex-1 flex justify-between items-center">
                            <span>
                                {t('encryption.modeLibrary.addEncryptionMode')}: {existingMode.name}
                            </span>
                            <button aria-label={t('main.edit')} onClick={handleEdit}>
                                <EditIcon />
                            </button>
                        </div>
                    ) : (
                        <span>{t('encryption.modeLibrary.encryptionModeBuilder')}</span>
                    )}
                </div>
            </div>
            <div className="w-116 mt-8 pl-4">
                {error ? (
                    <div className="flex flex-wrap">
                        <div className="flex-1">
                            <Alert
                                type="danger"
                                title={t('encryption.modeLibrary.newEncModeAddError')}
                                description={error?.message || ''}
                            />
                        </div>
                    </div>
                ) : null}
                {validationError ? (
                    <div className="flex flex-wrap">
                        <div className="flex-1">
                            <Alert type="danger" title={t('keystore.inputError', { count: validationError })} />
                        </div>
                    </div>
                ) : null}
                <div className="flex flex-wrap mb-6 items-end">
                    <div className="flex-1 name-input-container">
                        <TextInput
                            id="encmode-name-input"
                            name="encmode-name-input"
                            datat-testid="encmode-name-input"
                            labelText={t('encryption.modeLibrary.encModeName')}
                            placeholder={t('encryption.modeLibrary.encModeNamePlaceholder')}
                            invalid={Boolean(inputErrors.name)}
                            invalidText={inputErrors.name}
                            value={(inputState.name as string) || ''}
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                inputActions.validateField({ field: 'name', skipNull: true });
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                inputActions.setField('name', val);
                            }}
                            disabled={editMode}
                        />
                        <span className="input-char-counter right-0">
                            {(inputState.name as string)?.length ?? 0} / 30
                        </span>
                    </div>
                </div>
                <div className="flex flex-wrap mb-6">
                    <div className="flex-1 relative">
                        <TextArea
                            id="encmode-description-input"
                            name="encmode-description-input"
                            data-testid="encmode-description-input"
                            labelText={t('encryption.modeLibrary.descriptionInputLabel')}
                            invalidText={t('encryption.modeLibrary.descriptionInputInvalid')}
                            placeholder={t('encryption.modeLibrary.descriptionPlaceholder')}
                            value={(inputState.description as string) || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                inputActions.setField('description', val);
                                if (val.length > 100) {
                                    e.target.value = val.slice(0, 100);
                                }
                                inputActions.setField('description', e.target.value);
                            }}
                            disabled={editMode}
                        />
                        <span className="input-char-counter right-0">
                            {(inputState.description as string)?.length ?? 0} / 100
                        </span>
                    </div>
                </div>
                <div className="flex flex-wrap mb-6 items-start">
                    <div className="flex-1">
                        <DropdownSelect
                            id="encmode-select-input"
                            placeholder={t('main.chooseOption')}
                            label={t('encryption.modeLibrary.encMode')}
                            value={
                                inputState.mode
                                    ? modeItems.find(m => m.id === inputState.mode)
                                    : { id: 'chooseOption', label: t('main.chooseOption') }
                            }
                            getToggleButtonProps={() => {
                                const { className: btnClasses, ...rest } = getDefaultToggleButtonProps();
                                return {
                                    disabled: Boolean(existingMode),
                                    className: `${btnClasses}`,
                                    ...rest,
                                };
                            }}
                            handleSelectedItemChange={({ selectedItem }) => {
                                inputActions.setFields({ ...inputState, mode: selectedItem?.id as string, type: null });
                            }}
                            items={modeItems}
                        />
                    </div>
                </div>
                <div className="flex flex-wrap mb-6 items-end">
                    <div className="flex-1">
                        <DropdownSelect
                            id="enctype-select-input"
                            placeholder={t('main.chooseOption')}
                            label={t('encryption.modeLibrary.encType')}
                            value={
                                inputState.type
                                    ? { id: inputState.type as string, label: inputState.type as string }
                                    : { id: 'chooseOption', label: t('main.chooseOption') }
                            }
                            getToggleButtonProps={() => {
                                const { className: btnClasses, ...rest } = getDefaultToggleButtonProps();
                                return {
                                    disabled: Boolean(!inputState.mode) || Boolean(existingMode),
                                    className: `${btnClasses}`,
                                    ...rest,
                                };
                            }}
                            handleSelectedItemChange={({ selectedItem }) => {
                                inputActions.setField('type', selectedItem?.id as string);
                            }}
                            items={
                                inputState.mode
                                    ? EncModes[inputState.mode as string].map((type: string) => ({
                                          id: type,
                                          label: type,
                                          'data-testid': `enctype-li-${type}`,
                                      }))
                                    : []
                            }
                        />
                    </div>
                </div>
                {showPfpe ? (
                    <>
                        <label className="mb-2 block text-xs font-medium text-gray-600">
                            {t('encryption.modeLibrary.partialConfig')}
                        </label>
                        <div className="flex justify-between w-1/2 mb-1">
                            <div className="relative flex w-1/2 h-8">
                                <span className="text-xs w-10 bg-gray-300 border border-solid border-gray-300 justify-center items-center flex">
                                    {t('encryption.modeLibrary.start')}
                                </span>
                                <TextInput
                                    name="pfpe-start"
                                    data-testid="pfpe-start"
                                    labelHidden
                                    labelText={t('main.chooseOption')}
                                    value={inputState.pfpeStart}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const val = e.target.value.trim();
                                        inputActions.setField('pfpeStart', val);
                                    }}
                                    getInputContainerProps={() => {
                                        return {
                                            className: 'w-10 flex',
                                        };
                                    }}
                                    getInputProps={() => {
                                        return {
                                            className:
                                                'shadow-sm focus:ring-blue focus:border-blue focus:text-gray-700 block w-full text-sm border text-center focus:rounded-none flex-grow focus: outline-none ',
                                        };
                                    }}
                                    disabled={Boolean(existingMode)}
                                />
                            </div>
                            <div className="relative flex w-1/2 h-8">
                                <span className="text-xs w-10 bg-gray-300 border border-solid border-gray-300 justify-center items-center flex">
                                    {t('encryption.modeLibrary.stop')}
                                </span>
                                <TextInput
                                    name="pfpe-stop"
                                    data-testid="pfpe-stop"
                                    labelHidden
                                    labelText={t('main.chooseOption')}
                                    value={inputState.pfpeEnd}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const val = e.target.value.trim();
                                        inputActions.setField('pfpeEnd', val);
                                    }}
                                    getInputContainerProps={() => {
                                        return {
                                            className: 'w-10 flex',
                                        };
                                    }}
                                    getInputProps={() => {
                                        return {
                                            className:
                                                'shadow-sm focus:ring-blue focus:border-blue focus:text-gray-700 block w-full text-sm border text-center focus:rounded-none flex-grow focus: outline-none ',
                                        };
                                    }}
                                    disabled={Boolean(existingMode)}
                                />
                            </div>
                        </div>
                        <div className="w-full text-gray-550 text-xxxs mb-6">
                            <span className="block my-1">{t('encryption.modeLibrary.pfpeExample1')}</span>
                            <span className="block my-1">{t('encryption.modeLibrary.pfpeExample2')}</span>
                            <span className="block my-1">{t('encryption.modeLibrary.pfpeExample3')}</span>
                            <span className="block my-1">{t('encryption.modeLibrary.pfpeText')}</span>
                        </div>
                    </>
                ) : null}
                {editMode ? (
                    <div className="flex w-full justify-end h-8">
                        <Button
                            theme="primary"
                            className="py-2 w-24 uppercase font-semibold text-xs"
                            data-testid="edit-enc-mode-btn"
                            onClick={() => {
                                handleEdit();
                            }}>
                            {t('main.edit')}
                        </Button>
                    </div>
                ) : (
                    <div className="flex w-full justify-end h-8">
                        <Button
                            theme="secondary"
                            className="py-2 w-24 text-xs font-semibold uppercase"
                            data-testid="close-enc-mode-btn"
                            onClick={() => {
                                handleClose();
                            }}>
                            {t('main.cancel')}
                        </Button>
                        <Button
                            className="py-2 w-24 ml-2 text-xs font-semibold uppercase"
                            data-testid="add-enc-mode-btn"
                            theme={!isValid ? 'disabled' : 'primary'}
                            onClick={handleSave}>
                            {t('main.save')}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AddEncryptionMode;
