import TextInput from '@baffle/components/src/forms/TextInput';
import { InputActions, InputError, InputState } from '@baffle/utilities/src/inputHooks';
import React from 'react';
import { t } from '@baffle/translate';
import TextArea from '@baffle/components/src/forms/TextArea';

interface RbacConfigSqlCommentJwtProps {
    inputState: InputState;
    inputErrors: InputError;
    inputActions: InputActions;
    editMode: boolean;
}

const RbacConfigSqlCommentJwt = ({ inputState, inputActions, inputErrors, editMode }: RbacConfigSqlCommentJwtProps) => {
    return (
        <div>
            <div className="flex flex-wrap mb-6 items-end">
                <div className="flex-1 name-input-container">
                    <TextInput
                        id="sql-comment-format-input"
                        name="sql-comment-format-input"
                        data-testid="sql-comment-format-input"
                        labelText={t('encryption.modeLibrary.rbac.rbacConfig.sqlCommentFormatLabel')}
                        placeholder={t('encryption.modeLibrary.rbac.rbacConfig.sqlCommentPlaceholder')}
                        invalid={Boolean(inputErrors.sqlCommentFormat)}
                        invalidText={inputErrors.sqlCommentFormat}
                        value={(inputState.sqlCommentFormat as string) || ''}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            inputActions.validateField({
                                field: 'sqlCommentFormat',
                                skipNull: true,
                            });
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value || '';
                            inputActions.setField('sqlCommentFormat', val);
                        }}
                        disabled={editMode}
                    />
                </div>
            </div>
            <div className="flex flex-wrap mb-6 items-end">
                <div className="flex-1 name-input-container">
                    <TextInput
                        id="tid-input"
                        name="tid-input"
                        data-testid="tid-input"
                        labelText={t('encryption.modeLibrary.rbac.rbacConfig.tidLabel')}
                        invalid={Boolean(inputErrors.tid)}
                        invalidText={inputErrors.tid}
                        value={(inputState.tid as string) || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value || '';
                            inputActions.setField('tid', val);
                        }}
                        placeholder={t('encryption.modeLibrary.rbac.rbacConfig.tidPlaceholder')}
                        disabled={editMode}
                    />
                </div>
            </div>
            <div className="flex flex-wrap mb-6 items-end">
                <div className="flex-1 name-input-container">
                    <TextInput
                        id="aud-input"
                        name="aud-input"
                        data-testid="aud-input"
                        labelText={t('encryption.modeLibrary.rbac.rbacConfig.audLabel')}
                        invalid={Boolean(inputErrors.aud)}
                        invalidText={inputErrors.aud}
                        value={(inputState.aud as string) || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value || '';
                            inputActions.setField('aud', val);
                        }}
                        placeholder={t('encryption.modeLibrary.rbac.rbacConfig.audPlaceholder')}
                        disabled={editMode}
                    />
                </div>
            </div>
            <div className="flex flex-wrap mb-6">
                <div className="flex-1 relative">
                    <TextArea
                        id="key-value-pairs-input"
                        name="key-value-pairs-input"
                        data-testid="key-value-pairs-input"
                        labelText={t('encryption.modeLibrary.rbac.rbacConfig.keyValueLabel')}
                        placeholder={t('encryption.modeLibrary.rbac.rbacConfig.keyValuePlacehoder')}
                        value={(inputState.keyValuePairs as string) || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value || '';
                            inputActions.setField('keyValuePairs', val);
                        }}
                        disabled={editMode}
                    />
                </div>
            </div>
            <div className="flex flex-wrap mb-6 items-end">
                <div className="flex-1 name-input-container">
                    <TextInput
                        id="secretKey-input"
                        name="secretKey-input"
                        data-testid="secretKey-input"
                        labelText={t('encryption.modeLibrary.rbac.rbacConfig.secretKeyLabel')}
                        invalid={Boolean(inputErrors.secretKey)}
                        invalidText={inputErrors.secretKey}
                        value={(inputState.secretKey as string) || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value || '';
                            inputActions.setField('secretKey', val);
                        }}
                        placeholder={t('encryption.modeLibrary.rbac.rbacConfig.secretKeyPlacholder')}
                        disabled={editMode}
                        type="password"
                    />
                </div>
            </div>
            <div className="flex flex-wrap mb-6 items-end">
                <div className="flex-1 name-input-container">
                    <TextInput
                        id="providerUrl-input"
                        name="providerUrl-input"
                        data-testid="providerUrl-input"
                        labelText={t('encryption.modeLibrary.rbac.rbacConfig.providerUrlLabel')}
                        invalid={Boolean(inputErrors.providerUrl)}
                        invalidText={inputErrors.providerUrl}
                        value={(inputState.providerUrl as string) || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value || '';
                            inputActions.setField('providerUrl', val.trim());
                        }}
                        placeholder={t('encryption.modeLibrary.rbac.rbacConfig.providerUrlPlacholder')}
                        disabled={editMode}
                    />
                </div>
            </div>
            <div className="flex flex-wrap mb-6 items-end">
                <div className="flex-1 name-input-container">
                    <TextInput
                        id="cacheCapacity-input"
                        name="cacheCapacity-input"
                        data-testid="cacheCapacity-input"
                        labelText={t('encryption.modeLibrary.rbac.rbacConfig.cacheCapacityLabel')}
                        invalid={Boolean(inputErrors.cacheCapacity)}
                        invalidText={inputErrors.cacheCapacity}
                        value={inputState.cacheCapacity === 0 ? '0' : inputState.cacheCapacity || ''}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            inputActions.validateField({
                                field: 'cacheCapacity',
                                skipNull: true,
                            });
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value || '';
                            inputActions.setField('cacheCapacity', val.trim());
                        }}
                        placeholder={t('encryption.modeLibrary.rbac.rbacConfig.cacheCapacityPlaceholder')}
                        disabled={editMode}
                    />
                </div>
            </div>
        </div>
    );
};

export default RbacConfigSqlCommentJwt;
