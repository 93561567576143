import React, { useState, useMemo } from 'react';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import { CreateModal, CreateModalBody, CreateModalHeader } from '@baffle/components/src/CreateModal/CreateModal';
import { TextArea, TextInput } from 'carbon-components-react';
import createKeystoreInputScheme from '../keystore/keystoreInputSchemes/createKeystoreInputScheme';
import { KeystoreTypeEnum } from '@baffle/graphql/src/models';
import { t } from '@baffle/translate';
import { RadioButtonGroup, RadioButton, Checkbox } from 'carbon-components-react';

interface AddCmkModalProps {
    open: boolean;
    onClose: () => void;
    onSuccess: (cmkDetails: any) => void;
}

const AddCmkModal = (props: AddCmkModalProps) => {
    const keystoreInputSchemes = useMemo(() => createKeystoreInputScheme(), []);
    //@ts-ignore
    const { inputState, inputErrors, inputActions } = useInputState(keystoreInputSchemes[KeystoreTypeEnum.AWS_KMS]);
    const isValid =
        inputActions.validateIndividualField({ field: 'name', skipNull: false }) &&
        inputActions.validateIndividualField({ field: 'awsKmsAlias', skipNull: false });
    const initState = {
        useExistingKey: true,
        createDefaultDek: false,
    };

    const [cmk, setCmk] = useState(initState);

    const onSuccess = () => {
        let cmkDetails = {
            name: inputState.name,
            description: inputState.description,
            useExistingKey: cmk.useExistingKey,
            alias: inputState.awsKmsAlias,
            createDefaultDek: cmk.createDefaultDek,
        };
        props.onSuccess(cmkDetails);
    };

    return (
        <>
            <CreateModal
                key={JSON.stringify(props)}
                open={props.open}
                onClose={props.onClose}
                isValid={isValid}
                modalId="add-cmk-modal"
                primaryButtonText={t('keystorev2.addCmkTitle')}
                primaryButtonTheme="primary"
                primaryButton={onSuccess}>
                <CreateModalHeader titleText={t('keystorev2.addCmkTitle')} />
                <CreateModalBody>
                    <div className="text-sm font-normal leading-5 mb-4 mt-2 mr-6">
                        {t('keystorev2.addCmkModalMessage')}
                    </div>
                    <div className="flex flex-wrap mb-6 items-end">
                        <div className="flex-1 text-sm name-input-container">
                            <TextInput
                                id="cmk-name-input"
                                name="cmk-name-input"
                                data-testid="cmk-name-input"
                                labelText={t('main.name')}
                                placeholder={t('keystorev2.namePlaceholder')}
                                invalid={Boolean(inputErrors.name)}
                                invalidText={inputErrors.name}
                                value={(inputState.name as string) || ''}
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    inputActions.validateField({
                                        field: 'name',
                                        skipNull: true,
                                    });
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    let val = e.target.value || '';
                                    inputActions.setField('name', val);
                                }}
                            />
                            <span className="input-char-counter right-0">
                                {(inputState.name as string)?.length ?? 0} / 30
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-wrap mb-6">
                        <div className="flex-1 relative">
                            <TextArea
                                id="cmk-description-input"
                                name="cmk-description-input"
                                data-testid="cmk-description-input"
                                labelText={t('keystorev2.descriptionInputLabel')}
                                placeholder={t('keystorev2.descriptionPlaceholder')}
                                value={(inputState.description as string) || ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const val = e.target.value || '';
                                    inputActions.setField('description', val);
                                }}
                            />
                            <span className="input-char-counter right-0">
                                {(inputState.description as string)?.length ?? 0} / 100
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-wrap mb-6">
                        <div className="flex-1 relative">
                            <RadioButtonGroup
                                defaultSelected="default-selected"
                                legend="Group Legend"
                                name="radio-button-group"
                                valueSelected={cmk.useExistingKey ? 'useExistingKey' : 'createNewKey'}>
                                <RadioButton
                                    onClick={() => {
                                        setCmk(prevCmk => {
                                            return { ...prevCmk, useExistingKey: true };
                                        });
                                    }}
                                    id="useExistingKey"
                                    labelText={t('keystorev2.useExistingKey')}
                                    value="useExistingKey"
                                    checked={cmk.useExistingKey}
                                />
                                <RadioButton
                                    onClick={() => {
                                        setCmk(prevCmk => {
                                            return { ...prevCmk, useExistingKey: false };
                                        });
                                    }}
                                    id="createNewKey"
                                    labelText={t('keystorev2.createNewKey')}
                                    value="createNewKey"
                                    checked={!cmk.useExistingKey}
                                />
                            </RadioButtonGroup>
                        </div>
                    </div>
                    <div className="flex flex-wrap mb-6 items-end">
                        <div className="flex-1 text-sm name-input-container">
                            <TextInput
                                id="cmk-alias-input"
                                name="cmk-alias-input"
                                data-testid="cmk-alias-input"
                                labelText={t('keystorev2.alias')}
                                placeholder={t('keystore.awsKmsAliasPlaceholder')}
                                invalid={Boolean(inputErrors.awsKmsAlias)}
                                invalidText={inputErrors.awsKmsAlias}
                                value={(inputState.awsKmsAlias as string) || ''}
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    inputActions.validateField({
                                        field: 'awsKmsAlias',
                                        skipNull: true,
                                    });
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    let val = e.target.value || '';
                                    inputActions.setField('awsKmsAlias', val);
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap mb-6">
                        <div className="flex-1 relative">
                            <Checkbox
                                labelText={t('keystorev2.createDefaultDek')}
                                id="createDefaultDek"
                                onClick={() => {
                                    setCmk(prevCmk => {
                                        return { ...prevCmk, createDefaultDek: !prevCmk.createDefaultDek };
                                    });
                                }}
                            />
                        </div>
                    </div>
                </CreateModalBody>
            </CreateModal>
        </>
    );
};

export default AddCmkModal;
