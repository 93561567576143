import React, { useEffect, useState, useRef } from 'react';
import cx from 'classnames';

export interface MenuItem {
    text: string | JSX.Element;
    className?: string;
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | undefined;
    'data-testid'?: string;
}

interface ActuatorProps {
    text: string | JSX.Element;
    className?: string;
    activeClassName?: string;
    'data-testid'?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | undefined;
    'aria-label'?: string;
}

interface OverflowMenuProps {
    actuator: ActuatorProps;
    menuItems: MenuItem[];
    menuClassName?: string;
    stayOpenOnMenuClick?: boolean;
    'data-testid'?: string;
    didClose?: () => void;
    didOpen?: () => void;
}

const OverflowMenu = (props: OverflowMenuProps) => {
    const {
        menuClassName = '',
        actuator,
        menuItems,
        didClose = () => null,
        didOpen = () => null,
        stayOpenOnMenuClick = false,
    } = props;
    const keyAffix = Math.random().toString();
    const [open, setOpen] = useState(false);

    const actuatorRef = useRef(null);
    const menuRef = useRef(null);
    const handleDocumentClick = (e: MouseEvent) => {
        if (
            e.target === actuatorRef.current ||
            //@ts-ignore
            (actuatorRef.current && actuatorRef.current.contains && actuatorRef.current.contains(e.target))
        ) {
            if (open) {
                setOpen(false);
            }
        } else if (
            !stayOpenOnMenuClick ||
            //not one of the menu items...
            (menuRef.current &&
                //@ts-ignore
                menuRef.current.contains &&
                //@ts-ignore
                !menuRef.current.contains(e.target))
        ) {
            setOpen(false);
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    useEffect(() => {
        if (open) {
            didOpen();
        } else {
            didClose();
        }
    }, [open]);

    const {
        text: actuatorText,
        className: actuatorClassName = '',
        activeClassName: actuatorActiveClassName = '',
        onClick: actuatorOnClick,
        ...actuatorRest
    } = actuator;

    return (
        <div className="relative inline-block text-left" data-testid={props['data-testid'] ?? ''}>
            <div>
                <button
                    ref={actuatorRef}
                    type="button"
                    className={cx('inline-flex ', actuatorClassName, {
                        [actuatorActiveClassName ?? '']: open,
                        'border-white': open,
                    })}
                    {...actuatorRest}
                    onClick={e => {
                        if (actuatorOnClick) {
                            actuatorOnClick(e);
                        }
                        setOpen(!open);
                    }}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : 'false'}>
                    {actuator.text}
                </button>
            </div>

            <div
                ref={menuRef}
                className={cx(
                    'origin-top-right absolute right-0 w-40 rounded-md shadow-lg transition ease-in-out duration-100',
                    menuClassName,
                    { hidden: !open }
                )}>
                <div className="bg-white shadow-xs">
                    <div role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        {menuItems.map((m, i) => (
                            <button
                                {...m}
                                key={`${i}-${keyAffix}`}
                                onClick={m.onClick}
                                className={cx(
                                    'text-left block w-full px-4 py-2 text-xs leading-5 text-gray-700 hover:bg-gray-10 hover:text-gray-900 focus:outline-none focus:bg-gray-10 focus:text-gray-900',
                                    m.className
                                )}
                                role="menuitem">
                                {m.text}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OverflowMenu;
