import { Shield } from '@baffle/graphql/src/models';
import client from '.';
import { MAX_PAGE_SIZE } from './clientEnums';

interface HookOpts {
    skip?: boolean;
}

export async function list() {
    return client(`api/v1.0/shields?pagenum=1&pagesize=${MAX_PAGE_SIZE}`);
}

export async function listErrors() {
    return client(`api/v1.0/shield/errors?pagenum=1&pagesize=${MAX_PAGE_SIZE}`);
}

interface ReadShieldOpts extends HookOpts {}

export async function read(id: string, opts: ReadShieldOpts) {
    try {
        const shield = (await client(`api/v1.0/shield/${id}`)) as Shield;
        //adds extra data about shield on the client
        compileShield(shield ?? {});
        return shield;
    } catch (error) {
        throw error;
    }
}

export async function readDebugLevel(id: string, debugLevel: number) {
    return client(`api/v1.0/shield/config/${id}/debug/${debugLevel}`);
}

export async function updateXssValue(id: string, body: Partial<Shield>) {
    return client(`/v2/shield/${id}/configurations/xss`, { body, method: 'PUT' });
}

export async function createRestartShield(id: string) {
    return client(`/api/v1.0/shield/restart/${id}`, {
        method: 'POST',
    });
}

export async function remove(id: string) {
    return client(`api/v1.0/shield/${id}`, { method: 'DELETE' });
}

const compileShield = (shield: Shield) => {
    if (shield.certExpiry && shield.certExpiry > 0) {
        const deltaT = (shield.certExpiry || 0) - Date.now();
        const ttl = 2592000000; //30 days in ms
        shield.expiresSoon = deltaT <= ttl;
    }
};

export default {
    createRestartShield,
    list,
    listErrors,
    read,
    readDebugLevel,
    remove,
    updateXssValue,
};
