import { t } from '@baffle/translate';

const setKeyDisplay = (keyID: number) => {
    switch (keyID) {
        case -1:
            return 2;
        case -2:
            return t('encryption.rle');
        case -3:
            return 'MSK';
        default:
            return keyID;
    }
};
export default setKeyDisplay;
