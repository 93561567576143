import React from 'react';
import { Modal } from 'carbon-components-react';
import { t } from '@baffle/translate';
import { DatabaseStore } from '@baffle/manager/src/stores';
import { Observer } from 'mobx-react-lite';

interface DeleteDatabaseModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: () => void;
}

const DeleteDatabaseModal = ({ open, onClose, onSubmit }: DeleteDatabaseModalProps) => {
    return (
        <Observer>
            {() => {
                return (
                    <Modal
                        id="database-delete-modal"
                        className="modal-container-white"
                        open={open}
                        modalHeading={t('database.deleteDatabaseTitle', {
                            name: DatabaseStore.database?.name,
                        })}
                        danger={true}
                        onRequestClose={onClose}
                        primaryButtonText={t('main.delete')}
                        secondaryButtonText={t('main.cancel')}
                        size="sm"
                        onRequestSubmit={onSubmit}>
                        <p>{t('database.deleteDatabaseCopy')}</p>
                    </Modal>
                );
            }}
        </Observer>
    );
};

export default DeleteDatabaseModal;
