import React from 'react';
import { BaffleIconProps } from '../componentTypes';

const BaffleLogoIcon = ({ color = '#00bab6', width = 20, height = 22, ...rest }: BaffleIconProps) => {
    return (
        <svg {...rest} width={width} height={height}>
            <path
                d="M29.057 14.93a.86.86 0 01.658.273c.19.198.28.42.28.682a.959.959 0 01-.28.678.923.923 0 01-.658.306.923.923 0 01-.657-.306.949.949 0 01-.28-.663c0-.278.089-.5.28-.697a.86.86 0 01.657-.273"
                fill={color}
            />
            <path
                d="M23.437 20.75a.86.86 0 01.658.273c.19.198.28.42.28.682a.959.959 0 01-.28.678.923.923 0 01-.658.306.923.923 0 01-.657-.306.949.949 0 01-.28-.663c0-.278.089-.5.28-.697a.86.86 0 01.657-.273"
                fill={color}
            />
            <path
                d="M17.997 26.57a.86.86 0 01.658.273c.19.198.28.42.28.682a.959.959 0 01-.28.678.923.923 0 01-.658.306.923.923 0 01-.657-.306.949.949 0 01-.28-.663c0-.278.089-.5.28-.697a.86.86 0 01.657-.273"
                fill={color}
            />
            <path
                d="M17.997 3.49a.86.86 0 01.658.273c.19.198.28.42.28.682a.959.959 0 01-.28.678.923.923 0 01-.658.306.923.923 0 01-.657-.306.949.949 0 01-.28-.663c0-.278.089-.5.28-.697a.86.86 0 01.657-.273"
                fill={color}
            />
            <path
                d="M26.247 17.84a.86.86 0 01.658.273c.19.198.28.42.28.682a.959.959 0 01-.28.678.923.923 0 01-.658.306.923.923 0 01-.657-.306.95.95 0 01-.28-.663c0-.278.089-.5.28-.697a.86.86 0 01.657-.273"
                fill={color}
            />
            <path
                d="M20.808 23.66a.86.86 0 01.657.273c.19.198.28.42.28.681a.96.96 0 01-.28.679.925.925 0 01-.657.306.924.924 0 01-.658-.306.95.95 0 01-.28-.663c0-.278.089-.5.28-.697a.86.86 0 01.658-.273"
                fill={color}
            />
            <path
                d="M15.187 6.4a.86.86 0 01.657.273c.192.198.28.42.281.682a.958.958 0 01-.28.678.922.922 0 01-.658.306.923.923 0 01-.657-.306.95.95 0 01-.28-.663c0-.278.089-.5.28-.697a.86.86 0 01.657-.273"
                fill={color}
            />
            <path
                d="M23.44 17.453a1.479 1.479 0 01-1.055-.477 1.551 1.551 0 01-.445-1.075c0-.43.148-.8.445-1.108a1.41 1.41 0 011.055-.443c.417 0 .77.148 1.055.443.296.307.445.677.445 1.108-.011.421-.16.78-.445 1.075a1.479 1.479 0 01-1.055.477"
                fill={color}
            />
            <path
                d="M6.94 23.273a1.479 1.479 0 01-1.055-.477 1.55 1.55 0 01-.445-1.075c0-.43.148-.8.445-1.108a1.412 1.412 0 011.055-.443c.417 0 .77.148 1.055.443.296.307.445.677.445 1.108-.011.421-.16.78-.445 1.075a1.478 1.478 0 01-1.055.477"
                fill={color}
            />
            <path
                d="M12.38 23.273a1.479 1.479 0 01-1.055-.477 1.55 1.55 0 01-.445-1.075c0-.43.148-.8.445-1.108a1.411 1.411 0 011.055-.443c.417 0 .77.148 1.055.443.296.307.445.677.445 1.108-.011.421-.16.78-.445 1.075a1.479 1.479 0 01-1.055.477"
                fill={color}
            />
            <path
                d="M23.44 11.833a1.478 1.478 0 01-1.055-.477 1.551 1.551 0 01-.445-1.075c0-.43.148-.8.445-1.108a1.41 1.41 0 011.055-.443c.417 0 .77.148 1.055.443.296.307.445.677.445 1.108-.011.421-.16.78-.445 1.075a1.478 1.478 0 01-1.055.477"
                fill={color}
            />
            <path
                d="M16.962 8.974a1.395 1.395 0 011.047-.444c.414 0 .763.148 1.047.444.294.306.441.676.441 1.108-.01.42-.158.779-.441 1.075l-5.46 5.817a1.463 1.463 0 01-1.048.477 1.463 1.463 0 01-1.046-.477 1.557 1.557 0 01-.442-1.075c0-.431.147-.801.442-1.108z"
                fill={color}
            />
            <path
                d="M2.535 16.974a1.463 1.463 0 01-1.047.477 1.463 1.463 0 01-1.046-.477A1.557 1.557 0 010 15.899c0-.431.147-.801.442-1.108l5.46-5.817A1.395 1.395 0 016.95 8.53c.414 0 .763.148 1.047.444.294.306.441.676.441 1.108-.01.42-.158.779-.441 1.074z"
                fill={color}
            />
            <g>
                <path
                    d="M11.342 8.974a1.395 1.395 0 011.047-.444c.414 0 .763.148 1.047.444.294.306.441.676.441 1.108-.01.42-.158.779-.441 1.075l-5.46 5.817a1.463 1.463 0 01-1.048.477 1.463 1.463 0 01-1.046-.477 1.557 1.557 0 01-.442-1.075c0-.431.147-.801.442-1.108z"
                    fill={color}
                />
            </g>
            <g>
                <path
                    d="M12.38 29.093a1.48 1.48 0 01-1.055-.477 1.55 1.55 0 01-.445-1.075c0-.43.148-.8.445-1.108a1.41 1.41 0 011.055-.443c.417 0 .77.148 1.055.443.296.307.445.677.445 1.108-.011.421-.16.78-.445 1.075a1.48 1.48 0 01-1.055.477"
                    fill={color}
                />
            </g>
            <g>
                <path
                    d="M12.38 6.013a1.48 1.48 0 01-1.055-.477 1.55 1.55 0 01-.445-1.075c0-.43.148-.8.445-1.108a1.41 1.41 0 011.055-.443c.417 0 .77.148 1.055.443.296.307.445.677.445 1.108-.011.421-.16.78-.445 1.075a1.48 1.48 0 01-1.055.477"
                    fill={color}
                />
            </g>
            <g>
                <path
                    d="M4.13 20.363a1.478 1.478 0 01-1.055-.477 1.55 1.55 0 01-.445-1.075c0-.43.148-.8.445-1.108a1.41 1.41 0 011.055-.443c.417 0 .77.148 1.055.443.297.307.445.677.445 1.108-.011.421-.16.78-.445 1.075a1.479 1.479 0 01-1.055.477"
                    fill={color}
                />
            </g>
            <g>
                <path
                    d="M9.75 20.363a1.479 1.479 0 01-1.055-.477 1.55 1.55 0 01-.445-1.075c0-.43.148-.8.445-1.108a1.41 1.41 0 011.055-.443c.417 0 .77.148 1.055.443.297.307.445.677.445 1.108-.011.421-.16.78-.445 1.075a1.478 1.478 0 01-1.055.477"
                    fill={color}
                />
            </g>
            <g>
                <path
                    d="M26.25 14.543a1.479 1.479 0 01-1.055-.477 1.55 1.55 0 01-.445-1.074c0-.432.148-.802.445-1.109a1.411 1.411 0 011.055-.443c.417 0 .77.148 1.055.443.296.307.445.677.445 1.109a1.55 1.55 0 01-.445 1.074 1.479 1.479 0 01-1.055.477"
                    fill={color}
                />
            </g>
            <g>
                <path
                    d="M19.606 11.883c.282-.295.63-.443 1.041-.443.412 0 .76.148 1.041.443.293.307.44.678.44 1.109-.011.42-.158.779-.44 1.074l-5.477 5.818a1.45 1.45 0 01-1.04.477 1.45 1.45 0 01-1.042-.477 1.562 1.562 0 01-.439-1.075c0-.431.146-.801.44-1.108z"
                    fill={color}
                />
            </g>
            <g>
                <path
                    d="M15.19 32.003a1.479 1.479 0 01-1.055-.477 1.55 1.55 0 01-.445-1.075c0-.43.149-.8.445-1.108a1.411 1.411 0 011.055-.443c.417 0 .77.148 1.055.443.297.307.445.677.445 1.108-.011.421-.16.78-.445 1.075a1.48 1.48 0 01-1.055.477"
                    fill={color}
                />
            </g>
            <g>
                <path
                    d="M15.19 3.103a1.48 1.48 0 01-1.055-.477 1.55 1.55 0 01-.445-1.074c0-.432.149-.802.445-1.109A1.412 1.412 0 0115.19 0c.417 0 .77.148 1.055.443.297.307.445.677.445 1.109-.01.42-.16.778-.445 1.074a1.48 1.48 0 01-1.055.477"
                    fill={color}
                />
            </g>
            <g>
                <path
                    d="M19.606 17.704c.282-.296.63-.444 1.041-.444.412 0 .76.148 1.041.444.293.306.44.676.44 1.108-.011.42-.158.779-.44 1.075l-5.477 5.817c-.293.307-.64.466-1.04.477-.402-.01-.749-.17-1.042-.477a1.562 1.562 0 01-.439-1.075c0-.431.146-.801.44-1.108z"
                    fill={color}
                />
            </g>
            <g>
                <path
                    d="M9.75 26.183a1.479 1.479 0 01-1.055-.477 1.55 1.55 0 01-.445-1.075c0-.43.148-.8.445-1.108a1.412 1.412 0 011.055-.443c.417 0 .77.148 1.055.443.297.307.445.677.445 1.108-.011.421-.16.78-.445 1.075a1.478 1.478 0 01-1.055.477"
                    fill={color}
                />
            </g>
            <g>
                <path
                    d="M20.81 8.923a1.479 1.479 0 01-1.055-.477 1.55 1.55 0 01-.445-1.075c0-.43.148-.8.445-1.108a1.411 1.411 0 011.055-.443c.417 0 .77.148 1.055.443.297.307.445.677.445 1.108-.01.421-.16.78-.445 1.075a1.479 1.479 0 01-1.055.477"
                    fill={color}
                />
            </g>
            <g>
                <path
                    d="M9.75 8.923a1.479 1.479 0 01-1.055-.477A1.55 1.55 0 018.25 7.37c0-.43.148-.8.445-1.108A1.412 1.412 0 019.75 5.82c.417 0 .77.148 1.055.443.297.307.445.677.445 1.108-.011.421-.16.78-.445 1.075a1.478 1.478 0 01-1.055.477"
                    fill={color}
                />
            </g>
        </svg>
    );
};

export default BaffleLogoIcon;
