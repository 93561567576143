import { User } from '@baffle/graphql/src/models';
import client from '.';

export async function readActivationLink(userId: string) {
    return client(`/api/v1.0/user/invite/${userId}`);
}
interface ReadValidateTokenOpts {
    queryParams: {
        ac: string;
    };
}

export async function readValidateToken({ queryParams }: ReadValidateTokenOpts) {
    return client('/api/public/v1.0/user/validate', { queryParams });
}

interface CreateUserInvitePayload {
    data: {
        email: string;
        sendEmail: boolean;
    };
}

export async function createUserInvite({ data }: CreateUserInvitePayload) {
    return client('/api/v1.0/user/register/email', { body: data, method: 'POST' });
}

interface ReadUserInvitePayload {
    queryParams: { sendEmail: boolean };
}

export async function readUserInvite(uid: string, { queryParams }: ReadUserInvitePayload) {
    return client(`/api/v1.0/user/invite/${uid}`, { queryParams });
}

export async function createActivateUser({ data }: { data: Partial<User> }) {
    return client('/api/public/v1.0/user/activate', { body: data, method: 'PUT' });
}

export async function addIbmUser(email: string) {
    return client('/api/v1.0/user', { body: { email: email }, method: 'POST' });
}

export default {
    readActivationLink,
    readValidateToken,
    readUserInvite,
    createUserInvite,
    createActivateUser,
    addIbmUser,
};
