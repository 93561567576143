import React, { useMemo } from 'react';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import { CreateModal, CreateModalBody, CreateModalHeader } from '@baffle/components/src/CreateModal/CreateModal';
import { TextInput, MultiSelect } from 'carbon-components-react';
import roleInputScheme from './RoleInputScheme';
import { RoleStore } from '@baffle/manager/src/stores';
import { RoleInfo, RoleEffects } from '@baffle/graphql/src/models';
import { t } from '@baffle/translate';

interface AddRoleModalProps {
    open: boolean;
    onClose: () => void;
    onSuccess: (roleInfo: RoleInfo) => void;
}

const AddRoleModal = (props: AddRoleModalProps) => {
    const roleInputSchema = useMemo(() => roleInputScheme(RoleStore.roleList as RoleInfo[], RoleStore.role?.id), []);
    //@ts-ignore
    const { inputState, inputErrors, inputActions } = useInputState(roleInputSchema);

    const isValid = inputActions.validateAll(true);

    const onSuccess = () => {
        let roleData = {
            name: inputState.name,
            effects: inputState.effects,
        };
        props.onSuccess(roleData as RoleInfo);
    };

    const onClose = () => {
        inputActions.reset();
        props.onClose();
    };

    // Either Decrypt or mask can be selected. Both cant be selected together.
    // So disable one if the other one is selected and vice versa !
    const setDisabled = (effect: string) => {
        let selectedEffects = inputState.effects as Array<string>;
        if (!selectedEffects) return false;
        if (effect === RoleEffects.DECRYPT && selectedEffects.includes(RoleEffects.MASK)) {
            return true;
        } else if (effect === RoleEffects.MASK && selectedEffects.includes(RoleEffects.DECRYPT)) {
            return true;
        }

        return false;
    };

    return (
        <>
            <CreateModal
                key={JSON.stringify(props)}
                open={props.open}
                onClose={onClose}
                isValid={isValid}
                modalId="add-role-modal"
                primaryButtonText={t('main.create')}
                primaryButtonTheme="primary"
                primaryButton={onSuccess}>
                <CreateModalHeader titleText={t('roles.addRoleTitle')} />
                <CreateModalBody>
                    <div className="flex flex-wrap mb-6 items-end">
                        <div className="flex-1 text-sm name-input-container">
                            <TextInput
                                id="role-name-input"
                                name="role-name-input"
                                data-testid="role-name-input"
                                labelText={t('main.name')}
                                invalid={Boolean(inputErrors.name)}
                                invalidText={inputErrors.name}
                                value={(inputState.name as string) || ''}
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    inputActions.validateField({
                                        field: 'name',
                                        skipNull: true,
                                    });
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    let val = e.target.value || '';
                                    inputActions.setField('name', val);
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap mb-6">
                        <div className="flex-1 relative">
                            <MultiSelect
                                id="role-effects-input-dropdown"
                                name="role-effects-input-dropdown"
                                titleText={t('roles.permissions')}
                                items={RoleStore.effects.map(eff => {
                                    return {
                                        id: eff,
                                        label: eff as string,
                                        'data-testid': `effects-li-${eff}`,
                                        disabled: setDisabled(eff),
                                    };
                                })}
                                label={t('roles.chooseOption')}
                                onChange={(selected: any) => {
                                    let selectedEffects: string[] = [];
                                    if (selected.selectedItems.length) {
                                        for (let i = 0; i < selected.selectedItems.length; i++) {
                                            selectedEffects.push(selected.selectedItems[i].label);
                                        }
                                    }
                                    inputActions.setField('effects', selectedEffects);
                                }}
                            />
                        </div>
                    </div>
                </CreateModalBody>
            </CreateModal>
        </>
    );
};

export default AddRoleModal;
