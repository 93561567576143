import React, { ComponentType, useMemo } from 'react';
import { ListChildComponentProps, VariableSizeList as List } from 'react-window';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { BaseCompoundComponentProps } from '../types/react';
import scrollbarWidth from '@baffle/utilities/src/scrollbarWidth';

export interface AutoSizerSize extends Size {}

export interface AutoSizerChildProps extends BaseCompoundComponentProps {
    width: number;
    height: number;
}
export interface RowProps<T> {
    index: number;
    style: any;
    data: T;
}

export interface TBodyProps extends BaseCompoundComponentProps {
    children: ComponentType<ListChildComponentProps>;
    data: {
        itemData: Array<any>;
        getItemProps?: (i: number, itemData: Array<any>) => any;
    };
    getListProps?: () => any;
    onResize?: (size: Size) => void;
}

export const getDefaultListProps = () => {
    return {
        minWidth: 0,
    };
};

export const TBody = ({ children, data, onResize, getListProps = getDefaultListProps }: TBodyProps) => {
    const { minWidth } = getListProps();

    const scrollBarSize = useMemo(() => scrollbarWidth(), []);

    return (
        <AutoSizer defaultHeight={300} onResize={onResize}>
            {({ height, width }) => {
                return (
                    <List
                        className="bg-white"
                        height={height}
                        width={Math.max(width, minWidth) + scrollBarSize}
                        itemCount={data.itemData.length}
                        itemSize={() => 48}
                        itemData={data}>
                        {children}
                    </List>
                );
            }}
        </AutoSizer>
    );
};

export const getDefaultTableProps = () => {
    return {
        className: 'w-full shadow',
    };
};
interface TableProps extends BaseCompoundComponentProps {
    getTableProps?: () => any;
}
const Table = ({ children, getTableProps = getDefaultTableProps }: TableProps) => (
    <div role="table" {...getTableProps()}>
        {children}
    </div>
);

export default Table;
