import dataProtectionClient from '@baffle/api-client/src/dataProtectionClient';
import { EncryptionMode, EncryptionModeV2, MaskingMode } from '@baffle/graphql/src/models';
import { observable, action, makeObservable } from 'mobx';
import { RequestOpts } from '../types/StoreTypes';

class PolicyStore {
    constructor() {
        makeObservable(this, {
            encryptionModes: observable,
            encryptionModesV2: observable,
            maskingModes: observable,
            selectedMaskMode: observable,
            selectedEncMode: observable,
            setEncryptionModes: action,
            setEncryptionModesV2: action,
            setMaskingModes: action,
            setSelectedMaskMode: action,
            setSelectedEncMode: action,
        });
    }
    encryptionModes = observable<EncryptionMode>([]);
    maskingModes = observable<MaskingMode>([]);
    selectedMaskMode: MaskingMode | null = null;
    selectedEncMode: EncryptionMode | null = null;
    encryptionModesV2 = observable<EncryptionModeV2>([]);

    setEncryptionModes(encModes: EncryptionMode[]) {
        this.encryptionModes.replace(encModes);
    }
    setMaskingModes(maskModes: MaskingMode[]) {
        this.maskingModes.replace(maskModes);
    }
    setSelectedMaskMode(maskMode: MaskingMode | null) {
        this.selectedMaskMode = maskMode;
    }
    setSelectedEncMode(encMode: EncryptionMode | null) {
        this.selectedEncMode = encMode;
    }
    setEncryptionModesV2(encModes: EncryptionModeV2[]) {
        this.encryptionModesV2.replace(encModes);
    }
    async listAll(appId: string) {
        try {
            const modes = await dataProtectionClient.listAll({ appId });
            this.setMaskingModes(modes.maskingModes ?? []);
            this.setEncryptionModes(modes.encryptionModes ?? []);
            return modes;
        } catch (error) {
            throw error;
        }
    }

    async listEncryptionModes(opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first' && this.encryptionModes.length > 0) {
            return;
        }
        try {
            const res = await dataProtectionClient.listEncryptionModes();
            this.setEncryptionModes(res.data);
        } catch (error) {
            throw error;
        }
    }

    async listMaskingModes(opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first' && this.maskingModes.length > 0) {
            return;
        }
        try {
            const res = await dataProtectionClient.listMaskingModes();
            this.setMaskingModes(res.data);
        } catch (error) {
            throw error;
        }
    }

    getMaskingPolicy(id: string) {
        return this.maskingModes.find(e => {
            return e.id === id;
        });
    }

    getEncryptionPolicy(id: string) {
        return this.encryptionModes.find(e => {
            return e.id === id;
        });
    }

    async getSelectedMode(modeID: string, type: string, opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first') {
            return;
        }
        try {
            if (type === 'masking') {
                const maskMode = await dataProtectionClient.getSelectedMode(modeID, 'masking');
                this.setSelectedMaskMode(maskMode);
            } else if (type === 'encryption') {
                const encMode = await dataProtectionClient.getSelectedMode(modeID, 'encryption');
                this.setSelectedEncMode(encMode);
            }
        } catch (error) {
            throw error;
        }
    }

    async listEncryptionModesV2(opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first' && this.encryptionModesV2.length > 0) {
            return;
        }
        try {
            const res = await dataProtectionClient.listEncryptionModesV2();
            this.setEncryptionModesV2(res.data);
        } catch (error) {
            throw error;
        }
    }
}

export default PolicyStore;
