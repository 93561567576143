import React, { useState, useEffect } from 'react';
import Header from '@baffle/components/src/nav/Header';
import Button from '@baffle/components/src/buttons/Button';
import SkeletonText from '@baffle/components/src/skeleton/SkeletonText';
import cx from 'classnames';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { LIST_ALL_DATABASES, GET_DATABASE_COUNT, DELETE_DATABASE } from '@baffle/graphql';
import { t } from '@baffle/translate';
import { Tag, Search, OverflowMenu, OverflowMenuItem } from 'carbon-components-react';
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import CreateIcon from '@material-ui/icons/CreateOutlined';
import Content from '@baffle/components/src/content/Content';
import { Database, DatabaseListItem, DatabaseType } from '@baffle/graphql/src/models';
import './DataBases.scss';
import TH from '@baffle/components/src/table/react-data-grid/TH';
import { stringSort, boolSort } from '@baffle/utilities/src/sorters';
import { triggerResize } from '@baffle/utilities/src/resize';
import AddDatabaseModal from './AddDatabaseModal';
import UpdateDatabaseCertModal from './UpdateDatabaseCertModal';
import ReactDataGrid from 'react-data-grid';
import Toast, { ToastState } from '@baffle/components/src/toasts/Toast';
import { RowCol } from '@baffle/components/src/componentTypes';
import DatabaseSidePanel from '../sidepanel/DatabaseSidePanel';
import usePageHeight from '@baffle/utilities/src/usePageHeight';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Cell from '@baffle/components/src/table/react-data-grid/Cell';
import databaseClient from '@baffle/api-client/src/databaseClient';
import useAwait from '@baffle/api-client/src/useAwait';
import UpdateDatabaseModal from './UpdateDatabaseModal';

const Databases = () => {
    const columns = [
        {
            key: 'header1',
            name: t('database.header1'),
            width: 220,
            formatter: Cell,
            sortable: true,
            sortFn: (a: DatabaseListItem, b: DatabaseListItem) => stringSort(a.type, b.type),
            headerRenderer: <TH idPrefix="databases" />,
        },
        {
            key: 'header2',
            name: t('database.header2'),
            sortable: true,
            formatter: Cell,
            sortFn: (a: DatabaseListItem, b: DatabaseListItem) => stringSort(a.dbName, b.dbName),
            headerRenderer: <TH idPrefix="databases" />,
        },
        {
            key: 'header3',
            name: t('database.header3'),
            sortable: true,
            formatter: Cell,
            sortFn: (a: DatabaseListItem, b: DatabaseListItem) => stringSort(a.hostName, b.hostName),
            headerRenderer: <TH idPrefix="databases" />,
        },
        {
            key: 'alerts',
            name: t('main.alerts'),
            width: 150,
            sortable: true,
            formatter: Cell,
            sortFn: (a: DatabaseListItem, b: DatabaseListItem) =>
                boolSort(Boolean(a?.details?.expiresSoon), Boolean(b?.details?.expiresSoon)),
            headerRenderer: <TH idPrefix="keystores" />,
        },
        {
            key: 'header4',
            name: t('database.header4'),
            width: 60,
            sortable: true,
            formatter: Cell,
            sortFn: (a: DatabaseListItem, b: DatabaseListItem) => boolSort(a.ssl, b.ssl),
            headerRenderer: <TH idPrefix="databases" />,
        },
        {
            key: 'header5',
            name: t('database.header5'),
            width: 80,
            sortable: true,
            formatter: Cell,
            sortFn: (a: DatabaseListItem, b: DatabaseListItem) => boolSort(Boolean(a.status), Boolean(b.status)),
            headerRenderer: <TH idPrefix="databases" />,
        },

        {
            key: 'header6',
            name: '',
            formatter: Cell,
            width: 60,
        },
        //header7 is literally a filler for spacing
        {
            key: 'header7',
            name: ' ',
            formatter: Cell,
            width: 1,
        },
    ];

    const initialModalState = { addDatabase: false, updateCertificate: false, updateDatabase: false, data: {} };
    const [modalsToggled, setModalsToggled] = useState(initialModalState);
    const { run } = useAwait();
    const pageHeight = usePageHeight();

    const pageHeightOffset = 126.99; //126.99 is the header height and search
    const size = Math.floor(pageHeight / 35);
    const [initialized, setInitialized] = useState(false);
    const initialToastState: ToastState = {
        show: false,
        title: '',
        type: 'success',
    };
    const [toast, setToast] = useState(initialToastState);
    const [selectedDB, setSelectedDB]: any = useState(null);
    const { data: countData, refetch: refetchCount } = useQuery(GET_DATABASE_COUNT);
    const { loading, data, refetch: refetchDatabases } = useQuery(LIST_ALL_DATABASES);

    const [deleteDatabase] = useMutation(DELETE_DATABASE);
    const [filtered, setFiltered] = useState({ results: [], query: '' });
    const [sort, setSort] = useState({ direction: 'NONE', key: '' });
    const [dbTypes, setDbTypes] = useState<DatabaseType[]>([]);

    useEffect(() => {
        run(
            databaseClient.getDatabaseTypes().then((types: DatabaseType[]) => {
                setDbTypes(types ?? []);
            })
        );
    }, []);

    const doDeleteDatabase = async (id: string, name: string) => {
        await deleteDatabase({ variables: { id: id } });
        //close the side panel if its open
        if (selectedDB?.id === id) {
            setSelectedDB(null);
            triggerResize(50);
        }
        refetchDatabases();
        setToast({
            show: true,
            type: 'success',
            title: t('database.deleteSuccess', { name: name }),
        });
        setFiltered({ results: [], query: '' });
    };

    const skeletonCol = {
        id: <SkeletonText />,
        header1: <SkeletonText />,
        header2: <SkeletonText />,
        header3: <SkeletonText />,
        header4: <SkeletonText />,
        header5: <SkeletonText />,
        alerts: <SkeletonText />,
        header6: ' ',
        header7: ' ',
    };

    const emptyCol = {
        id: '',
        header1: '',
        header2: '',
        header3: '',
        header4: '',
        header5: '',
        alerts: '',
        header6: ' ',
        header7: ' ',
    };

    if (!initialized && !loading) {
        setInitialized(true);
    }
    const databasesUnfiltered = data?.databases || [];
    const databases = filtered.query && filtered.query != '' ? filtered.results : databasesUnfiltered;

    //sort data
    if (sort.direction !== 'NONE') {
        const header = columns.find(h => h.key === sort.key);
        if (header && header.sortFn) {
            databases.sort((a: DatabaseListItem, b: DatabaseListItem) => {
                if (sort.direction === 'ASC') {
                    return header.sortFn(a, b);
                } else if (sort.direction === 'DESC') {
                    return header.sortFn(b, a);
                }
            });
        }
    }
    const rows =
        loading && !initialized
            ? //add  skeleton row on initial load
              [...Array(Math.floor(1)).keys()].map(() => skeletonCol)
            : databases.map((d: DatabaseListItem, i: number) => ({
                  id: d.id,
                  selectedId: selectedDB?.id,
                  header1: d.type,
                  header2: d.dbName,
                  header3: d.hostName,
                  alerts: d?.details?.expiresSoon ? <Tag type={'red'}>{t('main.alert')}</Tag> : null,
                  header4: Boolean(d.ssl) ? (
                      <CheckCircleIcon data-testid={`${i}-database-ssl-on-icon`} className="w-4 h-4 text-green-60" />
                  ) : (
                      <CancelIcon data-testid={`${i}-database-ssl-off-icon`} className="w-4 h-4 text-red-60" />
                  ),
                  header5: Boolean(d.status) ? (
                      <CheckCircleIcon
                          data-testid={`${i}-database-status-success-icon`}
                          className="w-4 h-4 text-green-60"
                      />
                  ) : (
                      <CancelIcon data-testid={`${i}-database-status-failure-icon`} className="w-4 h-4 text-red-60" />
                  ),
                  header6: (
                      <OverflowMenu
                          menuOffset={{ left: -64 }}
                          className="datagrid-settings"
                          ariaLabel={t('application.overFlowMenu')}
                          onClick={(e: React.MouseEvent) => e.stopPropagation()}
                          renderIcon={() => <MoreVertIcon className="w-4 h-4 text-gray-70" />}>
                          <OverflowMenuItem
                              itemText={
                                  <div className="flex items-center">
                                      <CreateIcon className="mr-1 w-4 h-4" /> {t('database.edit')}
                                  </div>
                              }
                              onClick={async (e: React.MouseEvent) => {
                                  e.stopPropagation();
                                  setModalsToggled({
                                      addDatabase: false,
                                      updateDatabase: true,
                                      updateCertificate: false,
                                      data: { db: d.details },
                                  });
                              }}
                          />

                          <OverflowMenuItem
                              itemText={
                                  <>
                                      <div className="config-menu-delete flex items-center">
                                          <DeleteOutlineIcon className="mr-1 w-4 h-4" /> {t('main.delete')}
                                      </div>
                                  </>
                              }
                              onClick={(e: React.MouseEvent) => {
                                  e.stopPropagation();
                                  doDeleteDatabase(d.id as string, d.dbName);
                              }}
                          />
                      </OverflowMenu>
                  ),
                  header7: ' ',
              }));

    // add skeleton rows as a loading indicator
    if (loading && initialized) {
        rows.push(skeletonCol, skeletonCol, skeletonCol);
    }

    //add empty rows to fill the page, if needed
    if (databases.length < size) {
        const sizeDelta = size - databases.length;
        rows.push(...[...Array(sizeDelta).keys()].map(() => emptyCol));
    }

    const searchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = (e.target.value || '').toLowerCase();
        const results = (data?.databases ?? []).filter(
            (d: DatabaseListItem) =>
                (d.dbName.toLowerCase().includes(query) || d.hostName.toLowerCase().includes(query)) && query !== ''
        );

        setFiltered({ results, query });
    };

    return (
        <>
            {toast.show ? (
                <Toast
                    title={toast.title}
                    type={toast.type}
                    onDismiss={() => setToast({ title: '', show: false, type: 'success' })}
                />
            ) : null}
            <AddDatabaseModal
                databases={databasesUnfiltered}
                onSuccess={(db: Database) => {
                    refetchCount();
                    refetchDatabases();
                    setToast({
                        show: true,
                        type: 'success',
                        title: t('database.addSuccess', { name: db.dbName }),
                    });
                }}
                open={modalsToggled.addDatabase}
                dbTypes={dbTypes}
                onClose={() =>
                    setModalsToggled({
                        addDatabase: false,
                        updateDatabase: false,
                        updateCertificate: false,
                        data: {},
                    })
                }
            />

            <UpdateDatabaseCertModal
                //@ts-ignore
                database={modalsToggled.data.db as DatabaseListItem}
                onSuccess={(db: Database) => {
                    refetchDatabases();
                    setToast({
                        show: true,
                        type: 'success',
                        title: t('database.updateCertSuccess', { name: db.dbName }),
                    });
                }}
                open={modalsToggled.updateCertificate}
                onClose={() =>
                    setModalsToggled({
                        updateDatabase: false,
                        addDatabase: false,
                        updateCertificate: false,
                        data: {},
                    })
                }
            />
            {modalsToggled.updateDatabase ? (
                <UpdateDatabaseModal
                    databases={databasesUnfiltered}
                    //@ts-ignore
                    database={modalsToggled.data.db as DatabaseListItem}
                    onSuccess={(db: Database) => {
                        refetchDatabases();
                        const updatedDB = Object.assign({}, selectedDB, db);
                        setSelectedDB(updatedDB);
                        setToast({
                            show: true,
                            type: 'success',
                            title: t('database.updateSuccess', { name: db.dbName }),
                        });
                    }}
                    open={modalsToggled.updateDatabase}
                    onClose={() =>
                        setModalsToggled({
                            addDatabase: false,
                            updateDatabase: false,
                            updateCertificate: false,
                            data: {},
                        })
                    }
                />
            ) : null}

            <Content>
                <Header>
                    <div className="flex-1 px-4 flex justify-between">
                        <div className="flex-1 flex items-center">
                            <h1 className="text-heading-01 text-gray-100">
                                {t('database.headerName', {
                                    count: countData?.databaseCount ? countData.databaseCount.dbCount : '-',
                                })}
                            </h1>
                        </div>
                    </div>
                    <div className="mx-4 flex text-md items-center h-full">
                        <Button
                            theme="primary"
                            className="h-12 w-40 flex justify-between flex-row-reverse"
                            id="add-database-btn"
                            aria-label={t('database.ariaAddDatabase')}
                            onClick={() =>
                                setModalsToggled({
                                    updateDatabase: false,
                                    addDatabase: true,
                                    updateCertificate: false,
                                    data: {},
                                })
                            }>
                            <AddIcon className="w-4 h-4" /> {t('database.database')}
                        </Button>
                    </div>
                </Header>
                <div className="flex font-normal text-sm leading-primary tracking-primary">
                    <div
                        id="table-container"
                        className={cx('datagrid-container database-datagrid flex-grow', {
                            'with-side-panel': Boolean(selectedDB),
                        })}>
                        <div>
                            <div className="flex flex-wrap">
                                <div className="flex-1 px-4">
                                    <Search
                                        size="sm"
                                        value={filtered.query ?? ''}
                                        closeButtonLabelText={t('main.ariaCloseSearch')}
                                        className="data-grid-search"
                                        labelText={t('database.ariaSearch')}
                                        placeholder={t('database.ariaSearch')}
                                        onChange={searchChange}
                                        id="data-grid-search"
                                        autoComplete="none"
                                    />
                                </div>
                            </div>
                        </div>
                        <ReactDataGrid
                            rowHeight={48}
                            headerFiltersHeight={48}
                            headerRowHeight={48}
                            onRowClick={(idx: number, row: RowCol) => {
                                if (row) {
                                    const db: DatabaseListItem = databases.find(
                                        (db: DatabaseListItem) => db.id === row.id
                                    );
                                    setSelectedDB(db?.details);
                                    triggerResize(50);
                                }
                            }}
                            // @ts-ignore
                            enableCellAutoFocus={false}
                            onGridSort={(key: string, direction: string) => setSort({ direction, key })}
                            //@ts-ignore
                            columns={columns}
                            rowGetter={i => rows[i]}
                            rowsCount={rows.length}
                            minHeight={pageHeight - pageHeightOffset}
                            //@ts-ignore
                            enableRowSelect={null}
                        />
                    </div>
                </div>
            </Content>
            <DatabaseSidePanel
                handleUpdateCert={(db: Database) => {
                    setModalsToggled({
                        addDatabase: false,
                        updateDatabase: false,
                        updateCertificate: true,
                        data: { db },
                    });
                }}
                handleUpdateDatabase={(db: Database) => {
                    setModalsToggled({
                        addDatabase: false,
                        updateDatabase: true,
                        updateCertificate: false,
                        data: { db },
                    });
                }}
                db={selectedDB}
                deleteDatabase={(id: string, name: string) => {
                    doDeleteDatabase(id, name);
                }}
                onClose={() => {
                    setSelectedDB(null);
                    triggerResize();
                }}
            />
        </>
    );
};

export default Databases;
