import React, { useState, useEffect } from 'react';
import { InlineLoading, TextInput } from 'carbon-components-react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@baffle/components/src/modal/Modal';
import Button from '@baffle/components/src/buttons/Button';
import { t } from '@baffle/translate';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import Joi from '@hapi/joi';
import { useSetToast } from '@baffle/components/src/toasts/Toast';
import InlineToast from '@baffle/components/src/toasts/InlineToast';
import currentUserClient from '@baffle/api-client/src/currentUserClient';

interface Props {
    open: boolean;
    onClose?: () => void;
    onSuccess?: () => void;
}

const ChangePasswordModal = (props: Props) => {
    const [open, setOpen] = useState(props.open);
    const [teardownModal, setTeardownModal] = useState(false);
    const { toast, setToast } = useSetToast();
    const [loading, setLoading] = useState(false);
    const { inputState, inputErrors, inputActions } = useInputState({
        ns: 'profile',
        defaultValues: {},
        keys: ['newPassword', 'oldPassword', 'confirmPassword'],
        validationSchema: {
            oldPassword: Joi.string()
                .min(10)
                .required(),
            newPassword: Joi.string()
                .pattern(new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{10,}$'))
                .error(new Error(t('setup.passwordInputInvalid'))),
            confirmPassword: Joi.string()
                .custom(val => {
                    if (val !== inputState.newPassword) {
                        throw new Error(t('setup.passwordMismatch'));
                    }
                    return val;
                })
                .required()
                .error(new Error(t('setup.passwordMismatch'))),
        },
    });

    //Sync state from props
    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const initClose = (clearState?: boolean) => {
        if (loading || !open) {
            return;
        }
        setOpen(false);
        //allow the modal to animate out
        setTimeout(() => {
            closeModal(clearState);
        }, 200);
    };

    const closeModal = (clearState?: boolean) => {
        if (loading) {
            return;
        }
        setToast({ title: '', show: false, type: 'success' });
        if (clearState) {
            inputActions.reset();
            setTeardownModal(true);
            setTimeout(() => setTeardownModal(false), 0);
        }
        if (props.onClose) {
            props.onClose();
        }
    };

    const doChangePassword = async () => {
        setLoading(true);
        try {
            const input = {
                oldPassword: inputState.oldPassword as string,
                newPassword: inputState.newPassword as string,
            };
            await currentUserClient.updatePassword(input);
            if (props.onSuccess) {
                props.onSuccess();
            }
        } catch (error) {
            const msg = error?.message ?? t('session.resetFailed');
            setToast({
                title: msg,
                type: 'danger',
                show: true,
            });
            console.error(error);
        }
        setLoading(false);
    };

    const isValid = inputActions.validateAll(true);
    //hack to remove the input components state
    if (teardownModal) {
        return null;
    }
    return (
        <Modal open={open} onClose={() => initClose(true)} aria-label="modal">
            <ModalHeader onClose={() => closeModal(true)}>
                <div className="flex items-start">
                    <div className="ml-2">
                        <h3 className="text-xl font-medium text-teal" id="modal-headline">
                            {t('profile.addModalHeading')}
                        </h3>
                    </div>
                </div>
            </ModalHeader>
            <ModalBody>
                <div className="px-4">
                    {toast.show ? (
                        <InlineToast
                            className="mb-5"
                            duration={10000}
                            title={toast.title}
                            type={toast.type}
                            onDismiss={() => setToast({ title: '', show: false, type: 'success' })}
                        />
                    ) : null}
                    <p className="mb-6 text-sm">{t('profile.passwordRules')}</p>
                    <div className="mb-6">
                        <TextInput
                            id="old-password-input"
                            labelText={t('profile.oldPassword')}
                            invalid={Boolean(inputErrors.oldPassword)}
                            type="password"
                            invalidText={inputErrors.oldPassword}
                            placeholder={t('profile.oldPasswordPlaceholder')}
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                inputActions.validateField({ field: 'oldPassword', skipNull: true });
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                inputActions.setField('oldPassword', val.trim());
                            }}
                        />
                    </div>
                    <div className="mb-6">
                        <TextInput
                            id="new-password-input"
                            labelText={t('profile.newPassword')}
                            invalid={Boolean(inputErrors.newPassword)}
                            invalidText={inputErrors.newPassword}
                            type="password"
                            placeholder={t('profile.newPasswordPlaceholder')}
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                inputActions.validateField({ field: 'newPassword', skipNull: true });
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                inputActions.setField('newPassword', val.trim());
                            }}
                        />
                    </div>
                    <div className="mb-6">
                        <TextInput
                            id="confirm-password-input"
                            labelText={t('profile.confirmPassword')}
                            invalid={Boolean(inputErrors.confirmPassword)}
                            type="password"
                            invalidText={inputErrors.confirmPassword}
                            placeholder={t('profile.confirmPasswordPlaceholder')}
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                //have to validate all here
                                inputActions.validateField({ field: 'confirmPassword', skipNull: true });
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                inputActions.setField('confirmPassword', val.trim());
                            }}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex justify-end items-center h-16 w-full">
                    <Button className="h-full w-1/2" theme="secondary" onClick={() => initClose(true)} size="lg">
                        {t('main.cancel')}
                    </Button>
                    {loading ? (
                        <InlineLoading description={t('profile.changingPassword')} />
                    ) : (
                        <Button
                            className="h-full w-1/2"
                            data-testid="do-change-password-btn"
                            theme={isValid ? 'primary' : 'disabled'}
                            id="do-change-password-btn"
                            onClick={doChangePassword}
                            size="lg">
                            {t('profile.changePassword')}
                        </Button>
                    )}
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default ChangePasswordModal;
