import React, { useState, useEffect, useRef } from 'react';
import { TextInput, InlineLoading } from 'carbon-components-react';
import Joi from '@hapi/joi';
import Button from '@baffle/components/src/buttons/Button';
import { t } from '@baffle/translate';
import { useHistory } from 'react-router-dom';
import InlineToast, { ToastState, ToastType } from '@baffle/components/src/toasts/InlineToast';
import useEnterKeyPress from '@baffle/utilities/src/useEnterKeyPress';
import { SessionStore } from '../stores';
import FormInputStore from '../../../utilities/src/FormInputStore';
import { Observer } from 'mobx-react-lite';
import { CodeSnippet } from 'carbon-components-react';
import BrokerLogo from '@baffle/components/src/logo/BrokerLogo';
import useAwait from '@baffle/api-client/src/useAwait';

const LoginIBM = () => {
    const { current: sessionForm } = useRef(
        new FormInputStore(
            {
                token: '',
            },
            {
                token: Joi.string()
                    .min(1)
                    .required()
                    .error(new Error(t('session.tokenInputInvalid'))),
            }
        )
    );
    const { run, status, error } = useAwait();
    const history = useHistory();
    const initialToastState: ToastState = {
        show: false,
        title: '',
        type: 'success',
    };
    const [toast, setToast] = useState(initialToastState);

    useEffect(() => {
        if (error) {
            setToast({ show: true, type: 'danger', title: error.message });
        }
    }, [error]);

    useEffect(() => {
        //@ts-ignore
        if (history?.location?.state?.show && history?.location?.state?.type && history?.location?.state?.title) {
            setToast({
                show: true,
                //@ts-ignore
                type: history.location.state.type as ToastType,
                //@ts-ignore
                title: history.location.state.title,
            });
        }
    }, [history.location]);

    const doSignIn = () => {
        run(
            SessionStore.validateIbmLogin(sessionForm.values).then(() => {
                //trigger page refresh on success
                document.location.href = `${document.location.origin}/`;
            })
        );
    };

    useEnterKeyPress(() => {
        if (sessionForm.isValid) {
            doSignIn();
        }
    });

    return (
        <div className="session flex flex-col min-h-screen justify-center items-center bg-blue-900">
            <div className="bg-white p-8 w-132">
                {toast.show ? (
                    <InlineToast
                        className="mb-5"
                        duration={100000}
                        title={toast.title}
                        type={toast.type}
                        onDismiss={() => setToast({ title: '', show: false, type: 'success' })}
                    />
                ) : null}
                <BrokerLogo />
                <h3 className="text-gray-100 font-bold mt-2">{t('session.ibmLoginHeader')}</h3>
                <h4 className="font-medium mt-4">{t('session.ibmLoginTitle')}</h4>
                <p className="text-xs my-4">{t('session.ibmLoginDescription')}</p>
                <CodeSnippet className="code-snippet mb-6" type="single" copyButtonDescription="" feedback="">
                    {t('session.ibmTokenCommand')}
                </CodeSnippet>
                <Observer>
                    {() => {
                        return (
                            <TextInput
                                id={`session-token-input`}
                                labelText={t('session.tokenInputLabel')}
                                placeholder={t('session.tokenInputPlaceholder')}
                                invalid={Boolean(sessionForm.errors.token)}
                                invalidText={sessionForm.errors.token}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const val = e.target.value || '';
                                    sessionForm.setValue('token', val.trim());
                                    sessionForm.validate({ field: 'token' });
                                }}
                            />
                        );
                    }}
                </Observer>
                {status === 'loading' ? (
                    <InlineLoading className="my-6" description={t('session.signingIn')} />
                ) : (
                    <Observer>
                        {() => {
                            return (
                                <Button
                                    className="w-full h-12 mt-8"
                                    theme={sessionForm.isValid ? 'primary' : 'disabled'}
                                    id="do-login-btn"
                                    onClick={doSignIn}>
                                    {t('session.signIn')}
                                </Button>
                            );
                        }}
                    </Observer>
                )}
            </div>
        </div>
    );
};

export default LoginIBM;
