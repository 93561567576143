import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import { t } from '@baffle/translate';

const SystemError = () => {
    return (
        <div className="setup flex flex-col min-h-screen justify-center items-center bg-blue-900">
            <div className="w-132 bg-white">
                <div className="flex flex-wrap items-center py-4 px-8 text-red-60 uppercase text-xl">
                    <WarningIcon />
                    <span className="ml-2">{t('setup.systemError')}</span>
                </div>
                <div className="pb-12 px-8">
                    <div className="my-4 text-sm">
                        <span className="font-medium leading-normal">{t('setup.systemErrorCopy')}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SystemError;
