import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { BaffleIconProps } from '../componentTypes';

const TreeDeleteIcon = ({
    color = '#000000',
    width = 24,
    height = 24,
    viewBox = '0 0 24 24',
    fontSize = 'small',
}: BaffleIconProps) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} fontSize={fontSize}>
            <defs>
                <path
                    d="M9.14285714,9.42857143 L10.4285714,9.42857143 L10.4285714,17.1428571 L9.14285714,17.1428571 L9.14285714,9.42857143 Z M13,9.42857143 L14.2857143,9.42857143 L14.2857143,17.1428571 L13,17.1428571 L13,9.42857143 Z M4,5.57142857 L4,6.85714286 L5.28571429,6.85714286 L5.28571429,19.7142857 C5.28571429,20.4243661 5.86134818,21 6.57142857,21 L16.8571429,21 C17.5672232,21 18.1428571,20.4243661 18.1428571,19.7142857 L18.1428571,6.85714286 L19.4285714,6.85714286 L19.4285714,5.57142857 L4,5.57142857 Z M6.57142857,19.7142857 L6.57142857,6.85714286 L16.8571429,6.85714286 L16.8571429,19.7142857 L6.57142857,19.7142857 Z M9.14285714,3 L14.2857143,3 L14.2857143,4.28571429 L9.14285714,4.28571429 L9.14285714,3 Z"
                    id="path-1"></path>
            </defs>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-1"></use>
                </mask>
                <use fill={color} xlinkHref="#path-1"></use>
            </g>
        </SvgIcon>
    );
};

export default TreeDeleteIcon;
