import React, { memo, useEffect } from 'react';
import { RowProps } from '@baffle/components/src/table/Table';
import Cell, { getDefaultCellProps } from '@baffle/components/src/table/Cell';
import {
    ColumnMetaData,
    CryptoActionType,
    DataProtectionItem,
    KeyDetails,
    SelectedColumn,
} from '@baffle/graphql/src/models';
import LockOpenOutlined from '@material-ui/icons/LockOpenOutlined';
import setKeyDisplay from '@baffle/utilities/src/setKeyDisplay';
import { OverflowMenu, OverflowMenuItem, Checkbox } from 'carbon-components-react';
import { Lookup } from '../../../../types/lookup';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Lock from '@material-ui/icons/Lock';
import { t } from '@baffle/translate';
import cx from 'classnames';
import { RequestStatus } from '@baffle/api-client/src/useAwait';

import FillColumnTableRow from './FillColumnTableRow';
import { Observer } from 'mobx-react-lite';
import { mutateColumn } from '../../../stores/EncryptionStore';
import { getDataProtection, compileDataProtectionItems } from '@baffle/utilities/src/column/dataFormat';
import DataProtectionComboBox from './DataProtectionComboBox';
import memoize from 'fast-memoize';

interface ColumnTableRowType {
    itemData: Array<ColumnMetaData>;
    getItemProps: (
        i: number,
        itemData: Array<ColumnMetaData>
    ) => {
        keys?: KeyDetails[];
        cryptoAction: CryptoActionType;
        sendSelected: (d: ColumnMetaData, replace?: boolean) => void;
        handleSingleCheckbox: (d: ColumnMetaData, index?: number, checked?: boolean) => void;
        handleMultiCheckbox: (d: ColumnMetaData[], index?: number, checked?: boolean) => void;
        selectedColumns: SelectedColumn[];
        selectedColumnsLookup: Lookup;
        requestStatus: RequestStatus;
        renderWhich: 'EMPTY' | 'SKELETON' | 'NORMAL';
        isRecordLevel?: boolean;
        shiftPress?: boolean;
    };
}

export const columnProps = {
    check: {
        className: 'w-16 flex-shrink-0 flex justify-between items-center',
    },
    lock: {
        className: 'w-16 flex-shrink-0 flex justify-between items-center',
    },
    colName: {
        className: 'flex-auto flex-shrink-0 sm:w-24 2xl:w-32',
    },
    dataType: {
        className: 'w-32 flex-shrink-0',
    },
    dataFormat: {
        className: 'w-112 flex-shrink-0',
    },
    keyId: {
        className: 'w-24 flex-shrink-0',
    },
    spacer: {
        className: 'h-full bg-white whitespace-nowrap text-sm border-l border-solid border-gray-300 w-2 flex-shrink-0',
    },
};

const ColumnTableRow = ({ index, style, data }: RowProps<ColumnTableRowType>) => {
    //clone the children and pass the data item as a prop
    const { itemData, getItemProps } = data;
    const {
        renderWhich,
        keys = [],
        cryptoAction,
        sendSelected,
        handleSingleCheckbox,
        handleMultiCheckbox,
        selectedColumnsLookup,
        selectedColumns,
        isRecordLevel,
        shiftPress,
    } = getItemProps(index, itemData);

    if (renderWhich !== 'NORMAL') {
        return <FillColumnTableRow fillWith={renderWhich} style={style} />;
    }

    let col = itemData[index];
    const keyIdItems = keys.map((k: KeyDetails) => ({
        id: k.keyId,
        label: setKeyDisplay(k.keyId) as string,
    }));
    if (isRecordLevel) {
        keyIdItems.push({ id: -2, label: t('encryption.rle') });
    }
    const isSelected = selectedColumnsLookup[col.path];

    useEffect(() => {
        if (isSelected) {
            const tempColumn: ColumnMetaData = JSON.parse(JSON.stringify(col));
            //Use the selected col for operating on if it is selected
            col = (selectedColumns.find(sl => sl.path === col.path) as SelectedColumn) ?? col;
            // TODO Just a patch fix. Need to figure out better solution
            col.encryptionModes = tempColumn.encryptionModes;
            col.maskingModes = tempColumn.maskingModes;
            col.policies = tempColumn.policies;
            col.jsonPolicies = tempColumn.jsonPolicies;
            col.rbacPolicies = tempColumn.rbacPolicies;
        }
    }, [isSelected]);

    return (
        <div
            role="row"
            className={cx('flex items-center h-12', {
                'bg-gray-20': isSelected,
            })}
            style={style}>
            <Cell
                getCellProps={() => {
                    const { className, ...props } = getDefaultCellProps();
                    return {
                        className: `${className} ${columnProps.check.className}`,
                        ...props,
                    };
                }}>
                <Checkbox
                    id={index + '_select_column'}
                    data-testid={`${col.name}_select_column`}
                    aria-label={t('encryption.selectCell')}
                    disabled={cryptoAction === 'encryption' && Boolean(col.touched)}
                    checked={selectedColumnsLookup[col.path] ? true : false}
                    labelText=""
                    onChange={(event: any, { checked, id }: { checked: boolean; id: any }) => {
                        if (cryptoAction === 'encryption' && col.touched) {
                            return;
                        }
                        if (shiftPress) {
                            handleMultiCheckbox(itemData, index, checked);
                        } else {
                            handleSingleCheckbox(col, index, checked);
                        }
                    }}
                />
            </Cell>
            <Cell
                getCellProps={() => {
                    const { className, ...props } = getDefaultCellProps();
                    return {
                        className: `${className} ${columnProps.lock.className}`,
                        ...props,
                    };
                }}>
                {col.touched && !selectedColumnsLookup[col.path] ? (
                    <Lock className="w-4 h-4" />
                ) : selectedColumnsLookup[col.path] ? (
                    <LockOpenOutlined className="w-4 h-4" />
                ) : (
                    ''
                )}
            </Cell>
            <Cell
                getCellProps={() => {
                    const { className, ...props } = getDefaultCellProps();
                    return {
                        className: `${className} ${columnProps.colName.className}`,
                        ...props,
                    };
                }}>
                {col.name}
            </Cell>
            <Cell
                getCellProps={() => {
                    const { className, ...props } = getDefaultCellProps();
                    return {
                        className: `${className} ${columnProps.dataType.className} lowercase`,
                        ...props,
                    };
                }}>
                {col.dataType}
            </Cell>
            <Cell
                getCellProps={() => {
                    const { className, ...props } = getDefaultCellProps();
                    return {
                        className: `${className.replace('truncate', '')} ${columnProps.dataFormat.className}`,
                        ...props,
                    };
                }}>
                <DataFormatCell
                    sendSelected={sendSelected}
                    d={col}
                    i={index}
                    isSelected={isSelected}
                    cryptoAction={cryptoAction}
                />
            </Cell>
            <Cell
                getCellProps={() => {
                    const { className, ...props } = getDefaultCellProps();
                    return {
                        className: `${className} ${columnProps.keyId.className}`,
                        ...props,
                    };
                }}>
                <KeyIdCell
                    d={col}
                    selectedColumnsLookup={selectedColumnsLookup}
                    items={keyIdItems}
                    cryptoAction={cryptoAction}
                    sendSelected={sendSelected}
                    i={index}
                />
            </Cell>
            <Cell
                getCellProps={() => {
                    return {
                        ...getDefaultCellProps(),
                        className: `${columnProps.spacer.className}`,
                    };
                }}>
                {/* empty space cell */}{' '}
            </Cell>
        </div>
    );
};

const KeyIdCell = memo(
    ({
        d,
        selectedColumnsLookup,
        items,
        cryptoAction,
        i,
        sendSelected,
    }: {
        d: ColumnMetaData;
        selectedColumnsLookup: Lookup;
        items: { id: number; label: string }[];
        cryptoAction: CryptoActionType;
        i: number;
        sendSelected: (d: ColumnMetaData, replace?: boolean) => void;
    }) => {
        //default KeyID display option to 2
        let keyId = setKeyDisplay(d.keyID);
        if (!selectedColumnsLookup[d.path]) {
            return <div className="text-gray-unselected">{keyId}</div>;
        }

        return (
            <OverflowMenu
                id={i + '_select_column_keyid'}
                data-testid={i + '_select_keyid'}
                ariaLabel={t('application.overFlowMenu')}
                className="select-keyid w-16 h-7 px-2 border border-solid border-gray-300 hover:bg-white justify-start"
                onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                }}
                renderIcon={() => {
                    return (
                        <>
                            {keyId}
                            <ExpandMoreIcon className="ml-4" />
                        </>
                    );
                }}>
                {items.map((k: any, i: number) => {
                    return (
                        <OverflowMenuItem
                            key={`keyid-${k.id}-${i}`}
                            className="w-16"
                            itemText={k.label}
                            data-testid={i + '_select_keyid_item'}
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation();
                                mutateColumn(d, { keyID: k.id });
                                sendSelected(d, true);
                            }}
                        />
                    );
                })}
            </OverflowMenu>
        );
    }
);

const compileDefaultModeItems = memoize((col: ColumnMetaData) => {
    let defaultItemsCopy = [];
    if (col.selections.policy) {
        defaultItemsCopy.push(Object.assign(col.selections.policy, { type: 'policy' }));
    }
    if (col.selections.jsonPolicy) {
        defaultItemsCopy.push(Object.assign(col.selections.jsonPolicy, { type: 'jsonPolicy' }));
    }
    if (col.selections.rbacPolicy) {
        defaultItemsCopy.push(Object.assign(col.selections.rbacPolicy, { type: 'rbacPolicy' }));
    }
    if (col.selections.encryptionMode) {
        defaultItemsCopy.push(Object.assign(col.selections.encryptionMode, { type: 'encryption' }));
    }
    if (col.selections.maskingMode) {
        defaultItemsCopy.push(Object.assign(col.selections.maskingMode, { type: 'mask' }));
    }
    return defaultItemsCopy;
});

const DataFormatCell = memo(
    ({
        d,
        cryptoAction,
        isSelected,
        sendSelected,
        i,
    }: {
        d: ColumnMetaData;
        cryptoAction: CryptoActionType;
        isSelected: boolean;
        sendSelected: (d: ColumnMetaData, replace?: boolean) => void;
        i: number;
    }) => {
        const dataFormatExists =
            Boolean(d.selections.policy) ||
            Boolean(d.selections.encryptionMode) ||
            Boolean(d.selections.maskingMode) ||
            Boolean(d.selections.rbacPolicy) ||
            Boolean(d.selections.jsonPolicy);
        //return early if column is not selected
        const dataFormatText = getDataProtection(d);
        if (!isSelected || cryptoAction === 'decryption') {
            return <div className="text-gray-unselected">{dataFormatText}</div>;
        }
        //momentarily is null on deselect
        if (!isSelected) {
            return <div className="pl-2">{dataFormatText}</div>;
        }

        return (
            <Observer>
                {() => {
                    const defaultItems: DataProtectionItem[] = dataFormatExists ? compileDefaultModeItems(d) : [];
                    const finalItems: DataProtectionItem[] = [];
                    finalItems.push(
                        ...compileDataProtectionItems(d.encryptionModes ?? [], 'encryption'),
                        ...compileDataProtectionItems(d.maskingModes ?? [], 'mask'),
                        ...compileDataProtectionItems(d.policies ?? [], 'policy'),
                        ...compileDataProtectionItems(d.jsonPolicies ?? [], 'jsonPolicy'),
                        ...compileDataProtectionItems(d.rbacPolicies ?? [], 'rbacPolicy')
                    );
                    return (
                        <DataProtectionComboBox
                            col={d}
                            getToggleButtonProps={() => ({
                                id: i + '_data_protection_ddl',
                                'data-testid': d.name + '_data_protection_ddl',
                            })}
                            defaultItems={defaultItems}
                            onChange={({ selectedItems }) => {
                                if (selectedItems.length) {
                                    for (let i = 0; i < selectedItems.length; i++) {
                                        const selectedItem = selectedItems[i];
                                        switch (selectedItem.type) {
                                            case 'policy':
                                                mutateColumn(d, {
                                                    selections: {
                                                        maskingMode: d.selections.maskingMode,
                                                        encryptionMode: null,
                                                        jsonPolicy: d.selections.jsonPolicy,
                                                        rbacPolicy: d.selections.rbacPolicy,
                                                        policy: {
                                                            id: selectedItem.id,
                                                            name: selectedItem.name,
                                                        },
                                                    },
                                                });
                                                break;
                                            case 'encryption':
                                                mutateColumn(d, {
                                                    selections: {
                                                        policy: null,
                                                        maskingMode: d.selections.maskingMode,
                                                        jsonPolicy: d.selections.jsonPolicy,
                                                        rbacPolicy: d.selections.rbacPolicy,
                                                        encryptionMode: {
                                                            id: selectedItem.id,
                                                            name: selectedItem.name,
                                                        },
                                                    },
                                                });
                                                break;
                                            case 'mask':
                                                mutateColumn(d, {
                                                    selections: {
                                                        policy: d.selections.policy,
                                                        encryptionMode: d.selections.encryptionMode,
                                                        jsonPolicy: d.selections.jsonPolicy,
                                                        rbacPolicy: d.selections.rbacPolicy,
                                                        maskingMode: {
                                                            id: selectedItem.id,
                                                            name: selectedItem.name,
                                                        },
                                                    },
                                                });
                                                break;
                                            case 'jsonPolicy':
                                                mutateColumn(d, {
                                                    selections: {
                                                        policy: d.selections.policy,
                                                        encryptionMode: d.selections.encryptionMode,
                                                        maskingMode: d.selections.maskingMode,
                                                        rbacPolicy: d.selections.rbacPolicy,
                                                        jsonPolicy: {
                                                            id: selectedItem.id,
                                                            name: selectedItem.name,
                                                        },
                                                    },
                                                });
                                                break;
                                            case 'rbacPolicy':
                                                mutateColumn(d, {
                                                    selections: {
                                                        policy: d.selections.policy,
                                                        encryptionMode: d.selections.encryptionMode,
                                                        maskingMode: d.selections.maskingMode,
                                                        rbacPolicy: {
                                                            id: selectedItem.id,
                                                            name: selectedItem.name,
                                                        },
                                                        jsonPolicy: d.selections.jsonPolicy,
                                                    },
                                                });
                                        }
                                    }
                                    sendSelected(d, true);
                                } else {
                                    mutateColumn(d, {
                                        selections: {
                                            policy: null,
                                            maskingMode: null,
                                            encryptionMode: null,
                                            rbacPolicy: null,
                                            jsonPolicy: null,
                                        },
                                    });
                                }
                            }}
                            items={finalItems}
                        />
                    );
                }}
            </Observer>
        );
    }
);

export default ColumnTableRow;
