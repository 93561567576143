import gql from 'graphql-tag';

export const SIGN_OUT = gql`
    mutation SignOut {
        signOut @client
    }
`;

//currently not using, but leaving for formSerializer example
export const SIGN_IN = gql`
    mutation SignIn($input: Object!, $formSerializer: any) {
        signIn(input: $input) @rest(path: "login", method: "POST", endpoint: "v0", bodySerializer: $formSerializer) {
            NoResponse
        }
    }
`;
export const RECOVER_PASSWORD = gql`
    mutation RecoverPassword($input: Object!) {
        recoverPassword(input: $input) @rest(path: "user/password", method: "POST", endpoint: "v2") {
            data
        }
    }
`;
