import React, { useState, useEffect, useMemo } from 'react';
import { t } from '@baffle/translate';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import Alert from '@baffle/components/src/alerts/Alert';
import { SystemEmailStore } from '@baffle/manager/src/stores';
import { SystemInfoEmail } from '@baffle/graphql/src/models';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@baffle/components/src/modal/Modal';
import TextInput from '@baffle/components/src/forms/TextInput';
import Button from '@baffle/components/src/buttons/Button';
import useAwait from '@baffle/api-client/src/useAwait';
import SpinnerOverlay from '@baffle/components/src/loader/SpinnerOverlay';
import createSettingsInputScheme from '../SettingsInputScheme/createSettingsInputScheme';

interface EmailSettingsModalProps {
    open: boolean;
    onClose?: () => void;
    onSuccess?: (action: string) => void;
    existingInfo: boolean;
}

const EmailSettingsModal = ({ open, onClose, onSuccess, existingInfo }: EmailSettingsModalProps) => {
    const [openModal, setOpen] = useState(open);
    const systemEmail: SystemInfoEmail | null = SystemEmailStore.systemEmail;
    const settingsInputSchemes = useMemo(() => createSettingsInputScheme(systemEmail), []);

    //@ts-ignore
    const { inputState, inputErrors, inputActions } = useInputState(settingsInputSchemes.SMTP);

    const { run, status, error, reset } = useAwait();
    const loading = status === 'loading';

    const handleClose = () => {
        if (!openModal) {
            return;
        }
        setOpen(false);
        reset();
        inputActions.reset();
        setTimeout(() => {
            if (onClose) {
                onClose();
            }
        }, 200);
    };

    const doSetSystemEmail = async () => {
        const payload: SystemInfoEmail = settingsInputSchemes.SMTP.keys.reduce((acc: any, c: any) => {
            if (inputState[c]) {
                if (c === 'port' || c === 'retryCount') {
                    acc[c] = +(inputState[c] as string);
                } else {
                    acc[c] = (inputState[c] as string)?.trim();
                }
            }

            return acc;
        }, {});
        run(
            (existingInfo
                ? SystemEmailStore.updateSystemEmail(systemEmail?.id as string, {
                      id: systemEmail?.id as string,
                      ...payload,
                  })
                : SystemEmailStore.createSystemEmail(payload)
            ).then(() => {
                handleClose();
                if (onSuccess) {
                    onSuccess(existingInfo ? 'updated' : 'added');
                }
            })
        );
    };

    //Sync state from props
    useEffect(() => {
        setOpen(open);
    }, [open]);

    const isValid = inputActions.validateAll(true);

    return (
        <Modal open={openModal} onClose={handleClose}>
            {loading && <SpinnerOverlay description={t('settings.configuringSmtp')} />}
            <ModalHeader onClose={handleClose}>
                <div className="flex items-start">
                    <div className="mt-0 ml-2">
                        <h3 className="text-xl font-medium text-gray-100" id="modal-headline">
                            {existingInfo
                                ? t('settings.editEmailSettingsModalHeading')
                                : t('settings.emailSettingsModalHeading')}
                        </h3>
                    </div>
                </div>
            </ModalHeader>
            <ModalBody>
                <div className="flex flex-col px-4">
                    {error ? (
                        <div className="flex flex-wrap mb-4">
                            <div className="flex-1 px-4">
                                <Alert
                                    type="danger"
                                    title={
                                        existingInfo
                                            ? t('settings.editEmailConfigError')
                                            : t('settings.configureEmailError')
                                    }
                                    description={error?.message || ''}
                                />
                            </div>
                        </div>
                    ) : null}
                    <div className="mb-6 flex gap-x-4">
                        <div className="w-2/3">
                            <TextInput
                                id="setup-host-input"
                                name="setup-host-input"
                                labelText={t('setup.hostInputLabel')}
                                placeholder={t('setup.hostInputPlaceholder')}
                                invalid={Boolean(inputErrors.host)}
                                invalidText={inputErrors.host}
                                value={inputState.host || ''}
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    inputActions.validateField({ field: 'host', skipNull: true });
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const val = e.target.value || '';
                                    inputActions.setField('host', val.trim());
                                }}
                            />
                        </div>
                        <div className="w-1/3">
                            <TextInput
                                id="setup-port-input"
                                name="setup-port-input"
                                labelText={t('setup.portInputLabel')}
                                placeholder={t('setup.portInputPlaceholder')}
                                invalid={Boolean(inputErrors.port)}
                                invalidText={inputErrors.port}
                                value={inputState.port || ''}
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    inputActions.validateField({ field: 'port', skipNull: true });
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const val = e.target.value || '';
                                    inputActions.setField('port', val.trim());
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex gap-x-4 pb-6">
                        <TextInput
                            id="setup-username-input"
                            name="setup-username-input"
                            labelText={t('setup.usernameInputLabel')}
                            placeholder={t('setup.usernameInputPlaceholder')}
                            invalid={Boolean(inputErrors.username)}
                            invalidText={inputErrors.username}
                            value={inputState.username || ''}
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                inputActions.validateField({ field: 'username', skipNull: true });
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                inputActions.setField('username', val.trim());
                            }}
                        />
                        <TextInput
                            id="setup-password-input"
                            name="setup-password-input"
                            labelText={t('setup.emailPasswordInputLabel')}
                            placeholder={t('setup.emailPasswordInputPlaceholder')}
                            invalid={Boolean(inputErrors.password)}
                            invalidText={inputErrors.password}
                            type="password"
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                inputActions.validateField({ field: 'password', skipNull: true });
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                inputActions.setField('password', val.trim());
                            }}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex justify-end items-center h-16 w-full">
                    <Button theme="secondary" className="h-full w-1/2" onClick={() => handleClose()} size="lg">
                        {t('main.cancel')}
                    </Button>
                    <Button
                        data-testid="do-add-email-settings-btn"
                        className="h-full w-1/2"
                        theme={isValid ? 'primary' : 'disabled'}
                        id="do-add-email-settings-btn"
                        disabled={!isValid}
                        onClick={doSetSystemEmail}
                        size="lg">
                        {t('main.save')}
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default EmailSettingsModal;
