import { BaseCredentials } from './credentials';

export interface SystemInfo {
    initPass?: string;
    publicAccess: string;
    proxyAccess: string;
    domain: string;
    organization: string;
    id: string;
}

export interface ApplicationAccessData {
    locked: boolean;
    environment: 'STANDARD' | 'IBM_CLOUD';
    additionalFeatures: { [key: string]: string };
}

export interface SystemInfoRes {
    tenant: string;
    domain: string;
}

export interface SystemInfoEmail extends BaseCredentials {
    id?: string;
    host: string;
    port: number;
    protocol: string;
    retryCount?: number;
}

export interface SystemVersionInfo {
    bm: string;
}

export interface SuperAdminInfo {
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string | null;
    password: string | null;
    confirmPassword: string | null;
}

export interface CredentialStoreInfo {
    secretKey: string;
    configPassword: string;
    confirmPassword: string;
}

export interface CredentialStoreData {
    name: string;
    type: string;
    details: CredentialStoreInfo;
}

export interface InitialConfiguration {
    keystoreDetails?: CredentialStoreData;
    superAdminDetails: SuperAdminInfo;
    systemSettings: SystemInfo;
}

export interface HttpsCertificate {
    id: string;
    subject: string;
    authority: string;
    expiry: number;
    issuer: string;
    certificatePath: string;
}

export interface LoggerConfiguration {
    id: string;
    name: string;
    logLevel: string;
}

export const LogLevelItems = [
    {
        id: 'DEBUG',
        label: 'DEBUG',
    },
    {
        id: 'INFO',
        label: 'INFO',
    },
    {
        id: 'WARN',
        label: 'WARN',
    },
    {
        id: 'ERROR',
        label: 'ERROR',
    },
    {
        id: 'TRACE',
        label: 'TRACE',
    },
];
