import React, { useEffect } from 'react';
import { ShieldEnums } from '@baffle/utilities/src/enums';
import SyncIcon from '@material-ui/icons/Sync';
import RightPanel, {
    PanelModule,
    PanelTitle,
    TwoColTableModule,
    TwoColTableModuleRow,
} from '@baffle/components/src/content/RightPanel';
import { t } from '@baffle/translate';
import { Shield, ShieldListItem } from '@baffle/graphql/src/models';
import ErrorIcon from '@material-ui/icons/Error';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ShieldErrors from '../shields/ShieldErrors';
import { ShieldStore, AgentErrorStore, FeatureFlagStore } from '../stores';
import { Observer } from 'mobx-react-lite';
import { format } from '@baffle/utilities/src/date';
import SkeletonText, { SkeletonParagraph } from '@baffle/components/src/skeleton/SkeletonText';
import useAwait from '@baffle/api-client/src/useAwait';
import logger from '@baffle/utilities/src/logger';
import ShieldAdvanceConfiguration from './ShieldAdvanceConfiguration';
import ShieldLogsAction from '../shields/ShieldLogsAction';

interface ShieldSidePanelProps {
    shieldListItem: ShieldListItem | null;
    onClose?: () => void;
    deleteShield?: (id: string, name: string) => void;
    onCloseImmediate?: () => void;
    triggerDismissAllNotifications?: (shieldListItem: ShieldListItem) => void;
    handleRestartShield?: () => void;
}
const ShieldSidePanel = ({
    shieldListItem,
    onClose = () => null,
    deleteShield = (id: string, name: string) => null,
    onCloseImmediate = () => null,
    triggerDismissAllNotifications = (shieldListItem: ShieldListItem) => null,
    handleRestartShield = () => null,
}: ShieldSidePanelProps) => {
    const { run, status, error } = useAwait();
    const getShield = async (id?: string) => {
        if (Boolean(id)) {
            run(
                Promise.all([
                    ShieldStore.read(id as string, { resource: 'Shield' }),
                    AgentErrorStore.list({ agentId: id }),
                ])
            );
        }
    };
    useEffect(() => {
        if (error) {
            logger.error(error);
        }
    }, [error]);

    //refetch shields when the id changes
    useEffect(() => {
        getShield(shieldListItem?.id);
    }, [shieldListItem?.id]);

    return (
        <Observer>
            {() => {
                const shield = ShieldStore.shields.find(s => s.id === shieldListItem?.id) ?? ({} as Partial<Shield>);
                const shieldErrors = AgentErrorStore.get({ agentId: shieldListItem?.id });
                //poll shield to get logfile prop updates or debug prop updates
                useEffect(() => {
                    return shield?.id &&
                        (!Boolean(shield?.logFileProps?.enable) || !Boolean(shield?.debugProps?.enable))
                        ? ShieldStore.subscribe(
                              [
                                  {
                                      resource: 'Shield',
                                      id: shieldListItem?.id,
                                  },
                              ],
                              'ShieldSidePanel' //component that is subcribed, to hold components accountable down the road
                          )
                        : () => {};
                }, [shield?.id, shield?.logFileProps?.enable, shield?.debugProps?.enable]);

                const detailsRows: TwoColTableModuleRow[] =
                    status === 'loading' || status === 'idle'
                        ? []
                        : [
                              {
                                  'data-testid': 'shield-added-on-text',
                                  cols: [
                                      t('main.addedOn'),
                                      format(shield?.createTime ?? '', 'yyyy-M-d H:mm:ss') as string,
                                  ],
                              },
                              {
                                  'data-testid': 'shield-created-by-text',
                                  cols: [t('main.createdBy'), shield.createdBy as string],
                              },
                          ];
                if (detailsRows.length !== 0 && shield.updateTime) {
                    detailsRows.push({
                        'data-testid': 'shield-update-on-text',
                        cols: [t('main.updatedOn'), format(shield?.updateTime ?? '', 'yyyy-M-d H:mm:ss') as string],
                    });
                }
                const shieldDetailsRows: TwoColTableModuleRow[] =
                    status === 'loading' || status === 'idle'
                        ? []
                        : [
                              {
                                  'data-testid': 'shield-application-text',
                                  cols: [
                                      t('shield.application'),
                                      shield?.applications?.length ? shield.applications[0] : t('main.na'),
                                  ],
                              },
                              {
                                  'data-testid': 'shield-deploymentmodel-text',
                                  cols: [t('shield.deploymentModel'), shield.deploymentModel as string],
                              },
                              {
                                  'data-testid': 'shield-port-text',
                                  cols: [
                                      t('main.port'),
                                      typeof shield?.port === 'number' ? shield?.port?.toString() : ('8444' as string),
                                  ],
                              },
                          ];
                if (shield?.tag && shieldDetailsRows.length !== 0) {
                    shieldDetailsRows.push({
                        'data-testid': 'shield-tag-text',
                        cols: [t('shield.tag'), shield.tag],
                    });
                }
                if (shield.deploymentModel === ShieldEnums.CLOUD) {
                    shieldDetailsRows.push(
                        {
                            'data-testid': 'shield-cloud-text',
                            cols: [t('shield.deploymentType'), t('shield.cloud')],
                        },
                        {
                            'data-testid': 'shield-cloud-template-text',
                            cols: [t('shield.cloudTemplate'), shield?.cloudId as string],
                        }
                    );
                }

                const configMenuItems = [
                    {
                        item: (
                            <div className="config-menu-delete flex items-center">
                                <DeleteOutlineIcon /> {t('main.delete')}
                            </div>
                        ),
                        onClick: () => {
                            deleteShield(shield.id as string, shield.shieldName as string);
                        },
                    },
                ];

                configMenuItems.unshift({
                    item: (
                        <div className="flex items-center">
                            <SyncIcon fontSize="small" /> {t('shield.restart')}
                        </div>
                    ),
                    onClick: () => {
                        handleRestartShield();
                    },
                });

                const didClearNotification = () => {
                    getShield(shieldListItem?.id);
                };

                return (
                    <RightPanel
                        data-testid="shield-sidepanel"
                        configMenuItems={configMenuItems}
                        skeleton={status === 'loading' || status === 'idle'}
                        title={shield?.shieldName as string}
                        onClose={onClose}
                        onCloseImmediate={onCloseImmediate}>
                        <PanelModule title={t('main.details')} data-testid="shield-main-details">
                            {status === 'loading' || status === 'idle' ? (
                                <SkeletonParagraph />
                            ) : (
                                <TwoColTableModule rows={detailsRows} />
                            )}
                        </PanelModule>
                        <PanelModule
                            data-testid="shield-description"
                            title={t('main.description')}
                            description={
                                status === 'loading' || status === 'idle' ? <SkeletonText /> : shield.shieldDescription
                            }
                        />
                        <PanelModule title={t('shield.details')} data-testid="shield-details">
                            {status === 'loading' || status === 'idle' ? (
                                <SkeletonParagraph />
                            ) : (
                                <TwoColTableModule rows={shieldDetailsRows} />
                            )}
                        </PanelModule>
                        {shieldListItem?.deploymentModel !== 'MANUAL' && (
                            <PanelModule title={t('shield.debugLevelLabel')}>
                                {status === 'loading' || status === 'idle' ? (
                                    <SkeletonParagraph />
                                ) : (
                                    <ShieldLogsAction
                                        requestDebugLevelStarted={() => {}}
                                        requestDebugLevelCompleted={() => {
                                            getShield(shieldListItem?.id);
                                        }}
                                        shield={shield}
                                    />
                                )}
                            </PanelModule>
                        )}
                        {shieldListItem?.ssl ? (
                            <PanelModule title={t('shield.certificate')} data-testid="shield-ssl">
                                <div className="flex flex-col">
                                    <div className="flex justify-between">
                                        <div>
                                            {shield.expiresSoon ? (
                                                <div
                                                    data-testid="shield-cert-expires-soon"
                                                    className="text-red mb-2 flex items-center justify-content">
                                                    <ErrorIcon className="w-4 h-4" />
                                                    <p className="ml-1 text-xs truncate">
                                                        {t('shield.certExpiresOn', {
                                                            expires: format(shield?.certExpiry ?? '', 'M-d-yyyy'),
                                                        })}
                                                    </p>
                                                </div>
                                            ) : null}
                                            <table className="table-fixed">
                                                <tbody>
                                                    <tr>
                                                        <td className="text-gray-600 px-1">
                                                            {t('shield.certIssuerDN')}:
                                                        </td>
                                                        <td className="px-1" data-testid="shield-certIssuerDN-text">
                                                            {shield.certIssuerDN}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-gray-600 px-1">
                                                            {t('shield.certSubjectSAN')}:
                                                        </td>
                                                        <td className="px-1" data-testid="shield-certSubjectSAN-text">
                                                            {shield.certSubjectSAN}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-gray-600 px-1">
                                                            {t('shield.certSubjectDN')}:
                                                        </td>
                                                        <td className="px-1" data-testid="shield-certSubjectDN-text">
                                                            {shield.certSubjectDN}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-gray-600 px-1">{t('main.expiresOn')}:</td>
                                                        <td className="px-1" data-testid="shield-certExpiry-text">
                                                            {format(shield?.certExpiry ?? '', 'M-d-yyyy')}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </PanelModule>
                        ) : null}
                        {FeatureFlagStore.advancedConfiguration ? <ShieldAdvanceConfiguration shield={shield} /> : null}
                        {shieldErrors.length ? (
                            <PanelModule data-testid="shield-alerts">
                                <PanelTitle>
                                    {`${t('shield.alerts')} (${shieldErrors.length})`}
                                    <button
                                        onClick={() => {
                                            triggerDismissAllNotifications(shieldListItem as ShieldListItem);
                                        }}
                                        className="bx--btn--icon-only-transparent text-red-60 text-xs">
                                        {t('shield.clearAll')}
                                    </button>
                                </PanelTitle>
                                <div className="mt-4 overflow-y-scroll">
                                    <ShieldErrors
                                        idPrefix="sidepanel"
                                        didClearNotification={didClearNotification}
                                        agentErrors={shieldErrors}
                                    />
                                </div>
                            </PanelModule>
                        ) : null}
                    </RightPanel>
                );
            }}
        </Observer>
    );
};

export default ShieldSidePanel;
