import { Role } from '@baffle/graphql/src/models';

export const DatabaseEnums = {
    POSTGRES: 'POSTGRES',
    RDS_POSTGRES: 'RDS_POSTGRES',
    RDS_AURORA_MYSQL: 'RDS_AURORA_MYSQL',
    MARIADB: 'MARIADB',
    AZURE_MSSQL: 'AZURE_MSSQL',
    AZURE_MYSQL: 'AZURE_MYSQL',
    CASSANDRA: 'CASSANDRA',
    MYSQL: 'MYSQL',
    SQL_SERVER: 'SQL_SERVER',
    RDS_MARIADB: 'RDS_MARIADB',
    RDS_MYSQL: 'RDS_MYSQL',
    RDS_MSSQL: 'RDS_MSSQL',
    AWS_REDSHIFT: 'AWS_REDSHIFT',
    GREENPLUM: 'GREENPLUM',
};

export const DatabaseDefaultPorts = {
    [DatabaseEnums.POSTGRES]: 5432,
    [DatabaseEnums.RDS_POSTGRES]: 5432,
    [DatabaseEnums.RDS_AURORA_MYSQL]: 3306,
    [DatabaseEnums.RDS_MYSQL]: 3306,
    [DatabaseEnums.AZURE_MSSQL]: 1433,
    [DatabaseEnums.AZURE_MYSQL]: 3306,
    [DatabaseEnums.MARIADB]: 3306,
    [DatabaseEnums.RDS_MARIADB]: 3306,
    [DatabaseEnums.MYSQL]: 3306,
    [DatabaseEnums.CASSANDRA]: 7000,
    [DatabaseEnums.SQL_SERVER]: 1433,
    [DatabaseEnums.RDS_MSSQL]: 1433,
    [DatabaseEnums.AWS_REDSHIFT]: 5439,
    [DatabaseEnums.GREENPLUM]: 5432,
};

export const KeyStoreEnums = {
    LOCAL: 'LOCAL',
    AWS_KMS: 'AWS_KMS',
    SAFENET_KEY_SECURE: 'SAFENET_KEY_SECURE',
    CLOUD_HSM: 'CLOUD_HSM',
    AZURE: 'AZURE',
    HASHI_VAULT: 'HASHI_VAULT',
    GENERIC_HSM: 'GENERIC_HSM',
};

export const InstanceList = ['t2.micro', 't2.small', 't2.large', 't2.xlarge', 'm4.xlarge', 'm4.4xlarge'];
export const AWSRegionList = [
    'us-gov-west-1',
    'us-gov-east-1',
    'us-east-1',
    'us-east-2',
    'us-west-1',
    'us-west-2',
    'eu-west-1',
    'eu-west-2',
    'eu-west-3',
    'eu-central-1',
    'eu-north-1',
    'ap-east-1',
    'ap-south-1',
    'ap-southeast-1',
    'ap-southeast-2',
    'ap-northeast-1',
    'ap-northeast-2',
    'sa-east-1',
    'cn-north-1',
    'cn-northwest-1',
    'ca-central-1',
];

export const IBMRegionList = ['us-south', 'us-east', 'eu-gb', 'eu-de', 'au-syd', 'jp-tok', 'jp-osa'];

export const DEKStorageType = {
    AWS_S3_BUCKET: 'AWS S3 bucket',
    // AWS_SECRETS_MANAGER: 'AWS Secrets Manager',
};

export const SHIELD_STATES = {
    PROCESSING: 'Processing',
    RUNNING: 'Running',
    ERROR: 'Error',
    ERROR_1: 'Error_1',
    ERROR_2: 'Error_2',
    STOPPED: 'Stopped',
    UNINSTALLING: 'Uninstalling',
    INSTALLING: 'Installing',
    IDLE: 'Idle',
};

export const ShieldEnums = {
    SSH: 'SSH',
    CLOUD: 'AWSCF',
    MANUAL: 'MANUAL',
    GENERATE: 'generate',
    UPLOAD: 'upload',
    SINGLE_INSTANCE: 'SINGLE_INSTANCE',
    AUTOSCALED_ELB: 'AUTOSCALED_ELB',
    Shield: 'Shield',
    ShieldListItem: 'ShieldListItem',
};

export const ApplicationEnums = {
    SUCCESS: 'SUCCESS',
    INITIATED: 'INITIATED',
    IN_PROGRESS: 'IN_PROGRESS',
    FAILED: 'FAILED',
    STARTED: 'STARTED',
    NA: 'NA',
    //to map the bool value to enum
    encTypeMap: {
        true: 'record_level',
        false: 'column_level',
    },
    RECORD_LEVEL: 'RECORD_LEVEL',
    COLUMN_LEVEL: 'COLUMN_LEVEL',
    Advanced: 'Advanced',
    Standard: 'Standard',
    statusMap: {
        INITIATED: 'migrating',
        STARTED: 'migrating',
        IN_PROGRESS: 'migrating',
        FAILED: 'failed',
        SUCCESS: 'protected',
        NA: 'enrolled',
    },
};

export const SessionEnums = {
    NG_STORAGE_AUTH_TOKEN: 'ngStorage-authToken',
    NG_STORAGE_REFRESH_TOKEN: 'ngStorage-refreshToken',
    NG_STORAGE_USER: 'ngStorage-user',
    NG_STORAGE_AUTH_TOKEN_EXPIRY: 'ngStorage-authTokenExpiry',
    NG_STORAGE_IDLE_TIME_EXPIRY: 'ngStorage-idleTimeExpiry',
    NG_STORAGE_CLIENT_ENV: 'ngStorage-clientEnv',
    NG_STORAGE_ADVANCED_ENC_MODE: 'ngStorage-advancedEncMode',
};

export const Roles: Role[] = [
    { id: '5d389d27927f5a0c6e76bd90', name: 'ROLE_DOMAIN_ADMIN' },
    { id: '5d389d27927f5a0c6e76bd91', name: 'ROLE_APPLICATION_ADMIN' },
    { id: '5d389d27927f5a0c6e76bd92', name: 'ROLE_KEY_STORE_ADMIN' },
    { id: '5d389d27927f5a0c6e76bd93', name: 'ROLE_DATABASE_ADMIN' },
    { id: '5d389d27927f5a0c6e76bd94', name: 'ROLE_SECURITY_ADMIN' },
    { id: '5d389d27927f5a0c6e76bd96', name: 'ROLE_SYSTEM_ADMIN' },
    { id: '5d389d27927f5a0c6e76bd95', name: 'ROLE_DOMAIN_AUDITOR' },
    { id: '5d389d27927f5a0c6e76bd97', name: 'ROLE_AUDITOR' },
];

export const InitEnums = {
    BAFFLE_TENANT: 'baffle-tenant',
    BAFFLE_ADMIN_USER: 'baffle-admin-user',
};

export const StatusMaps = {
    appStatusMap: {
        INITIATED: 'migrating',
        STARTED: 'migrating',
        IN_PROGRESS: 'migrating',
        FAILED: 'failed',
        SUCCESS: 'protected',
        Enrolled: 'enrolled',
        NA: 'enrolled',
    },
    shieldStatusMap: {
        [SHIELD_STATES.ERROR]: 'danger',
        [SHIELD_STATES.ERROR_1]: 'danger',
        [SHIELD_STATES.ERROR_2]: 'danger',
        [SHIELD_STATES.RUNNING]: 'success',
        [SHIELD_STATES.UNINSTALLING]: 'progress',
        [SHIELD_STATES.INSTALLING]: 'progress',
        [SHIELD_STATES.PROCESSING]: 'progress',
        [SHIELD_STATES.STOPPED]: 'idle',
        [SHIELD_STATES.IDLE]: 'idle',
    },
    statusIconMap: {
        INITIATED: 'progress',
        STARTED: 'progress',
        IN_PROGRESS: 'progress',
        FAILED: 'danger',
        SUCCESS: 'success',
        Enrolled: 'idle',
        NA: 'idle',
    },
};
