import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@baffle/components/src/modal/Modal';
import { t } from '@baffle/translate';
import Button from '@baffle/components/src/buttons/Button';
import WarningIcon from '@material-ui/icons/Warning';
import useAwait from '@baffle/api-client/src/useAwait';
import agentClient from '@baffle/api-client/src/agentClient';
import Alert from '@baffle/components/src/alerts/Alert';

interface DismissAllShieldNotificationsModalProps {
    open: boolean;
    onClose?: () => void;
    onSuccess: (resourceId?: string) => void;
    prefix?: string;
    resourceId?: string;
}
const DismissAllShieldNotificationsModal = (props: DismissAllShieldNotificationsModalProps) => {
    const { prefix = '' } = props;
    const [open, setOpen] = useState(props.open);
    const [teardownModal, setTeardownModal] = useState(false);

    const { run, status, error } = useAwait();
    //Sync state from props
    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const closeModal = (clearState?: boolean) => {
        if (clearState) {
            setTeardownModal(true);
            setTimeout(() => setTeardownModal(false), 0);
        }
        setOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    };
    useEffect(() => {
        if (status === 'success') {
            props.onSuccess(props.resourceId);
            closeModal(true);
        }
    }, [status]);

    if (teardownModal) {
        return null;
    }
    return (
        <Modal
            open={open}
            onClose={() => closeModal()}
            data-testid={`${prefix}dismiss-all-shield-notifications-modal`}
            aria-label="modal">
            <>
                <ModalHeader onClose={() => closeModal(true)}>
                    <div className="flex items-start">
                        <div className="flex items-center justify-center ml-2 text-red">
                            <WarningIcon className="mr-2" />
                            <h3 className="text-xl font-medium" id="modal-headline">
                                {t('notifications.dismissAll')}
                            </h3>
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <>
                        {error ? (
                            <div className="flex flex-wrap">
                                <div className="flex-1 px-4">
                                    <Alert
                                        type="danger"
                                        title={t('main.unexpectedError')}
                                        description={error.message || ''}
                                    />
                                </div>
                            </div>
                        ) : null}
                        <div className="flex flex-wrap items-start">
                            <div className="flex-1 px-4">
                                <div className="mb-12">
                                    <p className="my-2">{t('notifications.dismissAllDisclaimer')}</p>
                                </div>
                            </div>
                        </div>
                    </>
                </ModalBody>
                <ModalFooter>
                    <div className="flex justify-end items-center h-16 w-full">
                        <Button theme="secondary" className="h-full w-1/2" onClick={() => closeModal(true)} size="lg">
                            {t('main.cancel')}
                        </Button>
                        <Button
                            theme="danger"
                            className="h-full w-1/2"
                            id="do-dismiss-all-notifications-btn"
                            onClick={() => {
                                if (status !== 'loading') {
                                    run(agentClient.update());
                                }
                            }}
                            size="lg">
                            {status === 'loading' ? `${t('main.loading')}...` : t('notifications.clearAlerts')}
                        </Button>
                    </div>
                </ModalFooter>
            </>
        </Modal>
    );
};
export default DismissAllShieldNotificationsModal;
