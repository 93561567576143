import React, { useState, useEffect, useMemo } from 'react';
import Button from '@baffle/components/src/buttons/Button';
import TextInput from '@baffle/components/src/forms/TextInput';
import { InputActions, InputError, InputState, useInputState } from '@baffle/utilities/src/inputHooks';
import { Modal, ModalBody, ModalFooter, ModalHeader, SkeletonModal } from '@baffle/components/src/modal/Modal';
import { t } from '@baffle/translate';
import Alert from '@baffle/components/src/alerts/Alert';
import systemClient from '@baffle/api-client/src/systemClient';
import { SystemInfo } from '@baffle/graphql/src/models';
import { ToastStore } from '@baffle/manager/src/stores/ToastStore';
import useAwait from '@baffle/api-client/src/useAwait';
import createSettingsInputScheme from '../SettingsInputScheme/createSettingsInputScheme';

interface SystemSettingsProps {
    open: boolean;
    onClose?: () => void;
    systemInformation: SystemInfo;
}

const SystemSettingsModal = ({ open, onClose = () => null, systemInformation }: SystemSettingsProps) => {
    const [openModal, setOpen] = useState(open);
    const { status, run, error, reset } = useAwait();
    const settingsInputSchemes = useMemo(() => createSettingsInputScheme(), []);

    //@ts-ignore
    const { inputState, inputErrors, inputActions } = useInputState(settingsInputSchemes.System);

    useEffect(() => {
        setOpen(open);
        inputActions.setFields({
            domain: systemInformation?.domain,
            organization: systemInformation?.organization,
            publicAccess: systemInformation?.publicAccess,
            proxyAccess: systemInformation?.proxyAccess,
        });
    }, [open]);

    const doUpdateSystemSettings = async () => {
        const payload: SystemInfo = settingsInputSchemes.System.keys.reduce((acc: any, c: any) => {
            acc[c] = (inputState[c] as string)?.trim();
            return acc;
        }, {});
        run(
            systemClient
                .updateSystemSettings(systemInformation.id as string, {
                    ...payload,
                    id: systemInformation.id as string,
                })
                .then(() => {
                    handleClose();
                    ToastStore.push({ title: t('settings.systemSettingsSuccess'), type: 'success' });
                })
        );
    };

    const handleClose = () => {
        onClose();
        inputActions.reset();
        reset();
    };

    return (
        <Modal open={openModal} onClose={handleClose}>
            {status === 'loading' ? (
                <SkeletonModal />
            ) : (
                <UpdateSystemSettings
                    handleClose={handleClose}
                    doUpdateSystemSettings={doUpdateSystemSettings}
                    inputErrors={inputErrors}
                    inputActions={inputActions}
                    inputState={inputState}
                    error={error}
                />
            )}
        </Modal>
    );
};

interface UpdateSystemSettingsProps {
    handleClose: () => void;
    doUpdateSystemSettings: () => void;
    inputErrors: InputError;
    inputActions: InputActions;
    inputState: InputState;
    error: Error | null;
}
const UpdateSystemSettings = ({
    handleClose,
    doUpdateSystemSettings,
    inputActions,
    inputErrors,
    inputState,
    error,
}: UpdateSystemSettingsProps) => {
    const isValid = inputActions.validateAll(true);
    return (
        <>
            <ModalHeader onClose={handleClose}>
                <div className="flex items-start">
                    <div className="mt-0 ml-2">
                        <h3 className="text-xl font-medium text-gray-100" id="modal-headline">
                            {t('settings.system')}
                        </h3>
                    </div>
                </div>
            </ModalHeader>
            <ModalBody>
                <div className="h-34">
                    {error ? (
                        <div className="flex flex-wrap mb-4">
                            <div className="flex-1 px-4">
                                <Alert type="danger" title={t('settings.error')} description={error?.message || ''} />
                            </div>
                        </div>
                    ) : null}
                    <div className="flex flex-wrap mb-6 items-start">
                        <div className="flex-1 px-4">
                            <TextInput
                                disabled
                                id="settings-organization-input"
                                name="settings-organization-input"
                                labelText={t('setup.organizationInputLabel')}
                                placeholder={t('setup.organizationInputPlaceholder')}
                                invalid={Boolean(inputErrors.organization)}
                                invalidText={inputErrors.organization}
                                type="text"
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    inputActions.validateField({ field: 'organization', skipNull: true });
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const val = e.target.value || '';
                                    inputActions.setField('organization', val);
                                }}
                                value={inputState.organization || ''}
                            />
                        </div>
                        <div className="flex-1 px-4">
                            <TextInput
                                disabled
                                id="settings-domain-input"
                                name="settings-domain-input"
                                labelText={t('setup.domainInputLabel')}
                                placeholder={t('setup.domainInputPlaceholder')}
                                invalid={Boolean(inputErrors.domain)}
                                invalidText={inputErrors.domain}
                                type="text"
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    inputActions.validateField({ field: 'domain', skipNull: true });
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const val = e.target.value || '';
                                    inputActions.setField('domain', val.trim());
                                }}
                                value={inputState.domain || ''}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap mb-6 items-start">
                        <div className="flex-1 px-4">
                            <TextInput
                                id="settings-public-access-input"
                                name="settings-public-access-input"
                                labelText={t('setup.publicAccessInputLabel')}
                                placeholder={t('setup.publicAccessInputPlaceholder')}
                                invalid={Boolean(inputErrors.publicAccess)}
                                invalidText={inputErrors.publicAccess}
                                type="text"
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    inputActions.validateField({ field: 'publicAccess', skipNull: true });
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const val = e.target.value || '';
                                    inputActions.setField('publicAccess', val.trim());
                                }}
                                value={inputState.publicAccess || ''}
                            />
                        </div>
                        <div className="flex-1 px-4">
                            <TextInput
                                id="settings-proxy-access-input"
                                name="settings-proxy-access-input"
                                labelText={t('setup.proxyAccessInputLabel')}
                                placeholder={t('setup.proxyAccessInputPlaceholder')}
                                invalid={Boolean(inputErrors.proxyAccess)}
                                invalidText={inputErrors.proxyAccess}
                                type="text"
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    inputActions.validateField({ field: 'proxyAccess', skipNull: true });
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const val = e.target.value || '';
                                    inputActions.setField('proxyAccess', val.trim());
                                }}
                                value={inputState.proxyAccess || ''}
                            />
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex justify-end items-center h-16 w-full">
                    <Button
                        theme="secondary"
                        className="h-full w-1/2"
                        onClick={handleClose}
                        data-testid="modal-cancel-btn"
                        size="lg">
                        {t('main.cancel')}
                    </Button>
                    <Button
                        theme={isValid ? 'primary' : 'disabled'}
                        className="h-full w-1/2"
                        disabled={!isValid}
                        onClick={() => {
                            doUpdateSystemSettings();
                        }}
                        id="do-update-system-settings-btn"
                        size="lg">
                        {t('main.save')}
                    </Button>
                </div>
            </ModalFooter>
        </>
    );
};

export default SystemSettingsModal;
