import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { t } from '@baffle/translate';
import cx from 'classnames';
import KeyClick from '@baffle/components/src/handlers/KeyClck';

//allows for less verbose state setting for managing many modals in the same component
export function useManageModals<T>(initialModalState: T) {
    const [modals, setModals] = useState(initialModalState);
    const doSetModals = (modalState: Partial<T>) => {
        setModals(Object.assign({}, initialModalState, modalState) as T);
    };
    return [modals, doSetModals] as const;
}

export interface BaseProps {
    children: React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
}
export interface ModalProps extends BaseProps {
    'aria-labelledby'?: string;
    open: boolean;
    onClose?: () => void;
    onCloseCompleted?: () => void;
    getRootModalProps?: () => any;
}

export const getDefaultRootModalProps = () => {
    return {
        className:
            'inline-block align-center bg-white text-left overflow-hidden shadow-xl transform transition-all sm:w-140 pointer-events-auto',
    };
};

export const Modal = (props: ModalProps) => {
    const {
        children,
        open,
        onClose,
        onCloseCompleted = () => {},
        getRootModalProps = getDefaultRootModalProps,
    } = props;
    //openForReal is a proxy for open... it keeps the true modal open state
    const [openForReal, setOpenForReal] = useState(false);

    const [animationState, setAnimationState] = useState<'none' | 'entering' | 'leaving'>('none');

    const { className: customClassName, ...rootModalProps } = getRootModalProps();
    useEffect(() => {
        if (!open && animationState !== 'none') {
            setAnimationState('leaving');
            const timeout = setTimeout(() => {
                setAnimationState('none');
                setOpenForReal(false);
                onCloseCompleted();
            }, 400);
            return () => {
                clearTimeout(timeout);
            };
        }
        if (open) {
            setOpenForReal(true);
            //allow for openForReal to be true so animating in works fine...
            const timeout = setTimeout(() => {
                setAnimationState('entering');
            }, 0);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [open]);

    if (!openForReal) {
        return null;
    }
    const modalOverlayClasses = cx('fixed inset-0 transition-opacity', {
        'opacity-0 ease-in duration-200': animationState === 'leaving' || 'none',
        'opacity-100 ease-out duration-300': animationState === 'entering',
    });
    const modalContentClasses = cx(customClassName, {
        'opacity-0 ease-in duration-200 translate-y-0 scale-95': animationState === 'leaving' || 'none',
        'opacity-100 ease-out duration-300 translate-y-0 scale-100': animationState === 'entering',
    });
    const rootModalClasses = cx('fixed mt-12 z-20 inset-0 overflow-y-auto', {
        'display-none': animationState === 'none',
    });

    return ReactDOM.createPortal(
        <div className={rootModalClasses} id="modal">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className={modalOverlayClasses}>
                    <KeyClick
                        handler={() => {
                            if (onClose) {
                                onClose();
                            }
                        }}>
                        <div className="absolute inset-0 bg-gray-700 opacity-75 pointer-events-none"></div>
                    </KeyClick>
                </div>
                {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                <div
                    className={modalContentClasses}
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby={props['aria-labelledby'] ?? '"modal-headline"'}
                    {...rootModalProps}>
                    {children}
                </div>
            </div>
        </div>,
        document.getElementById('root') as HTMLElement
    );
};

export interface ModalHeaderProps extends BaseProps {
    onClose?: () => void;
}
export const ModalHeader = ({ children, onClose = () => {} }: ModalHeaderProps) => {
    return (
        <div className="bg-white px-4 pt-4">
            <div className="absolute top-0 right-0 pt-5 pr-5">
                <button
                    type="button"
                    className="text-black-200 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                    aria-label={t('main.close')}
                    onClick={onClose}>
                    <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            {children}
        </div>
    );
};

export const ModalBody = ({ children }: BaseProps) => {
    return <div className="px-4 pt-4 mb-12 bg-white">{children}</div>;
};

export const ModalFooter = ({ children }: BaseProps) => {
    return <div className="bg-white flex">{children}</div>;
};

export const SkeletonModal = () => {
    return (
        <div className="max-w-sm w-full mx-auto">
            <div className="animate-pulse flex space-x-4 my-10">
                <div className="flex-1 space-y-4 py-1">
                    <div className="h-4 bg-gray-300 rounded w-3/4"></div>
                    <div className="space-y-2">
                        <div className="h-4 bg-gray-300 rounded"></div>
                        <div className="h-4 bg-gray-300 rounded w-5/6"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};
