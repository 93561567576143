import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { BaffleIconProps } from '../componentTypes';

const AlertIcon = ({ color = '#868d95', ...rest }: BaffleIconProps) => {
    return (
        <SvgIcon {...rest}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path
                    d="M10.25,0.411111111 C11.4865611,0.411111111 12.4959866,1.36901316 12.5779586,2.58449952 L12.5833333,2.74444444 L12.5833333,3.39798299 C15.8955832,4.38145472 18.2678521,7.39770624 18.3843954,10.9171924 L18.3888889,11.1888889 L18.3888889,17.2115621 L20.5,19.3226732 L20.5,21.4388889 L12.85,21.4381111 L12.8505556,21.755 C12.8505556,23.1922535 11.6872535,24.3555556 10.25,24.3555556 C8.86802549,24.3555556 7.73933447,23.2800174 7.65455643,21.9195706 L7.64944444,21.755 L7.649,21.4381111 L0,21.4388889 L0,19.3226732 L2.11111111,17.2115621 L2.11111111,11.1888889 C2.11111111,7.64460144 4.40057379,4.56432954 7.66375441,3.4776183 L7.91666667,3.39798299 L7.91666667,2.74444444 C7.91666667,1.45411977 8.95967533,0.411111111 10.25,0.411111111 Z M10.25,1.91111111 C9.82363303,1.91111111 9.47434591,2.22760077 9.42313231,2.63958642 L9.41666667,2.74444444 L9.41666667,4.58440948 L8.82540735,4.7124529 C5.88799902,5.34857954 3.7303773,7.91200335 3.61589476,10.9358659 L3.61111111,11.1888889 L3.61111111,17.8328824 L1.50510462,19.9388889 L18.9948954,19.9388889 L16.8888889,17.8328824 L16.8888889,11.1888889 C16.8888889,8.14057819 14.8106566,5.51196022 11.9175445,4.76989335 L11.6745926,4.7124529 L11.0833333,4.58440948 L11.0833333,2.74444444 C11.0833333,2.2825469 10.7118975,1.91111111 10.25,1.91111111 Z M11.3055556,14.3555556 L11.3055556,16.4666667 L9.19444444,16.4666667 L9.19444444,14.3555556 L11.3055556,14.3555556 Z M11.3055556,8.02222222 L11.3055556,12.2444444 L9.19444444,12.2444444 L9.19444444,8.02222222 L11.3055556,8.02222222 Z"
                    fill={color}
                    fillRule="nonzero"></path>
            </g>
        </SvgIcon>
    );
};

export default AlertIcon;
