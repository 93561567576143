import React, { useState, useEffect } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import cx from 'classnames';
import './Toast.scss';
import { t } from '@baffle/translate';
import { motion } from 'framer-motion';
import { ToastType } from './InlineToast';
import { Observer } from 'mobx-react-lite';
import { ToastStore } from '../../../manager/src/stores/ToastStore';
import Button from '../buttons/Button';

export type GlobalToastState = {
    title: string;
    type: ToastType;
    duration?: number;
    'data-testid'?: string;
};

interface CssType {
    [key: string]: string;
}

interface IconType {
    [key: string]: React.Component | React.ReactFragment | React.ReactPortal | null | undefined;
}
interface ToastProps {
    duration?: number;
    onDismiss?: () => void;
}
const GlobalToast = ({ onDismiss = () => null }: ToastProps) => {
    const cssTypes: CssType = {
        danger: 'danger',
        success: 'success',
    };
    const iconTypes: IconType = {
        danger: <InfoIcon fontSize="small" className="danger-icon" />,
        success: <CheckCircleIcon fontSize="small" className="success-icon" />,
    };

    return (
        <Observer>
            {() => {
                const [dismiss, setDismiss] = useState(false);

                const [toast, setToast] = useState<Partial<GlobalToastState>>({});
                const { type = 'success', title, duration = 3000 } = toast;

                const doDismiss = () => {
                    setDismiss(true);
                    setToast({});
                    onDismiss();
                };

                useEffect(() => {
                    if (ToastStore.size > 0 && !Boolean(title)) {
                        setToast(ToastStore.shift() as GlobalToastState);
                        setDismiss(false);
                    }
                    const dismissTimeout = setTimeout(() => {
                        doDismiss();
                    }, duration);
                    return () => {
                        clearTimeout(dismissTimeout);
                    };
                }, [ToastStore.size, title]);

                if (dismiss || !Boolean(title)) {
                    return <div className="hidden" />;
                }
                return (
                    <motion.div
                        data-testid={toast['data-testid'] ?? 'global-toast'}
                        animate={{ top: 58 }}
                        transition={{ duration: 0.3 }}
                        className={cx('toast flex justify-left items-center', cssTypes[type])}>
                        <div className="toast-icon-container">{iconTypes[type]}</div>
                        <div className="toast-message-container">
                            <p className="toast__title" dangerouslySetInnerHTML={{ __html: title as string }} />
                        </div>
                        <div className="toast__dismiss-container">
                            <Button
                                theme="icon-transparent"
                                onClick={() => {
                                    doDismiss();
                                }}
                                tabIndex={0}
                                aria-label={t('database.ariaDismissToast')}
                                data-testid="dismiss-toast"
                                type="button">
                                <CloseIcon fontSize="small" />
                            </Button>
                        </div>
                    </motion.div>
                );
            }}
        </Observer>
    );
};

export default GlobalToast;
