import { DatabasePayloadV2 } from '@baffle/graphql/src/models';

import client from '.';

export function createDatabase(payload: DatabasePayloadV2) {
    return client('/api/v2/databases', { body: payload, method: 'POST' });
}

export function readDatabases() {
    return client(`/api/v2/databases`);
}

export function readDatabase(id: string) {
    return client(`/api/v2/databases/${id}`);
}

export function deleteDatabase(id: string) {
    return client(`/api/v2/databases/${id}`, { method: 'DELETE' });
}

export function updateDatabase(id: string, payload: DatabasePayloadV2) {
    return client(`/api/v2/databases/${id}`, { body: payload, method: 'PUT' });
}

export function readDbEnvTypes() {
    return client('/api/v2/databases/env-types');
}

export function renameDatabase(id: string, payload: { newName: string }) {
    return client(`/api/v2/databases/${id}/rename`, { body: payload, method: 'PATCH' });
}

export function testConnection(payload: DatabasePayloadV2) {
    return client('/api/v2/databases/test-connection', { body: payload, method: 'POST' });
}

export default {
    createDatabase,
    readDatabases,
    readDatabase,
    deleteDatabase,
    updateDatabase,
    readDbEnvTypes,
    renameDatabase,
    testConnection,
};
