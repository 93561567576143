import tenantClient from '@baffle/api-client/src/tenantClient';
import { TenantInfo, AssociatedRolesInfo } from '@baffle/graphql/src/models';
import { observable, action, makeObservable } from 'mobx';
import { RequestOpts } from '../types/StoreTypes';
import { format } from '@baffle/utilities/src/date';

class TenantStore {
    constructor() {
        makeObservable(this, {
            tenantList: observable,
            tenant: observable,
            rolesList: observable,
            setTenantList: action,
            setTenant: action,
            setAssociatedRolesList: action,
        });
    }
    tenantList = observable<TenantInfo>([]);
    tenant: TenantInfo | null = null;
    rolesList = observable<AssociatedRolesInfo>([]);

    setTenantList(tenantList: TenantInfo[]) {
        this.tenantList.replace(tenantList);
    }

    setAssociatedRolesList(roles: AssociatedRolesInfo[]) {
        this.rolesList.replace(roles);
    }

    setTenant(tenant: TenantInfo | null) {
        this.tenant = tenant;
    }

    async addTenant(tenant: TenantInfo) {
        try {
            await tenantClient.addTenant(tenant);
            await this.getTenants();
        } catch (error) {
            throw error;
        }
    }

    async updateTenant(tenant: TenantInfo) {
        try {
            await tenantClient.updateTenant(tenant);
            await this.getTenants();
        } catch (error) {
            throw error;
        }
    }

    async getTenants(opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first' && this.tenantList.length > 0) {
            return;
        }
        try {
            const res = await tenantClient.getTenants();
            this.setTenantList(res.data);
        } catch (error) {
            throw error;
        }
    }

    async getTenant(id: string, opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first') {
            return;
        }
        try {
            const tenant = await tenantClient.getTenant(id);
            this.setTenant(tenant);
        } catch (error) {
            throw error;
        }
    }

    async deleteTenant(id: string) {
        try {
            await tenantClient.deleteTenant(id);
            await this.getTenants();
        } catch (error) {
            throw error;
        }
    }

    async getAssociatedRoles(opts: RequestOpts = {}) {
        try {
            const res = await tenantClient.getAssociatedRoles();
            this.setAssociatedRolesList(res.data);
        } catch (error) {
            throw error;
        }
    }

    get(name: string) {
        return this.tenantList.find(m => m.name === name);
    }
    getById(id: string) {
        return this.tenantList.find(m => m.id === id);
    }

    getTenantOverview(tenant: TenantInfo) {
        return {
            id: tenant.id,
            name: tenant.name,
            roles: tenant.roles,
            cmk: tenant.cmk,
            dek: tenant.dek,
            keystore: tenant.keystore,
            created: format(tenant.createdTime, 'MMM dd, yyyy H:mm:ss a'),
            createdBy: tenant.createdBy,
        };
    }
}

export default TenantStore;
