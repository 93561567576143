import React, { useMemo } from 'react';
import { Modal, TextInput } from 'carbon-components-react';
import { t } from '@baffle/translate';
import { RoleStore } from '@baffle/manager/src/stores';
import { Observer } from 'mobx-react-lite';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import RoleInputScheme from './RoleInputScheme';
import { RoleInfo } from '@baffle/graphql/src/models';

interface RenameRoleProps {
    open: boolean;
    onClose: () => void;
    onSuccess: (role: RoleInfo) => void;
}

const RenameRole = ({ open, onClose, onSuccess }: RenameRoleProps) => {
    return (
        <Observer>
            {() => {
                const roleRenameSchemes = useMemo(
                    () => RoleInputScheme(RoleStore.roleList as RoleInfo[], RoleStore.role?.id),
                    []
                );
                //@ts-ignore
                const { inputState, inputErrors, inputActions } = useInputState(roleRenameSchemes);
                const isValid = inputActions.validateIndividualField({ field: 'name', skipNull: false });
                const renameRole = () => {
                    let updatedRole: RoleInfo = { ...(RoleStore.role as RoleInfo) };
                    updatedRole.name = inputState.name as string;
                    inputActions.reset();
                    onSuccess(updatedRole);
                };
                return (
                    <Modal
                        id="role-rename-modal"
                        className="modal-container-white"
                        open={open}
                        modalHeading={t('roles.renameHeading')}
                        onRequestClose={() => {
                            inputActions.reset();
                            onClose();
                        }}
                        primaryButtonText={t('main.rename')}
                        secondaryButtonText={t('main.cancel')}
                        size="sm"
                        onRequestSubmit={() => renameRole()}
                        primaryButtonDisabled={!isValid}>
                        <TextInput
                            id="role-rename-input"
                            className="text-input-gray10"
                            name="role-rename-input"
                            data-testid="role-rename-input"
                            labelText={t('main.name')}
                            invalid={Boolean(inputErrors.name)}
                            invalidText={inputErrors.name}
                            value={(inputState.name as string) || ''}
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                inputActions.validateField({
                                    field: 'name',
                                    skipNull: true,
                                });
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                inputActions.setField('name', val);
                            }}
                        />
                    </Modal>
                );
            }}
        </Observer>
    );
};

export default RenameRole;
