import React from 'react';
import { BaseCompoundComponentProps } from '../types/react';

interface props extends BaseCompoundComponentProps {
    getProps: () => any;
}
const FixTableAutoResizer = ({ children, getProps = () => ({}) }: props) => {
    return (
        <div className="relative" {...getProps()}>
            <div className="absolute w-full overflow-x-auto shadow">{children}</div>
        </div>
    );
};

export default FixTableAutoResizer;
