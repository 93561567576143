import {
    RbacPolicyRuleV2,
    RbacPolicyV2,
    RbacPolicyV2Payload,
    RbacRuleOrder,
    RbacUsrGrp,
} from '@baffle/graphql/src/models';

import client from '.';

export function createRbacPolicy(payload: RbacPolicyV2Payload) {
    return client('/api/v2/rbac-policies', { body: payload, method: 'POST' });
}

export function readRbacPolicy(id: string) {
    return client(`/api/v2/rbac-policies/${id}`);
}

export function readRbacPolicies() {
    return client(`/api/v2/rbac-policies`);
}

export function updateRbacPolicy(id: string, payload: RbacPolicyV2) {
    return client(`/api/v2/rbac-policies/${id}`, { body: payload, method: 'PUT' });
}

export function deleteRbacPolicy(id: string) {
    return client(`/api/v2/rbac-policies/${id}`, { method: 'DELETE' });
}

export function createRbacUserGroup(payload: RbacUsrGrp) {
    return client('/api/v2/user-group-policies', { body: payload, method: 'POST' });
}

export function readRbacUserGroups() {
    return client(`/api/v2/user-group-policies`);
}

export function readRbacUserGroup(id: string) {
    return client(`/api/v2/user-group-policies/${id}`);
}

export function deleteRbacUserGroup(id: string) {
    return client(`/api/v2/user-group-policies/${id}`, { method: 'DELETE' });
}

export function updateRbacUserGroup(id: string, payload: RbacUsrGrp) {
    return client(`/api/v2/user-group-policies/${id}`, { body: payload, method: 'PUT' });
}

export function readRbacRules(id: string) {
    return client(`/api/v2/rbac-policies/${id}/rules`);
}

export function addRbacRule(payload: RbacPolicyRuleV2) {
    return client(`/api/v2/rbac-rules`, { body: payload, method: 'POST' });
}

export function readRbacRule(id: string) {
    return client(`/api/v2/rbac-rules/${id}`);
}

export function updateRbacRule(id: string, payload: RbacPolicyRuleV2) {
    return client(`/api/v2/rbac-rules/${id}`, { body: payload, method: 'PUT' });
}

export function deleteRbacRule(id: string) {
    return client(`/api/v2/rbac-rules/${id}`, { method: 'DELETE' });
}

export function updateRbacRulesList(id: string, payload: RbacRuleOrder[]) {
    return client(`api/v2/rbac-policies/${id}/rules/order`, { body: payload, method: 'PUT' });
}

export default {
    createRbacPolicy,
    readRbacPolicy,
    readRbacPolicies,
    updateRbacPolicy,
    deleteRbacPolicy,
    createRbacUserGroup,
    readRbacUserGroups,
    readRbacUserGroup,
    deleteRbacUserGroup,
    updateRbacUserGroup,
    deleteRbacRule,
    addRbacRule,
    updateRbacRule,
    updateRbacRulesList,
    readRbacRules,
    readRbacRule,
};
