import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { t } from '@baffle/translate';
import cx from 'classnames';
import KeyClick from '@baffle/components/src/handlers/KeyClck';
import './CreateModal.scss';
import Button from '../buttons/Button';
import { ButtonTheme } from '../types/theme';

export interface BaseProps {
    children: React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
}

export interface CreateModalProps extends BaseProps {
    'aria-labelledby'?: string;
    open: boolean;
    modalId: string;
    onClose: () => void;
    onCloseCompleted?: () => void;
    getRootModalProps?: () => any;
    isValid?: boolean;
    primaryButtonText: string;
    secondaryButtonText?: string;
    primaryButtonTheme: ButtonTheme;
    secondaryButtonTheme?: ButtonTheme;
    primaryButton: () => void;
}

export const getDefaultRootModalProps = () => {
    return {
        className:
            'inline-block flex flex-col align-top bg-white text-left overflow-hidden transform transition-all w-full h-full pointer-events-auto',
    };
};

export const CreateModal = (props: CreateModalProps) => {
    const {
        children,
        open,
        modalId,
        onClose,
        onCloseCompleted = () => {},
        isValid = true,
        primaryButtonText,
        secondaryButtonText = 'Cancel',
        primaryButtonTheme,
        secondaryButtonTheme = 'secondary',
        primaryButton = () => {},
        getRootModalProps = getDefaultRootModalProps,
    } = props;
    //openForReal is a proxy for open... it keeps the true modal open state
    const [openForReal, setOpenForReal] = useState(open);
    const primaryTheme = Boolean(isValid) ? primaryButtonTheme : 'disabled';

    const [animationState, setAnimationState] = useState<'none' | 'entering' | 'leaving'>('none');

    const { className: customClassName, ...rootModalProps } = getRootModalProps();

    useEffect(() => {
        if (open) {
            setOpenForReal(true);
            //allow for openForReal to be true so animating in works fine...
            const timeout = setTimeout(() => {
                setAnimationState('entering');
            }, 0);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [open]);

    const onCloseHandler = () => {
        setAnimationState('leaving');
        const timeout = setTimeout(() => {
            setAnimationState('none');
            setOpenForReal(false);
            onClose();
            onCloseCompleted();
        }, 200);
        return () => {
            clearTimeout(timeout);
        };
    };

    if (!openForReal) {
        return null;
    }

    const panelClasses = cx('create-modal bg-white h-full fixed inset-y-0 right-0 overflow-y-auto', {
        out: animationState === 'leaving',
    });

    const rootModalClasses = cx('fixed mt-12 z-20 inset-0 overflow-y-auto', {
        'display-none': animationState === 'none',
    });

    const modalOverlayClasses = cx('fixed inset-0 transition-opacity', {
        'opacity-0 ease-in duration-200': animationState === 'leaving',
        'opacity-100 ease-out duration-300': animationState === 'entering',
    });

    const modalContentClasses = cx(customClassName, {
        'opacity-0 ease-in duration-200 translate-y-0 scale-95': animationState === 'leaving',
        'opacity-100 ease-out duration-300 translate-y-0 scale-100': animationState === 'entering',
    });

    return ReactDOM.createPortal(
        <div className={rootModalClasses} id="modal" data-testid={modalId}>
            <div className="flex items-end justify-center h-screen pt-4 px-8 pb-20 text-center sm:block sm:p-0">
                <div className={modalOverlayClasses} onClick={onCloseHandler}>
                    <KeyClick handler={onCloseHandler}>
                        <div className="absolute inset-0 bg-gray-700 opacity-75 pointer-events-none"></div>
                    </KeyClick>
                </div>
                <div className={panelClasses}>
                    <div
                        className={modalContentClasses}
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby={props['aria-labelledby'] ?? '"modal-headline"'}
                        {...rootModalProps}>
                        <div className="absolute top-0 right-0 pt-10 pr-8">
                            <button
                                type="button"
                                className="text-black-200 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                                aria-label={t('main.close')}
                                onClick={onCloseHandler}>
                                <svg
                                    className="h-5 w-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                        {children}
                        <div className="bg-gray-250 flex flex-col mt-auto">
                            <div className="flex justify-end items-center h-16 mb-4 w-full">
                                <Button
                                    theme={secondaryButtonTheme}
                                    className="h-full w-1/2"
                                    onClick={onCloseHandler}
                                    size="lg"
                                    id={`${modalId}-secondary`}>
                                    {secondaryButtonText}
                                </Button>
                                <Button
                                    theme={primaryTheme}
                                    className="h-full w-1/2"
                                    disabled={!isValid}
                                    onClick={() => {
                                        primaryButton();
                                    }}
                                    size="lg"
                                    id={`${modalId}-primary`}>
                                    {primaryButtonText}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        document.getElementById('root') as HTMLElement
    );
};

export interface CreateModalHeaderProps {
    titleText: string;
}

export const CreateModalHeader = ({ titleText }: CreateModalHeaderProps) => {
    return (
        <div className="bg-gray-250 flex flex-col px-8 pt-8">
            <h3 className="text-xl font-medium" id="modal-headline">
                {titleText}
            </h3>
        </div>
    );
};

export const CreateModalBody = ({ children }: BaseProps) => {
    return <div className="px-8 pt-6 pb-12 bg-gray-250 flex-1 flex-col overflow-y-auto">{children}</div>;
};
