import React, { useState, useEffect } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import cx from 'classnames';

export type ToastType = 'danger' | 'success';

export type ToastState = {
    show: boolean;
    title: string;
    type: ToastType;
};

interface ToastProps {
    className?: string;
    title: string;
    description?: string;
    duration?: number;
    neverDismiss?: boolean;
    onDismiss?: () => void;
    type: ToastType;
}

interface CssType {
    [key: string]: string;
}

interface IconType {
    [key: string]: React.Component | React.ReactFragment | React.ReactPortal | null | undefined;
}
const InlineToast = ({
    className = '',
    title,
    description,
    type,
    duration = 3000,
    neverDismiss = false,
    onDismiss = () => null,
}: ToastProps) => {
    const [dismiss, setDismiss] = useState(false);

    useEffect(() => {
        //never dismiss
        if (neverDismiss) {
            return;
        }
        const doDismiss = setTimeout(() => {
            setDismiss(true);
            onDismiss();
        }, duration);
        return () => {
            clearTimeout(doDismiss);
        };
    });

    if (dismiss) {
        return null;
    }
    const cssTypes: CssType = {
        danger: 'danger border-red bg-white text-red',
        success: 'success border-teal bg-teal-200',
    };
    const iconTypes: IconType = {
        danger: <InfoIcon fontSize="small" className="danger-icon" />,
        success: <CheckCircleIcon fontSize="small" className="success-icon" />,
    };

    return (
        <div className={cx('border border-solid p-2 text-sm flex items-center', cssTypes[type], className)}>
            <div className="mr-2">{iconTypes[type]}</div>
            <p className="text-sm" dangerouslySetInnerHTML={{ __html: title }} />
            {description ? <p className="toast__description">{description}</p> : null}
        </div>
    );
};

export default InlineToast;
