import Joi from '@hapi/joi';
import { TenantInfo } from '@baffle/graphql/src/models';
import { t } from '@baffle/translate';

export function createTenantInputScheme(tenants: TenantInfo[], id: string | undefined) {
    const tenantInputScheme = {
        ns: 'tenants',
        keys: ['name', 'keystore', 'cmk', 'dek'],
        defaultValues: {},
        validationSchema: {
            name: Joi.string()
                .min(1)
                .max(30)
                .pattern(new RegExp(/^[^~`!@#$%^&()={}[\]:;,.<>+\/\\?]+$/))
                .required()
                .custom((val, helper) => {
                    if (
                        tenants.some(
                            (m: TenantInfo) => m.id !== id && m.name.trim().toLowerCase() === val.trim().toLowerCase()
                        )
                    ) {
                        return helper.message({
                            custom: t('tenants.duplicateTenantError'),
                        });
                    }
                })
                .messages({
                    'string.max': t('main.invalidNameLength'),
                    'string.pattern.base': t('tenants.nameInputInvalid'),
                    'string.empty': t('tenants.nameInputInvalid'),
                }),
            keystore: Joi.string()
                .min(1)
                .required()
                .allow(null),
            cmk: Joi.string()
                .min(1)
                .required(),
            dek: Joi.string()
                .min(1)
                .required()
                .allow(null),
        },
    };
    return tenantInputScheme;
}
