import Joi from '@hapi/joi';
import { t } from '@baffle/translate';
import { DataProtectionItem } from '@baffle/graphql/src/models';

export default function createRbacPolicyScheme(policies: DataProtectionItem[], id: string | undefined) {
    const rbacPolicySchema = {
        ns: 'rbacPolicy',
        keys: ['name', 'description', 'defaultPermission', 'rules', 'defaultMaskMode'],
        validationSchema: {
            name: Joi.string()
                .trim()
                .min(1)
                .max(30)
                .required()
                .pattern(/^[a-zA-Z0-9_-\s]+$/)
                .custom((val, helper) => {
                    if (
                        policies.some(
                            (p: DataProtectionItem) =>
                                p.id !== id && p.name.trim().toLowerCase() === val.trim().toLowerCase()
                        )
                    ) {
                        return helper.message({
                            custom: t('encryption.modeLibrary.rbac.rbacPolicy.duplicatePolicyNameError'),
                        });
                    }
                })
                .messages({
                    'string.max': t('main.invalidNameLength'),
                    'string.empty': t('encryption.modeLibrary.rbac.rbacPolicy.policyNameEmptyError'),
                    'string.pattern.base': t('encryption.modeLibrary.rbac.rbacPolicy.policyNameInvalidError'),
                }),
            defaultPermission: Joi.string()
                .min(1)
                .required(),
            defaultMaskMode: Joi.object().when('defaultPermission', {
                is: 'MASK',
                then: Joi.required(),
                otherwise: Joi.allow(null),
            }),
            rules: Joi.array()
                .items(
                    Joi.object({
                        name: Joi.string()
                            .trim()
                            .min(1)
                            .max(30)
                            .required()
                            .pattern(/^[a-zA-Z0-9_-\s]+$/)
                            .messages({ 'string.empty': t('encryption.modeLibrary.rbac.jsonFieldEmptyError') }),
                        order: Joi.number()
                            .required()
                            .messages({ 'string.empty': t('encryption.modeLibrary.rbac.datatypeEmptyError') }),
                        userGroups: Joi.array()
                            .min(1)
                            .required(),
                        permission: Joi.string()
                            .min(1)
                            .required(),
                        maskMode: Joi.object().when('permission', {
                            is: 'MASK',
                            then: Joi.required(),
                            otherwise: Joi.allow(null),
                        }),
                    })
                )
                .unique('name')
                .unique('order'),
        },
    };
    return rbacPolicySchema;
}
