import React from 'react';
import './LeftSideNav.scss';

interface LeftSideNavProps {
    children: React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
    id?: string;
}

const LeftSideNav = ({ children, ...rest }: LeftSideNavProps) => {
    return (
        <div className="sidebar-left shadow-sm z-20" {...rest}>
            <nav className="flex flex-col justify-between w-42 h-full" role="navigation" aria-label="Navigation">
                {children}
            </nav>
        </div>
    );
};

export default LeftSideNav;
