import React, { useState, useEffect } from 'react';
import TextInput from '@baffle/components/src/forms/TextInput';
import { t } from '@baffle/translate';
import '../../database/AddDatabaseModal.scss';
import { InputActions, InputError, InputState, useInputState } from '@baffle/utilities/src/inputHooks';
import Joi from '@hapi/joi';
import { Modal, ModalBody, ModalFooter, ModalHeader, SkeletonModal } from '@baffle/components/src/modal/Modal';
import Button from '@baffle/components/src/buttons/Button';
import { EncryptionStore } from '../../stores';
import { useParams } from 'react-router-dom';
import encryptionClient from '@baffle/api-client/src/encryptionClient';
import Alert from '@baffle/components/src/alerts/Alert';
import useAwait from '@baffle/api-client/src/useAwait';
import { ToastStore } from '../../stores/ToastStore';

interface AddDatabaseModalProps {
    databases: string[];
    open: boolean;
    fourLevel?: boolean | undefined;
    onClose?: () => void;
    existingDbName?: string;
    onSuccess?: (dbName: string, action: string) => void;
}
const AddNewDatabaseModal = ({
    databases,
    onClose = () => null,
    open,
    fourLevel,
    existingDbName,
    onSuccess = (dbName: string, action: string) => null,
}: AddDatabaseModalProps) => {
    const [openModal, setOpen] = useState(open);
    const { id } = useParams() as { id: string };
    const { status, run, error, reset } = useAwait();
    const { inputState, inputErrors, inputActions } = useInputState({
        keys: ['dbName'],
        ns: 'database',
        validationSchema: {
            dbName: Joi.string()
                .min(1)
                .max(30)
                .custom(val => {
                    if (
                        databases.some((a: String) => {
                            if (existingDbName) {
                                return a.trim().toLowerCase() === val.toLowerCase() && val !== existingDbName;
                            } else {
                                return a.trim().toLowerCase() === val.toLowerCase();
                            }
                        })
                    ) {
                        throw new Error(t('database.nameExists'));
                    }
                    return val;
                })
                .required()
                .messages({
                    'string.max': t('main.invalidNameLength'),
                    'string.empty': t('database.dbNameInputInvalid'),
                    'string.pattern.base': t('database.noSpecialCharacters'),
                    'any.custom': t('database.nameExists'),
                }),
        },
        defaultValues: { dbName: existingDbName },
    });

    //Sync state from props
    useEffect(() => {
        setOpen(open);
        if (open) {
            inputActions.setFields({
                dbName: existingDbName,
            });
        }
    }, [open]);

    const handleClose = () => {
        onClose();
        inputActions.reset();
        reset();
    };

    const setDatabase = () => {
        run(
            (existingDbName
                ? encryptionClient.editNewDatabase(
                      {
                          database: inputState.dbName as string,
                          appID: id,
                      },
                      existingDbName
                  )
                : encryptionClient.createNewDatabase({
                      database: inputState.dbName as string,
                      appID: id,
                  })
            ).then(() => {
                onSuccess(inputState.dbName as string, existingDbName ? 'updated' : 'added');
                if (existingDbName) {
                    const editType = fourLevel ? 'database-4' : 'database-3';
                    EncryptionStore.updateSelectedColumns({
                        editType,
                        newDBName: inputState.dbName as string,
                    });
                }
                EncryptionStore.setSelectedDatabase('encryption', '');
                EncryptionStore.setSelectedTable('encryption', '');
                EncryptionStore.listDatabases(id);
                EncryptionStore.getSchema(id as string);
                inputActions.reset();
            })
        );
    };

    useEffect(() => {
        if (error) {
            ToastStore.push({ type: 'danger', title: error.message });
        }
    }, [error]);

    return (
        <Modal open={openModal} onClose={handleClose}>
            {status === 'loading' ? (
                <SkeletonModal />
            ) : (
                <AddNewDatabase
                    handleClose={handleClose}
                    inputErrors={inputErrors}
                    inputActions={inputActions}
                    setDatabase={setDatabase}
                    existingDbName={existingDbName}
                    inputState={inputState}
                    error={error}
                />
            )}
        </Modal>
    );
};

interface AddNewDatabaseProps {
    handleClose: () => void;
    inputErrors: InputError;
    inputActions: InputActions;
    setDatabase: () => void;
    existingDbName?: string;
    inputState: InputState;
    error: Error | null;
}

const AddNewDatabase = ({
    handleClose,
    inputActions,
    inputErrors,
    setDatabase,
    existingDbName,
    inputState,
    error,
}: AddNewDatabaseProps) => {
    const isValid = inputActions.validateAll(true);
    return (
        <>
            <ModalHeader onClose={handleClose}>
                <div className="flex items-start">
                    <div className="mt-0 ml-2">
                        <h3 className="text-xl font-medium text-gray-100" id="modal-headline">
                            {existingDbName ? t('database.edit') : t('database.addDatabase')}
                        </h3>
                    </div>
                </div>
            </ModalHeader>
            <ModalBody>
                <div className="h-64">
                    {error ? (
                        <div className="flex flex-wrap mb-4">
                            <div className="flex-1 px-4">
                                <Alert
                                    type="danger"
                                    title={t('encryption.newDatabaseAddError')}
                                    description={error?.message || ''}
                                />
                            </div>
                        </div>
                    ) : null}
                    <TextInput
                        id="db-name-input"
                        name="db-name-input"
                        labelText={t('database.dbNameInputLabel')}
                        invalid={Boolean(inputErrors.dbName)}
                        invalidText={inputErrors.dbName}
                        placeholder={t('database.dbNamePlaceholder')}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            inputActions.validateField({
                                field: 'dbName',
                                skipNull: true,
                            });
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value || '';
                            inputActions.setField('dbName', val.trim());
                        }}
                        value={(inputState.dbName as string) ?? ''}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex justify-end items-center h-16 w-full">
                    <Button
                        theme="secondary"
                        className="h-full w-1/2"
                        onClick={handleClose}
                        data-testid="modal-cancel-future-db-btn"
                        size="lg">
                        {t('main.cancel')}
                    </Button>
                    <Button
                        className="h-full w-1/2"
                        theme={!isValid ? 'disabled' : 'primary'}
                        onClick={() => {
                            setDatabase();
                        }}
                        data-testid="modal-add-future-db-btn"
                        size="lg">
                        {t('main.save')}
                    </Button>
                </div>
            </ModalFooter>
        </>
    );
};

export default AddNewDatabaseModal;
