import React, { useMemo, useEffect } from 'react';
import { Modal, TextInput } from 'carbon-components-react';
import { t } from '@baffle/translate';
import { CertificateStore } from '@baffle/manager/src/stores';
import { Observer } from 'mobx-react-lite';
import { certificateScheme } from './createCertInputScheme';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';
import useAwait from '@baffle/api-client/src/useAwait';
import { CertificateDetails } from '@baffle/graphql/src/models';

interface RenameCACertificateProps {
    open: boolean;
    onClose: () => void;
    onSuccess: (name: string) => void;
}

const RenameCACertificate = ({ open, onClose, onSuccess }: RenameCACertificateProps) => {
    const { run, error, reset } = useAwait();
    const certificateRenameSchema = useMemo(
        () => certificateScheme(CertificateStore.certificates, CertificateStore.selectedCertificate?.id),
        [CertificateStore.certificates, CertificateStore.selectedCertificate?.id]
    );
    //@ts-ignore
    const { inputState, inputErrors, inputActions } = useInputState(certificateRenameSchema);
    const isValid = inputActions.validateAll(true);

    useEffect(() => {
        if (error) {
            NotificationStore.push({
                'data-testid': 'certificate-rename-failure',
                kind: 'error',
                title: t('certificate.renameCertificateError'),
                description: error.message ?? t('main.genericError'),
            });
        }
    }, [error]);

    const handleClose = () => {
        reset();
        setTimeout(() => {
            if (onClose) {
                onClose();
            }
        }, 200);
    };

    const currentCert: CertificateDetails | null = CertificateStore.selectedCertificate;
    const payload = {
        ...currentCert,
        name: (inputState.name as string) ?? '',
    };
    const handleSave = () => {
        run(
            CertificateStore.edit(currentCert?.id as string, payload).then(() => {
                handleClose();
                if (onSuccess) {
                    onSuccess(payload?.name as string);
                }
            })
        );
    };

    return (
        <Observer>
            {() => {
                return (
                    <Modal
                        id="certificate-rename-modal"
                        className="modal-container-white"
                        open={open}
                        modalHeading={t('certificate.renameCertificateTitle')}
                        onRequestClose={onClose}
                        primaryButtonText={t('main.rename')}
                        secondaryButtonText={t('main.cancel')}
                        size="sm"
                        onRequestSubmit={handleSave}
                        primaryButtonDisabled={!isValid}>
                        <TextInput
                            id="certificate-rename-input"
                            className="text-input-gray10"
                            name="certificate-rename-input"
                            data-testid="certificate-rename-input"
                            labelText={t('main.name')}
                            invalid={Boolean(inputErrors.name)}
                            invalidText={inputErrors.name}
                            value={(inputState.name as string) || ''}
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                inputActions.validateField({
                                    field: 'name',
                                    skipNull: true,
                                });
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                inputActions.setField('name', val);
                            }}
                        />
                    </Modal>
                );
            }}
        </Observer>
    );
};

export default RenameCACertificate;
