import React, { useEffect, InputHTMLAttributes, forwardRef } from 'react';
import cx from 'classnames';
import ErrorIcon from '@material-ui/icons/Error';

export const getDefaultInputContainerProps: () => any = () => {
    return {
        className: 'w-full relative',
    };
};

export const getDefaultInputProps: () => any = () => {
    return {
        className:
            'text-xs shadow-sm focus:ring-blue-60 focus:border-blue-60 focus:text-gray-700 block w-full pl-4 pr-8 py-2 rounded-none focus:rounded-none focus:outline-none',
    };
};

type Theme = 'primary' | 'secondary';

interface props extends Partial<InputHTMLAttributes<HTMLInputElement>> {
    labelHidden?: boolean;
    labelText?: string;
    topRightHelperText?: string;
    className?: string;
    name: string;
    id?: string;
    invalid?: boolean;
    invalidText?: string;
    placeholder?: string;
    defaultValue?: string; //triggers an on change event when the component mounts
    type?: string;
    charLimit?: number;
    value?: any;
    disabled?: boolean;
    getInputProps?: () => any;
    getInputContainerProps?: () => any;
    overrideInputClass?: string;
    showErrorIcon?: boolean;
    theme?: Theme;
}

const TextInput = forwardRef<HTMLInputElement, props>(
    (
        {
            labelText = '',
            topRightHelperText,
            placeholder = '',
            className = '',
            labelHidden = false,
            name,
            charLimit,
            invalid = false,
            invalidText = '',
            defaultValue = '',
            type = 'text',
            value,
            disabled = false,
            getInputProps = getDefaultInputProps,
            getInputContainerProps = getDefaultInputContainerProps,
            overrideInputClass,
            showErrorIcon = true,
            theme = 'primary',
            ...rest
        }: props,
        ref
    ) => {
        const { id = name } = rest;
        const { className: classNameFromInputProps, ...props } = getInputProps();

        useEffect(() => {
            //@ts-ignore
            //TODO: what type will recognize that ref.current is valid?
            if (Boolean(defaultValue) && ref?.current) {
                //@ts-ignore
                ref.current.value = defaultValue;
            }
        }, []);
        const labelClasses = cx({
            'mb-2 block text-xxs text-gray-70': !labelHidden,
            'sr-only': labelHidden,
        });
        const inputClasses = cx(
            overrideInputClass ?? classNameFromInputProps,
            {
                'border-b border-gray-50': !invalid,
                'border border-red text-red placeholder-red': invalid,
                'bg-gray-200 text-gray-100-disabled cursor-not-allowed': disabled,
            },
            {
                'bg-gray-10': theme === 'primary',
                'bg-white': theme === 'secondary',
            },
            className
        );
        return (
            <div {...getInputContainerProps()}>
                <div className="flex justify-between items-center">
                    <label htmlFor={name} className={labelClasses}>
                        {labelText}
                    </label>
                    {topRightHelperText ? (
                        <div className="mb-2 block text-xxs text-red">{topRightHelperText}</div>
                    ) : null}
                    {invalid && charLimit ? (
                        <div className="mb-2 block text-xxs text-red'">{value?.length + '/' + charLimit}</div>
                    ) : null}
                </div>
                {invalid && showErrorIcon ? <ErrorIcon className="text-red w-4 h-4 mt-2 absolute right-2" /> : null}
                <input
                    ref={ref}
                    type={type}
                    className={inputClasses}
                    id={id}
                    placeholder={placeholder}
                    name={name}
                    value={value}
                    disabled={disabled}
                    {...rest}
                    {...props}
                />
                {invalid ? (
                    <p className="text-xs text-red" id={id + '-error-msg'}>
                        {invalidText}
                    </p>
                ) : null}
            </div>
        );
    }
);

export default TextInput;
