import cx from 'classnames';
import React from 'react';

interface DefaultLinkProps {
    className?: string;
    url: string;
    children: React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
}

const DefaultLink = ({ className: customClassName, url, children, ...rest }: DefaultLinkProps) => {
    const className = cx(
        `text-xs cursor-pointer text-blue hover:underline active:text-gray-700 visited:text-purple focus:underline`,
        customClassName
    );
    return (
        <a href={url} className={className} {...rest} target="_blank" role="link">
            {children}
        </a>
    );
};

export default DefaultLink;
