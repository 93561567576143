import React, { useEffect } from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Button from '@baffle/components/src/buttons/Button';
import TextInput from '@baffle/components/src/forms/TextInput';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import Joi from '@hapi/joi';
import { t } from '@baffle/translate';
import { useHistory } from 'react-router-dom';
import InlineToast from '@baffle/components/src/toasts/InlineToast';
import useEnterKeyPress from '@baffle/utilities/src/useEnterKeyPress';
import { useSetToast } from '@baffle/components/src/toasts/Toast';
import { SuperAdminInfo, SystemInfo } from '@baffle/graphql/src/models';
import { SetupStore } from '../stores';
import PasswordToggleInput from '@baffle/components/src/forms/PasswordToggleInput';

const RegisterAdminUser = () => {
    const history = useHistory();
    const { toast, setToast } = useSetToast();
    const systemData: SystemInfo | null = SetupStore.systemData;

    useEffect(() => {
        if (!systemData?.domain) {
            history.replace('/configure-system');
        }
    }, [systemData]);

    const { inputState, inputErrors, inputActions } = useInputState({
        keys: ['firstName', 'lastName', 'email', 'phone', 'password', 'confirmPassword', 'countryCode'],
        ns: 'setup',
        defaultValues: { firstName: '', lastName: '', email: '', phone: '', password: '', confirmPassword: '' },
        validationSchema: {
            firstName: Joi.string()
                .min(1)
                .required()
                .error(new Error(t('setup.firstNameInputInvalid'))),
            lastName: Joi.string()
                .min(1)
                .required()
                .error(new Error(t('setup.lastNameInputInvalid'))),
            email: Joi.string()
                .email({ tlds: false })
                .custom(val => {
                    const domain = systemData?.domain;
                    const regex = new RegExp('@' + '?[a-zA-Z]+.' + domain + '$');
                    if (!regex.test(val)) {
                        throw new Error(t('setup.sameDomain', { domain }));
                    }

                    return val;
                })
                .required()
                .messages({
                    'any.custom': t('setup.sameDomain', { domain: systemData?.domain }),
                    'string.email': t('setup.emailInputInvalid'),
                    'string.empty': t('setup.emailInputInvalid'),
                }),
            phone: Joi.string()
                .min(1)
                .custom(val => {
                    const phoneNumber = parsePhoneNumberFromString(inputState.phone as string);
                    if (phoneNumber && phoneNumber.isValid()) {
                        return val;
                    }
                    throw new Error(t('setup.phoneInputInvalid'));
                })
                .optional()
                .allow('')
                .error(new Error(t('setup.phoneInputInvalid'))),
            password: Joi.string()
                .pattern(new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{10,}$'))
                .required()
                .error(new Error(t('setup.passwordInputInvalid'))),
            confirmPassword: Joi.string()
                .custom(val => {
                    if (val !== inputState.password) {
                        throw new Error(t('setup.passwordMismatch'));
                    }
                    return val;
                })
                .required()
                .error(new Error(t('setup.passwordMismatch'))),
        },
    });

    useEffect(() => {
        if (inputState.phone) {
            const phoneNumber = parsePhoneNumberFromString(inputState.phone as string);
            if (phoneNumber && phoneNumber.isValid()) {
                inputActions.setField('countryCode', phoneNumber?.country ?? '');
            }
        }
    }, [inputState.phone]);

    const isValid = inputActions.validateAll(true);

    const doSetInitialRegister = async (setInitialRegister: boolean) => {
        const superAdminData: SuperAdminInfo = {
            firstName: inputState.firstName as string,
            lastName: inputState.lastName as string,
            email: inputState.email as string,
            phone: inputState.phone as string,
            password: inputState.password as string,
            confirmPassword: inputState.confirmPassword as string,
        };
        SetupStore.setSuperAdminData(superAdminData);
        if (setInitialRegister) {
            history.replace('/configure-keystore');
        } else {
            history.replace('/configure-system');
        }
    };

    useEnterKeyPress(() => {
        if (isValid) {
            doSetInitialRegister(true);
        }
    });

    const superAdminData = SetupStore.superAdminData;

    useEffect(() => {
        inputActions.setFields({
            firstName: superAdminData?.firstName,
            lastName: superAdminData?.lastName,
            email: superAdminData?.email,
            phone: superAdminData?.phone,
            password: superAdminData?.password,
            confirmPassword: superAdminData?.confirmPassword,
        });
    }, [superAdminData]);

    return (
        <div className="w-132">
            <div className="bg-white py-4 px-8">
                {toast.show ? (
                    <InlineToast
                        className="mb-5"
                        duration={100000}
                        title={toast.title}
                        type={toast.type}
                        onDismiss={() => setToast({ title: '', show: false, type: 'success' })}
                    />
                ) : null}

                <div className="my-4 text-lg">
                    <span className="font-bold">{t('setup.step2')}</span> {t('setup.createAdminUser')}
                </div>
                <div className="mb-6">
                    <TextInput
                        name="setup-firstName-input"
                        id={`setup-firstName-input`}
                        labelText={t('setup.firstNameInputLabel')}
                        placeholder={t('setup.firstNameInputPlaceholder')}
                        invalid={Boolean(inputErrors.firstName)}
                        invalidText={inputErrors.firstName}
                        type="text"
                        value={(inputState.firstName as string) ?? ''}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            inputActions.validateField({ field: 'firstName', skipNull: true });
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value || '';
                            inputActions.setField('firstName', val.trim());
                        }}
                    />
                </div>
                <div className="mb-6">
                    <TextInput
                        name="setup-lastName-input"
                        id={`setup-lastName-input`}
                        labelText={t('setup.lastNameInputLabel')}
                        placeholder={t('setup.lastNameInputPlaceholder')}
                        invalid={Boolean(inputErrors.lastName)}
                        invalidText={inputErrors.lastName}
                        type="text"
                        value={(inputState.lastName as string) ?? ''}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            inputActions.validateField({ field: 'lastName', skipNull: true });
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value || '';
                            inputActions.setField('lastName', val.trim());
                        }}
                    />
                </div>
                <div className="mb-6">
                    <TextInput
                        name="setup-email-input"
                        id={`setup-email-input`}
                        labelText={t('setup.emailInputLabel')}
                        placeholder={t('setup.emailInputPlaceholder')}
                        invalid={Boolean(inputErrors.email)}
                        invalidText={inputErrors.email}
                        type="text"
                        value={(inputState.email as string) ?? ''}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            inputActions.validateField({ field: 'email', skipNull: true });
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value || '';
                            inputActions.setFields({ email: val.trim() });
                        }}
                    />
                </div>
                <div className="mb-6">
                    <TextInput
                        name="setup-phone-input"
                        id={`setup-phone-input`}
                        labelText={t('setup.phoneInputLabel')}
                        placeholder={t('setup.phoneInputPlaceholder')}
                        invalid={Boolean(inputErrors.phone)}
                        invalidText={inputErrors.phone}
                        type="text"
                        value={(inputState.phone as string) ?? ''}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            inputActions.validateField({
                                field: 'phone',
                                skipNull: true,
                            });
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value || '';
                            inputActions.setField('phone', val.trim());
                        }}
                    />
                </div>
                <div className="mb-6">
                    <PasswordToggleInput
                        name="setup-password-input"
                        id={`setup-password-input`}
                        labelText={t('setup.adminPasswordInputLabel')}
                        placeholder={t('setup.passwordInputPlaceholder')}
                        invalid={Boolean(inputErrors.password)}
                        invalidText={inputErrors.password}
                        value={(inputState.password as string) ?? ''}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            inputActions.validateField({ field: 'password', skipNull: true });
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value || '';
                            inputActions.setField('password', val.trim());
                        }}
                    />
                    <p className="text-gray-500 text-xxxs">{t('setup.passwordValidationMessage')}</p>
                </div>
                <div className="mb-6">
                    <TextInput
                        name="setup-confirmPassword-input"
                        labelText={t('setup.confirmPasswordInputLabel')}
                        id={`setup-confirmPassword-input`}
                        placeholder={t('setup.confirmPasswordInputPlaceholder')}
                        invalid={Boolean(inputErrors.confirmPassword)}
                        invalidText={inputErrors.confirmPassword}
                        type="password"
                        value={(inputState.confirmPassword as string) ?? ''}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            inputActions.validateField({ field: 'confirmPassword', skipNull: true });
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value || '';
                            inputActions.setField('confirmPassword', val.trim());
                        }}
                    />
                </div>
                <div className="w-150 flex justify-end h-10">
                    <Button className="w-1/5" theme="secondary" onClick={() => doSetInitialRegister(false)}>
                        {t('setup.back')}
                    </Button>
                    <Button
                        className="w-1/5"
                        theme={isValid ? 'primary' : 'disabled'}
                        id="do-initial-register-btn"
                        disabled={!isValid}
                        onClick={() => doSetInitialRegister(true)}>
                        {t('setup.continue')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default RegisterAdminUser;
