import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { BaffleIconProps } from '../componentTypes';

const HelpIcon = ({ color = '#868d95', ...rest }: BaffleIconProps) => {
    return (
        <SvgIcon {...rest}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-18.000000, -740.000000)" fill={color} fillRule="nonzero">
                    <g transform="translate(18.000000, 740.000000)">
                        <path d="M10,0 C15.52,0 20,4.48 20,10 C20,15.52 15.52,20 10,20 C4.48,20 0,15.52 0,10 C0,4.48 4.48,0 10,0 Z M10,1.5 C5.30842712,1.5 1.5,5.30842712 1.5,10 C1.5,14.6915729 5.30842712,18.5 10,18.5 C14.6915729,18.5 18.5,14.6915729 18.5,10 C18.5,5.30842712 14.6915729,1.5 10,1.5 Z M11.375,13.75 L11.375,15.5 L9.625,15.5 L9.625,13.75 L11.375,13.75 Z M10.5,4 C12.43375,4 14,5.56625 14,7.5 C14,9.6 11.5808,9.8856 11.387264,11.646912 L11.375,11.875 L9.625,11.875 C9.625,9.03125 12.25,9.25 12.25,7.5 C12.25,6.5375 11.4625,5.75 10.5,5.75 C9.585625,5.75 8.8291875,6.46071875 8.75582969,7.35701406 L8.75,7.5 L7,7.5 C7,5.56625 8.56625,4 10.5,4 Z"></path>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

export default HelpIcon;
