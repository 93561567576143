interface iThemeEnum {
    [key: string]: BaseTheme;
}
export const BaseThemeEnum: iThemeEnum = {
    TEAL: 'teal',
    TEAL_GHOST: 'teal-ghost',
    TEAL_LINK: 'teal-link',
    BLUE: 'blue',
    GREEN: 'green',
    BLUE_GHOST: 'blue-ghost',
    BLUE_LINK: 'blue-link',
    RED: 'red',
    RED_GHOST: 'red-ghost',
    RED_LINK: 'red-link',
    BLACK: 'black',
    BLACK_GHOST: 'black-ghost',
    BLACK_LINK: 'black-link',
    GRAY: 'gray',
    GRAY_GHOST: 'gray-ghost',
    GRAY_LINK: 'gray-link',
    ORANGE: 'orange',
};

export type BaseTheme =
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'tertiary-danger'
    | 'danger'
    | 'disabled'
    | 'teal'
    | 'teal-ghost'
    | 'teal-link'
    | 'blue'
    | 'blue-ghost'
    | 'blue-link'
    | 'green'
    | 'red'
    | 'red-ghost'
    | 'red-link'
    | 'black'
    | 'black-ghost'
    | 'black-link'
    | 'gray'
    | 'gray-ghost'
    | 'gray-link'
    | 'orange'
    | 'border-gray';

export type ButtonTheme = BaseTheme | 'icon-transparent';
