import React, { useState, useEffect } from 'react';
import { InlineLoading } from 'carbon-components-react';
import { t } from '@baffle/translate';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import Alert from '@baffle/components/src/alerts/Alert';
import WarningIcon from '@material-ui/icons/Warning';
import Joi from '@hapi/joi';
import useAwait from '../../../../api-client/src/useAwait';
import databaseClient from '../../../../api-client/src/databaseClient';
import { BaseCredentials } from '../../../../graphql/src/models/credentials';
import Button from '../../../../components/src/buttons/Button';
import TextInput from '../../../../components/src/forms/TextInput';
import {
    getDefaultRootModalProps,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../../../components/src/modal/Modal';

interface GrantsModalProps {
    id: string;
    databases: string;
    open: boolean;
    onClose?: () => void;
    onSuccess?: () => void;
}

const GrantsModal = ({
    id,
    databases,
    open: openFromParent,
    onClose = () => {},
    onSuccess = () => {},
}: GrantsModalProps) => {
    const [open, setOpen] = useState(openFromParent);
    const [teardownModal, setTeardownModal] = useState(false);

    const { run, error, status, reset } = useAwait();

    useEffect(() => {
        if (status === 'success') {
            initClose(true);
            onSuccess();
        }
    }, [status]);

    const { inputState, inputErrors, inputActions } = useInputState({
        ns: 'encryption',
        defaultValues: {},
        keys: ['username', 'password'],
        validationSchema: {
            username: Joi.string()
                .min(1)
                .required()
                .error(new Error(t('encryption.invalidUsername'))),
            password: Joi.string()
                .min(1)
                .required()
                .error(new Error(t('encryption.invalidPassword'))),
        },
    });

    useEffect(() => {
        //reset the initial database type and set the default value
        if (teardownModal) {
            inputActions.setField('cert', null);
        }
    }, [teardownModal]);

    //Sync state from props
    useEffect(() => {
        setOpen(openFromParent);
    }, [openFromParent]);

    const initClose = (clearState?: boolean) => {
        if (status === 'loading' || !open) {
            return;
        }
        setOpen(false);
        //allow the modal to animate out
        setTimeout(() => {
            closeModal(clearState);
        }, 200);
    };
    const closeModal = (clearState?: boolean) => {
        if (status === 'loading') {
            return;
        }
        if (clearState) {
            inputActions.reset();
            reset();
            setTeardownModal(true);
            setTimeout(() => setTeardownModal(false), 0);
        }
        if (onClose) {
            onClose();
        }
    };
    const doUpdateGrants = async () => {
        run(databaseClient.updateGrants(id, databases, (inputState as unknown) as BaseCredentials));
    };
    const isValid = inputActions.validateAll(true);
    //hack to remove the input components state since modals remain mounted in the dom
    if (teardownModal) {
        return null;
    }
    return (
        <Modal
            open={open}
            onClose={() => initClose(true)}
            getRootModalProps={() => {
                return {
                    'data-testid': 'grants-modal',
                    ...getDefaultRootModalProps(),
                };
            }}>
            <ModalHeader>
                <div className="flex items-center text-black">
                    <WarningIcon className="mr2" /> {t('database.databasePermissions')}
                </div>
            </ModalHeader>
            <ModalBody>
                <div className="flex-col justify-center items-center">
                    <div className="mb-6 flex justify-center">
                        <p
                            data-testid="db-permission-msg"
                            className="w-84"
                            dangerouslySetInnerHTML={{
                                __html: t('database.grantsNeededFor', { databases: databases }),
                            }}
                        />
                    </div>
                    {error ? (
                        <div className="mb-6 flex justify-center">
                            <Alert type="danger" description={error.message} />
                        </div>
                    ) : null}
                    <div className="mb-6 flex justify-center">
                        <div className="w-84">
                            <TextInput
                                id="grants-username-input"
                                name="grants-username-input"
                                labelText={t('encryption.username')}
                                invalid={Boolean(inputErrors.username)}
                                type="text"
                                invalidText={inputErrors.username}
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    inputActions.validateField({ field: 'username', skipNull: true });
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const val = e.target.value || '';
                                    inputActions.setField('username', val.trim());
                                }}
                            />
                        </div>
                    </div>
                    <div className="mb-24 flex justify-center">
                        <div className="w-84">
                            <TextInput
                                id="grants-password-input"
                                name="grants-password-input"
                                labelText={t('encryption.password')}
                                invalid={Boolean(inputErrors.password)}
                                type="password"
                                invalidText={inputErrors.password}
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    inputActions.validateField({ field: 'password', skipNull: true });
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const val = e.target.value || '';
                                    inputActions.setField('password', val.trim());
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex justify-end items-center h-16 w-full">
                    <Button
                        theme={status === 'loading' ? 'disabled' : 'secondary'}
                        className="h-full w-1/2"
                        onClick={() => initClose(true)}
                        size="lg">
                        {t('main.back')}
                    </Button>
                    {status === 'loading' ? (
                        <InlineLoading description={t('main.loading')} />
                    ) : (
                        <Button
                            id="do-update-grants-btn"
                            className="h-full w-1/2"
                            data-testid="do-update-grants-btn"
                            theme={!isValid ? 'disabled' : 'primary'}
                            onClick={doUpdateGrants}
                            size="lg">
                            {t('main.save')}
                        </Button>
                    )}
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default GrantsModal;
