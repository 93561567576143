import React from 'react';
import { t } from '@baffle/translate';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@baffle/components/src/modal/Modal';
import Button from '@baffle/components/src/buttons/Button';
import Alert from '@baffle/components/src/alerts/Alert';
import useAwait from '@baffle/api-client/src/useAwait';
import { User } from '@baffle/graphql/src/models';
import debounce from 'lodash.debounce';
import usersClient from '@baffle/api-client/src/usersClient';
import { fullNameOrEmail } from '@baffle/utilities/src/user';

interface ModalPropsWithoutChildren {
    'aria-labelledby'?: string;
    open: boolean;
    user?: User;
    onSuccess?: (fullNameOrEmail: string) => void;
    onClose?: () => void;
}

const DeleteUserModal = ({
    user,
    open,
    onSuccess = (fullNameOrEmail: string) => null,
    onClose = () => null,
}: ModalPropsWithoutChildren) => {
    const { run, error, reset } = useAwait();

    const doRemoveUser = () => {
        run(usersClient.remove(user?.id as string).then(() => onSuccess(fullNameOrEmail(user as User))));
    };

    const handleClose = () => {
        reset();
        onClose();
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <DeleteUser user={user as User} error={error} doRemoveUser={doRemoveUser} handleClose={handleClose} />
        </Modal>
    );
};

interface DeleteUserProps {
    handleClose: () => void;
    doRemoveUser: () => void;
    user: User;
    error: Error | null;
}
const DeleteUser = ({ doRemoveUser, handleClose, user, error }: DeleteUserProps) => {
    if (!user) {
        return null;
    }
    return (
        <>
            <ModalHeader onClose={handleClose}>
                <div className="flex items-start">
                    <div className="mt-0 ml-2">
                        <h3 className="text-xl font-medium text-red uppercase" id="modal-headline">
                            {t('users.removePeople')}
                        </h3>
                    </div>
                </div>
            </ModalHeader>
            <ModalBody>
                <div className="h-64 flex flex-col justify-center items-center ">
                    {error ? (
                        <div className="flex flex-wrap mb-4">
                            <div className="flex-1 px-4">
                                <Alert
                                    type="danger"
                                    title={t('main.unexpectedError')}
                                    description={error?.message || ''}
                                />
                            </div>
                        </div>
                    ) : null}
                    <div className="w-80">
                        <p
                            className="text-lg text-center mb-6"
                            dangerouslySetInnerHTML={{
                                __html: t('users.deleteConfirmationMsg', {
                                    name: fullNameOrEmail(user),
                                }),
                            }}></p>
                        <p className="text-lg text-center mb-6">{t('users.loseAccess')}</p>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex justify-end items-center h-16 w-full">
                    <Button theme="secondary" className="h-full w-1/2" onClick={handleClose} size="lg">
                        {t('main.cancel')}
                    </Button>
                    <Button
                        theme="danger"
                        data-testid="modal-remove-user-btn"
                        className="h-full w-1/2 capitalize"
                        onClick={debounce(() => doRemoveUser(), 300)}
                        size="lg">
                        {t('users.remove')}
                    </Button>
                </div>
            </ModalFooter>
        </>
    );
};

export default DeleteUserModal;
