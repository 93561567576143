import React, { useState, useEffect } from 'react';
import TextInput from '@baffle/components/src/forms/TextInput';
import { t } from '@baffle/translate';
import '../../database/AddDatabaseModal.scss';
import { InputActions, InputError, InputState, useInputState } from '@baffle/utilities/src/inputHooks';
import Joi from '@hapi/joi';
import { Modal, ModalBody, ModalFooter, ModalHeader, SkeletonModal } from '@baffle/components/src/modal/Modal';
import Button from '@baffle/components/src/buttons/Button';
import encryptionClient from '@baffle/api-client/src/encryptionClient';
import Alert from '@baffle/components/src/alerts/Alert';
import useAwait from '@baffle/api-client/src/useAwait';
import { ToastStore } from '../../stores/ToastStore';
import { DatabaseOwnerType } from '@baffle/graphql/src/models';

interface AddDbOwnerModalProps {
    open: boolean;
    onClose?: () => void;
    dbName: string;
    onSuccess?: (dbName: string) => void;
    dbId: string;
}
const AddDbOwnerModal = ({
    onClose = () => null,
    open,
    dbName,
    dbId,
    onSuccess = (dbName: string) => null,
}: AddDbOwnerModalProps) => {
    const [openModal, setOpen] = useState(open);
    const { status, run, error, reset } = useAwait();
    const { inputState, inputErrors, inputActions } = useInputState({
        keys: ['username', 'password'],
        ns: 'database',
        validationSchema: {
            username: Joi.string()
                .min(1)
                .max(30)
                .required()
                .messages({
                    'string.max': t('main.invalidNameLength'),
                    'string.empty': t('database.dbOwnerUsernameInvalid'),
                }),
            password: Joi.string()
                .min(1)
                .required()
                .messages({
                    'string.max': t('main.invalidNameLength'),
                    'string.empty': t('database.dbOwnerPasswordInvalid'),
                }),
        },
    });

    //Sync state from props
    useEffect(() => {
        setOpen(open);
    }, [open]);

    const handleClose = () => {
        onClose();
        inputActions.reset();
        reset();
    };

    const setCredentials = () => {
        run(
            encryptionClient
                .createDbOwnerCredentials({
                    id: dbId,
                    username: inputState.username as string,
                    password: inputState.password as string,
                    type: DatabaseOwnerType.SCHEMA,
                    key: dbName,
                })
                .then(() => {
                    onSuccess(dbName as string);
                    inputActions.reset();
                })
        );
    };

    useEffect(() => {
        if (error) {
            ToastStore.push({ type: 'danger', title: error.message });
        }
    }, [error]);

    return (
        <Modal open={openModal} onClose={handleClose}>
            {status === 'loading' ? (
                <SkeletonModal />
            ) : (
                <AddOwner
                    handleClose={handleClose}
                    inputErrors={inputErrors}
                    inputActions={inputActions}
                    setCredentials={setCredentials}
                    dbName={dbName}
                    inputState={inputState}
                    error={error}
                />
            )}
        </Modal>
    );
};

interface AddOwnerProps {
    handleClose: () => void;
    inputErrors: InputError;
    inputActions: InputActions;
    setCredentials: () => void;
    dbName?: string;
    inputState: InputState;
    error: Error | null;
}

const AddOwner = ({
    handleClose,
    inputActions,
    inputErrors,
    setCredentials,
    dbName,
    inputState,
    error,
}: AddOwnerProps) => {
    const isValid = inputActions.validateAll(true);
    return (
        <>
            <ModalHeader onClose={handleClose}>
                <div className="flex items-start">
                    <h3 className="text-xl font-medium text-gray-100" id="modal-headline">
                        {t('database.schemaOwnerCredTitle')}
                    </h3>
                </div>
            </ModalHeader>
            <ModalBody>
                <div className="flex flex-col justify-center items-center">
                    {error ? (
                        <div className="flex flex-wrap mb-4">
                            <div className="flex-1">
                                <Alert
                                    type="danger"
                                    title={t('database.saveDbOwnerCredFailed', { name: dbName })}
                                    description={error?.message || ''}
                                />
                            </div>
                        </div>
                    ) : null}
                    <div className="w-full mb-6" data-testid="dbOwner-username-input-testid">
                        <TextInput
                            id="dbOwner-username-input-id"
                            name="dbOwner-username-input-name"
                            labelText={t('database.dbOwnerUsernameLabel')}
                            invalid={Boolean(inputErrors.username)}
                            invalidText={inputErrors.username}
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                inputActions.validateField({
                                    field: 'username',
                                    skipNull: true,
                                });
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                inputActions.setField('username', val.trim());
                            }}
                            value={(inputState.username as string) ?? ''}
                        />
                    </div>
                    <div className="w-full" data-testid="dbOwner-password-input-testid">
                        <TextInput
                            id="dbOwner-password-input-id"
                            name="dbOwner-password-input-name"
                            labelText={t('database.dbOwnerPasswordLabel')}
                            invalid={Boolean(inputErrors.password)}
                            invalidText={inputErrors.password}
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                inputActions.validateField({
                                    field: 'password',
                                    skipNull: true,
                                });
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                inputActions.setField('password', val.trim());
                            }}
                            value={(inputState.password as string) ?? ''}
                            type="password"
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex justify-end items-center h-16 w-full">
                    <Button
                        theme="secondary"
                        className="h-full w-1/2"
                        onClick={handleClose}
                        data-testid="modal-close-db-owner-btn"
                        size="lg">
                        {t('main.cancel')}
                    </Button>
                    <Button
                        className="h-full w-1/2"
                        theme={!isValid ? 'disabled' : 'primary'}
                        onClick={() => {
                            setCredentials();
                        }}
                        data-testid="modal-add-db-owner-btn"
                        size="lg">
                        {t('main.save')}
                    </Button>
                </div>
            </ModalFooter>
        </>
    );
};

export default AddDbOwnerModal;
