import migrationClient from '@baffle/api-client/src/migrationClient';
import { MigrationStatus, CancelMigrationStatus, CancelMigrationStatusDto } from '@baffle/graphql/src/models';
import { makeObservable, action, observable } from 'mobx';
import { RequestOpts } from '../types/StoreTypes';

class MigrationStore {
    loading = false;
    setLoading = (loading: boolean) => {
        this.loading = loading;
    };
    migrations = observable<MigrationStatus>([]);
    setMigration = (m: MigrationStatus) => {
        const migrations = this.migrations.slice();
        for (let i = 0; i < migrations.length; i++) {
            const migration = migrations[i];
            if (migration.applicationID === m.applicationID) {
                migrations[i] = m;
                this.migrations.replace(migrations);
                return;
            }
        }
        migrations.push(m);
        this.migrations.replace(migrations);
    };

    cancelMigrationStatus: CancelMigrationStatus = CancelMigrationStatus.DISABLED;

    setCancelMigration = (m: CancelMigrationStatus) => {
        this.cancelMigrationStatus = m;
    };

    constructor() {
        makeObservable(this, {
            loading: observable,
            setLoading: action,
            setMigration: action,
            cancelMigrationStatus: observable,
            setCancelMigration: action,
        });
    }

    async read(id: string, opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first') {
            return;
        }
        const doFinally = () => {
            this.setLoading(false);
        };
        this.setLoading(true);
        try {
            const m = (await migrationClient.get(id)) as MigrationStatus;
            this.setMigration(m);
        } catch (error) {
            throw error;
        } finally {
            doFinally();
        }
    }
    get(id: string) {
        const m = this.migrations.find(m => m.applicationID === id);
        return m;
    }

    async readCancelMigration(id: string) {
        const doFinally = () => {
            this.setLoading(false);
        };
        this.setLoading(true);
        try {
            const m = (await migrationClient.readCancelMigration(id)) as CancelMigrationStatusDto;
            this.setCancelMigration(m.status);
        } catch (error) {
            throw error;
        } finally {
            doFinally();
        }
    }
}

export default MigrationStore;
