import databaseClient from '@baffle/api-client/src/databaseClient';
import { Database, DatabaseListItem } from '@baffle/graphql/src/models';
import { makeObservable, action, observable } from 'mobx';
import { RequestOpts } from '../types/StoreTypes';

class DatabaseServerStore {
    loading = false;
    databaseListItems = observable<DatabaseListItem>([]);
    databases = observable<Database>([]);
    setDatabaseListItems = (dbs: DatabaseListItem[]) => {
        this.databaseListItems.replace(dbs);
    };
    setDatabase = (db: Database) => {
        const databases = this.databases.slice();
        for (let i = 0; i < databases.length; i++) {
            const database = databases[i];
            if (database.id === db.id) {
                databases[i] = db;
                this.databases.replace(databases);
                return;
            }
        }
        databases.push(db);
        this.databases.replace(databases);
    };
    setLoading = (loading: boolean) => {
        this.loading = loading;
    };

    constructor() {
        makeObservable(this, {
            loading: observable,
            setLoading: action,
            setDatabase: action,
            setDatabaseListItems: action,
        });
    }

    async list(opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first') {
            return;
        }
        try {
            const dbs: DatabaseListItem[] = await databaseClient.list();
            this.setDatabaseListItems(dbs);
        } catch (error) {
            throw error;
        }
    }
    async read(id: string, opts: RequestOpts = {}, rest: { applicationId?: string } = {}) {
        if (opts.networkPolicy === 'cache-first') {
            return;
        }
        const doFinally = () => {
            this.setLoading(false);
        };
        this.setLoading(true);
        try {
            const db: Database = await databaseClient.read(id, opts);
            if (rest?.applicationId) {
                db.applicationId = rest.applicationId;
            }
            this.setDatabase(db);
        } catch (error) {
            throw error;
        } finally {
            doFinally();
        }
    }
    getByApplicationId(applicationId: string) {
        return this.databases.find(db => db.applicationId);
    }
}

export default DatabaseServerStore;
