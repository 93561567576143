import React, { useEffect, useMemo } from 'react';
import { Modal, TextInput } from 'carbon-components-react';
import { t } from '@baffle/translate';
import { DatabaseProxiesStore } from '@baffle/manager/src/stores';
import { Observer } from 'mobx-react-lite';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import { databaseProxyConfigScheme } from './addConfigInputScheme';
import useAwait from '@baffle/api-client/src/useAwait';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';
import { AdvanceConfiguration, KeyValuePairsMap } from '@baffle/graphql/src/models';

interface AddConfigProps {
    id: string;
    open: boolean;
    onClose: () => void;
    onSuccess?: (name: string) => void;
}

const AddConfig = ({ open, onClose, onSuccess, id }: AddConfigProps) => {
    const { run, error } = useAwait();

    const animateModal = () => {
        return new Promise(() => {
            setTimeout(() => {
                if (onClose) {
                    onClose();
                }
            }, 200);
        });
    };

    useEffect(() => {
        if (error) {
            NotificationStore.push({
                'data-testid': 'add-config-failure',
                kind: 'error',
                title: t('databaseProxies.databaseProxyConfigurations.addConfigError'),
                description: error.message ?? '',
            });
        }
    }, [error]);

    const databaseProxyConfigSchemes = useMemo(
        () => databaseProxyConfigScheme(DatabaseProxiesStore.advanceConfigurations as KeyValuePairsMap),
        [DatabaseProxiesStore.advanceConfigurations]
    );
    //@ts-ignore
    const { inputState, inputErrors, inputActions } = useInputState(databaseProxyConfigSchemes);
    const isValid = inputActions.validateAll(true);

    const handleClose = async () => {
        await animateModal();
    };

    const handleAddConfig = () => {
        let advanceConfig: AdvanceConfiguration = databaseProxyConfigSchemes.keys.reduce((acc: any, c: any) => {
            acc[c] = (inputState[c] as string)?.trim();
            return acc;
        }, {});

        DatabaseProxiesStore.advanceConfigurations[advanceConfig.key] = advanceConfig.value;
        let payload: KeyValuePairsMap = DatabaseProxiesStore.advanceConfigurations;
        run(
            DatabaseProxiesStore.updateDatabaseProxyConfiguration(
                id,
                { configParam: 'advancedConfig' },
                { advancedConfig: payload }
            ).then(() => {
                if (onSuccess) {
                    onSuccess(advanceConfig.key);
                }
                handleClose();
            })
        );
    };

    return (
        <Observer>
            {() => {
                return (
                    <Modal
                        id="advance-config-add-modal"
                        className="modal-container-white"
                        open={open}
                        modalLabel={t('databaseProxies.databaseProxyConfigurations.addConfigHeadingMessage')}
                        modalHeading={t('databaseProxies.databaseProxyConfigurations.addConfigHeading')}
                        onRequestClose={onClose}
                        primaryButtonText={t('databaseProxies.databaseProxyConfigurations.add')}
                        secondaryButtonText={t('main.cancel')}
                        size="sm"
                        onRequestSubmit={() => handleAddConfig()}
                        primaryButtonDisabled={!isValid}>
                        <div className="flex gap-x-8">
                            <TextInput
                                id="add-config-key-input"
                                className="text-input-gray10"
                                name="key"
                                data-testid="add-config-key-input"
                                labelText={t('databaseProxies.databaseProxyConfigurations.key')}
                                placeholder={t('databaseProxies.databaseProxyConfigurations.keyPlaceholder')}
                                invalid={Boolean(inputErrors.key)}
                                invalidText={inputErrors.key}
                                value={(inputState.key as string) || ''}
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    inputActions.validateField({
                                        field: 'key',
                                        skipNull: true,
                                    });
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const val = e.target.value || '';
                                    inputActions.setField('key', val);
                                }}
                            />
                            <TextInput
                                id="add-config-value-input"
                                className="text-input-gray10"
                                name="add-config-value-input"
                                data-testid="add-config-value-input"
                                labelText={t('databaseProxies.databaseProxyConfigurations.value')}
                                placeholder={t('databaseProxies.databaseProxyConfigurations.valuePlaceholder')}
                                invalid={Boolean(inputErrors.value)}
                                invalidText={inputErrors.value}
                                value={(inputState.value as string) || ''}
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    inputActions.validateField({
                                        field: 'value',
                                        skipNull: true,
                                    });
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const val = e.target.value || '';
                                    inputActions.setField('value', val);
                                }}
                            />
                        </div>
                    </Modal>
                );
            }}
        </Observer>
    );
};

export default AddConfig;
