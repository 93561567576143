import React, { useState } from 'react';
import BaffleSideNav from './nav/BaffleSideNav';
import NotificationsSidePanel from './sidepanel/NotificationsSidePanel';
import HelpSidePanel from './help/HelpSidePanel';
import IBMHelpSidePanel from './help/IBMHelpSidePanel';
import { AgentErrorStore, ShieldStore } from './stores';
import { Observer } from 'mobx-react-lite';
import GlobalToast from '@baffle/components/src/toasts/GlobalToast';
import { BaseCompoundComponentProps } from '@baffle/components/src/types/react';
import logger from '@baffle/utilities/src/logger';
import DismissAllShieldNotificationsModal from './shields/DismissAllShieldNotifications';
import { useManageModals } from '../../components/src/modal/Modal';
import { Header, HeaderName } from 'carbon-components-react';
import BaffleLogoIcon from '@baffle/components/src/icons/BaffleLogoIcon';
import BaffleTextIcon from '@baffle/components/src/icons/BaffleTextIcon';
import GlobalNotification from '@baffle/components/src/notifications/GlobalNotification';
import { SystemEmailStore } from './stores';

const BaffleApp = ({ children }: BaseCompoundComponentProps) => {
    const initialToggledPanels = {
        notifications: false,
        help: false,
    };
    const [toggledPanels, setTogglePanels] = useState(initialToggledPanels);

    const doTogglePanel = (key: 'notifications' | 'help') => {
        setTogglePanels(Object.assign({}, initialToggledPanels, { [key]: !toggledPanels[key] }));
    };

    interface BaffleAppModals {
        email: boolean;
        dismissNotifications: boolean;
    }
    const initialModals = {
        email: false,
        dismissNotifications: false,
        data: {},
    };
    const [modals, setModals] = useManageModals<BaffleAppModals>(initialModals);
    const clientEnvironment = SystemEmailStore.systemEnvironment;
    return (
        <div className="h-screen flex overflow-hidden bg-gray-10">
            <Observer>
                {() => {
                    return (
                        <>
                            {clientEnvironment === 'IBM_CLOUD' ? (
                                <Header aria-label="IBM Platform Name">
                                    <HeaderName href="#" prefix="IBM">
                                        Cloud
                                    </HeaderName>
                                </Header>
                            ) : (
                                <Header aria-label="Baffle Platform Name">
                                    <BaffleLogoIcon className="ml-4 w-10 h-10" width={20} height={22} />
                                    <BaffleTextIcon />
                                </Header>
                            )}
                            <BaffleSideNav
                                notificationsPanelOpen={toggledPanels.notifications}
                                helpPanelOpen={toggledPanels.help}
                                toggleNotifications={() => doTogglePanel('notifications')}
                                toggleHelp={() => doTogglePanel('help')}
                            />
                            {children}
                            <NotificationsSidePanel
                                didClearNotification={() => {
                                    AgentErrorStore.list().catch(err => {
                                        logger.error(err);
                                    });
                                    AgentErrorStore.readCount().catch(err => {
                                        logger.error(err);
                                    });
                                }}
                                triggerDismissAllNotifications={() => {
                                    setModals(
                                        Object.assign({}, initialModals, {
                                            dismissNotifications: true,
                                        })
                                    );
                                }}
                                toggleNotifications={() => doTogglePanel('notifications')}
                                open={toggledPanels.notifications}
                            />
                        </>
                    );
                }}
            </Observer>
            <DismissAllShieldNotificationsModal
                prefix="baffleapp-"
                onSuccess={() => {
                    ShieldStore.list({ resource: 'ShieldListItem' }).then(err => logger.error(err));
                    AgentErrorStore.list().catch(err => {
                        logger.error(err);
                    });
                    AgentErrorStore.readCount().catch(err => {
                        logger.error(err);
                    });
                }}
                open={modals.dismissNotifications}
                onClose={() => setModals(initialModals)}
            />
            {clientEnvironment === 'IBM_CLOUD' ? (
                <IBMHelpSidePanel toggleHelp={() => doTogglePanel('help')} open={toggledPanels.help} />
            ) : (
                <HelpSidePanel toggleHelp={() => doTogglePanel('help')} open={toggledPanels.help} />
            )}
            <GlobalToast />
            <GlobalNotification />
        </div>
    );
};

export default BaffleApp;
