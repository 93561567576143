import React, { useState } from 'react';
import { t } from '@baffle/translate';
import { Search } from 'carbon-components-react';
import SkeletonText from '@baffle/components/src/skeleton/SkeletonText';
import { ColumnMetaData, MigrationStatusTable } from '@baffle/graphql/src/models';
import { stringSort, numberSort } from '@baffle/utilities/src/sorters';
import { sortGrid, SortInfo, SortDirection } from '@baffle/utilities/src/gridHelpers';
import usePageHeight from '@baffle/utilities/src/usePageHeight';
import ReactDataGrid from 'react-data-grid';
import TH from '@baffle/components/src/table/react-data-grid/TH';
import setKeyDisplay from '@baffle/utilities/src/setKeyDisplay';
import { RequestStatus } from '@baffle/api-client/src/useAwait';
import Cell from '@baffle/components/src/table/react-data-grid/Cell';

interface Props {
    selectedTable: MigrationStatusTable | null;
    status: RequestStatus;
}
const MigrationColumnsTable = ({ selectedTable, status }: Props) => {
    const pageHeight = usePageHeight();
    const rowHeight = 35;
    const pageHeightOffset = 260;
    const sizeOffset = pageHeightOffset + 18; //sets how much space is used to calculate empty rows
    const size = Math.floor((pageHeight - sizeOffset) / rowHeight);

    const initialDBGridSort: SortInfo = { direction: 'NONE', key: '' };
    const [sort, setSort] = useState(initialDBGridSort);

    const initialResults: ColumnMetaData[] = [];
    const [filtered, setFiltered] = useState({ results: initialResults, query: '' });

    const columnsList =
        selectedTable && selectedTable.columns
            ? Object.keys(selectedTable.columns).map((k: string) => selectedTable.columns[k])
            : [];
    const columns: ColumnMetaData[] = filtered.query !== '' ? filtered.results : columnsList;

    const filterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value || '';
        const results = columns.filter((d: ColumnMetaData) => d.name.toLowerCase().includes(query));

        setFiltered({ results, query });
    };

    const skeletonCol = {
        id: '',
        select: '',
        header1: <SkeletonText />,
        encMode: <SkeletonText />,
        dataFormat: <SkeletonText />,
        header5: <SkeletonText />,
        header6: ' ',
    };

    const emptyCol = {
        id: '',
        select: '',
        header1: '',
        encMode: '',
        dataFormat: '',
        header5: '',
        header6: '',
    };

    const cols = [
        {
            key: 'header1',
            name: t('encryption.columnGrid.header1'),
            sortable: true,
            formatter: Cell,
            sortFn: (a: ColumnMetaData, b: ColumnMetaData) => stringSort(a.name, b.name),
            headerRenderer: <TH idPrefix="dbGrid" />,
        },
        {
            key: 'maskMode',
            name: t('encryption.columnGrid.maskMode'),
            sortable: true,
            formatter: Cell,
            sortFn: (a: ColumnMetaData, b: ColumnMetaData) =>
                stringSort(a.selections.maskingMode?.name, b.selections.maskingMode?.name),
            headerRenderer: <TH idPrefix="dbGrid" />,
        },

        {
            key: 'header5',
            name: t('encryption.columnGrid.header5'),
            sortable: true,
            formatter: Cell,
            sortFn: (a: ColumnMetaData, b: ColumnMetaData) => numberSort(a.keyID, b.keyID),
            headerRenderer: <TH idPrefix="dbGrid" />,
            width: 75,
        },
        {
            key: 'header6',
            name: ' ',
            width: 10,
        },
    ];

    sortGrid(sort, cols, columns);

    const rows =
        status === 'idle' || status === 'loading'
            ? //add  skeleton row on initial load
              [...Array(Math.floor(1)).keys()].map(() => skeletonCol)
            : columns.map((d: ColumnMetaData, i: number) => ({
                  id: d.path,
                  header1: d.name,
                  encMode: d.selections.encryptionMode?.name || 'N/A',
                  maskMode: d.selections.maskingMode?.name || 'N/A',
                  header5: setKeyDisplay(d.keyID),
                  header6: ' ',
                  columnIndex: i,
              }));

    //add empty rows to fill the page, if needed
    if (columns.length < size) {
        const sizeDelta = size - columns.length;
        //@ts-ignore
        rows.push(...[...Array(sizeDelta).keys()].map(() => emptyCol));
    }

    const minHeight = pageHeight - pageHeightOffset;
    return (
        <>
            {/* controlled input so that we can force clear the value */}
            <Search
                size="sm"
                closeButtonLabelText={t('main.ariaCloseSearch')}
                className="encryption-grid-filter mb2"
                labelText={t('encryption.columnGrid.ariaSearch')}
                disabled={!Boolean(selectedTable)}
                value={filtered.query}
                placeholder={t('encryption.columnGrid.ariaSearch')}
                onChange={filterChange}
                id="encryption-columngrid-filter"
                autoComplete="none"
            />
            <div className="relative" style={{ minHeight }}>
                <div className="absolute w-full">
                    <ReactDataGrid
                        onGridSort={(key: string, direction: SortDirection) => setSort({ direction, key })}
                        //@ts-ignore
                        columns={cols}
                        rowGetter={i => rows[i]}
                        rowsCount={rows.length}
                        minHeight={minHeight}
                    />
                </div>
            </div>
        </>
    );
};

export default MigrationColumnsTable;
