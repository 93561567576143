import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { BaffleIconProps } from '../componentTypes';

const ApplicationIcon = ({ color = '#868d95', ...rest }: BaffleIconProps) => {
    return (
        <SvgIcon {...rest}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-113.000000, -42.000000)" fill={color}>
                    <g id="Group-11" transform="translate(113.000000, 42.000000)">
                        <rect x="3.33333333" y="6.66666667" width="4.16666667" height="4.16666667"></rect>
                        <rect x="3.33333333" y="12.5" width="4.16666667" height="4.16666667"></rect>
                        <rect x="9.16666667" y="6.66666667" width="4.16666667" height="4.16666667"></rect>
                        <rect x="9.16666667" y="12.5" width="4.16666667" height="4.16666667"></rect>
                        <polygon
                            fillRule="nonzero"
                            points="0.5 5.33333333 19.5374349 5.33333333 19.5374349 3.83333333 0.5 3.83333333"></polygon>
                        <path
                            d="M1,0 L19,0 C19.5522847,0 20,0.44771525 20,1 L20,19 C20,19.5522847 19.5522847,20 19,20 L1,20 C0.44771525,20 0,19.5522847 0,19 L0,1 C0,0.44771525 0.44771525,0 1,0 Z M1.5,1.5 L1.5,18.5 L18.5,18.5 L18.5,1.5 L1.5,1.5 Z"
                            fillRule="nonzero"></path>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

export default ApplicationIcon;
