import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import './SlideOutPanel.scss';
import { BaseCompoundComponentProps } from '../types/react';

interface props extends BaseCompoundComponentProps {
    open: boolean;
    target: HTMLElement;
    className?: string;
    didClose?: () => void;
    didOpen?: () => void;
    willClose?: () => void;
    willOpen?: () => void;
}

const SlideOutPanel = ({
    open = false,
    target,
    children,
    className = '',
    didClose = () => null,
    didOpen = () => null,
    willOpen = () => null,
    willClose = () => null,
}: props) => {
    const animationDuration = 500;
    const panelWidth = 400;
    const panelLeftHidden = -(panelWidth + 20); //20 adds 20 pixels to push the element off the screen
    const [animate, setAnimate] = useState({ left: panelLeftHidden });

    let animationTimeout: NodeJS.Timeout;

    useEffect(() => {
        clearTimeout(animationTimeout);

        if (open) {
            //get target offset
            willOpen();
            const targetLeft = target.getBoundingClientRect().left;
            const targetWidth = target.getBoundingClientRect().width;
            const offset = targetLeft + targetWidth;
            //set the offset and let the css animate that to the target offset
            setAnimate({ left: offset });
            animationTimeout = setTimeout(() => {
                didOpen();
            }, animationDuration);
        } else {
            //animate back to the hidden position
            willClose();
            setAnimate({ left: panelLeftHidden });
            animationTimeout = setTimeout(() => {
                didClose();
            }, animationDuration);
        }
    }, [open]);

    return (
        <div className={cx('slideout-panel fixed h-screen bg-white shadow-md mt-12', className)} style={animate}>
            {children}
        </div>
    );
};

export default SlideOutPanel;
