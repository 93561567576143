import gql from 'graphql-tag';

import { TYPE_NAMES } from './index';

export const CHECK_APPLICATION_ACCESS = gql`
    query CheckApplicationAccess {
        applicationAccess @rest(type: "[${TYPE_NAMES.ApplicationAccess}]", path: "init/application_access_check", endpoint: "v0") {
            locked
        }
    }
`;

export const GET_SYSTEM = gql`
    query GetSystem {
        systemInfo @rest(type:"${TYPE_NAMES.SystemInfo}" path: "/api/public/v2/configurations/init", method: "GET") {
            publicAccess
            proxyAccess
        }
    }
`;

export const GET_TENANT_LOCAL = gql`
    query GetTenantLocal {
        tenant @client {
            name
        }
    }
`;

export const SET_SYSTEM_EMAIL = gql`
    mutation SetSystem($input: Object!, $tenant: String!) {
        systemEmail(input: $input, tenant: $tenant) @rest(type:"${TYPE_NAMES.SystemInfoEmail}" path: "init/email/{args.tenant}", method: "POST", endpoint: "v0") {
            NoResponse
        }
    }
`;

export const SET_INITIAL_CONFIGURATION = gql`
    mutation SetInitialConfiguration($input: Object!) {
        setInitialConfiguration(input: $input)
            @rest(type:"${TYPE_NAMES.InitialConfiguration}" path: "/api/public/v2/configurations/init", method: "POST") {
            NoResponse
        }
    }
`;

export const SET_TLS = gql`
    mutation SetTLS($input: Object!, $tenant: String!, $formSerializer: any) {
        setTLS(input: $input, tenant: $tenant)
            @rest(
                type:"${TYPE_NAMES.TLS}"
                path: "init/certificate/upload/{args.tenant}"
                method: "POST"
                endpoint: "v0"
                bodySerializer: $formSerializer
            ) {
            NoResponse
        }
    }
`;
