import React, { useState, useEffect, useMemo } from 'react';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import { useParams } from 'react-router-dom';
import PageHeader from '@baffle/components/src/pageheader/pageheader';
import Content from '@baffle/components/src/content/Content';
import keystoreClient from '@baffle/api-client/src/keystoreClient';
import { ToastStore } from '../stores/ToastStore';
import { Overview } from '@baffle/components/src/overview/Overview';
import { t } from '@baffle/translate';
import DataGrid from '@baffle/components/src/grid/data-grid';
import cx from 'classnames';
import { Modal, TextInput, TextArea } from 'carbon-components-react';
import createKeystoreInputScheme from '../keystore/keystoreInputSchemes/createKeystoreInputScheme';
import { KeystoreTypeEnum } from '@baffle/graphql/src/models';
import { CreateModal, CreateModalBody, CreateModalHeader } from '@baffle/components/src/CreateModal/CreateModal';

const KeystoreCMK = () => {
    const { id, cmk } = useParams() as { id: string; cmk: string | undefined };
    const [keystore, setKeystore] = useState({ name: '' });

    const initialModalState = {
        showRenameModal: false,
        showAddDekModal: false,
    };
    const [showModal, setShowModal] = useState(initialModalState);
    const keystoreInputSchemes = useMemo(() => createKeystoreInputScheme(), []);

    const getKeystore = async () => {
        try {
            const ks = await keystoreClient.read(id as string);
            setKeystore(ks);
        } catch (error) {
            ToastStore.push({
                type: 'danger',
                title: error.message,
                'data-testid': 'keystore-details-failure-toast',
            });
        }
    };
    useEffect(() => {
        getKeystore();
        inputActions.reset();
    }, []);

    console.log('id: ', id);
    console.log('cmk: ', cmk);
    //@ts-ignore
    const { inputState, inputErrors, inputActions } = useInputState(keystoreInputSchemes[KeystoreTypeEnum.LOCAL]);

    //TODO: Dummay mapping for now to check the look and feel. To be updated once the proper BE APIs are available.
    const cmkDetails = {
        id: cmk?.replace('cmk-', ''),
        name: 'CMK-' + cmk?.replace('cmk-', ''),
        createTime: '', //format(keystore.createTime, 'MMM dd, yyyy H:mm:ss a'),
        lastRotation: '',
        alias: 'baffle-external-cmk-1',
        arn: '',
        autoRotate: '',
        rotationSchedule: '',
    };

    const actions = [
        {
            id: '1',
            label: t('keystorev2.rename'),
        },
        {
            id: '2',
            label: t('keystore.rotateKey'),
        },
    ];

    const breadcrumbData = [
        {
            label: t('main.keystores'),
            href: '/keystoresV2',
        },
        {
            label: keystore.name,
            href: '/keystore/' + id,
        },
    ];

    const onSelectAction = (item: any) => {
        switch (item.selectedItem.label) {
            case t('keystorev2.rename'):
                inputActions.setField('name', cmkDetails.name);
                setShowModal(prevState => {
                    return { ...prevState, showRenameModal: true };
                });
                break;
            case t('keystore.rotateKey'):
                console.log('Rotate Key action selected');
                break;
            default:
                console.log('Unknown action selected');
                break;
        }
    };

    const headers = [
        {
            key: 'header1',
            header: t('main.name'),
        },
        {
            key: 'header2',
            header: t('keystorev2.keyId'),
        },
        {
            key: 'header3',
            header: t('keystorev2.entityId'),
        },
        {
            key: 'header4',
            header: t('keystorev2.createTime'),
        },
    ];

    //TODO: Dummy data for now to check the look and feel. Once the BE API is available will be updated.
    const rows: any = [
        {
            id: '1',
            header1: 'DEK-1',
            header2: '2',
            header3: '12345',
            header4: 'Aug 10, 2022 5:52:22 PM',
        },
    ];
    //TODO: Need BE API to link it.
    const onAdd = () => {
        inputActions.reset();
        setShowModal(prevState => {
            return { ...prevState, showAddDekModal: true };
        });
    };

    const onSyncButtonClick = () => {
        console.log('Sync Button clicked');
    };

    const isValid = inputActions.validateIndividualField({ field: 'name', skipNull: false });

    const handleSave = () => {
        console.log('Save Button Clicked');
    };

    return (
        <>
            <Modal
                open={showModal.showRenameModal}
                modalHeading={t('keystorev2.renameCmkTitle')}
                onRequestClose={() => {
                    setShowModal(prevState => {
                        return { ...prevState, showRenameModal: false };
                    });
                }}
                primaryButtonText={t('main.rename')}
                secondaryButtonText={t('main.cancel')}
                size="sm"
                onRequestSubmit={() => {
                    setShowModal(prevState => {
                        return { ...prevState, showRenameModal: false };
                    });
                    //TODO: Call the BE API to rename and refresh the page.
                }}
                primaryButtonDisabled={!isValid}>
                <TextInput
                    id="keystore-rename-input"
                    name="keystore-rename-input"
                    data-testid="keystore-rename-input"
                    labelText={t('main.name')}
                    invalid={Boolean(inputErrors.name)}
                    invalidText={inputErrors.name}
                    value={(inputState.name as string) || ''}
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                        inputActions.validateField({
                            field: 'name',
                            skipNull: true,
                        });
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const val = e.target.value || '';
                        inputActions.setField('name', val);
                    }}
                />
            </Modal>
            <CreateModal
                open={showModal.showAddDekModal}
                onClose={() => {
                    setShowModal(prevState => {
                        return { ...prevState, showAddDekModal: false };
                    });
                }}
                isValid={isValid}
                modalId="add-user-group-modal"
                primaryButtonText={t('keystorev2.addDek')}
                primaryButtonTheme="primary"
                primaryButton={() => handleSave()}>
                <CreateModalHeader titleText={t('keystorev2.addDekTitle')} />
                <CreateModalBody>
                    <div className="text-sm font-normal leading-5 mb-4 mt-2 mr-6">
                        {t('keystorev2.addDekModalMessage')}
                    </div>
                    <div className="flex flex-wrap mb-6 items-end">
                        <div className="flex-1 text-sm name-input-container">
                            <TextInput
                                id="dek-name-input"
                                name="dek-name-input"
                                data-testid="dek-name-input"
                                labelText={t('main.name')}
                                placeholder={t('keystorev2.namePlaceholder')}
                                invalid={Boolean(inputErrors.name)}
                                invalidText={inputErrors.name}
                                value={(inputState.name as string) || ''}
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    inputActions.validateField({
                                        field: 'name',
                                        skipNull: true,
                                    });
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    let val = e.target.value || '';
                                    inputActions.setField('name', val);
                                }}
                            />
                            <span className="input-char-counter right-0">
                                {(inputState.name as string)?.length ?? 0} / 30
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-wrap mb-6">
                        <div className="flex-1 relative">
                            <TextArea
                                id="dek-description-input"
                                name="dek-description-input"
                                data-testid="dek-description-input"
                                labelText={t('keystorev2.descriptionInputLabel')}
                                placeholder={t('keystorev2.descriptionPlaceholder')}
                                value={(inputState.description as string) || ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const val = e.target.value || '';
                                    inputActions.setField('description', val);
                                }}
                            />
                            <span className="input-char-counter right-0">
                                {(inputState.description as string)?.length ?? 0} / 100
                            </span>
                        </div>
                    </div>
                </CreateModalBody>
            </CreateModal>

            <Content>
                <PageHeader
                    title={cmk ? cmk : ''}
                    actions={actions}
                    breadcrumbData={breadcrumbData}
                    onActionSelect={onSelectAction}
                />
                <div className="px-8 mt-4 text-sm">
                    <div className={'pb-2 w-40 border-solid border-b-2 border-blue-60 cursor-pointer'}>
                        <p className="ml-4 text-lg">{t('keystorev2.overview')}</p>
                    </div>
                </div>
                <div className="overflow-y-auto h-80p">
                    <div className="pl-5 pr-5">
                        <Overview title={t('keystorev2.cmkDetails')} info={cmkDetails} topic="keystorev2" />
                    </div>
                    <div className="flex-1 px-0 pt-10 flex justify-between">
                        <div className="flex-1 flex items-center">
                            <h1 className="pl-10 text-heading-01 text-gray-100">{t('keystorev2.dek')}</h1>
                        </div>
                    </div>
                    <div id="table-container" className={cx('datagrid-container keystore-datagrid flex-grow')}>
                        <DataGrid
                            idPrefix="keystores"
                            rows={rows}
                            headers={headers}
                            showSearch={true}
                            onAdd={onAdd}
                            addButtonCaption={t('keystorev2.dek+')}
                            onSync={onSyncButtonClick}></DataGrid>
                    </div>
                </div>
            </Content>
        </>
    );
};

export default KeystoreCMK;
