export const stringSort = (a: string = '', b: string = '') => {
    const stringA = a.toLowerCase();
    const stringB = b.toLowerCase();
    if (stringA < stringB) {
        return -1;
    }
    if (stringA > stringB) {
        return 1;
    }
    return 0;
};
export const boolSort = (a: boolean = false, b: boolean = false) => {
    if (a && !b) {
        return -1;
    }
    if (!a && b) {
        return 1;
    }
    return 0;
};

export const numberSort = (a: number = 0, b: number = 0) => {
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return 0;
};
