import React, { useState, useEffect, useMemo } from 'react';
import { t } from '@baffle/translate';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import { CreateModal, CreateModalBody, CreateModalHeader } from '@baffle/components/src/CreateModal/CreateModal';
import { TextInput, Select, SelectItem } from 'carbon-components-react';
import {
    JwtClaimInfo,
    RbacConfigMode,
    RbacConfigModes,
    RbacConfigUserDetermination,
    RbacConfigUserDeterminations,
    RbacConfigV2,
} from '@baffle/graphql/src/models';
import useAwait from '@baffle/api-client/src/useAwait';
import { DatabaseProxiesStore } from '@baffle/manager/src/stores';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';
import { rbacConfigScheme } from './addConfigInputScheme';
import { jsonToArray } from '@baffle/utilities/src/column/dataFormat';
import RbacConfigSqlCommentJwt from './RbacConfigSqlCommentJwt';

interface UpdateRbacProps {
    id: string;
    open: boolean;
    onClose?: () => void;
    onSuccess?: () => void;
}

const UpdateRbac = ({ id, open, onClose, onSuccess }: UpdateRbacProps) => {
    const [openModal, setOpen] = useState(open);
    const { run, error, reset } = useAwait();
    const databaseProxyConfigSchemes = useMemo(() => rbacConfigScheme(), []);

    const isEnabled = DatabaseProxiesStore.databaseProxyConfiguration?.rbacConfig.enabled;
    const existingConfig = DatabaseProxiesStore.databaseProxyConfiguration?.rbacConfig;

    const [configType, setConfigType] = useState(
        isEnabled ? existingConfig?.userDetermination : RbacConfigUserDetermination.Session
    );

    //@ts-ignore
    const { inputState, inputErrors, inputActions } = useInputState(databaseProxyConfigSchemes[configType]);
    const modeItems = Object.keys(RbacConfigMode).map(key => ({
        id: key,
        label: key,
        'data-testid': `rbacConfigMode-li-${key}`,
    }));

    const userDeterminationItems = Object.keys(RbacConfigUserDetermination).map(key => ({
        id: key,
        label: key,
        'data-testid': `rbacConfigUserDetermination-li-${key}`,
    }));

    const animateModal = () => {
        return new Promise(() => {
            setTimeout(() => {
                if (onClose) {
                    onClose();
                }
            }, 200);
        });
    };

    useEffect(() => {
        if (error) {
            NotificationStore.push({
                'data-testid': 'update-rbac-config-failure',
                kind: 'error',
                title: t('databaseProxies.databaseProxyConfigurations.updateConfigError'),
                description: error.message ?? '',
            });
        }
    }, [error]);

    const handleClose = async () => {
        if (!openModal) {
            return;
        }
        setOpen(false);
        reset();
        await animateModal();
        inputActions.reset();
    };

    useEffect(() => {
        const {
            mode,
            userDetermination,
            sqlCommentPrefix,
            jwtSecretKey,
            jwksProviderUrl,
            jwtClaims,
        } = existingConfig as RbacConfigV2;
        inputActions.setFields({
            mode,
            userDetermination,
            sqlCommentPrefix,
            jwtSecretKey: jwtSecretKey || '',
            jwksProviderUrl: jwksProviderUrl || '',
            jwtClaims: jwtClaims ? jsonToArray(jwtClaims) : [{ key: '', value: '', jwtClaimData: '' }],
        });
    }, []);

    //Sync state from props
    useEffect(() => {
        setOpen(open);
    }, [open]);

    const isValid = inputActions.validateAll(true);

    const handleUpdateRbacConfig = () => {
        if (configType === RbacConfigUserDetermination.SQL_COMMENT_JWT) {
            if (!Boolean(inputState.jwtSecretKey) && !Boolean(inputState.jwksProviderUrl)) {
                NotificationStore.push({
                    'data-testid': 'update-rbac-jwt-failure',
                    kind: 'error',
                    title: t('databaseProxies.databaseProxyConfigurations.updateRbacJwtFailure'),
                });
                return;
            }
        }

        let payload: RbacConfigV2 = databaseProxyConfigSchemes[configType as RbacConfigUserDetermination].keys.reduce(
            (acc: any, c: any) => {
                switch (c) {
                    case 'jwtClaims':
                        const jwtClaims = inputState[c] as Array<JwtClaimInfo>;
                        let obj = {};
                        jwtClaims.map(item => {
                            Object.assign(obj, { [item.key]: item.value });
                        });
                        acc[c] = obj;
                        break;
                    default:
                        if (inputState[c]) {
                            acc[c] = inputState[c];
                        }
                        break;
                }
                return acc;
            },
            {}
        );

        run(
            DatabaseProxiesStore.updateDatabaseProxyConfiguration(
                id,
                { configParam: 'rbacConfig' },
                { rbacConfig: payload }
            ).then(() => {
                if (onSuccess) {
                    onSuccess();
                }
                handleClose();
            })
        );
    };

    return (
        <CreateModal
            open={openModal}
            onClose={handleClose}
            isValid={isValid}
            modalId="update-rbac-config-modal"
            primaryButtonText={t('main.update')}
            primaryButtonTheme="primary"
            primaryButton={() => handleUpdateRbacConfig()}>
            <CreateModalHeader titleText={t('databaseProxies.databaseProxyConfigurations.updateRbacConfig')} />
            <CreateModalBody>
                <div className="flex flex-wrap mb-6 items-end">
                    <div className="flex-1 mode-input-container">
                        <Select
                            id="rbac-config-mode-input"
                            labelText={t('databaseProxies.databaseProxyConfigurations.modeLabel')}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                inputActions.setField('mode', val);
                            }}
                            value={inputState.mode || ''}>
                            <SelectItem hidden text={t('main.chooseOption')} value="placeholder-item" />
                            {modeItems.map((mode, i) => {
                                return (
                                    <SelectItem
                                        datatest_id={mode['data-testid']}
                                        key={i}
                                        text={mode.label}
                                        value={RbacConfigMode[mode.id as RbacConfigModes]}
                                    />
                                );
                            })}
                        </Select>
                    </div>
                </div>
                <div className="flex flex-wrap mb-6 items-end">
                    <div className="flex-1 user-determination-input-container">
                        <Select
                            id="rbac-config-user-determination-input"
                            labelText={t('databaseProxies.databaseProxyConfigurations.userDetermination')}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                setConfigType(val as RbacConfigUserDetermination);
                                inputActions.setField('userDetermination', val);
                            }}
                            value={inputState.userDetermination || ''}>
                            <SelectItem hidden text={t('main.chooseOption')} value="placeholder-item" />
                            {userDeterminationItems.map((userDetermination, i) => {
                                return (
                                    <SelectItem
                                        datatest_id={userDetermination['data-testid']}
                                        key={i}
                                        text={userDetermination.label}
                                        value={
                                            RbacConfigUserDetermination[
                                                userDetermination.id as RbacConfigUserDeterminations
                                            ]
                                        }
                                    />
                                );
                            })}
                        </Select>
                    </div>
                </div>
                {inputState.userDetermination === RbacConfigUserDetermination.SQL_COMMENT_RAW ? (
                    <div className="flex flex-wrap mb-6 items-end">
                        <div className="flex-1 text-sm sql-comment-prefix-input-container">
                            <TextInput
                                id="sql-comment-prefix-input"
                                name="sql-comment-prefix-input"
                                data-testid="sql-comment-prefix-input"
                                labelText={t('databaseProxies.databaseProxyConfigurations.sqlCommentPrefix')}
                                placeholder={t(
                                    'databaseProxies.databaseProxyConfigurations.sqlCommentPrefixPlaceholder'
                                )}
                                invalid={Boolean(inputErrors.sqlCommentPrefix)}
                                invalidText={inputErrors.sqlCommentPrefix}
                                value={(inputState.sqlCommentPrefix as string) || ''}
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    inputActions.validateField({
                                        field: 'sqlCommentPrefix',
                                        skipNull: true,
                                    });
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    let val = e.target.value || '';
                                    inputActions.setField('sqlCommentPrefix', val);
                                }}
                            />
                        </div>
                    </div>
                ) : null}
                {inputState.userDetermination === RbacConfigUserDetermination.SQL_COMMENT_JWT ? (
                    <RbacConfigSqlCommentJwt
                        inputState={inputState}
                        inputActions={inputActions}
                        inputErrors={inputErrors}
                    />
                ) : null}
            </CreateModalBody>
        </CreateModal>
    );
};

export default UpdateRbac;
