import React, { useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import { t } from '@baffle/translate';
import SkeletonText from '@baffle/components/src/skeleton/SkeletonText';
import { ApplicationV2, ShieldForApplicationDetails } from '@baffle/graphql/src/models';
import { stringSort } from '@baffle/utilities/src/sorters';
import TH from '@baffle/components/src/table/react-data-grid/TH';
import { RequestStatus } from '@baffle/api-client/src/useAwait';
import Cell from '@baffle/components/src/table/react-data-grid/Cell';
import Status from '@baffle/components/src/status/Status';
import { StatusMaps } from '@baffle/utilities/src/enums';

interface Props {
    application: ApplicationV2;
    status: RequestStatus;
}
const ShieldsDataGridCondensed = ({ application, status }: Props) => {
    const columns = [
        {
            key: 'header1',
            name: t('shield.host'),
            formatter: Cell,
            sortable: true,
            sortFn: (a: ShieldForApplicationDetails, b: ShieldForApplicationDetails) => stringSort(a.host, b.host),
            headerRenderer: <TH idPrefix="appshield" />,
        },
        {
            key: 'header2',
            name: t('shield.header4'),
            sortable: true,
            sortFn: (a: ShieldForApplicationDetails, b: ShieldForApplicationDetails) => stringSort(a.status, b.status),
            headerRenderer: <TH idPrefix="appshield" />,
        },
        {
            key: 'space',
            name: ' ',
            width: 5,
        },
    ];
    const shields: ShieldForApplicationDetails[] = application.shieldDetails.shields;

    const [sort, setSort] = useState({ direction: 'NONE', key: '' });

    const skeletonCol = {
        header1: <SkeletonText />,
        header2: <SkeletonText />,
        space: ' ',
    };

    //sort data
    if (sort.direction !== 'NONE') {
        const header = columns.find(h => h.key === sort.key);
        if (header && header.sortFn) {
            shields.sort((a: ShieldForApplicationDetails, b: ShieldForApplicationDetails) => {
                if (sort.direction === 'ASC') {
                    return header.sortFn(a, b);
                } else if (sort.direction === 'DESC') {
                    return header.sortFn(b, a);
                } else {
                    return 0;
                }
            });
        }
    }

    const rows =
        status === 'idle' || status === 'loading'
            ? //add  skeleton row on initial load
              [...Array(Math.floor(1)).keys()].map(() => skeletonCol)
            : shields.map((d: ShieldForApplicationDetails) => ({
                  header1: d.host,
                  header2: <Status title={d.status} type={StatusMaps.shieldStatusMap[d.status ?? 'NA']} />,
                  space: ' ',
              }));

    return (
        <ReactDataGrid
            columns={columns}
            onGridSort={(key: string, direction: string) => setSort({ direction, key })}
            minHeight={150}
            rowGetter={i => rows[i]}
            rowsCount={rows.length}
        />
    );
};

export default ShieldsDataGridCondensed;
