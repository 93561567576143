import React, { useEffect } from 'react';
import { t } from '@baffle/translate';
import { Modal, ModalBody, ModalFooter, ModalHeader, SkeletonModal } from '@baffle/components/src/modal/Modal';
import Button from '@baffle/components/src/buttons/Button';
import { EncryptionStore } from '../../stores';
import WarningIcon from '@material-ui/icons/Warning';
import { useParams } from 'react-router-dom';
import useAwait from '@baffle/api-client/src/useAwait';
import encryptionClient from '@baffle/api-client/src/encryptionClient';
import Alert from '@baffle/components/src/alerts/Alert';
import { ToastStore } from '../../stores/ToastStore';

interface ModalPropsWithoutChildren {
    'aria-labelledby'?: string;
    open: boolean;
    tableName: string;
    onClose?: () => void;
    onSuccess?: (tableName: string) => void;
    selectedDatabase: string;
    selectedSchema: string;
    touched: boolean;
}

const DeleteNewTableModal = ({
    tableName,
    open,
    onClose = () => null,
    onSuccess = (tableName: string) => null,
    selectedDatabase,
    selectedSchema,
    touched,
}: ModalPropsWithoutChildren) => {
    const { id } = useParams() as { id: string };
    const { status, run, error, reset } = useAwait();
    const doRemoveTable = () => {
        run(
            encryptionClient.removeNewTable(id, selectedDatabase, selectedSchema, tableName).then(() => {
                onSuccess(tableName);
                EncryptionStore.deleteAllSelectedColumns({ actionType: 'encryption' });
                selectedSchema
                    ? EncryptionStore.listSchemaTables(id as string, selectedSchema)
                    : EncryptionStore.listTables(id as string, selectedDatabase);
                EncryptionStore.setSelectedTable('encryption', '');
            })
        );
    };

    useEffect(() => {
        if (error) {
            ToastStore.push({ type: 'danger', title: error.message });
        }
    }, [error]);

    const handleClose = () => {
        onClose();
        reset();
    };

    return (
        <Modal open={open} onClose={handleClose}>
            {status === 'loading' ? (
                <SkeletonModal />
            ) : (
                <DeleteTable
                    tableName={tableName}
                    doRemoveTable={doRemoveTable}
                    handleClose={handleClose}
                    error={error}
                    touched={touched}
                />
            )}
        </Modal>
    );
};

interface DeleteTableProps {
    handleClose: () => void;
    doRemoveTable: () => void;
    tableName: string;
    error: Error | null;
    touched: boolean;
}
const DeleteTable = ({ tableName, doRemoveTable, handleClose, error, touched }: DeleteTableProps) => {
    return (
        <>
            <ModalHeader onClose={handleClose}>
                <div className="mt-0 ml-2 text-red text-center text-2xl" id="modal-headline">
                    <WarningIcon fontSize="large" />
                    <h3>Warning</h3>
                </div>
            </ModalHeader>
            <ModalBody>
                {error ? (
                    <div className="flex flex-wrap mb-4">
                        <div className="flex-1 px-4">
                            <Alert
                                type="danger"
                                title={t('encryption.newTableDeleteError')}
                                description={error?.message || ''}
                            />
                        </div>
                    </div>
                ) : null}
                <div className="h-50 flex flex-col justify-center items-center ">
                    <div className="w-50">
                        <p className="text-lg text-center mb-6">
                            {touched ? t('encryption.deleteDisabledMsg') : t('encryption.deleteTableConfirmationMsg')}
                        </p>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex justify-end items-center h-16 w-full">
                    <Button theme="secondary" className="h-full w-1/2" onClick={handleClose} size="lg">
                        {t('main.cancel')}
                    </Button>
                    <Button
                        theme={touched ? 'disabled' : 'danger'}
                        data-testid="modal-delete-new-table-btn"
                        className="h-full w-1/2 capitalize"
                        onClick={() => doRemoveTable()}
                        size="lg">
                        {t('setup.continue')}
                    </Button>
                </div>
            </ModalFooter>
        </>
    );
};

export default DeleteNewTableModal;
