import useAwait from '@baffle/api-client/src/useAwait';
import DataGrid from '@baffle/components/src/grid/data-grid';
import Status from '@baffle/components/src/status/Status';
import { DatabaseProxyShield } from '@baffle/graphql/src/models';
import { t } from '@baffle/translate';
import { StatusMaps } from '@baffle/utilities/src/enums';
import usePageHeight from '@baffle/utilities/src/usePageHeight';
import { Observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { DatabaseProxiesStore } from '@baffle/manager/src/stores';
import DatabaseProxyShieldRowDetails from './DatabaseProxyShieldDetails';

const DatabaseProxyShields = () => {
    const history = useHistory();
    const { status, run } = useAwait();
    const pageHeight = usePageHeight() - 200;
    const { id } = useParams() as { id: string };
    const [update, setUpdate] = useState(true);

    if (!id) {
        history.push('/database-proxies');
        return null;
    }

    useEffect(() => {
        if (update == true) {
            run(DatabaseProxiesStore.readDatabaseProxyShields(id));
        }
        setUpdate(false);
    }, [update]);

    const onSyncButtonClick = () => {
        setUpdate(true);
    };

    const headers = [
        {
            key: 'header1',
            header: t('databaseProxies.databaseProxyShields.header1'),
            sortable: true,
        },
        {
            key: 'header2',
            header: t('databaseProxies.databaseProxyShields.header2'),
            sortable: true,
        },
        {
            key: 'header3',
            header: t('databaseProxies.databaseProxyShields.header3'),
            sortable: true,
        },
        {
            key: 'header4',
            header: t('databaseProxies.databaseProxyShields.header4'),
            sortable: true,
        },
    ];

    return (
        <Observer>
            {() => {
                const databaseProxyShields: DatabaseProxyShield[] = DatabaseProxiesStore.databaseProxyShields;
                const databaseProxyShieldsDetails: Partial<DatabaseProxyShield>[] =
                    DatabaseProxiesStore.databaseProxyShieldsList;

                const rows =
                    status === 'loading' || status === 'idle'
                        ? null
                        : databaseProxyShields.map((databaseProxyShield: DatabaseProxyShield, i: number) => {
                              return {
                                  id: id + i,
                                  header1: databaseProxyShield.name,
                                  header2: databaseProxyShield.hostname,
                                  header3: databaseProxyShield.port,
                                  header4: (
                                      <Status
                                          title={databaseProxyShield.status}
                                          type={StatusMaps.shieldStatusMap[databaseProxyShield.status ?? 'NA']}
                                      />
                                  ),
                              };
                          });

                return (
                    <div className="overflow-y-auto" style={{ height: pageHeight }}>
                        <DataGrid
                            idPrefix="databaseProxyShields"
                            rows={rows}
                            headers={headers}
                            showSearch={true}
                            onSync={onSyncButtonClick}
                            rowExpansionItems={databaseProxyShieldsDetails ?? []}
                            rowExpansionMethod={info => <DatabaseProxyShieldRowDetails info={info} />}></DataGrid>
                    </div>
                );
            }}
        </Observer>
    );
};

export default DatabaseProxyShields;
