import React, { useMemo } from 'react';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import Lock from '@material-ui/icons/Lock';
import { t } from '@baffle/translate';
import { Checkbox } from 'carbon-components-react';
import { SortDirection } from '@baffle/utilities/src/gridHelpers';
import { getDefaultTHeadCellProps, THead, THeadCell, THeadRow } from '@baffle/components/src/table/THead';
import { ColumnMetaData, CryptoActionType } from '@baffle/graphql/src/models';
import { Lookup } from '../../../../types/lookup';
import { columnProps } from './ColumnTableRow';

interface ColumnTHeadProps {
    cryptoAction: CryptoActionType;
    addKey?: () => void;
    table: string;
    onSort: ({ key, direction }: { key: string; direction: SortDirection }) => void;
    sortKey: string;
    direction: SortDirection;
    sendSelectedMultiple: (d: ColumnMetaData[]) => void;
    sendDeselectedMultiple: (d: ColumnMetaData[]) => void;
    columns: ColumnMetaData[];
    selectedColumnsLookup: Lookup;
    style: any;
}

const ColumnTHead = ({
    addKey = () => null,
    onSort = () => null,
    columns,
    table,
    cryptoAction,
    sendDeselectedMultiple,
    sendSelectedMultiple,
    sortKey,
    style = {},
    direction,
    selectedColumnsLookup,
}: ColumnTHeadProps) => {
    const selectAllChecked = useMemo(
        () => Boolean(columns.length > 0 && columns.every((c: ColumnMetaData) => selectedColumnsLookup[c.path])),
        [selectedColumnsLookup, columns]
    );
    return (
        <THead style={style}>
            <THeadRow>
                <THeadCell
                    getTHeadCellProps={() => {
                        const { className, ...props } = getDefaultTHeadCellProps();
                        return {
                            className: `${className} ${columnProps.check.className}`,
                            ...props,
                        };
                    }}>
                    {cryptoAction === 'decryption' ? (
                        <div className="select-all-cells flex items-center">
                            <Checkbox
                                id="select-all-cells"
                                disabled={!Boolean(table)}
                                aria-label={t('encyption.selectAll')}
                                checked={selectAllChecked}
                                labelText=""
                                onChange={(event: any, { checked, id }: { checked: boolean; id: any }) => {
                                    if (checked) {
                                        sendSelectedMultiple(columns);
                                    } else {
                                        sendDeselectedMultiple(columns);
                                    }
                                }}
                            />
                        </div>
                    ) : null}
                </THeadCell>
                <THeadCell
                    getTHeadCellProps={() => {
                        const { className, ...props } = getDefaultTHeadCellProps();
                        return {
                            className: `${className} ${columnProps.lock.className}`,
                            ...props,
                        };
                    }}>
                    <Lock className="w-4 h-4" />
                </THeadCell>
                <THeadCell
                    sortKey="colName"
                    sortFn={(direction: SortDirection) => {
                        onSort({ key: 'colName', direction });
                    }}
                    currentKey={sortKey}
                    direction={direction}
                    getTHeadCellProps={() => {
                        const { className, ...props } = getDefaultTHeadCellProps({ sortable: true });
                        return {
                            className: `${className} ${columnProps.colName.className} min-w-48 flex justify-between items-center focus:outline-none`,
                            ...props,
                        };
                    }}>
                    {t('encryption.columnGrid.header1')}
                </THeadCell>
                <THeadCell
                    sortKey="dataType"
                    currentKey={sortKey}
                    direction={direction}
                    sortFn={(direction: SortDirection) => {
                        onSort({ key: 'dataType', direction });
                    }}
                    getTHeadCellProps={() => {
                        const { className, ...props } = getDefaultTHeadCellProps({ sortable: true });
                        return {
                            className: `${className} ${columnProps.dataType.className} flex justify-between items-center focus:outline-none`,
                            ...props,
                        };
                    }}>
                    {t('encryption.columnGrid.header2')}
                </THeadCell>
                <THeadCell
                    sortKey="dataFormat"
                    currentKey={sortKey}
                    direction={direction}
                    sortFn={(direction: SortDirection) => {
                        onSort({ key: 'dataFormat', direction });
                    }}
                    getTHeadCellProps={() => {
                        const { className, ...props } = getDefaultTHeadCellProps({ sortable: true });
                        return {
                            className: `${className} ${columnProps.dataFormat.className} flex justify-between items-center focus:outline-none`,
                            ...props,
                        };
                    }}>
                    {t('encryption.columnGrid.dataProtection')}
                </THeadCell>
                <THeadCell
                    getTHeadCellProps={() => {
                        const { className, ...props } = getDefaultTHeadCellProps();
                        return {
                            className: `${className} ${columnProps.keyId.className} flex justify-between items-center focus:outline-none`,
                            ...props,
                        };
                    }}>
                    <div className="flex items-center">
                        {t('encryption.columnGrid.header5')}
                        {cryptoAction === 'encryption' ? (
                            <div className="add-key-id ml-1">
                                <button
                                    className="focus:outline-none"
                                    data-testid="add-key-id"
                                    id="add-key-id"
                                    onClick={e => {
                                        e.stopPropagation();
                                        addKey();
                                    }}>
                                    <AddCircleOutline className="w-4 h-4" />
                                </button>
                            </div>
                        ) : null}
                    </div>
                </THeadCell>
                <THeadCell
                    getTHeadCellProps={() => {
                        return {
                            ...getDefaultTHeadCellProps(),
                            className: 'h-full text-left font-bold uppercase w-2',
                        };
                    }}>
                    {/* empty cell spacer */}{' '}
                </THeadCell>
            </THeadRow>
        </THead>
    );
};
export default ColumnTHead;
