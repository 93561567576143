import React, { useEffect } from 'react';
import { Modal } from 'carbon-components-react';
import { t } from '@baffle/translate';
import { Observer } from 'mobx-react-lite';
import useAwait from '@baffle/api-client/src/useAwait';
import { useHistory } from 'react-router-dom';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';
import { DatabaseProxiesStore } from '@baffle/manager/src/stores';
import { DatabaseProxy } from '@baffle/graphql/src/models';
import databaseProxiesClient from '@baffle/api-client/src/databaseProxiesClient';

interface DeleteDataBaseProxyModalProps {
    open: boolean;
    onClose: () => void;
    onSuccess: (name: string) => void;
}

const DeleteDataBaseProxyModal = ({ open, onClose, onSuccess }: DeleteDataBaseProxyModalProps) => {
    const history = useHistory();
    const { run, error } = useAwait();
    const { id, name } = DatabaseProxiesStore.databaseProxy as DatabaseProxy;

    const animateModal = () => {
        return new Promise(() => {
            setTimeout(() => {
                if (onClose) {
                    onClose();
                }
                history.push('/database-proxies');
            }, 200);
        });
    };

    const handleClose = async () => {
        await animateModal();
    };

    useEffect(() => {
        if (error) {
            NotificationStore.push({
                'data-testid': 'database-proxy-delete-failure',
                kind: 'error',
                title: t('databaseProxies.deleteDataBaseProxyError'),
                description: error.message ?? '',
            });
        }
    }, [error]);

    const deleteDataBaseProxy = () => {
        run(
            databaseProxiesClient.deleteDatabaseProxy(id as string).then(() => {
                if (onSuccess) {
                    onSuccess(name);
                }
                handleClose();
            })
        );
    };

    return (
        <Observer>
            {() => {
                return (
                    <Modal
                        id="database-proxy-delete-modal"
                        className="modal-container-white"
                        open={open}
                        modalHeading={t('databaseProxies.deleteDatabaseProxyTitle', {
                            name: name,
                        })}
                        danger={true}
                        onRequestClose={onClose}
                        primaryButtonText={t('main.delete')}
                        secondaryButtonText={t('main.cancel')}
                        size="sm"
                        onRequestSubmit={deleteDataBaseProxy}>
                        <p>{t('databaseProxies.deleteDatabaseProxyMessage')}</p>
                    </Modal>
                );
            }}
        </Observer>
    );
};

export default DeleteDataBaseProxyModal;
