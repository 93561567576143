import systemClient from '@baffle/api-client/src/systemClient';
import {
    SystemInfoEmail,
    SystemInfo,
    LoggerConfiguration,
    HttpsCertificate,
    ApplicationAccessData,
} from '@baffle/graphql/src/models';
import { observable, action, makeObservable, autorun } from 'mobx';
import storage from '@baffle/utilities/src/storage';
import { SessionEnums } from '@baffle/utilities/src/enums';

class SystemEmailStore {
    constructor() {
        makeObservable(this, {
            systemStatusLoaded: observable,
            systemStatusLoading: observable,
            systemEmailLoading: observable,
            systemEmail: observable,
            systemHealthy: observable,
            systemSettingsValues: observable,
            httpsCertificateValues: observable,
            systemEnvironment: observable,
            setSystemEmail: action,
            setLoading: action,
            systemEmailSetup: action,
            removeSystemEmail: action,
            updateSystemEmail: action,
            createSystemEmail: action,
            getSystemEmail: action,
            getHttpsCertificateValues: action,
            refreshHttpsCertificate: action,
            setSystemStatus: action,
            setSystemSettingsValue: action,
            setHttpsCertificate: action,
            getSystemSettingsValues: action,
            readLoggers: action,
            createLogger: action,
            deleteLogger: action,
            setLoggers: action,
            watchSystemHealth: action,
            setSystemEnvironment: action,
        });
    }
    systemEnvironment: string | null = storage.get(JSON.stringify(SessionEnums.NG_STORAGE_CLIENT_ENV));
    systemStatusLoaded: boolean = true;
    systemStatusLoading: boolean = true;
    systemEmailLoading: boolean = true;
    systemEmail: SystemInfoEmail | null = null;
    systemHealthy = true;
    systemSettingsValues = { organization: '', domain: '', publicAccess: '', proxyAccess: '', id: '' };
    loggers = observable<LoggerConfiguration>([]);
    httpsCertificateValues: HttpsCertificate | null = null;
    advancedEncMode: String | null = storage.get(JSON.stringify(SessionEnums.NG_STORAGE_ADVANCED_ENC_MODE));

    setLoading(loading: boolean, key: 'SystemStatus' | 'SystemEmail') {
        switch (key) {
            case 'SystemStatus':
                if (!this.systemStatusLoaded) {
                    this.systemStatusLoaded = true;
                }
                this.systemStatusLoading = loading;
                break;
            case 'SystemEmail':
                if (!this.systemEmailLoading) {
                    this.systemEmailLoading = true;
                }
                this.systemEmailLoading = loading;
                break;
            default:
                break;
        }
    }
    setSystemEmail(systemEmail: SystemInfoEmail | null) {
        this.systemEmail = systemEmail;
    }
    systemEmailSetup() {
        return Boolean(this.systemEmail);
    }
    setSystemStatus(healthy: boolean) {
        this.systemHealthy = healthy;
    }
    setSystemSettingsValue(systemInfo: SystemInfo) {
        this.systemSettingsValues = systemInfo;
    }
    setLoggers(loggers: LoggerConfiguration[]) {
        this.loggers.replace(loggers);
    }
    setHttpsCertificate(httpsCertificate: HttpsCertificate) {
        this.httpsCertificateValues = httpsCertificate;
    }
    setSystemEnvironment(environment: string) {
        this.systemEnvironment = environment;
        storage.set(SessionEnums.NG_STORAGE_CLIENT_ENV, environment);
    }
    setAdvancedEncMode(advancedEncMode: string) {
        this.advancedEncMode = advancedEncMode;
        storage.set(SessionEnums.NG_STORAGE_ADVANCED_ENC_MODE, advancedEncMode);
    }

    async getSystemEmail() {
        this.setLoading(true, 'SystemEmail');
        const doFinally = () => {
            this.setLoading(false, 'SystemEmail');
        };
        try {
            const systemEmail: SystemInfoEmail = await systemClient.readEmail();
            if (systemEmail?.id) {
                this.setSystemEmail(systemEmail);
            } else {
                this.setSystemEmail(null);
            }
        } catch (error) {
            throw error;
        } finally {
            doFinally();
        }
    }
    async removeSystemEmail(id: string) {
        try {
            await systemClient.deleteEmail(id);
            await this.getSystemEmail();
        } catch (error) {
            console.log(error);
        }
    }
    async updateSystemEmail(id: string, emailConfig: SystemInfoEmail) {
        try {
            await systemClient.updateEmail(id, emailConfig);
            await this.getSystemEmail();
        } catch (error) {
            throw error;
        }
    }
    async createSystemEmail(emailConfig: SystemInfoEmail) {
        try {
            await systemClient.createEmail(emailConfig);
            await this.getSystemEmail();
        } catch (error) {
            throw error;
        }
    }
    async getSystemSettingsValues() {
        try {
            const systemInfo: SystemInfo = await systemClient.getSystemSettings();
            this.setSystemSettingsValue(systemInfo);
        } catch (error) {
            throw error;
        }
    }
    async readLoggers() {
        try {
            const res: LoggerConfiguration[] = await systemClient.readLoggers();
            this.setLoggers(res);
        } catch (error) {
            throw error;
        }
    }
    async getHttpsCertificateValues() {
        try {
            const httpsCertificate: HttpsCertificate = await systemClient.getHttpsCertificate();
            this.setHttpsCertificate(httpsCertificate);
        } catch (error) {
            throw error;
        }
    }
    async createLogger(data: LoggerConfiguration) {
        try {
            await systemClient.createLogger(data);
        } catch (error) {
            throw error;
        }
    }
    async deleteLogger(id: string) {
        try {
            await systemClient.deleteLogger(id);
        } catch (error) {
            throw error;
        }
    }
    async refreshHttpsCertificate() {
        try {
            const httpsCertificate: HttpsCertificate = await systemClient.refreshHttpsCertificate();
            this.setHttpsCertificate(httpsCertificate);
        } catch (error) {
            throw error;
        }
    }
    async getApplicationAccessData() {
        try {
            if (!this.systemEnvironment) {
                const systemData: ApplicationAccessData = await systemClient.readApplicationAccess();
                this.setSystemEnvironment(systemData?.environment);
                this.setSystemStatus(true);
                this.setAdvancedEncMode(systemData?.additionalFeatures?.advanced_enc_mode);
            }
        } catch (error) {
            this.setSystemStatus(false);
            throw error;
        }
    }

    watchSystemHealth() {
        let healthCheckInterval: NodeJS.Timeout;
        return autorun(() => {
            if (!this.systemHealthy) {
                if (!healthCheckInterval) {
                    healthCheckInterval = setInterval(() => {
                        this.getApplicationAccessData();
                    }, 20000);
                }
            } else {
                clearInterval(healthCheckInterval);
            }
        });
    }
}

export default SystemEmailStore;
