import React, { useState, useEffect } from 'react';
import useAwait from '@baffle/api-client/src/useAwait';
import Button from '@baffle/components/src/buttons/Button';
import { FileUploader, Toggle } from 'carbon-components-react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@baffle/components/src/modal/Modal';
import TextInput from '@baffle/components/src/forms/TextInput';
import TextArea from '@baffle/components/src/forms/TextArea';
import SkeletonText from '@baffle/components/src/skeleton/SkeletonText';
import { t } from '@baffle/translate';
import Divider from '@baffle/components/src/content/Divider';
import './AddApplicationModal.scss';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import Joi from '@hapi/joi';
import Alert from '@baffle/components/src/alerts/Alert';
import {
    ApplicationDetails,
    ApplicationInput,
    KeyLengthEnum,
    DatabaseListItem,
    KeystoreListItem,
    ApplicationV2,
} from '@baffle/graphql/src/models';
import { Observer } from 'mobx-react-lite';
import { ApplicationEnums } from '@baffle/utilities/src/enums';
import applicationClient from '@baffle/api-client/src/applicationClient';
import DropdownSelect, {
    getDefaultLIProps,
    getDefaultMenuProps,
    getDefaultToggleButtonProps,
} from '@baffle/components/src/dropdown/Dropdown';
import { DatabaseServerStore, FeatureFlagStore, KeystoreStore, SystemEmailStore } from '../stores';
import { ToastStore } from '../stores/ToastStore';
import SpinnerOverlay from '@baffle/components/src/loader/SpinnerOverlay';

interface AddApplicationModalProps {
    applications: ApplicationV2[];
    open: boolean;
    onClose?: () => void;
    onSuccess?: (inputState: ApplicationDetails) => void;
}
const AddApplicationModal = (props: AddApplicationModalProps) => {
    const { applications } = props;
    const { run, status, error } = useAwait();
    const [open, setOpen] = useState(props.open);
    const [teardownModal, setTeardownModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const initialPostErrorState: string | null = null;
    const [postError, setPostError] = useState(initialPostErrorState);

    useEffect(() => {
        if (open) {
            run(Promise.all([DatabaseServerStore.list(), KeystoreStore.list()]));
        }
    }, [open]);

    useEffect(() => {
        if (error) {
            ToastStore.push({ type: 'danger', title: error.message });
        }
    }, [error]);

    interface validationSchemaType {
        entitySchema: Joi.AnySchema;
        appName: Joi.StringSchema;
        db: Joi.StringSchema;
        keystore: Joi.StringSchema;
        description: Joi.StringSchema;
    }

    const validationSchema: validationSchemaType = {
        entitySchema: Joi.any()
            .custom(file => {
                //do not validate if its record level
                if (!inputState.recordLevel) {
                    return file;
                }

                if (!Boolean(file)) {
                    throw new Error(t('application.invalidRLEFile'));
                }
                if (file && file.name !== 'BaffleEntitySchema') {
                    throw new Error(t('application.invalidRLEFile'));
                }
                return file;
            })
            .optional()
            .error(new Error(t('application.invalidRLEFile'))),
        appName: Joi.string()
            .min(1)
            .max(30)
            .trim()
            .custom(val => {
                if (applications.some((a: ApplicationV2) => a.name.trim().toLowerCase() === val.trim().toLowerCase())) {
                    throw new Error(t('application.nameExists'));
                }
                return val;
            })
            .required()
            .messages({
                'string.max': t('main.invalidNameLength'),
                'any.custom': t('application.nameExists'),
                'string.empty': t('application.appNameInputInvalid'),
            }),
        db: Joi.string()
            .min(1)
            .required(),
        keystore: Joi.string()
            .min(1)
            .required(),
        description: Joi.string()
            .max(100)
            .allow(null, '')
            .messages({
                'string.max': t('main.invalidLength', { max: 100 }),
            }),
    };

    const { inputState, inputErrors, inputActions } = useInputState({
        keys: ['entitySchema', 'appName', 'description', 'db', 'keystore', 'recordLevel', 'wlc', 'wlcType', 'mode'],
        ns: 'application',
        defaultValues: {
            recordLevel: false,
            wlc: false,
            mode: ApplicationEnums.Standard,
            wlcType: 'WLC_Advanced',
            entitySchema: null,
            keyLength: KeyLengthEnum.AES_256,
        },
        validationSchema: validationSchema,
    });

    //Display the error if the schema file name is invalid
    useEffect(() => {
        if (inputState.entitySchema) {
            inputActions.validateField({
                field: 'entitySchema',
                skipNull: true,
            });
        }
        if (!inputState.recordLevel && inputErrors.entitySchema) {
            inputActions.clearError('entitySchema');
        }
    }, [inputState.entitySchema, inputState.recordLevel, inputErrors.entitySchema]);
    //Sync state from props
    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const closeModal = (clearState?: boolean) => {
        if (loading) {
            return;
        }
        if (clearState) {
            inputActions.reset();
            setTeardownModal(true);
            setPostError(null);
            setTimeout(() => setTeardownModal(false), 0);
        }
        setOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    };
    const doAddApplication = async () => {
        setLoading(true);
        try {
            if (inputState.recordLevel) {
                await applicationClient.createwithRLE((inputState as unknown) as ApplicationInput);
            } else {
                await applicationClient.create((inputState as unknown) as ApplicationInput);
            }
            props.onSuccess && props.onSuccess((inputState as unknown) as ApplicationDetails);
            closeModal(true);
        } catch (error) {
            setPostError(JSON.parse(error.message).errors.join(', '));
        }
        setLoading(false);
    };
    const isValid = inputActions.validateAll(true);
    //hack to remove the input components state
    if (teardownModal) {
        return null;
    }

    const encryptionTypeItems = [
        {
            id: ApplicationEnums.RECORD_LEVEL,
            label: t('application.recordLevel'),
        },
        {
            id: ApplicationEnums.COLUMN_LEVEL,
            label: t('application.columnLevel'),
        },
    ];

    const keyLengthItems = [
        {
            id: KeyLengthEnum.AES_256,
            label: KeyLengthEnum.AES_256,
        },
        {
            id: KeyLengthEnum.AES_128,
            label: KeyLengthEnum.AES_128,
        },
    ];
    return (
        <Modal open={open} onClose={() => closeModal(true)} aria-label="modal">
            {loading && <SpinnerOverlay description={t('application.addingApplication')} />}
            <>
                <ModalHeader onClose={() => closeModal(true)}>
                    <div className="flex items-start">
                        <h3 className="text-xl font-medium text-gray-100" id="modal-headline">
                            {t('application.addModalHeading')}
                        </h3>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <>
                        {postError ? (
                            <div className="flex flex-wrap">
                                <div className="flex-1">
                                    <Alert
                                        type="danger"
                                        title={t('application.addError')}
                                        description={postError || ''}
                                    />
                                </div>
                            </div>
                        ) : null}
                        <div className="flex flex-wrap mb-6 items-start">
                            <div className="flex-1 name-input-container">
                                <TextInput
                                    id="application-name-input"
                                    name="application-name-input"
                                    value={(inputState.appName as string) || ''}
                                    labelText={t('application.appNameInputLabel')}
                                    invalid={Boolean(inputErrors.appName)}
                                    invalidText={inputErrors.appName}
                                    placeholder={t('application.appNamePlaceholder')}
                                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        inputActions.validateField({
                                            field: 'appName',
                                            skipNull: true,
                                        });
                                    }}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const val = e.target.value || '';
                                        inputActions.setField('appName', val);
                                    }}
                                    size={30}
                                />
                                <span className="input-char-counter">
                                    {(inputState.appName as string)?.length ?? 0} / 30
                                </span>
                            </div>
                        </div>
                        <div className="flex flex-wrap mb-2">
                            <div className="flex-1 desc-input-container">
                                <TextArea
                                    id="application-description-input"
                                    name="application-description-input"
                                    value={(inputState.description as string) || ''}
                                    labelText={t('application.descriptionInputLabel')}
                                    invalid={Boolean(inputErrors.description)}
                                    invalidText={inputErrors.description}
                                    placeholder={t('application.descriptionPlaceholder')}
                                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        inputActions.validateField({
                                            field: 'description',
                                            skipNull: true,
                                        });
                                    }}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const val = e.target.value || '';
                                        inputActions.setField('description', val);
                                    }}
                                    size={100}
                                />
                                <span className="input-char-counter">
                                    {(inputState.description as string)?.length ?? 0}/ 100
                                </span>
                            </div>
                        </div>
                        <div className="flex flex-wrap">
                            <div className="flex-1 px-4">
                                <Divider />
                            </div>
                        </div>
                        <div className="flex flex-wrap mb-6 items-start">
                            <div className="flex-1 pr-4 w-1/2">
                                {status === 'idle' || status === 'loading' ? (
                                    <SkeletonText />
                                ) : (
                                    <Observer>
                                        {() => {
                                            const dbItems = DatabaseServerStore.databaseListItems.map(
                                                (d: DatabaseListItem) => ({
                                                    id: d.id as string,
                                                    label: d.dbName as string,
                                                })
                                            );
                                            return (
                                                <DropdownSelect
                                                    id="application-database-input"
                                                    label={t('application.databaseInputLabel')}
                                                    placeholder={t('main.chooseOption')}
                                                    getLIProps={() => {
                                                        const { className: liClasses, ...rest } = getDefaultLIProps();
                                                        return {
                                                            className: `${liClasses} w-full truncate`,
                                                            ...rest,
                                                        };
                                                    }}
                                                    getMenuProps={() => {
                                                        const { className: ulClasses, ...rest } = getDefaultMenuProps();
                                                        return {
                                                            className: `${ulClasses} w-full`,
                                                            ...rest,
                                                        };
                                                    }}
                                                    getToggleButtonProps={() => {
                                                        const {
                                                            className: btnClasses,
                                                            ...rest
                                                        } = getDefaultToggleButtonProps();
                                                        return {
                                                            className: `${btnClasses} w-full`,
                                                            ...rest,
                                                        };
                                                    }}
                                                    defaultSelectedItem={
                                                        inputState.db
                                                            ? dbItems.find(d => d.id === inputState.db)
                                                            : { id: 'chooseOption', label: t('main.chooseOption') }
                                                    }
                                                    handleSelectedItemChange={({ selectedItem }) => {
                                                        inputActions.setField('db', selectedItem?.id as string);
                                                    }}
                                                    items={dbItems}
                                                />
                                            );
                                        }}
                                    </Observer>
                                )}
                            </div>
                            <div className="flex-initial pl-4 w-1/2">
                                {status === 'idle' || status === 'loading' ? (
                                    <SkeletonText />
                                ) : (
                                    <Observer>
                                        {() => {
                                            const ksItems = KeystoreStore.keystoreListItems
                                                //remove baffle_credential_store from list
                                                .filter((ks: KeystoreListItem) => ks.name !== 'baffle_credential_store')
                                                .map((ks: KeystoreListItem) => ({
                                                    id: ks.id as string,
                                                    label: ks.name as string,
                                                }));
                                            return (
                                                <DropdownSelect
                                                    id="application-keystore-input"
                                                    label={t('application.keystoreInputLabel')}
                                                    placeholder={t('main.chooseOption')}
                                                    getLIProps={() => {
                                                        const { className: liClasses, ...rest } = getDefaultLIProps();
                                                        return {
                                                            className: `${liClasses} w-full truncate`,
                                                            ...rest,
                                                        };
                                                    }}
                                                    getMenuProps={() => {
                                                        const { className: ulClasses, ...rest } = getDefaultMenuProps();
                                                        return {
                                                            className: `${ulClasses} w-full `,
                                                            ...rest,
                                                        };
                                                    }}
                                                    getToggleButtonProps={() => {
                                                        const {
                                                            className: btnClasses,
                                                            ...rest
                                                        } = getDefaultToggleButtonProps();
                                                        return {
                                                            className: `${btnClasses} w-full`,
                                                            ...rest,
                                                        };
                                                    }}
                                                    defaultSelectedItem={
                                                        inputState.keystore
                                                            ? ksItems.find(d => d.id === inputState.keystore)
                                                            : { id: 'chooseOption', label: t('main.chooseOption') }
                                                    }
                                                    handleSelectedItemChange={({ selectedItem }) => {
                                                        inputActions.setField('keystore', selectedItem?.id);
                                                    }}
                                                    items={ksItems}
                                                />
                                            );
                                        }}
                                    </Observer>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-wrap mb-2 items-center">
                            <Observer>
                                {() => {
                                    return (
                                        <>
                                            {FeatureFlagStore.wlcToggle ? (
                                                <div className="flex-1">
                                                    <Toggle
                                                        className="toggle_override"
                                                        aria-label={t('application.ariaWlcInputLabel')}
                                                        toggled={inputState.wlc}
                                                        id="application-wlc-input"
                                                        labelA={t('application.off')}
                                                        labelB={t('application.on')}
                                                        labelText={t('application.wlcInputLabel')}
                                                        onToggle={(val: boolean) => {
                                                            inputActions.setField('wlc', val);
                                                            console.log('toggled...');
                                                        }}
                                                    />
                                                </div>
                                            ) : null}
                                        </>
                                    );
                                }}
                            </Observer>
                        </div>
                        <div className="flex flex-wrap mb0">
                            <div className="flex-1 px-4">
                                <Divider />
                            </div>
                        </div>
                        <div className="flex flex-wrap">
                            <div className="flex-1 pr-4 w-1/2">
                                <DropdownSelect
                                    id="application-encryptionType-input"
                                    label={t('application.encryptionTypeInputLabel')}
                                    placeholder={t('main.chooseOption')}
                                    getLIProps={() => {
                                        const { className: liClasses, ...rest } = getDefaultLIProps();
                                        return {
                                            className: `${liClasses} w-full truncate`,
                                            ...rest,
                                        };
                                    }}
                                    getMenuProps={() => {
                                        const { className: ulClasses, ...rest } = getDefaultMenuProps();
                                        return {
                                            className: `${ulClasses} w-full `,
                                            ...rest,
                                        };
                                    }}
                                    getToggleButtonProps={() => {
                                        const { className: btnClasses, ...rest } = getDefaultToggleButtonProps();
                                        return {
                                            className: `${btnClasses} w-full`,
                                            ...rest,
                                        };
                                    }}
                                    defaultSelectedItem={
                                        inputState.recordLevel ? encryptionTypeItems[0] : encryptionTypeItems[1]
                                    }
                                    handleSelectedItemChange={({ selectedItem }) => {
                                        const recordLevelMap = {
                                            RECORD_LEVEL: true,
                                            COLUMN_LEVEL: false,
                                        };
                                        //@ts-ignore
                                        const val = recordLevelMap[selectedItem.id];
                                        inputActions.setField('recordLevel', val);
                                    }}
                                    items={encryptionTypeItems}
                                />
                            </div>
                            <div className="flex-1 pl-4 w-1/2">
                                <FileUploader
                                    disabled={!inputState.recordLevel}
                                    aria-label={t('application.entitySchemaInputLabel')}
                                    buttonLabel={t('application.uploadEntitySchema')}
                                    name="application-entitySchema-input"
                                    buttonKind="tertiary"
                                    filenameStatus="edit"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const file = e.target.files ? e.target.files[0] : null;
                                        if (file) {
                                            inputActions.setField('entitySchema', file);
                                        }
                                    }}
                                />
                                {inputErrors.entitySchema ? (
                                    <div className="text-red text-xs mt-1">{inputErrors.entitySchema}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className="flex flex-wrap">
                            <div className="flex-1 pr-4 w-1/2">
                                <Observer>
                                    {() => {
                                        const selectedDB = DatabaseServerStore.databaseListItems.find(
                                            db => db.id === inputState?.db
                                        );
                                        if (
                                            selectedDB &&
                                            ((selectedDB?.type ?? '').includes('POSTGRES') ||
                                                (selectedDB?.type ?? '').includes('GREENPLUM')) &&
                                            (SystemEmailStore.advancedEncMode === 'ENABLE' ||
                                                FeatureFlagStore.advancedEncryptionMode)
                                        ) {
                                            const encModeItems = [
                                                {
                                                    id: ApplicationEnums.Standard,
                                                    label: t('application.standard'),
                                                },
                                                {
                                                    id: ApplicationEnums.Advanced,
                                                    label: t('application.advanced'),
                                                },
                                            ];
                                            return (
                                                <DropdownSelect
                                                    id="application-encryptionMode-input"
                                                    label={t('application.encryptionModeInputLabel')}
                                                    placeholder={t('main.chooseOption')}
                                                    getLIProps={() => {
                                                        const { className: liClasses, ...rest } = getDefaultLIProps();
                                                        return {
                                                            className: `${liClasses} w-full truncate`,
                                                            ...rest,
                                                        };
                                                    }}
                                                    getMenuProps={() => {
                                                        const { className: ulClasses, ...rest } = getDefaultMenuProps();
                                                        return {
                                                            className: `${ulClasses} w-full `,
                                                            ...rest,
                                                        };
                                                    }}
                                                    getToggleButtonProps={() => {
                                                        const {
                                                            className: btnClasses,
                                                            ...rest
                                                        } = getDefaultToggleButtonProps();
                                                        return {
                                                            className: `${btnClasses} w-full`,
                                                            ...rest,
                                                        };
                                                    }}
                                                    defaultSelectedItem={
                                                        inputState.mode
                                                            ? encModeItems.find(d => d.id === inputState.mode)
                                                            : { id: 'chooseOption', label: t('main.chooseOption') }
                                                    }
                                                    handleSelectedItemChange={({ selectedItem }) => {
                                                        inputActions.setField('mode', selectedItem?.id);
                                                    }}
                                                    items={encModeItems}
                                                />
                                            );
                                        } else {
                                            return <div />;
                                        }
                                    }}
                                </Observer>
                            </div>
                            <div className="flex-1 pl-4 w-1/2" />
                        </div>
                        {FeatureFlagStore.keyLength ? (
                            <div className="flex flex-wrap mt-6 items-center">
                                <div className="flex-0 pr-4 w-1/2">
                                    <DropdownSelect
                                        getLIProps={() => {
                                            const { className: liClasses, ...rest } = getDefaultLIProps();
                                            return {
                                                className: `${liClasses} w-full truncate`,
                                                ...rest,
                                            };
                                        }}
                                        getMenuProps={() => {
                                            const { className: ulClasses, ...rest } = getDefaultMenuProps();
                                            return {
                                                className: `${ulClasses} w-full `,
                                                ...rest,
                                            };
                                        }}
                                        getToggleButtonProps={() => {
                                            const { className: btnClasses, ...rest } = getDefaultToggleButtonProps();
                                            return {
                                                className: `${btnClasses} w-full`,
                                                ...rest,
                                            };
                                        }}
                                        id="application-keyLength-input"
                                        label={t('application.keyLengthInputLabel')}
                                        placeholder={t('main.chooseOption')}
                                        defaultSelectedItem={
                                            inputState.keyLength
                                                ? keyLengthItems.find(kl => kl.id === inputState.keyLength)
                                                : keyLengthItems[0]
                                        }
                                        handleSelectedItemChange={({ selectedItem }) => {
                                            inputActions.setField('keyLength', selectedItem?.id);
                                        }}
                                        items={keyLengthItems}
                                    />
                                </div>
                            </div>
                        ) : null}
                    </>
                </ModalBody>
                <ModalFooter>
                    <div className="flex justify-end items-center h-16 w-full">
                        <Button className="h-full w-1/2" theme="secondary" onClick={() => closeModal(true)} size="lg">
                            {t('main.cancel')}
                        </Button>
                        <Button
                            className="h-full w-1/2"
                            theme={isValid && !loading ? 'primary' : 'disabled'}
                            id="do-add-application-btn"
                            onClick={doAddApplication}
                            size="lg">
                            {t('application.enrollApplication')}
                        </Button>
                    </div>
                </ModalFooter>
            </>
        </Modal>
    );
};
export default AddApplicationModal;
