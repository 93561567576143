import {
    DataFormatItem,
    MaskingMode,
    EncryptionMode,
    RbacConfig,
    RbacUsrGrp,
    JsonPolicy,
    RbacPolicy,
} from '@baffle/graphql/src/models';
import client from '.';

//DEPRECATION WARNING: dataformats have been replaced by modes
export async function create(appID: string, payload: Partial<DataFormatItem>) {
    const p = Object.assign({}, { appID }, payload);
    return client(`/api/v1.0/application/encryption/dataformat`, { body: p, method: 'POST' });
}

export async function update(appID: string, payload: Partial<DataFormatItem>) {
    const p = Object.assign({}, { appID }, payload);
    return client(`/api/v1.0/application/encryption/dataformat`, { body: p, method: 'PUT' });
}

export async function listAll(queryParams: { appId: string }) {
    return client('/v2/data-protection/library', { queryParams });
}

export async function createEncryptionMode(payload: EncryptionMode) {
    return client('/v2/data-protection/encryption-modes', { body: payload, method: 'POST' });
}

export async function editEncryptionMode(id: string, payload: EncryptionMode) {
    const p = Object.assign({}, { id }, payload);
    return client(`/v2/data-protection/encryption-modes/${id}`, { body: p, method: 'PUT' });
}

export async function listEncryptionModes() {
    return client('/v2/data-protection/encryption-modes');
}

export async function createMaskingMode(payload: MaskingMode) {
    return client('/v2/data-protection/masking-modes', { body: payload, method: 'POST' });
}

export async function editMaskingMode(id: string, payload: MaskingMode) {
    const p = Object.assign({}, { id }, payload);
    return client(`/v2/data-protection/masking-modes/${id}`, { body: p, method: 'PUT' });
}

export async function listMaskingModes() {
    return client('/v2/data-protection/masking-modes');
}

export function createRbacConfig(payload: RbacConfig) {
    return client('/v2/data-protection/rbac/configurations', { body: payload, method: 'POST' });
}

export function readRbacConfig(id: string) {
    return client(`/v2/data-protection/rbac/configurations/${id}`);
}

export function readRbacConfigByAppId(queryParams: { appId: string }) {
    return client(`/v2/data-protection/rbac/configurations`, { queryParams });
}

export function updateRbacConfig(id: string, payload: RbacConfig) {
    return client(`/v2/data-protection/rbac/configurations/${id}`, { body: payload, method: 'PUT' });
}

export async function getSelectedMode(modeID: string, type: string) {
    if (type === 'masking') {
        return client(`/v2/data-protection/masking-modes/${modeID}`);
    } else if (type === 'encryption') {
        return client(`/v2/data-protection/encryption-modes/${modeID}`);
    }
}

export function createRbacUsrGrp(payload: RbacUsrGrp) {
    return client('/v2/data-protection/rbac/user-groups', { body: payload, method: 'POST' });
}

export function readRbacUsrGrp(id: string) {
    return client(`/v2/data-protection/rbac/user-groups/${id}`);
}

export function readRbacUsrGrps() {
    return client(`/v2/data-protection/rbac/user-groups`);
}

export function updateRbacUsrGrp(id: string, payload: RbacUsrGrp) {
    return client(`/v2/data-protection/rbac/user-groups/${id}`, { body: payload, method: 'PUT' });
}

export function createJsonPolicy(payload: JsonPolicy) {
    return client('/v2/data-protection/json-policies', { body: payload, method: 'POST' });
}

export function readJsonPolicy(id: string) {
    return client(`/v2/data-protection/json-policies/${id}`);
}

export function readJsonPolicyByAppId(queryParams: { appId: string }) {
    return client(`/v2/data-protection/json-policies`, { queryParams });
}

export function updateJsonPolicy(id: string, payload: JsonPolicy) {
    return client(`/v2/data-protection/json-policies/${id}`, { body: payload, method: 'PUT' });
}

export function createRbacPolicy(payload: RbacPolicy) {
    return client('/v2/data-protection/rbac/policies', { body: payload, method: 'POST' });
}

export function readRbacPolicy(id: string) {
    return client(`/v2/data-protection/rbac/policies/${id}`);
}

export function readRbacPolicies() {
    return client(`/v2/data-protection/rbac/policies`);
}

export function updateRbacPolicy(id: string, payload: RbacPolicy) {
    return client(`/v2/data-protection/rbac/policies/${id}`, { body: payload, method: 'PUT' });
}

export function listEncryptionModesV2() {
    return client(`/api/v2/encryption-policies`);
}

export default {
    create,
    update,
    listAll,
    createEncryptionMode,
    createMaskingMode,
    listEncryptionModes,
    listMaskingModes,
    editMaskingMode,
    editEncryptionMode,
    createRbacConfig,
    readRbacConfig,
    readRbacConfigByAppId,
    updateRbacConfig,
    getSelectedMode,
    createRbacUsrGrp,
    readRbacUsrGrp,
    readRbacUsrGrps,
    updateRbacUsrGrp,
    createJsonPolicy,
    readJsonPolicy,
    readJsonPolicyByAppId,
    updateJsonPolicy,
    createRbacPolicy,
    readRbacPolicy,
    readRbacPolicies,
    updateRbacPolicy,
    listEncryptionModesV2,
};
