import { InitialConfiguration } from '@baffle/graphql/src/models';
import client from '.';

export async function readRefreshToken(refreshToken: string) {
    try {
        const res = await client('/api/public/v2/auth/refresh-token', {
            body: { refreshToken: refreshToken },
            method: 'POST',
        });
        return res;
    } catch (error) {
        throw error;
    }
}

export async function postLogin(body: any) {
    try {
        const res = await client('/api/public/v2/auth', { body, method: 'POST', handleUnauthorized: false });
        return res;
    } catch (error) {
        throw error;
    }
}

export async function postIbmLogin(body: any) {
    try {
        const res = await client('/api/public/v2/auth/ibm-token', { body, method: 'POST', handleUnauthorized: false });
        return res;
    } catch (error) {
        throw error;
    }
}

export async function setInitialConfiguration(body: InitialConfiguration) {
    try {
        const res = await client('/api/public/v2/configurations/init', {
            body,
            method: 'POST',
            handleUnauthorized: false,
        });
        return res;
    } catch (error) {
        throw error;
    }
}

export default {
    readRefreshToken,
    postLogin,
    postIbmLogin,
    setInitialConfiguration,
};
