export interface DatabasePayloadV2 {
    id: string;
    name: string;
    environment: Environment;
    type: Type;
    dbType: DbType;
    ssl: boolean;
    version: string;
    hostname: string;
    port: number;
    configDbName: string;
    username: string;
    password: string;
    createTime: number;
    createdBy: string;
}

export enum DbType {
    // Postgres
    POSTGRES = 'POSTGRES',
    RDS_POSTGRES = 'RDS_POSTGRES',
    // Mysql
    RDS_AURORA_MYSQL = 'RDS_AURORA_MYSQL',
    MARIADB = 'MARIADB',
    AZURE_MYSQL = 'AZURE_MYSQL',
    MYSQL = 'MYSQL',
    RDS_MARIADB = 'RDS_MARIADB',
    RDS_MYSQL = 'RDS_MYSQL',
    // Mssql
    AZURE_SQL = 'AZURE_MSSQL',
    SQL_SERVER = 'SQL_SERVER',
    RDS_MSSQL = 'RDS_MSSQL',
    // Redshift
    AWS_REDSHIFT = 'AWS_REDSHIFT',
    // Greenplum
    GREENPLUM = 'GREENPLUM',
}

export enum Environment {
    AWS = 'AWS',
    Azure = 'Azure',
    GCP = 'GCP',
    IBM = 'IBM',
    Standalone = 'Standalone',
}

export enum Type {
    Mysql = 'Mysql',
    MariaDB = 'MariaDB',
    Mssql = 'Mssql',
    Postgres = 'Postgres',
    Redshift = 'Redshift',
    Greenplum = 'Greenplum',
}

export interface DbEnvTypes {
    [key: string]: EnvType[];
}

export interface EnvType {
    type: string;
    versions: string[];
    dbType: DbType;
    port: number;
}
