import React, { useEffect } from 'react';
import { Observer } from 'mobx-react-lite';
import Content from '@baffle/components/src/content/Content';
import { Overview, OverviewCard } from '@baffle/components/src/overview/Overview';
import PageHeader from '@baffle/components/src/pageheader/pageheader';
import { MaskingPolicyStore } from '@baffle/manager/src/stores';
import useAwait from '@baffle/api-client/src/useAwait';
import { useHistory, useParams } from 'react-router-dom';
import { t } from '@baffle/translate';
import { useManageModals } from '@baffle/components/src/modal/Modal';
import EditMaskingPolicy from './EditMaskingPolicy';
import DeleteMaskingPolicy from './DeleteMaskingPolicy';
import SpinnerOverlay from '@baffle/components/src/loader/SpinnerOverlay';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';
import { Associations } from '@baffle/graphql/src/models';
import DataGrid from '@baffle/components/src/grid/data-grid';
import RenameMaskingPolicy from './RenameMaskingPolicy';

const MaskingPolicyDetails = () => {
    const { id } = useParams() as { id: string };
    const history = useHistory();
    if (!id) {
        history.push('/masking-policies');
        return null;
    }

    const { status, run } = useAwait();

    useEffect(() => {
        run(Promise.all([MaskingPolicyStore.getMaskingPolicy(id), MaskingPolicyStore.getMaskingPolicies()]));
    }, []);

    const emptyCol = {
        id: '',
        header1: '',
        header2: '',
    };

    const headers = [
        {
            key: 'header2',
            header: t('globalPolicies.maskingPolicy.resources'),
            sortable: true,
        },
        {
            key: 'header1',
            header: t('globalPolicies.maskingPolicy.name'),
            sortable: false,
        },
    ];

    return (
        <Observer>
            {() => {
                const maskingPolicy = MaskingPolicyStore.maskingPolicy;

                const rows =
                    status === 'loading' || status === 'idle'
                        ? //add  skeleton row on initial load
                          [...Array(Math.floor(1)).keys()].map(() => emptyCol)
                        : maskingPolicy?.associations?.map((association: Associations, i: number) => {
                              return {
                                  selectedId: association?.id,
                                  id: association.id,
                                  header1: association.name,
                                  header2: association.type,
                                  link: '/rbac/rbac-policy/' + association.id,
                              };
                          });

                interface MaskingPolicyModals {
                    editMaskingPolicy: boolean;
                    deleteMaskingPolicy: boolean;
                    renameMaskingPolicy: boolean;
                }

                const initialModalState = {
                    editMaskingPolicy: false,
                    deleteMaskingPolicy: false,
                    renameMaskingPolicy: false,
                };

                const [modals, setModals] = useManageModals<MaskingPolicyModals>(initialModalState);

                const actions = [
                    {
                        id: '1',
                        label: t('globalPolicies.maskingPolicy.renameMaskingPolicy'),
                    },
                    {
                        id: '2',
                        label: t('globalPolicies.maskingPolicy.editMaskingPolicy'),
                    },
                    {
                        id: '3',
                        label: t('globalPolicies.maskingPolicy.deleteMaskingPolicy'),
                        disabled: MaskingPolicyStore.maskingPolicy?.isDefault,
                    },
                ];

                const breadcrumbData = [
                    {
                        label: t('globalPolicies.maskingPolicy.maskingPolicies'),
                        href: '/masking-policies',
                    },
                ];

                const onSelectAction = (item: any) => {
                    switch (item.selectedItem.label) {
                        case t('globalPolicies.maskingPolicy.renameMaskingPolicy'):
                            setModals({ renameMaskingPolicy: true });
                            break;
                        case t('globalPolicies.maskingPolicy.editMaskingPolicy'):
                            setModals({ editMaskingPolicy: true });
                            break;
                        case t('globalPolicies.maskingPolicy.deleteMaskingPolicy'):
                            setModals({ deleteMaskingPolicy: true });
                            break;
                        default:
                            break;
                    }
                };

                return (
                    <>
                        <Content>
                            {modals.editMaskingPolicy ? (
                                <EditMaskingPolicy
                                    open={modals.editMaskingPolicy}
                                    onClose={() => setModals({ editMaskingPolicy: false })}
                                    onSuccess={(name: string) => {
                                        NotificationStore.push({
                                            'data-testid': 'masking-policy-edit-success',
                                            kind: 'success',
                                            title: t('globalPolicies.maskingPolicy.editMaskingPolicySuccess', {
                                                name: name,
                                            }),
                                        });
                                    }}
                                />
                            ) : null}

                            {modals.renameMaskingPolicy ? (
                                <RenameMaskingPolicy
                                    open={modals.renameMaskingPolicy}
                                    onClose={() => setModals({ renameMaskingPolicy: false })}
                                    onSuccess={(name: string) => {
                                        NotificationStore.push({
                                            'data-testid': 'masking-policy-rename-success',
                                            title: t('globalPolicies.maskingPolicy.renameMaskingPolicySuccess', {
                                                name,
                                            }),
                                            kind: 'success',
                                        });
                                    }}
                                />
                            ) : null}

                            {modals.deleteMaskingPolicy ? (
                                <DeleteMaskingPolicy
                                    open={modals.deleteMaskingPolicy}
                                    onClose={() => setModals({ deleteMaskingPolicy: false })}
                                    onSuccess={(name: string) => {
                                        NotificationStore.push({
                                            kind: 'success',
                                            'data-testid': 'masking-policy-delete-success',
                                            title: t('globalPolicies.maskingPolicy.deletedMaskingPolicyName', {
                                                name: name,
                                            }),
                                        });
                                    }}
                                />
                            ) : null}

                            {(status === 'loading' || status === 'idle') && <SpinnerOverlay description="" />}
                            {maskingPolicy && (
                                <>
                                    <PageHeader
                                        title={maskingPolicy.name}
                                        actions={actions}
                                        breadcrumbData={breadcrumbData}
                                        onActionSelect={onSelectAction}
                                    />
                                    <div className="px-8 mt-4 text-lg">
                                        <div
                                            className={
                                                'pb-2 w-40 border-solid border-b-2 border-blue-60 cursor-pointer'
                                            }>
                                            <div className="ml-4">{t('globalPolicies.maskingPolicy.overview')}</div>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="w-1/2 med:w-3/5">
                                            <Overview
                                                title={t('globalPolicies.maskingPolicy.maskingPolicyDetails')}
                                                info={MaskingPolicyStore.getMaskingPolicyOverview(maskingPolicy)}
                                                topic="maskingPolicyDetail"
                                            />
                                        </div>
                                        <div className="w-1/2 med:w-2/5">
                                            <OverviewCard
                                                title={t('globalPolicies.attachedResources')}
                                                topic="maskingPolicyAttachedResources">
                                                <DataGrid
                                                    addMargin={false}
                                                    idPrefix="maskingPolicyAssociation"
                                                    rows={rows || []}
                                                    headers={headers}></DataGrid>
                                            </OverviewCard>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Content>
                    </>
                );
            }}
        </Observer>
    );
};

export default MaskingPolicyDetails;
