import React, { useEffect } from 'react';
import TextInput from '@baffle/components/src/forms/TextInput';
import PasswordToggleInput from '@baffle/components/src/forms/PasswordToggleInput';
import DropdownSelect, {
    getDefaultLIProps,
    getDefaultMenuProps,
    getDefaultToggleButtonProps,
} from '@baffle/components/src/dropdown/Dropdown';
import { FileUploader } from 'carbon-components-react';
import { t } from '@baffle/translate';
import { DEKStorageTypeHashicorpValues, DEKStorageTypeHashicorp } from '@baffle/graphql/src/models';
import keystoreClient from '@baffle/api-client/src/keystoreClient';

const AddHashicorpKeystore = ({
    inputState,
    inputErrors,
    inputActions,
    editMode,
    selectedKS,
    touched,
    postAPIError,
}: any) => {
    const dekItems = Object.values(DEKStorageTypeHashicorp).map((key: DEKStorageTypeHashicorp) => ({
        id: DEKStorageTypeHashicorpValues[key],
        label: DEKStorageTypeHashicorp[key],
    }));
    useEffect(() => {
        if (!editMode) {
            inputActions.setFields({
                vaultPort: 8200,
                unsealKeys: null,
                dekStore: 'vault',
                vaultKvVersion: '2',
            });
        } else {
            getHashiVaultKeystore();
            touched(false);
        }
    }, []);

    const getHashiVaultKeystore = async () => {
        try {
            const hashiKs = await keystoreClient.getHashiKS(selectedKS.id || '');
            if (hashiKs) {
                inputActions.setFields({
                    name: hashiKs.name,
                    description: hashiKs.description ? hashiKs.description : '',
                    type: selectedKS.type,
                    authToken: hashiKs.details?.authToken,
                    vaultServer: hashiKs.details?.vaultServer,
                });
            }
        } catch (error) {
            postAPIError(JSON.parse(error.message).errors.join(', '));
        }
    };

    return (
        <>
            <div className="flex flex-wrap mb-6 items-start">
                <div className="flex-1 pr-4">
                    <PasswordToggleInput
                        id="ks-authToken-input"
                        name="ks-authToken-input"
                        labelText={t('keystore.hashicorpAuthToken')}
                        placeholder={t('keystore.authTokenPlaceholder')}
                        value={(inputState.authToken as string) ?? ''}
                        invalid={Boolean(inputErrors.authToken)}
                        invalidText={t('keystore.authTokenInvalid')}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value.trim();
                            inputActions.setField('authToken', val);
                            touched(true);
                        }}
                        onBlur={() => inputActions.validateField({ field: 'authToken', skipNull: true })}
                    />
                </div>
                {!editMode && (
                    <div className="flex-1 pl-4">
                        <TextInput
                            id="ks-masterKeyId-input"
                            name="ks-masterKeyId-input"
                            labelText={t('keystore.masterKeyIdInputLabel')}
                            placeholder={t('keystore.masterKeyIdPlaceholder')}
                            value={inputState.masterKeyId ?? ''}
                            invalid={Boolean(inputErrors.masterKeyId)}
                            invalidText={inputErrors.masterKeyId}
                            charLimit={256}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value.trim();
                                inputActions.setField('masterKeyId', val);
                            }}
                            onBlur={() => {
                                inputActions.validateField({ field: 'masterKeyId', skipNull: true });
                            }}
                        />
                    </div>
                )}
            </div>

            <div className="flex flex-wrap mb-6 items-start">
                <div className="flex-1 pr-4">
                    <TextInput
                        id="ks-vaultServerIp-input"
                        name="ks-vaultServerIp-input"
                        labelText={t('keystore.hashicorpServerIp')}
                        placeholder={t('keystore.serverIpPlaceholder')}
                        value={inputState.vaultServer ?? ''}
                        invalid={Boolean(inputErrors.vaultServer)}
                        invalidText={t('keystore.hostServerIpInvalid')}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value.trim();
                            inputActions.setField('vaultServer', val);
                            touched(true);
                        }}
                        onBlur={() => inputActions.validateField({ field: 'vaultServer', skipNull: true })}
                    />
                </div>
                {!editMode && (
                    <div className="flex-1 pl-4">
                        <TextInput
                            id="ks-vaultServerPort-input"
                            name="ks-vaultServerPort-input"
                            labelText={t('keystore.hashicorpServerPort')}
                            placeholder={t('keystore.serverPortPlaceholder')}
                            value={inputState.vaultPort ?? ''}
                            invalid={Boolean(inputErrors.vaultPort)}
                            invalidText={t('keystore.hostPortInputInvalid')}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value.trim();
                                inputActions.setField('vaultPort', val);
                            }}
                            onBlur={() => {
                                inputActions.validateField({ field: 'vaultPort', skipNull: true });
                            }}
                        />
                    </div>
                )}
            </div>

            {!editMode && (
                <div className="flex flex-wrap mb-6 items-start">
                    <div className="flex-1 pr-4">
                        <TextInput
                            id="ks-vaultNamespace-input"
                            name="ks-vaultNamespace-input"
                            labelText={t('keystore.vaultNamespaceInputLabel')}
                            placeholder={t('keystore.vaultNamespacePlaceholder')}
                            value={inputState.vaultNamespace ?? ''}
                            invalid={Boolean(inputErrors.vaultNamespace)}
                            invalidText={inputErrors.vaultNamespace}
                            charLimit={256}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value.trim();
                                inputActions.setField('vaultNamespace', val);
                            }}
                            onBlur={() => {
                                inputActions.validateField({ field: 'vaultNamespace', skipNull: true });
                            }}
                        />
                    </div>
                    <div className="flex-1 pl-4">
                        <TextInput
                            id="ks-vaultKvVersion-input"
                            name="ks-vaultKvVersion-input"
                            labelText={t('keystore.kvVersionInputLabel')}
                            placeholder={t('keystore.kvVersionPlaceholder')}
                            value={inputState.vaultKvVersion ?? ''}
                            invalid={Boolean(inputErrors.vaultKvVersion)}
                            invalidText={inputErrors.vaultKvVersion}
                            charLimit={256}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value.trim();
                                inputActions.setField('vaultKvVersion', val);
                            }}
                            onBlur={() => {
                                inputActions.validateField({ field: 'vaultKvVersion', skipNull: true });
                            }}
                        />
                    </div>
                </div>
            )}
            {!editMode && (
                <div className="flex flex-wrap mb-6 items-start">
                    <div className="flex-1">
                        <PasswordToggleInput
                            id="ks-unsealKeys-input"
                            name="ks-unsealKeys-input"
                            labelText={t('keystore.hashicorpUnsealKey')}
                            placeholder={t('keystore.unsealKeyPlaceholder')}
                            value={inputState.unsealKeys ?? ''}
                            invalid={Boolean(inputErrors.unsealKeys)}
                            invalidText={t('keystore.unsealKeysInputInvalid')}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value.trim();
                                inputActions.setField('unsealKeys', val);
                            }}
                            onBlur={() => {
                                inputActions.validateField({ field: 'unsealKeys', skipNull: true });
                            }}
                        />
                    </div>
                </div>
            )}
            {!editMode && (
                <div className="flex flex-wrap mb-6 items-start">
                    <div className="flex-1">
                        <div className="w-64">
                            <DropdownSelect
                                id="ks-dekStore-input"
                                placeholder={t('main.chooseOption')}
                                label={t('keystore.dekStoreInputLabel')}
                                value={
                                    inputState.dekStore
                                        ? { id: inputState.dekStore as string, label: inputState.dekStore as string }
                                        : { id: 'chooseOption', label: t('main.chooseOption') }
                                }
                                getLIProps={({ index }) => {
                                    const { className: liClasses, ...rest } = getDefaultLIProps();
                                    return {
                                        className: `${liClasses} w-full truncate`,
                                        ...rest,
                                    };
                                }}
                                getMenuProps={() => {
                                    const { className: ulClasses, ...rest } = getDefaultMenuProps();
                                    return {
                                        className: `${ulClasses} w-full`,
                                        ...rest,
                                    };
                                }}
                                getToggleButtonProps={() => {
                                    const { className: btnClasses, ...rest } = getDefaultToggleButtonProps();
                                    return {
                                        className: `${btnClasses} w-full`,
                                        ...rest,
                                    };
                                }}
                                handleSelectedItemChange={({ selectedItem }) => {
                                    inputActions.setField('dekStore', selectedItem?.id as string);
                                }}
                                items={dekItems}
                            />
                        </div>
                    </div>
                </div>
            )}
            {inputState.dekStore === DEKStorageTypeHashicorpValues.vault && !editMode ? (
                <>
                    <div className="flex flex-wrap mb-6 items-start">
                        <div className="flex-1 pr-4">
                            <TextInput
                                id="ks-vaultBucket-input"
                                name="ks-vaultBucket-input"
                                labelText={t('keystore.hashicorpVaultBucket')}
                                placeholder={t('keystore.vaultBucketPlaceholder')}
                                value={(inputState.vaultBucket as string) ?? ''}
                                invalid={Boolean(inputErrors.vaultBucket)}
                                invalidText={inputErrors.vaultBucket}
                                charLimit={256}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const val = e.target.value.trim();
                                    inputActions.setField('vaultBucket', val);
                                }}
                                onBlur={() => inputActions.validateField({ field: 'vaultBucket', skipNull: true })}
                            />
                        </div>
                        <div className="flex-1 pl-4">
                            <TextInput
                                id="ks-appNamespace-input"
                                name="ks-appNamespace-input"
                                labelText={t('keystore.appNamespaceInputLabel')}
                                placeholder={t('keystore.appNamespacePlaceholder')}
                                value={inputState.appNamespace ?? ''}
                                invalid={Boolean(inputErrors.appNamespace)}
                                invalidText={t('keystore.appNamespaceInputInvalid')}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const val = e.target.value.trim();
                                    inputActions.setField('appNamespace', val);
                                }}
                                onBlur={() => {
                                    inputActions.validateField({ field: 'appNamespace', skipNull: true });
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap items-start">
                        <div className="flex-1 pr-4">
                            <FileUploader
                                aria-label={t('keystore.certInputLabel')}
                                buttonLabel={t('keystore.hashicorpVaultTruststoreCert')}
                                accept={['.jks']}
                                name="ks-hashicorp-cert-input"
                                id="ks-hashicorp-cert-input"
                                buttonKind="tertiary"
                                filenameStatus="edit"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    inputActions.setField('vaultTruststore', e.target.files ? e.target.files[0] : null)
                                }
                            />
                        </div>
                        <div className="flex-1 pl-4">
                            {inputState.vaultTruststore ? (
                                <PasswordToggleInput
                                    id="ks-vaultTruststorePassword-input"
                                    name="ks-vaultTruststorePassword-input"
                                    labelText={t('keystore.hashicorpVaultTruststorePw')}
                                    placeholder={t('keystore.vaultTruststorePlaceholderPw')}
                                    value={inputState.vaultTruststorePassword ?? ''}
                                    invalid={Boolean(inputErrors.vaultTruststorePassword)}
                                    invalidText={t('keystore.vaultTruststorePwInvalid')}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const val = e.target.value.trim();
                                        inputActions.setField('vaultTruststorePassword', val);
                                    }}
                                    onBlur={() => {
                                        inputActions.validateField({
                                            field: 'vaultTruststorePassword',
                                            skipNull: true,
                                        });
                                    }}
                                />
                            ) : null}
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
};

export default AddHashicorpKeystore;
