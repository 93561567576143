import React, { useEffect, useState } from 'react';
import { t } from '@baffle/translate';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import Joi from '@hapi/joi';
import inviteUserClient from '@baffle/api-client/src/inviteUserClient';
import useAwait from '@baffle/api-client/src/useAwait';
import { NotificationStore } from '../stores/NotificationStore';
import { TextInput, Modal } from 'carbon-components-react';

interface AddIbmUserModalProps {
    open: boolean;
    onClose: () => void;
    onSuccess: () => void;
}

const AddIbmUserModal = ({ open, onSuccess = () => null, onClose = () => null }: AddIbmUserModalProps) => {
    const { run, reset, error } = useAwait();
    const [openModal, setOpen] = useState(open);

    const { inputState, inputActions, inputErrors } = useInputState({
        ns: 'users',
        defaultValues: {},
        keys: ['email'],
        validationSchema: {
            email: Joi.string()
                .email({ tlds: false })
                .min(1)
                .required(),
        },
    });
    const isValid = inputActions.validateAll(true);

    //Sync state from props
    useEffect(() => {
        setOpen(open);
    }, [open]);

    useEffect(() => {
        if (error) {
            NotificationStore.push({
                'data-testid': 'user-invite-failure',
                kind: 'error',
                title: t('users.inviteFail'),
                description: t('users.inviteFailureDescription'),
            });
        }
    }, [error]);

    const handleClose = () => {
        onClose();
        inputActions.reset();
        reset();
    };

    const onSubmit = (email: string) => {
        run(
            inviteUserClient.addIbmUser(email).then(() => {
                handleClose();
                if (onSuccess) {
                    onSuccess();
                }
            })
        );
    };
    return (
        <Modal
            id="user-invite-modal"
            className="modal-container-white"
            open={openModal}
            modalHeading={t('users.addUsers')}
            onRequestClose={handleClose}
            primaryButtonText={t('main.save')}
            secondaryButtonText={t('main.cancel')}
            size="sm"
            onRequestSubmit={() => onSubmit(inputState.email as string)}
            primaryButtonDisabled={!isValid}>
            <TextInput
                id="add-user-email-input"
                className="text-input-gray10"
                name="add-user-email-input"
                data-testid="add-user-email-input"
                labelText={t('users.email')}
                placeholder={t('users.typeEmail')}
                invalid={Boolean(inputErrors.email)}
                invalidText={t('users.emailInputInvalid')}
                value={(inputState.email as string) || ''}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    inputActions.validateField({
                        field: 'email',
                        skipNull: true,
                    });
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const val = e.target.value || '';
                    inputActions.setField('email', val);
                }}
            />
        </Modal>
    );
};

export default AddIbmUserModal;
