import React, { useState, useEffect } from 'react';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import CloseIcon from '@material-ui/icons/Close';
import SkeletonText from '../skeleton/SkeletonText';
import cx from 'classnames';
import './RightPanel.scss';
import { t } from '@baffle/translate';
import OverflowMenu from '../menu/OverflowMenu';
import Button from '../buttons/Button';
import { BaseCompoundComponentProps } from '../types/react';

export type TwoColTableModuleCol = [string, React.ReactElement | JSX.Element | string | number | null | undefined];

export interface TwoColTableModuleRow {
    'data-testid'?: string;
    cols: TwoColTableModuleCol;
}

interface TwoColTableModuleProps {
    rows: TwoColTableModuleRow[];
}

export const TableLeftColumnCell = ({ children }: BaseCompoundComponentProps) => {
    return <div className="module--table-td left-col">{children}</div>;
};
export const TableColumnSeperator = ({ children }: BaseCompoundComponentProps) => {
    return <div className="module--table-col-seperator">{children ?? ':'}</div>;
};
export const TableRightColumnCell = ({ children }: BaseCompoundComponentProps) => {
    return <div className="module--table-td right-col truncate">{children}</div>;
};

export const TableLeftColumn = ({ children }: BaseCompoundComponentProps) => {
    return <div className="module--table-left-col">{children}</div>;
};

export const TableRightColumn = ({ children }: BaseCompoundComponentProps) => {
    return <div className="module--table-right-col">{children}</div>;
};

export const TwoColTableModule = ({ rows }: TwoColTableModuleProps) => {
    const uniqueKeySig = Math.random();
    const leftCol: any[] = [];
    const rightCol: any[] = [];
    rows.forEach((r: TwoColTableModuleRow, i: number) => {
        r.cols.forEach((c, j: number) => {
            if (j === 0) {
                leftCol.push(
                    <TableLeftColumnCell key={`module--table-col-${i}-${j}-${uniqueKeySig}`}>{c}</TableLeftColumnCell>
                );
            } else if (j === 1) {
                rightCol.push(
                    <TableRightColumnCell key={`module--table-col-${i}-${j}-${uniqueKeySig}`}>{c}</TableRightColumnCell>
                );
            }
        });
    });
    const colSeperators = [...new Array(leftCol.length).keys()].map((_: any, i: number) => (
        <TableColumnSeperator key={`col-sep-${i}-${uniqueKeySig}`}>: </TableColumnSeperator>
    ));
    return (
        <div className="module--table flex">
            <TableLeftColumn>{leftCol}</TableLeftColumn>
            <div className="module--table-col-seperator">{colSeperators}</div>
            <TableRightColumn>{rightCol}</TableRightColumn>
        </div>
    );
};

export const PanelTitle = ({ children }: BaseCompoundComponentProps) => {
    return <div className="mb-2 font-semibold text-xs text-gray-700 flex justify-between items-center">{children}</div>;
};

export const PanelDescription = ({ children }: BaseCompoundComponentProps) => {
    return <div className="module--description break-all">{children}</div>;
};

interface PanelModuleProps extends BaseCompoundComponentProps {
    title?: string;
    action?: React.ReactChild | React.ReactFragment | string;
    description?: React.ReactChild | React.ReactFragment | string;
    ['data-testid']?: string;
}

export const PanelModule = ({ title, description, children, action, 'data-testid': dataTestId }: PanelModuleProps) => {
    return (
        <div className="panel--module" data-testid={dataTestId ?? ''}>
            {title ? (
                <PanelTitle>
                    {title} {action ?? null}
                </PanelTitle>
            ) : null}
            {description ? <PanelDescription>{description || ''}</PanelDescription> : null}
            {children}
        </div>
    );
};

interface ConfigMenuItem {
    item: string | React.ReactElement;
    onClick: (e: React.MouseEvent) => void;
}
interface PanelProps extends BaseCompoundComponentProps {
    title: string;
    skeleton?: boolean;
    configMenuItems?: ConfigMenuItem[];
    onClose?: () => void;
    onCloseImmediate?: () => void;
    showOverflowMenu?: boolean;
}

const RightPanel = ({
    title,
    children,
    configMenuItems = [],
    skeleton,
    onClose = () => null,
    onCloseImmediate = () => null,
    showOverflowMenu = true,
    ...rest
}: PanelProps) => {
    const [animateOut, setAnimateOut] = useState(false);
    const panelClasses = cx('right-panel flex flex-col bg-white shadow-l h-full', {
        out: animateOut,
    });
    let animateOutFn: any = null;
    const onCloseHandler = () => {
        onCloseImmediate();
        const animateOutTimeout = 501;
        if (!animateOut) {
            setAnimateOut(true);
            animateOutFn = setTimeout(() => {
                onClose();
            }, animateOutTimeout);
        }
    };
    useEffect(() => {
        return () => {
            if (animateOut) {
                clearTimeout(animateOutFn);
            }
        };
    }, [animateOut]);
    return (
        <div>
            <div className={panelClasses} {...rest}>
                <div className="panel--header flex justify-between items-center pb-2 pl-4">
                    <div className="panel--title truncate pr-2">{skeleton ? <SkeletonText /> : title}</div>
                    <div className="panel--action flex items-center flex-end">
                        {showOverflowMenu && (
                            <OverflowMenu
                                menuItems={configMenuItems.map(c => ({ text: c.item, onClick: c.onClick }))}
                                data-testid="sidepanel-config-menu"
                                menuClassName="z-10"
                                actuator={{
                                    'aria-label': t('main.ariaSidepanelConfigBtn'),
                                    className: 'focus:outline-none',
                                    'data-testid': 'sidepanel-config-btn',
                                    text: <SettingsIcon className="w-5 h-5" />,
                                }}
                            />
                        )}
                        <Button
                            onClick={onCloseHandler}
                            theme="icon-transparent"
                            aria-label={t('main.closeSidepanel')}
                            data-testid="close-side-panel-btn"
                            id="close-side-panel-btn">
                            <CloseIcon className="w-5 h-5" />
                        </Button>
                    </div>
                </div>
                <div className="panel-content flex flex-col px-4">{children}</div>
            </div>
        </div>
    );
};

export default RightPanel;
