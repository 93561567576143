import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import client from '@baffle/graphql/src/apollo/client';
import { ApolloProvider } from '@apollo/react-hooks';

import './styles/index.scss';
import './App.scss';
import './styles/overrides.scss';
import './styles/theme-white.scss';

import BaffleApp from './BaffleApp';
import Applications from './application/Applications';
import EncryptionPolicies from './policies/EncryptionPolicies';
import Rbac from './policies/rbac/Rbac';
import RbacUserGroupDetails from './policies/rbac/usergroup/RbacUserGroupDetails';
import MaskingPolicies from './policies/masking/MaskingPolicies';
import MaskingPolicyDetails from './policies/masking/MaskingPolicyDetails';
import DataBases from './database/DataBases';
import KeyStores from './keystore/KeyStores';
import Shields from './shields/Shields';
import Encryption from './application/encryption/Encryption';
import EncryptionConfirmation from './application/encryption/EncryptionConfirmation';
import Decryption from './application/decryption/Decryption';
import DecryptionConfirmation from './application/decryption/DecryptionConfirmation';
import Session from './session/Session';
import Login from './session/Login';
import LoginIBM from './session/LoginIBM';
import RecoverPassword from './session/RecoverPassword';
import Profile from './user/Profile';
import Setup from './setup/Setup';
import ConfigureSystem from './setup/ConfigureSystem';
import RegisterAdminUser from './setup/RegisterAdminUser';
import RegisterIbmAdmin from './setup/RegisterIbmAdmin';
import ConfigureCredentialStore from './setup/ConfigureCredentialStore';
import { SessionStore, SystemEmailStore, UsersStore } from './stores';
import { Observer } from 'mobx-react-lite';
import AppConfiguration from './application/config-editor/AppConfiguration';
import Users from './users/Users';
import useAwait from '@baffle/api-client/src/useAwait';
import { isSuperAdmin } from '@baffle/utilities/src/user/roles';
import CreateProfile from './session/CreateProfile';
import systemClient from '../../api-client/src/systemClient';
import { ToastStore } from './stores/ToastStore';
import { signOut } from '../../graphql/src';
import MigrationStatusDashboard from './application/migration/MigrationStatusDashboard';
import DatabasesV2 from './database/v2/DatabasesV2';
import CreateDatabase from './database/v2/CreateDatabase';
import CreateRbacPolicy from './policies/rbac/rbacPolicy/CreateRbacPolicy';
import RbacPolicyDetails from './policies/rbac/rbacPolicy/RbacPolicyDetails';
import KeyStoresV2 from './keystoreV2/keystoreV2';
import KeystoreDetails from './keystoreV2/keystoreDetails';
import DatabaseDetails from './database/v2/DatabaseDetails';
import KeystoreCMK from './keystoreV2/keystoreCmk';
import BaffleSettings from './settings/BaffleSettings';
import Tenants from './tenants/Tenants';
import TenantDetails from './tenants/TenantDetails';
import SystemError from './setup/SystemError';
import Roles from './roles/Roles';
import RoleDetails from './roles/RoleDetails';
import DatabaseProxies from './database-proxy/DataBaseProxies';
import DatabaseProxyDetails from './database-proxy/database-proxy-details/DatabaseProxyDetails';

const PrivateRoute = ({ children, superAdmin, ...rest }: any) => {
    const { status, run } = useAwait();
    useEffect(() => {
        run(UsersStore.readCurrentUser({ networkPolicy: 'cache-first' }));
    }, [run]);
    const history = useHistory();

    return (
        <Observer>
            {() => {
                if (status === 'loading' || status === 'idle') {
                    return <div />;
                }
                if (!SessionStore.authorized || SessionStore.inactive) {
                    signOut();
                    return <div></div>;
                }
                if (superAdmin && !isSuperAdmin(UsersStore.currentUser?.roles ?? [])) {
                    history.replace('/');
                }

                return (
                    <Route
                        {...rest}
                        render={() => {
                            return children;
                        }}
                    />
                );
            }}
        </Observer>
    );
};

/**
 *
 * LockedRoute is used to indicate this route can only be accessed if the application is not locked
 */
const LockedRoute = ({ children, ...rest }: any) => {
    const history = useHistory();
    const { data, error, status, run } = useAwait();

    useEffect(() => {
        run(systemClient.readApplicationAccess());
    }, []);

    useEffect(() => {
        if (error) {
            ToastStore.push({ type: 'danger', title: error.message });
        }
    }, [error]);

    if (status === 'loading' || status === 'idle') {
        return null;
    }

    if (data?.locked) {
        history.replace('/configure-system');
    }
    return (
        <Route
            {...rest}
            render={() => {
                return children;
            }}
        />
    );
};

/**
 *
 * UnLockedRoute is used to indicate this route can only be accessed if the application is locked
 */
const UnLockedRoute = ({ children, ...rest }: any) => {
    const history = useHistory();
    const { data, error, status, run } = useAwait();

    useEffect(() => {
        run(systemClient.readApplicationAccess());
    }, []);

    useEffect(() => {
        if (error) {
            ToastStore.push({ type: 'danger', title: error.message });
        }
    }, [error]);

    if (status === 'loading' || status === 'idle') {
        return null;
    }

    if (Boolean(data) && !data?.locked) {
        history.replace('/login');
    }

    return (
        <Route
            {...rest}
            render={() => {
                return children;
            }}
        />
    );
};

const App = () => {
    const { run, status } = useAwait();
    return (
        <Observer>
            {() => {
                const clientEnvironment = SystemEmailStore.systemEnvironment;
                useEffect(() => {
                    run(SystemEmailStore.getApplicationAccessData());
                }, []);
                useEffect(() => {
                    return SystemEmailStore.watchSystemHealth();
                });

                if (status === 'error') {
                    return <SystemError />;
                }

                return (
                    <ApolloProvider client={client}>
                        <Router>
                            <Switch>
                                <PrivateRoute exact path="/">
                                    <BaffleApp>
                                        <Applications />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute path="/applications">
                                    <BaffleApp>
                                        <Applications />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute exact path="/application/encryption/:id">
                                    <BaffleApp>
                                        <Encryption />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute exact path="/application/decryption/:id">
                                    <BaffleApp>
                                        <Decryption />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute exact path="/application/encryption/:id/confirm">
                                    <BaffleApp>
                                        <EncryptionConfirmation />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute exact path="/application/decryption/:id/confirm">
                                    <BaffleApp>
                                        <DecryptionConfirmation />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute exact path="/application/configuration/:id/:fileName?/:shieldId?">
                                    <BaffleApp>
                                        <AppConfiguration />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute exact path="/application/migrationstatus/:id">
                                    <BaffleApp>
                                        <MigrationStatusDashboard />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute exact path="/database-proxies">
                                    <BaffleApp>
                                        <DatabaseProxies />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute exact path="/database-proxies/:id">
                                    <BaffleApp>
                                        <DatabaseProxyDetails />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute path="/encryption-policies">
                                    <BaffleApp>
                                        <EncryptionPolicies />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute exact path="/masking-policies">
                                    <BaffleApp>
                                        <MaskingPolicies />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute exact path="/masking-policies/:id">
                                    <BaffleApp>
                                        <MaskingPolicyDetails />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute exact path="/rbac">
                                    <BaffleApp>
                                        <Rbac />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute exact path="/rbac/rbac-policies/create">
                                    <BaffleApp>
                                        <CreateRbacPolicy />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute exact path="/rbac/rbac-policy/:id">
                                    <BaffleApp>
                                        <RbacPolicyDetails />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute exact path="/rbac/usergroup/:id">
                                    <BaffleApp>
                                        <RbacUserGroupDetails />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute exact path="/databasesV2">
                                    <BaffleApp>
                                        <DatabasesV2 />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute exact path="/databasesV2/create">
                                    <BaffleApp>
                                        <CreateDatabase />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute exact path="/databasesV2/:id">
                                    <BaffleApp>
                                        <DatabaseDetails />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute path="/databases">
                                    <BaffleApp>
                                        <DataBases />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute path="/keystores">
                                    <BaffleApp>
                                        <KeyStores />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute path="/keystoresV2">
                                    <BaffleApp>
                                        <KeyStoresV2 />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute exact path="/keystore/:id">
                                    <BaffleApp>
                                        <KeystoreDetails />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute exact path="/keystore/:id/:cmk">
                                    <BaffleApp>
                                        <KeystoreCMK />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute path="/shields">
                                    <BaffleApp>
                                        <Shields />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute exact path="/tenants">
                                    <BaffleApp>
                                        <Tenants />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute exact path="/tenant/:id">
                                    <BaffleApp>
                                        <TenantDetails />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute exact path="/roles">
                                    <BaffleApp>
                                        <Roles />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute exact path="/roles/:id">
                                    <BaffleApp>
                                        <RoleDetails />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute superAdmin path="/users">
                                    <BaffleApp>
                                        <Users />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute path="/settings">
                                    <BaffleApp>
                                        <BaffleSettings />
                                    </BaffleApp>
                                </PrivateRoute>
                                <PrivateRoute path="/profile">
                                    <BaffleApp>
                                        <Profile version={clientEnvironment} />
                                    </BaffleApp>
                                </PrivateRoute>
                                <Route path="/activation/:token">
                                    <LockedRoute>
                                        <Session>
                                            <CreateProfile />
                                        </Session>
                                    </LockedRoute>
                                </Route>
                                <Route path="/login">
                                    <LockedRoute>
                                        {clientEnvironment === 'IBM_CLOUD' ? (
                                            <LoginIBM />
                                        ) : (
                                            <Session>
                                                <Login />
                                            </Session>
                                        )}
                                    </LockedRoute>
                                </Route>
                                <Route path="/forgot">
                                    <LockedRoute>
                                        <Session>
                                            <RecoverPassword />
                                        </Session>
                                    </LockedRoute>
                                </Route>
                                <Route path="/configure-system">
                                    <UnLockedRoute>
                                        <Setup version={clientEnvironment}>
                                            <ConfigureSystem />
                                        </Setup>
                                    </UnLockedRoute>
                                </Route>
                                <Route path="/configure-admin-user">
                                    <Setup version={clientEnvironment}>
                                        {clientEnvironment === 'IBM_CLOUD' ? (
                                            <RegisterIbmAdmin />
                                        ) : (
                                            <RegisterAdminUser />
                                        )}
                                    </Setup>
                                </Route>
                                <Route path="/configure-keystore">
                                    <Setup version={clientEnvironment}>
                                        <ConfigureCredentialStore />
                                    </Setup>
                                </Route>
                            </Switch>
                        </Router>
                    </ApolloProvider>
                );
            }}
        </Observer>
    );
};

export default App;
