import Joi from '@hapi/joi';
import { t } from '@baffle/translate';

export default function createJsonPolicyScheme(jsonPolicies: any[], id: string | undefined) {
    const jsonPolicyInputScheme = {
        ns: 'jsonPolicy',
        keys: ['name', 'description', 'jsonPayload', 'jsonFields'],
        validationSchema: {
            name: Joi.string()
                .trim()
                .min(1)
                .max(30)
                .required()
                .custom(val => {
                    if (
                        jsonPolicies.some(
                            (a: any) => a.id !== id && a.name.trim().toLowerCase() === val.trim().toLowerCase()
                        )
                    ) {
                        throw new Error(t('encryption.modeLibrary.jsonPolicy.duplicatePolicyNameError'));
                    }
                    return val;
                })
                .pattern(/^[a-zA-Z0-9_-\s]+$/)
                .messages({
                    'string.empty': t('encryption.modeLibrary.jsonPolicy.policyNameEmptyError'),
                    'any.custom': t('encryption.modeLibrary.jsonPolicy.duplicatePolicyNameError'),
                    'string.pattern.base': t('encryption.modeLibrary.jsonPolicy.policyNameInvalidError'),
                    'string.max': t('encryption.modeLibrary.jsonPolicy.policyNameInvalidError'),
                }),
            description: Joi.string()
                .max(100)
                .allow(null, '')
                .messages({
                    'string.max': t('encryption.modeLibrary.rbac.rbacUsrGrp.descriptionInvalidError'),
                }),
            jsonPayload: Joi.string().allow(null, ''),
            jsonFields: Joi.array().items(
                Joi.object({
                    jsonKeyName: Joi.string()
                        .trim()
                        .required()
                        .messages({ 'string.empty': t('encryption.modeLibrary.jsonPolicy.jsonFieldEmptyError') }),
                    datatype: Joi.string()
                        .required()
                        .messages({ 'string.empty': t('encryption.modeLibrary.jsonPolicy.datatypeEmptyError') }),
                })
            ),
        },
    };
    return jsonPolicyInputScheme;
}
