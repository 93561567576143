import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import Button from '@baffle/components/src/buttons/Button';
import TextInput from '@baffle/components/src/forms/TextInput';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import Joi from '@hapi/joi';
import { t } from '@baffle/translate';
import { useHistory } from 'react-router-dom';
import InlineToast from '@baffle/components/src/toasts/InlineToast';
import useEnterKeyPress from '@baffle/utilities/src/useEnterKeyPress';
import { GET_SYSTEM } from '@baffle/graphql';
import { useSetToast } from '@baffle/components/src/toasts/Toast';
import { SystemInfo } from '@baffle/graphql/src/models';
import { SetupStore, SystemEmailStore } from '../stores';
import PasswordToggleInput from '@baffle/components/src/forms/PasswordToggleInput';

const ConfigureSystem = () => {
    const history = useHistory();
    const env = SystemEmailStore.systemEnvironment;
    const { toast, setToast } = useSetToast();
    const { inputState, inputErrors, inputActions } = useInputState({
        keys: ['initPass', 'domain', 'publicAccess', 'proxyAccess', 'organization'],
        ns: 'setup',
        defaultValues: { domain: '', publicAccess: '', proxyAccess: '', organization: '' },
        validationSchema: {
            initPass: Joi.string()
                .min(1)
                .max(256)
                .required()
                .allow(null)
                .messages({
                    'string.max': t('main.invalidLength', { max: 256 }),
                    'string.empty': t('main.invalidEmpty'),
                }),
            domain: Joi.string()
                .domain()
                .min(1)
                .max(256)
                .required()
                .messages({
                    'string.domain': t('main.invalidDomain'),
                    'string.max': t('main.invalidLength', { max: 256 }),
                    'string.empty': t('main.invalidEmpty'),
                }),
            publicAccess: Joi.string()
                .min(1)
                .required()
                .error(new Error(t('setup.publicAccessInputInvalid'))),
            proxyAccess: Joi.string()
                .min(1)
                .required()
                .error(new Error(t('setup.proxyAccessInputInvalid'))),
            organization: Joi.string()
                .min(1)
                .max(256)
                .required()
                .messages({
                    'string.max': t('main.invalidLength', { max: 256 }),
                    'string.empty': t('main.invalidEmpty'),
                }),
        },
    });

    const isValid = inputActions.validateAll(true);

    const { loading, data } = useQuery(GET_SYSTEM);

    //to prefill need to do a controlled component
    const systemInfo: SystemInfo = data ? data.systemInfo : {};

    useEffect(() => {
        inputActions.setFields({
            initPass: env !== 'IBM_CLOUD' ? null : '',
            domain: systemInfo.domain || '',
            publicAccess: systemInfo.publicAccess || '',
            organization: systemInfo.organization || '',
            proxyAccess: systemInfo.proxyAccess || '',
        });
    }, [data, loading]);

    const doSetSystem = async () => {
        const systemData: SystemInfo = {
            initPass: inputState.initPass as string,
            domain: inputState.domain as string,
            publicAccess: inputState.publicAccess as string,
            organization: inputState.organization as string,
            proxyAccess: inputState.proxyAccess as string,
            id: '',
        };
        SetupStore.setSystemData(systemData);
        history.replace('/configure-admin-user');
    };

    useEnterKeyPress(() => {
        if (isValid) {
            doSetSystem();
        }
    });

    const systemData: SystemInfo | null = SetupStore.systemData;

    useEffect(() => {
        inputActions.setFields({
            initPass: systemData?.initPass,
            domain: systemData?.domain,
            publicAccess: systemData?.publicAccess,
            organization: systemData?.organization,
            proxyAccess: systemData?.proxyAccess,
        });
    }, [systemData]);

    return (
        <div className="w-132">
            <div className="bg-white py-4 px-8">
                {toast.show ? (
                    <InlineToast
                        className="mb-5"
                        duration={100000}
                        title={toast.title}
                        type={toast.type}
                        onDismiss={() => setToast({ title: '', show: false, type: 'success' })}
                    />
                ) : null}

                <div className="my-4 text-lg">
                    <span className="font-bold">{t('setup.step1')}</span> {t('setup.configureSystem')}
                </div>

                {env === 'IBM_CLOUD' && (
                    <div className="mb-6">
                        <PasswordToggleInput
                            name="setup-initPass-input"
                            id={`setup-initPass-input`}
                            labelText={t('setup.initPassInputLabel')}
                            placeholder={t('setup.initPassInputPlaceholder')}
                            invalid={Boolean(inputErrors.initPass)}
                            invalidText={inputErrors.initPass}
                            value={(inputState.initPass as string) ?? ''}
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                //@ts-ignore
                                inputActions.setField('initPass', (inputState.initPass || '').trim());
                                inputActions.validateField({ field: 'initPass', skipNull: true });
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                inputActions.setField('initPass', val);
                            }}
                        />
                    </div>
                )}

                <div className="mb-6">
                    <TextInput
                        name="setup-organization-input"
                        id={`setup-organization-input`}
                        labelText={t('setup.organizationInputLabel')}
                        placeholder={t('setup.organizationInputPlaceholder')}
                        invalid={Boolean(inputErrors.organization)}
                        invalidText={inputErrors.organization}
                        type="text"
                        value={inputState.organization ?? ''}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            //@ts-ignore
                            inputActions.setField('organization', (inputState.organization || '').trim());
                            inputActions.validateField({ field: 'organization', skipNull: true });
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value || '';
                            inputActions.setField('organization', val);
                        }}
                    />
                </div>
                <div className="mb-6">
                    <TextInput
                        name="setup-domain-input"
                        id={`setup-domain-input`}
                        labelText={t('setup.domainInputLabel')}
                        placeholder={t('setup.domainInputPlaceholder')}
                        invalid={Boolean(inputErrors.domain)}
                        invalidText={inputErrors.domain}
                        type="text"
                        value={inputState.domain ?? ''}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            inputActions.validateField({ field: 'domain', skipNull: true });
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value || '';
                            inputActions.setField('domain', val.trim());
                        }}
                    />
                </div>
                <div className="mb-6">
                    <TextInput
                        name="setup-public-access-input"
                        id={`setup-public-access-input`}
                        labelText={t('setup.publicAccessInputLabel')}
                        placeholder={t('setup.publicAccessInputPlaceholder')}
                        invalid={Boolean(inputErrors.publicAccess)}
                        invalidText={inputErrors.publicAccess}
                        value={inputState.publicAccess ?? ''}
                        type="text"
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            inputActions.validateField({ field: 'publicAccess', skipNull: true });
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value || '';
                            inputActions.setField('publicAccess', val.trim());
                        }}
                    />
                    <p className="text-gray-500 text-xxxs">{t(`setup.publicAccessMessage.${env}`)}</p>
                </div>
                <div className="mb-6">
                    <TextInput
                        name="setup-proxy-access-input"
                        id={`setup-proxy-access-input`}
                        labelText={t('setup.proxyAccessInputLabel')}
                        placeholder={t('setup.proxyAccessInputPlaceholder')}
                        invalid={Boolean(inputErrors.proxyAccess)}
                        invalidText={inputErrors.proxyAccess}
                        value={inputState.proxyAccess ?? ''}
                        type="text"
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            inputActions.validateField({ field: 'proxyAccess', skipNull: true });
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value || '';
                            inputActions.setField('proxyAccess', val.trim());
                        }}
                    />
                    <p className="text-gray-500 text-xxxs">{t(`setup.proxyAccessMessage.${env}`)}</p>
                </div>

                <div className="w-100 flex justify-end h-10 ">
                    <Button
                        theme={isValid ? 'primary' : 'disabled'}
                        id="do-configure-btn"
                        disabled={!isValid}
                        onClick={() => doSetSystem()}>
                        {t('setup.continue')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ConfigureSystem;
