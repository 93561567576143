import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { BaffleIconProps } from '../componentTypes';

const TreeDropdownDownIcon = ({
    color = '#000000',
    width = 24,
    height = 24,
    viewBox = '0 0 24 24',
    fontSize = 'small',
}: BaffleIconProps) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} fontSize={fontSize}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <polygon fill={color} fillRule="nonzero" points="7 10 12 15 17 10"></polygon>
            </g>
        </SvgIcon>
    );
};

export default TreeDropdownDownIcon;
