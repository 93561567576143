import React, { useEffect, useState } from 'react';
import { Observer } from 'mobx-react-lite';
import Content from '@baffle/components/src/content/Content';
import Header from '@baffle/components/src/nav/Header';
import { t } from '@baffle/translate';
import useAwait from '@baffle/api-client/src/useAwait';
import { RoleStore } from '@baffle/manager/src/stores';
import { RoleInfo } from '@baffle/graphql/src/models';
import DataGrid from '@baffle/components/src/grid/data-grid';
import { format } from '@baffle/utilities/src/date';
import { Modal } from 'carbon-components-react';
import AddRoleModal from './AddRoleModal';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';
import RenameRole from './RenameRole';
import { useManageModals } from '@baffle/components/src/modal/Modal';

const Roles = () => {
    const { status, run, error } = useAwait();
    const [update, setUpdate] = useState(true);
    interface RoleModals {
        deleteRole: boolean;
        renameRole: boolean;
        addRole: boolean;
    }

    const initialModalState = {
        deleteRole: false,
        renameRole: false,
        addRole: false,
    };

    const [modals, setModals] = useManageModals<RoleModals>(initialModalState);

    useEffect(() => {
        if (update === true) {
            run(RoleStore.getRoles());
            setUpdate(false);
        }
    }, [update]);

    useEffect(() => {
        if (error) {
            NotificationStore.push({
                'data-testid': 'role-add-failure',
                kind: 'error',
                title: t('roles.addRoleError'),
                description: error.message ?? t('main.unexpectedError'),
            });
        }
    }, [error]);

    const onSyncButtonClick = () => {
        setUpdate(true);
    };

    const headers = [
        {
            key: 'header1',
            header: t('main.name'),
        },
        {
            key: 'header2',
            header: t('roles.permissions'),
        },
        {
            key: 'header3',
            header: t('roles.dataPolicies'),
        },
        {
            key: 'header4',
            header: t('roles.created'),
        },
    ];

    return (
        <Observer>
            {() => {
                const roles: RoleInfo[] = RoleStore.roleList;

                const rows =
                    status === 'loading' || status === 'idle'
                        ? null //add  skeleton table on initial load
                        : roles.map((role: RoleInfo, i: number) => {
                              return {
                                  selectedId: role?.id,
                                  id: role.id,
                                  header1: role.name,
                                  header2: role.effects.join(', '),
                                  header3: role.dataPolicies,
                                  header4: format(role.createdTime, 'MMM dd, yyyy H:mm:ss a'),
                                  link: '/roles/' + role.id,
                                  overFlowMenu: [
                                      {
                                          name: t('main.rename'),
                                          onClick: (id: string) => {
                                              RoleStore.setRole(RoleStore.getById(id) as RoleInfo);
                                              setModals({ renameRole: true });
                                          },
                                      },
                                      {
                                          name: t('main.delete'),
                                          onClick: (id: string) => {
                                              RoleStore.setRole(RoleStore.getById(id) as RoleInfo);
                                              setModals({ deleteRole: true });
                                          },
                                      },
                                  ],
                              };
                          });

                const onAdd = () => {
                    RoleStore.setRole(null);
                    setModals({ addRole: true });
                };
                const onDelete = async () => {
                    setModals({ deleteRole: false });

                    run(
                        RoleStore.deleteRole(RoleStore.role?.id as string).then(() => {
                            if (status === 'success') {
                                NotificationStore.push({
                                    'data-testid': 'role-del-success',
                                    kind: 'success',
                                    title: t('roles.deleteRoleSuccessMsg', {
                                        name: RoleStore.role?.name,
                                    }),
                                });
                            }
                            RoleStore.setRole(null);
                        })
                    );
                };

                const onAddRole = async (roleInfo: RoleInfo) => {
                    setModals({ addRole: false });
                    run(
                        RoleStore.addRole(roleInfo).then(() => {
                            if (status === 'success') {
                                NotificationStore.push({
                                    'data-testid': 'role-add-success',
                                    kind: 'success',
                                    title: t('roles.addRoleSuccessMsg', {
                                        name: roleInfo.name,
                                    }),
                                });
                            }
                        })
                    );
                };

                const onRenameRole = async (roleInfo: RoleInfo) => {
                    setModals({ renameRole: false });
                    run(
                        RoleStore.updateRole(roleInfo).then(() => {
                            if (status === 'success') {
                                NotificationStore.push({
                                    'data-testid': 'role-update-success',
                                    kind: 'success',
                                    title: t('roles.updateRoleSuccessMsg', {
                                        name: roleInfo.name,
                                    }),
                                });
                            }
                        })
                    );
                };

                return (
                    <>
                        <Content>
                            <Header>
                                <div className="flex-1 my-5 px-10 flex justify-between">
                                    <div className="flex-1 flex items-center">
                                        <h1 className="text-heading-01 text-gray-100">{t('main.roles')}</h1>
                                    </div>
                                </div>
                            </Header>

                            <RenameRole
                                open={modals.renameRole}
                                onClose={() => {
                                    setModals({ renameRole: false });
                                }}
                                onSuccess={(role: RoleInfo) => {
                                    onRenameRole(role);
                                }}
                            />
                            <Modal
                                open={modals.deleteRole}
                                id="role-delete-modal"
                                modalHeading={t('roles.deleteConfirmationTitle', {
                                    name: RoleStore.role?.name,
                                })}
                                danger={true}
                                onRequestClose={() => {
                                    setModals({ deleteRole: false });
                                }}
                                primaryButtonText={t('main.delete')}
                                secondaryButtonText={t('main.cancel')}
                                size="sm"
                                onRequestSubmit={onDelete}>
                                <p>{t('roles.deleteConfirmationMsg', { name: RoleStore.role?.name })}</p>
                            </Modal>
                            {modals.addRole && (
                                <AddRoleModal
                                    open={modals.addRole}
                                    onClose={() => {
                                        setModals({ addRole: false });
                                    }}
                                    onSuccess={onAddRole}
                                />
                            )}
                            <div className="flex flex-col mb-10 flex-grow overflow-y-auto">
                                <DataGrid
                                    idPrefix="roles"
                                    rows={rows}
                                    headers={headers}
                                    onAdd={onAdd}
                                    showSearch={true}
                                    onSync={onSyncButtonClick}
                                    addMargin={true}
                                    addButtonCaption={t('main.create')}></DataGrid>
                            </div>
                        </Content>
                    </>
                );
            }}
        </Observer>
    );
};

export default Roles;
