import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { BaffleIconProps } from '../componentTypes';

const css = `.cls-1 {
    fill: none;
  }`;

const ArrowDownIcon = ({ width = 32, height = 32, viewBox = '0 0 32 32', fontSize = 'small' }: BaffleIconProps) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} fontSize={fontSize}>
            <defs>
                <style>{css}</style>
            </defs>
            <polygon points="24.59 16.59 17 24.17 17 4 15 4 15 24.17 7.41 16.59 6 18 16 28 26 18 24.59 16.59" />
            <rect
                id="_Transparent_Rectangle_"
                data-name="&lt;Transparent Rectangle&gt;"
                className="cls-1"
                width="32"
                height="32"
            />
        </SvgIcon>
    );
};

export default ArrowDownIcon;
