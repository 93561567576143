import React, { useEffect } from 'react';
import { t } from '@baffle/translate';
import useAwait from '@baffle/api-client/src/useAwait';
import { Observer } from 'mobx-react-lite';
import Header from '@baffle/components/src/nav/Header';
import { PolicyStore } from '../stores';
import { EncryptionModeV2 } from '@baffle/graphql/src/models';
import Content from '@baffle/components/src/content/Content';
import DataGrid from '@baffle/components/src/grid/data-grid';
import usePageHeight from '@baffle/utilities/src/usePageHeight';
//@ts-ignore
import { Document } from '@carbon/icons-react';

const EncryptionPolicies = () => {
    const { run, status } = useAwait();
    const pageHeight = usePageHeight() - 136;

    useEffect(() => {
        run(PolicyStore.listEncryptionModesV2());
    }, []);

    return (
        <Observer>
            {() => {
                const emptyCol = {
                    id: '',
                    header1: '',
                    header2: '',
                };

                const encModesUnfiltered = PolicyStore.encryptionModesV2;

                const rows =
                    status === 'loading' || status === 'idle'
                        ? //add  skeleton row on initial load
                          [...Array(Math.floor(1)).keys()].map(() => emptyCol)
                        : encModesUnfiltered.map((enc: EncryptionModeV2) => {
                              return {
                                  id: enc.id,
                                  header1: enc.name,
                                  header2: enc.description,
                              };
                          });

                const headers = [
                    {
                        key: 'header1',
                        header: t('main.name'),
                    },
                    {
                        key: 'header2',
                        header: t('main.description'),
                    },
                ];

                return (
                    <>
                        <Content>
                            <Header>
                                <div className="flex-1 px-4 flex justify-between pb-0">
                                    <div className="flex-1 flex items-center">
                                        <h1 className="text-heading-01 text-gray-100 pl-6 pt-5">
                                            {t('globalPolicies.encryptionHeader')}
                                        </h1>
                                        <Document size="24" className="ml-2 mt-6 document-icon " />
                                    </div>
                                </div>
                            </Header>
                            <div className="overflow-y-auto" style={{ height: pageHeight }}>
                                <DataGrid idPrefix="encryptionPolicy" rows={rows} headers={headers}></DataGrid>
                            </div>
                        </Content>
                    </>
                );
            }}
        </Observer>
    );
};

export default EncryptionPolicies;
