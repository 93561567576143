import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { BaffleIconProps } from '../componentTypes';

const TreeTableIcon = ({
    color = '#000000',
    width = 24,
    height = 24,
    viewBox = '0 0 24 24',
    fontSize = 'small',
}: BaffleIconProps) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} fontSize={fontSize}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <path
                    d="M3.9,21 C3.40294373,21 3,20.5970563 3,20.1 L3,3.9 C3,3.40294373 3.40294373,3 3.9,3 L20.1,3 C20.5970563,3 21,3.40294373 21,3.9 L21,20.1 C21,20.5970563 20.5970563,21 20.1,21 L3.9,21 Z M8.399,7.95 L4.349,7.95 L4.35,19.65 L8.399,19.65 L8.399,7.95 Z M13.8,7.95 L9.75,7.95 L9.75,19.65 L13.8,19.65 L13.8,7.95 Z M19.65,7.95 L15.15,7.95 L15.15,19.65 L19.65,19.65 L19.65,7.95 Z M19.65,4.35 L4.35,4.35 L4.349,6.45 L19.65,6.45 L19.65,4.35 Z"
                    fill="#000000"
                    fillRule="nonzero"></path>
            </g>
        </SvgIcon>
    );
};

export default TreeTableIcon;
