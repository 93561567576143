import React from 'react';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOffOutlined';
import { t } from '@baffle/translate';

export interface SidePanelErrorItem {
    name?: string;
    err_time?: string;
    err_msg: string;
    id: string;
}
interface props {
    errors: SidePanelErrorItem[];
    didClearNotification?: (err: SidePanelErrorItem) => void;
    'data-testid'?: string;
}

const SidePanelErrors = ({ errors, didClearNotification = () => null }: props) => {
    return (
        <>
            {errors.map((err: SidePanelErrorItem) => {
                return (
                    <div key={err.id} className="mt-4 flex justify-between">
                        <div>
                            {err?.err_time ? (
                                <p className="text-sm truncate w-72">
                                    {t('notifications.created')}: {err.err_time}
                                </p>
                            ) : null}
                            {err?.name ? (
                                <p className="truncate w-72 text-xs">
                                    <span className="font-bold">{t('notifications.errName')}</span>: {err?.name}
                                </p>
                            ) : null}
                            <p className="text-red text-xs leading-tight break-all">{err.err_msg}</p>
                        </div>
                        <div className="w-48 font-light flex justify-end items-center h-full">
                            <button
                                onClick={() => didClearNotification(err)}
                                aria-label={t('notifications.dismissNotification')}
                                className="bx--btn bx--btn--icon-only-transparent text-gray-400">
                                <NotificationsOffIcon />
                            </button>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default SidePanelErrors;
