import React, { useState, useEffect } from 'react';
import { ColumnMetaData, SelectedColumn, CryptoActionType, KeyDetails } from '@baffle/graphql/src/models';
import usePageHeight from '@baffle/utilities/src/usePageHeight';
import { Observer } from 'mobx-react-lite';
import { RequestStatus } from '@baffle/api-client/src/useAwait';
import Table, { TBody } from '@baffle/components/src/table/Table';
import { SortTable, useSortTable } from '@baffle/components/src/table/THead';
import { stringSort, numberSort } from '@baffle/utilities/src/sorters';
import ColumnTableRow from './ColumnTableRow';
import { toJS } from 'mobx';
import ColumnTHead from './ColumnTHead';
import { Lookup } from '../../../../types/lookup';
import FixTableAutoResizer from '@baffle/components/src/table/FixTableAutoResizer';
import useShiftClickColumns from '@baffle/utilities/src/useShiftClickColumns';

const createSelectedColumnLookup = (selectedColumns: SelectedColumn[]) => {
    const lookup: Lookup = {};
    selectedColumns.forEach((sc: SelectedColumn) => {
        lookup[sc.path] = true;
    });
    return lookup;
};

interface ColumnsTableProps {
    addKey?: () => void;
    table: string;
    database?: string;
    keys?: KeyDetails[];
    columns: ColumnMetaData[];
    sendSelectedMultiple: (d: ColumnMetaData[]) => void;
    sendDeselectedMultiple: (d: ColumnMetaData[]) => void;
    sendSelected: (d: ColumnMetaData, replace?: boolean) => void;
    sendDeselected: (d: ColumnMetaData) => void;
    selectedColumns: SelectedColumn[];
    requestStatus: RequestStatus;
    cryptoAction: CryptoActionType;
    isRecordLevel?: boolean;
}

const ColumnsTable = ({
    addKey = () => null,
    keys = [],
    table,
    columns: observableColumns,
    requestStatus,
    sendDeselected,
    sendSelected,
    sendSelectedMultiple,
    sendDeselectedMultiple,
    selectedColumns,
    cryptoAction = 'encryption',
    isRecordLevel,
}: ColumnsTableProps) => {
    return (
        <Observer>
            {() => {
                const columns = toJS(observableColumns);
                const [sortTable, setSortTable] = useSortTable();
                const pageHeight = usePageHeight();
                const [selectedColumnsLookup, setLookup] = useState(createSelectedColumnLookup(selectedColumns));
                const { lastSelected, setLastSelected, shiftPress } = useShiftClickColumns();
                useEffect(() => {
                    setLookup(createSelectedColumnLookup(selectedColumns));
                }, [selectedColumns]);

                const cols: ColumnMetaData[] = columns
                    ? //only show columns that have not been encrypted
                      columns.filter((d: ColumnMetaData) => {
                          switch (cryptoAction) {
                              case 'decryption':
                                  return d.touched;
                              case 'encryption':
                              default:
                                  return true;
                          }
                      }) || []
                    : [];

                useEffect(() => {
                    setLastSelected(0);
                }, [table]);

                const tableContentMinWidth = 808;

                sortColumns(cols, sortTable);

                //add empty cols to fill the page with rows
                const sizeDelta = 30;
                cols.push(...[...Array(sizeDelta).keys()].map(() => (({} as unknown) as ColumnMetaData)));

                //handle checkbox selection
                const handleSingleCheckbox = (data: ColumnMetaData, index: number, checked: boolean) => {
                    if (checked) {
                        sendSelected(data);
                    } else {
                        sendDeselected(data);
                    }
                    setLastSelected(index);
                };
                const handleMultiCheckbox = (data: ColumnMetaData[], index: number, checked: boolean) => {
                    const shiftCols = data.slice(Math.min(index, lastSelected), Math.max(index, lastSelected) + 1);
                    //only shift select cols that havent been encrypted
                    const newData =
                        cryptoAction == 'decryption'
                            ? shiftCols
                            : shiftCols.filter((col: any) => {
                                  return col.touched === false;
                              });
                    if (checked) {
                        sendSelectedMultiple(newData);
                    } else {
                        sendDeselectedMultiple(newData);
                    }
                    setLastSelected(index);
                };
                return (
                    <div className="column-table-container relative">
                        {/* controlled input so that we can force clear the value */}
                        <FixTableAutoResizer
                            getProps={() => {
                                return { height: pageHeight };
                            }}>
                            <Table
                                getTableProps={() => {
                                    return {
                                        className: 'overflow-y-hidden shadow min-w-120 w-full',
                                        style: {
                                            height: pageHeight,
                                        },
                                    };
                                }}>
                                <ColumnTHead
                                    addKey={addKey}
                                    onSort={({ key, direction }) => {
                                        //do not sort if cols are empty cols only
                                        if (cols.some(c => Object.keys(c).length > 0)) {
                                            setSortTable({ key, direction });
                                            setLastSelected(0);
                                        }
                                    }}
                                    sortKey={sortTable.key}
                                    direction={sortTable.direction}
                                    sendDeselectedMultiple={sendDeselectedMultiple}
                                    sendSelectedMultiple={sendSelectedMultiple}
                                    cryptoAction={cryptoAction}
                                    selectedColumnsLookup={selectedColumnsLookup}
                                    columns={cols.filter(c => Object.keys(c).length !== 0)}
                                    table={table}
                                    style={{ minWidth: tableContentMinWidth }}
                                />

                                <TBody
                                    getListProps={() => {
                                        return {
                                            minWidth: tableContentMinWidth, //set the col table min width so that scroll x doesnt happen
                                        };
                                    }}
                                    data={{
                                        itemData: cols,
                                        getItemProps: (i, itemData) => {
                                            const inFirstThreeIndexs = { 0: 1, 1: 1, 2: 1 };
                                            const col = itemData[i];
                                            const determineRenderWhich = () => {
                                                if (
                                                    requestStatus === 'loading' &&
                                                    //@ts-ignore
                                                    Boolean(inFirstThreeIndexs[i])
                                                ) {
                                                    return 'SKELETON';
                                                }
                                                if (Object.keys(col).length === 0) {
                                                    return 'EMPTY';
                                                }
                                                return 'NORMAL';
                                            };
                                            return {
                                                requestStatus,
                                                keys,
                                                cryptoAction,
                                                sendSelected,
                                                handleSingleCheckbox,
                                                handleMultiCheckbox,
                                                selectedColumnsLookup,
                                                selectedColumns,
                                                renderWhich: determineRenderWhich(),
                                                isRecordLevel,
                                                shiftPress,
                                            };
                                        },
                                    }}>
                                    {ColumnTableRow}
                                </TBody>
                            </Table>
                        </FixTableAutoResizer>
                    </div>
                );
            }}
        </Observer>
    );
};

export default ColumnsTable;

function sortColumns(cols: ColumnMetaData[], { key, direction }: SortTable) {
    switch (key) {
        case 'colName':
            switch (direction) {
                case 'ASC':
                    cols.sort((a: ColumnMetaData, b: ColumnMetaData) => stringSort(a.name, b.name));
                    break;
                case 'DESC':
                    cols.sort((a: ColumnMetaData, b: ColumnMetaData) => stringSort(b.name, a.name));
            }
            break;
        case 'dataType':
            switch (direction) {
                case 'ASC':
                    cols.sort((a: ColumnMetaData, b: ColumnMetaData) => stringSort(a.dataType, b.dataType));
                    break;
                case 'DESC':
                    cols.sort((a: ColumnMetaData, b: ColumnMetaData) => stringSort(b.dataType, a.dataType));
            }
        case 'encMode':
            switch (direction) {
                case 'ASC':
                    cols.sort((a: ColumnMetaData, b: ColumnMetaData) =>
                        stringSort(a.selections.encryptionMode?.name || '', b.selections.encryptionMode?.name || '')
                    );
                    break;
                case 'DESC':
                    cols.sort((a: ColumnMetaData, b: ColumnMetaData) =>
                        stringSort(b.selections.encryptionMode?.name || '', a.selections.encryptionMode?.name || '')
                    );
            }
        case 'maskMode':
            switch (direction) {
                case 'ASC':
                    cols.sort((a: ColumnMetaData, b: ColumnMetaData) =>
                        stringSort(a.selections.maskingMode?.name || '', b.selections.maskingMode?.name || '')
                    );
                    break;
                case 'DESC':
                    cols.sort((a: ColumnMetaData, b: ColumnMetaData) =>
                        stringSort(b.selections.maskingMode?.name || '', a.selections.maskingMode?.name || '')
                    );
            }
        case 'keyID':
            switch (direction) {
                case 'ASC':
                    cols.sort((a: ColumnMetaData, b: ColumnMetaData) => numberSort(a.keyID, b.keyID));
                    break;
                case 'DESC':
                    cols.sort((a: ColumnMetaData, b: ColumnMetaData) => numberSort(b.keyID, a.keyID));
            }
    }
}
