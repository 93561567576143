import React, { useState, useLayoutEffect, useEffect } from 'react';
import {
    useSelect,
    UseSelectGetLabelPropsOptions,
    UseSelectGetMenuPropsOptions,
    UseSelectGetToggleButtonPropsOptions,
    UseSelectState,
} from 'downshift';
import { motion } from 'framer-motion';
import cx from 'classnames';
import TreeDownArrowIcon from '@material-ui/icons/ExpandMore';
import { BaseCompoundComponentProps } from '../types/react';
import { DropdownItem } from './dropdownTypes';
import getBounds, { Bounds } from '../../../utilities/getBounds';

type Position = 'top_left_align' | 'top_right_align' | 'bottom_left_align' | 'bottom_right_align';
type Theme = 'primary' | 'secondary';

interface Props {
    position?: Position;
    menuPadding?: number;
    items: DropdownItem[];
    defaultSelectedItem?: DropdownItem;
    theme?: Theme;
    value?: DropdownItem;
    label: string;
    id?: string;
    containerClasses?: string;
    placeholder: string;
    invalid?: Boolean;
    invalidText?: string;
    getLIProps?: ({ index }: { index: number }) => any;
    getLabelProps?: (options?: UseSelectGetLabelPropsOptions | undefined) => any;
    getToggleButtonProps?: (options?: UseSelectGetToggleButtonPropsOptions | undefined) => any;
    getMenuProps?: (options?: UseSelectGetMenuPropsOptions | undefined) => any;
    handleSelectedItemChange: (selectState: Partial<UseSelectState<DropdownItem>>) => void;
    overrideToggleBtnClass?: string;
    iconSize?: 'small' | 'large' | 'default' | 'inherit';
}

export const getDefaultMenuProps = () => {
    return { className: 'absolute overflow-y-auto z-100 focus:outline-none focus:border-blue-60 shadow-lg w-full' };
};

export const getDefaultLabelProps = () => {
    return {
        className: 'text-xxs mb-2 text-gray-70',
    };
};

export const getDefaultLIProps = () => {
    return {
        className:
            'flex text-xs p-2 cursor-pointer border-gray-300 bg-gray-10 hover:bg-gray-10-hover hover:text-gray-100 focus:border-blue-60 focus:outline-none items-center',
    };
};

export const getDefaultToggleButtonProps = () => {
    return {
        className:
            'w-full leading-normal flex text-xs px-4 py-2 items-center border-b border-gray-50 text-gray-100 focus:outline-none focus:border-blue-60 hover:bg-gray-10-hover justify-between truncate',
    };
};

export const getDefaultContainerClasses = () => 'flex flex-col';
interface CalcPositionsArg {
    menuHeight: number;
    position: Position;
    actuatorBounds: Bounds;
    menuBounds: Bounds;
    padding?: number;
}

interface PositionStyles {
    top: number;
    left: number;
}
const calculatePositions = ({ position, actuatorBounds, menuBounds, padding = 0, menuHeight }: CalcPositionsArg) => {
    let top = 0,
        left = 0;
    switch (position) {
        case 'top_left_align':
            //             +----------------------+
            //             |                      |
            //             |      menu items      |
            //             |                      |
            //             +----------------------
            //             +-------------+
            //             |             |
            //             |   actuator  |
            //             |             |
            //             +-------------+
            top = -Math.abs(actuatorBounds.height + menuHeight + padding);
            break;
        case 'top_right_align':
            //    +----------------------+
            //    |                      |
            //    |      menu items      |
            //    |                      |
            //    +----------------------
            //             +-------------+
            //             |             |
            //             |   actuator  |
            //             |             |
            //             +-------------+
            top = -Math.abs(actuatorBounds.height + menuHeight + padding);
            left =
                actuatorBounds.width < menuBounds.width
                    ? -Math.abs(Math.abs(actuatorBounds.width - menuBounds.width) + padding)
                    : Math.abs(Math.abs(actuatorBounds.width - menuBounds.width) + padding);
            break;

        case 'bottom_right_align':
            //             +-------------+
            //             |             |
            //             |   actuator  |
            //             |             |
            //             +-------------+
            //    +----------------------+
            //    |                      |
            //    |      menu items      |
            //    |                      |
            //    +----------------------
            top = padding;
            left =
                actuatorBounds.width < menuBounds.width
                    ? -Math.abs(Math.abs(actuatorBounds.width - menuBounds.width) + padding)
                    : Math.abs(Math.abs(actuatorBounds.width - menuBounds.width) + padding);
            break;
        case 'bottom_left_align':
        default:
            //             +-------------+
            //             |             |
            //             |   actuator  |
            //             |             |
            //             +-------------+
            //             +----------------------+
            //             |                      |
            //             |      menu items      |
            //             |                      |
            //             +----------------------
            top = padding;
            break;
    }
    return {
        top,
        left,
    };
};

export default function DropdownSelect(props: Props) {
    const {
        position = 'bottom_left_align',
        containerClasses = 'flex flex-col',
        theme = 'primary',
        items,
        placeholder,
        menuPadding = 0,
        label,
        defaultSelectedItem,
        value,
        invalid = false,
        invalidText = '',
        handleSelectedItemChange,
        getLIProps = getDefaultLIProps,
        getLabelProps = getDefaultLabelProps,
        getToggleButtonProps = getDefaultToggleButtonProps,
        getMenuProps = getDefaultMenuProps,
        id,
        overrideToggleBtnClass,
        iconSize = 'small',
    } = props;

    const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);

    useEffect(() => {
        if (value?.id) {
            setSelectedItem(value);
        }
    }, [value]);

    const onSelectedItemChange = (state: Partial<UseSelectState<DropdownItem>>) => {
        const { selectedItem } = state;
        if (Boolean(selectedItem?.disabled)) {
            return;
        }
        setSelectedItem(selectedItem);
        handleSelectedItemChange(state);
    };

    const {
        isOpen,
        getToggleButtonProps: getToggleButtonPropsFromUseSelect,
        getMenuProps: getMenuPropsFromUseSelect,
        highlightedIndex,
        getItemProps,
    } = useSelect({
        items,
        onSelectedItemChange,
        defaultSelectedItem,
    });
    const itemHeight = 38;
    const menuHeight = items.length < 4 ? items.length * itemHeight : itemHeight * 4;

    const { className: labelClasses, ...labelProps } = getLabelProps();
    const { className: btnClasses, ...btnProps } = getToggleButtonProps();
    const { className: ulClasses, ...ulProps } = getMenuProps();
    const { ref: actuatorRefFromUseSelect } = getToggleButtonPropsFromUseSelect();
    const { ref: menuRefFromUseSelect } = getMenuPropsFromUseSelect();

    const [actuatorRef, setActuatorRef] = useState<HTMLElement | null>(null);
    const [menuRef, setMenuRef] = useState<HTMLElement | null>(null);

    const [positionStyles, setPositionStyles] = useState<PositionStyles>({ top: 0, left: 0 });
    useLayoutEffect(() => {
        if (isOpen) {
            const actuatorBounds = getBounds(actuatorRef);
            const menuBounds = getBounds(menuRef);
            setPositionStyles(
                calculatePositions({
                    position,
                    actuatorBounds,
                    padding: menuPadding,
                    menuHeight,
                    menuBounds,
                })
            );
        }
    }, [items, isOpen]);
    return (
        <Select id={id ?? ''} className={containerClasses}>
            <label className={cx(labelClasses)} {...labelProps}>
                {label}
            </label>
            <button
                {...getToggleButtonPropsFromUseSelect()}
                ref={el => {
                    setActuatorRef(el);
                    actuatorRefFromUseSelect(el);
                }}
                {...btnProps}
                className={cx(
                    overrideToggleBtnClass ?? btnClasses,
                    {
                        'text-gray-100-disabled bg-gray-200 cursor-not-allowed opacity-100': Boolean(btnProps.disabled),
                        'border-gray-300 text-gray-600': !invalid,
                        'border-red text-red placeholder-red': invalid,
                    },
                    {
                        'bg-gray-10': theme === 'primary',
                        'bg-white': theme === 'secondary',
                    }
                )}>
                <span className="truncate">{selectedItem?.label ?? placeholder}</span>
                <TreeDownArrowIcon
                    className={cx('transform transition ease-in-out duration-250', { 'rotate-180': isOpen })}
                    fontSize={iconSize}
                />
            </button>
            <div className="relative">
                <ul
                    style={{ height: menuHeight, ...positionStyles }}
                    {...getMenuPropsFromUseSelect()}
                    {...ulProps}
                    ref={el => {
                        setMenuRef(el);
                        menuRefFromUseSelect(el);
                    }}
                    className={cx(ulClasses, {
                        hidden: !isOpen,
                    })}>
                    {isOpen &&
                        items.map((item, index) => {
                            const { className: liClasses, ...liProps } = getLIProps({ index });
                            return (
                                <motion.li
                                    animate={{ height: itemHeight, opacity: 1 }}
                                    transition={{ duration: 0.3 }}
                                    style={{ height: 0, opacity: 0 }}
                                    disabled={Boolean(item.disabled)}
                                    data-testid={item['data-testid'] ?? ''}
                                    className={cx(liClasses, {
                                        'bg-gray-200 border-blue': highlightedIndex === index,
                                        'cursor-not-allowed text-gray-400': Boolean(item.disabled),
                                        'text-gray-70': !Boolean(item.disabled),
                                    })}
                                    key={`${item.id}${index}`}
                                    {...getItemProps({ item, index })}
                                    {...liProps}>
                                    {item.label}
                                </motion.li>
                            );
                        })}
                </ul>
            </div>
            {invalid ? <p className="text-xs text-red">{invalidText}</p> : null}
        </Select>
    );
}

interface SelectProps extends BaseCompoundComponentProps {
    className?: string;
}
const Select = ({ className = 'flex flex-col', children, ...rest }: SelectProps) => {
    return (
        <div {...rest} className={className}>
            {children}
        </div>
    );
};
