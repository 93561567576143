import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { BaffleIconProps } from '../componentTypes';

const TreeFolderIcon = ({
    color = '#000000',
    width = 54,
    height = 54,
    viewBox = '0 0 54 54',
    fontSize = 'small',
}: BaffleIconProps) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} fontSize={fontSize}>
            <g>
                <path
                    d="M53,10.5H23.535l-3.703-5.555C19.646,4.667,19.334,4.5,19,4.5H1c-0.552,0-1,0.447-1,1v6v4v29.003
		C0,47.259,2.24,49.5,4.994,49.5h44.012C51.76,49.5,54,47.259,54,44.503V15.5v-4C54,10.947,53.552,10.5,53,10.5z M52,14.5H2v-2h21
		h29V14.5z M2,6.5h16.465l2.667,4H2V6.5z M52,44.503c0,1.652-1.343,2.997-2.994,2.997H4.994C3.343,47.5,2,46.155,2,44.503V16.5h50
		V44.503z"
                />
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
        </SvgIcon>
    );
};

export default TreeFolderIcon;
