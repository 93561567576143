import React, { useEffect, useState } from 'react';
import Header from '@baffle/components/src/nav/Header';
import Button, { getDefaultButtonProps } from '@baffle/components/src/buttons/Button';
import Content from '@baffle/components/src/content/Content';
import CloseIcon from '@material-ui/icons/Close';
import SkeletonText, {
    getDefaultSkeletonParagraphProps,
    getDefaultSkeletonTextProps,
    SkeletonParagraph,
} from '@baffle/components/src/skeleton/SkeletonText';
import { useHistory, useParams } from 'react-router-dom';
import { ApplicationV2, CancelMigrationStatus, MigrationPlan, MigrationStatus } from '@baffle/graphql/src/models';
import { t } from '@baffle/translate';
import Divider from '@baffle/components/src/content/Divider';
import ShieldsDataGridCondensed from './ShieldsDataGridCondensed';
import MigrationDatabaseTable from './MigrationDatabaseTable';
import MigrationColumnsTable from './MigrationColumnsTable';
import './MigrationStatus.scss';
import { format } from '@baffle/utilities/src/date';
import { ApplicationStore, MigrationStore, MigrationPlanStore } from '../../stores';
import { ToastStore } from '../../stores/ToastStore';
import migrationClient from '@baffle/api-client/src/migrationClient';
import useAwait from '@baffle/api-client/src/useAwait';
import { HTTP_POLL_INTERVAL } from '@baffle/utilities/src/enums/TimeEnum';
import { InlineLoading } from 'carbon-components-react';
import { autorun } from 'mobx';
import { Observer } from 'mobx-react-lite';
import logger from '@baffle/utilities/src/logger';

interface MigrationStatusProps {}

const MigrationStatusDashboard = (props: MigrationStatusProps) => {
    const { id }: { id: string | undefined } = useParams();

    if (!id) {
        return null;
    }
    const history = useHistory();

    const { run, status, error } = useAwait();
    const { run: runReadCancelMigration, status: readCancelMigrationStatus } = useAwait();
    const [selectedTable, setTable] = useState(null);

    const fetch = ({ skipStatus } = { skipStatus: false }) => {
        run(
            Promise.all([
                ApplicationStore.readV2(id),
                MigrationStore.readCancelMigration(id),
                MigrationStore.read(id),
                MigrationPlanStore.read(id),
            ]),
            { skipStatus }
        );
    };
    useEffect(() => {
        if (error) {
            logger.error(error);
        }
    }, [error]);

    useEffect(() => {
        fetch();
    }, []);

    return (
        <Observer>
            {() => {
                const application = ApplicationStore.getV2(id) as ApplicationV2;
                const migration = MigrationStore.get(id) as MigrationStatus;
                const plan = MigrationPlanStore.get(id) as MigrationPlan;
                const { className: defaultCancellationBtnClassName } = getDefaultButtonProps();

                const [cancellationBtnProps, setCancellationBtnProps] = useState({
                    ...getDefaultButtonProps(),
                    className: `${defaultCancellationBtnClassName} border-gray-300`,
                    disabled: true,
                    'data-testid': 'test-cancel-migration-btn',
                    id: 'id-cancel-migration-btn',
                });

                useEffect(() => {
                    const poller = {
                        pollFn: (0 as unknown) as NodeJS.Timeout,
                        setPoller(fn: () => NodeJS.Timeout) {
                            this.clear();
                            this.pollFn = fn();
                        },
                        clear() {
                            clearInterval(this.pollFn);
                        },
                    };

                    const createPoller = () =>
                        setInterval(() => {
                            runReadCancelMigration(MigrationStore.readCancelMigration(id), { skipStatus: true });
                        }, HTTP_POLL_INTERVAL);

                    const dispose = autorun(() => {
                        poller.clear();
                        switch (MigrationStore.cancelMigrationStatus) {
                            case CancelMigrationStatus.MIGRATION_IN_PROGRESS:
                                poller.setPoller(createPoller);
                                setCancellationBtnProps(
                                    Object.assign({}, cancellationBtnProps, {
                                        disabled: false,
                                        className: `${defaultCancellationBtnClassName} hover:border-red hover:text-white hover:bg-red`,
                                    })
                                );
                                break;
                            case CancelMigrationStatus.CANCELLATION_IN_PROGRESS:
                                poller.setPoller(createPoller);
                                setCancellationBtnProps(
                                    Object.assign({}, cancellationBtnProps, {
                                        disabled: true,
                                        className: `disabled flex items-center justify-center px-4 items-center border-solid border text-red-500 hover:border-red hover:text-white hover:bg-red`,
                                    })
                                );
                                break;
                            case CancelMigrationStatus.CANCELLATION_COMPLETED:
                                if (readCancelMigrationStatus === 'success') {
                                    ToastStore.push({
                                        type: 'success',
                                        title: t('application.cancelMigrationSuccess'),
                                        duration: 900000,
                                    });
                                }
                                setCancellationBtnProps(
                                    Object.assign({}, cancellationBtnProps, {
                                        disabled: true,
                                        className: `${defaultCancellationBtnClassName} border-gray-300`,
                                    })
                                );
                                break;
                            case CancelMigrationStatus.DISABLED:
                            default:
                                setCancellationBtnProps(
                                    Object.assign({}, cancellationBtnProps, {
                                        disabled: true,
                                        className: `${defaultCancellationBtnClassName} border-gray-300`,
                                    })
                                );
                                break;
                        }
                    });
                    return () => {
                        //cleans up mobx autorun
                        dispose();
                        poller.clear();
                    };
                }, [readCancelMigrationStatus]);

                const cancelMigration = async () => {
                    try {
                        MigrationStore.setCancelMigration(CancelMigrationStatus.CANCELLATION_IN_PROGRESS);
                        await migrationClient.createCancelMigration(id);
                    } catch (error) {
                        //TODO: a better state here would be CancelMigrationStatus.CANCELLATION_ERROR
                        MigrationStore.setCancelMigration(CancelMigrationStatus.DISABLED);
                        ToastStore.push({
                            type: 'danger',
                            title: t('application.cancelMigrationError'),
                            duration: 900000,
                        });
                    }
                };

                return (
                    <>
                        <Content className="h-screen">
                            <Header>
                                {status === 'idle' || status === 'loading' ? (
                                    <SkeletonText />
                                ) : (
                                    <>
                                        <div className="flex-1 px-4 flex justify-between">
                                            <h1 className="text-2xl">{application.name}</h1>
                                        </div>
                                        <div className="ml-6 flex items-center h-full">
                                            <Button
                                                theme="icon-transparent"
                                                onClick={() => {
                                                    history.replace('/applications');
                                                }}
                                                aria-label={t('encryption.migration.ariaCloseMigration')}>
                                                <CloseIcon />
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </Header>
                            <div className="flex p-4">
                                <div className="migration-details flex-col p-4 mr-6 w-96 bg-white">
                                    {/* details */}
                                    <p className="font-bold pb-1 text-sm">{t('main.details')}</p>
                                    <div className="flex">
                                        <div className="flex flex-col items-start mr-2">
                                            <p className="text-gray-500 text-sm">{t('main.addedOn')}:</p>
                                            <p className="text-gray-500 text-sm">{t('main.createdBy')}:</p>
                                            <p className="text-gray-500 text-sm">{t('main.updatedOn')}:</p>
                                        </div>
                                        <div className="flex flex-col">
                                            {status === 'idle' || status === 'loading' ? (
                                                <SkeletonParagraph
                                                    getSkeletonParagraphProps={() => ({
                                                        ...getDefaultSkeletonParagraphProps(),
                                                        width: 'w-full',
                                                    })}
                                                    getSkeletonProps={() => ({
                                                        ...getDefaultSkeletonTextProps(),
                                                        width: 'w-full',
                                                    })}
                                                />
                                            ) : (
                                                <>
                                                    <p className="text-sm">
                                                        {format(application.createTime, 'yyyy-M-d H:mm:ss')}
                                                    </p>
                                                    <p className="text-sm">{application.createdBy}</p>
                                                    <p className="text-sm">
                                                        {format(migration.updateTime, 'yyyy-M-d H:mm:ss')}
                                                    </p>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <Divider />

                                    {/* description */}
                                    <p className="font-bold pb-1 text-sm">{t('main.description')}</p>
                                    {status === 'idle' || status === 'loading' ? (
                                        <SkeletonText />
                                    ) : (
                                        <p className="pb-1 text-sm">{application.desc}</p>
                                    )}
                                    <Divider />

                                    {/* encryption details */}
                                    <p className="font-bold pb-1 text-sm">{t('application.encryptionDetails')}</p>
                                    <div className="flex">
                                        <div className="flex flex-col items-start mr-2">
                                            <p className="text-gray-500 text-sm">{t('application.header4')}:</p>
                                            {/* <p className="text-gray-500">{t('application.header5')}:</p> */}
                                            <p className="text-gray-500 text-sm">{t('encryption.migration.dbName')}:</p>
                                            <p className="text-gray-500 text-sm">{t('keystore.keystore')}:</p>
                                        </div>
                                        <div className="flex flex-col">
                                            {status === 'idle' || status === 'loading' ? (
                                                <SkeletonParagraph
                                                    getSkeletonParagraphProps={() => ({
                                                        ...getDefaultSkeletonParagraphProps(),
                                                        width: 'w-full',
                                                    })}
                                                    getSkeletonProps={() => ({
                                                        ...getDefaultSkeletonTextProps(),
                                                        width: 'w-full',
                                                    })}
                                                />
                                            ) : (
                                                <>
                                                    <p className="text-sm">{application.encType}</p>
                                                    {/* need https://baffle.atlassian.net/browse/BUX-114 to land */}
                                                    {/* <p>{t(`application.${(application.mode || '').toLowerCase()}`)}</p> */}
                                                    <p className="text-sm">{application.databaseDetails?.name}</p>
                                                    <p className="text-sm">{application.keystoreDetails?.name}</p>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <Divider />

                                    {/* migration details */}
                                    <p className="font-bold pb-1 text-sm">{t('encryption.migrationDetails')}</p>
                                    <div className="flex">
                                        <div className="flex flex-col items-start mr-2">
                                            <p className="text-gray-500 text-sm">
                                                {t('encryption.migration.migrationTypeInputLabel')}:
                                            </p>
                                            <p className="text-gray-500 text-sm">
                                                {t('encryption.migration.batchEnabledInputLabel')}:
                                            </p>
                                            <p className="text-gray-500 text-sm">
                                                {t('encryption.migration.failScopeInputLabel')}:
                                            </p>
                                        </div>
                                        <div className="flex flex-col">
                                            {status === 'idle' || status === 'loading' ? (
                                                <SkeletonParagraph
                                                    getSkeletonParagraphProps={() => ({
                                                        ...getDefaultSkeletonParagraphProps(),
                                                        width: 'w-full',
                                                    })}
                                                    getSkeletonProps={() => ({
                                                        ...getDefaultSkeletonTextProps(),
                                                        width: 'w-full',
                                                    })}
                                                />
                                            ) : (
                                                <>
                                                    <p className="text-sm">{plan.migrationType}</p>
                                                    <p className="text-sm">{plan.batchSize}</p>
                                                    <p className="text-sm">{plan.failScope}</p>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        <p className="py-2 text-sm text-gray-500">
                                            {t('application.cancelMigrationCopy')}
                                        </p>
                                        <Button
                                            theme="tertiary-danger"
                                            disabled={cancellationBtnProps.disabled}
                                            getButtonProps={() => cancellationBtnProps}
                                            onClick={() => cancelMigration()}>
                                            {MigrationStore.cancelMigrationStatus ===
                                            CancelMigrationStatus.CANCELLATION_IN_PROGRESS ? (
                                                <>
                                                    {t('application.canceling')}{' '}
                                                    <InlineLoading className="text-red"></InlineLoading>
                                                </>
                                            ) : (
                                                t('application.cancelMigration')
                                            )}
                                        </Button>
                                    </div>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: t('application.cancelMigrationNote') as string,
                                        }}
                                        className="text-xs pt-1"
                                    />
                                    <Divider />

                                    <p className="font-bold pb-1 text-sm">{t('application.baffleshields')}</p>
                                    <div className="shields-condensed-datagrid-container pr-6">
                                        {status === 'idle' || status === 'loading' ? (
                                            <SkeletonParagraph
                                                getSkeletonParagraphProps={() => ({
                                                    ...getDefaultSkeletonParagraphProps(),
                                                    width: 'w-full',
                                                })}
                                                getSkeletonProps={() => ({
                                                    ...getDefaultSkeletonTextProps(),
                                                    width: 'w-full',
                                                })}
                                            />
                                        ) : (
                                            <ShieldsDataGridCondensed application={application} status={status} />
                                        )}
                                    </div>
                                </div>
                                <div className="mgstatus-databasetable-container flex-grow mr-6">
                                    {status === 'idle' || status === 'loading' ? (
                                        <SkeletonParagraph
                                            getSkeletonParagraphProps={() => ({
                                                ...getDefaultSkeletonParagraphProps(),
                                                width: 'w-full',
                                            })}
                                            getSkeletonProps={() => ({
                                                ...getDefaultSkeletonTextProps(),
                                                width: 'w-full',
                                            })}
                                        />
                                    ) : (
                                        <MigrationDatabaseTable
                                            migrations={migration}
                                            selectedTable={selectedTable}
                                            status={status}
                                            onRowClick={(row: any) => {
                                                if (row) {
                                                    setTable(row.table);
                                                }
                                            }}
                                        />
                                    )}
                                </div>
                                <div className="migrationstatus-columns-container flex-grow">
                                    {status === 'idle' || status === 'loading' ? (
                                        <SkeletonParagraph
                                            getSkeletonParagraphProps={() => ({
                                                ...getDefaultSkeletonParagraphProps(),
                                                width: 'w-full',
                                            })}
                                            getSkeletonProps={() => ({
                                                ...getDefaultSkeletonTextProps(),
                                                width: 'w-full',
                                            })}
                                        />
                                    ) : (
                                        <MigrationColumnsTable selectedTable={selectedTable} status={status} />
                                    )}
                                </div>
                            </div>
                            <div className="flex w-full justify-end items-center bg-white border-t-2 border-solid border-gray-300 h-20">
                                <Button
                                    theme="secondary"
                                    className="w-48 h-full text-center"
                                    size="lg"
                                    onClick={() => {
                                        history.replace('/applications');
                                    }}>
                                    {t('main.close')}
                                </Button>
                            </div>
                        </Content>
                    </>
                );
            }}
        </Observer>
    );
};

export default MigrationStatusDashboard;
