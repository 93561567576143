import React, { InputHTMLAttributes } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import TextInput from '@baffle/components/src/forms/TextInput';
import { t } from '@baffle/translate';

export const getDefaultSearchContainerProps: () => any = () => {
    return {
        className: 'bg-white w-full relative flex h-8 border border-solid border-gray-300 p-1',
    };
};

export const getDefaultIconProps: () => any = () => {
    return {
        className: 'text-gray-700 absolute text-base m-auto inset-y-0 ml-3',
    };
};

interface props extends Partial<InputHTMLAttributes<HTMLInputElement>> {
    name: string;
    placeholder?: string;
    getSearchContainerProps?: () => any;
    getIconProps?: () => any;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Search = ({
    name,
    placeholder = '',
    getSearchContainerProps = getDefaultSearchContainerProps,
    getIconProps = getDefaultIconProps,
    onChange,
    ...rest
}: props) => {
    const { id = name, value } = rest;
    return (
        <div {...getSearchContainerProps()}>
            <SearchIcon {...getIconProps()} />
            <TextInput
                name={name}
                id={id}
                labelHidden
                placeholder={t('main.search')}
                labelText={t('main.chooseOption')}
                getInputContainerProps={() => {
                    return {
                        className: 'w-full flex ml-2',
                    };
                }}
                getInputProps={() => {
                    return {
                        className:
                            'border border-solid rounded-full border-gray-300 focus:ring-blue focus:border-blue-60 focus:text-gray-700 block w-full text-xs px-6 focus:rounded-none flex-grow focus: outline-none ',
                    };
                }}
                theme="secondary"
                onChange={onChange}
                value={value}
            />
        </div>
    );
};

export default Search;
