import React, { useState, useEffect, useMemo } from 'react';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import Header from '@baffle/components/src/nav/Header';
import cx from 'classnames';
import { t } from '@baffle/translate';
import { Tag } from 'carbon-components-react';
import Content from '@baffle/components/src/content/Content';
import { Keystore, KeystoreListItem } from '@baffle/graphql/src/models';
import '../keystore/Keystores.scss';
import { triggerResize } from '@baffle/utilities/src/resize';
import AddKeyStoreModal from '../keystore/AddKeystoreModal';
import UpdateKeystoreCertModal from '../keystore/UpdateKeystoreCertModal';
import { useHistory } from 'react-router-dom';
import { ToastStore } from '../stores/ToastStore';
import DataGrid from '@baffle/components/src/grid/data-grid';
import { KeystoreStore } from '../stores';
import useAwait from '@baffle/api-client/src/useAwait';
import { Observer } from 'mobx-react-lite';
import keystoreClient from '@baffle/api-client/src/keystoreClient';
import { Modal, TextInput } from 'carbon-components-react';
import createKeystoreInputScheme from '../keystore/keystoreInputSchemes/createKeystoreInputScheme';
import { KeystoreTypeEnum } from '@baffle/graphql/src/models';

const KeyStoresV2 = () => {
    const history = useHistory();
    const { run, status, error } = useAwait();
    const [update, setUpdate] = useState(true);

    const initialModalState = {
        showDeleteModal: false,
        showRenameModal: false,
        addKeystore: false,
        updateKeyStore: false,
        updateCertificate: false,
        ks: {},
    };

    const keystoreInputSchemes = useMemo(() => createKeystoreInputScheme(), []);

    const headers = [
        {
            key: 'header1',
            header: t('main.name'),
        },
        {
            key: 'header2',
            header: t('keystorev2.type'),
        },
        {
            key: 'header3',
            header: t('keystorev2.cmks'),
        },
        {
            key: 'header4',
            header: t('keystorev2.dekStore'),
        },
        {
            key: 'alerts',
            header: t('main.alerts'),
        },
    ];

    const [modalsToggled, setModalsToggled] = useState(initialModalState);

    const [selectedKs, setSelectedKs]: any = useState(null);

    const emptyCol = {
        id: '',
        header1: '',
        header2: '',
        header3: '',
        header4: '',
        alerts: '',
    };

    const keystores = KeystoreStore.keystores || [];
    //@ts-ignore
    const { inputState, inputErrors, inputActions } = useInputState(keystoreInputSchemes[KeystoreTypeEnum.LOCAL]);

    useEffect(() => {
        if (update === true) {
            run(
                Promise.all([
                    KeystoreStore.clearKeystores(),
                    KeystoreStore.list().then(() => {
                        KeystoreStore.keystoreListItems.map(ks => {
                            return Promise.all([KeystoreStore.read(ks.id as string)]);
                        });
                    }),
                ])
            );
            setUpdate(false);
            inputActions.reset();
        }
    }, [update]);

    useEffect(() => {
        if (error) {
            ToastStore.push({ type: 'danger', title: error.message });
        }
    }, [error]);

    //set the keystoreId id and application ID
    useEffect(() => {
        //@ts-ignore
        if (history?.location?.state?.updateCert && status != 'idle' && status != 'loading') {
            const keystore = keystores.find(
                //@ts-ignore
                (k: KeystoreListItem) => k.id === history?.location?.state?.keystoreId
            );
            if (keystore) {
                setSelectedKs(keystore);
                triggerResize(50);
                setModalsToggled(prevState => {
                    return { ...prevState, updateCertificate: true, ks: keystore };
                });
            }
            //@ts-ignore
            delete history.location.state.keystoreId;
        }
    }, [history.location, status]);

    const onAdd = () => {
        setModalsToggled(prevState => {
            return { ...prevState, addKeystore: true, ks: {} };
        });
    };

    const onSyncButtonClick = () => {
        setUpdate(true);
    };

    const deleteKeystore = () => {
        doDeleteKeystore(selectedKs?.id, selectedKs?.name);
    };

    const doDeleteKeystore = async (id: string, name: string) => {
        setModalsToggled(prevState => {
            return { ...prevState, showDeleteModal: false };
        });

        try {
            await keystoreClient.remove(id);
            if (selectedKs && selectedKs.id === id) {
                setSelectedKs(null);
                triggerResize(50);
            }
            setUpdate(true);
            ToastStore.push({
                type: 'success',
                title: t('keystore.deleteSuccess', { name: name }),
                'data-testid': 'keystore-delete-success-toast',
            });
        } catch (error) {
            ToastStore.push({
                type: 'danger',
                title: error.message,
                'data-testid': 'keystore-delete-failure-toast',
            });
        }
    };

    const isValid = inputActions.validateIndividualField({ field: 'name', skipNull: false });

    const OnRenameConfirm = () => {
        setModalsToggled(prevState => {
            return { ...prevState, showRenameModal: false };
        });
        //TODO: Call the BE REST API to rename the selected Keystore (use selectedKs).
        // and refresh the page to update the table.
    };

    return (
        <>
            <AddKeyStoreModal
                onSuccess={(ks: Keystore, edit = false) => {
                    setUpdate(true);
                    ToastStore.push({
                        type: 'success',
                        title: edit
                            ? t('keystore.editSuccess', { name: ks.name })
                            : t('keystore.addSuccess', { name: ks.name }),
                        'data-testid': 'keystore-add-success-toast',
                    });
                }}
                open={modalsToggled.addKeystore || modalsToggled.updateKeyStore}
                onClose={() => {
                    setModalsToggled(prevState => {
                        return { ...prevState, addKeystore: false, updateKeyStore: false, ks: {} };
                    });
                }}
                selectedKS={modalsToggled.ks as Keystore}
            />
            <UpdateKeystoreCertModal
                //@ts-ignore
                keystore={modalsToggled.ks as Keystore}
                onSuccess={(ks: Keystore) => {
                    //TODO: re-implement this if we can support zero downtime to update a cert
                    // if (history?.location?.state?.updateCert && history?.location?.state?.applicationId) {
                    //     history.push(`/application/encryption/${history?.location?.state?.applicationId}`);
                    //     return;
                    // }
                    setUpdate(true);
                    ToastStore.push({
                        type: 'success',
                        title: t('keystore.updateCertSuccess', { name: ks.name }),
                        'data-testid': 'keystore-update-cert-success',
                    });
                }}
                open={modalsToggled.updateCertificate}
                onClose={() => {
                    setModalsToggled(prevState => {
                        return { ...prevState, updateCertificate: false, ks: {} };
                    });
                }}
            />
            <Modal
                open={modalsToggled.showDeleteModal}
                modalHeading={t('keystorev2.deleteConfirmationTitle', {
                    name: selectedKs?.name,
                })}
                danger={true}
                onRequestClose={() => {
                    setModalsToggled(prevState => {
                        return { ...prevState, showDeleteModal: false };
                    });
                }}
                primaryButtonText={t('main.delete')}
                secondaryButtonText={t('main.cancel')}
                size="sm"
                onRequestSubmit={deleteKeystore}>
                <p>{t('keystorev2.deleteConfirmationMsg', { name: selectedKs?.name })}</p>
            </Modal>
            <Modal
                open={modalsToggled.showRenameModal}
                modalHeading={t('keystorev2.renameKsTitle')}
                onRequestClose={() => {
                    setModalsToggled(prevState => {
                        return { ...prevState, showRenameModal: false };
                    });
                }}
                primaryButtonText={t('main.rename')}
                secondaryButtonText={t('main.cancel')}
                size="sm"
                onRequestSubmit={OnRenameConfirm}
                primaryButtonDisabled={!isValid}>
                <TextInput
                    id="keystore-rename-input"
                    name="keystore-rename-input"
                    data-testid="keystore-rename-input"
                    labelText={t('main.name')}
                    invalid={Boolean(inputErrors.name)}
                    invalidText={inputErrors.name}
                    value={(inputState.name as string) || ''}
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                        inputActions.validateField({
                            field: 'name',
                            skipNull: true,
                        });
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const val = e.target.value || '';
                        inputActions.setField('name', val);
                    }}
                />
            </Modal>

            <Content>
                <Header>
                    <div className="flex-1 px-4 flex justify-between pb-0">
                        <div className="flex-1 flex items-center">
                            <h1 className="text-heading-01 text-gray-100 pl-6 pt-5">
                                {/* ternary here to get i18n to update */}
                                {t('keystorev2.headerName')}
                            </h1>
                        </div>
                    </div>
                </Header>
                <Observer>
                    {() => {
                        const rows =
                            status === 'idle' || status === 'loading'
                                ? //add  empty row on initial load
                                  [...Array(Math.floor(1)).keys()].map(() => emptyCol)
                                : keystores.map((d: any) => ({
                                      id: d.id,
                                      selectedId: selectedKs?.id,
                                      header1: d.name,
                                      alerts: d?.expiresSoon ? (
                                          <Tag type={'red'} data-testid={`keystore-alert-${d.id}`}>
                                              {t('main.alert')}
                                          </Tag>
                                      ) : null,
                                      header2: t(`keystore.${d.type}`),
                                      header3: '', // Will be updated once the BE APIs are available
                                      header4: '',
                                      link: '/keystore/' + d.id,
                                      overFlowMenu: [
                                          {
                                              name: 'Rename',
                                              onClick: () => {
                                                  setSelectedKs(d);
                                                  inputActions.setField('name', d.name);
                                                  setModalsToggled(prevState => {
                                                      return { ...prevState, showRenameModal: true };
                                                  });
                                              },
                                          },
                                          {
                                              name: 'Edit',
                                              onClick: () => {
                                                  console.log('Edit action Clicked');
                                              },
                                          },
                                          {
                                              name: 'Delete',
                                              onClick: (id: string, name: string) => {
                                                  setSelectedKs(d);
                                                  setModalsToggled(prevState => {
                                                      return { ...prevState, showDeleteModal: true };
                                                  });
                                              },
                                          },
                                      ],
                                  }));
                        return (
                            <div className="flex font-normal text-sm leading-primary tracking-primary">
                                <div
                                    id="table-container"
                                    className={cx('datagrid-container keystore-datagrid flex-grow')}>
                                    <DataGrid
                                        idPrefix="keystores"
                                        rows={rows}
                                        headers={headers}
                                        showSearch={true}
                                        onAdd={onAdd}
                                        addButtonCaption={t('keystore.keystore')}
                                        onSync={onSyncButtonClick}></DataGrid>
                                </div>
                            </div>
                        );
                    }}
                </Observer>
            </Content>
        </>
    );
};

export default KeyStoresV2;
