import React, { memo, useCallback } from 'react';
import Cell, { getDefaultCellProps } from '@baffle/components/src/table/Cell';
import SkeletonText from '@baffle/components/src/skeleton/SkeletonText';
import { columnProps } from './ColumnTableRow';

interface FillColumnTableRowProps {
    style: any;
    fillWith: 'EMPTY' | 'SKELETON' | 'NORMAL';
}
const FillColumnTableRow = memo(({ fillWith, style }: FillColumnTableRowProps) => {
    const renderFill = useCallback(() => {
        switch (fillWith) {
            case 'SKELETON':
                return <SkeletonText />;

            case 'EMPTY':
            default:
                return ' ';
        }
    }, [fillWith]);
    return (
        <div role="row" className="flex items-center" style={style}>
            <Cell
                getCellProps={() => {
                    const { className, ...props } = getDefaultCellProps();
                    return {
                        className: `${className} ${columnProps.check.className}`,
                        ...props,
                    };
                }}></Cell>
            <Cell
                getCellProps={() => {
                    const { className, ...props } = getDefaultCellProps();
                    return {
                        className: `${className} ${columnProps.lock.className}`,
                        ...props,
                    };
                }}>
                {renderFill()}
            </Cell>
            <Cell
                getCellProps={() => {
                    const { className, ...props } = getDefaultCellProps();
                    return {
                        className: `${className} ${columnProps.colName.className}`,
                        ...props,
                    };
                }}>
                {renderFill()}
            </Cell>
            <Cell
                getCellProps={() => {
                    const { className, ...props } = getDefaultCellProps();
                    return {
                        className: `${className} ${columnProps.dataType.className}`,
                        ...props,
                    };
                }}>
                {renderFill()}
            </Cell>
            <Cell
                getCellProps={() => {
                    const { className, ...props } = getDefaultCellProps();
                    return {
                        className: `${className} ${columnProps.dataFormat.className}`,
                        ...props,
                    };
                }}>
                {renderFill()}
            </Cell>
            <Cell
                getCellProps={() => {
                    const { className, ...props } = getDefaultCellProps();
                    return {
                        className: `${className} ${columnProps.keyId.className}`,
                        ...props,
                    };
                }}>
                {renderFill()}
            </Cell>
            <Cell
                getCellProps={() => {
                    return {
                        ...getDefaultCellProps(),
                        className: `${columnProps.spacer.className}`,
                    };
                }}>
                {/* empty space cell */}{' '}
            </Cell>
        </div>
    );
});

export default FillColumnTableRow;
