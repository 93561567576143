import React, { useState } from 'react';
import Header from '@baffle/components/src/nav/Header';
import { useQuery } from '@apollo/react-hooks';
import { t } from '@baffle/translate';
import Content from '@baffle/components/src/content/Content';
import { GET_CURRENT_USER } from '@baffle/graphql';
import { CurrentUser } from '@baffle/graphql/src/models';
import ChangePasswordModal from './ChangePasswordModal';
import Toast, { useSetToast } from '@baffle/components/src/toasts/Toast';
import Button from '@baffle/components/src/buttons/Button';

interface SetupProps {
    version?: string | null;
}

const Profile = ({ version }: SetupProps) => {
    const { toast, setToast } = useSetToast();
    const { data: userData } = useQuery(GET_CURRENT_USER);
    const user: CurrentUser = userData?.currentUser ?? { roles: [] };
    const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
    return (
        <>
            {toast.show ? (
                <Toast
                    title={toast.title}
                    type={toast.type}
                    onDismiss={() => setToast({ title: '', show: false, type: 'success' })}
                />
            ) : null}
            <ChangePasswordModal
                onSuccess={() => {
                    setOpenChangePasswordModal(false);
                    setToast({
                        title: t('profile.updatePasswordSuccess'),
                        type: 'success',
                        show: true,
                    });
                }}
                open={openChangePasswordModal}
                onClose={() => setOpenChangePasswordModal(false)}
            />

            <Content>
                <Header>
                    <div className="flex-1 px-4 flex justify-between">
                        <div className="flex-1 flex items-center">
                            <h1 className="text-heading-01 text-gray-100">{t('profile.headerName')}</h1>
                        </div>
                    </div>
                    <div className="bx--header__global"></div>
                </Header>
                <div className="min-h-screen p-6 w-84">
                    {version === 'IBM_CLOUD' ? (
                        <IbmUser user={user} />
                    ) : (
                        <User user={user} setOpenChangePasswordModal={setOpenChangePasswordModal} />
                    )}
                </div>
            </Content>
        </>
    );
};

interface UserProps {
    setOpenChangePasswordModal: (val: boolean) => void;
    user: CurrentUser;
}

const User = ({ user, setOpenChangePasswordModal }: UserProps) => (
    <>
        <div className="flex-col pb-4">
            <p className="font-bold mb-1 uppercase">{t('main.details')}</p>
            <div className="flex">
                <div className="flex flex-col items-end mr-2">
                    <p className="text-gray-500">{t('profile.title')}:</p>
                    <p className="text-gray-500">{t('profile.firstName')}:</p>
                    <p className="text-gray-500">{t('profile.lastName')}:</p>
                    <p className="text-gray-500">{t('profile.email')}:</p>
                    <p className="text-gray-500">{t('profile.phone')}:</p>
                    <p className="text-gray-500">{t('profile.password')}:</p>
                </div>
                <div className="flex flex-col">
                    <p className="min-h-1_5">{user.title}</p>
                    <p className="min-h-1_5">{user.firstName}</p>
                    <p className="min-h-1_5">{user.lastName}</p>
                    <p className="min-h-1_5">{user.email}</p>
                    <p className="min-h-1_5">{user.phone}</p>
                    <p className="min-h-1_5">{t('profile.pwValue')}</p>
                    <Button
                        className="my-2"
                        name="change-password-btn"
                        id="change-password-btn"
                        data-testid="change-password-btn"
                        onClick={() => setOpenChangePasswordModal(true)}
                        theme="tertiary">
                        {t('profile.changePassword')}
                    </Button>
                </div>
            </div>
        </div>
    </>
);

const IbmUser = ({ user }: { user: CurrentUser }) => (
    <>
        <div className="flex-col pb-4">
            <p className="font-bold mb-1 uppercase">{t('main.details')}</p>
            <div className="flex">
                <div className="flex flex-col items-end mr-2">
                    <p className="text-gray-500">{t('profile.email')}:</p>
                </div>
                <div className="flex flex-col">
                    <p className="min-h-1_5">{user.email}</p>
                </div>
            </div>
        </div>
    </>
);

export default Profile;
