import React, { useState, useEffect, useMemo } from 'react';
import { t } from '@baffle/translate';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import { CreateModal, CreateModalBody, CreateModalHeader } from '@baffle/components/src/CreateModal/CreateModal';
import { databaseEditScheme } from './createDbInputScheme';
import { TextInput, Button } from 'carbon-components-react';
import { DatabasePayloadV2 } from '@baffle/graphql/src/models';
import useAwait from '@baffle/api-client/src/useAwait';
import { DatabaseStore } from '@baffle/manager/src/stores';
import databaseClient from '@baffle/api-client/src/databaseClientV2';
import SpinnerOverlay from '@baffle/components/src/loader/SpinnerOverlay';
import { NotificationStore } from '../../stores/NotificationStore';

interface EditDatabaseModalProps {
    open: boolean;
    onClose?: () => void;
    onSubmit?: () => void;
}

const EditDatabaseModal = ({ open, onClose }: EditDatabaseModalProps) => {
    const [openModal, setOpen] = useState(open);
    const { run, error, reset, status } = useAwait();
    const database = DatabaseStore.database;
    const databaseEditSchema = useMemo(() => databaseEditScheme(), []);

    //@ts-ignore
    const { inputState, inputErrors, inputActions } = useInputState(databaseEditSchema);

    useEffect(() => {
        if (error) {
            NotificationStore.push({
                kind: 'error',
                title: t('database.editDatabaseError'),
                description: error.message ?? '',
            });
        }
    }, [error]);

    const handleClose = () => {
        if (!openModal) {
            return;
        }
        setOpen(false);
        reset();
        inputActions.reset();
        setTimeout(() => {
            if (onClose) {
                onClose();
            }
        }, 200);
    };

    //Sync state from props
    useEffect(() => {
        if (database) {
            const { configDbName, username, password } = database;
            inputActions.setFields({
                configDbName,
                username,
                password,
            });
        }
    }, []);

    //Sync state from props
    useEffect(() => {
        setOpen(open);
    }, [open]);

    const isValid = inputActions.validateAll(true);

    const handleSave = (testConnection: boolean = false) => {
        let payload: DatabasePayloadV2 = databaseEditSchema.keys.reduce((acc: any, c: any) => {
            switch (c) {
                case 'password':
                    acc[c] = inputState[c];
                    break;
                default:
                    acc[c] = inputState[c] ? (inputState[c] as string).trim() : null;
                    break;
            }
            return acc;
        }, {});

        payload = Object.assign({}, database, payload);
        run(
            testConnection
                ? databaseClient.testConnection(payload).then(res => {
                      if (res) {
                          NotificationStore.push({
                              title: t('database.connectionSuccess'),
                              kind: 'success',
                          });
                      } else {
                          NotificationStore.push({
                              title: t('database.connectionFailure'),
                              kind: 'error',
                          });
                      }
                  })
                : DatabaseStore.updateDatabase(payload.id, payload).then(() => {
                      NotificationStore.push({
                          title: t('database.databaseSuccess', { name: payload.name }),
                          kind: 'success',
                      });
                      inputActions.reset();
                      handleClose();
                  })
        );
    };

    return (
        <>
            {status === 'loading' && <SpinnerOverlay description="" />}
            <CreateModal
                open={openModal}
                onClose={handleClose}
                isValid={isValid}
                modalId="edit-database-modal"
                primaryButtonText={t('main.save')}
                primaryButtonTheme="primary"
                primaryButton={() => handleSave()}>
                <CreateModalHeader titleText={t('database.editDatabaseModalHealine')} />
                <CreateModalBody>
                    <div>
                        <div className="flex flex-wrap mt-8 items-end">
                            <div className="flex-1 relative">
                                <p className="text-sm">
                                    <span className="font-bold">{t('database.databaseHeader1')}:</span> {database?.name}
                                </p>
                                <p className="text-sm mt-2">
                                    <span className="font-bold">{t('database.environmentLabel')}:</span>{' '}
                                    {database?.environment}
                                </p>
                                <p className="text-sm mt-2">
                                    <span className="font-bold">{t('database.dbTypeLabel')}:</span> {database?.type}
                                </p>
                                <p className="text-sm mt-2">
                                    <span className="font-bold">{t('databaseDetail.hostname')}:</span>{' '}
                                    {database?.hostname}
                                </p>
                                <p className="text-sm mt-2">
                                    <span className="font-bold">{t('database.portInputLabel')}:</span> {database?.port}
                                </p>
                                <p className="text-sm mt-2">
                                    <span className="font-bold">{t('database.header4')}:</span>{' '}
                                    {database?.ssl ? 'true' : 'false'}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-wrap mt-8 items-end">
                            <div className="flex-1 text-sm relative">
                                <TextInput
                                    id="configDbName-input"
                                    name="configDbName-input"
                                    data-testid="configDbName-input"
                                    labelText={t('database.configDbNameLabel')}
                                    placeholder={t('database.configDbNamePlaceholder')}
                                    invalid={Boolean(inputErrors.configDbName)}
                                    invalidText={inputErrors.configDbName}
                                    value={(inputState.configDbName as string) || ''}
                                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        inputActions.validateField({
                                            field: 'configDbName',
                                            skipNull: true,
                                        });
                                    }}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const val = e.target.value || '';
                                        inputActions.setField('configDbName', val);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap mt-6">
                            <div className="flex-1 relative">
                                <TextInput
                                    id="username-input"
                                    name="username-input"
                                    data-testid="username-input"
                                    labelText={t('database.usernameLabel')}
                                    placeholder={t('database.dbUserPlaceholder')}
                                    invalid={Boolean(inputErrors.username)}
                                    invalidText={inputErrors.username}
                                    value={(inputState.username as string) || ''}
                                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        inputActions.validateField({
                                            field: 'username',
                                            skipNull: true,
                                        });
                                    }}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const val = e.target.value || '';
                                        inputActions.setField('username', val);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap mt-6">
                            <div className="flex-1 relative">
                                <TextInput
                                    id="password-input"
                                    name="password-input"
                                    data-testid="password-input"
                                    labelText={t('database.passwordLabel')}
                                    placeholder={t('database.dbPassPlaceholder')}
                                    invalid={Boolean(inputErrors.password)}
                                    invalidText={inputErrors.password}
                                    value={(inputState.password as string) || ''}
                                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        inputActions.validateField({
                                            field: 'password',
                                            skipNull: true,
                                        });
                                    }}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const val = e.target.value || '';
                                        inputActions.setField('password', val);
                                    }}
                                    type={'password'}
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap mt-8">
                            <div className="flex-1 relative">
                                <Button
                                    kind="tertiary"
                                    disabled={!isValid}
                                    id="test-connection-btn"
                                    onClick={() => {
                                        handleSave(true);
                                    }}>
                                    {t('database.testConnection')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </CreateModalBody>
            </CreateModal>
        </>
    );
};

export default EditDatabaseModal;
