export interface BaseDatabaseInput {
    dbName: string;
    dbPass: string;
    dbUser: string;
    bsConfigClientDBName?: string;
    description: string;
    hostName: string;
    ssl: boolean;
    port: number;
}

export interface DatabaseType {
    port: number;
    isCloud: boolean;
    type: string;
    name: string;
}

export interface DatabaseInput extends BaseDatabaseInput {
    type: DatabaseTypeEnum;
    cert?: File;
}

export interface DatabasePayload extends BaseDatabaseInput {
    type: DatabaseTypeEnum;
}

export interface DatabasePayloadPUT extends BaseDatabaseInput {
    type: DatabaseTypeEnum;
    id: string;
}
export interface DatabaseListItem {
    id?: string;
    dbName: string;
    hostName: string;
    status: string;
    ssl: boolean;
    type: string;
    details?: Database;
}
export interface Database {
    id?: string;
    uniqueId?: string;
    dbName: string;
    dbUser?: string;
    bsConfigClientDBName?: string;
    dbPass?: string;
    hostName: string;
    description?: string;
    status: string;
    ssl: boolean;
    type: string;
    protection?: string;
    createTime: number;
    createdBy: string;
    port?: number;
    certIssuerDN?: string;
    certSubjectSAN?: string;
    certSubjectDN?: string;
    certExpiry?: number;
    expiresSoon?: boolean; //added by typepatche on the client
    applicationId?: string; //resolved by client
}

export interface DatabaseCount {
    dbCount: number;
}

export enum DatabaseTypeEnum {
    POSTGRES = 'POSTGRES',
    RDS_POSTGRES = 'RDS_POSTGRES',
    AWS_RDS_AURORA_MYSQL = 'RDS_AURORA_MYSQL',
    MARIADB = 'MARIADB',
    AZURE_SQL = 'AZURE_MSSQL',
    AZURE_MYSQL = 'AZURE_MYSQL',
    CASSANDRA = 'CASSANDRA',
    MYSQL = 'MYSQL',
    SQL_SERVER = 'SQL_SERVER',
    RDS_MARIADB = 'RDS_MARIADB',
    RDS_MYSQL = 'RDS_MYSQL',
    RDS_MSSQL = 'RDS_MSSQL',
    AWS_REDSHIFT = 'AWS_REDSHIFT',
    GREENPLUM = 'GREENPLUM',
}
