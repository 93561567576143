import { MaskingPolicy } from '@baffle/graphql/src/models';
import client from '.';

export async function createMaskingPolicy(payload: MaskingPolicy) {
    return client('/api/v2/mask-policies', { body: payload, method: 'POST' });
}

export async function updateMaskingPolicy(id: string, payload: MaskingPolicy) {
    const p = Object.assign({}, { id }, payload);
    return client(`/api/v2/mask-policies/${id}`, { body: p, method: 'PUT' });
}

export async function getMaskingPolicies() {
    return client('/api/v2/mask-policies');
}

export async function readMaskingPolicy(modeID: string) {
    return client(`/api/v2/mask-policies/${modeID}`);
}

export function deleteMaskingPolicy(id: string) {
    return client(`/api/v2/mask-policies/${id}`, { method: 'DELETE' });
}

export function renameMaskingPolicy(id: string, payload: { newName: string }) {
    return client(`/api/v2/mask-policies/${id}/rename`, { body: payload, method: 'PATCH' });
}

export default {
    createMaskingPolicy,
    updateMaskingPolicy,
    getMaskingPolicies,
    readMaskingPolicy,
    deleteMaskingPolicy,
    renameMaskingPolicy,
};
