import { baffleApi, getAuthToken } from './clientUtils';
//this is not a done via a q/uery because
//we need to be able to get the response headers
//to set the session storage
export const refreshToken = () => {
    return fetch(`${baffleApi}/api/v1.0/refresh_token`, {
        method: 'GET',
        headers: {
            'X-AUTH-TOKEN': getAuthToken(),
        },
    });
};
