import { ColumnMetaData, Keystore, Database, Shield } from '../models';
import { RestLink } from 'apollo-link-rest';
import { TYPE_NAMES } from '../queries';
import { KeyStoreEnums } from '@baffle/utilities/src/enums';

/**
 * Since databases, tables and columns do not have unique identifiers from the server side,
 * the UI cannot effectively track which databases, tables and columns have been operated on across different parts of the UI application.
 * For example the ColumnPills component needs to have unique Identifiers to work properly...
 *
 * To solve for this, the UI creates an ID on the frontend composed of the the following patterns:
 *
 * database ID pattern: database name
 * table ID pattern: table name + column names concatenated
 * column ID pattern: table name + column name
 */

const typePatcher = {
    Keystore: (data: Keystore, outerType: string, patchDeeper: RestLink.FunctionalTypePatcher): any => {
        if (data !== null) {
            if (data.type === KeyStoreEnums.SAFENET_KEY_SECURE) {
                const deltaT = (data.certExpiry || 0) - Date.now();
                const ttl = 2592000000; //30 days in ms
                data.expiresSoon = deltaT <= ttl;
                data.expired = deltaT <= 0;
            }
        }
        return data;
    },
    Database: (data: Database, outerType: string, patchDeeper: RestLink.FunctionalTypePatcher): any => {
        if (data !== null) {
            if (data.ssl) {
                const deltaT = (data.certExpiry || 0) - Date.now();
                const ttl = 2592000000; //30 days in ms
                data.expiresSoon = deltaT <= ttl;
            }
        }
        return data;
    },
    Shield: (data: Shield, outerType: string, patchDeeper: RestLink.FunctionalTypePatcher): any => {
        if (data !== null) {
            if (data.certExpiry && data.certExpiry > 0) {
                const deltaT = (data.certExpiry || 0) - Date.now();
                const ttl = 2592000000; //30 days in ms
                data.expiresSoon = deltaT <= ttl;
            }
        }
        return data;
    },
    //Type patch databases for app encryption databases in GET_ENCRYPTION_DATABASES
    ApplicationBindingDTO: (data: any, outerType: string, patchDeeper: RestLink.FunctionalTypePatcher): any => {
        if (data.columns != null) {
            data.columns = data.columns.map((c: ColumnMetaData) => {
                return {
                    __typename: TYPE_NAMES.ColumnMetaData,
                    ...c,
                };
            });
        }
        return data;
    },
    EncryptionSchema: (data: any, outerType: string, patchDeeper: RestLink.FunctionalTypePatcher): any => {
        if (data.columns != null) {
            data.columns = data.columns.map((c: ColumnMetaData) => {
                return {
                    __typename: TYPE_NAMES.ColumnMetaData,
                    ...c,
                };
            });
        }
        return data;
    },
    //Type patch shield ids to fetch more on application details
    ApplicationDetails: (data: any, outerType: string, patchDeeper: RestLink.FunctionalTypePatcher): any => {
        if (data.shields != null) {
            data.shields = data.shields.map((shieldId: string) => ({
                __typename: TYPE_NAMES.ApplicationDetailsShield,
                id: shieldId,
            }));
        }
        return data;
    },
};

export default typePatcher;
