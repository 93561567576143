import React, { useState, useEffect } from 'react';
import { ADD_ROTATE_KEY, ADD_ROTATE_MASTER_KEY } from '@baffle/graphql';
import { useLazyQuery } from '@apollo/react-hooks';
import RightPanel, {
    PanelModule,
    TwoColTableModule,
    TwoColTableModuleRow,
} from '@baffle/components/src/content/RightPanel';
import ReactTooltip from 'react-tooltip';
import SkeletonText from '@baffle/components/src/skeleton/SkeletonText';
import { OverflowMenu, OverflowMenuItem } from 'carbon-components-react';
import { t } from '@baffle/translate';
import { Keystore, KeyDetails, KeystoreListItem } from '@baffle/graphql/src/models';
import ReactDataGrid from 'react-data-grid';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TH from '@baffle/components/src/table/react-data-grid/TH';
import Cell from '@baffle/components/src/table/react-data-grid/Cell';
import { stringSort, numberSort } from '@baffle/utilities/src/sorters';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import RotateRightOutlinedIcon from '@material-ui/icons/RotateRightOutlined';
import ErrorIcon from '@material-ui/icons/Error';
import './KeystoreSidePanel.scss';
import useFlush from '@baffle/utilities/src/useFlush';
import cx from 'classnames';
import SidePanelErrors, { SidePanelErrorItem } from './SidePanelErrors';
import shortid from 'shortid';
import { KeyStoreEnums } from '@baffle/utilities/src/enums';
import { KeyDetailsStore } from '../stores';
import { Observer } from 'mobx-react-lite';
import { format } from '@baffle/utilities/src/date';
import { ToastStore } from '../stores/ToastStore';
import { SkeletonParagraph } from '@baffle/components/src/skeleton/SkeletonText';
import CreateIcon from '@material-ui/icons/CreateOutlined';

interface KeystoreSidePanelProps {
    ks: KeystoreListItem | null;
    onClose?: () => void;
    deleteKeystore?: (id: string, name: string) => void;
    onCloseImmediate?: () => void;
    handleUpdateCert?: (ks: Keystore) => void;
    onEdit?: () => void;
}
const KeystoreSidePanel = ({
    ks,
    deleteKeystore = (id: string, name: string) => null,
    onClose = () => null,
    onCloseImmediate = () => null,
    handleUpdateCert = (ks: Keystore) => null,
    onEdit = () => null,
}: KeystoreSidePanelProps) => {
    if (!ks) {
        return null;
    }
    const [sidePanelErrors, setSidePanelErrors] = useState(([] as unknown) as SidePanelErrorItem[]);
    const flush = useFlush(ks.id);
    const rKey: any = {};
    const [rotatedKey, setRotatedKey] = useState(rKey);
    const columns = [
        {
            key: 'header1',
            name: t('keystore.keyDetails.header1'),
            sortable: false,
            formatter: Cell,
            sortFn: (a: KeyDetails, b: KeyDetails) => numberSort(a.keyId, b.keyId),
            headerRenderer: <TH idPrefix="keyDetails" />,
            width: 65,
        },
        {
            key: 'header2',
            name: t('keystore.keyDetails.header2'),
            sortable: false,
            formatter: Cell,
            sortFn: (a: KeyDetails, b: KeyDetails) => stringSort(a.keyType || '', b.keyType || ''),
            headerRenderer: <TH idPrefix="keyDetails" />,
            width: 55,
        },
        {
            key: 'header3',
            name: t('keystore.keyDetails.header3'),
            sortable: false,
            formatter: Cell,
            sortFn: (a: KeyDetails, b: KeyDetails) => numberSort(a.creationDate || 0, b.creationDate || 0),
            headerRenderer: <TH idPrefix="keyDetails" />,
            width: 150,
        },
        {
            key: 'header4',
            formatter: Cell,
            width: 50,
        },
        {
            key: 'header5',
            name: ' ',
            width: 5,
        },
    ];

    const keystore: Keystore = ks.details;

    useEffect(() => {
        if (ks?.id) {
            KeyDetailsStore.read(ks.id).catch(error => {
                if (error.message) {
                    const err: SidePanelErrorItem = {
                        id: shortid.generate(),
                        err_msg: error.message,
                    };
                    const errs = sidePanelErrors.slice();
                    errs.push(err);
                    setSidePanelErrors(errs);
                }
            });
        }
    }, [ks?.id]);

    const [addRotateKey, { data: rotateData }] = useLazyQuery(ADD_ROTATE_KEY, {
        fetchPolicy: 'network-only',
        onError: error => {
            const errs = sidePanelErrors.slice();
            errs.push({
                id: shortid.generate(),
                err_msg: error.message ?? t('keystore.rotateKeyFail'),
            });
            setSidePanelErrors(errs);

            ToastStore.push({
                title: t('keystore.rotateKeyFail'),
                type: 'danger',
                'data-testid': 'keystore-rotate-key-failure',
            });
        },
    });

    const [addRotateMasterKey, { data: rotateMasterData }] = useLazyQuery(ADD_ROTATE_MASTER_KEY, {
        fetchPolicy: 'network-only',
        onError: error => {
            const errs = sidePanelErrors.slice();
            errs.push({
                id: shortid.generate(),
                err_msg: error.message ?? t('keystore.rotateKeyFail'),
            });
            setSidePanelErrors(errs);
            ToastStore.push({
                title: t('keystore.rotateKeyFail'),
                type: 'danger',
                'data-testid': 'keystore-rotate-master-key-failure',
            });
        },
    });

    //Rotate normal keys
    useEffect(() => {
        const didRotate = rotateData?.addRotateKeyRes?.data;
        if (didRotate !== null && didRotate !== undefined && !didRotate) {
            ToastStore.push({
                title: t('keystore.rotateKeyFail'),
                type: 'danger',
                'data-testid': 'keystore-rotate-key-failure',
            });
        } else if (didRotate) {
            ToastStore.push({
                title: t('keystore.rotateKeySuccess', { key: rotatedKey?.key, keyToRotate: rotatedKey?.keyToRotate }),
                type: 'success',
                'data-testid': 'keystore-rotate-key-success',
            });
        }
    }, [rotateData]);

    //Rotate master keys
    useEffect(() => {
        const didRotate = rotateMasterData?.addRotateKeyMasterRes?.data;
        if (didRotate !== null && didRotate !== undefined && !didRotate) {
            ToastStore.push({
                title: t('keystore.rotateKeyFail'),
                type: 'danger',
                'data-testid': 'keystore-rotate-master-key-failure',
            });
        } else if (didRotate) {
            ToastStore.push({
                title: t('keystore.rotateKeyMasterSuccess'),
                type: 'success',
                'data-testid': 'keystore-rotate-master-key-success',
            });
        }
    }, [rotateMasterData]);

    const detailsRows: TwoColTableModuleRow[] = [
        {
            'data-testid': 'keystore-added-on-text',
            cols: [t('main.addedOn'), format(keystore.createTime, 'yyyy-M-d H:mm:ss')],
        },
        {
            'data-testid': 'keystore-created-by-text',
            cols: [t('main.createdBy'), keystore.createdBy],
        },
    ];

    const ksDetailsRows: TwoColTableModuleRow[] = [
        {
            'data-testid': 'keystore-appNameSpace-text',
            cols: [t('keystore.header2'), keystore.appNameSpace || 'N/A'],
        },
        {
            'data-testid': 'keystore-type-text',
            cols: [t('keystore.header3'), t(`keystore.${keystore.type}`)],
        },
    ];

    const [sort, setSort] = useState({ direction: 'NONE', key: '' });

    let configMenuItems = [];

    // Add Edit option only for the key store type HASHI_VAULT for now
    if (ks && ks.type === 'HASHI_VAULT') {
        configMenuItems.push({
            item: (
                <div className="flex items-center" data-testid="edit-keystore-menu-item">
                    <CreateIcon /> {t('keystore.editModalHeading')}
                </div>
            ),
            onClick: () => {
                onEdit();
            },
        });
    }
    configMenuItems.push({
        item: (
            <div
                className={cx('config-menu-delete flex items-center', {
                    disabled: keystore.name === 'baffle_credential_store',
                })}>
                <DeleteOutlineIcon /> {t('main.delete')}
            </div>
        ),
        onClick: () => {
            if (keystore.name === 'baffle_credential_store') {
                return;
            }
            deleteKeystore(keystore.id as string, keystore.name);
        },
    });
    return (
        <RightPanel
            configMenuItems={configMenuItems}
            skeleton={false}
            title={keystore.name}
            onClose={onClose}
            onCloseImmediate={onCloseImmediate}>
            <Observer>
                {() => {
                    return (
                        <>
                            {KeyDetailsStore.loading ? (
                                <div />
                            ) : (
                                KeyDetailsStore.get(ks?.id as string, { forRotation: true }).map((d: KeyDetails) => (
                                    <ReactTooltip key={`${d.keyId}-key`} id={`${d.keyId}-key`} effect="solid">
                                        <p>{d.keyUUID}</p>
                                    </ReactTooltip>
                                ))
                            )}
                        </>
                    );
                }}
            </Observer>
            <PanelModule title={t('main.details')}>
                <TwoColTableModule rows={detailsRows} />
            </PanelModule>
            <PanelModule title={t('main.description')} description={keystore.description} />
            <PanelModule title={t('keystore.details')} data-testid="keystore-details">
                <TwoColTableModule rows={ksDetailsRows} />
            </PanelModule>
            <PanelModule title={t('keystore.keyDetails.title')} data-testid="keystore-keydetails">
                <div className="keydetails-datagrid">
                    <Observer>
                        {() => {
                            //sort data
                            if (sort.direction !== 'NONE') {
                                const header = columns.find(h => h.key === sort.key);
                                if (header && header.sortFn) {
                                    KeyDetailsStore.get(ks?.id ?? '', { forRotation: true }).sort(
                                        //@ts-ignore
                                        (a: KeyDetails, b: KeyDetails) => {
                                            if (sort.direction === 'ASC') {
                                                return header.sortFn(a, b);
                                            } else if (sort.direction === 'DESC') {
                                                return header.sortFn(b, a);
                                            }
                                        }
                                    );
                                }
                            }
                            const skeletonCol = {
                                header1: <SkeletonText />,
                                header2: <SkeletonText />,
                                header3: <SkeletonText />,
                                header4: ' ',
                            };
                            const rows = KeyDetailsStore.loading
                                ? //add  skeleton row on initial load
                                  [...Array(Math.floor(1)).keys()].map(() => skeletonCol)
                                : KeyDetailsStore.get(ks?.id ?? '', { forRotation: true }).map((d: KeyDetails) => ({
                                      header1: (
                                          <span
                                              data-tip
                                              data-for={`${d.keyId}-key`}
                                              data-event="click"
                                              className="keyid-cell-item">
                                              {d.keyId}
                                          </span>
                                      ),
                                      header2: d.keyType,
                                      header3: format(d.creationDate, 'yyyy-M-d H:mm:ss'),
                                      header4:
                                          ks.type !== KeyStoreEnums.HASHI_VAULT && d.keyType === 'MK' ? (
                                              <OverflowMenu
                                                  menuOffset={{ left: -64 }}
                                                  className="datagrid-settings"
                                                  ariaLabel={t('application.overFlowMenu')}
                                                  onClick={(e: React.MouseEvent) => e.stopPropagation()}
                                                  renderIcon={() => (
                                                      <MoreVertIcon
                                                          data-testid={`keylist-settings-${d.keyId}`}
                                                          className="w-4 h-4 text-gray-500"
                                                      />
                                                  )}>
                                                  <OverflowMenuItem
                                                      itemText={
                                                          <div
                                                              className="flex items-center"
                                                              data-testid={`key-rotate-${d.keyId}`}>
                                                              <RotateRightOutlinedIcon className="w-4 h-4" />{' '}
                                                              {t('keystore.rotateKey')}
                                                          </div>
                                                      }
                                                      data-testid={`key-rotate-btn-${d.keyId}`}
                                                      onClick={async (e: React.MouseEvent) => {
                                                          e.stopPropagation();
                                                          if (d.keyId === 0) {
                                                              await addRotateMasterKey({
                                                                  variables: {
                                                                      id: keystore.id,
                                                                  },
                                                              });
                                                          } else {
                                                              const nextKey = KeyDetailsStore.nextKey(
                                                                  ks?.id as string,
                                                                  {
                                                                      forRotation: true,
                                                                  }
                                                              );
                                                              setRotatedKey({
                                                                  key: d.keyId,
                                                                  keyToRotate: nextKey.keyId,
                                                              });
                                                              await addRotateKey({
                                                                  variables: {
                                                                      id: keystore.id,
                                                                      key: d.keyId,
                                                                      keyToRotate: nextKey.keyId,
                                                                  },
                                                              });
                                                          }
                                                          //refetch
                                                          await KeyDetailsStore.read(ks?.id as string);
                                                      }}
                                                  />
                                              </OverflowMenu>
                                          ) : null,
                                  }));
                            return KeyDetailsStore.loading ? (
                                <div />
                            ) : (
                                <ReactDataGrid
                                    //name property 'can' be an element, the type def is not flexible enough in the lib
                                    //@ts-ignore
                                    columns={columns}
                                    onGridSort={(key: string, direction: string) => setSort({ direction, key })}
                                    rows={rows}
                                    minHeight={175}
                                    rowGetter={i => rows[i]}
                                    rowsCount={rows.length}
                                />
                            );
                        }}
                    </Observer>
                </div>
            </PanelModule>
            {sidePanelErrors.length ? (
                <PanelModule title={t('main.alerts')}>
                    {flush ? (
                        <SkeletonParagraph />
                    ) : (
                        <SidePanelErrors
                            didClearNotification={(error: SidePanelErrorItem) => {
                                const errIndex = sidePanelErrors.findIndex(err => err.id === error.id);
                                if (errIndex !== -1) {
                                    const errs = sidePanelErrors.slice();
                                    errs.splice(errIndex, 1);
                                    setSidePanelErrors(errs);
                                }
                            }}
                            errors={sidePanelErrors}
                        />
                    )}
                </PanelModule>
            ) : null}
            {ks.type === KeyStoreEnums.SAFENET_KEY_SECURE ? (
                <PanelModule title={t('keystore.certificate')}>
                    <div className="flex justify-between w-full">
                        <div>
                            {keystore.expiresSoon ? (
                                <div className="text-red mb-2 flex items-center justify-content">
                                    <ErrorIcon className="w-4 h-4" />
                                    <p className="ml-1 text-xs truncate">
                                        {t('keystore.certExpiresOn', {
                                            expires: format(keystore?.certExpiry ?? '', 'M-d-yyyy'),
                                        })}
                                    </p>
                                </div>
                            ) : null}
                            <table className="table-fixed">
                                <tbody>
                                    <tr>
                                        <td className="text-gray-600 px-1">{t('keystore.certIssuerDN')}:</td>
                                        <td className="px-1">{keystore.certIssuerDN}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-gray-600 px-1">{t('keystore.certSubjectSAN')}:</td>
                                        <td className="px-1">{keystore.certSubjectSAN}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-gray-600 px-1">{t('keystore.certSubjectDN')}:</td>
                                        <td className="px-1">{keystore.certSubjectDN}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-gray-600 px-1">{t('main.expiresOn')}:</td>
                                        <td className="px-1">{format(keystore?.certExpiry ?? '', 'M-d-yyyy')}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="w-48 font-light flex justify-end items-center h-full">
                            <button
                                onClick={() => {
                                    handleUpdateCert(keystore);
                                }}
                                data-testid={`update-keystore-cert-btn`}
                                id="update-keystore-cert-btn"
                                className="bx--btn bx--btn--icon-only-transparent text-blue-60 text-xs">
                                {t('main.update')}
                            </button>
                        </div>
                    </div>
                </PanelModule>
            ) : null}
        </RightPanel>
    );
};

export default KeystoreSidePanel;
