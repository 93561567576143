import React, { useEffect, useMemo } from 'react';
import { Modal, TextInput } from 'carbon-components-react';
import { t } from '@baffle/translate';
import { MaskingPolicyStore } from '@baffle/manager/src/stores';
import { Observer } from 'mobx-react-lite';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import { maskingPolicyRenameScheme } from './createMaskingPolicyInputScheme';
import maskingPolicyClient from '@baffle/api-client/src/maskingPolicyClient';
import useAwait from '@baffle/api-client/src/useAwait';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';

interface RenameMaskingPolicyProps {
    open: boolean;
    onClose: () => void;
    onSuccess?: (name: string) => void;
}

const RenameMaskingPolicy = ({ open, onClose, onSuccess }: RenameMaskingPolicyProps) => {
    const { run, error } = useAwait();

    const animateModal = () => {
        return new Promise(() => {
            setTimeout(() => {
                if (onClose) {
                    onClose();
                }
            }, 200);
        });
    };

    useEffect(() => {
        if (error) {
            NotificationStore.push({
                'data-testid': 'masking-policy-rename-failure',
                kind: 'error',
                title: t('globalPolicies.maskingPolicy.renameMaskingPolicyError'),
                description: error.message ?? '',
            });
        }
    }, [error]);

    const handleClose = async () => {
        await animateModal();
    };

    const renameMaskingPolicy = (name: string) => {
        run(
            maskingPolicyClient
                .renameMaskingPolicy(MaskingPolicyStore.maskingPolicy?.id as string, { newName: name })
                .then(() => {
                    Promise.all([
                        MaskingPolicyStore.getMaskingPolicy(MaskingPolicyStore.maskingPolicy?.id as string),
                        MaskingPolicyStore.getMaskingPolicies(),
                    ]).then(() => {
                        if (onSuccess) {
                            onSuccess(name);
                        }
                        handleClose();
                    });
                })
        );
    };

    return (
        <Observer>
            {() => {
                const maskingPolicyRenameSchemes = useMemo(
                    () =>
                        maskingPolicyRenameScheme(
                            MaskingPolicyStore.maskingPolicies,
                            MaskingPolicyStore.maskingPolicy?.id
                        ),
                    [MaskingPolicyStore.maskingPolicies, MaskingPolicyStore.maskingPolicy?.id]
                );
                //@ts-ignore
                const { inputState, inputErrors, inputActions } = useInputState(maskingPolicyRenameSchemes);
                const isValid = inputActions.validateAll(true);
                return (
                    <Modal
                        id="masking-policy-rename-modal"
                        className="modal-container-white"
                        open={open}
                        modalHeading={t('globalPolicies.maskingPolicy.renameMaskingPolicyHeading')}
                        onRequestClose={onClose}
                        primaryButtonText={t('globalPolicies.maskingPolicy.rename')}
                        secondaryButtonText={t('main.cancel')}
                        size="sm"
                        onRequestSubmit={() => renameMaskingPolicy(inputState.name as string)}
                        primaryButtonDisabled={!isValid}>
                        <TextInput
                            id="masking-policy-rename-input"
                            className="text-input-gray10"
                            name="masking-policy-rename-input"
                            data-testid="masking-policy-rename-input"
                            labelText={t('globalPolicies.maskingPolicy.name')}
                            placeholder={t('globalPolicies.maskingPolicy.namePlaceholder')}
                            invalid={Boolean(inputErrors.name)}
                            invalidText={inputErrors.name}
                            value={(inputState.name as string) || ''}
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                inputActions.validateField({
                                    field: 'name',
                                    skipNull: true,
                                });
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                inputActions.setField('name', val);
                            }}
                        />
                    </Modal>
                );
            }}
        </Observer>
    );
};

export default RenameMaskingPolicy;
