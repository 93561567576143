import currentUserClient from '@baffle/api-client/src/currentUserClient';
import inviteUserClient from '@baffle/api-client/src/inviteUserClient';
import usersClient from '@baffle/api-client/src/usersClient';
import { CurrentUser, User } from '@baffle/graphql/src/models';
import { makeObservable, action, observable } from 'mobx';
import { RequestOpts } from '../types/StoreTypes';

class UsersStore {
    constructor() {
        makeObservable(this, {
            users: observable,
            setUsers: action,
            currentUser: observable,
            setCurrentUser: action,
        });
    }
    currentUser: CurrentUser | undefined = undefined;
    users = observable<User>([]);
    setUsers(users: User[]) {
        this.users.replace(users);
    }

    async list(opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first' && this.users.length > 0) {
            return;
        }
        try {
            const users = await usersClient.read();
            this.setUsers(users);
        } catch (error) {
            throw error;
        }
    }

    setCurrentUser(currentUser: CurrentUser) {
        this.currentUser = currentUser;
    }
    async readCurrentUser(opts: RequestOpts = {}) {
        //TODO: update all reads and updates for the current user to use the store and the client
        if (opts.networkPolicy === 'cache-first' && this.currentUser) {
            return;
        }
        try {
            let currentUser: CurrentUser = await currentUserClient.read();
            // Adding following condition as a fix for BM3-1373 as BE filters are removing x-auth-token from request for the first request
            if (!currentUser || Object.keys(currentUser).length === 0) currentUser = await currentUserClient.read();
            this.setCurrentUser(currentUser);
        } catch (error) {
            throw error;
        }
    }
    async readUserFromActivationToken(ac: string, opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first') {
            console.warn('cannot cache activation token request');
        }
        try {
            const user = await inviteUserClient.readValidateToken({ queryParams: { ac } });
            this.setCurrentUser(user);
        } catch (error) {
            throw error;
        }
    }
}

export default UsersStore;
