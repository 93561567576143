import TextInput from '@baffle/components/src/forms/TextInput';
import { InputActions, InputError, InputState } from '@baffle/utilities/src/inputHooks';
import React from 'react';
import { t } from '@baffle/translate';

interface RbacConfigSqlCommentProps {
    inputState: InputState;
    inputErrors: InputError;
    inputActions: InputActions;
    editMode: boolean;
}

const RbacConfigSqlComment = ({ inputState, inputActions, inputErrors, editMode }: RbacConfigSqlCommentProps) => {
    return (
        <div className="flex flex-wrap mb-6 items-end">
            <div className="flex-1 name-input-container">
                <TextInput
                    id="sql-comment-format-input"
                    name="sql-comment-format-input"
                    data-testid="sql-comment-format-input"
                    labelText={t('encryption.modeLibrary.rbac.rbacConfig.sqlCommentFormatLabel')}
                    placeholder={t('encryption.modeLibrary.rbac.rbacConfig.sqlCommentPlaceholder')}
                    invalid={Boolean(inputErrors.sqlCommentFormat)}
                    invalidText={inputErrors.sqlCommentFormat}
                    value={(inputState.sqlCommentFormat as string) || ''}
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                        inputActions.validateField({
                            field: 'sqlCommentFormat',
                            skipNull: true,
                        });
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const val = e.target.value || '';
                        inputActions.setField('sqlCommentFormat', val);
                    }}
                    disabled={editMode}
                />
            </div>
        </div>
    );
};

export default RbacConfigSqlComment;
