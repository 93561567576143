import React, { useEffect, useState } from 'react';
import { Observer } from 'mobx-react-lite';
import { RbacPolicyV2 } from '@baffle/graphql/src/models';
import DataGrid from '@baffle/components/src/grid/data-grid';
import { t } from '@baffle/translate';
import useAwait from '@baffle/api-client/src/useAwait';
import { useHistory } from 'react-router-dom';
import { useManageModals } from '@baffle/components/src/modal/Modal';
import DeleteRbacPolicyModal from './DeleteRbacPolicy';
import EditRbacPolicyModal from './EditRbacPolicyModal';
import { format } from '@baffle/utilities/src/date';
import { RbacPolicyStore } from '@baffle/manager/src/stores';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';
import usePageHeight from '@baffle/utilities/src/usePageHeight';

const RbacPolicies = () => {
    const history = useHistory();
    const { run, status } = useAwait();
    const [deleteRbacPolicyId, setDeleteRbacPolicyId] = useState('');
    const [editRbacPolicyId, setEditRbacPolicyId] = useState('');
    const [deleteRbacPolicyName, setDeleteRbacPolicyName] = useState('');
    const [update, setUpdate] = useState(true);
    const pageHeight = usePageHeight() - 200;

    useEffect(() => {
        if (update === true) {
            run(RbacPolicyStore.readRbacPolicies());
        }
        setUpdate(false);
    }, [update]);

    const onSyncButtonClick = () => {
        setUpdate(true);
    };

    const headers = [
        {
            key: 'header1',
            header: t('rbac.rbacPolicy.header1'),
            sortable: true,
        },
        {
            key: 'header2',
            header: t('rbac.rbacPolicy.header2'),
            sortable: true,
        },
        {
            key: 'header3',
            header: t('rbac.rbacPolicy.header3'),
            sortable: true,
        },
        {
            key: 'header4',
            header: t('rbac.rbacPolicy.header4'),
            sortable: true,
        },
    ];

    const emptyCol = {
        id: '',
        header1: '',
        header2: '',
        header3: '',
        header4: '',
    };

    return (
        <Observer>
            {() => {
                const onAdd = () => {
                    history.push(`/rbac/rbac-policies/create`);
                };

                interface RbacPolicyModals {
                    editRbacPolicy: boolean;
                    deleteRbacPolicy: boolean;
                }
                const initialModalState = {
                    editRbacPolicy: false,
                    deleteRbacPolicy: false,
                };
                const [modals, setModals] = useManageModals<RbacPolicyModals>(initialModalState);

                const rbacPolicies: RbacPolicyV2[] = RbacPolicyStore.rbacPolicies;

                const rows =
                    status === 'loading' || status === 'idle'
                        ? //add  skeleton row on initial load
                          [...Array(Math.floor(1)).keys()].map(() => emptyCol)
                        : rbacPolicies.map((policy: RbacPolicyV2, i: number) => {
                              return {
                                  id: policy.id,
                                  header1: policy.name,
                                  header2: policy.defaultPermission,
                                  header3: policy.ruleRefs?.length,
                                  header4: format(policy?.audit.createdDate, 'MM-dd-yyyy'),
                                  overFlowMenu: [
                                      {
                                          name: t('main.edit'),
                                          onClick: () => {
                                              const rbacPolicy = RbacPolicyStore.get(policy.id);
                                              run(RbacPolicyStore.readRbacRules(policy.id));
                                              RbacPolicyStore.setSelectedRbacPolicy(rbacPolicy);
                                              setModals({ editRbacPolicy: true });
                                              setEditRbacPolicyId(policy.id);
                                          },
                                      },
                                      {
                                          name: t('main.delete'),
                                          onClick: () => {
                                              setModals({ deleteRbacPolicy: true });
                                              setDeleteRbacPolicyId(policy.id);
                                              setDeleteRbacPolicyName(policy.name);
                                          },
                                      },
                                  ],
                                  link: '/rbac/rbac-policy/' + policy.id,
                              };
                          });
                return (
                    <>
                        <div className="overflow-y-auto" style={{ height: pageHeight }}>
                            {modals.editRbacPolicy ? (
                                <EditRbacPolicyModal
                                    open={modals.editRbacPolicy}
                                    onClose={() => setModals({ editRbacPolicy: false })}
                                    id={editRbacPolicyId}
                                    onSuccess={(name: string) => {
                                        NotificationStore.push({
                                            kind: 'success',
                                            title: t('rbac.rbacPolicy.editRbacPolicySuccess', { name: name }),
                                            'data-testid': 'rbac-policy-update-success',
                                        });
                                        run(RbacPolicyStore.readRbacPolicies());
                                    }}
                                />
                            ) : null}

                            {modals.deleteRbacPolicy ? (
                                <DeleteRbacPolicyModal
                                    open={modals.deleteRbacPolicy}
                                    onClose={() => setModals({ deleteRbacPolicy: false })}
                                    id={deleteRbacPolicyId}
                                    name={deleteRbacPolicyName}
                                    onSuccess={(name: string) => {
                                        NotificationStore.push({
                                            title: t('rbac.rbacPolicy.deleteRbacPolicySuccess', { name: name }),
                                            kind: 'success',
                                            'data-testid': 'rbac-policy-delete-success',
                                        });
                                        run(RbacPolicyStore.readRbacPolicies());
                                    }}
                                />
                            ) : null}

                            <DataGrid
                                idPrefix="rbacPolicy"
                                rows={rows}
                                headers={headers}
                                showSearch={true}
                                onAdd={onAdd}
                                addButtonCaption={t('rbac.rbacPolicy.createButtonText')}
                                onSync={onSyncButtonClick}></DataGrid>
                        </div>
                    </>
                );
            }}
        </Observer>
    );
};

export default RbacPolicies;
