import client from '.';
import { t } from '@baffle/translate';
import { ApplicationInput, ApplicationPutBody } from '@baffle/graphql/src/models';
import { MAX_PAGE_SIZE } from '@baffle/graphql';

export async function createwithRLE(app: ApplicationInput) {
    if (app.recordLevel && !app.entitySchema) {
        throw new Error(t('application.missingEntitySchemaFile'));
    }
    return client('api/v1.0/application/ssl', {
        body: { entity: app.entitySchema, applicationReqDTO: serializeApplicationPayload(app) },
        method: 'POST',
        useForm: true,
    });
}

export async function create(app: ApplicationInput) {
    return client('api/v1.0/application', {
        body: serializeApplicationPayload(app),
        method: 'POST',
    });
}

const serializeApplicationPayload = ({
    appName,
    description,
    shields,
    db,
    keystore,
    recordLevel,
    wlc,
    mode,
    keyLength,
}: ApplicationInput) => {
    return { appName, description, shields, db, keystore, recordLevel, wlc, mode, keyLength };
};

export async function list() {
    return client(`v2/applications?page=0&size=${MAX_PAGE_SIZE}`);
}

export async function readV2(id: string) {
    return client(`v2/applications/${id}`);
}
export async function read(id: string) {
    return client(`api/v1.0/application/${id}`);
}

export async function update(id: string, body: ApplicationPutBody) {
    return client(`api/v1.0/application/${id}`, { body, method: 'PUT' });
}

export async function updateShield(queryParams: { appId: string; shieldId: string }) {
    return client(`api/v1.0/application/add/shield`, { queryParams, method: 'POST' });
}

export async function removeShield(queryParams: { appId: string; shieldId: string }) {
    return client(`api/v1.0/application/remove/shield`, { queryParams, method: 'POST' });
}

interface RemoveParams {
    force?: boolean;
}

export async function remove(id: string, params: RemoveParams) {
    return client(`api/v1.0/application/unenroll/${id}`, { method: 'DELETE', queryParams: params });
}

export async function refreshSyncId(id: string) {
    return client(`api/v1.0/application/${id}/refresh/syncId`, { method: 'PUT' });
}

export default {
    list,
    create,
    createwithRLE,
    read,
    readV2,
    update,
    updateShield,
    remove,
    removeShield,
    refreshSyncId,
};
