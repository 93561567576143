import { TYPE_NAMES, COLUMN } from '.';
import gql from 'graphql-tag';

export const GET_ENCRYPTION_SCHEMA = gql`
    query GetEncryptionSchema($id: String!) {
        schema(id: $id) @rest(type: "${TYPE_NAMES.EncryptionSchema}", path: "application/encschema/{args.id}", endpoint: "v1") {
            id
            columns
        }
    }
`;

export const GET_ENCRYPTION_DATABASES = gql`
    query GetEncryptionDatabases($id: String!) {
        encryption(id: $id)
            @rest(type: "${TYPE_NAMES.ApplicationBindingDTO}", path: "application/encryption/{args.id}", endpoint: "v1") {
            id @export(as: "id")
            databases {
                name
                touched
            }
            enable
            options
            chefNodeName
            newKeys
        }
    }
`;

export const GET_ENCRYPTION_DATABASE_TABLES = gql`
    query GetEncryptionDatabases($id: String!, $dbName: String!) {
        tables(id: $id, dbName: $dbName)
            @rest(
                type: "[${TYPE_NAMES.EncryptionDatabaseTable}]"
                path: "application/encryption/tables/{args.id}/{args.dbName}"
                endpoint: "v1"
            ) {
            name
            touched
            columns {
                ...Column
            }
        }
    }
    ${COLUMN.fragments.column}
`;

export const ADD_ENCRYPTION = gql`
    mutation AddEncryption($id: String!, $input: Object!, $formSerializer: any) {
        encryption(input: $input) @rest(type: "${TYPE_NAMES.ApplicationBindingDTO}", path: "application/encryption", method: "POST", endpoint: "v1"  bodySerializer: $formSerializer) {
            id
        }
    }
`;

export const GET_APPLICATION_ENCRYPTION_CONFIRMATION = gql`
    query GetApplicationEncryptionConfirmation($id: String!){
        application(id: $id) @rest(type: "${TYPE_NAMES.ApplicationDetails}", path: "application/{args.id}", endpoint: "v1") {
            id @export(as: "id")
            keystore @export(as: "keystoreId")
            db @export(as: "databaseId")
            appName
            migrations
                @rest(type: "${TYPE_NAMES.MigrationStatus}", path: "application/mgrstatus/{exportVariables.id}", endpoint: "v1") {
                status
                mgrTableList
            }
            databaseDetails(id: $id) @rest(type: "${TYPE_NAMES.Database}", path: "database/{exportVariables.databaseId}", endpoint: "v1") {
                id
                type
            }
        }
    }
`;
