import React, { useEffect } from 'react';
import { Observer } from 'mobx-react-lite';
import { t } from '@baffle/translate';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import Button from '@baffle/components/src/buttons/Button';
import EmailSettingsModal from './EmailSettingsModal';
import DeleteSmtpModal from './DeleteSmtpModal';
import { SystemEmailStore } from '@baffle/manager/src/stores';
import { SystemInfoEmail } from '@baffle/graphql/src/models';
import SkeletonText from '@baffle/components/src/skeleton/SkeletonText';
import { ToastStore } from '@baffle/manager/src/stores/ToastStore';
import { useManageModals } from '@baffle/components/src/modal/Modal';
import useAwait from '@baffle/api-client/src/useAwait';
import EditIcon from '@baffle/components/src/icons/EditIcon';

const ConfigureSMTP = () => {
    const { status, run } = useAwait();
    const loading = status === 'loading' || status === 'idle';

    useEffect(() => {
        run(SystemEmailStore.getSystemEmail());
    }, []);

    return (
        <Observer>
            {() => {
                interface ConfigureSmtpModals {
                    addSmtp: boolean;
                    deleteSmtp: boolean;
                }
                const initialModalState = {
                    addSmtp: false,
                    deleteSmtp: false,
                };
                const [modals, setModals] = useManageModals<ConfigureSmtpModals>(initialModalState);
                const systemEmail: SystemInfoEmail | null = SystemEmailStore.systemEmail;
                return (
                    <div className="flex-col pb-6">
                        {modals.addSmtp ? (
                            <EmailSettingsModal
                                open={modals.addSmtp}
                                existingInfo={Boolean(systemEmail)}
                                onClose={() => setModals({ addSmtp: false })}
                                onSuccess={(action: string) => {
                                    ToastStore.push({
                                        title: t('settings.emailConfigSuccess', {
                                            action: action,
                                        }),
                                        type: 'success',
                                    });
                                }}
                            />
                        ) : null}
                        {modals.deleteSmtp ? (
                            <DeleteSmtpModal
                                open={modals.deleteSmtp}
                                onClose={() => setModals({ deleteSmtp: false })}
                                onSuccess={() => {
                                    ToastStore.push({ title: t('settings.deleteEmailConfigSuccess'), type: 'success' });
                                }}
                            />
                        ) : null}
                        <div className="flex justify-between items-end">
                            <div className="flex flex-col">
                                <p className="text-lg uppercase font-bold mb-1">{t('settings.smtp')}</p>
                                <p className="text-sm text-gray-500">{t('settings.configureSMTP')}</p>
                            </div>
                            <div className="flex text-md items-center h-full">
                                <Button
                                    theme={systemEmail ? 'disabled' : 'primary'}
                                    className="h-full w-24 uppercase justify-between flex-row-reverse"
                                    id="add-smtp-btn"
                                    aria-label={t('settings.ariaAddSMTP')}
                                    disabled={Boolean(systemEmail)}
                                    onClick={() =>
                                        setModals({
                                            addSmtp: true,
                                        })
                                    }>
                                    <AddIcon className="w-4 h-4" />
                                    {t('settings.smtp')}
                                </Button>
                            </div>
                        </div>

                        <table className="w-full mt-2 text-xsmall text-left">
                            <thead className="h-12 border-solid border border-gray-300 bg-gray-20 font-bold text-sm">
                                <tr>
                                    <th scope="col" className="p-4 w-1/3">
                                        {t('settings.hostName')}
                                    </th>
                                    <th scope="col" className="p-4 pl-24 w-1/3">
                                        {t('settings.port')}
                                    </th>
                                    <th scope="col" className="p-4 w-1/3">
                                        {t('settings.userName')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="h-12 border-solid border-l border-r border-b border-gray-300">
                                <tr className="bg-white">
                                    <td className="p-4 w-2/6">{loading ? <SkeletonText /> : systemEmail?.host}</td>
                                    <td className="p-4 pl-24 w-2/6 large:w-3/6">
                                        {loading ? <SkeletonText /> : systemEmail?.port}
                                    </td>
                                    <td className="p-4 w-2/6 large:w-1/6">
                                        <div className="flex justify-between items-center">
                                            {loading ? <SkeletonText /> : systemEmail?.username}
                                            {systemEmail ? (
                                                <div className="flex gap-x-4">
                                                    <button
                                                        id="edit-smtp-btn"
                                                        aria-label={t('settings.ariaEditSMTP')}
                                                        onClick={() =>
                                                            setModals({
                                                                addSmtp: true,
                                                            })
                                                        }>
                                                        <EditIcon className="w-4 h-4" />
                                                    </button>
                                                    <DeleteOutlineIcon
                                                        id="delete-smtp-btn"
                                                        aria-label={t('settings.ariaDeleteSMTP')}
                                                        onClick={() => {
                                                            setModals({
                                                                deleteSmtp: true,
                                                            });
                                                        }}
                                                        className="cursor-pointer config-menu-delete w-4 h-4"
                                                    />
                                                </div>
                                            ) : null}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                );
            }}
        </Observer>
    );
};

export default ConfigureSMTP;
