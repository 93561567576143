import { baffleApi, getAuthToken, signOut } from '@baffle/graphql';
import qs from 'qs';
import { t } from '@baffle/translate';
import { SystemEmailStore } from '../../manager/src/stores';

export interface GenericListPayload<T> {
    data: T[];
    hasMore: boolean;
}
export interface customOpts {
    body?: any;
    method?: 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH' | 'HEAD';
    headers?: any;
    queryParams?: any;
    customConfig?: any;
    useForm?: boolean;
    returnResponse?: boolean;
    responseContentType?: string;
    handleUnauthorized?: boolean;
    useFormWithJson?: boolean;
}

export default function client(
    endpoint: string,
    {
        body,
        customConfig = {},
        useForm = false,
        returnResponse = false,
        queryParams = {},
        responseContentType = 'application/json',
        headers = { 'Content-Type': 'application/json', Authorization: 'Bearer ' + getAuthToken() },
        method = 'GET',
        handleUnauthorized = true,
        useFormWithJson = false,
    }: customOpts = {}
) {
    if (endpoint[0] === '/') {
        endpoint = endpoint.substring(1, endpoint.length);
    }
    if (Object.keys(queryParams).length > 0) {
        endpoint = `${endpoint}?${qs.stringify(queryParams)}`;
    }
    const config: any = {
        method,
        headers: {
            ...headers,
        },
        ...customConfig,
    };

    if (body) {
        if (useForm) {
            //let the browser set the content type automagically, for w/e reason it doesnt work if you set it to multipart/form-data manually
            delete config.headers['Content-Type'];
            config.body = new FormData();

            Object.keys(body).forEach(k => {
                const entry = body[k];
                //handle files
                if (entry instanceof File || entry instanceof Blob) {
                    config.body.append(k, entry);
                } else {
                    const d = typeof entry === 'object' ? JSON.stringify(entry) : entry;
                    config.body.append(k, d);
                }
            });
        } else if (useFormWithJson) {
            delete config.headers['Content-Type'];
            config.body = new FormData();
            Object.keys(body).forEach(k => {
                const entry = body[k];
                //handle files
                if (entry instanceof File || entry instanceof Blob) {
                    config.body.append(k, entry);
                } else {
                    if (typeof entry === 'object') {
                        config.body.append(
                            k,
                            new Blob([JSON.stringify(entry)], {
                                type: 'application/json',
                            })
                        );
                    } else {
                        config.body.append(k, entry);
                    }
                }
            });
        } else {
            switch (config.headers['Content-Type']) {
                case 'application/x-www-form-urlencoded':
                    config.body = qs.stringify(body);
                    break;

                case 'application/json':
                default:
                    config.body = JSON.stringify(body);
                    break;
            }
        }
    }

    return window.fetch(`${baffleApi}/${endpoint}`, config).then(async response => {
        if (response.status >= 500) {
            //check system status for RAPI
            SystemEmailStore.getApplicationAccessData();
        }
        if (response.status === 401 && handleUnauthorized) {
            signOut();
            return;
        }
        try {
            //handle no content response
            if (response.status === 204) {
                return Promise.resolve();
            }
            //Our endpoint responses can be inconsistent
            if (!response.ok) {
                try {
                    //some endpoints respond with error message in json format
                    const res2 = response.clone();
                    const json = await res2.json();
                    if (json?.message) {
                        return Promise.reject(new Error(json.message));
                    }
                } catch {}

                let msg = await (response?.text ? response.text() : response.statusText);

                //handle errors for different endpoints
                switch (endpoint) {
                    case 'api/v1.0/database/ssl':
                        if (response.status === 412) {
                            msg = t('database.certInvalid');
                        }
                        break;
                    default:
                        break;
                }
                return Promise.reject(new Error(msg));
            }
            //Handle different expected response types
            let data: Promise<any>;
            switch (responseContentType) {
                case 'application/octet-stream':
                    data = response.blob();
                    break;
                case 'application/json':
                    const rawText = await response.text();
                    if (rawText.length) {
                        const rawData = JSON.parse(rawText);
                        data = Promise.resolve(rawData);
                    } else {
                        data = Promise.resolve({});
                    }
                    break;
                case 'text/plain':
                    data = response.text();
                    break;
                default:
                    return Promise.reject(
                        new Error(`${responseContentType} is not supported in the client. Please add it to the client.`)
                    );
            }
            if (response.ok) {
                if (returnResponse) {
                    return response;
                }
                return data;
            } else {
                return Promise.reject(data);
            }
        } catch (error) {
            // @ts-ignore
            if (window.Cypress && error.message.includes('Unexpected end of JSON input')) {
                return null;
                //This message will occur in FF for empty response bodies, which a lot of our endpoints have
            } else if (error.message.includes('unexpected end of data at line 1 column 1 of the JSON data')) {
                return null;
                //This message will occur in FF for empty response bodies, which a lot of our endpoints have
            } else if (error.message.includes('Unexpected end of JSON input')) {
                return null;
            } else {
                throw Promise.reject(error);
            }
        }
    });
}
