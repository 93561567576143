import React from 'react';

import { Dropdown, Breadcrumb, BreadcrumbItem } from 'carbon-components-react';

import { t } from '@baffle/translate';
import './pageheader.scss';

/*
    Examples:
    ---------

    Datastructure to pass for example:
        const actions = [
            {
                id: '1',
                label: 'Action 1',
            },
            {
                id: '2',
                label: 'Action 2',
            },
        ];
    
        const breadcrumbData = [
            {
                label: 'Link1',
                href: '#',
                onClick: () => {
                    console.log('Link1 Clicked');
                },
            },
            {
                label: 'Link2',
                href: '#',
                onClick: () => {
                    console.log('Link2 Clicked');
                },
            },
        ];

    Page header with title, breadcrumb and actions drop down:
    <PageHeader title="Masking Policies" actions={actions} breadcrumbData={breadcrumbData} onActionSelect={onSelectAction}/>

    Page header with title and breadcrumb:
    <PageHeader title="Masking Policies" breadcrumbData={breadcrumbData}/>

    Page header with Title and actions dropdown:
    <PageHeader title="Masking Policies" actions={actions} onActionSelect={onSelectAction}/>

    Page header with just title:
    <PageHeader title="Masking Policies"/>
*/

interface PageHeaderProps {
    title: string;
    actions?: { id: string; label: string }[];
    breadcrumbData?: { label: string; href: string; onClick?: () => void }[];
    onActionSelect?: (item: any) => void;
}

function PageHeader({ title, actions, breadcrumbData, onActionSelect }: PageHeaderProps) {
    let selectedAction = '';
    const handleActionsChange = (action: any) => {
        if (onActionSelect) {
            onActionSelect(action);
        }
        selectedAction = '';
    };
    return (
        <div className="px-8">
            {breadcrumbData && (
                <div className="pt-4">
                    <Breadcrumb>
                        {breadcrumbData?.map((item: any, i: any) => {
                            return (
                                <BreadcrumbItem href={item.href} key={i} onClick={item.onClick}>
                                    {item.label}
                                </BreadcrumbItem>
                            );
                        })}
                    </Breadcrumb>
                </div>
            )}
            <div className="relative flex pt-0 pb-8">
                <div className="flex-1 px-0 pt-0 flex justify-between">
                    <div className="flex-1 flex items-center">
                        <h1 className="text-heading-01 text-gray-100">{title}</h1>
                    </div>
                </div>
                {actions && (
                    <div className="ml-4 -mt-2 flex text-xl items-center h-full w-60">
                        <div className="flex-1 px-4 pt-3">
                            <Dropdown
                                className="page-header-dd"
                                ariaLabel="PageheaderDropdown"
                                id="page-header-actions"
                                items={actions}
                                label={t('pageHeader.actions')}
                                titleText=""
                                size="lg"
                                onChange={handleActionsChange}
                                selectedItem={selectedAction}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default PageHeader;
