import Joi from '@hapi/joi';
import { KeyValuePairsMap } from '@baffle/graphql/src/models';
import { t } from '@baffle/translate';
import { DatabaseProxiesStore } from '@baffle/manager/src/stores';

export function databaseProxyConfigScheme(advanceConfigurations: KeyValuePairsMap) {
    const configScheme = {
        ns: 'config',
        keys: ['key', 'value'],
        validationSchema: {
            key: Joi.string()
                .trim()
                .min(1)
                .max(30)
                .required()
                .custom((val, helper) => {
                    if (val !== DatabaseProxiesStore.advanceConfiguration?.key) {
                        if (
                            Object.entries(advanceConfigurations).some(
                                ([key, value]) => key.trim().toLowerCase() === val.trim().toLowerCase()
                            )
                        ) {
                            return helper.message({
                                custom: t('databaseProxies.databaseProxyConfigurations.duplicateConfigKeyError'),
                            });
                        }
                    }
                })
                .messages({
                    'string.max': t('main.invalidNameLength'),
                    'string.empty': t('databaseProxies.databaseProxyConfigurations.configKeyInputInvalid'),
                }),
            value: Joi.string()
                .trim()
                .min(1)
                .required()
                .messages({
                    'string.empty': t('databaseProxies.databaseProxyConfigurations.configValueInputInvalid'),
                }),
        },
    };
    return configScheme;
}

export function debugLevelConfigScheme() {
    const debugLevelScheme = {
        ns: 'debugLevelConfig',
        keys: ['debugLevel'],
        validationSchema: {
            debugLevel: Joi.string()
                .min(1)
                .required(),
        },
    };
    return debugLevelScheme;
}

export function rleConfigScheme() {
    const rleConfigScheme = {
        ns: 'rleConfigScheme',
        keys: ['rle'],
        validationSchema: {
            rle: Joi.boolean().required(),
        },
    };
    return rleConfigScheme;
}

export function rbacConfigScheme() {
    const rbacConfigSchemes = {
        SESSION: {
            ns: 'rbacConfig',
            keys: ['mode', 'userDetermination'],
            validationSchema: {
                mode: Joi.string()
                    .min(1)
                    .required(),
                userDetermination: Joi.string()
                    .min(1)
                    .required(),
            },
        },
        SQL_COMMENT_RAW: {
            ns: 'rbacConfig',
            keys: ['mode', 'userDetermination', 'sqlCommentPrefix'],
            validationSchema: {
                mode: Joi.string()
                    .min(1)
                    .required(),
                userDetermination: Joi.string()
                    .min(1)
                    .required(),
                sqlCommentPrefix: Joi.string()
                    .min(1)
                    .required()
                    .pattern(new RegExp(/[^'"%&();<>+]+$/))
                    .messages({
                        'string.pattern.base': t('encryption.modeLibrary.rbac.rbacConfig.sqlCommentInvalidError'),
                        'string.empty': t('encryption.modeLibrary.rbac.rbacConfig.sqlCommentEmptyError'),
                    }),
            },
        },
        SQL_COMMENT_JWT: {
            ns: 'rbacConfig',
            keys: ['mode', 'userDetermination', 'sqlCommentPrefix', 'jwtSecretKey', 'jwksProviderUrl', 'jwtClaims'],
            defaultValues: {
                jwtClaims: [],
            },
            validationSchema: {
                mode: Joi.string()
                    .min(1)
                    .required(),
                userDetermination: Joi.string()
                    .min(1)
                    .required(),
                sqlCommentPrefix: Joi.string()
                    .min(1)
                    .required()
                    .pattern(new RegExp(/[^'"%&();<>+]+$/))
                    .messages({
                        'string.pattern.base': t('encryption.modeLibrary.rbac.rbacConfig.sqlCommentInvalidError'),
                        'string.empty': t('encryption.modeLibrary.rbac.rbacConfig.sqlCommentEmptyError'),
                    }),
                jwtSecretKey: Joi.string()
                    .pattern(new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{10,}$'))
                    .required()
                    .allow(null, '')
                    .error(new Error(t('setup.secretInputInvalid'))),
                jwksProviderUrl: Joi.string()
                    .required()
                    .allow(null, ''),
                jwtClaims: Joi.array()
                    .items(
                        Joi.object({
                            key: Joi.string()
                                .trim()
                                .min(1),
                            value: Joi.string()
                                .trim()
                                .min(1),
                            jwtClaimData: Joi.string()
                                .trim()
                                .min(3)
                                .pattern(new RegExp(/(\w+):(\w+)/))
                                .messages({ 'string.empty': t('encryption.modeLibrary.rbac.jsonFieldEmptyError') }),
                        })
                    )
                    .unique('key')
                    .messages({ 'string.empty': t('encryption.modeLibrary.rbac.jsonFieldEmptyError') }),
            },
        },
    };
    return rbacConfigSchemes;
}
