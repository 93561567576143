import { Associations, Audit } from './encryption';

export type datatypeGroup =
    | 'BOOLEAN'
    | 'INTEGER'
    | 'FLOAT'
    | 'DECIMAL'
    | 'STRING'
    | 'DATE'
    | 'TIME'
    | 'DATETIME'
    | 'SMALL_DATETIME'
    | 'DATETIME_OFFSET'
    | 'TIME_STAMP_TZ'
    | 'YEAR'
    | 'UUID'
    | 'STRING'
    | 'BINARY';

export interface MaskingPolicy {
    id: string;
    name: string;
    description?: string;
    mode: string;
    value: string;
    associations: Associations[];
    datatypeGroup: datatypeGroup;
    isDefault?: boolean;
    audit: Audit;
}

export const DatatypeGroup: { [key in datatypeGroup]: string[] } = {
    STRING: ['FIXED', 'PATTERN', 'NUMERIC', 'CHARACTER'],
    BINARY: ['FIXED', 'NUMERIC', 'CHARACTER'],
    BOOLEAN: ['FIXED'],
    INTEGER: ['FIXED'],
    FLOAT: ['FIXED'],
    DECIMAL: ['FIXED'],
    DATE: ['FIXED'],
    TIME: ['FIXED'],
    DATETIME: ['FIXED'],
    SMALL_DATETIME: ['FIXED'],
    DATETIME_OFFSET: ['FIXED'],
    TIME_STAMP_TZ: ['FIXED'],
    YEAR: ['FIXED'],
    UUID: ['FIXED'],
};
