export default function a11yClickEvent(fn: any) {
    if (typeof fn !== 'function') {
        throw new Error(`a11yClickEvent higher order function can only be applied to methods not: ${typeof fn}`);
    }
    return function actionHandler(this: any, event: any) {
        if (
            !event ||
            event.type === 'click' ||
            (['keydown', 'keypress'].indexOf(event.type) > -1 && ['Enter', ' '].indexOf(event.key) > -1)
        ) {
            fn.call(this, event);
        }
    };
}
