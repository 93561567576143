import React, { useEffect, useState } from 'react';
import Header from '@baffle/components/src/nav/Header';
import Content from '@baffle/components/src/content/Content';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@baffle/components/src/buttons/Button';
import { useHistory, useParams } from 'react-router-dom';
import {
    MigrationPlan,
    EncryptionSchema,
    MigrationPlanInput,
    DeploymentState,
    MigrationStatus,
    ApplicationDetailsV2,
    ApplicationBindingDTO,
} from '@baffle/graphql/src/models';
import EncryptionConfirmationTable from './EncryptionConfirmationTable';
import { t } from '@baffle/translate';
import './EncryptionConfirmation.scss';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import { Observer } from 'mobx-react-lite';
import { EncryptionStore, ApplicationStore, MigrationStore, MigrationPlanStore, ShieldStore } from '../../stores';
import migrationPlanClient from '@baffle/api-client/src/migrationPlanClient';
import { ToastStore } from '../../stores/ToastStore';
import encryptionClient from '@baffle/api-client/src/encryptionClient';
import MigrationSettingsPanel from './MigrationSettingsPanel';
import { autorun } from 'mobx';
import useAwait from '@baffle/api-client/src/useAwait';
import {
    compileColumnDTOPayload,
    compileEncryptionPayload,
} from '../../../../utilities/src/migration/compileColumnDTOPayload';
import { migrationPlanHasChanged } from '../../../../utilities/src/migration/migrationUtils';
import Joi from '@hapi/joi';
import SpinnerOverlay from '@baffle/components/src/loader/SpinnerOverlay';

const EncryptionConfirmation = () => {
    const { id } = useParams() as { id: string | undefined };
    const history = useHistory();

    const [deploymentPlan, setDeploymentPlan] = useState<DeploymentState>('save_draft');
    const [migrationShield, setMigrationShield] = useState('');
    const [saving, setSaving] = useState(false);

    if (!id) {
        return null;
    }

    const { status, run, error } = useAwait();

    useEffect(() => {
        if (error) {
            ToastStore.push({ type: 'danger', title: t('main.unexpectedError') });
        }
    }, [error]);

    const { inputState, inputActions, inputErrors } = useInputState({
        keys: ['migrationType', 'migrationTargetDB', 'batch', 'batchSize', 'failScope', 'parallel', 'cleanTmp'],
        ns: 'encryption',
        defaultValues: {
            migrationType: 'table_to_table',
            batch: true,
            batchSize: 2000,
            failScope: 'SERVER',
            parallel: true,
            cleanTmp: false,
        },
        validationSchema: {
            batch: Joi.boolean().required(),
            batchSize: Joi.number()
                .min(1)
                .max(100000)
                .when('.batch', { is: true, otherwise: Joi.number() })
                .error(new Error(t('encryption.migration.batchSizeInvalid'))),
        },
    });
    const isValid = inputActions.validateAll(true);

    return (
        <Observer>
            {() => {
                //TODO: cleanup use of initialized in favor of status from useAwait
                const [initialized, setInitialized] = useState(false);
                const loading = status === 'loading' || status === 'idle';
                const application = ApplicationStore.get(id) as ApplicationDetailsV2;

                const shields = ShieldStore.shields.filter(s => (application?.shields ?? []).includes(s.id));
                const migration = MigrationStore.get(id) as MigrationStatus;
                const migrationPlan = MigrationPlanStore.get(id) as MigrationPlan;
                const isEnrolled = Boolean(migration?.status === 'NA');

                if (!initialized && !loading) {
                    setInitialized(true);
                }
                const selectedEncryptionColumns = EncryptionStore.selectedEncryptionColumns.filter(
                    d => d.application === application?.id
                );
                const applicationBindingDTO: ApplicationBindingDTO = {
                    //migration is set by selected column resolvers in resolvers.ts
                    columns: compileColumnDTOPayload(
                        'encryption',
                        EncryptionStore.schema as EncryptionSchema,
                        selectedEncryptionColumns,
                        deploymentPlan
                    ),
                    id,
                };

                useEffect(() => {
                    run(
                        Promise.all([
                            ApplicationStore.read(id as string),
                            EncryptionStore.getSchema(id as string),
                            MigrationStore.read(id),
                            MigrationPlanStore.read(id),
                            EncryptionStore.readDeployAndMigrate(applicationBindingDTO, 'encryption'),
                        ])
                    );
                    //This is how we can load other dependecies when application is loaded
                    return autorun(() => {
                        if (application?.shields?.length) {
                            for (let i = 0; i < application.shields.length; i++) {
                                const shieldId = application.shields[i];
                                ShieldStore.read(shieldId);
                            }
                        }
                    });
                }, []);

                const doHandleEncrypt = async () => {
                    setSaving(true);
                    const encPayload = compileEncryptionPayload(
                        deploymentPlan,
                        EncryptionStore.schema as EncryptionSchema,
                        selectedEncryptionColumns,
                        id,
                        'encryption',
                        'createSchema',
                        migrationShield
                    );
                    try {
                        //handle migration
                        if (deploymentPlan === 'save_migrate') {
                            if (isEnrolled) {
                                await migrationPlanClient.create(
                                    application.id,
                                    (inputState as unknown) as MigrationPlanInput
                                );
                            } else if (
                                migrationPlanHasChanged(migrationPlan, inputState) ||
                                migrationPlan.migrationType === 'server_to_server'
                            ) {
                                await migrationPlanClient.create(application.id, {
                                    //Update migration type if applicable
                                    migrationType:
                                        migrationPlan.migrationType === 'server_to_server'
                                            ? 'table_to_table'
                                            : migrationPlan.migrationType,
                                    batch: inputState.batch as boolean,
                                    batchSize: inputState.batchSize as number,
                                    failScope: inputState.failScope as string,
                                    parallel: inputState.parallel as boolean,
                                    cleanTmp: inputState.cleanTmp as boolean,
                                });
                            }
                        }

                        await encryptionClient.createSchema(encPayload);
                        let toastTitle = t('encryption.encryptionSuccess');
                        switch (deploymentPlan) {
                            case 'save_deploy':
                                toastTitle = t('encryption.deploySuccess');
                                break;
                            case 'save_draft':
                                toastTitle = t('encryption.saveStoreSuccess');
                                break;

                            default:
                                break;
                        }
                        ToastStore.push({
                            type: 'success',
                            title: toastTitle,
                        });
                        EncryptionStore.resetSelected({ actionType: 'encryption' });
                        history.replace('/applications');
                    } catch (error) {
                        ToastStore.push({ type: 'danger', title: JSON.parse(error.message).errors.join(', ') });
                        console.error(error);
                    } finally {
                        setSaving(false);
                    }
                };

                return (
                    <>
                        {saving && <SpinnerOverlay description={t('encryption.migration.' + deploymentPlan)} />}
                        <Content>
                            <Header>
                                <div className="flex-1 px-4 flex justify-between items-center">
                                    <h1 className="text-heading-01">
                                        <span className="text-gray-100">{t('encryption.confirmationTitle')}:</span>{' '}
                                        {application?.appName}
                                    </h1>
                                </div>
                                <button
                                    onClick={() => {
                                        EncryptionStore.resetSelected({
                                            actionType: 'encryption',
                                        });
                                        history.replace('/applications');
                                    }}
                                    className="bx--btn bx--btn--icon-only-transparent mx-4"
                                    aria-label={t('encryption.ariaCloseEncryption')}>
                                    <CloseIcon />
                                </button>
                            </Header>
                            <div className="flex p-4">
                                <MigrationSettingsPanel
                                    deploymentPlan={deploymentPlan}
                                    setDeploymentPlan={setDeploymentPlan}
                                    migrationShield={migrationShield}
                                    setMigrationShield={setMigrationShield}
                                    isEnrolled={isEnrolled}
                                    loading={loading}
                                    inputActions={inputActions}
                                    inputState={inputState}
                                    inputErrors={inputErrors}
                                    application={application}
                                    shields={shields}
                                    migrationPlan={migrationPlan}
                                />
                                <div
                                    id="encryption-confirmation-grid"
                                    className="confirmation-datagrid-container flex-1">
                                    <div className="flex w-full bg-white border-solid border border-gray-300 py-2 px-3">
                                        <p className="font-thin uppercase">
                                            <span className="text-gray-100 font-bold">
                                                {selectedEncryptionColumns.length}
                                            </span>{' '}
                                            {t('encryption.columnsSelected')}
                                        </p>
                                    </div>
                                    <EncryptionConfirmationTable
                                        type="encryption"
                                        selectedColumns={selectedEncryptionColumns}
                                        loading={loading && !initialized}
                                    />
                                </div>
                            </div>
                            <div className="flex w-full justify-end items-center bg-white border-t-2 border-solid border-gray-300 h-20">
                                <Button
                                    theme="tertiary"
                                    className="w-48 h-full"
                                    onClick={() => {
                                        history.push(`/application/encryption/${id}`);
                                    }}
                                    size="lg">
                                    {t('main.back')}
                                </Button>
                                <Button
                                    theme="secondary"
                                    className="w-48 h-full"
                                    onClick={() => {
                                        EncryptionStore.resetSelected({
                                            actionType: 'encryption',
                                        });
                                        history.replace('/applications');
                                    }}
                                    size="lg">
                                    {t('main.cancel')}
                                </Button>
                                <Button
                                    id="do-encrypt-btn"
                                    className="w-48 h-full"
                                    theme={
                                        selectedEncryptionColumns.length === 0 || (!isValid && saving)
                                            ? 'disabled'
                                            : 'primary'
                                    }
                                    onClick={() => {
                                        doHandleEncrypt();
                                    }}
                                    size="lg">
                                    {t('main.save')}
                                </Button>
                            </div>
                        </Content>
                    </>
                );
            }}
        </Observer>
    );
};

export default EncryptionConfirmation;
