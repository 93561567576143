import { ColumnMetaData, DataProtectionItem, JwtClaimInfo } from '@baffle/graphql/src/models';
import memoize from 'fast-memoize';

export function getDataTypeWithoutPrecisionValue(dataType: string): string {
    return dataType.split('(')[0] ?? '';
}

export function getDataProtection(col: ColumnMetaData) {
    let dataProtection = '';
    if (col.selections.jsonPolicy) {
        dataProtection = `${col.selections.jsonPolicy.name}`;
    }
    if (col.selections.rbacPolicy) {
        dataProtection = dataProtection
            ? `${dataProtection},${col.selections.rbacPolicy.name}`
            : `${col.selections.rbacPolicy.name}`;
    }
    if (col.selections.encryptionMode) {
        dataProtection = dataProtection
            ? `${dataProtection},${col.selections.encryptionMode.name}`
            : `${col.selections.encryptionMode.name}`;
    }
    if (col.selections.maskingMode) {
        dataProtection = dataProtection
            ? `${dataProtection},${col.selections.maskingMode.name}`
            : `${col.selections.maskingMode.name}`;
    }
    if (col.selections.policy) {
        dataProtection = dataProtection
            ? `${dataProtection},${col.selections.policy.name}`
            : `${col.selections.policy.name}`;
    }
    return dataProtection;
}

export const compileDataProtectionItems = memoize((items: DataProtectionItem[], type: string) => {
    const itemsCopy = JSON.parse(JSON.stringify(items));
    switch (type) {
        case 'encryption':
            itemsCopy.forEach((mode: DataProtectionItem) => (mode.type = 'encryption'));
            break;
        case 'mask':
            itemsCopy.forEach((mode: DataProtectionItem) => (mode.type = 'mask'));
            break;
        case 'policy':
            itemsCopy.forEach((mode: DataProtectionItem) => (mode.type = 'policy'));
            break;
        case 'jsonPolicy':
            itemsCopy.forEach((mode: DataProtectionItem) => (mode.type = 'jsonPolicy'));
            break;
        case 'rbacPolicy':
            itemsCopy.forEach((mode: DataProtectionItem) => (mode.type = 'rbacPolicy'));
            break;
    }
    return itemsCopy;
});

export const stringToJson = (val: string) => {
    const pairs = val.split('\n');
    return pairs.reduce((acc: any, pair: string) => {
        const keyValueArr = pair.split(':');
        const key = keyValueArr[0];
        let value;
        if (keyValueArr.length > 2) {
            keyValueArr.shift();
            value = keyValueArr.join(':');
        } else {
            value = keyValueArr[1];
        }
        acc[key.trim()] = value.trim();
        return acc;
    }, {});
};

export const jsonToString = (val: { [index: string]: string }) => {
    const str = Object.keys(val).reduce((acc: string, key: string) => {
        acc = acc.concat('\n', key.trim(), ':', val[key].trim());
        return acc;
    }, '');
    return str.trim();
};

export const jsonToArray = (val: { [index: string]: string }) => {
    const jwtClaims = new Array<JwtClaimInfo>();
    for (const [key, value] of Object.entries(val)) {
        let field: JwtClaimInfo = { key: key, value: value, jwtClaimData: key + ':' + value };
        jwtClaims.push(field);
    }
    return jwtClaims;
};

export const deselectDataProtectionItem = (item: DataProtectionItem, col: ColumnMetaData) => {
    switch (item.type) {
        case 'policy':
            col.selections.policy = null;
            break;
        case 'mask':
            col.selections.maskingMode = null;
            break;
        case 'encryption':
            col.selections.encryptionMode = null;
            break;
        case 'jsonPolicy':
            col.selections.jsonPolicy = null;
            break;
        case 'rbacPolicy':
            col.selections.rbacPolicy = null;
            break;
        default:
            break;
    }
};
