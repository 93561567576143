import React, { useState, useEffect } from 'react';
import { FileUploader } from 'carbon-components-react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@baffle/components/src/modal/Modal';
import Button from '@baffle/components/src/buttons/Button';
import { t } from '@baffle/translate';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import Alert from '@baffle/components/src/alerts/Alert';
import { Database } from '@baffle/graphql/src/models';
import databaseClient from '@baffle/api-client/src/databaseClient';
import SpinnerOverlay from '@baffle/components/src/loader/SpinnerOverlay';

interface UpdateDatabaseCertModalProps {
    database: Database;
    open: boolean;
    onClose?: () => void;
    onSuccess?: (inputState: Database) => void;
}

const UpdateDatabaseCertModal = (props: UpdateDatabaseCertModalProps) => {
    const [open, setOpen] = useState(props.open);
    const [teardownModal, setTeardownModal] = useState(false);
    const [postError, setPostError] = useState(null);
    const [loading, setLoading] = useState(false);

    const { inputState, inputActions } = useInputState({
        ns: 'database',
        defaultValues: {},
        keys: ['cert'],
        validationSchema: {},
    });

    useEffect(() => {
        //reset the initial database type and set the default value
        if (teardownModal) {
            inputActions.setField('cert', null);
        }
    }, [teardownModal]);

    //Sync state from props
    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const initClose = (clearState?: boolean) => {
        if (loading || !open) {
            return;
        }
        setOpen(false);
        //allow the modal to animate out
        setTimeout(() => {
            closeModal(clearState);
        }, 200);
    };
    const closeModal = (clearState?: boolean) => {
        if (loading) {
            return;
        }
        if (clearState) {
            inputActions.reset();
            setPostError(null);
            setTeardownModal(true);
            setTimeout(() => setTeardownModal(false), 0);
        }
        if (props.onClose) {
            props.onClose();
        }
    };
    const doUpdateDatabaseCert = async () => {
        setLoading(true);
        try {
            await databaseClient.updateCert(props.database.id as string, inputState.cert as File);
        } catch (error) {
            setPostError(JSON.parse(error.message).errors.join(', '));
            setLoading(false);
            return;
        }
        props.onSuccess && props.onSuccess(props.database);
        initClose(true);
        setLoading(false);
    };
    const isValid = inputActions.validateAll(true);
    //hack to remove the input components state
    if (teardownModal) {
        return null;
    }
    return (
        <Modal open={open} onClose={() => initClose(true)} aria-label="modal">
            {loading && <SpinnerOverlay description={t('database.uploadingDatabaseCert')} />}
            <ModalHeader onClose={() => initClose(true)}>
                <div className="flex items-start">
                    <div className="mt-0 ml-2">
                        <h3 className="text-xl font-medium text-gray-100" id="modal-headline">
                            {t('database.updateDatabaseCert')}
                        </h3>
                    </div>
                </div>
            </ModalHeader>
            <ModalBody>
                <>
                    {postError ? (
                        <div className="mb-6">
                            <Alert type="danger" title={t('database.updateCertError')} description={postError || ''} />
                        </div>
                    ) : null}
                    <div className="mb-6">
                        <Alert type="warning" description={t('database.updateCertWarning')} />
                    </div>
                    <div className="mt-6">
                        <p className="text-xs mb-2">{t('database.certInputLabel')}</p>
                        <FileUploader
                            buttonLabel={t('database.addCertBtn')}
                            id="db-cert-input"
                            aria-label={t('database.addCertBtn')}
                            accept={['.pem']}
                            name="db-cert-input"
                            buttonKind="tertiary"
                            filenameStatus="edit"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                inputActions.setField('cert', e.target.files ? e.target.files[0] : null)
                            }
                        />
                    </div>
                </>
            </ModalBody>
            <ModalFooter>
                <div className="flex justify-end items-center h-16 w-full">
                    <Button className="h-full w-1/2" theme="secondary" onClick={() => initClose(true)} size="lg">
                        {t('main.cancel')}
                    </Button>
                    <Button
                        className="h-full w-1/2"
                        theme={isValid ? 'primary' : 'secondary'}
                        id="do-add-database-cert-btn"
                        disabled={!isValid}
                        onClick={doUpdateDatabaseCert}
                        size="lg">
                        {t('main.save')}
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default UpdateDatabaseCertModal;
