import { Shield, ShieldForApplicationDetails } from './shield';
import { Database } from './database';
import { Keystore } from './keystore';
import { MigrationPlan, MigrationStatus, MigrationStatusForApplicationDetails } from './migration';
import { KeyLength } from './encryption';
import { GenericLink } from './generic';

export type EncType = 'Column Level' | 'Row Level';
export type EncMode = 'Standard' | 'Advanced';
export type ApplicationActions = 'CONFIG_FILES' | 'MIGRATION_DETAILS ' | 'ENCRYPT ' | 'DECRYPT ' | 'DELETE ';
export const ApplicationActionsEnum = {
    CONFIG_FILES: 'CONFIG_FILES',
    MIGRATION_DETAILS: 'MIGRATION_DETAILS',
    ENCRYPT: 'ENCRYPT',
    DECRYPT: 'DECRYPT',
    DELETE: 'DELETE',
};
export interface AppActionItem {
    action: ApplicationActions;
    link: string;
}

export interface AllowedActions {
    actions: AppActionItem[];
    messages: string[];
}

//Application V2 is used by the application list view and application details panel
export interface ApplicationV2 {
    id: string;
    name: string;
    desc: string;
    encType: EncType;
    encMode: EncMode;
    encColumns: number; //number of columns protected
    errorCount: number;
    keyLength: KeyLength;
    syncId: string;
    createTime: number;
    createdBy: string;
    keystoreDetails: GenericLink;
    databaseDetails: GenericLink;
    shieldDetails: {
        total: number;
        running: number;
        shields: ShieldForApplicationDetails[];
    };
    migrationDetails: MigrationStatusForApplicationDetails;
    configuration: {
        wlc: boolean;
        filterMode: number;
    };
    allowedActions: AllowedActions;
}

export interface ApplicationListItem {
    id: string;
    name: string; //same as appName in ApplicationDetails
    mode: string;
    desc: string;
    status: boolean; //status for the shield (whyyyyyy)
    totalColumns: number;
    shieldCount: number;
    protection: string[];
    unEnroll: boolean;
    uniqueId: string;
    shields?: string[];
    //properties compiled after data fetched
    alerts: number;
}

//Only used by graphql query...deprecrating
export interface Application extends ApplicationListItem {
    //properties that are resolved by graphql query
    details: ApplicationDetails;
    migrations: MigrationStatus;
}

//ApplicationCompiled is used for areas of the application that need to operate on the combined data for a given application
export interface ApplicationCompiled extends ApplicationListItem {
    details?: ApplicationDetailsV2;
    migrations?: MigrationStatus;
}

export interface ApplicationQueryRes {
    id: string;
    name: string;
    mode: string;
    desc: string;
    status: boolean;
    shieldCount: number;
    protection: string[];
    unEnroll: boolean;
    uniqueId: string;
    //properties resolved by graphql query
    details: ApplicationDetails;
    migrations: MigrationStatus;
}

export interface ApplicationDetailsV2 {
    id: string;
    appName: string;
    cloudStackName: string;
    db: string;
    dbType: string;
    description: string;
    enable: true;
    keystore: string;
    keyLength: string;
    lastKeyRotation: number;
    migrationType: string;
    protection: string[];
    recordLevel: boolean;
    uniqueId: string;
    createTime: number;
    createdBy: string;
    wlc: boolean;
    filterMode: number;
    wlcType: 'WLC_Advanced' | 'WLC_Simple';
    shields?: string[];
    shieldSyncID: string;
    restSyncID: string;
    fourLevel: boolean;
}

//ApplicationDetails is used for parts of tha application that use graphql.
export interface ApplicationDetails {
    id: string;
    appName: string;
    cloudStackName: string;
    db: string;
    dbType: string;
    description: string;
    enable: true;
    keystore: string;
    lastKeyRotation: number;
    migrationType: string;
    protection: string[];
    recordLevel: boolean;
    uniqueId: string;
    createTime: number;
    createdBy: string;
    wlc: boolean;
    filterMode: number;
    wlcType: 'WLC_Advanced' | 'WLC_Simple';
    //shields originally comes from the backend as a string array of shield ids
    //this is type patched via apollo client to be in the format of ApplicationDetailsShield
    shields?: ApplicationDetailsShield[];
    //resolved by graphql query
    migrationPlan?: MigrationPlan;
    migrations?: MigrationStatus;
    keystoreDetails?: Keystore;
    databaseDetails?: Database;
}

export interface ApplicationInput {
    entitySchema?: File;
    appName: string;
    description: string;
    shields: string[];
    db: string;
    keystore: string;
    keyLength: string;
    recordLevel: boolean;
    wlc: boolean;
    wlcType: string;
    mode: string;
}

export interface ApplicationPutBody extends Partial<ApplicationInput> {
    filterMode?: number;
}

export interface ApplicationDetailsShield {
    id: string;
    shieldInfo: Shield;
}

export interface ConfigFileShieldMetaData {
    shieldName: string;
    id: string;
}
export interface ConfigFileAssociations {
    appId: string;
    fileName: string;
    shields?: ConfigFileShieldMetaData[];
}

export type ConfigFiles = {
    [fileName: string]: string;
};

export type ConfigFilesForEditor = {
    [fileName: string]: {
        value: string;
        didChange: boolean;
    };
};

export interface AppConfigs {
    [appId: string]: ConfigFiles;
}

export interface ConfigIdentifierParams {
    appId: string;
    fileName: string;
    shieldId?: string;
}

interface ConfigFileNameMap {
    [key: string]: ConfigFileName;
}

export const ConfigFileNameEnum: ConfigFileNameMap = {
    BPS: 'BafflePrivacySchema',
    BCC: 'BaffleCommonConfig',
};

export type ConfigFileName =
    | 'BafflePrivacySchema'
    | 'BaffleEntitySchema'
    | 'BaffleCommonConfig'
    | 'BafflePerKeyConfig'
    | 'KmsConfig.properties'
    | 'PwdConfig.properties'
    | 'KmipConfig.xml'
    | 'BaffleSetupConfig';
