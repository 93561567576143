import { MigrationStatus } from '@baffle/graphql/src/models';
import client from '.';

export async function get(id: string) {
    const m: MigrationStatus = await client(`api/v1.0/application/mgrstatus/${id}`);
    //compile initial values and additional data
    if (!m) {
        return {
            totalRows: 0,
            mgrRows: 0,
            status: 'NA',
            mgrTableList: {},
            applicationID: id,
        };
    } else {
        m.totalRows = 0;
        m.mgrRows = 0;
    }

    if (m.mgrTableList === null) {
        m.mgrTableList = {};
    }

    Object.keys(m.mgrTableList).forEach((k: string) => {
        const table = m.mgrTableList[k];
        m.totalRows += table.totalRows;
        m.mgrRows += table.mgrRows;
    });
    m.applicationID = id;
    return m;
}

export async function createCancelMigration(id: string) {
    return client(`api/v1.0/application/${id}/migration/cancel`, { method: 'POST' });
}

export async function readCancelMigration(id: string) {
    return client(`api/v1.0/application/${id}/migration/cancel`);
}
export default { get, createCancelMigration, readCancelMigration };
