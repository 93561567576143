import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { BaffleIconProps } from '../componentTypes';

const SchemaIcon = ({
    color = '#000000',
    width = 24,
    height = 24,
    viewBox = '0 0 24 24',
    fontSize = 'small',
}: BaffleIconProps) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} fontSize={fontSize}>
            <g id="Group" transform="translate(3.000000, 3.000000)" fill="#000000" fillRule="nonzero">
                <path
                    d="M5,0 L5,5 L0,5 L0,0 L5,0 Z M3.5,1.5 L1.5,1.5 L1.5,3.5 L3.5,3.5 L3.5,1.5 Z"
                    id="Rectangle"></path>
                <path
                    d="M5,6.5 L5,11.5 L0,11.5 L0,6.5 L5,6.5 Z M3.5,8 L1.5,8 L1.5,10 L3.5,10 L3.5,8 Z"
                    id="Rectangle-Copy"></path>
                <path
                    d="M18,6.5 L18,11.5 L13,11.5 L13,6.5 L18,6.5 Z M16.5,8 L14.5,8 L14.5,10 L16.5,10 L16.5,8 Z"
                    id="Rectangle-Copy-3"></path>
                <polygon id="Line-2" points="13 8.5 13 9.5 5 9.5 5 8.5"></polygon>
                <path
                    d="M5,13 L5,18 L0,18 L0,13 L5,13 Z M3.5,14.5 L1.5,14.5 L1.5,16.5 L3.5,16.5 L3.5,14.5 Z"
                    id="Rectangle-Copy-2"></path>
                <polygon id="Line-2-Copy" points="9 2 9 3 5 3 5 2"></polygon>
                <polygon id="Line-2-Copy-2" points="9 15 9 16 5 16 5 15"></polygon>
                <polygon id="Line" points="10 2 10 16 9 16 9 2"></polygon>
            </g>
        </SvgIcon>
    );
};

export default SchemaIcon;
