import { CreateModal, CreateModalBody, CreateModalHeader } from '@baffle/components/src/CreateModal/CreateModal';
import { TextInput, Select as CarbonSelect, SelectItem } from 'carbon-components-react';
import { t } from '@baffle/translate';
import { InputActions } from '@baffle/utilities/src/inputHooks';
import React, { useEffect } from 'react';
import Select from 'react-select';
import { DataProtectionItem, RbacPolicyRuleV2 } from '@baffle/graphql/src/models';
import { MaskingPolicyStore, RbacPolicyStore, UserGroupStore } from '@baffle/manager/src/stores';
import useAwait from '@baffle/api-client/src/useAwait';

interface RbacPolicyRulesModalProps {
    open: boolean;
    onClose?: () => void;
    inputActions: InputActions;
    rule: RbacPolicyRuleV2;
    policyRules: Array<RbacPolicyRuleV2>;
    permissionItems: any[];
    isValid: boolean;
    datatypeGroup: string;
}

const RbacPolicyRulesModal = ({
    open,
    onClose = () => null,
    inputActions,
    rule,
    policyRules,
    permissionItems,
    isValid,
    datatypeGroup,
}: RbacPolicyRulesModalProps) => {
    const { run } = useAwait();
    const maskModeItems = MaskingPolicyStore.maskingPolicies.map(mode => ({
        id: mode.id,
        label: mode.name,
        datatypeGroup: mode.datatypeGroup,
        'data-testid': `policy-mask-li-${mode.name}`,
    }));
    const handlePolicyRule = (ruleKey: string, value: any, ruleIndex: number) => {
        const rule = policyRules[ruleIndex];
        const rules = policyRules;
        switch (ruleKey) {
            case 'userGroups':
                rule[ruleKey] = value.length
                    ? value.map((group: any) => ({
                          id: group.value,
                          name: group.label,
                      }))
                    : [];
                break;
            case 'maskPolicy':
                rule[ruleKey] = MaskingPolicyStore.maskingPolicies.find(
                    mode => mode.id === value
                ) as DataProtectionItem;
                break;
            case 'deleteRule':
                rules.splice(ruleIndex, 1);
                break;
            default:
                //@ts-ignore
                rule[ruleKey] = value;
        }
        inputActions.setField('rules', policyRules);
    };

    useEffect(() => {
        run(UserGroupStore.getRbacUserGroups());
    }, []);

    const AddRule = () => {
        RbacPolicyStore.setRbacPolicyRules(policyRules);
        setTimeout(() => {
            if (onClose) {
                onClose();
            }
        }, 200);
    };
    return (
        <CreateModal
            open={open}
            modalId={'create-rbac-rule-modal'}
            onClose={onClose}
            isValid={isValid}
            primaryButtonText={t('rbac.rbacPolicy.createButtonText')}
            primaryButtonTheme={'primary'}
            primaryButton={AddRule}>
            <CreateModalHeader titleText={t('rbac.rbacPolicy.createRuleModalHeading')}></CreateModalHeader>
            <CreateModalBody>
                <div className="flex flex-wrap mb-6 items-end">
                    <div className="flex-1 name-input-container">
                        <TextInput
                            id="rbac-rule-name-input"
                            data-testid={`rbac-rule-name-input`}
                            value={(rule.name as string) || ''}
                            labelText={t('main.name')}
                            placeholder={t('encryption.modeLibrary.rbac.rbacPolicy.ruleNamePlaceholder')}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                handlePolicyRule('name', val, policyRules.length - 1);
                            }}
                        />
                    </div>
                </div>
                <div className="flex flex-wrap mb-6 items-end">
                    <div className="flex-1 name-input-container">
                        <label className="mb-2 block text-xxs">{t('rbac.rbacPolicy.rulesHeader2')}</label>
                        {rule.userGroups ? (
                            <Select
                                inputId={`rbac-rule-user-groups-dropdown`}
                                value={rule.userGroups.map(group => ({
                                    value: group.id,
                                    label: group.name,
                                }))}
                                isMulti
                                maxMenuHeight={98}
                                name="rbac-rule-user-groups-dropdown"
                                options={UserGroupStore.rbacUserGroups.map(group => ({
                                    value: group.id as string,
                                    label: group.name,
                                    'data-testid': `policy-permission-li-${group.name}`,
                                }))}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                isClearable={false}
                                isSearchable={true}
                                placeholder={t('main.chooseOption')}
                                onChange={(selected: any) => {
                                    handlePolicyRule('userGroups', selected, policyRules.length - 1);
                                }}
                            />
                        ) : null}
                    </div>
                </div>
                <div className="flex flex-wrap mb-6 items-end">
                    <div className="flex-1 name-input-container">
                        <CarbonSelect
                            id="rbac-rule-permission"
                            labelText={t('rbac.rbacPolicy.rulesHeader3')}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                handlePolicyRule('permission', val, policyRules.length - 1);
                                handlePolicyRule('maskPolicy', null, policyRules.length - 1);
                            }}
                            defaultValue="placeholder-item">
                            <SelectItem disabled hidden text={t('main.chooseOption')} value="placeholder-item" />
                            {permissionItems.map((permissionItem, i) => {
                                return <SelectItem key={i} text={permissionItem.label} value={permissionItem.id} />;
                            })}
                        </CarbonSelect>
                    </div>
                </div>
                {rule.permission === 'MASK' ? (
                    <div className="flex flex-wrap w-full mb-6 items-start">
                        <div className="flex-1 relative">
                            <CarbonSelect
                                id="rbac-rule-mask-mode"
                                labelText={t('rbac.rbacPolicy.maskPolicy')}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const val = e.target.value || '';
                                    handlePolicyRule('maskPolicy', val as string, policyRules.length - 1);
                                }}
                                defaultValue="placeholder-item">
                                <SelectItem disabled hidden text={t('main.chooseOption')} value="placeholder-item" />
                                {maskModeItems
                                    .filter((maskMode: any) => {
                                        return datatypeGroup == maskMode.datatypeGroup;
                                    })
                                    .map((maskModeItem, i) => {
                                        return <SelectItem key={i} text={maskModeItem.label} value={maskModeItem.id} />;
                                    })}
                            </CarbonSelect>
                        </div>
                    </div>
                ) : null}
            </CreateModalBody>
        </CreateModal>
    );
};

export default RbacPolicyRulesModal;
