import React, { useState, useEffect } from 'react';
import { Dropdown, Checkbox, FileUploader } from 'carbon-components-react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@baffle/components/src/modal/Modal';
import TextInput from '@baffle/components/src/forms/TextInput';
import TextArea from '@baffle/components/src/forms/TextArea';
import Button from '@baffle/components/src/buttons/Button';
import { t } from '@baffle/translate';
import Divider from '@baffle/components/src/content/Divider';
import './AddDatabaseModal.scss';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import Joi from '@hapi/joi';
import { DatabaseEnums, DatabaseDefaultPorts } from '@baffle/utilities/src/enums';
import Alert from '@baffle/components/src/alerts/Alert';
import { Database, DatabaseInput, DatabaseType } from '@baffle/graphql/src/models';
import databaseClient from '@baffle/api-client/src/databaseClient';
import SpinnerOverlay from '@baffle/components/src/loader/SpinnerOverlay';

interface AddDatabaseModalProps {
    databases: Database[];
    open: boolean;
    onClose?: () => void;
    onSuccess?: (inputState: Database) => void;
    dbTypes: DatabaseType[];
}
const AddDatabaseModal = (props: AddDatabaseModalProps) => {
    const { databases, dbTypes } = props;
    const [open, setOpen] = useState(props.open);
    const [teardownModal, setTeardownModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [postError, setPostError] = useState(null);
    const { inputState, inputErrors, inputActions } = useInputState({
        keys: [
            'dbName',
            'description',
            'type',
            'hostName',
            'port',
            'dbUser',
            'bsConfigClientDBName',
            'dbPass',
            'ssl',
            'cert',
        ],
        ns: 'database',
        defaultValues: { ssl: false, bsConfigClientDBName: '' },
        validationSchema: {
            dbName: Joi.string()
                .min(1)
                .max(30)
                .custom(val => {
                    if (
                        databases.some((a: Database) => {
                            return a.dbName.trim().toLowerCase() === val.toLowerCase();
                        })
                    ) {
                        throw new Error(t('database.nameExists'));
                    }
                    return val;
                })
                .pattern(/^[a-zA-Z0-9_-\s]+$/)
                .required()
                .messages({
                    'string.max': t('main.invalidNameLength'),
                    'string.empty': t('database.dbNameInputInvalid'),
                    'string.pattern.base': t('database.noSpecialCharacters'),
                    'any.custom': t('database.nameExists'),
                }),
            type: Joi.string().required(),
            hostName: Joi.string().required(),
            port: Joi.number()
                .port()
                .required(),
            dbUser: Joi.string()
                .custom(val => {
                    if (
                        inputState.type === DatabaseEnums.AZURE_MSSQL &&
                        Boolean(inputState.dbUser) &&
                        inputState.dbUser !== ''
                    ) {
                        //@ts-ignore
                        if (inputState.dbUser.includes('@')) {
                            //@ts-ignore
                            const split = inputState.dbUser.split('@');
                            if (split[1] !== inputState.hostName) {
                                throw new Error(t('database.hostNameDoesNotMatch', { hostName: inputState.hostName }));
                            }
                        }
                    }
                    return val;
                })
                .min(1)
                .required()
                .messages({
                    'any.custom': 'database.hostNameDoesNotMatch',
                    'string.empty': 'database.dbUserInputInvalid',
                }),
            bsConfigClientDBName: Joi.string().allow(''),
            dbPass: Joi.string()
                .min(1)
                .required(),
        },
    });

    //automatically adds @hostName to dbUser if it is azure sql
    useEffect(() => {
        if (inputState.type === DatabaseEnums.AZURE_MSSQL && Boolean(inputState.dbUser) && inputState.dbUser !== '') {
            //@ts-ignore
            if (!inputState.dbUser.includes('@')) {
                inputActions.setField('dbUser', `${inputState.dbUser}@${inputState.hostName}`);
            } else {
                inputActions.validateField({ field: 'dbUser', skipNull: true });
            }
        }
    }, [inputState]);

    //Sync state from props
    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const closeModal = (clearState?: boolean) => {
        if (loading) {
            return;
        }
        if (clearState) {
            inputActions.reset();
            setTeardownModal(true);
            setPostError(null);
            setTimeout(() => setTeardownModal(false), 0);
        }
        setOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    };
    const doAddDatabase = async () => {
        setLoading(true);
        try {
            await databaseClient.create((inputState as unknown) as DatabaseInput);
            setLoading(false);
            props.onSuccess && props.onSuccess((inputState as unknown) as Database);
            closeModal(true);
        } catch (error) {
            setPostError(JSON.parse(error.message).errors.join(', '));
            setLoading(false);
            return;
        }
    };

    const isValid = inputActions.validateAll(true);
    //hack to remove the input components state
    if (teardownModal) {
        return null;
    }
    const databaseTypeItems = dbTypes.map((d: DatabaseType) => ({
        id: d.type,
        value: d.type,
        label: d.name,
    }));

    const getBsConfigClientDBLabel = () => {
        switch (inputState.type) {
            case DatabaseEnums.POSTGRES:
                return t('database.bsConfigClientDBNameInputLabel');
            case DatabaseEnums.AWS_REDSHIFT:
                return t('database.bsConfigClientRedshiftDBNameInputLabel');
            case DatabaseEnums.GREENPLUM:
                return t('database.bsConfigClientGreenplumDBNameInputLabel');
            case DatabaseEnums.MYSQL:
                return t('database.bsConfigClientMysqlDBNameInputLabel');
            default:
                return '';
        }
    };

    return (
        <Modal open={open} aria-label="modal">
            {loading && <SpinnerOverlay description={t('database.addingDatabase')} />}
            <ModalHeader onClose={() => closeModal(true)}>
                <div className="flex items-start">
                    <h3 className="text-xl font-medium text-gray-100" id="modal-headline">
                        {t('database.addModalHeading')}
                    </h3>
                </div>
            </ModalHeader>
            <ModalBody>
                <>
                    {postError ? (
                        <div className="flex flex-wrap">
                            <div className="flex-1">
                                <Alert type="danger" title={t('database.error')} description={postError || ''} />
                            </div>
                        </div>
                    ) : null}
                    <div className="flex flex-wrap mb-6 items-start">
                        <div className="flex-1 name-input-container">
                            <TextInput
                                data-testid="db-name-input"
                                name="db-name-input"
                                labelText={t('database.dbNameInputLabel')}
                                invalid={Boolean(inputErrors.dbName)}
                                invalidText={inputErrors.dbName}
                                placeholder={t('database.dbNamePlaceholder')}
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    inputActions.validateField({
                                        field: 'dbName',
                                        skipNull: true,
                                    });
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const val = e.target.value || '';
                                    inputActions.setField('dbName', val.trim());
                                }}
                            />
                            <span className="input-char-counter">
                                {(inputState.dbName as string)?.length ?? 0} / 30
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-wrap mb-6">
                        <div className="flex-1 relative">
                            <TextArea
                                name="db-description-input"
                                data-testid="db-description-input"
                                labelText={t('database.descriptionInputLabel')}
                                invalidText={t('database.descriptionInputInvalid')}
                                placeholder={t('database.descriptionPlaceholder')}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const val = e.target.value || '';
                                    inputActions.setField('description', val.trim());
                                    const maxLength = 100;
                                    if (val.length > maxLength) {
                                        e.target.value = val.slice(0, maxLength - 1);
                                    }
                                }}
                            />
                            <span className="input-char-counter">
                                {(inputState.description as string)?.length ?? 0} / 100
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-wrap mb0">
                        <div className="flex-1 px-4">
                            <Divider />
                        </div>
                    </div>
                    <div className="flex flex-wrap mb-6 items-start">
                        <div className="flex-1 pr-4">
                            <Dropdown
                                id="db-type-input"
                                data-testid="db-type-input"
                                titleText={t('database.typeInputLabel')}
                                label={t('main.chooseOption')}
                                initialSelectedItem={
                                    inputState.type ? databaseTypeItems.find(d => d.id === inputState.type) : null
                                }
                                onChange={({
                                    selectedItem,
                                }: {
                                    selectedItem: { id: string; value: string; label: string };
                                }) => {
                                    const fields: { port: number; type: string; ssl?: boolean } = {
                                        port: DatabaseDefaultPorts[selectedItem.id],
                                        type: selectedItem.id,
                                    };
                                    if (selectedItem.id === DatabaseEnums.AZURE_MSSQL) {
                                        fields.ssl = true;
                                    }
                                    inputActions.setFields(fields);
                                }}
                                items={databaseTypeItems}
                            />
                        </div>
                        <div className="flex flex-1 pl-4">
                            <div className="flex-1">
                                <TextInput
                                    data-testid="db-hostname-input"
                                    name="db-hostname-input"
                                    labelText={t('database.hostNameInputLabel')}
                                    invalid={Boolean(inputErrors.hostName)}
                                    invalidText={t('database.hostNameInputInvalid')}
                                    placeholder={t('database.hostNamePlaceholder')}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        inputActions.setField('hostName', e.target.value.trim() || '')
                                    }
                                    onBlur={() => {
                                        inputActions.validateField({ field: 'hostName', skipNull: true });
                                    }}
                                />
                            </div>
                            <div className="flex-1 pl-4 port-input-col">
                                <TextInput
                                    data-testid="db-port-input"
                                    name="db-port-input"
                                    labelText={t('setup.portInputLabel')}
                                    value={(inputState.port ?? '0') as string}
                                    arial-label={t('database.portInputLabel')}
                                    invalid={Boolean(inputErrors.port)}
                                    invalidText={t('database.portInputInvalid')}
                                    placeholder={t('database.portPlaceholder')}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        inputActions.setField('port', e.target.value);
                                    }}
                                    onBlur={() => {
                                        inputActions.validateField({ field: 'port', skipNull: true });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap mb-6 items-start">
                        <div className="flex-1 pr-4">
                            <TextInput
                                data-testid="db-username-input"
                                name="db-username-input"
                                labelText={t('database.dbUserInputLabel')}
                                invalidText={t(inputErrors.dbUser, { hostName: inputState.hostName })}
                                placeholder={t('database.dbUserPlaceholder')}
                                invalid={Boolean(inputErrors.dbUser)}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    inputActions.setField('dbUser', e.target.value.trim() || '')
                                }
                                onBlur={() => {
                                    inputActions.validateField({ field: 'dbUser', skipNull: true });
                                }}
                            />
                            {inputState.type === DatabaseEnums.POSTGRES ||
                            inputState.type === DatabaseEnums.AWS_REDSHIFT ||
                            inputState.type === DatabaseEnums.GREENPLUM ||
                            inputState.type === DatabaseEnums.MYSQL ? (
                                <div className="mt-6">
                                    <TextInput
                                        id="db-bsConfigClientDBName-input"
                                        data-testid="db-bsConfigClientDBName-input"
                                        name="db-bsConfigClientDBName-input"
                                        labelText={getBsConfigClientDBLabel()}
                                        invalidText={t(inputErrors.bsConfigClientDBName)}
                                        invalid={Boolean(inputErrors.bsConfigClientDBName)}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            inputActions.setField('bsConfigClientDBName', e.target.value.trim() || '');
                                        }}
                                        onBlur={() => {
                                            inputActions.validateField({
                                                field: 'bsConfigClientDBName',
                                                skipNull: true,
                                            });
                                        }}
                                    />
                                </div>
                            ) : null}
                        </div>
                        <div className="flex-1 pl-4">
                            <TextInput
                                id="db-credential-input"
                                data-testid="db-credential-input"
                                name="db-credential-input"
                                type="password"
                                labelText={t('database.dbPassInputLabel')}
                                invalidText={t('database.dbPassInputInvalid')}
                                placeholder={t('database.dbPassPlaceholder')}
                                invalid={Boolean(inputErrors.dbPass)}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    inputActions.setField('dbPass', e.target.value.trim() || '')
                                }
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap mb0">
                        <div className="flex-1 px-4">
                            <Divider />
                        </div>
                    </div>
                    <div className="flex flex-wrap mb-4">
                        <div className="flex-1">
                            <Checkbox
                                id="db-ssl-input"
                                data-testid="db-ssl-input"
                                labelText={t('database.useSSL')}
                                disabled={inputState.type === DatabaseEnums.AZURE_MSSQL}
                                checked={Boolean(inputState.ssl)}
                                onChange={() => inputActions.setField('ssl', !inputState.ssl)}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap w-full">
                        <FileUploader
                            disabled={!inputState.ssl as boolean}
                            buttonLabel={t('database.addFile')}
                            accept={['.pem']}
                            name="db-sslfile-input"
                            buttonKind="tertiary"
                            filenameStatus="edit"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                inputActions.setField('cert', e.target.files ? e.target.files[0] : null)
                            }
                        />
                    </div>
                </>
            </ModalBody>
            <ModalFooter>
                <div className="flex justify-end items-center h-16 w-full">
                    <Button className="h-full w-1/2" theme="secondary" onClick={() => closeModal(true)} size="lg">
                        {t('main.cancel')}
                    </Button>
                    <Button
                        className="h-full w-1/2"
                        theme={isValid && !loading ? 'primary' : 'disabled'}
                        id="do-add-database-btn"
                        data-testid="db-do-add-database-btn"
                        onClick={doAddDatabase}
                        size="lg">
                        {t('database.addDatabase')}
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
};
export default AddDatabaseModal;
