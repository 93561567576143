import React, { useState, useEffect, useRef } from 'react';
import { TextInput, InlineLoading } from 'carbon-components-react';
import Joi from '@hapi/joi';
import Button from '@baffle/components/src/buttons/Button';
import { t } from '@baffle/translate';
import { Link, useHistory } from 'react-router-dom';
import InlineToast, { ToastState, ToastType } from '@baffle/components/src/toasts/InlineToast';
import useEnterKeyPress from '@baffle/utilities/src/useEnterKeyPress';
import { SessionStore } from '../stores';
import FormInputStore from '../../../utilities/src/FormInputStore';
import { Observer } from 'mobx-react-lite';
import useAwait from '@baffle/api-client/src/useAwait';

const Login = () => {
    const { current: sessionForm } = useRef(
        new FormInputStore(
            {
                username: '',
                password: '',
            },
            {
                username: Joi.string()
                    .min(1)
                    .required()
                    .error(new Error(t('session.usernameInputInvalid'))),
                password: Joi.string()
                    .min(1)
                    .required()
                    .error(new Error(t('session.passwordInputInvalid'))),
            }
        )
    );
    const { run, status, error } = useAwait();
    const history = useHistory();
    const initialToastState: ToastState = {
        show: false,
        title: '',
        type: 'success',
    };
    const [toast, setToast] = useState(initialToastState);

    useEffect(() => {
        if (error) {
            setToast({ show: true, type: 'danger', title: error.message });
        }
    }, [error]);

    useEffect(() => {
        //@ts-ignore
        if (history?.location?.state?.show && history?.location?.state?.type && history?.location?.state?.title) {
            setToast({
                show: true,
                //@ts-ignore
                type: history.location.state.type as ToastType,
                //@ts-ignore
                title: history.location.state.title,
            });
        }
    }, [history.location]);

    const doSignIn = () => {
        run(
            SessionStore.validateLogin(sessionForm.values).then(() => {
                //trigger page refresh on success
                document.location.href = `${document.location.origin}/`;
            })
        );
    };

    useEnterKeyPress(() => {
        if (sessionForm.isValid) {
            doSignIn();
        }
    });

    return (
        <div className="w-120">
            <div className="bg-white p-12">
                {toast.show ? (
                    <InlineToast
                        className="mb-5"
                        duration={100000}
                        title={toast.title}
                        type={toast.type}
                        onDismiss={() => setToast({ title: '', show: false, type: 'success' })}
                    />
                ) : null}
                <Observer>
                    {() => {
                        return (
                            <TextInput
                                id={`session-username-input`}
                                labelText={t('session.usernameInputLabel')}
                                invalid={Boolean(sessionForm.errors.username)}
                                invalidText={sessionForm.errors.username}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const val = e.target.value || '';
                                    sessionForm.setValue('username', val.trim());
                                    sessionForm.validate({ field: 'username' });
                                }}
                            />
                        );
                    }}
                </Observer>
                <div className="mt-6 relative">
                    <Link to="/forgot" className="absolute pw-recovery-link text-blue">
                        {t('session.forgotPassword')}
                    </Link>
                    <Observer>
                        {() => {
                            return (
                                <TextInput
                                    id={`session-password-input`}
                                    labelText={t('session.passwordInputLabel')}
                                    invalid={Boolean(sessionForm.errors.password)}
                                    invalidText={sessionForm.errors.password}
                                    type="password"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const val = e.target.value || '';
                                        sessionForm.setValue('password', val.trim());
                                        sessionForm.validate({ field: 'password' });
                                    }}
                                />
                            );
                        }}
                    </Observer>
                </div>
                {status === 'loading' ? (
                    <InlineLoading className="my-6" description={t('session.signingIn')} />
                ) : (
                    <Observer>
                        {() => {
                            return (
                                <Button
                                    className="w-full h-12 mt-8"
                                    theme={sessionForm.isValid ? 'primary' : 'disabled'}
                                    id="do-login-btn"
                                    onClick={() => doSignIn()}>
                                    {t('session.signIn')}
                                </Button>
                            );
                        }}
                    </Observer>
                )}
            </div>
            <div className="disclaimer text-white mt-8">{t('session.loginDisclaimer')}</div>
            <div data-testid="copyright" className="copyright text-white my-12 text-center">
                {t('support.copyright', { year: new Date().getFullYear() })}
            </div>
        </div>
    );
};

export default Login;
