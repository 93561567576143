import React, { useState, useEffect, useMemo } from 'react';
import '../../Encryption.scss';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import { t } from '@baffle/translate';
import Alert from '@baffle/components/src/alerts/Alert';
import Button from '@baffle/components/src/buttons/Button';
import { DataFormatStore } from '@baffle/manager/src/stores';
import useAwait from '@baffle/api-client/src/useAwait';
import createJsonPolicyScheme from '../modeInputSchemes/createJsonPolicyScheme';
import TextInput from '@baffle/components/src/forms/TextInput';
import TextArea, { getDefaultInputProps } from '@baffle/components/src/forms/TextArea';
import { Observer } from 'mobx-react-lite';
import usePageHeight from '@baffle/utilities/src/usePageHeight';
import EditIcon from '@baffle/components/src/icons/EditIcon';
import Divider from '@baffle/components/src/content/Divider';
import useEscapeKeyPress from '@baffle/utilities/src/useEscapePress';
import useMouseClick from '@baffle/utilities/src/useMouseClick';
import cx from 'classnames';
import JsonPolicyHelp from './JsonPolicyHelp';
import JsonFields from './JsonFields';
import { DataProtectionItem, JsonKeyDetail, JsonPolicy } from '@baffle/graphql/src/models';
import { useParams } from 'react-router-dom';
import dataProtectionClient from '@baffle/api-client/src/dataProtectionClient';
import { ToastStore } from '../../../../stores/ToastStore';
import { useManageModals } from '@baffle/components/src/modal/Modal';
import WarningModal from './WarningModal';
import { getKeys } from '@baffle/utilities/src/dataProtectionUtils';
import JsonFieldsView from './JsonFieldsView';

const AddJsonPolicy = () => {
    const defaultEncMode = DataFormatStore.encryptionModes.find(mode => mode.name === 'AES-CTR-DET');
    return (
        <Observer>
            {() => {
                const { id: appId } = useParams() as { id: string };
                const [editMode, setEditMode] = useState(Boolean(false));
                const { run, error, reset, violations } = useAwait();
                const [validationError, setValidationError] = useState(0);
                const existingPolicy = DataFormatStore.selectedJsonPolicy;
                const jsonPolicyInputScheme = useMemo(
                    () => createJsonPolicyScheme(DataFormatStore.jsonPolicies, existingPolicy?.id),
                    [DataFormatStore.jsonPolicies, existingPolicy]
                );
                const [modals, setModals] = useManageModals<{ clearFields: boolean }>({ clearFields: false });
                const [showHelp, setShowHelp] = useState(false);
                useEscapeKeyPress(() => {
                    setShowHelp(false);
                });
                const [showBackBtn, setShowBackBtn] = useState(false);
                useMouseClick(() => {
                    setShowHelp(false);
                });
                const onSuccess = (name: string) => {
                    ToastStore.push({
                        type: 'success',
                        title: t('encryption.modeLibrary.jsonPolicy.jsonPolicyAddEditSuccess', {
                            name,
                        }),
                    });
                };
                //@ts-ignore
                const { inputState, inputErrors, inputActions } = useInputState(jsonPolicyInputScheme);
                const isValid = inputActions.validateAll(true);
                const handleParse = () => {
                    try {
                        inputActions.clearError('jsonPayload');
                        const jsonObj = JSON.parse((inputState.jsonPayload as string) || '');
                        const jsonKeys = [...new Set(getKeys(jsonObj))];
                        const jsonFields: JsonKeyDetail[] = jsonKeys.map(key => {
                            return {
                                jsonKeyName: key,
                                datatype: '',
                                encryptionMode: defaultEncMode as DataProtectionItem,
                                keyId: 2,
                            };
                        });
                        inputActions.setField('jsonFields', jsonFields);
                        setShowBackBtn(true);
                    } catch (error) {
                        inputActions.setError('jsonPayload', t('encryption.modeLibrary.jsonPolicy.jsonInvalidError'));
                    }
                };
                const [jsonFieldsErr, setJsonFieldsErr]: [any[], any] = useState([]);
                useEffect(() => {
                    if (violations) {
                        const errObj: any[] = [];
                        violations.map(violation => {
                            if (violation.fieldName.includes('jsonKeysList[')) {
                                const strArr = violation.fieldName.split('.');
                                let index = +strArr[0].substring(13, strArr[0].length - 1);
                                if (existingPolicy) {
                                    index = index - existingPolicy.jsonKeysList.length;
                                }
                                const key = strArr[1];
                                if (errObj[index]) {
                                    errObj[index][key] = violation.message;
                                } else {
                                    errObj[index] = {
                                        [key]: violation.message,
                                    };
                                }
                            }
                        });
                        setJsonFieldsErr(errObj);
                        setValidationError(violations.length);
                    } else {
                        setJsonFieldsErr({});
                    }
                }, [violations]);
                const handleSave = () => {
                    let payload: JsonPolicy = jsonPolicyInputScheme.keys.reduce((acc: any, c: any) => {
                        //This creates a new object mapping of keys from the input scheme
                        //to key/values from the inputState. And send only those values which are present
                        if (Boolean(inputState[c]) && c !== 'jsonPayload') {
                            if (c === 'jsonFields') {
                                acc['jsonKeysList'] = (inputState.jsonFields as Array<JsonKeyDetail>).map(field => {
                                    return {
                                        jsonKeyName: field.jsonKeyName,
                                        datatype: field.datatype,
                                        keyId: field.keyId,
                                        encryptionMode: field.encryptionMode,
                                    };
                                });
                            } else {
                                acc[c] = (inputState[c] as string).trim();
                            }
                        }
                        return acc;
                    }, {});
                    payload.appId = appId;
                    if (existingPolicy) {
                        payload.id = existingPolicy.id;
                    }
                    run(
                        (existingPolicy
                            ? dataProtectionClient.updateJsonPolicy(existingPolicy?.id as string, payload)
                            : dataProtectionClient.createJsonPolicy(payload)
                        ).then((jsonPolicy: JsonPolicy) => {
                            DataFormatStore.listAll(appId as string);
                            onSuccess(jsonPolicy.name);
                            inputActions.reset();
                            reset();
                            DataFormatStore.resetModeBuilder();
                        })
                    );
                };

                const handleClose = () => {
                    DataFormatStore.resetModeBuilder();
                    inputActions.reset();
                    reset();
                };

                const handleEdit = () => {
                    setEditMode(false);
                };

                //Sync state from props
                useEffect(() => {
                    inputActions.reset();
                    setShowBackBtn(false);
                    setValidationError(0);
                    reset();
                    if (existingPolicy) {
                        setEditMode(true);
                        const { name, description, jsonKeysList: jsonFields } = existingPolicy;
                        inputActions.setFields({
                            name,
                            description,
                            jsonFields: jsonFields,
                            jsonPayload: null,
                        });
                        if (existingPolicy.associations && existingPolicy.associations.length > 0) {
                            ToastStore.push({
                                type: 'success',
                                title: t('encryption.modeLibrary.jsonPolicy.jsonPolicyEditWarning'),
                                duration: 5000,
                            });
                        }
                    } else {
                        setEditMode(false);
                        inputActions.setFields({
                            jsonFields: [
                                {
                                    jsonKeyName: '',
                                    datatype: '',
                                    encryptionMode: defaultEncMode,
                                    keyId: 2,
                                },
                            ],
                            name: null,
                            description: null,
                            jsonPayload: null,
                        });
                    }
                    setJsonFieldsErr([]);
                }, [existingPolicy]);

                useEffect(() => {
                    let validationErrorCount = 0;
                    Object.keys(inputErrors).forEach(key => {
                        if (inputErrors[key] != null) {
                            validationErrorCount += 1;
                        }
                    });
                    if (violations) {
                        validationErrorCount += violations.length;
                    }
                    setValidationError(validationErrorCount);
                }, [inputErrors]);

                // When component is unmounted, reset the flag
                useEffect(() => {
                    return () => {
                        DataFormatStore.resetModeBuilder();
                        DataFormatStore.setShowJsonPolicyView(false);
                        inputActions.reset();
                    };
                }, []);

                const handleBack = () => {
                    inputActions.setFields({
                        jsonFields: [
                            {
                                jsonKeyName: '',
                                datatype: '',
                                encryptionMode: defaultEncMode,
                                keyId: 2,
                            },
                        ],
                        jsonPayload: '',
                    });
                    setShowBackBtn(false);
                    setModals({ clearFields: false });
                };
                const pageHeight = usePageHeight() - 120;
                useEffect(() => {
                    if (Boolean(error || validationError)) {
                        document.getElementById('parent-div-add-policy')?.scrollTo({ top: 0 });
                    }
                }, [error, validationError]);

                return (
                    <div className="flex-grow bg-white">
                        <div className="bg-gray-200 px-4 h-8 border-solid border-t-2 border-b-2 border-gray-300">
                            <div className="relative flex items-center h-full uppercase w-112 font-bold text-xs">
                                {existingPolicy ? (
                                    <div className="flex-1 flex justify-between items-center">
                                        <span>{t('encryption.modeLibrary.jsonPolicy.jsonPolicyBuilder')}</span>
                                        <button
                                            aria-label={t('main.edit')}
                                            onClick={handleEdit}
                                            data-testid="json-policy-edit-btn">
                                            <EditIcon />
                                        </button>
                                    </div>
                                ) : (
                                    <span>{t('encryption.modeLibrary.jsonPolicy.jsonPolicyBuilder')}</span>
                                )}
                            </div>
                        </div>
                        <div className="overflow-y-auto" style={{ maxHeight: pageHeight }} id="parent-div-add-policy">
                            <div className="w-120 mt-4 pl-4 mb-4">
                                {error && !violations ? (
                                    <div className="flex flex-wrap">
                                        <div className="flex-1">
                                            <Alert
                                                type="danger"
                                                title={t('encryption.modeLibrary.jsonPolicy.jsonPolicyError')}
                                                description={error.message ?? ''}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                                {validationError ? (
                                    <div className="flex flex-wrap">
                                        <div className="flex-1">
                                            <Alert
                                                type="danger"
                                                title={t('keystore.inputError', { count: validationError })}
                                                data-testid="alert-field-validation-error"
                                            />
                                        </div>
                                    </div>
                                ) : null}

                                <div>
                                    <div className="flex flex-wrap mb-6 items-end">
                                        <div className="flex-1 name-input-container">
                                            <TextInput
                                                id="policy-name-input"
                                                name="policy-name-input"
                                                data-testid="policy-name-input"
                                                labelText={t('encryption.modeLibrary.jsonPolicy.policyNameLabel')}
                                                placeholder={t(
                                                    'encryption.modeLibrary.jsonPolicy.policyNamePlaceholder'
                                                )}
                                                invalid={Boolean(inputErrors.name)}
                                                invalidText={inputErrors.name}
                                                value={(inputState.name as string) || ''}
                                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    inputActions.validateField({
                                                        field: 'name',
                                                        skipNull: true,
                                                    });
                                                }}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    const val = e.target.value || '';
                                                    inputActions.setField('name', val);
                                                }}
                                                disabled={editMode}
                                            />
                                            <span className="input-char-counter right-0">
                                                {(inputState.name as string)?.length ?? 0} / 30
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap mb-6">
                                        <div className="flex-1 relative">
                                            <TextArea
                                                id="description-input"
                                                name="description-input"
                                                data-testid="description-input"
                                                labelText={t('encryption.modeLibrary.rbac.rbacUsrGrp.descriptionLabel')}
                                                placeholder={t(
                                                    'encryption.modeLibrary.rbac.rbacUsrGrp.descriptionPlaceholder'
                                                )}
                                                invalid={Boolean(inputErrors.description)}
                                                invalidText={inputErrors.description}
                                                value={(inputState.description as string) || ''}
                                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    inputActions.validateField({
                                                        field: 'description',
                                                        skipNull: true,
                                                    });
                                                }}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    const val = e.target.value || '';
                                                    inputActions.setField('description', val);
                                                }}
                                                disabled={editMode}
                                            />
                                            <span className="input-char-counter right-0">
                                                {(inputState.description as string)?.length ?? 0} / 100
                                            </span>
                                        </div>
                                    </div>
                                    {!showBackBtn && !existingPolicy ? (
                                        <div>
                                            <p
                                                className="font-medium mt-2 text-xs"
                                                dangerouslySetInnerHTML={{
                                                    __html: t('encryption.modeLibrary.jsonPolicy.parserCopy'),
                                                }}
                                            />
                                            <br />
                                            <div className="flex flex-wrap mb-6">
                                                <div className="flex-1 relative">
                                                    <TextArea
                                                        id="json-payload-input"
                                                        name="json-payload-input"
                                                        data-testid="json-payload-input"
                                                        labelText={t(
                                                            'encryption.modeLibrary.jsonPolicy.jsonPayloadLabel'
                                                        )}
                                                        placeholder={t(
                                                            'encryption.modeLibrary.jsonPolicy.jsonPayloadPlaceholder'
                                                        )}
                                                        invalid={Boolean(inputErrors.jsonPayload)}
                                                        invalidText={inputErrors.jsonPayload}
                                                        value={(inputState.jsonPayload as string) || ''}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            inputActions.setField('jsonPayload', e.target.value || '');
                                                        }}
                                                        disabled={editMode}
                                                        getInputProps={() => {
                                                            const {
                                                                className: defaultClassName,
                                                            } = getDefaultInputProps();
                                                            return { className: cx(defaultClassName, 'h-40') };
                                                        }}
                                                    />
                                                    <JsonPolicyHelp
                                                        onRefClick={() => {
                                                            setShowHelp(!showHelp);
                                                        }}
                                                        showHelp={showHelp}
                                                    />
                                                </div>
                                                <div className="flex w-full justify-end h-6 mt-4">
                                                    <Button
                                                        theme="secondary"
                                                        className="w-14 font-medium text-xs"
                                                        id="json-policy-reset-btn"
                                                        onClick={() => {
                                                            inputActions.clearError('jsonPayload');
                                                            inputActions.setField('jsonPayload', '');
                                                        }}
                                                        disabled={!inputState.jsonPayload}
                                                        size="sm">
                                                        {t('encryption.modeLibrary.jsonPolicy.resetBtn')}
                                                    </Button>
                                                    <Button
                                                        className="w-14 font-medium ml-2 text-xs"
                                                        id="json-policy-parse-btn"
                                                        theme={!inputState.jsonPayload ? 'disabled' : 'tertiary'}
                                                        onClick={handleParse}
                                                        size="sm">
                                                        {t('encryption.modeLibrary.jsonPolicy.parseBtn')}
                                                    </Button>
                                                </div>
                                            </div>
                                            <Divider />
                                        </div>
                                    ) : null}
                                    {existingPolicy &&
                                    (editMode ||
                                        (existingPolicy.associations && existingPolicy.associations.length > 0)) ? (
                                        <JsonFieldsView jsonFields={existingPolicy?.jsonKeysList ?? []} />
                                    ) : (
                                        <JsonFields
                                            inputActions={inputActions}
                                            inputState={inputState}
                                            defaultEncMode={defaultEncMode}
                                            existingPolicy={existingPolicy}
                                            editMode={editMode}
                                            jsonFieldsErr={jsonFieldsErr}
                                        />
                                    )}

                                    {editMode ? (
                                        <div className="flex w-full justify-end h-12">
                                            <Button
                                                theme="primary"
                                                className="mt-4 w-24 uppercase font-semibold text-xs"
                                                onClick={handleEdit}
                                                data-testid="json-policy-edit-btn-form">
                                                {t('main.edit')}
                                            </Button>
                                        </div>
                                    ) : (
                                        <div className="flex">
                                            {showBackBtn ? (
                                                <div className="flex w-full h-8">
                                                    <Button
                                                        theme="tertiary"
                                                        className="py-2 w-24 font-semibold text-xs uppercase"
                                                        id="back-btn"
                                                        onClick={() => {
                                                            setModals({ clearFields: true });
                                                        }}>
                                                        {t('encryption.modeLibrary.jsonPolicy.backBtn')}
                                                    </Button>
                                                </div>
                                            ) : null}
                                            <div className="flex w-full justify-end h-8">
                                                <Button
                                                    theme="secondary"
                                                    className="py-2 w-24 font-semibold text-xs uppercase"
                                                    onClick={() => {
                                                        handleClose();
                                                    }}>
                                                    {t('main.cancel')}
                                                </Button>
                                                <Button
                                                    theme={isValid ? 'primary' : 'disabled'}
                                                    className="py-2 w-24 font-semibold ml-2 text-xs uppercase"
                                                    id="save-json-policy-btn"
                                                    onClick={handleSave}>
                                                    {t('main.save')}
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div>
                            <WarningModal
                                open={modals.clearFields}
                                onClose={() => {
                                    setModals({ clearFields: false });
                                }}
                                doClearFields={handleBack}
                            />
                        </div>
                    </div>
                );
            }}
        </Observer>
    );
};

export default AddJsonPolicy;
