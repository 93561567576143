import React, { useState, useEffect } from 'react';
import Button from '@baffle/components/src/buttons/Button';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@baffle/components/src/modal/Modal';
import { t } from '@baffle/translate';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import WarningIcon from '@material-ui/icons/Warning';

interface DeleteApplicationModalProps {
    open: boolean;
    onClose?: () => void;
    id?: string;
    name?: string;
    onSuccess: (inputState: { id: string; name: string }) => void;
}
const DeleteApplicationModal = (props: DeleteApplicationModalProps) => {
    const [open, setOpen] = useState(props.open);
    const [teardownModal, setTeardownModal] = useState(false);

    const { inputState } = useInputState({
        // keys: ['decrypt', 'migrate', 'downtime'],
        keys: ['migrate', 'downtime'],
        ns: 'application',
        //changing these to default to true while we hide the checks for now
        //see https://baffle.atlassian.net/browse/BM3-52

        defaultValues: { migrate: true, downtime: true },
        validationSchema: {},
    });

    //Sync state from props
    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const closeModal = (clearState?: boolean) => {
        if (clearState) {
            //TODO: reactivate when the checkboxes exist again
            // inputActions.reset();
            setTeardownModal(true);
            setTimeout(() => setTeardownModal(false), 0);
        }
        setOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    };
    const doDeleteApplication = () => {
        const { name, id } = props;
        props.onSuccess({ name: name as string, id: id as string });
        closeModal(true);
    };
    const isValid = inputState.migrate && inputState.downtime;
    //hack to remove the input components state
    if (teardownModal) {
        return null;
    }
    return (
        <Modal open={open} onClose={() => closeModal()} aria-label="modal">
            <ModalHeader onClose={() => closeModal(true)}>
                <div className="flex items-start">
                    <div className="flex items-center justify-center ml-2 delete-application-heading text-red">
                        <WarningIcon className="mr-2" />
                        <h3 className="text-xl font-medium" id="modal-headline">
                            {t('application.deleteApplicationModalHeading', { name: props.name })}
                        </h3>
                    </div>
                </div>
            </ModalHeader>
            <ModalBody>
                <>
                    <div className="flex flex-wrap items-start">
                        <div className="flex-1">
                            <p className="my-2">{t('application.deleteWarning')}</p>
                        </div>
                    </div>
                </>
            </ModalBody>

            <ModalFooter>
                <div className="flex justify-end items-center h-16 w-full">
                    <Button className="h-full w-1/2" theme="secondary" onClick={() => closeModal(true)} size="lg">
                        {t('application.cancelDelete')}
                    </Button>
                    <Button
                        className="h-full w-1/2"
                        theme={isValid ? 'danger' : 'disabled'}
                        id="do-delete-application-btn"
                        onClick={doDeleteApplication}
                        size="lg">
                        {t('application.confirmDelete')}
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
};
export default DeleteApplicationModal;
