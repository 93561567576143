import React from 'react';
import { Observer } from 'mobx-react-lite';
import Content from '@baffle/components/src/content/Content';
import Header from '@baffle/components/src/nav/Header';
import { t } from '@baffle/translate';
import MaskingPoliciesGrid from './MaskingPoliciesGrid';
import usePageHeight from '@baffle/utilities/src/usePageHeight';

const Masking = () => {
    const pageHeight = usePageHeight() - 136;
    return (
        <Observer>
            {() => {
                return (
                    <>
                        <Content>
                            <Header>
                                <div className="flex-1 my-5 px-10 flex justify-between">
                                    <div className="flex-1 flex items-center">
                                        <h1 className="text-heading-01 text-gray-100">
                                            {t('globalPolicies.maskingPolicy.maskingPolicies')}
                                        </h1>
                                    </div>
                                </div>
                            </Header>
                            <div className="overflow-y-auto" style={{ height: pageHeight }}>
                                <MaskingPoliciesGrid />
                            </div>
                        </Content>
                    </>
                );
            }}
        </Observer>
    );
};

export default Masking;
