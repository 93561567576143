import React, { useMemo } from 'react';
import { BaseCompoundComponentProps } from '../types/react';
import { BaseTheme } from '../types/theme';
import cx from 'classnames';

interface BadgeProps extends BaseCompoundComponentProps {
    getBadgeProps?: () => any;
    theme?: BaseTheme;
}

export const getDefaultBadgeProps: () => any = () => ({
    className: 'inline-flex items-center px-3 py-0 text-sm font-medium',
});

/**
 * 
 * example usage:
 * 
 * plain example, basically does next to nothing
 * <Badge>Is all you need</Badge>
 * 
 * 
 * //adds the teal theme
 * <Badge theme="teal">Running</Badge> 
 * 
 * 
 * //Does the same exact thing as the teal theme, except you have custom control over the className prop
 * //and any other property for that matter
  <Badge 
    getBadgeProps={() => {
    const badgeProps = {
        ...getDefaultBadgeProps(),
        className:
            'inline-flex items-center px-3 py-0 text-sm font-medium border-teal text-white bg-teal',
    };
    return badgeProps;
    }}>
        Running
    </Badge> 
 */

const Badge = ({ children, theme, getBadgeProps = getDefaultBadgeProps }: BadgeProps) => {
    const badgeProps = useMemo(() => getBadgeProps(), [getBadgeProps]);
    if (!badgeProps) {
        throw new Error('did not return badge props value from getBadgeProps');
    }
    const { className, ...props } = badgeProps;
    const css = cx(className, {
        'border-teal text-white bg-teal': theme === 'teal',
        'border-blue-70 text-white bg-blue-70': theme === 'blue',
        'border-green-60 text-white bg-green-60': theme === 'green',
        'border-red-60 text-white bg-red-60': theme === 'red',
        'border-black text-white bg-black': theme === 'black',
        'border-teal text-teal': theme === 'teal-ghost',
        'border-blue text-blue': theme === 'blue-ghost',
        'border-red text-red': theme === 'red-ghost',
        'border-black text-black': theme === 'black-ghost',
        'text-teal border-none': theme === 'teal-link',
        'text-blue border-none': theme === 'blue-link',
        'text-red border-none': theme === 'red-link',
        'text-black border-none': theme === 'black-link',
    });
    return (
        <span className={css} {...props}>
            {children}
        </span>
    );
};
export default Badge;
