import { makeObservable, action, observable } from 'mobx';
import { RequestOpts } from '../types/StoreTypes';

class BaffleAppStore {
    loading = false;
    setLoading = (loading: boolean) => {
        this.loading = loading;
    };

    constructor() {
        makeObservable(this, {
            loading: observable,
            setLoading: action,
        });
    }

    async get(id: string, opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first') {
            return;
        }
        const doFinally = () => {
            this.setLoading(false);
        };
        this.setLoading(true);
        try {
            // const mp = await migrationPlanClient.get(id);
            // this.setMigrationPlan(mp);
        } catch (error) {
            throw error;
        } finally {
            doFinally();
        }
    }
}

export default BaffleAppStore;
