import React, { useEffect } from 'react';
import { Dropdown, Checkbox } from 'carbon-components-react';
import TextInput from '@baffle/components/src/forms/TextInput';
import { t } from '@baffle/translate';
import { AWSRegionList } from '@baffle/utilities/src/enums';
import { DEKStorageTypeValues, DEKStorageType } from '@baffle/graphql/src/models';
import DefaultLink from '@baffle/components/src/links/DefaultLink';

const AddAWSKeystore = ({ inputState, inputErrors, inputActions }: any) => {
    const regionItems = AWSRegionList.map((region: string, i: number) => ({ id: region, label: region }));
    const dekItems = Object.values(DEKStorageType).map((key: DEKStorageType) => ({
        id: DEKStorageTypeValues[key],
        label: DEKStorageTypeValues[key],
    }));
    useEffect(() => {
        inputActions.setField('iamRole', false);
    }, []);
    return (
        <>
            <div className="flex flex-wrap mb-4 items-center">
                <div className="pr-4">
                    <Checkbox
                        id="ks-iamRole-input"
                        className="text-xs"
                        labelText={t('keystore.iamRoleInputLabel')}
                        checked={Boolean(inputState.iamRole)}
                        onChange={() =>
                            inputActions.setFields({
                                iamRole: !inputState.iamRole,
                                awsAccessKeyId: '',
                                awsSecretAccessKey: '',
                            })
                        }
                    />
                </div>
                <div className="pl-4 ml-8">
                    <DefaultLink url={t('keystore.iamPolicyLinkURL')} data-testid="sample-iam-policy-link">
                        {t('keystore.iamPolicyLink')}
                    </DefaultLink>
                </div>
            </div>

            {!inputState.iamRole ? (
                <div className="flex flex-wrap mb-6 items-start">
                    <div className="flex-1 pr-4">
                        <TextInput
                            id="ks-awsaccesskeyid-input"
                            name="ks-awsaccesskeyid-input"
                            labelText={t('keystore.awsAccessKeyIdInputLabel')}
                            invalidText={t('keystore.awsAccessKeyIdInputInvalid')}
                            placeholder={t('keystore.awsAccessKeyIdPlaceholder')}
                            type="text"
                            defaultValue={inputState.awsAccessKeyId}
                            invalid={Boolean(inputErrors.awsAccessKeyId)}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                inputActions.setField('awsAccessKeyId', e.target.value.trim() || '')
                            }
                        />
                    </div>
                    <div className="flex-1 pl-4">
                        <TextInput
                            id="ks-awssecretaccessKey-input"
                            name="ks-awssecretaccessKey-input"
                            labelText={t('keystore.awsSecretAccessKeyInputLabel')}
                            invalidText={t('keystore.awsSecretAccessKeyInputInvalid')}
                            placeholder={t('keystore.awsSecretAccessKeyPlaceholder')}
                            type="password"
                            defaultValue={inputState.awsSecretAccessKey}
                            invalid={Boolean(inputErrors.awsSecretAccessKey)}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                inputActions.setField('awsSecretAccessKey', e.target.value.trim() || '')
                            }
                        />
                    </div>
                </div>
            ) : null}

            <div className="flex flex-wrap mb-6 items-start">
                <div className="flex-1 pr-4">
                    <TextInput
                        id="ks-awsrootarn-input"
                        name="ks-awsrootarn-input"
                        labelText={t('keystore.awsRootArnInputLabel')}
                        placeholder={t('keystore.awsRootArnPlaceholder')}
                        type="text"
                        defaultValue={inputState.awsRootArn}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputActions.setField('awsRootArn', e.target.value.trim() || '')
                        }
                    />
                </div>
                <div className="flex-1 pl-4">
                    <Dropdown
                        id="ks-awsRegion-input"
                        name="ks-awsRegion-input"
                        titleText={t('keystore.awsRegionInputLabel')}
                        label={t('main.chooseOption')}
                        initialSelectedItem={
                            inputState.awsRegion ? regionItems.find(d => d.id === inputState.awsRegion) : null
                        }
                        onChange={({ selectedItem }: { selectedItem: { id: string; label: string } }) => {
                            inputActions.setField('type', selectedItem.id);
                            inputActions.setField('awsRegion', selectedItem.id);
                        }}
                        items={regionItems}
                    />
                </div>
            </div>
            <div className="flex flex-wrap mb-6 items-start">
                <div className="flex-1 pr-4">
                    <Dropdown
                        id="ks-dekStore-input"
                        titleText={t('keystore.dekStoreInputLabel')}
                        label={t('main.chooseOption')}
                        initialSelectedItem={
                            inputState.dekStore ? dekItems.find(d => d.id === inputState.dekStore) : null
                        }
                        onChange={({ selectedItem }: { selectedItem: { id: string; label: string } }) => {
                            inputActions.setField('dekStore', selectedItem.id);
                        }}
                        items={dekItems}
                    />
                </div>
                <div className="flex-1 pl-4">
                    <TextInput
                        id="ks-appnamespace-input"
                        name="ks-appnamespace-input"
                        labelText={t('keystore.appNamespaceInputLabel')}
                        placeholder={t('keystore.appNamespacePlaceholder')}
                        type="text"
                        invalid={Boolean(inputErrors.appNamespace)}
                        invalidText={t('keystore.appNamespaceInputInvalid')}
                        value={inputState.appNamespace ?? ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const value = e.target.value.trim() || '';
                            inputActions.setField('appNamespace', value);
                        }}
                        onBlur={() => {
                            inputActions.validateField({ field: 'appNamespace', skipNull: true });
                        }}
                    />
                </div>
            </div>
            <div className="flex flex-wrap mb-6 items-start">
                <div className="flex-1 pr-4">
                    <TextInput
                        id="ks-awss3bucket-input"
                        name="ks-awss3bucket-input"
                        labelText={t('keystore.awsS3BucketInputLabel')}
                        placeholder={t('keystore.awsS3BucketPlaceholder')}
                        type="text"
                        invalid={Boolean(inputErrors.awsS3Bucket)}
                        invalidText={t('keystore.awsS3BucketInputInvalid')}
                        defaultValue={inputState.awsS3Bucket}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputActions.setField('awsS3Bucket', e.target.value.trim() || '')
                        }
                        onBlur={() => {
                            inputActions.validateField({ field: 'awsS3Bucket', skipNull: true });
                        }}
                    />
                </div>
                <div className="flex-1 pl-4">
                    <TextInput
                        id="ks-awsKmsAlias-input"
                        name="ks-awsKmsAlias-input"
                        labelText={t('keystore.awsKmsAliasInputLabel')}
                        placeholder={t('keystore.awsKmsAliasPlaceholder')}
                        type="text"
                        invalid={Boolean(inputErrors.awsKmsAlias)}
                        invalidText={t('keystore.awsKmsAliasInputInvalid')}
                        value={inputState.awsKmsAlias ?? ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const value = e.target.value.trim() || '';
                            inputActions.setField('awsKmsAlias', value);
                        }}
                        onBlur={() => {
                            inputActions.validateField({ field: 'awsKmsAlias', skipNull: true });
                        }}
                    />
                </div>
            </div>
            <div className="flex flex-wrap mb-6 items-start">
                <div className="flex-1 pr-4">
                    <TextInput
                        id="ks-awsS3Endpoint-input"
                        name="ks-awsS3Endpoint-input"
                        labelText={t('keystore.awsS3EndpointLabel')}
                        placeholder={t('keystore.awsS3EndpointPlaceholder')}
                        type="text"
                        value={inputState.awsS3Endpoint ?? ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputActions.setField('awsS3Endpoint', e.target.value.trim() || '')
                        }
                    />
                </div>
                <div className="flex-1 pl-4">
                    <TextInput
                        id="ks-awsKMSEndpoint-input"
                        name="ks-awsKMSEndpoint-input"
                        labelText={t('keystore.awsKMSEndpointLabel')}
                        placeholder={t('keystore.awsKMSEndpointPlaceholder')}
                        type="text"
                        value={inputState.awsKMSEndpoint ?? ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputActions.setField('awsKMSEndpoint', e.target.value.trim() || '')
                        }
                    />
                </div>
            </div>
        </>
    );
};

export default AddAWSKeystore;
