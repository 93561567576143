import React from 'react';
import { t } from '@baffle/translate';
import Button from '@baffle/components/src/buttons/Button';

interface RbacConfigLandingProps {
    setAddNewConfig: (val: boolean) => void;
}

const RbacConfigLanding = ({ setAddNewConfig }: RbacConfigLandingProps) => {
    return (
        <div>
            <div className="flex flex-wrap mb-6 items-end">
                <div className="flex-1 name-input-container">
                    <p className="text-xs">{t('encryption.modeLibrary.rbac.rbacConfig.rbacConfigCopy')}</p>
                    <br />
                    <p
                        className="text-xs"
                        dangerouslySetInnerHTML={{
                            __html: t('encryption.modeLibrary.rbac.rbacConfig.rbacConfigCopy1'),
                        }}
                    />
                    <br />
                    <p
                        className="text-xs"
                        dangerouslySetInnerHTML={{
                            __html: t('encryption.modeLibrary.rbac.rbacConfig.rbacConfigCopy2'),
                        }}
                    />
                    <br />
                    <p
                        className="text-xs"
                        dangerouslySetInnerHTML={{
                            __html: t('encryption.modeLibrary.rbac.rbacConfig.rbacConfigCopy3'),
                        }}
                    />
                </div>
            </div>
            <div className="flex flex-wrap mb-6 items-start">
                <div className="flex-1">
                    <Button
                        theme="primary"
                        className="py-2 text-xs font-semibold uppercase"
                        id="start-rbac-config-btn"
                        onClick={() => {
                            setAddNewConfig(true);
                        }}>
                        {t('encryption.modeLibrary.rbac.rbacConfig.completeRbacConfig')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default RbacConfigLanding;
