import { t } from '@baffle/translate';
import { format } from '@baffle/utilities/src/date';
import React from 'react';

interface Info {
    [key: string]: any;
}

interface DatabaseProxyShieldRowDetailsProps {
    info: Info;
}

const DatabaseProxyShieldRowDetails = ({ info }: DatabaseProxyShieldRowDetailsProps) => {
    return (
        <div className="flex-1 py-8">
            <div className="flex flex-wrap w-full gap-y-6">
                {info &&
                    Object.entries(info).map(([fieldName, fieldValue]) => (
                        <div className="w-1/2 flex flex-row gap-x-2" key={`shield-info-${fieldName}`}>
                            <div className="text-gray-600 font-bold w-50">
                                {t(`databaseProxies.databaseProxyShields.${fieldName}`)}
                            </div>
                            <span>
                                {![null, undefined, ''].includes(fieldValue) ? (
                                    <>
                                        {fieldName == 'expiryDate'
                                            ? format(fieldValue, 'MM-dd-yyyy')
                                            : String(fieldValue)}
                                    </>
                                ) : (
                                    <>- -</>
                                )}
                            </span>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default DatabaseProxyShieldRowDetails;
