import Joi from '@hapi/joi';
import { KeystoreTypeEnum } from '@baffle/graphql/src/models';
import { t } from '@baffle/translate';
import createIBMKeyProtectScheme from './createIBMKeyProtectScheme';
import createIBMHpcsScheme from './createIBMHpcsScheme';

export default function createKeystoreInputScheme() {
    const keystoreInputSchemes = {
        [KeystoreTypeEnum.LOCAL]: {
            ns: 'keystore',
            keys: ['name', 'description', 'type', 'secretKey'],
            defaultValues: {
                type: KeystoreTypeEnum.LOCAL,
            },
            validationSchema: {
                name: Joi.string()
                    .min(1)
                    .max(30)
                    .pattern(/^[a-zA-Z0-9_-]+$/)
                    .required()
                    .messages({
                        'string.max': t('main.invalidNameLength'),
                        'string.pattern.base': t('keystore.nameInputInvalid'),
                        'string.empty': t('keystore.nameInputInvalid'),
                    }),
                type: Joi.string().required(),
                secretKey: Joi.string()
                    .pattern(new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{10,}$'))
                    .required()
                    .error(new Error(t('setup.secretInputInvalid'))),
            },
        },
        [KeystoreTypeEnum.SAFENET_KEY_SECURE]: {
            ns: 'keystore',
            keys: [
                'name',
                'description',
                'type',
                'appNamespace',
                'hostName',
                'hostPassword',
                'hostPort',
                'hostUser',
                'cert',
                'keyStoreAlias',
                'keyStorePassword',
            ],
            defaultValues: {
                hostPort: 5696,
            },
            validationSchema: {
                name: Joi.string()
                    .min(1)
                    .max(30)
                    .pattern(/^[a-zA-Z0-9_-]+$/)
                    .required()
                    .messages({
                        'string.max': t('main.invalidNameLength'),
                        'string.pattern.base': t('keystore.nameInputInvalid'),
                        'string.empty': t('keystore.nameInputInvalid'),
                    }),
                type: Joi.string().required(),
                hostName: Joi.string().required(),
                hostPort: Joi.number()
                    .port()
                    .required(),
                hostUser: Joi.string()
                    .min(1)
                    .required(),
                hostPassword: Joi.string()
                    .min(1)
                    .required(),
                keyStoreAlias: Joi.string()
                    .min(1)
                    .required(),
                keyStorePassword: Joi.string()
                    .min(1)
                    .required(),
                appNamespace: Joi.string()
                    .min(3)
                    .max(50)
                    .required(),
            },
        },
        [KeystoreTypeEnum.AWS_KMS]: {
            ns: 'keystore',
            defaultValues: {},
            keys: [
                'name',
                'description',
                'type',
                'iamRole',
                'appNamespace',
                'awsAccessKeyId',
                'awsRegion',
                'awsRootArn',
                'awsS3Bucket',
                'awsKmsAlias',
                'awsSecretAccessKey',
                'dekStore',
                'awsS3Endpoint',
                'awsKMSEndpoint',
            ],
            validationSchema: {
                name: Joi.string()
                    .min(1)
                    .max(30)
                    .pattern(/^[a-zA-Z0-9_-]+$/)
                    .required()
                    .messages({
                        'string.max': t('main.invalidNameLength'),
                        'string.pattern.base': t('keystore.nameInputInvalid'),
                        'string.empty': t('keystore.nameInputInvalid'),
                    }),
                type: Joi.string().required(),
                awsRegion: Joi.string()
                    .min(1)
                    .required(),
                awsS3Bucket: Joi.string()
                    .pattern(
                        /^(?!(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}))((^[a-z0-9][a-z0-9-]*[a-z0-9])|([a-z0-9]))(?:\.(([a-z0-9][a-z0-9-]*[a-z0-9])|([a-z0-9])))*$/
                    )
                    .min(3)
                    .max(63)
                    .required(),
                dekStore: Joi.string()
                    .min(1)
                    .required(),
                appNamespace: Joi.string()
                    .min(3)
                    .max(63)
                    .pattern(/^[^~`!@#$%^&()_={}[\]:;,.<>+\s\/\\?]+$/)
                    .required(),
                awsKmsAlias: Joi.string()
                    .allow('')
                    .pattern(/^[^~`!@#$%^&()={}[\]:;,.<>+\s\\?]+$/)
                    .custom((val: string) => {
                        if (val.startsWith('aws')) {
                            throw new Error(t('keystore.awsKmsAliasInputInvalid'));
                        }
                        return val;
                    })
                    .messages({
                        'string.pattern.base': t('keystore.awsKmsAliasInputInvalid'),
                        'any.custom': t('keystore.awsKmsAliasInputInvalid'),
                    })
                    .optional(),
                iamRole: Joi.boolean().allow('', null),
            },
        },
        [KeystoreTypeEnum.CLOUD_HSM]: {
            ns: 'keystore',
            defaultValues: {},
            keys: [
                'name',
                'type',
                'typeValue',
                'hsmUser',
                'hsmPassword',
                'hsmPartition',
                'awsAccessKeyId',
                'awsSecretAccessKey',
                'awsRootArn',
                'awsRegion',
                'dekStore',
                'awsS3Bucket',
                'appNamespace',
            ],
            validationSchema: {
                name: Joi.string()
                    .min(1)
                    .max(30)
                    .pattern(/^[a-zA-Z0-9_-]+$/)
                    .required()
                    .messages({
                        'string.max': t('main.invalidNameLength'),
                        'string.pattern.base': t('keystore.nameInputInvalid'),
                        'string.empty': t('keystore.nameInputInvalid'),
                    }),
                type: Joi.string().required(),
                hsmUser: Joi.string()
                    .min(1)
                    .required(),
                hsmPassword: Joi.string()
                    .min(1)
                    .required(),
                hsmPartition: Joi.string()
                    .min(1)
                    .required(),
                awsAccessKeyId: Joi.string()
                    .min(1)
                    .required(),
                awsRegion: Joi.string()
                    .min(1)
                    .required(),
                awsS3Bucket: Joi.string()
                    .pattern(
                        /^(?!(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}))((^[a-z0-9][a-z0-9-]*[a-z0-9])|([a-z0-9]))(?:\.(([a-z0-9][a-z0-9-]*[a-z0-9])|([a-z0-9])))*$/
                    )
                    .required(),
                awsSecretAccessKey: Joi.string()
                    .min(1)
                    .required(),
                dekStore: Joi.string()
                    .min(1)
                    .required(),
                appNamespace: Joi.string()
                    .min(3)
                    .max(50)
                    .pattern(/^[^~`!@#$%^&()_={}[\]:;,.<>+\/\\?-]+$/)
                    .required(),
            },
        },
        [KeystoreTypeEnum.IBM]: createIBMKeyProtectScheme(),
        [KeystoreTypeEnum.IBM_HPCS]: createIBMHpcsScheme(),
        [KeystoreTypeEnum.GENERIC_HSM]: {
            ns: 'keystore',
            keys: ['name', 'description', 'type'],
            defaultValues: {
                type: KeystoreTypeEnum.GENERIC_HSM,
            },
            validationSchema: {
                name: Joi.string()
                    .min(1)
                    .max(30)
                    .pattern(/^[a-zA-Z0-9_-]+$/)
                    .required()
                    .messages({
                        'string.max': t('main.invalidNameLength'),
                        'string.pattern.base': t('keystore.nameInputInvalid'),
                        'string.empty': t('keystore.nameInputInvalid'),
                    }),
                type: Joi.string().required(),
            },
        },
        [KeystoreTypeEnum.AZURE]: {
            ns: 'keystore',
            keys: [
                'name',
                'description',
                'type',
                'dekStore',
                'appNamespace',
                'keyVaultName',
                'masterKeyId',
                'azureTenantId',
                'clientId',
                'clientSecret',
                'dekContainer',
                'storageAccount',
                'accountKey',
            ],
            defaultValues: {
                type: KeystoreTypeEnum.AZURE,
            },
            validationSchema: {
                name: Joi.string()
                    .min(1)
                    .max(30)
                    .pattern(/^[a-zA-Z0-9_-]+$/)
                    .required()
                    .messages({
                        'string.max': t('main.invalidNameLength'),
                        'string.pattern.base': t('keystore.nameInputInvalid'),
                        'string.empty': t('keystore.nameInputInvalid'),
                    }),
                type: Joi.string().required(),
                dekStore: Joi.string()
                    .min(1)
                    .required(),
                appNamespace: Joi.string()
                    .min(3)
                    .max(63)
                    .pattern(/^[^~`!@#$%^&()_={}[\]:;,.<>+\s\/\\?]+$/)
                    .required(),
                keyVaultName: Joi.string()
                    .min(1)
                    .required(),
                azureTenantId: Joi.string()
                    .min(1)
                    .required(),
                masterKeyId: Joi.string()
                    .min(1)
                    .required(),
                clientId: Joi.string()
                    .min(1)
                    .required(),
                clientSecret: Joi.string()
                    .min(1)
                    .required(),
                dekContainer: Joi.string()
                    .min(1)
                    .required(),
                storageAccount: Joi.string()
                    .min(1)
                    .required(),
                accountKey: Joi.string()
                    .min(1)
                    .required(),
            },
        },
        [KeystoreTypeEnum.HASHI_VAULT]: {
            ns: 'keystore',
            keys: [
                'name',
                'description',
                'type',
                'dekStore',
                'appNamespace',
                'authToken',
                'masterKeyId',
                'vaultServer',
                'vaultPort',
                'unsealKeys',
                'vaultBucket',
                'vaultTruststore',
                'vaultTruststorePassword',
                'vaultKvVersion',
                'vaultNamespace',
            ],
            defaultValues: {
                type: KeystoreTypeEnum.HASHI_VAULT,
            },
            validationSchema: {
                name: Joi.string()
                    .min(1)
                    .max(30)
                    .pattern(/^[a-zA-Z0-9_-]+$/)
                    .required()
                    .messages({
                        'string.max': t('main.invalidNameLength'),
                        'string.pattern.base': t('keystore.nameInputInvalid'),
                        'string.empty': t('keystore.nameInputInvalid'),
                    }),
                type: Joi.string().required(),
                dekStore: Joi.string()
                    .min(1)
                    .required(),
                appNamespace: Joi.string()
                    .min(3)
                    .max(63)
                    .pattern(/^[^~`!@#$%^&()_={}[\]:;,.<>+\s\/\\?]+$/)
                    .required(),
                authToken: Joi.string()
                    .min(1)
                    .required(),
                masterKeyId: Joi.string()
                    .min(1)
                    .max(256)
                    .required()
                    .messages({
                        'string.max': t('main.invalidLength', { max: 256 }),
                        'string.empty': t('main.invalidEmpty'),
                    }),
                vaultServer: Joi.alternatives()
                    .try(Joi.string().domain(), Joi.string().ip())
                    .required(),
                vaultPort: Joi.number()
                    .port()
                    .required(),
                unsealKeys: Joi.string()
                    .min(45)
                    .required()
                    .allow(null, ''),
                vaultBucket: Joi.string()
                    .min(1)
                    .max(256)
                    .required()
                    .messages({
                        'string.max': t('main.invalidLength', { max: 256 }),
                        'string.empty': t('main.invalidEmpty'),
                    }),
                vaultTruststorePassword: Joi.string()
                    .min(1)
                    .optional(),
                vaultKvVersion: Joi.string()
                    .max(256)
                    .allow('')
                    .optional()
                    .messages({
                        'string.max': t('main.invalidLength', { max: 256 }),
                    }),
                vaultNamespace: Joi.string()
                    .max(256)
                    .allow('')
                    .optional()
                    .messages({
                        'string.max': t('main.invalidLength', { max: 256 }),
                    }),
            },
        },
    };
    return keystoreInputSchemes;
}
