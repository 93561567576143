import React from 'react';
import { TextInput, InlineLoading } from 'carbon-components-react';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import Joi from '@hapi/joi';
import Button from '@baffle/components/src/buttons/Button';
import { t } from '@baffle/translate';
import { Link, useHistory } from 'react-router-dom';
import useEnterKeyPress from '@baffle/utilities/src/useEnterKeyPress';
import { useMutation } from '@apollo/react-hooks';
import { RECOVER_PASSWORD } from '@baffle/graphql';
import { useSetToast } from '@baffle/components/src/toasts/Toast';
import InlineToast from '@baffle/components/src/toasts/InlineToast';

const RecoverPassword = () => {
    const { toast, setToast } = useSetToast();
    const history = useHistory();
    const { inputState, inputErrors, inputActions } = useInputState({
        keys: ['email'],
        ns: 'session',
        defaultValues: {},
        validationSchema: {
            email: Joi.string()
                .min(1)
                .required()
                .error(new Error(t('session.emailInputInvalid'))),
        },
    });

    const [recoverPassword, { loading }] = useMutation(RECOVER_PASSWORD, {
        onCompleted: () => {
            history.push('/login', { show: true, type: 'success', title: t('session.resetLink') });
        },
        onError: err => {
            const msg = err?.message ?? t('session.resetFailed');
            setToast({
                title: msg,
                type: 'danger',
                show: true,
            });
        },
    });

    const isValid = inputActions.validateAll(true);
    const doRecover = async () => {
        try {
            await recoverPassword({
                variables: {
                    input: {
                        email: inputState.email,
                    },
                },
            });
        } catch (error) {
            setToast({
                title: t('session.resetFailed'),
                type: 'danger',
                show: true,
            });
        }
    };

    useEnterKeyPress(() => {
        if (isValid) {
            doRecover();
        }
    });

    return (
        <div className="w-120">
            <div className="bg-gray-200 p-12">
                {toast.show ? (
                    <InlineToast
                        className="mb-5"
                        duration={10000}
                        title={toast.title}
                        type={toast.type}
                        onDismiss={() => setToast({ title: '', show: false, type: 'success' })}
                    />
                ) : null}
                <p className="text-gray-500 text-xl mb-6">{t('session.recoverMessage')}</p>
                <TextInput
                    id={`session-email-input`}
                    labelText={t('session.emailInputLabel')}
                    invalid={Boolean(inputErrors.email)}
                    invalidText={inputErrors.email}
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                        inputActions.validateField({ field: 'email', skipNull: true });
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const val = e.target.value || '';
                        inputActions.setField('email', val.trim());
                    }}
                />

                {loading ? (
                    <InlineLoading className="my-6" description={t('main.loading')} />
                ) : (
                    <Button
                        className="w-full mt-8 h-12"
                        theme={isValid ? 'primary' : 'disabled'}
                        id="do-recover-pw-btn"
                        onClick={() => doRecover()}>
                        {t('session.resetPassword')}
                    </Button>
                )}
                <div className="mt-6 relative w-full flex justify-center">
                    <Link to="/login" className="text-blue">
                        {t('main.back')}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default RecoverPassword;
