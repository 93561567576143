import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import PageHeader from '@baffle/components/src/pageheader/pageheader';
import Content from '@baffle/components/src/content/Content';
import keystoreClient from '@baffle/api-client/src/keystoreClient';
import { ToastStore } from '../stores/ToastStore';
import { Overview } from '@baffle/components/src/overview/Overview';
import { t } from '@baffle/translate';
import DataGrid from '@baffle/components/src/grid/data-grid';
import cx from 'classnames';
import { format } from '@baffle/utilities/src/date';
import { Modal, TextInput } from 'carbon-components-react';
import createKeystoreInputScheme from '../keystore/keystoreInputSchemes/createKeystoreInputScheme';
import { KeystoreTypeEnum } from '@baffle/graphql/src/models';
import AddCmkModal from './AddCmkModal';

const KeystoreDetails = () => {
    const { id } = useParams() as { id: string | undefined };
    const history = useHistory();
    const initialModalState = {
        showDeleteModal: false,
        showRenameModal: false,
        showAddCmkModal: false,
    };
    const [showModal, setShowModal] = useState(initialModalState);
    const keystoreInputSchemes = useMemo(() => createKeystoreInputScheme(), []);

    const emptyCol = {
        id: '',
        name: '',
        type: '',
        createTime: '',
        iamRole: '',
        accessKey: '',
        secretKey: '',
        awsRegion: '',
        awsRootArn: '',
        KMSEndPoint: '',
        dekStore: '',
        appNameSpace: '',
        awsS3Bucket: '',
        S3EndPoint: '',
    };

    const [keystore, setKeystore] = useState(emptyCol);
    //@ts-ignore
    const { inputState, inputErrors, inputActions } = useInputState(keystoreInputSchemes[KeystoreTypeEnum.LOCAL]);

    const getKeystore = async () => {
        try {
            const ks = await keystoreClient.read(id as string);
            setKeystore(ks);
        } catch (error) {
            ToastStore.push({
                type: 'danger',
                title: error.message,
                'data-testid': 'keystore-details-failure-toast',
            });
        }
    };
    useEffect(() => {
        getKeystore();
        inputActions.reset();
    }, []);

    const headers = [
        {
            key: 'header1',
            header: t('main.name'),
        },
        {
            key: 'header2',
            header: t('keystorev2.alias'),
        },
        {
            key: 'header3',
            header: t('keystorev2.arn'),
        },
        {
            key: 'header4',
            header: t('keystorev2.deks'),
        },
        {
            key: 'header5',
            header: t('keystorev2.lastRotation'),
        },
    ];

    const actions = [
        {
            id: '1',
            label: t('keystorev2.rename'),
        },
        {
            id: '2',
            label: t('main.edit'),
        },
        {
            id: '3',
            label: t('main.delete'),
        },
    ];

    const breadcrumbData = [
        {
            label: t('main.keystores'),
            href: '/keystoresV2',
        },
    ];

    const doDeleteKeystore = async () => {
        setShowModal(prevState => {
            return { ...prevState, showDeleteModal: false };
        });
        try {
            await keystoreClient.remove(keystore.id);
            ToastStore.push({
                type: 'success',
                title: t('keystore.deleteSuccess', { name: keystore.name }),
                'data-testid': 'keystore-delete-success-toast',
            });
            history.push('/keystoresV2');
        } catch (error) {
            ToastStore.push({
                type: 'danger',
                title: error.message,
                'data-testid': 'keystore-delete-failure-toast',
            });
        }
    };

    const onSelectAction = (item: any) => {
        switch (item.selectedItem.label) {
            case t('keystorev2.rename'):
                console.log('Rename action selected');
                inputActions.setField('name', keystore.name);
                setShowModal(prevState => {
                    return { ...prevState, showRenameModal: true };
                });
                break;
            case t('main.edit'):
                console.log('Edit action selected');
                break;
            case t('main.delete'):
                // Show the confirmation modal to get the confirmation from User
                setShowModal(prevState => {
                    return { ...prevState, showDeleteModal: true };
                });
                break;
            default:
                console.log('Unknown action selected');
                break;
        }
    };

    //TODO: Dummay mapping for now to check the look and feel. To be updated once the proper BE APIs are available.
    // The fields shown in the UI will be based on the keystore type. Awaiting data to be shown for each KS type.
    // Once that is available, this needs to be modified based on that data.
    const keystoreDetails = {
        id: id,
        name: keystore.name,
        type: keystore.type,
        createTime: format(keystore.createTime, 'MMM dd, yyyy H:mm:ss a'), //keystore.createTime,
        iamRole: 'NO',
        accessKey: 'ABCDEFG',
        secretKey: '****',
        awsRegion: keystore.awsRegion,
        awsRootArn: keystore.awsRootArn,
        KMSEndPoint: '',
        dekStore: keystore.dekStore,
        appNameSpace: keystore.appNameSpace,
        awsS3Bucket: keystore.awsS3Bucket,
        S3EndPoint: '',
    };

    //TODO: Need BE API to link it.
    const onAdd = () => {
        setShowModal(prevState => {
            return { ...prevState, showAddCmkModal: true };
        });
    };

    const onSyncButtonClick = () => {
        console.log('Sync Button clicked');
    };

    //TODO: Dummy data for now to check the look and feel. Once the BE API is available will be updated.
    const rows: any = [
        {
            id: '1',
            header1: 'CMK-1',
            header2: 'baffle-external-cmk-3',
            header3: 'arn:aws:kms:',
            header4: 3,
            header5: 'May 10, 2021 5:52:22 PM',
            link: '/keystore/' + keystore.id + '/cmk-' + '1', //The last one to be replaced with CMk-id
        },
        {
            id: '2',
            header1: 'CMK-2',
            header2: 'baffle-external-cmk-3',
            header3: 'arn:aws:kms:',
            header4: 3,
            header5: 'May 20, 2021 12:52:22 PM',
            link: '/keystore/' + keystore.id + '/cmk-' + '2',
        },
        {
            id: '3',
            header1: 'CMK-3',
            header2: 'baffle-external-cmk-3',
            header3: 'arn:aws:kms:',
            header4: 3,
            header5: 'May 20, 2021 12:52:22 PM',
            link: '/keystore/' + keystore.id + '/cmk-' + '3',
        },
    ];
    const isValid = inputActions.validateIndividualField({ field: 'name', skipNull: false });

    const onAddCmk = (cmkDetails: any) => {
        console.log('Add CMK called, cmkDetails: ', cmkDetails);
        setShowModal(prevState => {
            return { ...prevState, showAddCmkModal: false };
        });
        // TODO: Call the BE API to add CMK
    };

    return (
        <>
            <Content>
                <PageHeader
                    title={keystore.name}
                    actions={actions}
                    breadcrumbData={breadcrumbData}
                    onActionSelect={onSelectAction}
                />
                <Modal
                    open={showModal.showDeleteModal}
                    modalHeading={t('keystorev2.deleteConfirmationTitle', {
                        name: keystore.name,
                    })}
                    danger={true}
                    onRequestClose={() => {
                        setShowModal(prevState => {
                            return { ...prevState, showDeleteModal: false };
                        });
                    }}
                    primaryButtonText={t('main.delete')}
                    secondaryButtonText={t('main.cancel')}
                    size="sm"
                    onRequestSubmit={doDeleteKeystore}>
                    <p>{t('keystorev2.deleteConfirmationMsg', { name: keystore.name })}</p>
                </Modal>
                <Modal
                    open={showModal.showRenameModal}
                    modalHeading={t('keystorev2.renameKsTitle')}
                    onRequestClose={() => {
                        setShowModal(prevState => {
                            return { ...prevState, showRenameModal: false };
                        });
                    }}
                    primaryButtonText={t('main.rename')}
                    secondaryButtonText={t('main.cancel')}
                    size="sm"
                    onRequestSubmit={() => {
                        setShowModal(prevState => {
                            return { ...prevState, showRenameModal: false };
                        });
                        //TODO: Call the BE API to rename and refresh the page.
                    }}
                    primaryButtonDisabled={!isValid}>
                    <TextInput
                        id="keystore-rename-input"
                        name="keystore-rename-input"
                        data-testid="keystore-rename-input"
                        labelText={t('main.name')}
                        invalid={Boolean(inputErrors.name)}
                        invalidText={inputErrors.name}
                        value={(inputState.name as string) || ''}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            inputActions.validateField({
                                field: 'name',
                                skipNull: true,
                            });
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value || '';
                            inputActions.setField('name', val);
                        }}
                    />
                </Modal>
                <AddCmkModal
                    open={showModal.showAddCmkModal}
                    onClose={() => {
                        setShowModal(prevState => {
                            return { ...prevState, showAddCmkModal: false };
                        });
                    }}
                    onSuccess={onAddCmk}
                />
                <div className="px-8 mt-4 text-sm">
                    <div className={'pb-2 w-40 border-solid border-b-2 border-blue-60 cursor-pointer'}>
                        <p className="ml-4 text-lg">{t('keystorev2.overview')}</p>
                    </div>
                </div>
                <div className="overflow-y-auto h-80p">
                    <div className="pl-5 pr-5">
                        <Overview title={t('keystorev2.ksDetails')} info={keystoreDetails} topic="keystorev2" />
                    </div>

                    <div className="flex-1 px-0 pt-10 flex justify-between">
                        <div className="flex-1 flex items-center">
                            <h1 className="pl-10 text-heading-01 text-gray-100">{t('keystorev2.cmk')}</h1>
                        </div>
                    </div>

                    <div id="table-container" className={cx('datagrid-container keystore-datagrid flex-grow')}>
                        <DataGrid
                            idPrefix="keystores"
                            rows={rows}
                            headers={headers}
                            showSearch={true}
                            onAdd={onAdd}
                            addButtonCaption={t('keystorev2.cmk+')}
                            onSync={onSyncButtonClick}></DataGrid>
                    </div>
                </div>
            </Content>
        </>
    );
};

export default KeystoreDetails;
