import React, { useState, useEffect, useMemo } from 'react';
import { t } from '@baffle/translate';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import { CreateModal, CreateModalBody, CreateModalHeader } from '@baffle/components/src/CreateModal/CreateModal';
import { TextInput, FileUploader } from 'carbon-components-react';
import { certificateScheme } from './createCertInputScheme';
import useAwait from '@baffle/api-client/src/useAwait';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';
import { CertificateStore } from '@baffle/manager/src/stores';

interface AddCACertModalProps {
    open: boolean;
    onClose?: () => void;
    onSuccess?: (name: string) => void;
}

const AddCACertificate = ({ open, onClose, onSuccess }: AddCACertModalProps) => {
    const [openModal, setOpen] = useState(open);
    const { run, error, reset } = useAwait();
    const certificateInputScheme = useMemo(
        () => certificateScheme(CertificateStore.certificates, CertificateStore.selectedCertificate?.id),
        [CertificateStore.certificates, CertificateStore.selectedCertificate?.id]
    );

    //@ts-ignore
    const { inputState, inputErrors, inputActions } = useInputState(certificateInputScheme);

    useEffect(() => {
        if (error) {
            NotificationStore.push({
                'data-testid': 'certificate-add-failure',
                kind: 'error',
                title: t('certificate.addCertificateError'),
                description: error.message ?? t('main.genericError'),
            });
        }
    }, [error]);

    const handleClose = () => {
        if (!openModal) {
            return;
        }
        setOpen(false);
        reset();
        inputActions.reset();
        setTimeout(() => {
            if (onClose) {
                onClose();
            }
        }, 200);
    };

    //Sync state from props
    useEffect(() => {
        setOpen(open);
    }, [open]);

    const isValid = inputActions.validateAll(true);

    const handleSave = () => {
        const payload = {
            certName: inputState.name as string,
            cert: inputState.cert as File,
        };
        run(
            CertificateStore.create(payload).then(() => {
                handleClose();
                if (onSuccess) {
                    onSuccess(payload.certName as string);
                }
            })
        );
    };

    return (
        <CreateModal
            open={openModal}
            onClose={handleClose}
            isValid={isValid}
            modalId="add-certificate-modal"
            primaryButtonText={t('main.create')}
            primaryButtonTheme="primary"
            primaryButton={() => handleSave()}>
            <CreateModalHeader titleText={t('certificate.addCertificateModal')} />
            <CreateModalBody>
                <div className="text-sm font-normal leading-5 mb-4 mt-2 mr-6">
                    {t('certificate.certificateModalMessage')}
                </div>
                <div className="flex flex-wrap items-end">
                    <div className="w-full text-sm name-input-container mb-4">
                        <TextInput
                            id="certificate-name-input"
                            name="certificate-name-input"
                            data-testid="certificate-name-input"
                            labelText={t('main.name')}
                            invalid={Boolean(inputErrors.name)}
                            invalidText={inputErrors.name}
                            value={(inputState.name as string) || ''}
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                inputActions.validateField({
                                    field: 'name',
                                    skipNull: true,
                                });
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                let val = e.target.value || '';
                                inputActions.setField('name', val);
                            }}
                        />
                    </div>
                    <div>
                        <FileUploader
                            aria-label={t('main.addFile')}
                            buttonLabel={t('main.addFile')}
                            name="certificate-file-upload"
                            data-testid="certificate-file-upload"
                            buttonKind="primary"
                            filenameStatus="edit"
                            iconDescription="upload-certificate"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const file = e.target.files ? e.target.files[0] : null;
                                if (file) {
                                    inputActions.setField('cert', file);
                                }
                            }}
                            labelDescription={t('certificate.certificateUploadLabel')}
                        />
                    </div>
                </div>
            </CreateModalBody>
        </CreateModal>
    );
};

export default AddCACertificate;
