import { ColumnsList } from './encryption';
import { ObjectID } from './ssh';

export type MigrationStatusTypes = 'INITIATED' | 'STARTED' | 'IN_PROGRESS' | 'SUCCESS' | 'FAILED' | 'NA';

export interface MigrationStatusTable {
    dbName: string;
    tableName: string;
    columns: ColumnsList;
    mgrRows: number;
    totalRows: number;
    mgrPercent: number;
    status: MigrationStatusTypes;
    //properties compiled after data fetched
    id?: string;
}

export interface MgrTableList {
    [key: string]: MigrationStatusTable;
}

export interface MigrationStatusForApplicationDetails {
    mgrShieldId: string;
    status: MigrationStatusTypes;
    mgrRows: number;
    totalRows: number;
    columns: number;
}

export interface MigrationStatus {
    id: ObjectID;
    createTime: number;
    createdBy: string;
    updateTime: number;
    updatedBy: string;
    command: string;
    status: MigrationStatusTypes;
    mode: string;
    mgrShieldId: string;
    mgrTableList: MgrTableList;
    log: string;
    //properties compiled after data fetched
    mgrRows: number; //migrated rows across all tables
    totalRows: number; //total rows across all tables
    applicationID: string;
}

export interface MigrationPlan {
    applicationId?: string; //this is premptive association to application
    failScope: string;
    parallel: boolean;
    batch: boolean;
    batchSize: number;
    cleanTmp: boolean;
    migrationType: 'server_to_server' | 'table_to_table';
    dbType: string;
}

export interface MigrationPlanInput {
    failScope?: string;
    parallel?: boolean;
    batchSize?: number;
    batch?: boolean;
    cleanTmp?: boolean;
    migrationType?: 'server_to_server' | 'table_to_table';
    migrationTargetDB?: string;
    applicationId?: string; //this is premptive association to application
}

export enum CancelMigrationStatus {
    MIGRATION_IN_PROGRESS = 'MIGRATION_IN_PROGRESS',
    CANCELLATION_IN_PROGRESS = 'CANCELLATION_IN_PROGRESS',
    CANCELLATION_COMPLETED = 'CANCELLATION_COMPLETED',
    DISABLED = 'DISABLED',
}

export interface CancelMigrationStatusDto {
    status: CancelMigrationStatus;
}
