import React, { useState, useEffect } from 'react';
import TextInput from '@baffle/components/src/forms/TextInput';
import { t } from '@baffle/translate';
import '../../database/AddDatabaseModal.scss';
import { InputActions, InputError, InputState, useInputState } from '@baffle/utilities/src/inputHooks';
import Joi from '@hapi/joi';
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    SkeletonModal,
    getDefaultRootModalProps,
} from '@baffle/components/src/modal/Modal';
import Button from '@baffle/components/src/buttons/Button';
import { EncryptionStore } from '../../stores';
import { useParams } from 'react-router-dom';
import { TextArea } from 'carbon-components-react';
import encryptionClient from '@baffle/api-client/src/encryptionClient';
import Alert from '@baffle/components/src/alerts/Alert';
import useAwait from '@baffle/api-client/src/useAwait';
import { CreateNewTableDTO } from '@baffle/graphql/src/models';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { ToastStore } from '../../stores/ToastStore';
import { Manager, Reference, Popper } from '@baffle/components/src/poppers/Popover';
import ReactDOM from 'react-dom';
import useEscapeKeyPress from '@baffle/utilities/src/useEscapePress';

interface AddTableModalProps {
    tables: string[];
    open: boolean;
    onClose?: () => void;
    existingTableName?: string;
    onSuccess?: (tableName: string, action: string) => void;
    selectedDatabase: string;
    selectedSchema: string;
    fourLevel?: boolean | undefined;
}
const AddNewTableModal = ({
    tables,
    onClose = () => null,
    existingTableName,
    onSuccess = (tableName: string, action: string) => null,
    fourLevel,
    selectedDatabase,
    selectedSchema,
    open,
}: AddTableModalProps) => {
    const [openModal, setOpen] = useState(open);
    const { id } = useParams() as { id: string };
    const { status, run, error, reset } = useAwait();
    const { inputState, inputErrors, inputActions } = useInputState({
        keys: ['tableName, columns'],
        ns: 'table',
        validationSchema: {
            tableName: Joi.string()
                .min(1)
                .max(30)
                .custom(val => {
                    if (
                        tables.some((a: String) => {
                            if (existingTableName) {
                                return a.trim().toLowerCase() === val.toLowerCase() && val !== existingTableName;
                            } else {
                                return a.trim().toLowerCase() === val.toLowerCase();
                            }
                        })
                    ) {
                        throw new Error(t('encryption.tableNameExists'));
                    }
                    return val;
                })
                .pattern(/^[a-zA-Z0-9_-\s]+$/)
                .required()
                .messages({
                    'string.max': t('main.invalidNameLength'),
                    'string.empty': t('encryption.tableNameInputInvalid'),
                    'string.pattern.base': t('encryption.noSpecialCharacters'),
                    'any.custom': t('encryption.tableNameExists'),
                }),
        },
        defaultValues: { tableName: existingTableName },
    });

    //Sync state from props
    useEffect(() => {
        setOpen(open);
        if (open) {
            inputActions.setFields({
                tableName: existingTableName,
            });
        }
    }, [open]);

    useEffect(() => {
        if (existingTableName) {
            run(
                (fourLevel
                    ? encryptionClient.getNewColumns(id, selectedSchema, existingTableName)
                    : encryptionClient.getNewColumns(id, selectedDatabase, existingTableName)
                ).then(columns => {
                    if (columns) {
                        inputActions.setFields({
                            columns: (columns as string[]).join('\n'),
                            tableName: existingTableName,
                        });
                    }
                })
            );
        }
    }, [existingTableName]);

    const handleClose = () => {
        onClose();
        inputActions.reset();
        reset();
    };

    const setTable = () => {
        const columns = inputState.columns ? (inputState.columns as string).split('\n') : undefined;
        let payload: CreateNewTableDTO = {
            database: selectedDatabase,
            appID: id,
            table: inputState.tableName as string,
            schema: selectedSchema.split('/')[1],
            columns: columns?.filter(col => (col ? true : false)),
        };
        run(
            (existingTableName
                ? encryptionClient.editNewTable(payload, existingTableName)
                : encryptionClient.createNewTable(payload)
            ).then(() => {
                onSuccess(inputState.tableName as string, existingTableName ? 'updated' : 'added');
                if (existingTableName) {
                    const editType = fourLevel ? 'table-4' : 'table-3';
                    EncryptionStore.updateSelectedColumns({
                        editType,
                        newTableName: inputState.tableName as string,
                    });
                }
                fourLevel
                    ? EncryptionStore.listSchemaTables(id as string, selectedSchema)
                    : EncryptionStore.listTables(id as string, selectedDatabase);
                EncryptionStore.setSelectedTable('encryption', '');
                inputActions.reset();
            })
        );
    };

    useEffect(() => {
        if (error) {
            ToastStore.push({ type: 'danger', title: error.message });
        }
    }, [error]);

    const { className } = getDefaultRootModalProps();

    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            getRootModalProps={() => {
                return {
                    className: 'overflow-visible ' + className,
                };
            }}>
            {status === 'loading' ? (
                <SkeletonModal />
            ) : (
                <AddNewTable
                    handleClose={handleClose}
                    inputErrors={inputErrors}
                    inputActions={inputActions}
                    setTable={setTable}
                    existingTableName={existingTableName}
                    inputState={inputState}
                    error={error}
                />
            )}
        </Modal>
    );
};

interface AddNewTableProps {
    handleClose: () => void;
    inputErrors: InputError;
    inputActions: InputActions;
    setTable: () => void;
    existingTableName?: string;
    inputState: InputState;
    error: Error | null;
}

const AddNewTable = ({
    handleClose,
    inputActions,
    inputErrors,
    setTable,
    existingTableName,
    inputState,
    error,
}: AddNewTableProps) => {
    const isValid = inputActions.validateAll(true);
    const [controlledVisible, setControlledVisible] = useState(false);
    useEscapeKeyPress(() => {
        setControlledVisible(false);
    });
    return (
        <div>
            <ModalHeader onClose={handleClose}>
                <div className="flex items-start">
                    <div className="mt-0 ml-2">
                        <h3 className="text-xl font-medium text-gray-100" id="modal-headline">
                            {existingTableName ? t('encryption.editTable') : t('encryption.addTable')}
                        </h3>
                    </div>
                </div>
            </ModalHeader>
            <ModalBody>
                <div>
                    {error ? (
                        <div className="flex flex-wrap mb-4">
                            <div className="flex-1 px-4">
                                <Alert
                                    type="danger"
                                    title={t('encryption.newTableAddError')}
                                    description={error?.message || ''}
                                />
                            </div>
                        </div>
                    ) : null}
                    <TextInput
                        id="table-name-input"
                        name="table-name-input"
                        labelText={t('encryption.columnGrid.tableName')}
                        invalid={Boolean(inputErrors.tableName)}
                        invalidText={inputErrors.tableName}
                        placeholder={t('encryption.tableNamePlaceholder')}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            inputActions.validateField({
                                field: 'tableName',
                                skipNull: true,
                            });
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value || '';
                            inputActions.setField('tableName', val.trim());
                        }}
                        value={(inputState.tableName as string) ?? ''}
                    />
                    <br />
                    <TextArea
                        id="table-columns-input"
                        labelText={t('encryption.addColumns')}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value || '';
                            inputActions.setField('columns', val);
                        }}
                        value={(inputState.columns as string) ?? ''}
                        className="my-0 h-72"
                    />
                    <Manager>
                        <Reference>
                            {({ ref }) => (
                                <span
                                    id="span-help-columns"
                                    ref={ref}
                                    onClick={() => {
                                        if (controlledVisible) {
                                            setControlledVisible(false);
                                        } else {
                                            setControlledVisible(true);
                                        }
                                    }}
                                    className="absolute right-6 top-40 text-blue-200 cursor-pointer">
                                    <HelpOutlineIcon fontSize="small" />
                                </span>
                            )}
                        </Reference>
                        {controlledVisible
                            ? ReactDOM.createPortal(
                                  <Popper placement="bottom" eventsEnabled={false}>
                                      {({ ref, style, placement, arrowProps }) => (
                                          <div
                                              ref={ref}
                                              style={style}
                                              className="bg-white border-solid border border-gray-300 shadow p-2 rounded baffle-popover z-10 text-left cursor-auto top-3 w-92"
                                              data-placement="bottom">
                                              <p>
                                                  To define each new column, type the following required information on
                                                  one line:
                                              </p>
                                              <br />
                                              <ul className="list-disc ml-4">
                                                  <li>&lt;column name&gt;</li>
                                                  <li>&lt;datatype&gt;</li>
                                              </ul>
                                              <br />
                                              <p>
                                                  The following parameters can be optionally added to each column line:
                                              </p>
                                              <br />
                                              <ul className="list-disc ml-4">
                                                  <li>&lt;NULL or NOT_NULL&gt;</li>
                                                  <li>&lt;PK&gt;(If column is primary key)</li>
                                                  <li>CHARSET&lt;value&gt;</li>
                                                  <li>COLLATION&lt;value&gt;</li>
                                                  <li>DEFAULT&lt;value&gt;</li>
                                              </ul>
                                              <br />
                                              <p>
                                                  Example column line:
                                                  <br />
                                                  customername varchar(100) NOT_NULL PK CHARSET utf8 COLLATION
                                                  latin1_swedish_ci DEFAULT
                                              </p>
                                              <div
                                                  ref={arrowProps.ref}
                                                  style={Object.assign({}, arrowProps.style, {
                                                      top: '-2px',
                                                  })}
                                                  data-placement={'bottom'}
                                                  className="popover-arrow"
                                              />
                                          </div>
                                      )}
                                  </Popper>,
                                  document.getElementById('span-help-columns') as HTMLElement
                              )
                            : null}
                    </Manager>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex justify-end items-center h-16 w-full">
                    <Button
                        theme="secondary"
                        className="h-full w-1/2"
                        onClick={handleClose}
                        data-testid="modal-cancel-future-table-btn"
                        size="lg">
                        {t('main.cancel')}
                    </Button>
                    <Button
                        className="h-full w-1/2"
                        theme={!isValid ? 'disabled' : 'primary'}
                        onClick={() => {
                            setTable();
                        }}
                        data-testid="modal-add-future-table-btn"
                        size="lg">
                        {t('main.save')}
                    </Button>
                </div>
            </ModalFooter>
        </div>
    );
};

export default AddNewTableModal;
