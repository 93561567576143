import React, { useState, useRef, useEffect, useMemo } from 'react';
import Header from '@baffle/components/src/nav/Header';
import Content from '@baffle/components/src/content/Content';
import Button, { getDefaultButtonProps } from '@baffle/components/src/buttons/Button';
import AddIcon from '@material-ui/icons/Add';
import { useHistory, useParams } from 'react-router-dom';
import { t } from '@baffle/translate';
import { Observer } from 'mobx-react-lite';
import {
    ConfigFileAssociations,
    ConfigFileNameEnum,
    ConfigFilesForEditor,
    ConfigFileShieldMetaData,
    DeploymentState,
} from '@baffle/graphql/src/models';
import KeyClick from '@baffle/components/src/handlers/KeyClck';
import cx from 'classnames';
import ChevronBoldIcon from '@baffle/components/src/icons/ChevronBoldIcon';
import RefreshIcon from '@material-ui/icons/Refresh';
import CloseIcon from '@material-ui/icons/Close';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import { AppConfigStore, ApplicationStore } from '../../stores';
import SkeletonText, { getDefaultSkeletonTextProps } from '@baffle/components/src/skeleton/SkeletonText';
import useAwait from '@baffle/api-client/src/useAwait';
import { ToastStore } from '../../stores/ToastStore';
import DropdownSelect, {
    getDefaultLIProps,
    getDefaultMenuProps,
    getDefaultToggleButtonProps,
} from '@baffle/components/src/dropdown/Dropdown';
import AddConfigFileModal from './AddConfigFileModal';
import { useManageModals } from '@baffle/components/src/modal/Modal';
import { createFileNameKey } from '../../stores/AppConfigStore';
import './AppConfiguration.scss';

const AppConfiguration = () => {
    return (
        <Observer>
            {() => {
                const history = useHistory();
                const { id, fileName, shieldId } = useParams() as { id: string; fileName: string; shieldId?: string };

                const [localConfigFileList, setLocalConfigFileList] = useState<ConfigFileAssociations[]>([]);
                const [localConfigFiles, setState] = useState<ConfigFilesForEditor>({});

                const setConfigFiles = (
                    fileName: string,
                    { value, didChange }: { value: string; didChange: boolean }
                ) => {
                    const newState = Object.assign({}, localConfigFiles, { [fileName]: { value, didChange } });
                    setState(newState);
                };

                const { run: runOnMount, status: statusOnMount, error: errorOnMount } = useAwait();
                const { run, error } = useAwait();

                useEffect(() => {
                    runOnMount(
                        Promise.all([
                            ApplicationStore.read(id, { networkPolicy: 'network-only' }),
                            AppConfigStore.list(id).then(() => {
                                const appConfigFiles = AppConfigStore.configFilesListByApp(id);
                                setLocalConfigFileList(appConfigFiles);
                            }),
                        ])
                    );
                }, []);

                useEffect(() => {
                    //only read from remote if the file is in the list...
                    //useful if this is a newly created client side file
                    if (
                        statusOnMount === 'success' &&
                        localConfigFileList.some(fileListItem => fileListItem.fileName === fileName)
                    ) {
                        run(
                            AppConfigStore.read(
                                {
                                    appId: id,
                                    fileName,
                                    ...(shieldId ? { shieldId } : {}),
                                },
                                { networkPolicy: 'network-only' }
                            ).then(() => {
                                //sync editor config files from store/remote
                                const key = createFileNameKey(fileName, shieldId);
                                if (!localConfigFiles[key]) {
                                    const config = AppConfigStore.get({ appId: id, fileName, shieldId }) ?? '';
                                    setConfigFiles(key, { value: config, didChange: false });
                                }
                            })
                        );
                    }
                }, [fileName, shieldId, statusOnMount]);

                useEffect(() => {
                    if (error) {
                        ToastStore.push({ title: error.message, type: 'danger' });
                    }
                    if (errorOnMount) {
                        ToastStore.push({ title: errorOnMount.message, type: 'danger' });
                    }
                }, [error, errorOnMount]);

                interface ConfigModals {
                    addConfigFile: boolean;
                }

                const application = ApplicationStore.get(id);

                const [modals, setModals] = useManageModals<ConfigModals>({ addConfigFile: false });

                const configData = AppConfigStore.get({ appId: id, fileName, shieldId }) ?? '';
                const editorRef = useRef<HTMLTextAreaElement>(null);
                const [hoverType, setHoverType] = useState('');

                const isBPS = useMemo<boolean>(() => fileName.toLowerCase() === ConfigFileNameEnum.BPS.toLowerCase(), [
                    fileName,
                ]);
                const appConfigFile = localConfigFileList.find(f => f.fileName === fileName);
                const selectedFileNameKey = useMemo<string>(() => createFileNameKey(fileName, shieldId), [
                    fileName,
                    shieldId,
                ]);

                const localConfigFile = localConfigFiles[selectedFileNameKey] ?? {};

                const doSaveConfigFile = async ({
                    deploymentState,
                    fileName,
                    newFile,
                }: {
                    deploymentState: DeploymentState;
                    fileName: string;
                    newFile?: boolean;
                }) => {
                    try {
                        const payload = Object.assign(
                            {},
                            {
                                fileName: fileName,
                                shieldId,
                                config: newFile ? '' : localConfigFile.value ?? '',
                                deploymentState: deploymentState,
                            }
                        );
                        await AppConfigStore.save({ appId: id, ...payload });

                        setConfigFiles(selectedFileNameKey, { value: '', didChange: false });

                        let toastTitle = t('encryption.encryptionSuccess', { name: application?.appName });
                        switch (deploymentState) {
                            case 'save_deploy':
                                toastTitle = t('encryption.deploySuccess', { name: fileName });
                                break;
                            case 'save_draft':
                                toastTitle = t('encryption.saveStoreSuccess', { name: application?.appName });
                                break;

                            default:
                                break;
                        }

                        ToastStore.push({
                            type: 'success',
                            title: toastTitle,
                        });
                    } catch (error) {
                        ToastStore.push({ title: JSON.parse(error.message).errors.join(', '), type: 'danger' });
                    }
                };

                const configFileShieldItems = (appConfigFile?.shields ?? []).map((s: ConfigFileShieldMetaData) => ({
                    label: s.shieldName,
                    id: s.id,
                }));

                return (
                    <>
                        <AddConfigFileModal
                            onSuccess={fileName => {
                                const key = createFileNameKey(fileName, '');
                                const listCopy = localConfigFileList.slice();
                                listCopy.push({ appId: id, fileName });
                                setLocalConfigFileList(listCopy);
                                setConfigFiles(key, { value: '', didChange: true });
                                doSaveConfigFile({ deploymentState: 'save_deploy', fileName, newFile: true });
                                setModals({ addConfigFile: false });
                            }}
                            open={modals.addConfigFile}
                            onClose={() => setModals({ addConfigFile: false })}
                        />
                        <Content className="flex flex-col" data-testid="application-config-editor">
                            <Header>
                                <div className="flex-1 px-4 flex justify-between">
                                    {statusOnMount === 'loading' ? (
                                        <SkeletonText
                                            getSkeletonProps={() => {
                                                return { ...getDefaultSkeletonTextProps(), width: 'w-72' };
                                            }}
                                        />
                                    ) : (
                                        <h1 className="text-heading-01">
                                            <span className="text-gray-100">{t('application.configEditorTitle')}:</span>{' '}
                                            {application?.appName}
                                        </h1>
                                    )}
                                </div>
                                <div className="ml-6 flex items-center h-full">
                                    <Button
                                        theme="icon-transparent"
                                        onClick={() => {
                                            history.replace('/');
                                        }}
                                        aria-label={t('main.closeWindow')}>
                                        <CloseIcon />
                                    </Button>
                                </div>{' '}
                            </Header>
                            <div className="flex flex-grow">
                                <div className="flex w-72 border-solid border-r border-gray-300 mr-2">
                                    <div
                                        data-testid="config-file-nav"
                                        className="flex flex-col flex-grow mr-2 pt-6 pl-6 pr-2 pb-0 w-72">
                                        <Button
                                            aria-label={t('application.addConfigFile')}
                                            theme="primary"
                                            onClick={() => setModals({ addConfigFile: true })}
                                            getButtonProps={() => {
                                                const { className, ...rest } = getDefaultButtonProps();
                                                return {
                                                    ...rest,
                                                    'data-testid': 'add-config-file-btn',
                                                    className: `${className.replace(
                                                        'justify-center',
                                                        'justify-start'
                                                    )} mb-4 h-10 flex justify-between flex-row-reverse`,
                                                };
                                            }}>
                                            <AddIcon className="w-4 h-4" />
                                            {t('application.configFile')}
                                        </Button>
                                        <div className="flex flex-col overflow-y-auto config-file-nav">
                                            {localConfigFileList.map((file, i) => {
                                                const fileKey = createFileNameKey(file.fileName, shieldId);
                                                const localFileRef = localConfigFiles[fileKey] ?? {};
                                                const cs = cx(
                                                    'flex justify-between items-center hover:font-bold text-base mb-4 focus:outline-none h-4',
                                                    {
                                                        'text-gray-600 font-bold focus:text-gray-600':
                                                            file.fileName === fileName,
                                                        'text-gray-500 focus:text-blue': file.fileName !== fileName,
                                                    }
                                                );
                                                return (
                                                    <KeyClick
                                                        key={`${fileName}_${i}`}
                                                        data-testid={`${fileName}_nav_item`}
                                                        handler={() => {
                                                            let url = `/application/configuration/${id}/${file.fileName}`;
                                                            if (file?.shields && file?.shields?.length > 0) {
                                                                url += `/${file.shields[0].id}`;
                                                            }
                                                            history.push(url);
                                                        }}>
                                                        <div
                                                            className={cs}
                                                            onMouseEnter={() => setHoverType(file.fileName)}
                                                            onMouseLeave={() => setHoverType('')}
                                                            title={file.fileName}>
                                                            <span className="truncate">{file.fileName}</span>
                                                            {/* //unsaved changes */}
                                                            {localFileRef.didChange ? (
                                                                <FiberManualRecordIcon
                                                                    className="text-teal-300"
                                                                    data-testid={`unsavedchanges-icon-${file.fileName}`}
                                                                />
                                                            ) : file.fileName === fileName ||
                                                              hoverType === file.fileName ? (
                                                                <ChevronBoldIcon
                                                                    color="#009C98"
                                                                    data-testid={`chevronicon-${file.fileName}`}
                                                                />
                                                            ) : (
                                                                <FiberManualRecordOutlinedIcon
                                                                    className="text-gray-300"
                                                                    data-testid={`nochanges-icon-${file.fileName}`}
                                                                />
                                                            )}
                                                        </div>
                                                    </KeyClick>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col flex-grow p-6 pb-0">
                                    <div className="text-xl flex w-full items-center justify-between">
                                        <h2>{fileName} </h2>
                                    </div>
                                    {(appConfigFile?.shields ?? []).length > 0 ? (
                                        <div className="border border-solid border-gray-300 p-4">
                                            <DropdownSelect
                                                id="config-file-shield-dropdown-btn"
                                                label={t('shield.shield')}
                                                placeholder={t('main.chooseOption')}
                                                defaultSelectedItem={configFileShieldItems.find(
                                                    item => item.id === shieldId
                                                )}
                                                getLIProps={() => {
                                                    const { className: liClasses, ...rest } = getDefaultLIProps();
                                                    return {
                                                        className: `${liClasses} w-full truncate`,
                                                        ...rest,
                                                    };
                                                }}
                                                getMenuProps={() => {
                                                    const { className: ulClasses, ...rest } = getDefaultMenuProps();
                                                    return {
                                                        className: `${ulClasses} w-1/3 `,
                                                        ...rest,
                                                    };
                                                }}
                                                getToggleButtonProps={() => {
                                                    const {
                                                        className: btnClasses,
                                                        ...rest
                                                    } = getDefaultToggleButtonProps();
                                                    return {
                                                        className: `${btnClasses} w-1/3`,
                                                        ...rest,
                                                    };
                                                }}
                                                handleSelectedItemChange={({ selectedItem }) => {
                                                    history.push(
                                                        `/application/configuration/${id}/${fileName}/${selectedItem?.id}`
                                                    );
                                                }}
                                                items={configFileShieldItems}
                                            />
                                        </div>
                                    ) : null}
                                    <textarea
                                        ref={editorRef}
                                        data-testid="config-editor"
                                        value={localConfigFile.didChange ? localConfigFile.value : configData}
                                        onChange={event => {
                                            event.persist();
                                            const config: string = event.target.value ?? '';
                                            if (config === configData) {
                                                //reset state since data has not changed
                                                setConfigFiles(selectedFileNameKey, {
                                                    value: '',
                                                    didChange: false,
                                                });
                                            } else {
                                                setConfigFiles(selectedFileNameKey, {
                                                    value: config,
                                                    didChange: true,
                                                });
                                            }
                                        }}
                                        className="w-full flex-grow whitespace-pre-wrap bg-white border-solid border border-gray-300 pt-2 px-3"></textarea>

                                    <div className="my-4 flex">
                                        <Button
                                            theme={!localConfigFile.didChange ? 'disabled' : 'secondary'}
                                            className="capitalize mr-2 flex justify-between flex-row-reverse"
                                            data-testid="refresh-file-contents-btn"
                                            onClick={() => {
                                                setConfigFiles(selectedFileNameKey, { value: '', didChange: false });
                                            }}>
                                            <RefreshIcon fontSize="small" data-testid="refresh-editor-file" />
                                            {t('main.refresh')}
                                        </Button>
                                        <Button
                                            theme={!localConfigFile.didChange ? 'disabled' : 'tertiary'}
                                            className="capitalize mr-4"
                                            data-testid="save_draft-btn"
                                            onClick={() => {
                                                doSaveConfigFile({ deploymentState: 'save_draft', fileName });
                                            }}>
                                            {isBPS ? t('main.save') : t('application.deploy')}
                                        </Button>
                                        {isBPS ? (
                                            <>
                                                <Button
                                                    theme={!localConfigFile.didChange ? 'disabled' : 'tertiary'}
                                                    data-testid="save_deploy-btn"
                                                    className="capitalize mr-4"
                                                    onClick={() => {
                                                        doSaveConfigFile({ deploymentState: 'save_deploy', fileName });
                                                    }}>
                                                    {t('application.deploy')}
                                                </Button>
                                                <Button
                                                    theme={!localConfigFile.didChange ? 'disabled' : 'tertiary'}
                                                    className="hide-element capitalize"
                                                    data-testid="save_migrate-btn"
                                                    onClick={() => {
                                                        doSaveConfigFile({ deploymentState: 'save_migrate', fileName });
                                                    }}>
                                                    {t('application.deployAndMigrate')}
                                                </Button>
                                            </>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="w-full flex justify-end items-center bg-white border-t-2 border-solid border-gray-300 h-20">
                                <Button
                                    theme="secondary"
                                    className="w-48 h-full"
                                    onClick={() => {
                                        history.push(`/`);
                                    }}
                                    size="lg">
                                    {t('main.closeWindow')}
                                </Button>
                            </div>
                        </Content>
                    </>
                );
            }}
        </Observer>
    );
};

export default AppConfiguration;
