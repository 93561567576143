import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { BaffleIconProps } from '../componentTypes';

const EditIcon = ({
    color = '#000000',
    width = 24,
    height = 24,
    viewBox = '0 0 24 24',
    fontSize = 'small',
}: BaffleIconProps) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} fontSize={fontSize}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <g>
                    <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                    <path
                        d="M3,19.6665959 L21,19.6665959 L21,21 L3,21 L3,19.6665959 Z M18.5174538,7.66595864 C19.057647,7.14510464 19.057647,6.30131252 18.5174538,5.78045852 L16.0348385,3.3896649 C15.4936967,2.8701117 14.6180648,2.8701117 14.0769231,3.3896649 L3.69230769,13.3901959 L3.69230769,17.6664897 L8.13283846,17.6664897 L18.5174538,7.66595864 Z M15.0552231,4.33178159 L17.5384615,6.72317524 L15.4615385,8.72328145 L12.9784385,6.33202114 L15.0552231,4.33178159 Z M5.07692308,16.3330855 L5.07692308,13.9423586 L11.9995154,7.2748712 L14.4825462,9.66606484 L7.55946923,16.3330855 L5.07692308,16.3330855 Z"
                        fill={color}
                        fillRule="nonzero"></path>
                </g>
            </g>
        </SvgIcon>
    );
};

export default EditIcon;
