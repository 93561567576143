const get = (key: string) => localStorage.getItem(key);
const set = (key: string, val: any) => localStorage.setItem(key, val);
const remove = (key: string) => localStorage.removeItem(key);
const clear = () => localStorage.clear();
const listKeys = () => {
    const keys: string[] = [];
    for (let i = 0; i < localStorage.length - 1; i++) {
        keys.push(localStorage.key(i) as string);
    }
    return keys;
};

export default {
    get,
    set,
    remove,
    clear,
    listKeys,
};
