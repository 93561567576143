import React, { useState, useEffect } from 'react';
import { Observer } from 'mobx-react-lite';
import { t } from '@baffle/translate';
import DataGrid from '@baffle/components/src/grid/data-grid';
import AddUserGroup from './AddUserGroup';
import EditUserGroup from './EditUserGroup';
import DeleteUserGroup from './DeleteUserGroup';
import useAwait from '@baffle/api-client/src/useAwait';
import { UserGroupStore } from '@baffle/manager/src/stores';
import { useManageModals } from '@baffle/components/src/modal/Modal';
import { RbacUsrGrpV2 } from '@baffle/graphql/src/models';
import { NotificationStore } from '../../../stores/NotificationStore';
import usePageHeight from '@baffle/utilities/src/usePageHeight';

const UserGroups = () => {
    const { status, run } = useAwait();
    const [update, setUpdate] = useState(true);
    const pageHeight = usePageHeight() - 200;

    useEffect(() => {
        if (update === true) {
            run(UserGroupStore.getRbacUserGroups());
            setUpdate(false);
        }
    }, [update]);

    const onSyncButtonClick = () => {
        setUpdate(true);
    };

    const emptyCol = {
        id: '',
        header1: '',
        header2: '',
        header3: '',
        header4: '',
    };

    const headers = [
        {
            key: 'header1',
            header: t('globalPolicies.rbacUserGroups.userGroupsHeader1'),
            sortable: true,
        },
        {
            key: 'header2',
            header: t('globalPolicies.rbacUserGroups.userGroupsHeader2'),
            sortable: true,
        },
        {
            key: 'header3',
            header: t('globalPolicies.rbacUserGroups.userGroupsHeader3'),
            sortable: true,
        },
        {
            key: 'header4',
            header: t('globalPolicies.rbacUserGroups.userGroupsHeader4'),
            sortable: true,
        },
    ];

    return (
        <Observer>
            {() => {
                interface UserGroupModals {
                    addUserGroup: boolean;
                    editUserGroup: boolean;
                    deleteUserGroup: boolean;
                }

                const initialModalState = {
                    addUserGroup: false,
                    editUserGroup: false,
                    deleteUserGroup: false,
                };

                const [modals, setModals] = useManageModals<UserGroupModals>(initialModalState);

                const userGroups: RbacUsrGrpV2[] = UserGroupStore.rbacUserGroups;

                const onAdd = () => {
                    UserGroupStore.setRbacUserGroup(null);
                    setModals({ addUserGroup: true });
                };

                const onEdit = (id: string) => {
                    UserGroupStore.setRbacUserGroup(UserGroupStore.get(id) as RbacUsrGrpV2);
                    setModals({ editUserGroup: true });
                };

                const onDelete = (id: string) => {
                    UserGroupStore.setRbacUserGroup(UserGroupStore.get(id) as RbacUsrGrpV2);
                    setModals({ deleteUserGroup: true });
                };

                const rows =
                    status === 'loading' || status === 'idle'
                        ? //add  skeleton row on initial load
                          [...Array(Math.floor(1)).keys()].map(() => emptyCol)
                        : userGroups.map((usrGrp: RbacUsrGrpV2, i: number) => {
                              return {
                                  selectedId: usrGrp?.id,
                                  id: usrGrp.id,
                                  header1: usrGrp.name,
                                  header2: usrGrp.global ? 'Yes' : 'No',
                                  header3: usrGrp.users?.join(', '),
                                  header4: usrGrp.subnets?.join(', '),
                                  link: '/rbac/usergroup/' + usrGrp.id,
                                  overFlowMenu: [
                                      {
                                          name: 'Edit',
                                          onClick: (id: string) => {
                                              onEdit(id);
                                          },
                                      },
                                      {
                                          name: 'Delete',
                                          onClick: (id: string) => {
                                              onDelete(id);
                                          },
                                      },
                                  ],
                              };
                          });

                return (
                    <>
                        <div className="overflow-y-auto" style={{ height: pageHeight }}>
                            {modals.addUserGroup ? (
                                <AddUserGroup
                                    open={modals.addUserGroup}
                                    onClose={() => setModals({ addUserGroup: false })}
                                    onSuccess={(name: string) => {
                                        NotificationStore.push({
                                            'data-testid': 'user-group-add-success',
                                            kind: 'success',
                                            title: t('globalPolicies.rbacUserGroups.createdUserGroupName', {
                                                name: name,
                                            }),
                                        });
                                    }}
                                />
                            ) : null}

                            {modals.editUserGroup ? (
                                <EditUserGroup
                                    open={modals.editUserGroup}
                                    onClose={() => setModals({ editUserGroup: false })}
                                    onSuccess={(name: string) => {
                                        NotificationStore.push({
                                            'data-testid': 'user-group-edit-success',
                                            kind: 'success',
                                            title: t('globalPolicies.rbacUserGroups.editUserGroupSuccess', {
                                                name: name,
                                            }),
                                        });
                                    }}
                                />
                            ) : null}

                            {modals.deleteUserGroup ? (
                                <DeleteUserGroup
                                    open={modals.deleteUserGroup}
                                    onClose={() => setModals({ deleteUserGroup: false })}
                                    onSuccess={(name: string) => {
                                        NotificationStore.push({
                                            kind: 'success',
                                            'data-testid': 'user-group-delete-success',
                                            title: t('globalPolicies.rbacUserGroups.deletedUserGroupName', {
                                                name: name,
                                            }),
                                        });
                                    }}
                                />
                            ) : null}
                            <DataGrid
                                idPrefix="userGroup"
                                rows={rows}
                                headers={headers}
                                onAdd={onAdd}
                                showSearch={true}
                                onSync={onSyncButtonClick}
                                addButtonCaption={t('globalPolicies.rbacUserGroups.createRbacUserGroup')}></DataGrid>
                        </div>
                    </>
                );
            }}
        </Observer>
    );
};

export default UserGroups;
