import React from 'react';
import LoginLogo from '@baffle/components/src/logo/LoginLogo';
import BrokerLogoLight from '@baffle/components/src/logo/BrokerLogoLight';
import './Setup.scss';
import { BaseCompoundComponentProps } from '@baffle/components/src/types/react';
import { t } from '@baffle/translate';

interface SetupProps extends BaseCompoundComponentProps {
    version?: string | null;
}

const Setup = ({ version, children }: SetupProps) => {
    return (
        <div className="setup flex flex-col min-h-screen justify-center items-center bg-blue-900">
            <div className="w-120">
                {version === 'IBM_CLOUD' ? (
                    <div className="flex justify-left mb-4">
                        <BrokerLogoLight />
                        <h3 className="text-white ml-2">{t('session.ibmLoginHeader')}</h3>
                    </div>
                ) : (
                    <div className="flex justify-center mb-8">
                        <LoginLogo />
                    </div>
                )}
            </div>
            {children}
        </div>
    );
};

export default Setup;
