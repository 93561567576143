import { useState, useRef } from 'react';
import Joi from '@hapi/joi';
import { DataProtectionItem } from '@baffle/graphql/src/models';

export interface InputState {
    [key: string]: string | number | boolean | File | Blob | null | undefined | any[] | DataProtectionItem;
}

export interface InputError {
    [key: string]: any;
}

export interface ValidationSchema {
    [key: string]: any;
}

export interface ResetOpts {
    fields?: string[];
}
export interface InputActions {
    setField: (field: string, value: any) => void;
    setFields: (fields: Fields) => void;
    setError: (field: string, error: any) => void;
    validateField: (args: { field: string; skipNull: boolean }) => void;
    validateAll: (skipSetErrors: boolean) => boolean;
    validateIndividualField: (args: { field: string; skipNull: boolean }) => boolean;
    isValid: () => void | boolean;
    reset: (opts?: ResetOpts) => void;
    clearError: (field: string) => void;
    clearErrors: () => void;
    getErrorDetails: (abortEarly?: boolean) => any;
}

export interface InputStateArg {
    //namespace for localization
    ns:
        | 'dataformat'
        | 'shield'
        | 'session'
        | 'encryption'
        | 'database'
        | 'keystore'
        | 'application'
        | 'profile'
        | 'setup'
        | 'users'
        | 'table'
        | 'schema'
        | 'decryption';
    keys: string[];
    defaultValues?: InputState;
    validationSchema: any;
}

export interface Fields {
    [field: string]: string | number | boolean | File | null | undefined | any[] | DataProtectionItem;
}

// create v2 of useInputState where the Fields are passed in via a type map
// ie:
// interface InputState {
//     name: string;
//     file: File;
//     ...
// }
export function useInputState(opts: InputStateArg) {
    const {
        keys,
        defaultValues = {},
        validationSchema,
        // ns = 'main'
    } = opts;
    const keysSchema = keys.reduce((acc: InputState, k: string) => {
        acc[k] = null;
        return acc;
    }, {});

    const initialState = useRef(keysSchema); //sets it to the very first one

    const [inputState, setInputState]: [InputState, any] = useState(
        Object.assign({}, initialState.current, defaultValues)
    );

    const [inputErrors, setErrors]: [InputError, any] = useState(initialState.current);

    const inputActions: InputActions = {
        setField(field, value) {
            const newState = Object.assign({}, inputState, { [field]: value });
            setInputState(newState);
        },
        setFields(fields) {
            const newState = Object.assign({}, inputState, fields);
            setInputState(newState);
        },
        setError(field, error) {
            const newErrors = Object.assign({}, inputErrors, { [field]: error });
            setErrors(newErrors);
        },
        validateField(opts: { field: string; skipNull: boolean }) {
            const { field, skipNull } = opts;

            if (skipNull) {
                if (inputState[field] === null || inputState[field] === undefined) {
                    return;
                }
            }
            const schema = validationSchema[field];
            const result = schema.validate(inputState[field]);
            if (result.error) {
                //no longers sets the error message based  on translation lib
                // this.setError(field, t(`${ns}.${field}InputInvalid`));
                this.setError(field, result.error.message);
            } else {
                this.clearError(field);
            }
        },
        validateIndividualField(opts: { field: string; skipNull: boolean }) {
            const { field, skipNull } = opts;

            if (skipNull) {
                if (inputState[field] === null || inputState[field] === undefined) {
                    return true;
                }
            }
            const schema = validationSchema[field];
            const result = schema.validate(inputState[field]);
            return !result.error;
        },
        validateAll(skipSetErrors = false) {
            const schema = Joi.object(validationSchema);
            const result = schema.validate(inputState, { allowUnknown: true });
            if (result.error) {
                if (!skipSetErrors) {
                    if (result?.error?.details) {
                        const errorKey = result.error.details[0].path[0];
                        // this.setError(errorKey as string, t(`${ns}.${errorKey}InputInvalid`));
                        this.setError(errorKey as string, result.error.message);
                    }
                }
                return false;
            } else {
                if (!skipSetErrors) {
                    this.clearErrors();
                }
                return true;
            }
        },
        getErrorDetails(abortEarly = true) {
            const schema = Joi.object(validationSchema);
            const result = schema.validate(inputState, { allowUnknown: true, abortEarly });
            if (result.error) {
                return result?.error?.details;
            } else {
                return null;
            }
        },
        isValid() {
            return this.validateAll(true);
        },
        reset(opts = {}) {
            const { fields } = opts;
            if (fields) {
                const resetFields = fields.reduce((acc: any, k: string) => {
                    acc[k] = null;
                    return acc;
                }, {});
                const newInputState = Object.assign({}, inputState, resetFields, defaultValues);
                const newErrorState = Object.assign({}, inputErrors, resetFields);
                setInputState(newInputState);
                setErrors(newErrorState);
            } else {
                const newInputState = Object.assign({}, initialState.current, defaultValues);
                setInputState(newInputState);
                setErrors(initialState.current);
            }
        },
        clearError(field: string) {
            const newErrors = Object.assign({}, inputErrors, { [field]: null });
            setErrors(newErrors);
        },
        clearErrors() {
            setErrors(initialState.current);
        },
    };

    return { inputState, inputErrors, inputActions, keys };
}
