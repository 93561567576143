import React from 'react';
import { Observer } from 'mobx-react-lite';
import Content from '@baffle/components/src/content/Content';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';
import { DatabaseProxiesStore } from '@baffle/manager/src/stores';
import { useManageModals } from '@baffle/components/src/modal/Modal';
import PageHeader from '@baffle/components/src/pageheader/pageheader';
import { t } from '@baffle/translate';
import RenameDataBaseProxyModal from '../RenameDataBaseProxyModal';
import DeleteDataBaseProxyModal from '../DeleteDataBaseProxyModal';
import DatabaseProxyTabs from './DatabaseProxyTabs';

const DatabaseProxyDetails = () => {
    return (
        <Observer>
            {() => {
                const databaseProxy = DatabaseProxiesStore.databaseProxy;

                interface DatabaseProxyModals {
                    renameDataBaseProxy: boolean;
                    deleteDataBaseProxy: boolean;
                }

                const initialModalState = {
                    renameDataBaseProxy: false,
                    deleteDataBaseProxy: false,
                };

                const [modals, setModals] = useManageModals<DatabaseProxyModals>(initialModalState);

                const actions = [
                    {
                        id: '1',
                        label: t('databaseProxies.databaseProxy.renameDatabaseProxy'),
                    },
                    {
                        id: '2',
                        label: t('databaseProxies.databaseProxy.deleteDatabaseProxy'),
                    },
                ];

                const breadcrumbData = [
                    {
                        label: t('databaseProxies.databaseProxy.databaseProxies'),
                        href: '/database-proxies',
                    },
                ];

                const onSelectAction = (item: any) => {
                    switch (item.selectedItem.label) {
                        case t('databaseProxies.databaseProxy.renameDatabaseProxy'):
                            setModals({ renameDataBaseProxy: true });
                            break;
                        case t('databaseProxies.databaseProxy.deleteDatabaseProxy'):
                            setModals({ deleteDataBaseProxy: true });
                            break;
                        default:
                            break;
                    }
                };

                return (
                    <>
                        <Content>
                            {modals.renameDataBaseProxy ? (
                                <RenameDataBaseProxyModal
                                    open={modals.renameDataBaseProxy}
                                    onClose={() => setModals({ renameDataBaseProxy: false })}
                                    onSuccess={(name: string) => {
                                        NotificationStore.push({
                                            'data-testid': 'database-proxy-rename-success',
                                            title: t('databaseProxies.renameDataBaseSuccess', { name }),
                                            kind: 'success',
                                        });
                                    }}
                                />
                            ) : null}

                            {modals.deleteDataBaseProxy ? (
                                <DeleteDataBaseProxyModal
                                    open={modals.deleteDataBaseProxy}
                                    onClose={() => setModals({ renameDataBaseProxy: false })}
                                    onSuccess={(name: string) => {
                                        NotificationStore.push({
                                            kind: 'success',
                                            'data-testid': 'database-proxy-delete-success',
                                            title: t('databaseProxies.deleteDataBaseSuccess', {
                                                name: name,
                                            }),
                                        });
                                    }}
                                />
                            ) : null}
                            <PageHeader
                                title={databaseProxy?.name as string}
                                actions={actions}
                                breadcrumbData={breadcrumbData}
                                onActionSelect={onSelectAction}
                            />
                            <DatabaseProxyTabs />
                        </Content>
                    </>
                );
            }}
        </Observer>
    );
};

export default DatabaseProxyDetails;
