import React, { useEffect, useState } from 'react';
import { Observer } from 'mobx-react-lite';
import { t } from '@baffle/translate';
import SyncIcon from '@material-ui/icons/Sync';
import Button from '@baffle/components/src/buttons/Button';
import { SystemEmailStore } from '@baffle/manager/src/stores';
import { ToastStore } from '@baffle/manager/src/stores/ToastStore';
import useAwait from '@baffle/api-client/src/useAwait';
import { HttpsCertificate } from '@baffle/graphql/src/models';
import { format } from '@baffle/utilities/src/date';

const TLSConfigTable = () => {
    const { run, error } = useAwait();
    const [refreshCertificate, setRefreshCertificate] = useState(false);
    const env = SystemEmailStore.systemEnvironment;
    useEffect(() => {
        run(SystemEmailStore.getHttpsCertificateValues());
    }, []);

    useEffect(() => {
        if (error) {
            ToastStore.push({ type: 'danger', title: error.message });
        }
    }, [error]);
    return (
        <Observer>
            {() => {
                const httpsCertificateValues: HttpsCertificate | null = SystemEmailStore.httpsCertificateValues;
                if (refreshCertificate && !httpsCertificateValues?.certificatePath) {
                    ToastStore.push({ type: 'danger', title: t('settings.certificateNotFound') });
                    setRefreshCertificate(false);
                }
                return (
                    <div className="flex-col pb-6">
                        <div className="flex justify-between items-end text-lg">
                            <div className="flex flex-col">
                                <p className="text-lg font-bold mb-1">{t('settings.tlsCertificate')}</p>
                                <p className="text-sm text-gray-500">{t(`settings.configureTLS.${env}`)}</p>
                            </div>
                            <Button
                                theme="primary"
                                className="h-full"
                                id="refresh-certificate-btn"
                                onClick={() => {
                                    run(
                                        SystemEmailStore.refreshHttpsCertificate().then(() => {
                                            setRefreshCertificate(true);
                                            ToastStore.push({
                                                title: t('settings.httpsConfigSuccess'),
                                                type: 'success',
                                            });
                                        })
                                    );
                                }}>
                                <SyncIcon data-testid="refresh-certificate-btn" className="w-4 h-4" />
                            </Button>
                        </div>

                        <table className="w-full mt-2 text-xsmall text-left">
                            <thead className="h-12 border-solid border border-t border-gray-300 bg-gray-20 font-bold text-sm">
                                <tr className="">
                                    <th scope="col" className="p-3 w-2/6">
                                        {t('settings.certificate')}
                                    </th>
                                    <th scope="col" className="p-3 w-1/6">
                                        {t('settings.subjectName')}
                                    </th>
                                    <th scope="col" className="p-3 w-1/6">
                                        {t('settings.issuer')}
                                    </th>
                                    <th scope="col" className="p-3 w-2/6">
                                        {t('settings.expiryDate')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="h-12 border-solid border-l border-r border-b border-gray-300">
                                <tr className="bg-white">
                                    <td className="p-3 w-1/4" data-testid="https-certificate">
                                        {httpsCertificateValues?.certificatePath}{' '}
                                    </td>
                                    <td className="p-3 w-1/4" data-testid="https-subjectName">
                                        {httpsCertificateValues?.subject}{' '}
                                    </td>
                                    <td className="p-3 w-1/4" data-testid="https-issuer">
                                        {httpsCertificateValues?.issuer}{' '}
                                    </td>
                                    <td className="p-3 w-1/4" data-testid="https-expiryDate">
                                        {httpsCertificateValues?.expiry
                                            ? format(httpsCertificateValues.expiry, 'MM-dd-yyyy')
                                            : ''}{' '}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                );
            }}
        </Observer>
    );
};

export default TLSConfigTable;
