import client from '.';

export async function read() {
    return client('/api/v1.0/user');
}

export async function updatePassword(payload: { oldPassword: string; newPassword: string }) {
    return client('/api/public/v1.0/user/password', { body: payload, method: 'PUT' });
}

export default {
    read,
    updatePassword,
};
