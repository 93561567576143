import { useEffect, useState } from 'react';

const useShiftKeyPress = () => {
    const [shiftPress, setShiftPress] = useState(false);
    const onShiftDown = (e: KeyboardEvent) => {
        if (e.keyCode === 16) {
            setShiftPress(true);
        }
    };
    const onShiftUp = (e: KeyboardEvent) => {
        if (e.keyCode === 16) {
            setShiftPress(false);
        }
    };
    useEffect(() => {
        document.addEventListener('keydown', onShiftDown);
        document.addEventListener('keyup', onShiftUp);
        return () => {
            document.removeEventListener('keydown', onShiftDown);
            document.removeEventListener('keyup', onShiftUp);
        };
    }, []);
    return shiftPress;
};

export default useShiftKeyPress;
