import { BaseThemeEnum } from '@baffle/components/src/types/theme';
import { SshKey } from '.';
import { GenericLink } from './generic';

export type ShieldDeploymentModel = 'SSH' | 'AWSCF' | 'MANUAL' | '';

export type ShieldState =
    | 'Processing'
    | 'Running'
    | 'Error'
    | 'Error_1'
    | 'Error_2'
    | 'Stopped'
    | 'Uninstalling'
    | 'Installing'
    | 'Idle';

export const ShieldStatusThemeEnum = {
    Processing: BaseThemeEnum.BLUE,
    Running: BaseThemeEnum.GREEN,
    Uninstalling: BaseThemeEnum.BLUE,
    Installing: BaseThemeEnum.BLUE,
    Error: BaseThemeEnum.RED,
    Error_1: BaseThemeEnum.RED,
    Error_2: BaseThemeEnum.RED,
    Stopped: BaseThemeEnum.GRAY,
    Idle: BaseThemeEnum.GRAY,
};

export type ShieldResourceType = 'ShieldListItem' | 'Shield' | 'SshKey';

export interface ShieldForErrors {
    id: string;
    name: string;
    applicationName: string;
}
export interface ShieldForApplicationDetails extends GenericLink {
    id: string;
    host: string;
    tag: string;
    port: number;
    status: ShieldState;
}
export interface ShieldListItem {
    id: string;
    shieldName: string;
    shieldDescription: string | null;
    state: ShieldState;
    port: number;
    hostName: string; //this is used for the GET response body
    hostPassword: string | null;
    hostUser: string;
    ssl: boolean;
    useSshKey: boolean;
    certSource: 'upload' | 'generate';
    details?: Shield;
    deploymentModel: ShieldDeploymentModel;
    cloudId: string | null;
    shieldDeployment: string | null;
    bmIPAddress: string | null;
    hostSshKey: SshKey | null;
    applications: string[];
    createTime: number;
    createdBy: string;
    polledTime: number;
    tempDir: string;
}

export interface Shield {
    shieldName: string;
    shieldDescription: string;
    port: number;
    hostName?: string;
    hostUser?: string;
    hostPassword?: string;
    hostSshKey?: SshKey;
    state: ShieldState;
    applications: string[];
    id: string; //this is premptive id
    createTime: number;
    createdBy: string;
    updateTime: number;
    deploymentModel?: ShieldDeploymentModel;
    cloudId?: string;
    shieldDeployment?: string;
    debugLevel: number;
    debugProps: ConfigProps;
    pollProps: ConfigProps;
    logFileProps: ConfigProps;
    wcProps: ConfigProps;
    certIssuerDN?: string;
    certSubjectSAN?: string;
    certSubjectDN?: string;
    certExpiry?: number;
    expiresSoon?: boolean; //added by typepatche on the client
    tag?: string;
    tempDir: string;
    xssValue?: number;
}

export interface ConfigProps {
    enable: boolean;
    shieldConfig: ShieldConfig | ShieldDebugConfig;
}
export interface ShieldConfig {
    id: string;
    shieldID: string;
    configType: string;
    configValue: object;
    status: string;
    stage: string;
}

export interface ShieldDebugConfig {
    id: string;
    shieldID: string;
    configType: string;
    configValue: object;
    status: string;
    stage: string;
}

export type DebugLevels = 0 | 8 | 24 | 27;
