import React, { useEffect } from 'react';
import SlideOutPanel from '@baffle/components/src/content/SlideOutPanel';
import { t } from '@baffle/translate';
import CloseIcon from '@material-ui/icons/Close';
import useAwait from '@baffle/api-client/src/useAwait';
import Button from '@baffle/components/src/buttons/Button';
import SkeletonText from '@baffle/components/src/skeleton/SkeletonText';
import DefaultLink from '@baffle/components/src/links/DefaultLink';
import { Observer } from 'mobx-react-lite';
import { VersionStore } from '../stores';
import { SystemVersionInfo } from '@baffle/graphql/src/models';

interface props {
    open: boolean;
    toggleHelp?: () => void;
    didClose?: () => void;
    didOpen?: () => void;
    willClose?: () => void;
    willOpen?: () => void;
}

const IBMHelpSidePanel = ({ open, toggleHelp = () => null }: props) => {
    const { status, run } = useAwait();
    useEffect(() => {
        if (open) {
            run(VersionStore.readCurrentVersion({ networkPolicy: 'cache-first' }));
        }
    }, [open]);

    const target = document.getElementById('baffle-side-nav');
    if (!target) {
        return null;
    }
    const handleClose = () => {
        toggleHelp();
    };
    const version: SystemVersionInfo = VersionStore.currentVersion ?? { bm: '' };

    return (
        <SlideOutPanel target={target as HTMLElement} open={open}>
            <div className="p-4 flex flex-col h-full">
                <div className="flex flex-col h-full justify-between text-xs">
                    {/* panel body */}
                    <div>
                        <div className="text-2xl flex justify-between items-center mb-6">
                            <div>{t('support.help')}</div>
                            <Button
                                theme="icon-transparent"
                                data-testid="close-help-panel-btn"
                                onClick={handleClose}
                                id="close-help-panel-btn">
                                <CloseIcon />
                            </Button>
                        </div>
                        <div>
                            <div className="uppercase font-semibold">{t('support.supportResources')}</div>
                            <div className="my-3">
                                <DefaultLink data-testid="support-portal-link" url={t('support.ibmSupportPortalLink')}>
                                    {t('support.ibmSupportPortal')}
                                </DefaultLink>
                            </div>
                        </div>
                    </div>
                    {/* panel footer */}
                    <div className="mb-12">
                        <div className="flex flex-1">
                            <div className="font-semibold">{t('support.version')}</div>
                            <Observer>
                                {() => {
                                    return (
                                        <div className="ml-1" data-testid="current-baffle-version">
                                            {status === 'loading' ? <SkeletonText /> : version.bm}
                                        </div>
                                    );
                                }}
                            </Observer>
                        </div>
                    </div>
                </div>
            </div>
        </SlideOutPanel>
    );
};
export default IBMHelpSidePanel;
