import { t } from '@baffle/translate';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Modal } from 'carbon-components-react';

interface DeleteRbacPolicyRuleModalProps {
    open: boolean;
    name: string;
    onClose?: () => void;
    onSubmit?: () => void;
}

const DeleteRbacPolicyRuleModal = ({ open, name, onClose, onSubmit }: DeleteRbacPolicyRuleModalProps) => {
    return (
        <Observer>
            {() => {
                return (
                    <Modal
                        open={open}
                        className="modal-container-white"
                        modalHeading={t('rbac.rbacPolicy.deleteRbacRuleModalHeading', {
                            name: name,
                        })}
                        danger={true}
                        id="delete-rbac-rule-modal"
                        onRequestClose={onClose}
                        primaryButtonText={t('main.delete')}
                        secondaryButtonText={t('main.cancel')}
                        size="sm"
                        onRequestSubmit={onSubmit}>
                        <p>{t('rbac.rbacPolicy.deleteRbacRuleModalMessage')}</p>
                    </Modal>
                );
            }}
        </Observer>
    );
};

export default DeleteRbacPolicyRuleModal;
