import { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';

const usePageHeight = () => {
    const [pageHeight, setPageHeight] = useState(
        Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
    );

    //add window resize event listener
    useEffect(() => {
        const handleResize = debounce(() => {
            setPageHeight(Math.max(document.documentElement.clientHeight, window.innerHeight || 0));
        }, 50);
        window.addEventListener('resize', handleResize);

        //remove window resize event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    return pageHeight;
};
export default usePageHeight;
