import client from '.';
import { t } from '@baffle/translate';
import {
    KeystoreInputSafenet,
    KeystoreInputLocal,
    KeystoreInputAWS,
    KeystorePayload,
} from '@baffle/graphql/src/models';
import { KeyStoreEnums } from '@baffle/utilities/src/enums';

export async function list() {
    return client('api/v1.0/keystores');
}
export async function read(id: string) {
    const ks = await client(`api/v1.0/keystore/${id}`);
    if (ks && ks.type === KeyStoreEnums.SAFENET_KEY_SECURE) {
        const deltaT = (ks.certExpiry || 0) - Date.now();
        const ttl = 2592000000; //30 days in ms
        ks.expiresSoon = deltaT <= ttl;
        ks.expired = deltaT <= 0;
    }
    return ks;
}

export async function getHashiKS(id: string) {
    const ks = await client(`api/v2/keystore/details/HASHI_VAULT/${id}`);
    return ks;
}

export async function updateCert(id: string, cert: File) {
    if (!cert) {
        throw new Error(t('main.certInputEmpty'));
    }

    return client('api/v1.0/keystore/ssl/uploadCert', { body: { cert, id }, useForm: true, method: 'POST' });
}
export async function create(input: KeystoreInputSafenet | KeystoreInputLocal | KeystoreInputAWS) {
    //@ts-ignore
    if (input.type === KeyStoreEnums.SAFENET_KEY_SECURE && !Boolean(input?.cert)) {
        throw new Error(t('main.certInputEmpty'));
    }
    const payload: KeystorePayload = serializeKeystorePayload(input);
    return client('api/v1.0/keystore', { body: payload, useForm: true, method: 'POST' });
}

export async function edit(input: any) {
    return client('api/v2/keystore/edit', { body: input, method: 'PATCH' });
}

const serializeKeystorePayload = (input: KeystoreInputSafenet | KeystoreInputLocal | KeystoreInputAWS) => {
    const payload = {
        keystore: {
            name: input.name,
            description: input.description,
            type: input.type,
            details: Object.assign({}, input),
        },
        //@ts-ignore
        ...(input?.cert ? { jks: input.cert } : {}),
        //@ts-ignore
        ...(input?.vaultTruststore ? { jks: input.vaultTruststore } : {}),
    };
    switch (input.type) {
        case KeyStoreEnums.SAFENET_KEY_SECURE:
            //@ts-ignore
            payload.keystore.details.appNamespace = `x-${payload.keystore.details.appNamespace}`;
            break;
        case KeyStoreEnums.AWS_KMS:
        case KeyStoreEnums.CLOUD_HSM:
            //@ts-ignore
            payload.keystore.details.appNamespace = payload.keystore.details.appNamespace + '-';
            break;
        case KeyStoreEnums.HASHI_VAULT:
            //@ts-ignore
            payload.keystore.details.appNamespace = payload.keystore.details.appNamespace + '-';
            //@ts-ignore
            payload.keystore.details.vaultTruststore = '{}';
        default:
            break;
    }

    //@ts-ignore
    delete payload.keystore.details.name;
    //@ts-ignore
    delete payload.keystore.details.description;

    return payload;
};

export async function readKeyDetails(id: string) {
    return await client(`api/v1.0/keystore/keysList/${id}`);
}

export async function remove(id: string) {
    return await client(`api/v1.0/keystore/unenroll/${id}`, { method: 'DELETE' });
}

export async function getKeystoreTypes() {
    return client('api/v1.0/keystore/types');
}

export default {
    list,
    create,
    read,
    readKeyDetails,
    updateCert,
    remove,
    getHashiKS,
    edit,
    getKeystoreTypes,
};
