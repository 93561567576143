import React, { useEffect, useState } from 'react';
import Header from '@baffle/components/src/nav/Header';
import { t } from '@baffle/translate';
import Content from '@baffle/components/src/content/Content';
import Button from '@baffle/components/src/buttons/Button';
import AddIcon from '@material-ui/icons/Add';
import { Search } from 'carbon-components-react';
import Divider from '@baffle/components/src/content/Divider';
import UserCard from './UserCard';
import './Users.scss';
import { User } from '@baffle/graphql/src/models';
import useAwait from '@baffle/api-client/src/useAwait';
import { UsersStore, SystemEmailStore } from '../stores';
import { ToastStore } from '../stores/ToastStore';
import { SkeletonUserCard } from '@baffle/components/src/skeleton/SkeletonCard';
import { Observer } from 'mobx-react-lite';
import AddIbmUserModal from './AddIbmUserModal';
import AddUserModal from './AddUserModal';
import ReInviteUserModal from './ReInviteUserModal';
import DeleteUserModal from './DeleteUserModal';
import { useManageModals } from '@baffle/components/src/modal/Modal';
import { filterByQuery } from '@baffle/utilities/src/user/search';
import { NotificationStore } from '../stores/NotificationStore';

const Users = () => {
    const { status, error, run } = useAwait();
    const clientEnvironment = SystemEmailStore.systemEnvironment;
    useEffect(() => {
        run(UsersStore.list({ networkPolicy: 'cache-first' }));
    }, []);

    const initialModalState: { addUser: boolean; removeUser: boolean; reInvite: boolean; data: any } = {
        addUser: false,
        removeUser: false,
        reInvite: false,
        data: {},
    };

    const [modals, setModals] = useManageModals(initialModalState);

    const initialResults: { results: User[]; query: string } = { results: [], query: '' };
    const [filtered, setFiltered] = useState(initialResults);

    useEffect(() => {
        if (error) {
            ToastStore.push({ type: 'danger', title: error.message });
        }
    }, [error]);

    return (
        <>
            <Content>
                <Header>
                    <div className="flex-1 px-4 flex justify-between">
                        <div className="flex-1 flex items-center">
                            <h1 className="text-heading-01 text-gray-100">{t('users.headerName')}</h1>
                        </div>
                    </div>
                    <div className="mx-4 flex text-md items-center h-full">
                        <Button
                            theme="primary"
                            className="h-12 w-40 flex justify-between flex-row-reverse"
                            id="add-user-btn"
                            aria-label={t('users.ariaAddUser')}
                            onClick={() => {
                                setModals({ addUser: true });
                            }}>
                            <AddIcon className="w-4 h-4" /> {t('users.people')}
                        </Button>
                    </div>
                </Header>
                <div className="flex flex-wrap w-full">
                    <div className="w-full px-4">
                        <Observer>
                            {() => {
                                return (
                                    <Search
                                        size="sm"
                                        closeButtonLabelText={t('main.ariaCloseSearch')}
                                        className="data-grid-search w-1/4"
                                        labelText={t('users.ariaSearch')}
                                        placeholder={t('users.ariaSearch')}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const query = e.target.value || '';
                                            setFiltered({ results: filterByQuery(query, UsersStore.users), query });
                                        }}
                                        id="filter-users-search"
                                        autoComplete="none"
                                    />
                                );
                            }}
                        </Observer>
                    </div>
                    <Divider classNames="mb-0" />
                    <Observer>
                        {() => {
                            const users = filtered.query !== '' ? filtered.results : UsersStore.users;
                            return (
                                <ul className="calc-height-for-users-grid grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 px-4 flex-grow overflow-y-scroll pt-4">
                                    {status === 'loading'
                                        ? new Array(3).fill(null).map((val, i) => <SkeletonUserCard key={i} />)
                                        : users.map((user: User) => {
                                              return (
                                                  <UserCard
                                                      onRemoveUser={() => {
                                                          setModals({ removeUser: true, data: { user } });
                                                      }}
                                                      onResendInvite={() => {
                                                          setModals({ reInvite: true, data: { user } });
                                                      }}
                                                      key={user.id}
                                                      user={user}
                                                  />
                                              );
                                          })}
                                </ul>
                            );
                        }}
                    </Observer>
                </div>
            </Content>
            {clientEnvironment === 'IBM_CLOUD' ? (
                <AddIbmUserModal
                    open={modals.addUser}
                    onSuccess={() => {
                        NotificationStore.push({
                            'data-testid': 'user-invite-success',
                            kind: 'success',
                            title: t('users.inviteSuccess'),
                            description: t('users.inviteSuccessDescription'),
                        });
                        run(UsersStore.list());
                    }}
                    onClose={() => {
                        setModals({ addUser: false });
                    }}
                />
            ) : (
                <AddUserModal
                    open={modals.addUser}
                    onSuccess={() => {
                        run(UsersStore.list());
                    }}
                    onClose={() => {
                        setModals({ addUser: false });
                    }}
                />
            )}

            <Observer>
                {() => {
                    return (
                        <>
                            <ReInviteUserModal
                                open={modals.reInvite}
                                user={modals.data.user}
                                onClose={() => {
                                    setModals({ reInvite: false });
                                }}
                            />
                            <DeleteUserModal
                                open={modals.removeUser}
                                user={modals.data.user}
                                onSuccess={(fullName: string) => {
                                    run(UsersStore.list());
                                    setModals({ removeUser: false });
                                    ToastStore.push({
                                        type: 'success',
                                        title: t('users.removeSuccess', { name: fullName }),
                                    });
                                }}
                                onClose={() => {
                                    setModals({ removeUser: false });
                                }}
                            />
                        </>
                    );
                }}
            </Observer>
        </>
    );
};

export default Users;
