import React, { useEffect } from 'react';
import Button from '@baffle/components/src/buttons/Button';
import TextInput from '@baffle/components/src/forms/TextInput';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import Joi from '@hapi/joi';
import { t } from '@baffle/translate';
import { useHistory } from 'react-router-dom';
import InlineToast from '@baffle/components/src/toasts/InlineToast';
import useEnterKeyPress from '@baffle/utilities/src/useEnterKeyPress';
import { useSetToast } from '@baffle/components/src/toasts/Toast';
import { InitialConfiguration, SuperAdminInfo, SystemInfo } from '@baffle/graphql/src/models';
import { SetupStore, SystemEmailStore } from '../stores';
import { InlineLoading } from 'carbon-components-react';
import useAwait from '@baffle/api-client/src/useAwait';
import sessionClient from '@baffle/api-client/src/sessionClient';

const RegisterAdminUser = () => {
    const history = useHistory();
    const { toast, setToast } = useSetToast();
    const systemData: SystemInfo | null = SetupStore.systemData;

    useEffect(() => {
        if (!systemData?.domain) {
            history.replace('/configure-system');
        }
    }, [systemData]);

    const { inputState, inputErrors, inputActions } = useInputState({
        keys: ['email'],
        ns: 'setup',
        defaultValues: { email: '' },
        validationSchema: {
            email: Joi.string()
                .email({ tlds: false })
                .custom(val => {
                    const domain = systemData?.domain;
                    const regex = new RegExp('@' + '?[a-zA-Z]+.' + domain + '$');
                    if (!regex.test(val)) {
                        throw new Error(t('setup.sameDomain', { domain }));
                    }

                    return val;
                })
                .required()
                .messages({
                    'any.custom': t('setup.sameDomain', { domain: systemData?.domain }),
                    'string.email': t('setup.emailInputInvalid'),
                    'string.empty': t('setup.emailInputInvalid'),
                }),
        },
    });

    const isValid = inputActions.validateAll(true);

    const env = SystemEmailStore.systemEnvironment;
    const { run, status, error } = useAwait();

    useEffect(() => {
        if (error) {
            setToast({ show: true, type: 'danger', title: error.message ?? t('setup.initialRegisterError') });
        }
    }, [error]);

    const doSetInitialRegister = async (setInitialRegister: boolean) => {
        const superAdminData: SuperAdminInfo = {
            firstName: null,
            lastName: null,
            email: inputState.email as string,
            phone: null,
            password: null,
            confirmPassword: null,
        };
        SetupStore.setSuperAdminData(superAdminData);
        if (setInitialRegister) {
            const payload: InitialConfiguration = {
                systemSettings: {
                    ...(env === 'IBM_CLOUD' && {
                        initPass: systemData?.initPass ?? '',
                    }),
                    domain: systemData?.domain ?? '',
                    organization: systemData?.organization ?? '',
                    publicAccess: systemData?.publicAccess ?? '',
                    proxyAccess: systemData?.proxyAccess ?? '',
                    id: systemData?.id ?? '',
                },
                superAdminDetails: {
                    firstName: superAdminData?.firstName ?? null,
                    lastName: superAdminData?.lastName ?? null,
                    email: superAdminData?.email ?? '',
                    phone: superAdminData?.phone ?? null,
                    password: superAdminData?.password ?? null,
                    confirmPassword: superAdminData?.confirmPassword ?? null,
                },
            };
            run(
                sessionClient.setInitialConfiguration(payload).then(() => {
                    history.replace('/login', {
                        show: true,
                        type: 'success',
                        title: t(`setup.success.${env}`),
                    });
                })
            );
        } else {
            history.replace('/configure-system');
        }
    };

    useEnterKeyPress(() => {
        if (isValid) {
            doSetInitialRegister(true);
        }
    });

    const ibmAdminData = SetupStore.superAdminData;

    useEffect(() => {
        inputActions.setFields({
            email: ibmAdminData?.email,
        });
    }, [ibmAdminData]);

    return (
        <div className="w-132">
            <div className="bg-white py-4 px-8">
                {toast.show ? (
                    <InlineToast
                        className="mb-5"
                        duration={100000}
                        title={toast.title}
                        type={toast.type}
                        onDismiss={() => setToast({ title: '', show: false, type: 'success' })}
                    />
                ) : null}

                <div className="my-4 text-lg">
                    <span className="font-bold">{t('setup.step2')}</span> {t('setup.createAdminUser')}
                </div>
                <div className="mb-6">
                    <TextInput
                        name="setup-email-input"
                        id={`setup-email-input`}
                        labelText={t('setup.emailInputLabel')}
                        placeholder={t('setup.emailInputPlaceholder')}
                        invalid={Boolean(inputErrors.email)}
                        invalidText={inputErrors.email}
                        type="text"
                        value={(inputState.email as string) ?? ''}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            inputActions.validateField({ field: 'email', skipNull: true });
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value || '';
                            inputActions.setFields({ email: val.trim() });
                        }}
                    />
                </div>
                {status === 'loading' ? (
                    <InlineLoading description={t('main.configuring')} />
                ) : (
                    <div className="w-100 flex justify-end h-10">
                        <Button className="w-1/5" theme="secondary" onClick={() => doSetInitialRegister(false)}>
                            {t('setup.back')}
                        </Button>
                        <Button
                            className="w-1/5"
                            theme={isValid ? 'primary' : 'disabled'}
                            id="do-initial-register-btn"
                            disabled={!isValid}
                            onClick={() => doSetInitialRegister(true)}>
                            {t('setup.finish')}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default RegisterAdminUser;
