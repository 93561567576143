import React from 'react';

const BrokerLogoLight = () => {
    return (
        <div className="w-8 h-8">
            <svg id="CloudDataSecurityBroker" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <defs>
                    <linearGradient
                        id="xdwypl1w5a"
                        x1="404.418"
                        y1="64.592"
                        x2="404.418"
                        y2="68.592"
                        gradientTransform="translate(-380.418 -40.592)"
                        gradientUnits="userSpaceOnUse">
                        <stop offset="0" />
                        <stop offset="1" stopOpacity="0" />
                    </linearGradient>
                    <linearGradient id="1otidaaboc" y1="32" x2="32" gradientUnits="userSpaceOnUse">
                        <stop offset=".1" stopColor="#3ddbd9" />
                        <stop offset=".9" stopColor="#4589ff" />
                    </linearGradient>
                    <mask id="pdu9xk5kmb" x="0" y="0" width="32" height="32" maskUnits="userSpaceOnUse">
                        <path d="M26 17v8h-4v2h4a2 2 0 0 0 2-2v-8" style={{ fill: '#fff' }} />
                        <path transform="rotate(-90 24 26)" style={{ fill: 'url(#xdwypl1w5a)' }} d="M22 24h4v4h-4z" />
                        <path
                            d="M4 30h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2zm0-6h16v4H4zM9 8H3v6h6zm-2 4H5v-2h2z"
                            style={{ fill: '#fff' }}
                        />
                    </mask>
                </defs>
                <g style={{ mask: 'url(#pdu9xk5kmb)' }}>
                    <path style={{ fill: 'url(#1otidaaboc)' }} d="M0 0h32v32H0z" />
                </g>
                <path
                    d="M29 7h-1V4.182A3.356 3.356 0 0 0 24.5 1 3.356 3.356 0 0 0 21 4.182V7h-1a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1zm-6-2.818A1.388 1.388 0 0 1 24.5 3 1.388 1.388 0 0 1 26 4.182V7h-3zM28 13h-7V9h7z"
                    style={{ fill: '#f4f4f4' }}
                />
                <circle cx="7" cy="26" r="1" style={{ fill: '#f4f4f4' }} />
                <path style={{ fill: '#f4f4f4' }} d="M15 10h2v2h-2zM11 10h2v2h-2z" />
            </svg>
        </div>
    );
};

export default BrokerLogoLight;
