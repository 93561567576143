import { TenantInfo } from '@baffle/graphql/src/models';
import client from '.';

export async function addTenant(payload: TenantInfo) {
    return client('/api/v2/tenant', { body: payload, method: 'POST' });
}

export async function updateTenant(payload: TenantInfo) {
    return client('/api/v2/tenant', { body: payload, method: 'PUT' });
}

export async function getTenants() {
    return client('/api/v2/tenants');
}

export async function getTenant(id: string) {
    return client(`/api/v2/tenant/${id}`);
}

export function deleteTenant(id: string) {
    return client(`/api/v2/tenant/${id}`, { method: 'DELETE' });
}

export async function getAssociatedRoles() {
    return client('/api/v2/tenants/roles');
}

export default {
    addTenant,
    getTenants,
    getTenant,
    deleteTenant,
    updateTenant,
    getAssociatedRoles,
};
