import { observable, action } from 'mobx';
import { GlobalNotificationState } from '@baffle/components/src/notifications/GlobalNotification';

export const NotificationStore = observable(
    {
        notifications: observable<GlobalNotificationState>([]),
        shift() {
            const notifications = this.notifications.slice();
            const notification = notifications.shift();
            this.notifications.replace(notifications);
            return notification;
        },
        push(notification: GlobalNotificationState) {
            const notifications = this.notifications.slice();
            notifications.push(notification);
            this.notifications.replace(notifications);
        },
        get size() {
            return this.notifications.length;
        },
    },
    {
        shift: action,
        push: action,
    }
);
