import React, { useEffect, useMemo, useState } from 'react';
import useAwait from '@baffle/api-client/src/useAwait';
import { CreateModal, CreateModalBody, CreateModalHeader } from '@baffle/components/src/CreateModal/CreateModal';
import { TextInput, Select, SelectItem } from 'carbon-components-react';
import { t } from '@baffle/translate';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';
import { DatabaseProxiesStore, DatabaseStore, KeystoreStore } from '@baffle/manager/src/stores';
import { DatabasePayloadV2, DatabaseProxyPayload, DataProtectionItem } from '@baffle/graphql/src/models';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import CreateDataBaseProxyInputScheme from './CreateDataBaseProxyInputScheme';

interface AddDataBaseProxyProps {
    open: boolean;
    onClose?: () => void;
    onSuccess?: (name: string) => void;
}

const AddDataBaseProxy = ({ open, onClose, onSuccess }: AddDataBaseProxyProps) => {
    const [openModal, setOpen] = useState(open);
    const { run, error, reset } = useAwait();
    const existingDatabaseProxy = DatabaseProxiesStore.databaseProxy;
    const databases: DatabasePayloadV2[] = DatabaseStore.databases;
    const keystores = KeystoreStore.keystoreListItems || [];
    const databaseProxyInputSchemes = useMemo(
        () => CreateDataBaseProxyInputScheme(DatabaseProxiesStore.databaseProxies, existingDatabaseProxy?.id),
        [DatabaseProxiesStore.databaseProxies, existingDatabaseProxy]
    );
    //@ts-ignore
    const { inputState, inputErrors, inputActions } = useInputState(databaseProxyInputSchemes);
    const isValid = inputActions.validateAll(true);

    const databasesItems = databases.map(database => ({
        id: database.id,
        name: database.name,
        'data-testid': `databases-li-${database.name}`,
    }));

    const keystoresItems = keystores.map(keystore => ({
        id: keystore.id,
        name: keystore.name,
        'data-testid': `keystores-li-${keystore.name}`,
    }));

    //Sync state from props
    useEffect(() => {
        setOpen(open);
    }, [open]);

    useEffect(() => {
        if (error) {
            NotificationStore.push({
                'data-testid': 'database-proxy-add-failure',
                kind: 'error',
                title: t('databaseProxies.addDataBaseProxyError'),
                description: error.message ?? '',
            });
        }
    }, [error]);

    useEffect(() => {
        run(Promise.all([DatabaseStore.readDatabases(), KeystoreStore.clearKeystores(), KeystoreStore.list()]));
    }, []);

    const animateModal = () => {
        return new Promise(() => {
            setTimeout(() => {
                if (onClose) {
                    onClose();
                }
            }, 200);
        });
    };

    const handleClose = async () => {
        if (!openModal) {
            return;
        }
        setOpen(false);
        reset();
        await animateModal();
        inputActions.reset();
    };

    const handleSave = () => {
        let payload: DatabaseProxyPayload = databaseProxyInputSchemes.keys.reduce((acc: any, c: any) => {
            if (inputState[c]) {
                acc[c] = inputState[c] as string;
            }
            return acc;
        }, {});

        run(
            DatabaseProxiesStore.createDatabaseProxy(payload).then(() => {
                if (onSuccess) {
                    onSuccess(payload.name as string);
                }
                handleClose();
            })
        );
    };

    return (
        <CreateModal
            open={openModal}
            onClose={handleClose}
            isValid={isValid}
            modalId="add-database-proxy-modal"
            primaryButtonText={t('main.create')}
            primaryButtonTheme="primary"
            primaryButton={() => handleSave()}>
            <CreateModalHeader titleText={t('databaseProxies.addDataBaseProxyModalHealine')} />
            <CreateModalBody>
                <div className="flex flex-wrap mb-6 items-end">
                    <div className="flex-1 text-sm name-input-container">
                        <TextInput
                            id="database-proxy-name-input"
                            name="database-proxy-name-input"
                            data-testid="database-proxy-name-input"
                            labelText={t('main.name')}
                            invalid={Boolean(inputErrors.name)}
                            invalidText={inputErrors.name}
                            value={(inputState.name as string) || ''}
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                inputActions.validateField({
                                    field: 'name',
                                    skipNull: true,
                                });
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                let val = e.target.value || '';
                                inputActions.setField('name', val);
                            }}
                        />
                    </div>
                </div>
                <div className="flex flex-wrap mb-6 items-end">
                    <div className="flex-1 text-sm name-input-container">
                        <Select
                            id="database-proxy-database-input"
                            data-testid="database-proxy-database-input"
                            labelText={t('databaseProxies.header2')}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                const value = databases.find(database => database.id === val) as DataProtectionItem;
                                inputActions.setField('database', { name: value.name, id: value.id });
                            }}
                            value={(inputState.database as DataProtectionItem)?.id || 'placeholder-item'}>
                            <SelectItem disabled hidden text={t('main.chooseOption')} value="placeholder-item" />
                            {databasesItems.map((databasesItem, i) => {
                                return <SelectItem key={i} text={databasesItem.name} value={databasesItem.id} />;
                            })}
                        </Select>
                    </div>
                </div>
                <div className="flex flex-wrap mb-6 items-end">
                    <div className="flex-1 text-sm name-input-container">
                        <Select
                            id="database-proxy-keystore-input"
                            data-testid="database-proxy-keystore-input"
                            labelText={t('databaseProxies.header3')}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                const value = keystores.find(keystore => keystore.id === val) as DataProtectionItem;
                                inputActions.setField('keystore', { name: value.name, id: value.id });
                            }}
                            value={(inputState.keystore as DataProtectionItem)?.id || 'placeholder-item'}>
                            <SelectItem disabled hidden text={t('main.chooseOption')} value="placeholder-item" />
                            {keystoresItems.map((keystoresItem, i) => {
                                return <SelectItem key={i} text={keystoresItem.name} value={keystoresItem.id} />;
                            })}
                        </Select>
                    </div>
                </div>
            </CreateModalBody>
        </CreateModal>
    );
};

export default AddDataBaseProxy;
