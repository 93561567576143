import useAwait from '@baffle/api-client/src/useAwait';
import { Modal } from 'carbon-components-react';
import { t } from '@baffle/translate';
import React, { useEffect } from 'react';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';
import { DatabaseProxiesStore } from '@baffle/manager/src/stores';

interface DeleteConfigProps {
    id: string;
    configKey: string | null;
    open: boolean;
    onClose: () => void;
    onSuccess?: (name: string) => void;
}
const DeleteConfig = ({ id, configKey, open, onSuccess, onClose }: DeleteConfigProps) => {
    const { run, error } = useAwait();

    const animateModal = () => {
        return new Promise(() => {
            setTimeout(() => {
                if (onClose) {
                    onClose();
                }
            }, 200);
        });
    };

    useEffect(() => {
        if (error) {
            NotificationStore.push({
                'data-testid': 'advance-config-delete-failure',
                kind: 'error',
                title: t('databaseProxies.databaseProxyConfigurations.deleteConfigError'),
                description: error.message ?? '',
            });
        }
    }, [error]);

    const handleClose = async () => {
        await animateModal();
    };

    const handleDeleteConfig = () => {
        run(
            DatabaseProxiesStore.deleteDatabaseProxyAdvanceConfiguration(id, [configKey as string]).then(() => {
                DatabaseProxiesStore.readDatabaseProxyConfiguration(id).then(() => {
                    if (onSuccess) {
                        onSuccess(configKey as string);
                    }
                    handleClose();
                });
            })
        );
    };

    return (
        <Modal
            id="advance-config-delete-modal"
            open={open}
            modalHeading={t('databaseProxies.databaseProxyConfigurations.deleteConfigHeading')}
            danger={true}
            onRequestClose={onClose}
            primaryButtonText={t('main.delete')}
            secondaryButtonText={t('main.cancel')}
            size="sm"
            onRequestSubmit={handleDeleteConfig}>
            <p>{t('databaseProxies.databaseProxyConfigurations.deleteConfigConfirmationMessage')}</p>
        </Modal>
    );
};

export default DeleteConfig;
