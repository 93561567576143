import React from 'react';
import ReactDOM from 'react-dom';
import { load } from '@baffle/translate';
import App from './App';

let startupConfig = {
    localLoadPath: '/locales/{{lng}}.json',
};

//@ts-ignore
if (window.Cypress) {
    require('whatwg-fetch'); //for polyfill cypress test
}

//**this will get stripped out of prod webpack builds */
//@ts-ignore
if (!PRODUCTION) {
    //TODO: fix the console barf that is happening with react-axe
    // var axe = require('react-axe');
    // axe(React, ReactDOM, 1000);
    startupConfig.localLoadPath = '/locales/{{lng}}.json';
}

const loadApp = async () => {
    try {
        await load({
            loadPath: startupConfig.localLoadPath,
            crossDomain: false,
        });
    } catch (error) {
        console.log(error);
    } finally {
        ReactDOM.render(<App />, document.getElementById('root'));
    }
};

loadApp();
