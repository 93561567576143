import { observable, makeAutoObservable } from 'mobx';
import agentClient, { AgentQueryParams } from '../../../api-client/src/agentClient';
import { AgentError, AgentErrorCount } from '../../../graphql/src/models';
import { RequestOpts } from '../types/StoreTypes';
import memo from 'fast-memoize';

interface CountHash {
    [key: string]: number;
}

interface ErrorHash {
    [key: string]: AgentError[];
}

export const createErrorByResourceHashKey = memo(({ agentId, errorId, appId }: AgentQueryParams = {}) => {
    agentId = appId ? agentId + '_' : '';
    errorId = errorId ? errorId + '_' : '';
    appId = appId ? appId + '_' : '';
    //if all of these are empty, we will end up with just 'all' for the key
    return `${appId}${agentId}${errorId}all`;
});
class AgentErrorStore {
    count = observable<CountHash>({});
    errors = observable<ErrorHash>({});
    constructor() {
        makeAutoObservable(this);
    }
    setCount = (key: string, count: number) => {
        this.count[key] = count;
    };
    setAgentErrors = (key: string, errors: AgentError[]) => {
        this.errors[key] = errors;
    };
    async list(params: AgentQueryParams = {}, opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first') {
            return;
        }
        try {
            const payload = await agentClient.list(params);
            this.setAgentErrors(createErrorByResourceHashKey(params), payload.data);
        } catch (error) {
            throw error;
        }
    }
    async readCount(params: AgentQueryParams = {}, opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first') {
            return;
        }
        try {
            const body: AgentErrorCount = await agentClient.readCount(params);

            this.setCount(createErrorByResourceHashKey(params), body.count);
        } catch (error) {
            throw error;
        }
    }
    getCount(params: AgentQueryParams = {}) {
        const key = createErrorByResourceHashKey(params);
        if (this.count[key]) {
            return this.count[key];
        }
        return 0;
    }
    get(params: AgentQueryParams = {}) {
        const key = createErrorByResourceHashKey(params);
        return this.errors[key] ?? [];
    }
}

export default AgentErrorStore;
