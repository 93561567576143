import React, { useEffect, useRef, useState } from 'react';
import { t } from '@baffle/translate';
import { Modal, ModalBody, ModalFooter, ModalHeader, SkeletonModal } from '@baffle/components/src/modal/Modal';
import Button from '@baffle/components/src/buttons/Button';
import TextInput from '@baffle/components/src/forms/TextInput';
import inviteUserClient from '@baffle/api-client/src/inviteUserClient';
import Alert from '@baffle/components/src/alerts/Alert';
import useAwait from '@baffle/api-client/src/useAwait';
import { ActivationLinkResponse, User } from '@baffle/graphql/src/models';
import copyTextToClipboard from '@baffle/utilities/src/copyTextToClipboard';
import { ToastStore } from '../stores/ToastStore';
import { SystemEmailStore } from '../stores';
import debounce from 'lodash.debounce';

interface ModalPropsWithoutChildren {
    'aria-labelledby'?: string;
    open: boolean;
    user?: User;
    onClose?: () => void;
}

const ReInviteUserModal = ({ user, open, onClose = () => null }: ModalPropsWithoutChildren) => {
    const [{ error, activationLink }, setInviteData] = useState({
        error: null as Error | null,
        activationLink: '',
    });

    const doSendInvite = async ({ sendEmail }: { sendEmail: boolean }) => {
        if (sendEmail && !SystemEmailStore.systemEmailSetup()) {
            setInviteData({
                activationLink: '',
                error: new Error("STMP server not configured. Please configure it or choose 'Get Invite Link' instead"),
            });
        } else {
            try {
                const data = (await inviteUserClient.readUserInvite(user?.id as string, {
                    queryParams: { sendEmail },
                })) as ActivationLinkResponse;
                setInviteData({ activationLink: data.activationLink, error: null });
                if (sendEmail) {
                    ToastStore.push({ title: t('users.inviteSuccess'), type: 'success' });
                }
            } catch (error) {
                ToastStore.push({ title: t('users.inviteFail'), type: 'danger' });
                setInviteData({ activationLink: '', error });
            }
        }
    };

    const { status, run, error: requestError } = useAwait();

    useEffect(() => {
        if (user?.id && open) {
            run(Promise.all([SystemEmailStore.getSystemEmail(), doSendInvite({ sendEmail: false })]));
        }
    }, [user, run, open]);

    useEffect(() => {
        if (requestError) {
            setInviteData({ error: requestError, activationLink: '' });
        }
    }, [requestError]);

    const handleClose = () => {
        onClose();
        setInviteData({ error: null, activationLink: '' });
    };

    return (
        <Modal open={open} onClose={handleClose}>
            {status === 'loading' || status === 'idle' ? (
                <SkeletonModal />
            ) : (
                <ReInviteUser
                    error={error}
                    doSendInvite={doSendInvite}
                    activationLink={activationLink}
                    handleClose={handleClose}
                />
            )}
        </Modal>
    );
};

interface ReInviteUserProps {
    handleClose: () => void;
    doSendInvite: ({ sendEmail }: { sendEmail: boolean }) => void;
    activationLink: string;
    error: Error | null;
}
const ReInviteUser = ({ doSendInvite, handleClose, activationLink, error }: ReInviteUserProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const doCopy = async () => {
        try {
            await copyTextToClipboard(activationLink);
            ToastStore.push({ type: 'success', title: t('users.copySuccess') });
        } catch (error) {
            ToastStore.push({ type: 'danger', title: t('users.copyFail') });
        }
    };
    return (
        <>
            <ModalHeader onClose={handleClose}>
                <div className="flex items-start">
                    <div className="mt-0 ml-2">
                        <h3 className="text-xl font-medium text-gray-100" id="modal-headline">
                            {t('users.resendInvite')}
                        </h3>
                    </div>
                </div>
            </ModalHeader>
            <ModalBody>
                <div className="h-64">
                    {error ? (
                        <div className="flex flex-wrap mb-4">
                            <div className="flex-1 px-4">
                                <Alert
                                    type="danger"
                                    title={t('main.unexpectedError')}
                                    description={error?.message || ''}
                                />
                            </div>
                        </div>
                    ) : null}
                    <p className="text-sm text-center leading-5 text-gray-500 mb-6">{t('users.shareDescription')}</p>
                    <div className="flex items-center">
                        <div className="flex flex-grow">
                            <TextInput
                                ref={inputRef}
                                labelText={t('users.inviteLink')}
                                readOnly
                                labelHidden
                                value={activationLink}
                                onClick={debounce(e => {
                                    inputRef.current?.select();
                                    doCopy();
                                }, 300)}
                                className="border-r-0 h-10"
                                name="select-activation-link-input"
                            />
                        </div>
                        <Button
                            theme="tertiary"
                            className="w-48 h-10"
                            onClick={debounce(() => {
                                inputRef.current?.select();
                                doCopy();
                            }, 300)}>
                            {t('users.copyLink')}
                        </Button>
                    </div>
                    <p className="text-sm text-center leading-5 text-gray-500 my-6 uppercase">{t('main.or')}</p>
                    <div className="flex items-center justify-center">
                        <Button
                            theme="tertiary"
                            className="w-48 h-10 mb-4"
                            onClick={debounce(() => {
                                doSendInvite({ sendEmail: true });
                            }, 300)}>
                            {t('users.sendInviteEmail')}
                        </Button>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex justify-end items-center h-16 w-full">
                    <Button theme="secondary" className="h-full w-1/2" onClick={handleClose} size="lg">
                        {t('main.cancel')}
                    </Button>
                    <Button theme="primary" className="h-full w-1/2" onClick={handleClose} size="lg">
                        {t('users.done')}
                    </Button>
                </div>
            </ModalFooter>
        </>
    );
};

export default ReInviteUserModal;
