import systemClient from '@baffle/api-client/src/systemClient';
import useAwait from '@baffle/api-client/src/useAwait';
import Alert from '@baffle/components/src/alerts/Alert';
import Button from '@baffle/components/src/buttons/Button';
import DropdownSelect, {
    getDefaultLIProps,
    getDefaultMenuProps,
    getDefaultToggleButtonProps,
} from '@baffle/components/src/dropdown/Dropdown';
import TextInput from '@baffle/components/src/forms/TextInput';
import { Modal, ModalBody, ModalFooter, ModalHeader, SkeletonModal } from '@baffle/components/src/modal/Modal';
import { LoggerConfiguration, LogLevelItems } from '@baffle/graphql/src/models';
import { t } from '@baffle/translate';
import { InputActions, InputError, InputState, useInputState } from '@baffle/utilities/src/inputHooks';
import React, { useState, useEffect, useMemo } from 'react';
import { ToastStore } from '@baffle/manager/src/stores/ToastStore';
import createSettingsInputScheme from '../SettingsInputScheme/createSettingsInputScheme';

interface AddLoggerModalProps {
    open: boolean;
    onClose?: () => void;
}

const AddLoggerModal = ({ open, onClose = () => null }: AddLoggerModalProps) => {
    const [openModal, setOpen] = useState(open);
    const { status, run, error, reset } = useAwait();
    const settingsInputSchemes = useMemo(() => createSettingsInputScheme(), []);

    //@ts-ignore
    const { inputState, inputErrors, inputActions } = useInputState(settingsInputSchemes.Logger);
    useEffect(() => {
        setOpen(open);
    }, [open]);

    const doAddLogger = async () => {
        const payload: LoggerConfiguration = settingsInputSchemes.Logger.keys.reduce((acc: any, c: any) => {
            acc[c] = (inputState[c] as string)?.trim();
            return acc;
        }, {});
        run(
            systemClient
                .createLogger({
                    ...payload,
                })
                .then(() => {
                    handleClose();
                    ToastStore.push({ title: t('settings.loggerConfigSuccess'), type: 'success' });
                })
        );
    };

    const handleClose = () => {
        onClose();
        inputActions.reset();
        reset();
    };
    return (
        <Modal open={openModal} onClose={handleClose}>
            {status === 'loading' ? (
                <SkeletonModal />
            ) : (
                <AddLogger
                    handleClose={handleClose}
                    doAddLogger={doAddLogger}
                    inputErrors={inputErrors}
                    inputActions={inputActions}
                    inputState={inputState}
                    error={error}
                />
            )}
        </Modal>
    );
};

interface AddLoggerProps {
    handleClose: () => void;
    doAddLogger: () => void;
    inputErrors: InputError;
    inputActions: InputActions;
    inputState: InputState;
    error: Error | null;
}
const AddLogger = ({ handleClose, doAddLogger, inputActions, inputErrors, inputState, error }: AddLoggerProps) => {
    const isValid = inputActions.validateAll(true);
    return (
        <>
            <ModalHeader onClose={handleClose}>
                <div className="flex items-start">
                    <div className="mt-0 ml-2">
                        <h3 className="text-xl font-medium text-gray-100" id="modal-headline">
                            {t('settings.addLogger')}
                        </h3>
                    </div>
                </div>
            </ModalHeader>
            <ModalBody>
                <div className="h-34">
                    {error ? (
                        <div className="flex flex-wrap mb-4">
                            <div className="flex-1 px-4">
                                <Alert type="danger" title={t('settings.error')} description={error?.message || ''} />
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className="flex flex-wrap mb-6 pb-4 items-start">
                    <div className="px-4 w-2/3">
                        <TextInput
                            id="logger-input-name"
                            name="logger-input-name"
                            labelText={t('settings.logger')}
                            placeholder={t('settings.loggerPlaceHolder')}
                            invalid={Boolean(inputErrors.name)}
                            invalidText={inputErrors.name}
                            value={inputState.name || ''}
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                inputActions.validateField({ field: 'name', skipNull: true });
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                inputActions.setField('name', val.trim());
                            }}
                        />
                    </div>
                    <div className="px-4 w-1/3">
                        <DropdownSelect
                            id="logger-log-level-input"
                            label={t('settings.logLevel')}
                            placeholder={t('main.chooseOption')}
                            getLIProps={() => {
                                const { className: liClasses, ...rest } = getDefaultLIProps();
                                return {
                                    className: `${liClasses} w-full truncate`,
                                    ...rest,
                                };
                            }}
                            getMenuProps={() => {
                                const { className: ulClasses, ...rest } = getDefaultMenuProps();
                                return {
                                    className: `${ulClasses} w-full`,
                                    ...rest,
                                };
                            }}
                            getToggleButtonProps={() => {
                                const { className: btnClasses, ...rest } = getDefaultToggleButtonProps();
                                return {
                                    className: `${btnClasses} w-full`,
                                    ...rest,
                                };
                            }}
                            handleSelectedItemChange={({ selectedItem }) => {
                                inputActions.setField('logLevel', selectedItem?.id as string);
                            }}
                            items={LogLevelItems}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex justify-end items-center h-16 w-full">
                    <Button
                        theme="secondary"
                        className="h-full w-1/2"
                        onClick={handleClose}
                        data-testid="modal-cancel-btn"
                        size="lg">
                        {t('main.cancel')}
                    </Button>
                    <Button
                        theme={isValid ? 'primary' : 'disabled'}
                        className="h-full w-1/2"
                        disabled={!isValid}
                        onClick={() => {
                            doAddLogger();
                        }}
                        id="do-add-logger-btn"
                        size="lg">
                        {t('main.save')}
                    </Button>
                </div>
            </ModalFooter>
        </>
    );
};

export default AddLoggerModal;
