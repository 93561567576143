import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Select, SelectItem } from 'carbon-components-react';
import { t } from '@baffle/translate';
import { DatabaseProxiesStore } from '@baffle/manager/src/stores';
import { Observer } from 'mobx-react-lite';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import { rleConfigScheme } from './addConfigInputScheme';
import useAwait from '@baffle/api-client/src/useAwait';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';
import { Rle, RleToBoolMap } from '@baffle/graphql/src/models';

interface AddConfigProps {
    id: string;
    open: boolean;
    onClose: () => void;
    onSuccess?: (rle: boolean) => void;
}

const UpdateRecordLevelEncryption = ({ open, onClose, onSuccess, id }: AddConfigProps) => {
    const { run, error } = useAwait();

    const animateModal = () => {
        return new Promise(() => {
            setTimeout(() => {
                if (onClose) {
                    onClose();
                }
            }, 200);
        });
    };

    useEffect(() => {
        if (error) {
            NotificationStore.push({
                'data-testid': 'update-rle-failure',
                kind: 'error',
                title: t('databaseProxies.databaseProxyConfigurations.updateConfigError'),
                description: error.message ?? '',
            });
        }
    }, [error]);

    const databaseProxyConfigSchemes = useMemo(() => rleConfigScheme(), []);
    //@ts-ignore
    const { inputState, inputErrors, inputActions } = useInputState(databaseProxyConfigSchemes);

    const rleItems = Object.keys(RleToBoolMap).map(key => ({
        id: key,
        label: key,
        'data-testid': `rle-${key}`,
    }));

    const handleClose = async () => {
        await animateModal();
    };

    useEffect(() => {
        inputActions.setFields({
            rle: DatabaseProxiesStore.databaseProxyConfiguration?.rle,
        });
    }, []);

    const [edit, setEdit] = useState(false);
    const isValid = inputActions.validateAll(true) && edit;

    const handleRecordLevelEncryption = () => {
        let payload = databaseProxyConfigSchemes.keys.reduce((acc: any, c: any) => {
            acc[c] = inputState[c];
            return acc;
        }, {});

        run(
            DatabaseProxiesStore.updateDatabaseProxyConfiguration(id, { configParam: 'rle' }, payload).then(() => {
                if (onSuccess) {
                    onSuccess(inputState.rle as boolean);
                }
                handleClose();
            })
        );
    };

    return (
        <Observer>
            {() => {
                return (
                    <Modal
                        id="record-level-encryption-modal"
                        className="modal-container-white"
                        open={open}
                        modalHeading={t('databaseProxies.databaseProxyConfigurations.updateRecordLevelEncryption')}
                        onRequestClose={onClose}
                        primaryButtonText={t('databaseProxies.databaseProxyConfigurations.update')}
                        secondaryButtonText={t('main.cancel')}
                        size="sm"
                        onRequestSubmit={() => handleRecordLevelEncryption()}
                        primaryButtonDisabled={!isValid}>
                        <Select
                            id="record-level-encryption-input"
                            className="input-helper-text"
                            labelText={t('databaseProxies.databaseProxyConfigurations.rle')}
                            helperText={t(
                                'databaseProxies.databaseProxyConfigurations.recordLevelEncryptionHelperText'
                            )}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (!edit) {
                                    setEdit(true);
                                }
                                const val = e.target.value == 'true' ? true : false;
                                inputActions.setField('rle', val);
                            }}
                            value={inputState.rle || false}>
                            <SelectItem disabled hidden text={t('main.chooseOption')} value="placeholder-item" />
                            {rleItems.map((datatype, i) => {
                                return (
                                    <SelectItem
                                        datatest_id={datatype['data-testid']}
                                        key={i}
                                        text={datatype.label}
                                        value={RleToBoolMap[datatype.id as Rle] as boolean}
                                    />
                                );
                            })}
                        </Select>
                    </Modal>
                );
            }}
        </Observer>
    );
};

export default UpdateRecordLevelEncryption;
