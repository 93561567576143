import Joi from '@hapi/joi';
import { t } from '@baffle/translate';
import { DataProtectionItem } from '@baffle/graphql/src/models';

interface UsrGrpSchemeType {
    [index: string]: any;
}

const validateSubnet = (subnetString: string) => {
    const subnetArr = subnetString.split(',');
    subnetArr.forEach(subnet => {
        if (!new RegExp(/^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/gim).test(subnet.trim())) {
            throw Error();
        }
    });
};

const validateUsers = (users: string) => {
    const userArr = users.split(',');
    userArr.forEach(user => {
        if (!new RegExp(/^[a-zA-Z0-9_-\s]+$/).test(user.trim())) {
            throw new Error();
        }
    });
};

const getGenericInputScheme = (usrGrps: DataProtectionItem[], id: string | undefined) => {
    return {
        ns: 'rbacUsrGrp',
        keys: ['name', 'description', 'global', 'users', 'subnets'],
        validationSchema: {
            name: Joi.string()
                .trim()
                .min(1)
                .max(30)
                .required()
                .custom(val => {
                    if (
                        usrGrps.some(
                            (a: DataProtectionItem) =>
                                a.id !== id && a.name.trim().toLowerCase() === val.trim().toLowerCase()
                        )
                    ) {
                        throw new Error(t('encryption.modeLibrary.rbac.rbacUsrGrp.duplicateUsrGrpNameError'));
                    }
                    return val;
                })
                .pattern(/^[a-zA-Z0-9_-\s]+$/)
                .messages({
                    'string.max': t('main.invalidNameLength'),
                    'string.empty': t('encryption.modeLibrary.rbac.rbacUsrGrp.usrGrpNameEmptyError'),
                    'any.custom': t('encryption.modeLibrary.rbac.rbacUsrGrp.duplicateUsrGrpNameError'),
                    'string.pattern.base': t('encryption.modeLibrary.rbac.rbacUsrGrp.usrGrpNameInvalidError'),
                }),
            global: Joi.boolean(),
            subnets: Joi.string()
                .allow(null, '')
                .custom(validateSubnet)
                .messages({
                    'any.custom': t('encryption.modeLibrary.rbac.rbacUsrGrp.subnetInvalidError'),
                }),
        },
        defaultValues: {
            global: false,
        },
    };
};

export default function createRbacUsrGrpInputScheme(usrGrps: DataProtectionItem[], id: string | undefined) {
    const genericInputScheme = getGenericInputScheme(usrGrps, id);
    const rbacUsrGrpInputSchemes: UsrGrpSchemeType = {
        GLOBAL: genericInputScheme,
        NON_GLOBAL: {
            ns: genericInputScheme.ns,
            keys: genericInputScheme.keys,
            validationSchema: Object.assign({}, genericInputScheme.validationSchema, {
                users: Joi.string()
                    .min(1)
                    .required()
                    .custom(validateUsers)
                    .messages({
                        'string.empty': t('encryption.modeLibrary.rbac.rbacUsrGrp.usersEmptyError'),
                        'any.custom': t('encryption.modeLibrary.rbac.rbacUsrGrp.usersInvalidError'),
                    }),
            }),
            defaultValues: genericInputScheme.defaultValues,
        },
    };
    return rbacUsrGrpInputSchemes;
}
