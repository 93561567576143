import useAwait from '@baffle/api-client/src/useAwait';
import { Modal } from 'carbon-components-react';
import { t } from '@baffle/translate';
import React, { useEffect } from 'react';
import { MaskingPolicyStore } from '@baffle/manager/src/stores';
import { MaskingPolicy } from '@baffle/graphql/src/models';
import { useHistory } from 'react-router-dom';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';

interface DeleteMaskingPolicyProps {
    open: boolean;
    onClose?: () => void;
    onSuccess?: (name: string) => void;
}
const DeleteMaskingPolicy = ({ open, onSuccess, onClose }: DeleteMaskingPolicyProps) => {
    const { run, error } = useAwait();
    const { id, name, isDefault } = MaskingPolicyStore.maskingPolicy as MaskingPolicy;
    const history = useHistory();

    const animateModal = () => {
        return new Promise(() => {
            setTimeout(() => {
                if (onClose) {
                    onClose();
                }
                history.push('/masking-policies');
            }, 200);
        });
    };

    useEffect(() => {
        if (error) {
            NotificationStore.push({
                'data-testid': 'masking-policy-delete-failure',
                kind: 'error',
                title: t('globalPolicies.maskingPolicy.deleteMaskingPolicyError'),
                description: error.message ?? '',
            });
        }
    }, [error]);

    const handleClose = async () => {
        await animateModal();
    };

    const deleteMaskingPolicy = () => {
        run(
            MaskingPolicyStore.deleteMaskingPolicy(id as string).then(() => {
                if (onSuccess) {
                    onSuccess(name);
                }
                handleClose();
            })
        );
    };

    return (
        <Modal
            primaryButtonDisabled={isDefault}
            id="masking-policy-delete-modal"
            open={open}
            modalHeading={t('globalPolicies.maskingPolicy.deleteMaskingPolicyHeading', { name: name })}
            danger={true}
            onRequestClose={onClose}
            primaryButtonText={t('main.delete')}
            secondaryButtonText={t('main.cancel')}
            size="sm"
            onRequestSubmit={deleteMaskingPolicy}>
            <p>{t('globalPolicies.maskingPolicy.deleteMaskingPolicyConfirmationMsg')}</p>
        </Modal>
    );
};

export default DeleteMaskingPolicy;
