import React from 'react';
import { BaffleIconProps } from '../componentTypes';

const PolicyIcon = ({ color = '#868d95', ...rest }: BaffleIconProps) => {
    return (
        <svg
            className="w-4 h-4"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://wwww.w3.org/2000/svg"
            color="currentColor">
            <path
                d="M0.5 1C0.5 0.723858 0.723858 0.5 1 0.5H9C11.4853 0.5 13.5 2.51472 13.5 5V13C13.5 13.2761 13.2761 13.5 13 13.5H1C0.723858 13.5 0.5 13.2761 0.5 13V1Z"
                stroke="currentColor"
            />
            <path
                d="M0.5 1C0.5 0.723858 0.723858 0.5 1 0.5H9C11.4853 0.5 13.5 2.51472 13.5 5V13C13.5 13.2761 13.2761 13.5 13 13.5H1C0.723858 13.5 0.5 13.2761 0.5 13V1Z"
                stroke="currentColor"
            />
            <line x1="2.29742" y1="2.30005" x2="6.03076" y2="2.30005" stroke="currentColor" />
            <line x1="2.29742" y1="4.16675" x2="8.83076" y2="4.16675" stroke="currentColor" />
            <line x1="2.29742" y1="6.03345" x2="10.6974" y2="6.03345" stroke="currentColor" />
            <line x1="2.29742" y1="7.8999" x2="10.6974" y2="7.8999" stroke="currentColor" />
            <line x1="2.29742" y1="9.7666" x2="8.04101" y2="9.7666" stroke="currentColor" />
            <line x1="2.29742" y1="11.6333" x2="5.74358" y2="11.6333" stroke="currentColor" />
        </svg>
    );
};

export default PolicyIcon;
