import React from 'react';
import { BaffleIconProps } from '../componentTypes';

const MinusIcon = ({
    color = '#323232',
    width = 24,
    height = 24,
    viewBox = '0 0 24 24',
    fontSize = 'small',
}: BaffleIconProps) => {
    return (
        <svg width={width} height={height} viewBox={viewBox} fontSize={fontSize} fill="none">
            <path d="M16 12H8" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default MinusIcon;
