import React, { useState, useEffect } from 'react';
import { Observer } from 'mobx-react-lite';
import { t } from '@baffle/translate';
import DataGrid from '@baffle/components/src/grid/data-grid';
import AddMaskingPolicy from './AddMaskingPolicy';
import EditMaskingPolicy from './EditMaskingPolicy';
import DeleteMaskingPolicy from './DeleteMaskingPolicy';
import useAwait from '@baffle/api-client/src/useAwait';
import { MaskingPolicyStore } from '@baffle/manager/src/stores';
import { useManageModals } from '@baffle/components/src/modal/Modal';
import { MaskingPolicy } from '@baffle/graphql/src/models';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';
import RenameMaskingPolicy from './RenameMaskingPolicy';

const MaskingPoliciesGrid = () => {
    const { status, run } = useAwait();
    const [update, setUpdate] = useState(true);

    useEffect(() => {
        if (update === true) {
            run(MaskingPolicyStore.getMaskingPolicies());
            setUpdate(false);
        }
    }, [update]);

    const onSyncButtonClick = () => {
        setUpdate(true);
    };

    const emptyCol = {
        id: '',
        header1: '',
        header2: '',
        header3: '',
        header4: '',
        header5: '',
    };

    const headers = [
        {
            key: 'header1',
            header: t('globalPolicies.maskingPolicy.maskingPolicyHeader1'),
        },
        {
            key: 'header2',
            header: t('globalPolicies.maskingPolicy.maskingPolicyHeader2'),
        },
        {
            key: 'header3',
            header: t('globalPolicies.maskingPolicy.maskingPolicyHeader3'),
        },
        {
            key: 'header4',
            header: t('globalPolicies.maskingPolicy.maskingPolicyHeader4'),
        },
        {
            key: 'header5',
            header: t('globalPolicies.maskingPolicy.maskingPolicyHeader5'),
        },
    ];

    return (
        <Observer>
            {() => {
                interface MaskingPolicyModals {
                    addMaskingPolicy: boolean;
                    editMaskingPolicy: boolean;
                    deleteMaskingPolicy: boolean;
                    renameMaskingPolicy: boolean;
                }

                const initialModalState = {
                    addMaskingPolicy: false,
                    editMaskingPolicy: false,
                    deleteMaskingPolicy: false,
                    renameMaskingPolicy: false,
                };

                const [modals, setModals] = useManageModals<MaskingPolicyModals>(initialModalState);

                const maskingPolicies: MaskingPolicy[] = MaskingPolicyStore.maskingPolicies;

                const onAdd = () => {
                    MaskingPolicyStore.setMaskingPolicy(null);
                    setModals({ addMaskingPolicy: true });
                };

                const onRename = (id: string) => {
                    MaskingPolicyStore.setMaskingPolicy(MaskingPolicyStore.get(id) as MaskingPolicy);
                    setModals({ renameMaskingPolicy: true });
                };

                const onEdit = (id: string) => {
                    MaskingPolicyStore.setMaskingPolicy(MaskingPolicyStore.get(id) as MaskingPolicy);
                    setModals({ editMaskingPolicy: true });
                };

                const onDelete = (id: string) => {
                    MaskingPolicyStore.setMaskingPolicy(MaskingPolicyStore.get(id) as MaskingPolicy);
                    setModals({ deleteMaskingPolicy: true });
                };

                const rows =
                    status === 'loading' || status === 'idle'
                        ? //add  skeleton row on initial load
                          [...Array(Math.floor(1)).keys()].map(() => emptyCol)
                        : maskingPolicies.map((maskingPolicy: MaskingPolicy, i: number) => {
                              return {
                                  selectedId: maskingPolicy?.id,
                                  id: maskingPolicy.id,
                                  header1: maskingPolicy.name,
                                  header2: maskingPolicy.mode,
                                  header3: maskingPolicy.value,
                                  header4: maskingPolicy.datatypeGroup,
                                  header5: String(maskingPolicy.isDefault),
                                  link: '/masking-policies/' + maskingPolicy.id,
                                  overFlowMenu: [
                                      {
                                          name: t('globalPolicies.maskingPolicy.renameMaskingPolicy'),
                                          onClick: (id: string) => onRename(id as string),
                                      },
                                      {
                                          name: t('globalPolicies.maskingPolicy.editMaskingPolicy'),
                                          onClick: (id: string) => {
                                              onEdit(id);
                                          },
                                      },
                                      {
                                          name: t('globalPolicies.maskingPolicy.deleteMaskingPolicy'),
                                          onClick: (id: string) => {
                                              onDelete(id);
                                          },
                                          disabled: maskingPolicy.isDefault,
                                      },
                                  ],
                              };
                          });

                return (
                    <>
                        {modals.addMaskingPolicy ? (
                            <AddMaskingPolicy
                                open={modals.addMaskingPolicy}
                                onClose={() => setModals({ addMaskingPolicy: false })}
                                onSuccess={(name: string) => {
                                    NotificationStore.push({
                                        'data-testid': 'masking-policy-add-success',
                                        kind: 'success',
                                        title: t('globalPolicies.maskingPolicy.createdMaskingPolicyName', {
                                            name: name,
                                        }),
                                    });
                                }}
                            />
                        ) : null}

                        {modals.editMaskingPolicy ? (
                            <EditMaskingPolicy
                                open={modals.editMaskingPolicy}
                                onClose={() => setModals({ editMaskingPolicy: false })}
                                onSuccess={(name: string) => {
                                    NotificationStore.push({
                                        'data-testid': 'masking-policy-edit-success',
                                        kind: 'success',
                                        title: t('globalPolicies.maskingPolicy.editMaskingPolicySuccess', {
                                            name: name,
                                        }),
                                    });
                                }}
                            />
                        ) : null}

                        {modals.renameMaskingPolicy ? (
                            <RenameMaskingPolicy
                                open={modals.renameMaskingPolicy}
                                onClose={() => setModals({ renameMaskingPolicy: false })}
                                onSuccess={(name: string) => {
                                    NotificationStore.push({
                                        'data-testid': 'masking-policy-rename-success',
                                        title: t('globalPolicies.maskingPolicy.renameMaskingPolicySuccess', { name }),
                                        kind: 'success',
                                    });
                                }}
                            />
                        ) : null}

                        {modals.deleteMaskingPolicy ? (
                            <DeleteMaskingPolicy
                                open={modals.deleteMaskingPolicy}
                                onClose={() => setModals({ deleteMaskingPolicy: false })}
                                onSuccess={(name: string) => {
                                    NotificationStore.push({
                                        kind: 'success',
                                        'data-testid': 'masking-policy-delete-success',
                                        title: t('globalPolicies.maskingPolicy.deletedMaskingPolicyName', {
                                            name: name,
                                        }),
                                    });
                                }}
                            />
                        ) : null}

                        <DataGrid
                            idPrefix="maskingPolicy"
                            rows={rows}
                            headers={headers}
                            onAdd={onAdd}
                            showSearch={true}
                            onSync={onSyncButtonClick}
                            addButtonCaption={t('globalPolicies.maskingPolicy.createMaskingPolicy')}></DataGrid>
                    </>
                );
            }}
        </Observer>
    );
};

export default MaskingPoliciesGrid;
