import { TYPE_NAMES } from '.';
import gql from 'graphql-tag';

export const LIST_ALL_DATABASES = gql`
    query Databases {
        databases @rest(type: "[${TYPE_NAMES.DatabaseListItem}]", path: "database/all", endpoint: "v1") {
            id @export(as: "id")
            dbName
            dbUser
            hostName
            status
            ssl
            type
            details @rest(type: "${TYPE_NAMES.Database}", path: "database/{exportVariables.id}", endpoint: "v1") {
                id
                dbName
                dbUser
                hostName
                status
                ssl
                type
                description
                createdBy
                createTime
                port
                certIssuerDN
                certSubjectSAN
                certSubjectDN
                certExpiry
                expiresSoon
            }
        }
    }
`;

export const GET_DATABASE = gql`
    query Database($id: String!) {
        database(id: $id) @rest(type: "${TYPE_NAMES.Database}", path: "database/{args.id}", endpoint: "v1") {
            id
            dbName
            hostName
            status
            ssl
            type
            description
            createdBy
            createTime
            port
        }
    }
`;

export const DELETE_DATABASE = gql`
    mutation DeleteDatabase($id: ID!) {
        deleteDatabase(id: $id)
            @rest(type: "${TYPE_NAMES.Database}", path: "database/unenroll/{args.id}", method: "DELETE", endpoint: "v1") {
            NoResponse
        }
    }
`;

export const GET_DATABASE_COUNT = gql`
    query DatabaseCount {
        databaseCount @rest(type: "${TYPE_NAMES.DatabaseCount}", path: "database/count", endpoint: "v1") {
            dbCount
        }
    }
`;
