import React, { useEffect } from 'react';
import SlideOutPanel from '@baffle/components/src/content/SlideOutPanel';
import { AgentError } from '@baffle/graphql/src/models';
import { t } from '@baffle/translate';
import CloseIcon from '@material-ui/icons/Close';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOffOutlined';
import KeyClick from '@baffle/components/src/handlers/KeyClck';
import { useHistory } from 'react-router-dom';
import { AgentErrorStore, ShieldStore } from '../stores';
import { ToastStore } from '../stores/ToastStore';
import Button, { getDefaultButtonProps } from '@baffle/components/src/buttons/Button';
import { PanelTitle } from '../../../components/src/content/RightPanel';
import useAwait from '../../../api-client/src/useAwait';
import { Observer } from 'mobx-react-lite';
import agentClient from '@baffle/api-client/src/agentClient';
import { format } from '@baffle/utilities/src/date';

interface props {
    open: boolean;
    toggleNotifications?: () => void;
    didClose?: () => void;
    didOpen?: () => void;
    willClose?: () => void;
    willOpen?: () => void;
    didClearNotification?: () => void;
    triggerDismissAllNotifications?: () => void;
}

const NotificationsSidePanel = ({
    open,
    toggleNotifications = () => null,
    didClearNotification = () => null,
    triggerDismissAllNotifications = () => null,
}: props) => {
    const { run } = useAwait();
    const history = useHistory();
    const target = document.getElementById('baffle-side-nav');

    const handleClose = () => {
        toggleNotifications();
    };
    const handleShieldErrorClick = (agent: AgentError) => {
        history.push('/shields', { selectedShieldID: agent.agentDetails.id });
        handleClose();
    };
    useEffect(() => {
        if (open) {
            run(Promise.all([AgentErrorStore.list(), ShieldStore.list({ resource: 'ShieldListItem' })]));
        }
    }, [open]);

    if (!target) {
        return null;
    }

    return (
        <SlideOutPanel target={target as HTMLElement} open={open}>
            <div className="p-4 flex flex-col justify-between h-full">
                <div className="flex flex-col h-93p">
                    {/* panel title */}
                    <div>
                        <div className="text-2xl flex justify-between items-center mb-2">
                            <Observer>
                                {() => {
                                    return (
                                        <div>
                                            {t('notifications.notifications')}({AgentErrorStore.getCount()})
                                        </div>
                                    );
                                }}
                            </Observer>
                            <div>
                                <Button
                                    aria-label={t('notifications.closeNotifications')}
                                    onClick={handleClose}
                                    data-testid="close-notifications-panel-btn"
                                    id="close-notifications-panel-btn"
                                    theme="icon-transparent">
                                    <CloseIcon />
                                </Button>
                            </div>
                        </div>
                    </div>
                    {/* list the notifications */}
                    <div className="h-90p">
                        <PanelTitle>
                            <p className="font-bold">{t('notifications.alerts')}</p>
                            <Button
                                theme="red-link"
                                className="pr-0 text-sm"
                                data-testid="nsp-dismiss-all-shield-errors-btn"
                                onClick={() => {
                                    triggerDismissAllNotifications();
                                }}>
                                {t('shield.clearAll')}
                            </Button>
                        </PanelTitle>
                        <Observer>
                            {() => {
                                return (
                                    <div className="h-full overflow-y-scroll">
                                        {AgentErrorStore.get().map((a: AgentError) => {
                                            const shield = ShieldStore.shieldListItems.find(
                                                shield => shield.id === a.agentDetails.id
                                            );
                                            return (
                                                <div key={a.id} className="py-2 flex justify-between hover:bg-gray-10">
                                                    <KeyClick
                                                        data-testid={`notification-item-${a.id}`}
                                                        handler={() => handleShieldErrorClick(a)}>
                                                        <div>
                                                            <p className="text-xs truncate w-68">
                                                                <span className="font-bold">
                                                                    {t('notifications.created')}:{' '}
                                                                </span>
                                                                <span data-testid={`shield-error-time`}>
                                                                    {format(a.lastTimestamp, 'yyyy-M-d H:mm:ss')}
                                                                </span>
                                                            </p>
                                                            <p className="text-xs truncate w-68">
                                                                <span className="font-bold">
                                                                    {t('notifications.baffleShield')}:{' '}
                                                                </span>
                                                                <span data-testid={`shield-error-name`}>
                                                                    {shield?.shieldName ?? a.agentDetails.id}
                                                                </span>
                                                            </p>
                                                            <p className="truncate w-68 text-xs">
                                                                <span className="font-bold">
                                                                    {t('notifications.application')}:{' '}
                                                                </span>
                                                                <span data-testid={`shield-error-application`}>
                                                                    {a.appDetails.name}
                                                                </span>
                                                            </p>
                                                            <p className="text-red text-xs break-all">{a.errorMsg}</p>
                                                        </div>
                                                    </KeyClick>
                                                    <div className="font-light flex justify-end items-center h-full">
                                                        <Button
                                                            theme="icon-transparent"
                                                            size="none"
                                                            onClick={async () => {
                                                                try {
                                                                    await agentClient.update({ errorId: a.id });
                                                                    didClearNotification();
                                                                } catch (error) {
                                                                    ToastStore.push({
                                                                        title: t('shield.dismissFailure'),
                                                                        type: 'danger',
                                                                    });
                                                                }
                                                            }}
                                                            getButtonProps={() => {
                                                                const btnProps = {
                                                                    ...getDefaultButtonProps(),
                                                                    className:
                                                                        'flex items-center justify-center items-center border-solid border hover:text-red focus:text-red text-gray-400',
                                                                    'aria-label': t(
                                                                        'notifications.dismissNotification'
                                                                    ),
                                                                    title: t('application.addShield'),
                                                                    'data-testid': `dismiss-notifications-${a.id}`,
                                                                };
                                                                return btnProps;
                                                            }}
                                                            id={`dismiss-notifications-${a.id}`}>
                                                            <NotificationsOffIcon className="w-4 h-4" />
                                                        </Button>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            }}
                        </Observer>
                    </div>
                </div>
            </div>
        </SlideOutPanel>
    );
};

export default NotificationsSidePanel;
