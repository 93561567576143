import React from 'react';
import { BaffleIconProps } from '../componentTypes';

const JsonPolicyAddIcon = ({
    color = '#323232',
    width = 24,
    height = 24,
    viewBox = '0 0 24 24',
    fontSize = 'small',
    strokeWidth = 1.5,
}: BaffleIconProps) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox={viewBox}
            fill="none"
            fontSize={fontSize}
            xmlns="http://www.w3.org/2000/svg">
            <g id="Interface, Essential/Plus, Add">
                <g id="Group">
                    <g id="Group_2">
                        <path
                            id="Path"
                            d="M12 8V16"
                            stroke={color}
                            strokeWidth={strokeWidth}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            id="Path_2"
                            d="M16 12H8"
                            stroke={color}
                            strokeWidth={strokeWidth}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            id="Path_3"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z"
                            stroke={color}
                            strokeWidth={strokeWidth}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default JsonPolicyAddIcon;
