import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { BaffleIconProps } from '../componentTypes';

const KeystoreIcon = ({ color = '#868d95', ...rest }: BaffleIconProps) => {
    return (
        <SvgIcon {...rest}>
            <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-18.000000, -140.000000)">
                    <g transform="translate(19.000000, 141.000000)">
                        <path
                            d="M15.3223265,0.242601142 C15.4774802,0.0874426037 15.6883904,0 15.9083977,0 L18.1705871,0 L18.1705871,0.452381441 L18.1705871,0 C18.628555,0 19,0.371456699 19,0.829424888 L19,3.09168557 C19,3.31225812 18.9120939,3.52474143 18.7572249,3.67794257 L18.2459369,4.18864801 L18.2459369,4.9769028 C18.2459369,5.43487099 17.8744919,5.80632769 17.416524,5.80632769 L16.7378106,5.80632769 L16.7378106,6.48507659 C16.7378106,6.94304478 16.3663656,7.31450148 15.9083977,7.31450148 L15.2296843,7.31450148 L15.2296843,7.99325038 C15.2296843,8.45121857 14.8582393,8.82267527 14.4002714,8.82267527 L13.6127461,8.82267527 L11.7813309,10.6554157 C12.6621467,12.8936618 12.1607459,15.4778291 10.424206,17.2144238 C8.04267997,19.5952103 4.16853442,19.5952103 1.78608455,17.2143144 C-0.595382367,14.8319579 -0.595382367,10.9576725 1.78620965,8.57519091 C3.52288227,6.83922798 6.1076817,6.3381706 8.34561022,7.21953729 L15.3223265,0.242601142 Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            fillRule="nonzero"
                            transform="translate(9.500000, 9.500000) rotate(-270.000000) translate(-9.500000, -9.500000) "></path>
                        <circle fill={color} cx="5" cy="5" r="2"></circle>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

export default KeystoreIcon;
