import React, { useEffect, useState } from 'react';
import { Observer } from 'mobx-react-lite';
import { t } from '@baffle/translate';
import SystemSettingsModal from './SystemSettingsModal';
import { SystemEmailStore } from '@baffle/manager/src/stores';
import EditIcon from '@baffle/components/src/icons/EditIcon';

const SystemConfigTable = () => {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        SystemEmailStore.getSystemSettingsValues();
    }, [open]);
    return (
        <Observer>
            {() => {
                const systemInformation = SystemEmailStore.systemSettingsValues;
                return (
                    <>
                        <SystemSettingsModal
                            open={open}
                            onClose={() => setOpen(false)}
                            systemInformation={systemInformation}
                        />
                        <div className="flex-col pb-6">
                            <p className="text-lg font-bold mb-1">{t('settings.system')}</p>
                            <p className="text-sm text-gray-500 mb-1">{t('settings.configureSystem')}</p>
                            <table className="w-full mt-2 text-xsmall text-left">
                                <thead className="h-12 border-solid border border-t border-gray-300 bg-gray-20 font-bold text-sm">
                                    <tr>
                                        <th scope="col" className="p-4 w-2/6">
                                            {t('settings.organizationName')}
                                        </th>
                                        <th scope="col" className="p-4 w-1/6">
                                            {t('settings.domainName')}
                                        </th>
                                        <th scope="col" className="p-4 w-1/6">
                                            {t('settings.publicAccess')}
                                        </th>
                                        <th scope="col" className="p-4 w-2/6">
                                            {t('settings.proxyAccess')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="h-12 border-solid border-l border-r border-b border-gray-300">
                                    <tr className="bg-white h-12">
                                        <td className="p-4 w-1/4" data-testid="system-settings-organization">
                                            {systemInformation?.organization}
                                        </td>
                                        <td className="p-4 w-1/4" data-testid="system-settings-domain">
                                            {systemInformation?.domain}
                                        </td>
                                        <td className="p-4 w-1/4" data-testid="system-settings-publicAccess">
                                            {systemInformation?.publicAccess}
                                        </td>
                                        <td className="p-4 w-1/4" data-testid="system-settings-proxyAccess">
                                            <div className="flex justify-between items-center">
                                                {systemInformation?.proxyAccess}
                                                <button
                                                    data-testid="edit-system-settings-btn"
                                                    onClick={() => setOpen(true)}
                                                    className="border-none bg-transparent">
                                                    <EditIcon className="w-4 h-4" />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                );
            }}
        </Observer>
    );
};

export default SystemConfigTable;
