import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { BaffleIconProps } from '../componentTypes';

const HelpIcon = ({
    color = '#000000',
    width = 24,
    height = 24,
    viewBox = '0 0 24 24',
    fontSize = 'small',
}: BaffleIconProps) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} fontSize={fontSize}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <g transform="translate(2.000000, 2.000000)" fill={color} fillRule="nonzero">
                    <path d="M11.0416667,14.1944444 L11.0416667,15.9444444 L9.29166667,15.9444444 L9.29166667,14.1944444 L11.0416667,14.1944444 Z M10.1666667,4.44444444 C12.1004167,4.44444444 13.6666667,6.01069444 13.6666667,7.94444444 C13.6666667,10.1319444 11.0416667,10.3506944 11.0416667,12.3194444 L11.0416667,12.3194444 L9.29166667,12.3194444 C9.29166667,9.47569444 11.9166667,9.69444444 11.9166667,7.94444444 C11.9166667,6.98194444 11.1291667,6.19444444 10.1666667,6.19444444 C9.20416667,6.19444444 8.41666667,6.98194444 8.41666667,7.94444444 L8.41666667,7.94444444 L6.66666667,7.94444444 C6.66666667,6.01069444 8.23291667,4.44444444 10.1666667,4.44444444 Z"></path>
                    <path d="M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 Z M10,1.5 C14.6915729,1.5 18.5,5.30842712 18.5,10 C18.5,14.6915729 14.6915729,18.5 10,18.5 C5.30842712,18.5 1.5,14.6915729 1.5,10 C1.5,5.30842712 5.30842712,1.5 10,1.5 Z"></path>
                </g>
            </g>
        </SvgIcon>
    );
};

export default HelpIcon;
