import React from 'react';
import { Dropdown } from 'carbon-components-react';
import TextInput from '@baffle/components/src/forms/TextInput';
import { t } from '@baffle/translate';
import { IBMRegionList } from '@baffle/utilities/src/enums';
import { DEKStorageTypeValues, DEKStorageType } from '@baffle/graphql/src/models';

const AddIbmKeyProtect = ({ inputState, inputErrors, inputActions }: any) => {
    const ibmRegionItems = IBMRegionList.map((region: string, i: number) => ({ id: region, label: region }));
    const DEKStorageTypeLabels = {
        [DEKStorageType.AWS_S3_BUCKET]: t('keystore.ibmCloudObjectStorage'),
    };
    const dekItems = Object.values(DEKStorageType).map((key: DEKStorageType) => ({
        id: DEKStorageTypeValues[key],
        label: DEKStorageTypeLabels[key],
    }));
    return (
        <>
            <div className="flex flex-wrap mb-6 items-start">
                <div className="flex-1 pr-4">
                    <TextInput
                        name="ks-instanceid-input"
                        data-testid="ks-instanceid-input"
                        labelText={t('keystore.instanceIdInputLabel')}
                        invalidText={t('main.invalidEmpty')}
                        placeholder={t('keystore.instanceIdPlaceholder')}
                        type="text"
                        defaultValue={inputState.instanceId}
                        invalid={Boolean(inputErrors.instanceId)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputActions.setField('instanceId', e.target.value.trim() || '')
                        }
                        onBlur={() => {
                            inputActions.validateField({ field: 'instanceId', skipNull: true });
                        }}
                    />
                </div>
                <div className="flex-1 pl-4">
                    <TextInput
                        name="ks-appnamespace-input"
                        data-testid="ks-appnamespace-input"
                        labelText={t('keystore.appNamespaceInputLabel')}
                        placeholder={t('keystore.ibmAppNamespaceInputPlaceholder')}
                        type="text"
                        invalid={Boolean(inputErrors.appNamespace)}
                        invalidText={t('main.invalidEmpty')}
                        value={inputState.appNamespace ?? ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const value = e.target.value.trim() || '';
                            inputActions.setField('appNamespace', value);
                        }}
                        onBlur={() => {
                            inputActions.validateField({ field: 'appNamespace', skipNull: true });
                        }}
                    />
                </div>
            </div>
            <div className="flex flex-wrap mb-6 items-start">
                <div className="flex-1 pr-4">
                    <TextInput
                        name="ks-ibmkmsalias-input"
                        data-testid="ks-ibmkmsalias-input"
                        labelText={t('keystore.ibmKmsAliasInputLabel')}
                        placeholder={t('keystore.ibmKmsAliasPlaceholder')}
                        type="text"
                        defaultValue={inputState.ibmKmsAlias}
                        invalid={Boolean(inputErrors.ibmKmsAlias)}
                        invalidText={t('main.invalidEmpty')}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputActions.setField('ibmKmsAlias', e.target.value.trim() || '')
                        }
                        onBlur={() => {
                            inputActions.validateField({ field: 'ibmKmsAlias', skipNull: true });
                        }}
                    />
                </div>
                <div className="flex-1 pl-4">
                    <TextInput
                        name="ks-iamapikey-input"
                        data-testid="ks-iamapikey-input"
                        labelText={t('keystore.iamApiKeyInputLabel')}
                        placeholder={t('keystore.iamApiKeyPlaceholder')}
                        type="password"
                        defaultValue={inputState.iamApiKey}
                        invalid={Boolean(inputErrors.iamApiKey)}
                        invalidText={t('main.invalidEmpty')}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputActions.setField('iamApiKey', e.target.value.trim() || '')
                        }
                        onBlur={() => {
                            inputActions.validateField({ field: 'iamApiKey', skipNull: true });
                        }}
                    />
                </div>
            </div>
            <div className="flex flex-wrap mb-6 items-start">
                <div className="flex-1 pr-4">
                    <div className="w-64">
                        <Dropdown
                            id="ks-ibmregion-input"
                            data-testid="ks-ibmregion-input"
                            titleText={t('keystore.ibmRegionInputLabel')}
                            label={t('main.chooseOption')}
                            initialSelectedItem={
                                inputState.ibmRegion ? ibmRegionItems.find(d => d.id === inputState.ibmRegion) : null
                            }
                            onChange={({ selectedItem }: { selectedItem: { id: string; label: string } }) => {
                                inputActions.setField('ibmRegion', selectedItem.id);
                            }}
                            items={ibmRegionItems}
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap mb-6 items-start">
                <div className="flex-1">
                    <div className="w-64">
                        <Dropdown
                            id="ks-dekStore-input"
                            data-testid="ks-dekStore-input"
                            titleText={t('keystore.dekStoreInputLabel')}
                            label={t('main.chooseOption')}
                            initialSelectedItem={
                                inputState.dekStore ? dekItems.find(d => d.id === inputState.dekStore) : null
                            }
                            onChange={({ selectedItem }: { selectedItem: { id: string; label: string } }) => {
                                inputActions.setField('dekStore', selectedItem.id);
                            }}
                            items={dekItems}
                        />
                    </div>
                </div>
            </div>
            {inputState.dekStore === DEKStorageTypeValues.AWS_S3_BUCKET ? (
                <div>
                    <div className="flex flex-wrap mb-6 items-start">
                        <div className="flex-1 pr-4">
                            <TextInput
                                name="ks-ibmCloudObjectStorageURL-input"
                                data-testid="ks-ibmCloudObjectStorageURL-input"
                                labelText={t('keystore.ibmCloudObjectStorageURLInputLabel')}
                                defaultValue={inputState.awsS3Endpoint}
                                invalid={Boolean(inputErrors.awsS3Endpoint)}
                                invalidText={t('main.invalidEmpty')}
                                placeholder={t('keystore.ibmCloudObjectStorageURLInputPlaceholder')}
                                type="text"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    inputActions.setField('awsS3Endpoint', e.target.value.trim() || '')
                                }
                                onBlur={() => {
                                    inputActions.validateField({ field: 'awsS3Endpoint', skipNull: true });
                                }}
                            />
                        </div>
                        <div className="flex-1 pl-4">
                            <TextInput
                                name="ks-ibmSecretKey-input"
                                data-testid="ks-ibmSecretKey-input"
                                labelText={t('keystore.ibmSecretKeyInputLabel')}
                                invalid={Boolean(inputErrors.awsSecretAccessKey)}
                                invalidText={t('main.invalidEmpty')}
                                placeholder={t('keystore.ibmSecretKeyInputPlaceholder')}
                                type="password"
                                defaultValue={inputState.awsSecretAccessKey}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    inputActions.setField('awsSecretAccessKey', e.target.value.trim() || '')
                                }
                                onBlur={() => {
                                    inputActions.validateField({ field: 'awsSecretAccessKey', skipNull: true });
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap mb-6 items-start">
                        <div className="flex-1 pr-4">
                            <TextInput
                                name="ks-ibmAccessKeyId-input"
                                data-testid="ks-ibmAccessKeyId-input"
                                labelText={t('keystore.ibmAccessKeyIdInputLabel')}
                                placeholder={t('keystore.ibmAccessKeyIdInputPlaceholder')}
                                type="text"
                                defaultValue={inputState.awsAccessKeyId}
                                invalid={Boolean(inputErrors.awsAccessKeyId)}
                                invalidText={t('main.invalidEmpty')}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    inputActions.setField('awsAccessKeyId', e.target.value.trim() || '')
                                }
                                onBlur={() => {
                                    inputActions.validateField({ field: 'awsAccessKeyId', skipNull: true });
                                }}
                            />
                        </div>
                        <div className="flex-1 pl-4">
                            <TextInput
                                name="ks-cloudObjectStorageBucket-input"
                                data-testid="ks-cloudObjectStorageBucket-input"
                                labelText={t('keystore.cloudObjectStorageBucketInputLabel')}
                                placeholder={t('keystore.cloudObjectStorageBucketInputPlaceholder')}
                                type="text"
                                invalid={Boolean(inputErrors.awsS3Bucket)}
                                invalidText={t('main.invalidEmpty')}
                                defaultValue={inputState.awsS3Bucket}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    inputActions.setField('awsS3Bucket', e.target.value.trim() || '')
                                }
                                onBlur={() => {
                                    inputActions.validateField({ field: 'awsS3Bucket', skipNull: true });
                                }}
                            />
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default AddIbmKeyProtect;
