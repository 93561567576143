import React, { useState, useEffect, useMemo } from 'react';
import Button from '@baffle/components/src/buttons/Button';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@baffle/components/src/modal/Modal';
import TextArea from '@baffle/components/src/forms/TextArea';
import TextInput from '@baffle/components/src/forms/TextInput';
import InlineToast from '@baffle/components/src/toasts/InlineToast';
import { t } from '@baffle/translate';
import Divider from '@baffle/components/src/content/Divider';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import Alert from '@baffle/components/src/alerts/Alert';
import { Keystore, KeystoreTypeEnum, KeystoreType } from '@baffle/graphql/src/models';
import AddLocalKeystore from './AddLocalKeystore';
import AddSafeNetKeystore from './AddSafeNetKeystore';
import AddAWSKeystore from './AddAWSKeystore';
import AddAzureKeystore from './AddAzureKeystore';
import AddCloudHSM from './AddCloudHSM';
import AddIbmKeyProtect from './AddIbmKeyProtect';
import AddHashicorpKeystore from './AddHashicorpKeystore';
import keystoreClient from '@baffle/api-client/src/keystoreClient';
import DropdownSelect, {
    getDefaultMenuProps,
    getDefaultToggleButtonProps,
} from '@baffle/components/src/dropdown/Dropdown';
import createKeystoreInputScheme from './keystoreInputSchemes/createKeystoreInputScheme';
import SpinnerOverlay from '@baffle/components/src/loader/SpinnerOverlay';
import AddIbmHPCS from './AddIbmHPCS';

interface AddKeystoreModalProps {
    open: boolean;
    onClose?: () => void;
    onSuccess?: (inputState: Keystore, edit: boolean) => void;
    selectedKS: Keystore;
    keystoreTypes?: KeystoreType[];
}

const AddKeyStoreModal = (props: AddKeystoreModalProps) => {
    const keystoreInputSchemes = useMemo(() => createKeystoreInputScheme(), []);

    const [open, setOpen] = useState(props.open);
    const [teardownModal, setTeardownModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [postError, setPostError] = useState(null);
    const [validationError, setValidationError] = useState(0);

    const [keyStoreType, setKeyStoreType] = useState(KeystoreTypeEnum.LOCAL);
    const [editMode, setEditMode] = useState(Boolean(props.selectedKS));
    const [touched, setTouched] = useState(false);

    //@ts-ignore
    const { inputState, inputErrors, inputActions } = useInputState(keystoreInputSchemes[keyStoreType]);

    useEffect(() => {
        //reset the initial keystore type and set the default value
        if (teardownModal) {
            setKeyStoreType(KeystoreTypeEnum.LOCAL);
            inputActions.setField('type', KeystoreTypeEnum.LOCAL);
        }
    }, [teardownModal]);

    //Sync state from props
    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    //Check for input errors
    useEffect(() => {
        let validationErrorCount = 0;
        Object.keys(inputErrors).forEach(key => {
            if (inputErrors[key] != null) {
                validationErrorCount += 1;
            }
        });
        setValidationError(validationErrorCount);
    }, [inputErrors]);

    //Sync state from props
    useEffect(() => {
        if (props.selectedKS && Object.keys(props.selectedKS).length > 0) {
            inputActions.reset();
            setEditMode(true);
            const { name, description, type, dekStore } = props.selectedKS;
            inputActions.setFields({
                name,
                description,
                type,
                dekStore,
            });
            setKeyStoreType(type as KeystoreTypeEnum);
        } else {
            setEditMode(false);
            inputActions.reset();
        }
    }, [props.selectedKS]);

    const initClose = (clearState?: boolean) => {
        if (loading || !open) {
            return;
        }
        setOpen(false);
        //allow the modal to animate out
        setTimeout(() => {
            closeModal(clearState);
        }, 200);
    };
    const closeModal = (clearState?: boolean) => {
        if (loading) {
            return;
        }
        if (clearState) {
            inputActions.reset();
            setPostError(null);
            setTeardownModal(true);
            setTimeout(() => setTeardownModal(false), 0);
        }
        if (props.onClose) {
            props.onClose();
        }
    };

    const doUpdateKeyStore = async () => {
        setLoading(true);
        const hashiKSEdit = {
            id: props.selectedKS.id,
            name: (inputState.name as string).trim(),
            description: (inputState.description as string).trim(),
            type: props.selectedKS.type,
            details: {
                type: props.selectedKS.type,
                authToken: inputState.authToken,
                vaultServer: inputState.vaultServer,
            },
        };
        try {
            await keystoreClient.edit(hashiKSEdit);

            props.onSuccess && props.onSuccess((inputState as unknown) as Keystore, true);
            initClose(true);
        } catch (error) {
            setPostError(JSON.parse(error.message).errors.join(', '));
        } finally {
            setLoading(false);
        }
    };

    const doAddKeyStore = async () => {
        setLoading(true);
        const s = keystoreInputSchemes[keyStoreType].keys.reduce((acc: any, c: any) => {
            //This creates a new object mapping the keys from the input scheme
            //to the key/values from the inputState
            if (typeof inputState[c] == 'boolean' || Boolean(inputState[c])) {
                acc[c] = inputState[c];
            }
            return acc;
        }, {});
        try {
            await keystoreClient.create(s);

            props.onSuccess && props.onSuccess((inputState as unknown) as Keystore, false);
            initClose(true);
        } catch (error) {
            setPostError(JSON.parse(error.message).errors.join(', '));
        } finally {
            setLoading(false);
        }
    };

    const isValid = () => {
        if (editMode) {
            if (!touched) {
                return false;
            }
            return (
                inputActions.validateIndividualField({ field: 'name', skipNull: false }) &&
                inputActions.validateIndividualField({ field: 'authToken', skipNull: false }) &&
                inputActions.validateIndividualField({ field: 'vaultServer', skipNull: false })
            );
        } else {
            return inputActions.validateAll(true);
        }
    };

    const keystoreTypeItems = props.keystoreTypes?.map((k: KeystoreType) => ({
        id: k.type,
        value: k.type,
        label: k.name,
        disabled: k.type === 'CLOUD_HSM' || k.type === 'GENERIC_HSM' ? true : false,
    }));

    //hack to remove the input components state
    if (teardownModal) {
        return null;
    }
    return (
        <Modal open={open} onClose={() => closeModal(true)} aria-label="modal">
            {loading && <SpinnerOverlay description={t('keystore.addingKeystore')} />}
            <ModalHeader onClose={() => initClose(true)}>
                <div className="flex items-start">
                    <h3 className="text-xl font-medium text-gray-100" id="modal-headline">
                        {props.selectedKS && Object.keys(props.selectedKS).length > 0
                            ? t('keystore.editModalHeading')
                            : t('keystore.addModalHeading')}
                    </h3>
                </div>
            </ModalHeader>
            <ModalBody>
                <>
                    {postError ? (
                        <div className="flex flex-wrap">
                            <div className="flex-1">
                                <Alert
                                    type="danger"
                                    title={editMode ? t('keystore.editError') : t('keystore.error')}
                                    description={postError || ''}
                                />
                            </div>
                        </div>
                    ) : null}
                    {validationError ? (
                        <div className="flex flex-wrap">
                            <div className="flex-1">
                                <Alert type="danger" title={t('keystore.inputError', { count: validationError })} />
                            </div>
                        </div>
                    ) : null}
                    <div className="flex flex-wrap mb-6 items-end">
                        <div className="flex-1 name-input-container">
                            <TextInput
                                id="ks-name-input"
                                name="ks-name-input"
                                data-testid="ks-name-input"
                                labelText={t('keystore.nameInputLabel')}
                                invalid={Boolean(inputErrors.name)}
                                invalidText={inputErrors.name}
                                value={(inputState.name as string) || ''}
                                placeholder={t('keystore.namePlaceholder')}
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    inputActions.validateField({ field: 'name', skipNull: true });
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const val = e.target.value || '';
                                    inputActions.setField('name', val);
                                    setTouched(true);
                                }}
                            />
                            <span className="input-char-counter"> {(inputState.name as string)?.length ?? 0} / 30</span>
                        </div>
                    </div>
                    <div className="flex flex-wrap mb-2">
                        <div className="flex-1 relative">
                            <TextArea
                                name="ks-description-input"
                                data-testid="ks-description-input"
                                labelText={t('keystore.descriptionInputLabel')}
                                invalidText={t('keystore.descriptionInputInvalid')}
                                placeholder={t('keystore.descriptionPlaceholder')}
                                value={(inputState.description as string) || ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const val = e.target.value || '';
                                    inputActions.setField('description', val);
                                    const maxLength = 100;
                                    if (val.length > maxLength) {
                                        e.target.value = val.slice(0, maxLength - 1);
                                    }
                                    setTouched(true);
                                }}
                            />
                            <span className="input-char-counter">
                                {(inputState.description as string)?.length ?? 0} / 100
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-wrap mb0">
                        <div className="flex-1 px-4">
                            <Divider />
                        </div>
                    </div>
                    <div className="flex flex-wrap mb-6 items-start">
                        <div className="flex-1">
                            <div className="w-64">
                                <DropdownSelect
                                    id="ks-selectType-input"
                                    data-testid="ks-selectType-input"
                                    label={t('keystore.keystoreTypeLabel')}
                                    placeholder="Choose an option"
                                    defaultSelectedItem={{
                                        id: keyStoreType,
                                        label: t(`keystore.${keyStoreType}`),
                                    }}
                                    handleSelectedItemChange={({ selectedItem }) => {
                                        setKeyStoreType(selectedItem?.id as KeystoreTypeEnum);
                                        inputActions.setFields({
                                            type: selectedItem?.id,
                                        });
                                    }}
                                    getMenuProps={() => {
                                        const { className: ulClasses, ...rest } = getDefaultMenuProps();
                                        return {
                                            className: `${ulClasses} w-full`,
                                            ...rest,
                                        };
                                    }}
                                    items={keystoreTypeItems ?? []}
                                    getToggleButtonProps={() => {
                                        const { ...rest } = getDefaultToggleButtonProps();
                                        return {
                                            disabled: editMode,
                                            ...rest,
                                        };
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {keyStoreType === KeystoreTypeEnum.LOCAL ? (
                        <AddLocalKeystore
                            inputState={inputState}
                            inputActions={inputActions}
                            inputErrors={inputErrors}
                        />
                    ) : null}
                    {keyStoreType === KeystoreTypeEnum.AWS_KMS ? (
                        <AddAWSKeystore inputState={inputState} inputActions={inputActions} inputErrors={inputErrors} />
                    ) : null}
                    {keyStoreType === KeystoreTypeEnum.IBM ? (
                        <AddIbmKeyProtect
                            inputState={inputState}
                            inputActions={inputActions}
                            inputErrors={inputErrors}
                        />
                    ) : null}
                    {keyStoreType === KeystoreTypeEnum.IBM_HPCS ? (
                        <AddIbmHPCS inputState={inputState} inputActions={inputActions} inputErrors={inputErrors} />
                    ) : null}
                    {keyStoreType === KeystoreTypeEnum.AZURE ? (
                        <AddAzureKeystore
                            inputState={inputState}
                            inputActions={inputActions}
                            inputErrors={inputErrors}
                        />
                    ) : null}
                    {keyStoreType === KeystoreTypeEnum.SAFENET_KEY_SECURE ? (
                        <AddSafeNetKeystore
                            inputState={inputState}
                            inputActions={inputActions}
                            inputErrors={inputErrors}
                        />
                    ) : null}
                    {keyStoreType === KeystoreTypeEnum.HASHI_VAULT ? (
                        <AddHashicorpKeystore
                            editMode={editMode}
                            selectedKS={props.selectedKS}
                            touched={setTouched}
                            inputState={inputState}
                            inputActions={inputActions}
                            inputErrors={inputErrors}
                            postAPIError={setPostError}
                        />
                    ) : null}
                    {keyStoreType === KeystoreTypeEnum.CLOUD_HSM ? (
                        <AddCloudHSM inputState={inputState} inputActions={inputActions} inputErrors={inputErrors} />
                    ) : null}
                    {keyStoreType === KeystoreTypeEnum.GENERIC_HSM ? (
                        <div className="flex justify-center">
                            <InlineToast
                                className="mb-5"
                                duration={100000}
                                title={t('main.unsupportedFeature')}
                                type="danger"
                            />
                        </div>
                    ) : null}
                </>
            </ModalBody>
            <ModalFooter>
                <div className="flex justify-end items-center h-16 w-full">
                    <Button className="h-full w-1/2" theme="secondary" onClick={() => initClose(true)} size="lg">
                        {t('main.cancel')}
                    </Button>
                    <Button
                        className="h-full w-1/2"
                        theme={isValid() ? 'primary' : 'disabled'}
                        id="do-add-keystore-btn"
                        data-testid="do-add-keystore-btn"
                        onClick={editMode ? doUpdateKeyStore : doAddKeyStore}
                        size="lg">
                        {editMode ? t('main.save') : t('keystore.addKeystore')}
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default AddKeyStoreModal;
