import React, { useEffect } from 'react';
import { TextInput, Dropdown } from 'carbon-components-react';
import { t } from '@baffle/translate';
import { AWSRegionList, DEKStorageType } from '@baffle/utilities/src/enums';

const AddCloudHSM = ({ inputState, inputErrors, inputActions }: any) => {
    const regionItems = AWSRegionList.map((region: string, i: number) => ({ id: region, label: region }));
    const dekItems = Object.keys(DEKStorageType).map((key: string, i: number) => ({
        //@ts-ignore
        id: DEKStorageType[key],
        //@ts-ignore
        label: DEKStorageType[key],
    }));

    useEffect(() => {
        if (!inputState.hsmPartition) {
            inputActions.setFields({ hsmPartition: 'PARTITION_1' });
        }
    }, []);
    return (
        <>
            <div className="flex flex-wrap mb-6 items-start">
                <div className="flex-1 px-4">
                    <TextInput
                        id="ks-hsmUser-input"
                        labelText={t('keystore.hsmUserInputLabel')}
                        invalidText={t('main.invalidEmpty')}
                        placeholder={t('keystore.hsmUserPlaceholder')}
                        type="text"
                        defaultValue={inputState.hsmUser}
                        invalid={Boolean(inputErrors.hsmUser)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputActions.setField('hsmUser', e.target.value.trim() || '')
                        }
                        onBlur={() => {
                            inputActions.validateField({ field: 'hsmUser', skipNull: true });
                        }}
                    />
                </div>
                <div className="flex-1 px-4">
                    <TextInput
                        id="ks-hsmPassword-input"
                        labelText={t('keystore.hsmPasswordInputLabel')}
                        invalidText={t('main.invalidEmpty')}
                        placeholder={t('keystore.hsmPasswordPlaceholder')}
                        type="password"
                        defaultValue={inputState.hsmPassword}
                        invalid={Boolean(inputErrors.hsmPassword)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputActions.setField('hsmPassword', e.target.value.trim() || '')
                        }
                        onBlur={() => {
                            inputActions.validateField({ field: 'hsmPassword', skipNull: true });
                        }}
                    />
                </div>
            </div>
            <div className="flex flex-wrap mb-6 items-start">
                <div className="flex-1 px-4">
                    <TextInput
                        id="ks-hsmPartition-input"
                        labelText={t('keystore.hsmPartitionInputLabel')}
                        invalidText={t('main.invalidEmpty')}
                        placeholder={t('keystore.hsmPartitionPlaceholder')}
                        type="text"
                        defaultValue={inputState.hsmPartition}
                        invalid={Boolean(inputErrors.hsmPartition)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputActions.setField('hsmPartition', e.target.value.trim() || '')
                        }
                        onBlur={() => {
                            inputActions.validateField({ field: 'hsmPartition', skipNull: true });
                        }}
                    />
                </div>
                <div className="flex-1 px-4" />
            </div>
            <div className="flex flex-wrap mb-6 items-start">
                <div className="flex-1 px-4">
                    <TextInput
                        id="ks-awsaccesskeyid-input"
                        labelText={t('keystore.awsAccessKeyIdInputLabel')}
                        invalidText={t('keystore.awsAccessKeyIdInputInvalid')}
                        placeholder={t('keystore.awsAccessKeyIdPlaceholder')}
                        type="text"
                        defaultValue={inputState.awsAccessKeyId}
                        invalid={Boolean(inputErrors.awsAccessKeyId)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputActions.setField('awsAccessKeyId', e.target.value.trim() || '')
                        }
                        onBlur={() => {
                            inputActions.validateField({ field: 'awsAccessKeyId', skipNull: true });
                        }}
                    />
                </div>
                <div className="flex-1 px-4">
                    <TextInput
                        id="ks-awssecretaccessKey-input"
                        labelText={t('keystore.awsSecretAccessKeyInputLabel')}
                        invalidText={t('keystore.awsSecretAccessKeyInputInvalid')}
                        placeholder={t('keystore.awsSecretAccessKeyPlaceholder')}
                        type="password"
                        defaultValue={inputState.awsSecretAccessKey}
                        invalid={Boolean(inputErrors.awsSecretAccessKey)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputActions.setField('awsSecretAccessKey', e.target.value.trim() || '')
                        }
                        onBlur={() => {
                            inputActions.validateField({ field: 'awsSecretAccessKey', skipNull: true });
                        }}
                    />
                </div>
            </div>
            <div className="flex flex-wrap mb-6 items-start">
                <div className="flex-1 px-4">
                    <TextInput
                        id="ks-awsrootarn-input"
                        labelText={t('keystore.awsRootArnInputLabel')}
                        placeholder={t('keystore.awsRootArnPlaceholder')}
                        type="text"
                        defaultValue={inputState.awsRootArn}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputActions.setField('awsRootArn', e.target.value.trim() || '')
                        }
                    />
                </div>
                <div className="flex-1 px-4">
                    <Dropdown
                        id="ks-awsRegion-input"
                        titleText={t('keystore.awsRegionInputLabel')}
                        label={t('main.chooseOption')}
                        initialSelectedItem={
                            inputState.awsRegion ? regionItems.find(d => d.id === inputState.awsRegion) : null
                        }
                        onChange={({ selectedItem }: { selectedItem: { id: string; label: string } }) => {
                            inputActions.setField('type', selectedItem.id);
                            inputActions.setField('awsRegion', selectedItem.id);
                        }}
                        items={regionItems}
                    />
                </div>
            </div>
            <div className="flex flex-wrap mb-6 items-start">
                <div className="flex-1 px-4">
                    <Dropdown
                        id="ks-dekStore-input"
                        titleText={t('keystore.dekStoreInputLabel')}
                        label={t('main.chooseOption')}
                        initialSelectedItem={
                            inputState.dekStore ? dekItems.find(d => d.id === inputState.dekStore) : null
                        }
                        onChange={({ selectedItem }: { selectedItem: { id: string; label: string } }) => {
                            inputActions.setField('dekStore', selectedItem.id);
                        }}
                        items={dekItems}
                    />
                </div>
                <div className="flex-1 px-4">
                    <TextInput
                        id="ks-appnamespace-input"
                        labelText={t('keystore.appNamespaceInputLabel')}
                        placeholder={t('keystore.appNamespacePlaceholder')}
                        type="text"
                        invalid={Boolean(inputErrors.appNamespace)}
                        invalidText={t('keystore.appNamespaceInputInvalid')}
                        defaultValue={inputState.appNamespace}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputActions.setField('appNamespace', e.target.value.trim() || '')
                        }
                        onBlur={() => {
                            inputActions.validateField({ field: 'appNamespace', skipNull: true });
                        }}
                    />
                </div>
            </div>
            <div className="flex flex-wrap mb-6 items-start">
                <div className="flex-1 px-4">
                    <TextInput
                        id="ks-awss3bucket-input"
                        labelText={t('keystore.awsS3BucketInputLabel')}
                        placeholder={t('keystore.awsS3BucketPlaceholder')}
                        type="text"
                        invalid={Boolean(inputErrors.awsS3Bucket)}
                        invalidText={t('keystore.awsS3BucketInputInvalid')}
                        defaultValue={inputState.awsS3Bucket}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputActions.setField('awsS3Bucket', e.target.value.trim() || '')
                        }
                        onBlur={() => {
                            inputActions.validateField({ field: 'awsS3Bucket', skipNull: true });
                        }}
                    />
                </div>
                <div className="flex-1 px-4"></div>
            </div>
        </>
    );
};

export default AddCloudHSM;
