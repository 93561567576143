import {
    DatabaseProxyPayload,
    RbacConfigurations,
    AdvanceConfigurations,
    DebugLevel,
} from '@baffle/graphql/src/models';

import client from '.';

export function createDatabaseProxy(payload: DatabaseProxyPayload) {
    return client('/api/v2/database-proxies', { body: payload, method: 'POST' });
}

export function readDatabaseProxy(id: string) {
    return client(`/api/v2/database-proxies/${id}`);
}

export function readDatabaseProxies() {
    return client(`/api/v2/database-proxies`);
}

export function updateDatabaseProxy(id: string, payload: DatabaseProxyPayload) {
    return client(`/api/v2/database-proxies/${id}`, { body: payload, method: 'PUT' });
}

export function deleteDatabaseProxy(id: string) {
    return client(`/api/v2/database-proxies/${id}`, { method: 'DELETE' });
}

export function renameDatabaseProxy(id: string, payload: { newName: string }) {
    return client(`/api/v2/database-proxies/${id}/rename`, { body: payload, method: 'PATCH' });
}

export function readDatabaseProxyShields(id: string) {
    return client(`/api/v2/database-proxies/${id}/shields`);
}

export function readDatabaseProxyConfiguration(id: string) {
    return client(`/api/v2/database-proxies/${id}/configurations`);
}

export function updateDatabaseProxyConfiguration(
    id: string,
    queryParams: { configParam: string },
    payload: AdvanceConfigurations | DebugLevel | RbacConfigurations
) {
    return client(`/api/v2/database-proxies/${id}/configurations`, { queryParams, body: payload, method: 'PUT' });
}

export function deleteDatabaseProxyAdvanceConfiguration(id: string, payload: string[]) {
    return client(`/api/v2/database-proxies/${id}/advanced-configurations`, { body: payload, method: 'DELETE' });
}

export default {
    createDatabaseProxy,
    readDatabaseProxy,
    readDatabaseProxies,
    updateDatabaseProxy,
    deleteDatabaseProxy,
    renameDatabaseProxy,
    readDatabaseProxyShields,
    readDatabaseProxyConfiguration,
    updateDatabaseProxyConfiguration,
    deleteDatabaseProxyAdvanceConfiguration,
};
