import React, { useState, useEffect } from 'react';
import Header from '@baffle/components/src/nav/Header';
import cx from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { t } from '@baffle/translate';
import { Search, Tag, OverflowMenu, OverflowMenuItem } from 'carbon-components-react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Content from '@baffle/components/src/content/Content';
import { ShieldListItem, Shield } from '@baffle/graphql/src/models';
import TH from '@baffle/components/src/table/react-data-grid/TH';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { stringSort, boolSort } from '@baffle/utilities/src/sorters';
import { triggerResize } from '@baffle/utilities/src/resize';
import ReactDataGrid from 'react-data-grid';
import DismissAllShieldNotifications from './DismissAllShieldNotifications';
import RestartShieldModal from './RestartShieldModal';
import './Shields.scss';
import Toast, { ToastState } from '@baffle/components/src/toasts/Toast';
import ShieldSidePanel from '../sidepanel/ShieldSidePanel';
import usePageHeight from '@baffle/utilities/src/usePageHeight';
import { useHistory } from 'react-router-dom';
import Cell from '@baffle/components/src/table/react-data-grid/Cell';
import { ShieldStore } from '../stores';
import { Observer } from 'mobx-react-lite';
import { ToastStore } from '../stores/ToastStore';
import useAwait from '@baffle/api-client/src/useAwait';
import SkeletonText from '@baffle/components/src/skeleton/SkeletonText';
import { useManageModals } from '../../../components/src/modal/Modal';
import agentClient from '@baffle/api-client/src/agentClient';
import usePoller from '@baffle/api-client/src/usePoller';
import logger from '@baffle/utilities/src/logger';
import Status from '@baffle/components/src/status/Status';
import { StatusMaps } from '@baffle/utilities/src/enums';

const Shields = () => {
    const history = useHistory();
    const initialToastState: ToastState = {
        show: false,
        title: '',
        type: 'success',
    };
    const [toast, setToast] = useState(initialToastState);
    const columns = [
        {
            key: 'header1',
            name: t('shield.header1'),
            sortable: true,
            formatter: Cell,
            sortFn: (a: ShieldListItem, b: ShieldListItem) => stringSort(a.shieldName, b.shieldName),
            headerRenderer: <TH idPrefix="shields" />,
        },
        {
            key: 'header2',
            name: t('shield.header2'),
            width: 300,
            sortable: true,
            formatter: Cell,
            sortFn: (a: ShieldListItem, b: ShieldListItem) => stringSort(a.hostName, b.hostName),
            headerRenderer: <TH idPrefix="shields" />,
        },
        {
            key: 'alerts',
            name: t('main.alerts'),
            width: 150,
            sortable: true,
            formatter: Cell,
            sortFn: (a?: Shield, b?: Shield) => boolSort(Boolean(a?.expiresSoon), Boolean(b?.expiresSoon)),
            headerRenderer: <TH idPrefix="keystores" />,
        },
        {
            key: 'header3',
            name: t('shield.header3'),
            width: 100,
            sortable: true,
            formatter: Cell,
            sortFn: (a: ShieldListItem, b: ShieldListItem) => boolSort(Boolean(a.ssl), Boolean(b.ssl)),
            headerRenderer: <TH idPrefix="shields" />,
        },
        {
            key: 'header4',
            name: t('shield.header4'),
            width: 150,
            sortable: true,
            formatter: Cell,
            sortFn: (a: ShieldListItem, b: ShieldListItem) => stringSort(a.state, b.state),
            headerRenderer: <TH idPrefix="shields" />,
        },
        {
            key: 'header5',
            formatter: Cell,
            name: '',
            width: 60,
        },
        //header6 is literally a filler for spacing
        {
            key: 'header6',
            formatter: Cell,
            name: ' ',
            width: 1,
        },
    ];
    const pageHeight = usePageHeight();
    const pageHeightOffset = 126.99; //126.99 is the header height and search
    const size = Math.floor((pageHeight - pageHeightOffset) / 35);
    interface ShieldDashboardModals {
        dismissAllNotifications: boolean;
        restartShield: boolean;
        data: {
            shieldListItem?: ShieldListItem;
            shield?: Shield;
        };
    }
    const initialModalState = {
        dismissAllNotifications: false,
        restartShield: false,
        data: {},
    };
    const [modalsToggled, setModalsToggled] = useManageModals<ShieldDashboardModals>(initialModalState);
    const [selectedBs, setSelectedBs]: any = useState(null);

    const { status, error: listShieldError, run } = useAwait();

    const listShields = async () => {
        try {
            await ShieldStore.list();
            await Promise.all(
                ShieldStore.shieldListItems.map(shieldListItem =>
                    ShieldStore.read(shieldListItem.id, { resource: 'Shield' })
                )
            );
        } catch (error) {
            throw error;
        }
    };
    useEffect(() => {
        if (listShieldError) {
            logger.error(listShieldError);
            ToastStore.push({ type: 'danger', title: t('shield.listShieldError') });
        }
    }, [listShieldError]);

    useEffect(() => {
        run(listShields());
    }, []);

    usePoller(() => run(ShieldStore.list(), { skipStatus: true }));

    const initialResults: { results: ShieldListItem[]; query: string } = { results: [], query: '' };
    const [filtered, setFiltered] = useState(initialResults);
    const [sort, setSort] = useState({ direction: 'NONE', key: '' });

    const skeletonCol = {
        id: <SkeletonText />,
        header1: <SkeletonText />,
        header2: <SkeletonText />,
        header3: <SkeletonText />,
        header4: <SkeletonText />,
        alerts: <SkeletonText />,
        header5: ' ',
        header6: ' ',
    };

    const emptyCol = {
        id: '',
        header1: '',
        header2: '',
        header3: '',
        header4: '',
        alerts: '',
        header5: '',
        header6: '',
    };

    return (
        <Observer>
            {() => {
                const shieldsUnfiltered = ShieldStore.shieldListItems.slice();
                const shields = filtered.query && filtered.query != '' ? filtered.results : shieldsUnfiltered || [];

                //set the selected bs from selecting an error notification from the notification panel
                useEffect(() => {
                    //@ts-ignore
                    if (history?.location?.state?.selectedShieldID) {
                        const shield: ShieldListItem | undefined = shieldsUnfiltered.find(
                            //@ts-ignore
                            (s: ShieldListItem) => s.id === history?.location?.state?.selectedShieldID
                        );
                        if (shield) {
                            setSelectedBs(shield);
                            triggerResize(50);
                            //@ts-ignore
                            delete history.location.state.selectedShieldID;
                        }
                    }
                }, [history.location, shieldsUnfiltered]);

                //sort data
                if (sort.direction !== 'NONE') {
                    const header = columns.find(h => h.key === sort.key);
                    if (header && header.sortFn) {
                        shields.sort((a: ShieldListItem, b: ShieldListItem) => {
                            if (sort.key === 'alerts') {
                                const shieldA = ShieldStore.shields.find(s => s.id === a.id);
                                const shieldB = ShieldStore.shields.find(s => s.id === b.id);
                                if (sort.direction === 'ASC') {
                                    //@ts-ignore
                                    return header.sortFn(shieldA, shieldB);
                                } else if (sort.direction === 'DESC') {
                                    //@ts-ignore
                                    return header.sortFn(shieldB, shieldA);
                                } else {
                                    return 0;
                                }
                            }
                            if (sort.direction === 'ASC') {
                                //@ts-ignore
                                return header.sortFn(a, b);
                            } else if (sort.direction === 'DESC') {
                                //@ts-ignore
                                return header.sortFn(b, a);
                            } else {
                                return 0;
                            }
                        });
                    }
                }
                const rows =
                    status === 'loading'
                        ? //add  skeleton row on initial load
                          [...Array(Math.floor(1)).keys()].map(() => skeletonCol)
                        : shields.map((d: ShieldListItem, i) => {
                              const shield = ShieldStore.shields.find(s => s.id === d.id);
                              const alert =
                                  shield && shield.expiresSoon ? <Tag type={'red'}>{t('main.alert')}</Tag> : null;
                              return {
                                  id: d.id,
                                  selectedId: selectedBs?.id,
                                  header1: d.shieldName,
                                  header2: d.hostName || 'N/A',
                                  alerts: alert,
                                  header3: Boolean(d.ssl) ? (
                                      <CheckCircleIcon
                                          data-testid={`${i}-shield-datagrid-ssl-on-icon`}
                                          className="w-4 h-4 text-green-60"
                                      />
                                  ) : (
                                      <CancelIcon
                                          data-testid={`${i}-shield-datagrid-ssl-off-icon`}
                                          className="w-4 h-4 text-red-60"
                                      />
                                  ),
                                  header4: (
                                      <Status title={d.state} type={StatusMaps.shieldStatusMap[d.state ?? 'NA']} />
                                  ),
                                  header5: (
                                      <OverflowMenu
                                          menuOffset={{ left: -64 }}
                                          className="datagrid-settings"
                                          ariaLabel={t('application.overFlowMenu')}
                                          onClick={(e: React.MouseEvent) => e.stopPropagation()}
                                          renderIcon={() => <MoreVertIcon className="w-4 h-4 text-gray-70" />}>
                                          <OverflowMenuItem
                                              itemText={
                                                  <>
                                                      <div className="config-menu-delete flex items-center">
                                                          <DeleteOutlineIcon className="mr-1 w-4 h-4" />{' '}
                                                          {t('main.delete')}
                                                      </div>
                                                  </>
                                              }
                                              onClick={async (e: React.MouseEvent) => {
                                                  e.stopPropagation();
                                                  doDeleteShield(d.id, d.shieldName);
                                              }}
                                          />
                                      </OverflowMenu>
                                  ),
                                  header6: ' ',
                              };
                          });

                if (status === 'loading') {
                    //@ts-ignore
                    rows.push(skeletonCol, skeletonCol, skeletonCol);
                }

                //add empty rows to fill the page, if needed
                if (shields.length < size) {
                    const sizeDelta = size - shields.length;
                    //@ts-ignore
                    rows.push(...[...Array(sizeDelta).keys()].map(() => emptyCol));
                }

                const searchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                    const query = (e.target.value || '').toLowerCase();
                    const results = (shieldsUnfiltered ?? []).filter(
                        (s: ShieldListItem) =>
                            (s.shieldName.toLowerCase().includes(query) || s.hostName!.toLowerCase().includes(query)) &&
                            query !== ''
                    );
                    setFiltered({ results, query });
                };

                const doDeleteShield = async (id: string, name: string) => {
                    const shield = shields.find((s: ShieldListItem) => s.id === id);
                    if (shield) {
                        if (shield.applications?.length > 0) {
                            setToast({
                                show: true,
                                type: 'danger',
                                title: t('shield.deleteFailApplication', { name: name }),
                            });
                            return;
                        }

                        await ShieldStore.remove(id);
                        if (selectedBs && selectedBs.id === id) {
                            setSelectedBs(null);
                            triggerResize(50);
                        }
                        run(listShields());
                        setToast({
                            show: true,
                            type: 'success',
                            title: t('shield.deleteSuccess', { name: name }),
                        });
                        setFiltered({ results: [], query: '' });
                    }
                };

                return (
                    <>
                        {toast.show ? (
                            <Toast
                                title={toast.title}
                                type={toast.type}
                                onDismiss={() => setToast({ title: '', show: false, type: 'success' })}
                            />
                        ) : null}
                        <RestartShieldModal
                            shield={selectedBs}
                            open={modalsToggled.restartShield}
                            onClose={() => setModalsToggled(initialModalState)}
                        />
                        {/* add clear all alerts modal here */}
                        <DismissAllShieldNotifications
                            onSuccess={async (shieldId?: string) => {
                                try {
                                    await agentClient.update({ agentId: shieldId });
                                    run(listShields());
                                } catch (error) {
                                    ToastStore.push({
                                        title: t('shield.dismissAllFailure'),
                                        type: 'danger',
                                    });
                                }
                            }}
                            resourceId={modalsToggled.data?.shieldListItem?.id}
                            open={modalsToggled.dismissAllNotifications}
                            onClose={() => setModalsToggled(initialModalState)}
                        />
                        <Content>
                            <Header>
                                <div className="flex-1 px-4 flex justify-between">
                                    <div className="flex-1 flex items-center h-12">
                                        <h1 className="text-heading-01 text-gray-100">
                                            {/* ternary here to get i18n to update */}
                                            {shieldsUnfiltered
                                                ? t('shield.headerName', {
                                                      count: shieldsUnfiltered.length,
                                                  })
                                                : t('shield.headerName', {
                                                      count: '-',
                                                  })}
                                        </h1>
                                    </div>
                                </div>
                            </Header>
                            <div className="flex shields font-normal text-sm leading-primary tracking-primary">
                                <div
                                    id="table-container"
                                    className={cx('datagrid-container shield-datagrid flex-grow', {
                                        'with-side-panel': Boolean(selectedBs),
                                    })}>
                                    <div>
                                        <div className="flex flex-wrap">
                                            <div className="flex-1 px-4">
                                                <Search
                                                    size="sm"
                                                    value={filtered.query ?? ''}
                                                    closeButtonLabelText={t('main.ariaCloseSearch')}
                                                    className="data-grid-search"
                                                    labelText={t('shield.ariaSearch')}
                                                    placeholder={t('shield.ariaSearch')}
                                                    onChange={searchChange}
                                                    id="shield-data-grid-search"
                                                    autoComplete="none"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <ReactDataGrid
                                        rowHeight={48}
                                        headerFiltersHeight={48}
                                        headerRowHeight={48}
                                        onRowClick={(_idx: number, row: any) => {
                                            if (row) {
                                                const shield = shields.find((s: ShieldListItem) => s.id === row.id);
                                                if (shield) {
                                                    setSelectedBs(shield);
                                                    triggerResize(50);
                                                }
                                            }
                                        }}
                                        enableCellSelect={false}
                                        // @ts-ignore
                                        enableCellAutoFocus={false}
                                        onGridSort={(key: string, direction: string) => setSort({ direction, key })}
                                        //@ts-ignore
                                        columns={columns}
                                        rowGetter={i => rows[i]}
                                        rowsCount={rows.length}
                                        minHeight={pageHeight - pageHeightOffset}
                                        //@ts-ignore
                                        enableRowSelect={null}
                                    />
                                </div>
                            </div>
                        </Content>
                        {selectedBs ? (
                            <ShieldSidePanel
                                deleteShield={doDeleteShield}
                                shieldListItem={selectedBs}
                                triggerDismissAllNotifications={(shieldListItem: ShieldListItem) => {
                                    setModalsToggled(
                                        Object.assign({}, initialModalState, {
                                            dismissAllNotifications: true,
                                            data: { shieldListItem },
                                        })
                                    );
                                }}
                                handleRestartShield={() => {
                                    setModalsToggled(
                                        Object.assign({}, initialModalState, {
                                            restartShield: true,
                                        })
                                    );
                                }}
                                onClose={() => {
                                    setSelectedBs(null);
                                    triggerResize();
                                }}
                            />
                        ) : null}
                    </>
                );
            }}
        </Observer>
    );
};

export default Shields;
