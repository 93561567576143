import React, { useState, useEffect } from 'react';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import Joi from '@hapi/joi';

import { t } from '@baffle/translate';
import { useHistory, useParams } from 'react-router-dom';
import InlineToast, { ToastState } from '@baffle/components/src/toasts/InlineToast';
import useEnterKeyPress from '@baffle/utilities/src/useEnterKeyPress';
import TextInput from '@baffle/components/src/forms/TextInput';
import Button from '@baffle/components/src/buttons/Button';
import useAwait from '@baffle/api-client/src/useAwait';
import { UsersStore } from '../stores';
import inviteUserClient from '@baffle/api-client/src/inviteUserClient';
import { User } from '@baffle/graphql/src/models';

const CreateProfile = () => {
    const { token } = useParams() as { token: string | undefined };
    const history = useHistory();
    const { status, error, run } = useAwait();
    useEffect(() => {
        run(UsersStore.readUserFromActivationToken(token as string));
    }, []);
    const initialToastState: ToastState = {
        show: false,
        title: '',
        type: 'success',
    };
    const [toast, setToast] = useState(initialToastState);
    const { inputState, inputErrors, inputActions } = useInputState({
        keys: ['firstName', 'lastName', 'password', 'confirmPassword'],
        ns: 'setup',
        defaultValues: {},
        validationSchema: {
            firstName: Joi.string()
                .min(1)
                .required()
                .error(new Error(t('setup.firstNameInputInvalid'))),
            lastName: Joi.string()
                .min(1)
                .required()
                .error(new Error(t('setup.lastNameInputInvalid'))),
            password: Joi.string()
                .pattern(new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{10,}$'))
                .required()
                .error(new Error(t('setup.passwordInputInvalid'))),
            confirmPassword: Joi.string()
                .custom(val => {
                    if (val !== inputState.password) {
                        throw new Error(t('setup.passwordInputInvalid'));
                    }
                    return val;
                })
                .required()
                .error(new Error(t('setup.passwordInputInvalid'))),
        },
    });

    const isValid = inputActions.validateAll(true);

    const doActivateUser = async () => {
        try {
            await inviteUserClient.createActivateUser({
                data: {
                    id: UsersStore.currentUser?.id,
                    firstName: inputState.firstName,
                    lastName: inputState.lastName,
                    password: inputState.password,
                    email: UsersStore.currentUser?.email,
                } as Partial<User>,
            });
            history.replace('/login', {
                show: true,
                type: 'success',
                title: t('users.createProfileSuccess'),
            });
        } catch (error) {
            setToast({ type: 'danger', show: true, title: JSON.parse(error.message).errors.join(', ') });
        }
    };

    useEnterKeyPress(() => {
        if (isValid) {
            doActivateUser();
        }
    });

    useEffect(() => {
        if (status === 'error') {
            history.replace('/login', {
                show: true,
                type: 'danger',
                title: error?.message ?? t('users.invalidActivation'),
            });
        }
    }, [status, error]);

    if (status === 'idle' || status === 'loading') {
        return <SkeletonCreateProfile />;
    }

    return (
        <div className="w-120">
            <div className="bg-white px-4 py-2 border-b-2 border-gray-300 border-solid">
                <div className="flex items-start">
                    <div className="mt-0 ml-2">
                        <h3 className="text-xl font-medium text-gray-100" id="modal-headline">
                            {t('session.createUserProfile')}
                        </h3>
                    </div>
                </div>
            </div>
            <div className="bg-gray-200 p-12">
                {toast.show ? (
                    <InlineToast
                        className="mb-5"
                        duration={100000}
                        title={toast.title}
                        type={toast.type}
                        onDismiss={() => setToast({ title: '', show: false, type: 'success' })}
                    />
                ) : null}
                <div className="mb-6">
                    <TextInput
                        id={`createprofile-firstName-input`}
                        name={`createprofile-firstName-input`}
                        labelText={t('setup.firstNameInputLabel')}
                        placeholder={t('setup.firstNameInputPlaceholder')}
                        invalid={Boolean(inputErrors.firstName)}
                        invalidText={inputErrors.firstName}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            inputActions.validateField({ field: 'firstName', skipNull: true });
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value || '';
                            inputActions.setField('firstName', val.trim());
                        }}
                    />
                </div>
                <div className="mb-6">
                    <TextInput
                        id={`createprofile-lastName-input`}
                        name={`createprofile-lastName-input`}
                        labelText={t('setup.lastNameInputLabel')}
                        placeholder={t('setup.lastNameInputPlaceholder')}
                        invalid={Boolean(inputErrors.lastName)}
                        invalidText={inputErrors.lastName}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            inputActions.validateField({ field: 'lastName', skipNull: true });
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value || '';
                            inputActions.setField('lastName', val.trim());
                        }}
                    />
                </div>
                <div className="flex mb-6">
                    <div className="flex flex-col flex-grow">
                        <div className="mb-6">
                            <TextInput
                                id={`createprofile-password-input`}
                                name={`createprofile-password-input`}
                                labelText={t('session.passwordInputLabel')}
                                placeholder={t('session.passwordInputLabelPlaceholder')}
                                invalid={Boolean(inputErrors.password)}
                                invalidText={inputErrors.password}
                                type="password"
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    inputActions.validateField({ field: 'password', skipNull: true });
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const val = e.target.value || '';
                                    inputActions.setField('password', val.trim());
                                }}
                            />
                        </div>
                        <div className="mb-6">
                            <TextInput
                                labelText={t('setup.confirmPasswordInputLabel')}
                                id={`setup-confirmPassword-input`}
                                name={`setup-confirmPassword-input`}
                                placeholder={t('setup.confirmPasswordInputPlaceholder')}
                                invalid={Boolean(inputErrors.confirmPassword)}
                                invalidText={inputErrors.confirmPassword}
                                type="password"
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    inputActions.validateField({ field: 'confirmPassword', skipNull: true });
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const val = e.target.value || '';
                                    inputActions.setField('confirmPassword', val.trim());
                                }}
                            />
                        </div>
                    </div>
                    <p className="text-gray-500 w-32 ml-4 mt-5 text-xs leading-4">{t('session.passwordReq')}</p>
                </div>
            </div>
            <div className="flex h-20">
                <Button theme="secondary" onClick={() => history.push('/login')} className="flex-grow">
                    {t('main.cancel')}
                </Button>
                <Button theme={!isValid ? 'disabled' : 'primary'} onClick={doActivateUser} className="flex-grow">
                    {t('main.save')}
                </Button>
            </div>
            <div data-testid="copyright" className="copyright text-white my-12 text-center">
                {t('support.copyright', { year: new Date().getFullYear() })}
            </div>
        </div>
    );
};

export const SkeletonCreateProfile = () => {
    return (
        <div className="w-120">
            <div className="max-w-sm w-full mx-auto bg-gray-200 p-12">
                <div className="animate-pulse flex space-x-4 my-10">
                    <div className="flex-1 space-y-4 py-1">
                        <div className="h-4 bg-gray-300 rounded w-3/4"></div>
                        <div className="space-y-2">
                            <div className="h-4 bg-gray-300 rounded"></div>
                            <div className="h-4 bg-gray-300 rounded w-5/6"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateProfile;
