// TO-DO: implement as custom error debugger
const error = (err: any) => {
    if (err) {
        console.error(err);
    }
    return;
};
const info = (info: any) => {
    console.info(info);
};

const log = (info: any) => {
    console.log(info);
};

export default { error, info, log };
