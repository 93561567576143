import React, { useEffect } from 'react';
import Button from '@baffle/components/src/buttons/Button';
import TextInput from '@baffle/components/src/forms/TextInput';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import Joi from '@hapi/joi';
import { t } from '@baffle/translate';
import { useHistory } from 'react-router-dom';
import InlineToast from '@baffle/components/src/toasts/InlineToast';
import useEnterKeyPress from '@baffle/utilities/src/useEnterKeyPress';
import { useSetToast } from '@baffle/components/src/toasts/Toast';
import { KeyStoreEnums } from '@baffle/utilities/src/enums';
import { SetupStore, SystemEmailStore } from '../stores';
import { CredentialStoreInfo, InitialConfiguration, SuperAdminInfo, SystemInfo } from '@baffle/graphql/src/models';
import PasswordToggleInput from '@baffle/components/src/forms/PasswordToggleInput';
import { InlineLoading } from 'carbon-components-react';
import useAwait from '@baffle/api-client/src/useAwait';
import sessionClient from '@baffle/api-client/src/sessionClient';

const ConfigureCredentialStore = () => {
    const history = useHistory();
    const ksName = 'baffle_credential_store';
    const { toast, setToast } = useSetToast();
    const systemData: SystemInfo | null = SetupStore.systemData;
    const superAdminData: SuperAdminInfo | null = SetupStore.superAdminData;
    const env = SystemEmailStore.systemEnvironment;
    const { run, status, error } = useAwait();

    useEffect(() => {
        if (!superAdminData?.email) {
            history.replace('/configure-system');
        }
    }, [superAdminData]);

    useEffect(() => {
        if (error) {
            setToast({ show: true, type: 'danger', title: error.message ?? t('setup.initialRegisterError') });
        }
    }, [error]);

    const { inputState, inputErrors, inputActions } = useInputState({
        keys: ['type', 'secretKey', 'configPassword', 'confirmPassword'],
        ns: 'setup',
        defaultValues: {
            type: KeyStoreEnums.LOCAL,
            secretKey: '',
            configPassword: '',
            confirmPassword: '',
        },
        validationSchema: {
            type: Joi.string()
                .min(1)
                .required(),
            secretKey: Joi.string()
                .min(1)
                .required()
                .pattern(new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{10,}$'))
                .error(new Error(t('keystore.secretKeyInputInvalid'))),
            configPassword: Joi.string()
                .min(1)
                .required()
                .pattern(new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{10,}$'))
                .error(new Error(t('setup.configPasswordInputInvalid'))),
            confirmPassword: Joi.string()
                .custom(val => {
                    if (val !== inputState.configPassword) {
                        throw new Error(t('setup.passwordMismatch'));
                    }
                    return val;
                })
                .required()
                .error(new Error(t('setup.passwordMismatch'))),
        },
    });

    const isValid = inputActions.validateAll(true);

    const doBack = () => {
        const credentialStoreData: CredentialStoreInfo = {
            secretKey: inputState.secretKey as string,
            configPassword: inputState.configPassword as string,
            confirmPassword: inputState.confirmPassword as string,
        };
        SetupStore.setCredentialStoreData(credentialStoreData);
        history.replace('/configure-admin-user');
    };

    const doSetInitialConfiguration = () => {
        const payload: InitialConfiguration = {
            systemSettings: {
                ...(env === 'IBM_CLOUD' && {
                    initPass: systemData?.initPass ?? '',
                }),
                domain: systemData?.domain ?? '',
                organization: systemData?.organization ?? '',
                publicAccess: systemData?.publicAccess ?? '',
                proxyAccess: systemData?.proxyAccess ?? '',
                id: systemData?.id ?? '',
            },
            superAdminDetails: {
                firstName: superAdminData?.firstName ?? null,
                lastName: superAdminData?.lastName ?? null,
                email: superAdminData?.email ?? '',
                phone: superAdminData?.phone ?? null,
                password: superAdminData?.password ?? null,
                confirmPassword: superAdminData?.confirmPassword ?? null,
            },
            keystoreDetails: {
                name: ksName,
                type: inputState.type as string,
                details: {
                    secretKey: inputState.secretKey as string,
                    configPassword: inputState.configPassword as string,
                    confirmPassword: inputState.confirmPassword as string,
                },
            },
        };
        run(
            sessionClient.setInitialConfiguration(payload).then(() => {
                history.replace('/login', {
                    show: true,
                    type: 'success',
                    title: t(`setup.success.${env}`),
                });
            })
        );
    };

    useEnterKeyPress(() => {
        if (isValid) {
            doSetInitialConfiguration();
        }
    });

    const credentialStoreData: CredentialStoreInfo | null = SetupStore.credentialStoreData;

    useEffect(() => {
        inputActions.setFields({
            secretKey: credentialStoreData?.secretKey,
            configPassword: credentialStoreData?.configPassword,
            confirmPassword: credentialStoreData?.confirmPassword,
        });
    }, [credentialStoreData]);

    return (
        <div className="w-132">
            <div className="bg-white py-4 px-8">
                {toast.show ? (
                    <InlineToast
                        className="mb-5"
                        duration={100000}
                        title={toast.title}
                        type={toast.type}
                        onDismiss={() => setToast({ title: '', show: false, type: 'success' })}
                    />
                ) : null}

                <div className="my-4 text-lg">
                    <span className="font-bold">{t('setup.step3')}</span> {t('setup.configureCredential')}
                </div>
                <div className="mb-6">
                    <PasswordToggleInput
                        name="setup-secretKey-input"
                        id={`setup-secretKey-input`}
                        labelText={t(`setup.secretKeyInputLabel.${env}`)}
                        placeholder={t('setup.secretKeyInputPlaceholder')}
                        invalid={Boolean(inputErrors.secretKey)}
                        invalidText={inputErrors.secretKey}
                        value={(inputState.secretKey as string) ?? ''}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            inputActions.validateField({ field: 'secretKey', skipNull: true });
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value || '';
                            inputActions.setField('secretKey', val.trim());
                        }}
                    />
                    <p className="text-gray-500 text-xxxs">{t('setup.passwordValidationMessage')}</p>
                </div>
                <div className="mb-6">
                    <PasswordToggleInput
                        name="setup-configPassword-input"
                        id={`setup-configPassword-input`}
                        labelText={t('setup.configPasswordInputLabel')}
                        placeholder={t('setup.configPasswordInputPlaceholder')}
                        invalid={Boolean(inputErrors.configPassword)}
                        invalidText={inputErrors.configPassword}
                        value={(inputState.configPassword as string) ?? ''}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            inputActions.validateField({ field: 'configPassword', skipNull: true });
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value || '';
                            inputActions.setField('configPassword', val.trim());
                        }}
                    />
                    <p className="text-gray-500 text-xxxs">{t('setup.passwordValidationMessage')}</p>
                </div>
                <div className="mb-6">
                    <TextInput
                        name="setup-confirmPassword-input"
                        labelText={t('setup.confirmConfigPasswordInputLabel')}
                        id={`setup-confirmPassword-input`}
                        placeholder={t('setup.confirmConfigPasswordInputPlaceholder')}
                        invalid={Boolean(inputErrors.confirmPassword)}
                        invalidText={inputErrors.confirmPassword}
                        type="password"
                        value={(inputState.confirmPassword as string) ?? ''}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            inputActions.validateField({ field: 'confirmPassword', skipNull: true });
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value || '';
                            inputActions.setField('confirmPassword', val.trim());
                        }}
                    />
                </div>
                {status === 'loading' ? (
                    <InlineLoading description={t('main.configuring')} />
                ) : (
                    <div className="w-100 flex justify-end h-10">
                        <Button className="w-1/5" theme="secondary" onClick={() => doBack()}>
                            {t('setup.back')}
                        </Button>
                        <Button
                            className="w-1/5"
                            theme={isValid ? 'primary' : 'disabled'}
                            id="do-initial-register-btn"
                            disabled={!isValid}
                            onClick={() => doSetInitialConfiguration()}>
                            {t('setup.finish')}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ConfigureCredentialStore;
