import { AgentError, AgentErrorCount } from '@baffle/graphql/src/models';
import client, { GenericListPayload } from '.';

export interface AgentQueryParams {
    status?: 'OPEN' | 'CLOSED';
    page?: number;
    size?: number;
    appId?: string;
    agentId?: string;
    errorId?: string;
}

export async function list({ size = 20, page = 0, ...rest }: AgentQueryParams = {}) {
    return client(`v2/agent-errors`, { queryParams: { size, page, ...rest } }) as Promise<
        GenericListPayload<AgentError>
    >;
}

export async function readCount(queryParams: AgentQueryParams = {}) {
    try {
        const res = await client(`v2/agent-errors`, { queryParams, method: 'HEAD', returnResponse: true });
        const body: AgentErrorCount = { count: res.headers.get('X-Total-Count') ?? 0 };
        return body;
    } catch (error) {
        throw error;
    }
}

interface CloseErrorParams extends AgentQueryParams {
    id?: string;
}
export async function update(queryParams: CloseErrorParams = {}) {
    return client(`v2/agent-errors/status/close`, { queryParams, method: 'PATCH' });
}

export async function remove(id: string) {
    return client(`v2/agent-errors/${id}`, { method: 'DELETE' });
}

export default {
    list,
    readCount,
    update,
    remove,
};
