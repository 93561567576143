import React, { useState } from 'react';
import LeftSideNav from '@baffle/components/src/nav/LeftSideNav';
import NavLinkIcon from '@baffle/components/src/nav/NavLinkIcon';
import NavButton from '@baffle/components/src/nav/NavButton';
import ApplicationIcon from '@baffle/components/src/icons/ApplicationIcon';
import DataBaseIcon from '@baffle/components/src/icons/DataBaseIcon';
import KeystoreIcon from '@baffle/components/src/icons/KeystoreIcon';
import BaffleShieldIcon from '@baffle/components/src/icons/BaffleShieldIcon';
import SignOutIcon from '@baffle/components/src/icons/SignOutIcon';
import UserIcon from '@baffle/components/src/icons/UserIcon';
import AlertIcon from '@baffle/components/src/icons/AlertIcon';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@baffle/components/src/icons/SettingsIcon';
import HelpIcon from '@baffle/components/src/icons/HelpIcon';
import { t } from '@baffle/translate';
import { useMutation } from '@apollo/react-hooks';
import { SIGN_OUT } from '@baffle/graphql';
import BadgeCount from '@baffle/components/src/badges/BadgeCount';
import Divider from '@baffle/components/src/content/Divider';
import { AgentErrorStore, UsersStore, FeatureFlagStore } from '../stores';
import { isSuperAdmin } from '@baffle/utilities/src/user/roles';
import { BaseThemeEnum } from '@baffle/components/src/types/theme';
import { Observer } from 'mobx-react-lite';
import usePoller from '@baffle/api-client/src/usePoller';
import logger from '@baffle/utilities/src/logger';
import KeyClick from '@baffle/components/src/handlers/KeyClck';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PolicyIcon from '@baffle/components/src/icons/PolicyIcon';

interface props {
    toggleNotifications?: () => void;
    toggleSettings?: () => void;
    toggleHelp?: () => void;
    notificationsPanelOpen?: boolean;
    helpPanelOpen?: boolean;
}

const BaffleSideNav = ({
    notificationsPanelOpen = false,
    helpPanelOpen = false,
    toggleNotifications = () => null,
    toggleHelp = () => null,
}: props) => {
    const [signOut] = useMutation(SIGN_OUT);
    const [togglePoliciesDropDown, setTogglePoliciesDropDown] = useState(false);
    const [toggleKeyMgmtDropDown, setToggleKeyMgmtDropDown] = useState(false);
    const [toggleRbacDropDown, setToggleRbacDropDown] = useState(false);

    usePoller(() => AgentErrorStore.readCount().catch(err => logger.error(err)));

    return (
        <LeftSideNav id="baffle-side-nav">
            <div className="flex flex-col flex-grow pt-4 pb-4 overflow-y-auto overflow-x-hidden justify-between">
                <Divider classNames="border-gray-600" />
                <div className="flex-1 flex flex-col">
                    <div className="flex flex-col">
                        <nav className="px-2">
                            <NavLinkIcon to="/applications" aria-label={t('application.ariaNavigate')}>
                                <ApplicationIcon className="w-5 h-5" />
                                <p className="text-base px-1 text-center">{t('main.applications')}</p>
                            </NavLinkIcon>

                            {FeatureFlagStore.databaseProxies && (
                                <NavLinkIcon
                                    to="/database-proxies"
                                    aria-label={t('databaseV2.ariaNavigate')}
                                    data-testid="navlink-database-proxies">
                                    <ApplicationIcon className="w-5 h-5" />
                                    <p className="text-base px-1 text-center">{t('main.databaseProxies')}</p>
                                </NavLinkIcon>
                            )}

                            {FeatureFlagStore.globalPolicies && (
                                <div>
                                    <KeyClick
                                        key={'keyclick_data_policies'}
                                        data-testid={'keyclick_data_policies'}
                                        handler={() => {
                                            setTogglePoliciesDropDown(prevState => {
                                                return !prevState;
                                            });
                                        }}>
                                        <div
                                            className="group flex items-center px-2 py-2 hover:text-white text-gray-400"
                                            aria-controls={`keyclick_data_policies`}
                                            aria-expanded={togglePoliciesDropDown ? 'true' : 'false'}>
                                            <PolicyIcon className="w-5 h-5" />
                                            <span className="flex-1 flex items-center ml-2">
                                                <span className="text-base" title={t('main.policies')}>
                                                    {t('main.policies')}
                                                </span>
                                                {togglePoliciesDropDown ? (
                                                    <KeyboardArrowDownIcon className="ml-1 w-5 h-5" />
                                                ) : (
                                                    <ArrowForwardIosIcon className="pl-2 w-5 h-5" />
                                                )}
                                            </span>
                                        </div>
                                    </KeyClick>
                                    {togglePoliciesDropDown && (
                                        <div className="pl-5">
                                            <NavLinkIcon
                                                to="/encryption-policies"
                                                aria-label={t('globalPolicies.ariaNavigateEncryption')}>
                                                <p className="pl-4 -mt-2 text-xsmall">{t('main.encryption')}</p>
                                            </NavLinkIcon>
                                            <NavLinkIcon
                                                to="/masking-policies"
                                                aria-label={t('globalPolicies.ariaNavigateMasking')}>
                                                <p className="pl-4 -mt-2 text-xsmall">{t('main.masking')}</p>
                                            </NavLinkIcon>
                                            <NavLinkIcon to="/rbac" aria-label={t('globalPolicies.ariaNavigateRbac')}>
                                                <p className="pl-4 -mt-2 text-xsmall">{t('main.rbac')}</p>
                                            </NavLinkIcon>
                                        </div>
                                    )}
                                </div>
                            )}
                            <NavLinkIcon to="/databases" aria-label={t('database.ariaNavigate')}>
                                <DataBaseIcon className="w-5 h-5" />
                                <p className="px-1 text-center text-base">{t('main.databases')}</p>
                            </NavLinkIcon>
                            {FeatureFlagStore.databaseV2 && (
                                <NavLinkIcon
                                    to="/databasesV2"
                                    aria-label={t('databaseV2.ariaNavigate')}
                                    data-testid="navlink-database-v2">
                                    <DataBaseIcon className="w-5 h-5" />
                                    <p className="px-1 text-center text-base">{t('main.databases')}</p>
                                </NavLinkIcon>
                            )}
                            <NavLinkIcon to="/keystores" aria-label={t('keystore.ariaNavigate')}>
                                <KeystoreIcon className="w-5 h-5" />
                                <p className="px-1 text-center text-base">{t('main.keystores')}</p>
                            </NavLinkIcon>

                            {(FeatureFlagStore.keystoreV2 || FeatureFlagStore.apiServices) && (
                                <div>
                                    <KeyClick
                                        key={'keyclick_keys'}
                                        data-testid={'keyclick_keys'}
                                        handler={() => {
                                            setToggleKeyMgmtDropDown(prevState => {
                                                return !prevState;
                                            });
                                        }}>
                                        <div
                                            className="group flex items-center px-2 py-2 hover:text-white text-gray-400"
                                            aria-controls={`keyclick_keys`}
                                            aria-expanded={toggleKeyMgmtDropDown ? 'true' : 'false'}>
                                            <KeystoreIcon className="w-5 h-5" />
                                            <span className="flex-1 flex items-center ml-1">
                                                <span className="text-base" title={t('main.keyManagement')}>
                                                    {t('main.keyManagement')}
                                                </span>
                                                {toggleKeyMgmtDropDown ? (
                                                    <KeyboardArrowDownIcon className="ml-1 w-5 h-5" />
                                                ) : (
                                                    <ArrowForwardIosIcon className="pl-2 w-5 h-5" />
                                                )}
                                            </span>
                                        </div>
                                    </KeyClick>
                                    {toggleKeyMgmtDropDown && (
                                        <div className="pl-5">
                                            {FeatureFlagStore.keystoreV2 && (
                                                <NavLinkIcon to="/keystoresV2" aria-label={t('keystore.ariaNavigate')}>
                                                    <p className="pl-4 -mt-2 text-xsmall">{t('main.keystores')}</p>
                                                </NavLinkIcon>
                                            )}
                                            {FeatureFlagStore.apiServices && (
                                                <NavLinkIcon to="/tenants" aria-label={t('tenants.ariaNavigate')}>
                                                    <p className="pl-4 -mt-2 text-xsmall">{t('main.tenants')}</p>
                                                </NavLinkIcon>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}

                            <NavLinkIcon to="/shields" aria-label={t('shield.ariaNavigate')}>
                                <BaffleShieldIcon className="w-5 h-5" />
                                <p className="px-1 text-center text-base">{t('main.shields')}</p>
                            </NavLinkIcon>
                            {FeatureFlagStore.apiServices && (
                                <div>
                                    <KeyClick
                                        key={'keyclick_rbac'}
                                        data-testid={'keyclick_rbac'}
                                        handler={() => {
                                            setToggleRbacDropDown(prevState => {
                                                return !prevState;
                                            });
                                        }}>
                                        <div
                                            className="group flex items-center px-2 py-2 hover:text-white text-gray-400"
                                            aria-controls={`keyclick_rbac`}
                                            aria-expanded={toggleRbacDropDown ? 'true' : 'false'}>
                                            <PolicyIcon className="w-5 h-5" />
                                            <span className="flex-1 flex items-center ml-2">
                                                <span className="text-base" title={t('main.rbac')}>
                                                    {t('main.rbac')}
                                                </span>
                                                {toggleRbacDropDown ? (
                                                    <KeyboardArrowDownIcon className="ml-1 w-5 h-5" />
                                                ) : (
                                                    <ArrowForwardIosIcon className="pl-2 w-5 h-5" />
                                                )}
                                            </span>
                                        </div>
                                    </KeyClick>
                                    {toggleRbacDropDown && (
                                        <div className="pl-5">
                                            <NavLinkIcon to="/roles" aria-label={t('roles.ariaNavigate')}>
                                                <p className="pl-4 -mt-2 text-xsmall">{t('main.roles')}</p>
                                            </NavLinkIcon>
                                        </div>
                                    )}
                                </div>
                            )}
                        </nav>
                    </div>
                </div>
                <Divider classNames="border-gray-600" />
                <div className="flex flex-col w-full">
                    <div className="flex flex-col">
                        <nav className="px-2">
                            <NavButton
                                data-testid="notifications-icon-btn"
                                btnClass="inline-block relative"
                                color={notificationsPanelOpen ? '#78a9ff' : '#868d95'}
                                onClick={() => {
                                    toggleNotifications();
                                }}
                                aria-label={t('main.toggleNotifications')}>
                                <Observer>
                                    {() => {
                                        const count = AgentErrorStore.getCount();
                                        return (
                                            <BadgeCount
                                                count={count}
                                                theme={BaseThemeEnum.RED}
                                                style={{
                                                    left: count > 9 ? '4px' : '3px',
                                                    top: '4px',
                                                }}
                                            />
                                        );
                                    }}
                                </Observer>
                                <AlertIcon className="w-5 h-5" />
                                <p className="px-1 text-center text-base">{t('main.alerts')}</p>
                            </NavButton>
                            <NavLinkIcon to="/settings" aria-label={t('settings.ariaNavigate')}>
                                <SettingsIcon className="w-5 h-5" />
                                <p className="px-1 text-center text-base">{t('main.settings')}</p>
                            </NavLinkIcon>
                            {isSuperAdmin(UsersStore.currentUser?.roles ?? []) ? (
                                <NavLinkIcon
                                    to="/users"
                                    aria-label={t('users.ariaNavigate')}
                                    data-testid="users-nav-btn">
                                    {/* //this barfs because of the color prop from NavLinkIcon is not compatible with material ui component */}
                                    <PeopleIcon className="w-5 h-5" />
                                    <p className="px-1 text-center text-base">{t('main.users')}</p>
                                </NavLinkIcon>
                            ) : null}
                            <NavLinkIcon to="/profile" aria-label={t('profile.ariaNavigate')}>
                                <UserIcon className="w-5 h-5" />
                                <p className="px-1 text-center text-base">{t('main.profile')}</p>
                            </NavLinkIcon>
                            <NavButton
                                data-testid="help-icon-btn"
                                color={helpPanelOpen ? '#78a9ff' : '#868d95'}
                                onClick={() => {
                                    toggleHelp();
                                }}
                                aria-label={t('support.ariaNavigate')}>
                                <HelpIcon className="w-5 h-5" />
                                <p className="px-1 text-center text-base">{t('main.help')}</p>
                            </NavButton>
                        </nav>
                    </div>
                    <Divider classNames="border-gray-600" />
                    <div className="flex flex-col">
                        <nav className="px-2 space-y-1">
                            <NavButton
                                color={'#fb4b53'}
                                hoverColor={'#fb4b53'}
                                onClick={() => {
                                    signOut();
                                }}
                                override={true}
                                aria-label={t('main.signout')}>
                                <SignOutIcon className="w-5 h-5" />
                                <p className="px-1 text-sm text-red-100">{t('main.logoff')}</p>
                            </NavButton>
                        </nav>
                    </div>
                </div>
            </div>
        </LeftSideNav>
    );
};
export default BaffleSideNav;
