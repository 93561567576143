import React from 'react';
import {
    EncryptionDatabase,
    EncryptionDatabaseTable,
    EncryptionDatabaseSchema,
    CryptoActionType,
} from '@baffle/graphql/src/models';
import './Encryption.scss';
import { Observer } from 'mobx-react-lite';
import { EncryptionStore } from '../../stores';
import useAwait from '@baffle/api-client/src/useAwait';
import cx from 'classnames';
import SkeletonText, { getDefaultSkeletonTextProps } from '@baffle/components/src/skeleton/SkeletonText';
import TreeDownArrowIcon from '@baffle/components/src/icons/TreeDownArrowIcon';
import TreeRightArrowIcon from '@baffle/components/src/icons/TreeRightArrowIcon';

import TreeDatabaseIcon from '@baffle/components/src/icons/TreeDatabaseIcon';
import SchemaIcon from '@baffle/components/src/icons/SchemaIcon';
import OverflowMenu from '@baffle/components/src/menu/OverflowMenu';
import TreeDropdownDownIcon from '@baffle/components/src/icons/TreeDropdownDownIcon';
import KeyClick from '@baffle/components/src/handlers/KeyClck';
import EncryptionTreeViewItem from './EncryptionTreeViewItem';

interface EncryptionTreeViewProps {
    id: string;
    dbs: EncryptionDatabase[];
    tables: EncryptionDatabaseTable[];
    views: EncryptionDatabaseTable[];
    dbSchemas: EncryptionDatabaseSchema[];
    menuItemsDatabase: any;
    menuItemsSchema: any;
    menuItemsTable: any;
    cryptoAction: CryptoActionType;
    fourLevel: boolean | undefined;
    handleFilterChange: (obj: string) => void;
}

const EncryptionTreeView = ({
    id,
    dbs,
    tables,
    views,
    dbSchemas,
    menuItemsDatabase,
    menuItemsSchema,
    menuItemsTable,
    cryptoAction,
    fourLevel,
    handleFilterChange,
}: EncryptionTreeViewProps) => {
    const { status: lazyStatusDB, run: lazyRunDB } = useAwait();
    const { status: lazyStatusSchema, run: lazyRunSchema } = useAwait();
    return fourLevel ? (
        <Observer>
            {() => (
                <nav
                    className="flex-1 bg-white overflow-y-auto text-xs border-solid border-r-2 border-gray-300"
                    aria-label="Sidebar">
                    {dbs.map((db, i) => {
                        const isSelected = EncryptionStore.selectedEncryptionDatabase === db.dbName;
                        return (
                            <div key={`${db.dbName}_${i}`}>
                                <KeyClick
                                    key={`keyclick_${db.dbName}_${i}`}
                                    data-testid={`database_tree_entry_${db.dbName}`}
                                    handler={() => {
                                        if (isSelected) {
                                            EncryptionStore.setSelectedDatabase(cryptoAction, '');
                                        } else {
                                            EncryptionStore.setSelectedDatabase(cryptoAction, db.dbName);
                                            EncryptionStore.setSelectedSchema(cryptoAction, '');
                                            lazyRunDB(EncryptionStore.listDatabaseSchemas(id, db.dbName));
                                            handleFilterChange('schema');
                                        }
                                    }}>
                                    <div
                                        className="group hover:bg-gray-200 hover:text-gray-900 bg-white text-gray-700 w-full flex items-center font-medium py-1"
                                        aria-controls={`schema-nav-menu-${i}`}
                                        aria-expanded={isSelected ? 'true' : 'false'}>
                                        <span
                                            className={cx('flex-1 flex justify-between items-center ml-3', {
                                                italic: db.new,
                                            })}>
                                            <span className="w-64 truncate">
                                                {isSelected ? <TreeDownArrowIcon /> : <TreeRightArrowIcon />}
                                                <TreeDatabaseIcon />
                                                <span className="ml-1" title={db.dbName}>
                                                    {db.dbName}
                                                </span>
                                            </span>
                                            {menuItemsDatabase[db.dbName] ? (
                                                <OverflowMenu
                                                    data-testid={`tree-database-menu-enc-${db.dbName}`}
                                                    actuator={{
                                                        'data-testid': 'tree-database-dropdown-btn-enc-' + db.dbName,
                                                        text: <TreeDropdownDownIcon />,
                                                        className:
                                                            'mr-2 border border-gray-300 opacity-0 hover:opacity-100 group-hover:opacity-100 hover:bg-gray-300 focus:outline-none focus:opacity-100 focus:bg-white focus:shadow-lg',
                                                        onClick: (e: React.MouseEvent) => e.stopPropagation(),
                                                    }}
                                                    menuItems={menuItemsDatabase[db.dbName]}
                                                    menuClassName="z-10 mr-2"
                                                />
                                            ) : null}
                                        </span>
                                    </div>
                                </KeyClick>
                                {isSelected ? (
                                    <div className="space-y-1" id={`schema-nav-menu-${i}`}>
                                        {lazyStatusDB === 'loading' ? (
                                            <SkeletonText
                                                getSkeletonProps={() => {
                                                    const { className, ...rest } = getDefaultSkeletonTextProps();
                                                    return {
                                                        ...rest,
                                                        width: 'w-40',
                                                        className: `${className} ml-6`,
                                                    };
                                                }}
                                            />
                                        ) : (
                                            dbSchemas.map((schema, i) => {
                                                const isSelectedSchema =
                                                    EncryptionStore.selectedEncryptionSchema === schema.name;
                                                return (
                                                    <div key={`${schema.schemaName}_${i}`}>
                                                        <KeyClick
                                                            key={`keyclick_${schema.schemaName}_${i}`}
                                                            data-testid={`schema_tree_entry_${schema.name}`}
                                                            handler={() => {
                                                                if (isSelectedSchema) {
                                                                    EncryptionStore.setSelectedSchema(cryptoAction, '');
                                                                } else {
                                                                    EncryptionStore.setSelectedSchema(
                                                                        cryptoAction,
                                                                        schema.name
                                                                    );
                                                                    EncryptionStore.setSelectedTable(cryptoAction, '');
                                                                    lazyRunSchema(
                                                                        EncryptionStore.listSchemaTables(
                                                                            id as string,
                                                                            schema.name
                                                                        )
                                                                    );
                                                                    lazyRunSchema(
                                                                        EncryptionStore.listSchemaViews(
                                                                            id as string,
                                                                            schema.name
                                                                        )
                                                                    );
                                                                    handleFilterChange('table');
                                                                }
                                                            }}>
                                                            <div
                                                                key={`schema_${i}_${schema.schemaName}`}
                                                                className="group w-full flex items-center py-1 font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-200"
                                                                aria-controls={`db-nav-menu-${i}`}
                                                                aria-expanded={isSelectedSchema ? 'true' : 'false'}>
                                                                <span
                                                                    className={cx(
                                                                        'flex-1 flex justify-between items-center ml-7',
                                                                        {
                                                                            italic: schema.new,
                                                                        }
                                                                    )}>
                                                                    <span className="w-50 truncate">
                                                                        {isSelectedSchema ? (
                                                                            <TreeDownArrowIcon />
                                                                        ) : (
                                                                            <TreeRightArrowIcon />
                                                                        )}
                                                                        <SchemaIcon />
                                                                        <span
                                                                            className="ml-1"
                                                                            title={schema.schemaName}>
                                                                            {schema.schemaName}
                                                                        </span>
                                                                    </span>
                                                                    {menuItemsSchema[schema.name] ? (
                                                                        <OverflowMenu
                                                                            data-testid={`tree-schema-menu-enc-${schema.name}`}
                                                                            actuator={{
                                                                                'data-testid':
                                                                                    'tree-schema-dropdown-btn-enc-' +
                                                                                    schema.name,
                                                                                text: <TreeDropdownDownIcon />,
                                                                                className:
                                                                                    'mr-2 border border-gray-300 opacity-0 hover:opacity-100 group-hover:opacity-100 hover:bg-gray-300 focus:outline-none focus:opacity-100 focus:bg-white focus:shadow-lg',
                                                                                onClick: (e: React.MouseEvent) =>
                                                                                    e.stopPropagation(),
                                                                            }}
                                                                            menuItems={menuItemsSchema[schema.name]}
                                                                            menuClassName="z-10 mr-2"
                                                                        />
                                                                    ) : null}
                                                                </span>
                                                            </div>
                                                        </KeyClick>

                                                        {isSelectedSchema ? (
                                                            <div className="space-y-1" id={`db-nav-menu-${i}`}>
                                                                {lazyStatusSchema === 'loading' ? (
                                                                    <SkeletonText
                                                                        getSkeletonProps={() => {
                                                                            const {
                                                                                className,
                                                                                ...rest
                                                                            } = getDefaultSkeletonTextProps();
                                                                            return {
                                                                                ...rest,
                                                                                width: 'w-40',
                                                                                className: `${className} ml-14`,
                                                                            };
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        <EncryptionTreeViewItem
                                                                            id={id}
                                                                            db={db}
                                                                            itemList={tables}
                                                                            itemTitle={'tables'}
                                                                            i={i}
                                                                            cryptoAction={cryptoAction}
                                                                            menuItemsTable={menuItemsTable}
                                                                            dbSchema={schema}
                                                                        />
                                                                        <EncryptionTreeViewItem
                                                                            id={id}
                                                                            db={db}
                                                                            itemList={views}
                                                                            itemTitle={'views'}
                                                                            i={i}
                                                                            isView
                                                                            cryptoAction={cryptoAction}
                                                                            menuItemsTable={menuItemsTable}
                                                                            dbSchema={schema}
                                                                        />
                                                                    </>
                                                                )}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                );
                                            })
                                        )}
                                    </div>
                                ) : null}
                            </div>
                        );
                    })}
                </nav>
            )}
        </Observer>
    ) : (
        <nav
            className="flex-1 bg-white overflow-y-auto text-xs border-solid border-r-2 border-gray-300"
            aria-label="Sidebar">
            {dbs.map((db, i) => {
                const isSelected = EncryptionStore.selectedEncryptionDatabase === db.name;
                return (
                    <div key={`${db.name}_${i}`}>
                        <KeyClick
                            key={`keyclick_${db.name}_${i}`}
                            data-testid={`database_tree_entry_${db.name}`}
                            handler={() => {
                                if (isSelected) {
                                    EncryptionStore.setSelectedDatabase(cryptoAction, '');
                                } else {
                                    EncryptionStore.setSelectedDatabase(cryptoAction, db.name);
                                    EncryptionStore.setSelectedTable(cryptoAction, '');
                                    lazyRunDB(EncryptionStore.listTables(id as string, db.name));
                                    lazyRunDB(EncryptionStore.listViews(id as string, db.name));
                                    handleFilterChange('table');
                                }
                            }}>
                            <div
                                className="group hover:bg-gray-200 hover:text-gray-900 bg-white text-gray-700 w-full flex items-center font-medium py-1"
                                aria-controls={`db-nav-menu-${i}`}
                                aria-expanded={isSelected ? 'true' : 'false'}>
                                <span
                                    className={cx('flex-1 flex justify-between items-center ml-3', {
                                        italic: db.new,
                                    })}>
                                    <span className="w-64 truncate">
                                        {isSelected ? <TreeDownArrowIcon /> : <TreeRightArrowIcon />}
                                        <TreeDatabaseIcon />
                                        <span className="ml-1" title={db.name}>
                                            {db.name}
                                        </span>
                                    </span>
                                    {menuItemsDatabase[db.name] ? (
                                        <OverflowMenu
                                            data-testid={`tree-database-menu-enc-${db.name}`}
                                            actuator={{
                                                'data-testid': 'tree-database-dropdown-btn-enc-' + db.name,
                                                text: <TreeDropdownDownIcon />,
                                                className:
                                                    'mr-2 border border-gray-300 opacity-0 hover:opacity-100 group-hover:opacity-100 hover:bg-gray-300 focus:outline-none focus:opacity-100 focus:bg-white focus:shadow-lg',
                                                onClick: (e: React.MouseEvent) => e.stopPropagation(),
                                            }}
                                            menuItems={menuItemsDatabase[db.name]}
                                            menuClassName="z-10 mr-2"
                                        />
                                    ) : null}
                                </span>
                            </div>
                        </KeyClick>
                        {isSelected ? (
                            lazyStatusDB === 'loading' ? (
                                <SkeletonText
                                    getSkeletonProps={() => {
                                        const { className, ...rest } = getDefaultSkeletonTextProps();
                                        return {
                                            ...rest,
                                            width: 'w-40',
                                            className: `${className} ml-12`,
                                        };
                                    }}
                                />
                            ) : (
                                <div className="space-y-1" id={`db-nav-menu-${i}`}>
                                    <EncryptionTreeViewItem
                                        id={id}
                                        db={db}
                                        itemList={tables}
                                        itemTitle={'tables'}
                                        i={i}
                                        cryptoAction={cryptoAction}
                                        menuItemsTable={menuItemsTable}
                                    />

                                    <EncryptionTreeViewItem
                                        id={id}
                                        db={db}
                                        itemList={views}
                                        itemTitle={'views'}
                                        isView
                                        i={i}
                                        cryptoAction={cryptoAction}
                                        menuItemsTable={menuItemsTable}
                                    />
                                </div>
                            )
                        ) : null}
                    </div>
                );
            })}
        </nav>
    );
};

export default EncryptionTreeView;
