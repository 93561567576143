// IMPORTANT: Keep this file updated with https://baffle.atlassian.net/wiki/spaces/BUX/pages/1741193224/Baffle+Manager+Feature+Flag+Listing

import storage from '@baffle/utilities/src/storage';
import { makeAutoObservable } from 'mobx';
import qs from 'qs';

interface iFlagEnum {
    [key: string]: string;
}
//the user facing flag key
export const FlagEnum: iFlagEnum = {
    ff_batchsize: 'BATCH_SIZE',
    ff_forcedelete: 'FORCE_DELETE',
    ff_pro_keylength: 'KEY_LENGTH',
    ff_pro_future_bps_entry: 'FUTURE_BPS_ENTRY',
    ff_wlc_toggle: 'WLC_TOGGLE',
    ff_servertoserver_migration: 'STS_MIGRATION',
    ff_advanced_encryption_mode: 'ADVANCED_ENCRYPTION_MODE',
    ff_advanced_configuration: 'ADVANCED_CONFIGURATION',
    ff_global_policies: 'GLOBAL_POLICIES',
    ff_database_V2: 'DATABASE_V2',
    ff_keystore_v2: 'KEYSTORE_V2',
    ff_ca_certificates: 'CA_CERTIFICATES',
    ff_api_services: 'API_SERVICES',
    ff_database_proxies: 'DATABASE_PROXIES',
};

//the internal facing flag key
export type Flag =
    | 'BATCH_SIZE'
    | 'FORCE_DELETE'
    | 'FUTURE_BPS_ENTRY'
    | 'KEY_LENGTH'
    | 'WLC_TOGGLE'
    | 'ADVANCED_ENCRYPTION_MODE'
    | 'STS_MIGRATION'
    | 'ADVANCED_CONFIGURATION'
    | 'GLOBAL_POLICIES'
    | 'DATABASE_V2'
    | 'KEYSTORE_V2'
    | 'CA_CERTIFICATES'
    | 'API_SERVICES'
    | 'DATABASE_PROXIES';

export default class FeatureFlagStore {
    batchSize = false;
    forceDelete = false;
    keyLength = false;
    futureBpsEntry = false;
    wlcToggle = false;
    stsMigration = false;
    advancedEncryptionMode = false;
    advancedConfiguration = false;
    jsonPolicies = false;
    addNewJsonField = false;
    globalPolicies = false;
    databaseV2 = false;
    keystoreV2 = false;
    caCertificates = false;
    apiServices = false;
    databaseProxies = false;
    setFeatureFlag = (flag: Flag, val: boolean) => {
        switch (flag) {
            case 'BATCH_SIZE':
                this.batchSize = val;
                break;
            case 'FORCE_DELETE':
                this.forceDelete = val;
                break;
            case 'KEY_LENGTH':
                this.keyLength = val;
                break;
            case 'FUTURE_BPS_ENTRY':
                this.futureBpsEntry = val;
                break;
            case 'WLC_TOGGLE':
                this.wlcToggle = val;
                break;
            case 'STS_MIGRATION':
                this.stsMigration = val;
                break;
            case 'ADVANCED_ENCRYPTION_MODE':
                this.advancedEncryptionMode = val;
                break;
            case 'ADVANCED_CONFIGURATION':
                this.advancedConfiguration = val;
                break;
            case 'GLOBAL_POLICIES':
                this.globalPolicies = val;
                break;
            case 'DATABASE_V2':
                this.databaseV2 = val;
                break;
            case 'KEYSTORE_V2':
                this.keystoreV2 = val;
                break;
            case 'CA_CERTIFICATES':
                this.caCertificates = val;
                break;
            case 'API_SERVICES':
                this.apiServices = val;
                break;
            case 'DATABASE_PROXIES':
                this.databaseProxies = val;
                break;
            default:
                break;
        }
    };
    constructor() {
        makeAutoObservable(this);
        this.syncFromStorage();
        //query should override session storage for the time being
        this.syncFromQueryString();
    }
    syncFromStorage() {
        storage.listKeys().forEach((key: string) => {
            const flag = FlagEnum[key] as Flag;
            try {
                if (flag) {
                    const flagVal = JSON.parse(storage.get(key) ?? '');
                    if (flagVal) {
                        this.setFeatureFlag(flag, Boolean(flagVal));
                    }
                }
            } catch (error) {
                console.error('could not parse local storage value: ' + key);
                console.error(error);
            }
        });
    }
    syncFromQueryString() {
        const query = qs.parse(document.location.search, { ignoreQueryPrefix: true });
        Object.keys(query).forEach((key: string) => {
            const flag = FlagEnum[key] as Flag;
            try {
                const flagVal = JSON.parse((query[key] as string) ?? '');
                if (flag) {
                    this.setFeatureFlag(flag, Boolean(flagVal));
                    //sync to local storage
                    storage.set(key, flagVal);
                }
            } catch (error) {
                console.error('could not parse query string value, ' + key + '=' + query[key]);
                console.error(error);
            }
        });
    }
}
