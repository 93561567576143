import keystoreClient from '@baffle/api-client/src/keystoreClient';
import { Keystore, KeystoreListItem } from '@baffle/graphql/src/models';
import { makeObservable, action, observable } from 'mobx';
import { RequestOpts } from '../types/StoreTypes';

class KeystoreStore {
    loading = false;
    keystoreListItems = observable<KeystoreListItem>([]);
    keystores = observable<Keystore>([]);
    setKeystoreListItems = (ksItems: KeystoreListItem[]) => {
        this.keystoreListItems.replace(ksItems);
    };
    clearKeystores = () => {
        this.keystores.clear();
    };
    setKeyStore = (ks: Keystore) => {
        const keystores = this.keystores.slice();
        for (let i = 0; i < keystores.length; i++) {
            const keystore = keystores[i];
            if (keystore.id === ks.id) {
                keystores[i] = ks;
                this.keystores.replace(keystores);
                return;
            }
        }
        keystores.push(ks);
        this.keystores.replace(keystores);
    };
    setLoading = (loading: boolean) => {
        this.loading = loading;
    };

    constructor() {
        makeObservable(this, {
            loading: observable,
            setLoading: action,
            setKeyStore: action,
            clearKeystores: action,
        });
    }
    async list(opts: RequestOpts = {}, rest: { applicationId?: string } = {}) {
        if (opts.networkPolicy === 'cache-first') {
            return;
        }
        try {
            const ksItems = await keystoreClient.list();
            this.setKeystoreListItems(ksItems);
        } catch (error) {
            throw error;
        }
    }

    async read(id: string, opts: RequestOpts = {}, rest: { applicationId?: string } = {}) {
        if (opts.networkPolicy === 'cache-first') {
            return;
        }
        const doFinally = () => {
            this.setLoading(false);
        };
        this.setLoading(true);
        try {
            const ks = await keystoreClient.read(id);
            if (rest?.applicationId) {
                ks.applicationId = rest.applicationId;
            }
            this.setKeyStore(ks);
        } catch (error) {
            throw error;
        } finally {
            doFinally();
        }
    }
    get(id: string) {
        return this.keystores.find(ks => ks.id === id);
    }
    getByApplicationId(appId: string) {
        return this.keystores.find(ks => ks.applicationId === appId);
    }
}

export default KeystoreStore;
