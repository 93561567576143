import { TextInput } from 'carbon-components-react';
import { InputActions, InputError, InputState } from '@baffle/utilities/src/inputHooks';
import React from 'react';
import { t } from '@baffle/translate';
import JwtClaims from './JwtClaims';
import { Button } from 'carbon-components-react';
import { JwtClaimInfo } from '@baffle/graphql/src/models';

interface RbacConfigSqlCommentJwtProps {
    inputState: InputState;
    inputErrors: InputError;
    inputActions: InputActions;
}

const RbacConfigSqlCommentJwt = ({ inputState, inputActions, inputErrors }: RbacConfigSqlCommentJwtProps) => {
    const jwtClaims = (inputState.jwtClaims as Array<JwtClaimInfo>) || [{ key: '', value: '', jwtClaimData: '' }];

    const addNewField = () => {
        let field: JwtClaimInfo = { key: '', value: '', jwtClaimData: '' };
        jwtClaims.push(field);
        inputActions.setField('jwtClaims', jwtClaims);
    };

    return (
        <div>
            <div className="flex flex-wrap mb-6 items-end">
                <div className="flex-1 text-sm sql-comment-prefix-input-container">
                    <TextInput
                        id="sql-comment-prefix-input"
                        name="sql-comment-prefix-input"
                        data-testid="sql-comment-prefix-input"
                        labelText={t('databaseProxies.databaseProxyConfigurations.sqlCommentPrefix')}
                        placeholder={t('databaseProxies.databaseProxyConfigurations.sqlCommentPrefixPlaceholder')}
                        invalid={Boolean(inputErrors.sqlCommentPrefix)}
                        invalidText={inputErrors.sqlCommentPrefix}
                        value={(inputState.sqlCommentPrefix as string) || ''}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            inputActions.validateField({
                                field: 'sqlCommentPrefix',
                                skipNull: true,
                            });
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            let val = e.target.value || '';
                            inputActions.setField('sqlCommentPrefix', val);
                        }}
                    />
                </div>
            </div>
            <div className="flex flex-wrap mb-6 items-end">
                <div className="flex-1 text-sm jwt-secret-key-input-container">
                    <TextInput
                        id="jwt-secret-key-input"
                        name="jwt-secret-key-input"
                        data-testid="jwt-secret-key-input"
                        className="input-helper-text"
                        helperText={t('databaseProxies.databaseProxyConfigurations.jwtSecretKeyHelperMessage')}
                        labelText={t('databaseProxies.databaseProxyConfigurations.jwtSecretKey')}
                        placeholder={t('databaseProxies.databaseProxyConfigurations.jwtSecretKeyPlaceholder')}
                        invalid={Boolean(inputErrors.jwtSecretKey)}
                        invalidText={inputErrors.jwtSecretKey}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            inputActions.validateField({
                                field: 'jwtSecretKey',
                                skipNull: true,
                            });
                        }}
                        value={(inputState.jwtSecretKey as string) || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            let val = e.target.value || '';
                            inputActions.setField('jwtSecretKey', val);
                        }}
                    />
                </div>
            </div>
            <div className="flex flex-wrap mb-6 items-end">
                <div className="flex-1 text-sm sql-comment-prefix-input-container">
                    <TextInput
                        id="jwks-provider-url-input"
                        name="jwks-provider-url-input"
                        data-testid="jwks-provider-url-input"
                        labelText={t('databaseProxies.databaseProxyConfigurations.jwksProviderUrl')}
                        placeholder={t('databaseProxies.databaseProxyConfigurations.jwksProviderUrlPlaceholder')}
                        invalid={Boolean(inputErrors.jwksProviderUrl)}
                        invalidText={inputErrors.jwksProviderUrl}
                        value={(inputState.jwksProviderUrl as string) || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            let val = e.target.value || '';
                            inputActions.setField('jwksProviderUrl', val);
                        }}
                    />
                </div>
            </div>
            <div className="flex flex-col">
                <p className="text-sm font-bold">{t('databaseProxies.databaseProxyConfigurations.jwtClaimsHeading')}</p>
                <div
                    className={`${jwtClaims.length > 0 && 'border-t border-b'} border-solid border-gray-300 pt-2 my-2`}>
                    {jwtClaims?.map((jwtClaim: JwtClaimInfo, index) => (
                        <JwtClaims
                            inputActions={inputActions}
                            key={index}
                            jwtClaim={jwtClaim}
                            jwtClaims={jwtClaims}
                            index={index}
                        />
                    ))}
                </div>
                <Button id="add-button" className="w-44" tabIndex={0} onClick={addNewField} size="md" kind="tertiary">
                    {t('databaseProxies.databaseProxyConfigurations.addJwtClaim')}
                </Button>
            </div>
        </div>
    );
};

export default RbacConfigSqlCommentJwt;
