import cx from 'classnames';
import React from 'react';
import { AriaLabelPropType } from '../componentTypes';
import './Header.scss';

interface HeaderPropTypes extends AriaLabelPropType {
    className?: string;
    children: React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
}

const Header = ({ className: customClassName, children, ...rest }: HeaderPropTypes) => {
    const className = cx(`relative z-10 flex-shrink-0 flex py-2`, customClassName);

    return (
        <header {...rest} className={className} role="banner">
            {children}
        </header>
    );
};

export default Header;
