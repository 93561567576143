import React, { useEffect, useState } from 'react';
import { Observer } from 'mobx-react-lite';
import Content from '@baffle/components/src/content/Content';
import { Overview } from '@baffle/components/src/overview/Overview';
import PageHeader from '@baffle/components/src/pageheader/pageheader';
import { TenantStore } from '@baffle/manager/src/stores';
import useAwait from '@baffle/api-client/src/useAwait';
import { useHistory, useParams } from 'react-router-dom';
import { t } from '@baffle/translate';
import { useManageModals } from '@baffle/components/src/modal/Modal';
import SpinnerOverlay from '@baffle/components/src/loader/SpinnerOverlay';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';
import { AssociatedRolesInfo, TenantInfo } from '@baffle/graphql/src/models';
import DataGrid from '@baffle/components/src/grid/data-grid';
import RenameTenant from './RenameTenant';
import { Modal } from 'carbon-components-react';

const TenantDetails = () => {
    const { id } = useParams() as { id: string };
    const history = useHistory();
    const [refresh, setRefresh] = useState(true);
    if (!id) {
        history.push('/tenants');
        return null;
    }

    const { status, run, error } = useAwait();

    useEffect(() => {
        if (error) {
            NotificationStore.push({
                'data-testid': 'tenant-update-failure',
                kind: 'error',
                title: t('tenants.addTenantError'),
                description: error.message ?? t('main.unexpectedError'),
            });
        }
    }, [error]);

    useEffect(() => {
        if (refresh) {
            run(
                TenantStore.getTenants().then(() => {
                    TenantStore.getTenant(id);
                    // TBD: This is just a dummy API to check the UI layout with data. To be changed once BE API definitions are available.
                    TenantStore.getAssociatedRoles();
                })
            );
            setRefresh(false);
        }
    }, [refresh]);

    const headers = [
        {
            key: 'header1',
            header: t('main.name'),
        },
        {
            key: 'header2',
            header: t('tenants.permissions'),
        },
        {
            key: 'header3',
            header: t('tenants.dataPolicies'),
        },
    ];

    return (
        <Observer>
            {() => {
                const tenantInfo = TenantStore.tenant;
                const roles: AssociatedRolesInfo[] = TenantStore.rolesList;
                const [update, setUpdate] = useState(true);

                useEffect(() => {
                    if (update) {
                        run(
                            // TBD: This is just a dummy API to check the UI layout with data. To be changed once BE API definitions are available.
                            TenantStore.getAssociatedRoles()
                        );
                    }
                    setUpdate(false);
                }, [update]);
                // TBD: Get the list of tenants and roles / data policies associated from the backend, once the BE API is available
                const rows =
                    status === 'loading' || status === 'idle'
                        ? null //add  skeleton row on initial load
                        : roles.map((role: AssociatedRolesInfo, i: number) => {
                              return {
                                  id: i.toString(),
                                  header1: role.name,
                                  header2: role.effects.join(', '),
                                  header3: role.dataPolicies.join(', '),
                              };
                          });

                interface TenantModals {
                    deleteTenant: boolean;
                    renameTenant: boolean;
                }

                const initialModalState = {
                    deleteTenant: false,
                    renameTenant: false,
                };

                const [modals, setModals] = useManageModals<TenantModals>(initialModalState);

                const actions = [
                    {
                        id: '1',
                        label: t('main.rename'),
                    },
                    {
                        id: '2',
                        label: t('main.delete'),
                    },
                ];

                const breadcrumbData = [
                    {
                        label: t('main.tenants'),
                        href: '/tenants',
                    },
                ];

                const onSelectAction = (item: any) => {
                    switch (item.selectedItem.label) {
                        case t('main.rename'):
                            setModals({ renameTenant: true });
                            break;
                        case t('main.delete'):
                            setModals({ deleteTenant: true });
                            break;
                        default:
                            break;
                    }
                };
                const onDelete = async () => {
                    setModals({ deleteTenant: false });
                    run(
                        TenantStore.deleteTenant(TenantStore.tenant?.id as string).then(() => {
                            if (status === 'success') {
                                NotificationStore.push({
                                    'data-testid': 'tenant-del-success',
                                    kind: 'success',
                                    title: t('tenants.deleteTenantSuccessMsg', {
                                        name: TenantStore.tenant?.name,
                                    }),
                                });
                            }
                            TenantStore.setTenant(null);
                        })
                    );
                    history.push('/tenants');
                };

                const onRename = async (tenantInfo: TenantInfo) => {
                    setModals({ renameTenant: false });
                    run(
                        TenantStore.updateTenant(tenantInfo).then(() => {
                            if (status === 'success') {
                                NotificationStore.push({
                                    'data-testid': 'tenant-update-success',
                                    kind: 'success',
                                    title: t('tenants.updateTenantSuccessMsg', {
                                        name: tenantInfo.name,
                                    }),
                                });
                            }
                            setRefresh(true);
                        })
                    );
                };

                const onSyncButtonClick = () => {
                    setUpdate(true);
                };

                return (
                    <>
                        <Content>
                            {modals.renameTenant && (
                                <RenameTenant
                                    open={modals.renameTenant}
                                    onClose={() => setModals({ renameTenant: false })}
                                    onSuccess={(tenant: TenantInfo) => {
                                        onRename(tenant);
                                    }}
                                />
                            )}
                            <Modal
                                open={modals.deleteTenant}
                                id="tenant-delete-modal"
                                modalHeading={t('tenants.deleteConfirmationTitle', {
                                    name: TenantStore.tenant?.name,
                                })}
                                danger={true}
                                onRequestClose={() => setModals({ deleteTenant: false })}
                                primaryButtonText={t('main.delete')}
                                secondaryButtonText={t('main.cancel')}
                                size="sm"
                                onRequestSubmit={onDelete}>
                                <p>{t('tenants.deleteConfirmationMsg', { name: TenantStore.tenant?.name })}</p>
                            </Modal>

                            {(status === 'loading' || status === 'idle') && <SpinnerOverlay description="" />}
                            {tenantInfo && (
                                <>
                                    <PageHeader
                                        title={tenantInfo.name}
                                        actions={actions}
                                        breadcrumbData={breadcrumbData}
                                        onActionSelect={onSelectAction}
                                    />
                                    <div className="px-8 mt-4 text-lg">
                                        <div
                                            className={
                                                'pb-2 w-40 border-solid border-b-2 border-blue-60 cursor-pointer'
                                            }>
                                            <div className="ml-4">{t('main.overview')}</div>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <Overview
                                            title={t('tenants.tenantDetails')}
                                            info={TenantStore.getTenantOverview(tenantInfo)}
                                            topic="tenants"
                                        />
                                    </div>
                                    <div>
                                        <div className="ml-5 mt-10 mb-6 text-lg">{t('tenants.associatedRoles')}</div>
                                        <div className="w-full ml-5 pr-10">
                                            <DataGrid
                                                showSearch={true}
                                                addMargin={false}
                                                idPrefix="tenantAssociation"
                                                rows={rows}
                                                onSync={onSyncButtonClick}
                                                headers={headers}></DataGrid>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Content>
                    </>
                );
            }}
        </Observer>
    );
};

export default TenantDetails;
