const saveFile = (blob: Blob, fileName: string) => {
    // use msSaveBlob for Microsoft Edge
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        window.navigator.msSaveBlob(blob, fileName);
        return;
    }

    //Trick to download the file with a custom name
    const downloadUrl = URL.createObjectURL(blob);
    const downloaderBtn = document.createElement('a');
    downloaderBtn.href = downloadUrl;
    downloaderBtn.download = fileName;
    document.body.appendChild(downloaderBtn);
    // setTimeout becasue without it the file will not download ¯\_(ツ)_/¯
    setTimeout(() => {
        downloaderBtn.click();
        // Cleanup;
        downloaderBtn.remove();
        URL.revokeObjectURL(downloadUrl);
    }, 100);
};

export default saveFile;
