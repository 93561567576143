import React, { useState, useEffect } from 'react';
import { TextArea, Dropdown } from 'carbon-components-react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@baffle/components/src/modal/Modal';
import Button from '@baffle/components/src/buttons/Button';
import TextInput from '@baffle/components/src/forms/TextInput';
import { t } from '@baffle/translate';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import Alert from '@baffle/components/src/alerts/Alert';
import { Database, DatabasePayloadPUT, DatabaseTypeEnum } from '@baffle/graphql/src/models';
import Divider from '@baffle/components/src/content/Divider';
import databaseClient from '@baffle/api-client/src/databaseClient';
import { DatabaseEnums } from '@baffle/utilities/src/enums';
import Joi from '@hapi/joi';
import SpinnerOverlay from '@baffle/components/src/loader/SpinnerOverlay';

interface UpdateDatabaseModalProps {
    databases: Database[];
    database: Database;
    open: boolean;
    onClose?: () => void;
    onSuccess?: (inputState: Database) => void;
}

const UpdateDatabaseModal = (props: UpdateDatabaseModalProps) => {
    const database = props.database;
    const databases = props.databases.filter(d => d.id !== database.id);
    const [open, setOpen] = useState(props.open);
    const [teardownModal, setTeardownModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [postError, setPostError] = useState(null);

    const { inputState, inputErrors, inputActions } = useInputState({
        keys: ['dbName', 'bsConfigClientDBName', 'description', 'type', 'dbUser', 'dbPass'],
        ns: 'database',
        defaultValues: { bsConfigClientDBName: '' },
        validationSchema: {
            dbName: Joi.string()
                .min(1)
                .max(30)
                .custom(val => {
                    if (
                        databases.some((a: Database) => {
                            return a.dbName.trim().toLowerCase() === val.toLowerCase();
                        })
                    ) {
                        throw new Error(t('database.nameExists'));
                    }
                    return val;
                })
                .pattern(/^[a-zA-Z0-9_-\s]+$/)
                .required()
                .messages({
                    'string.max': t('main.invalidNameLength'),
                    'string.empty': t('database.dbNameInputInvalid'),
                    'string.pattern.base': t('database.noSpecialCharacters'),
                    'any.custom': t('database.nameExists'),
                }),
            type: Joi.string().required(),
            hostName: Joi.string().required(),
            port: Joi.number()
                .port()
                .required(),
            bsConfigClientDBName: Joi.string().allow('', null),
            dbUser: Joi.string()
                .custom(val => {
                    if (
                        inputState.type === DatabaseEnums.AZURE_MSSQL &&
                        Boolean(inputState.dbUser) &&
                        inputState.dbUser !== ''
                    ) {
                        //@ts-ignore
                        if (inputState.dbUser.includes('@')) {
                            //@ts-ignore
                            const split = inputState.dbUser.split('@');
                            if (split[1] !== inputState.hostName) {
                                throw new Error(t('database.hostNameDoesNotMatch', { hostName: inputState.hostName }));
                            }
                        }
                    }
                    return val;
                })
                .min(1)
                .required()
                .messages({
                    'any.custom': 'database.hostNameDoesNotMatch',
                    'string.empty': 'database.dbUserInputInvalid',
                }),
        },
    });

    //automatically adds @hostName to dbUser if it is azure sql
    useEffect(() => {
        if (inputState.type === DatabaseEnums.AZURE_MSSQL && Boolean(inputState.dbUser) && inputState.dbUser !== '') {
            //@ts-ignore
            if (!inputState.dbUser.includes('@')) {
                inputActions.setField('dbUser', `${inputState.dbUser}@${inputState.hostName}`);
            } else {
                inputActions.validateField({ field: 'dbUser', skipNull: true });
            }
        }
    }, [inputState]);

    //Sync state from props
    useEffect(() => {
        setOpen(props.open);
        if (props.open) {
            inputActions.setFields({
                dbName: database.dbName,
                description: database.description,
                type: database.type,
                hostName: database.hostName,
                port: database.port,
                bsConfigClientDBName: database.bsConfigClientDBName,
                dbUser: database.dbUser,
                dbPass: database.dbPass ?? '',
            });
        }
    }, [props.open]);

    const closeModal = (clearState?: boolean) => {
        if (loading) {
            return;
        }
        if (clearState) {
            inputActions.reset();
            setTeardownModal(true);
            setPostError(null);
            setTimeout(() => setTeardownModal(false), 0);
        }
        setOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    };
    const doUpdateDatabase = async () => {
        setLoading(true);

        try {
            const payload: DatabasePayloadPUT = {
                id: database.id as string,
                dbName: inputState.dbName as string,
                dbPass: inputState.dbPass as string,
                dbUser: inputState.dbUser as string,
                description: inputState.description as string,
                hostName: database.hostName,
                ssl: database.ssl,
                port: inputState.port as number,
                type: inputState.type as DatabaseTypeEnum,
            };
            if (inputState.type === DatabaseTypeEnum.POSTGRES || inputState.type === DatabaseTypeEnum.AWS_REDSHIFT) {
                payload.bsConfigClientDBName = inputState.bsConfigClientDBName as string;
            }
            await databaseClient.update(payload);
            setLoading(false);
            props.onSuccess && props.onSuccess((inputState as unknown) as Database);
            closeModal(true);
        } catch (error) {
            setPostError(JSON.parse(error.message).errors.join(', '));
            setLoading(false);
            return;
        }
    };

    const isValid = inputActions.validateAll(true);
    //hack to remove the input components state
    if (teardownModal) {
        return null;
    }
    return (
        <Modal open={open} onClose={() => closeModal(true)} aria-label="modal">
            {loading && <SpinnerOverlay description={t('database.updatingDatabase')} />}
            <ModalHeader onClose={() => closeModal(true)}>
                <div className="flex items-start">
                    <div className="ml-2">
                        <h3 className="text-xl font-medium text-gray-100" id="modal-headline">
                            {t('database.updateDatabase')}
                        </h3>
                    </div>
                </div>
            </ModalHeader>
            <ModalBody>
                <>
                    {postError ? (
                        <div className="flex flex-wrap">
                            <div className="flex-1 px-4">
                                <Alert type="danger" title={t('database.error')} description={postError || ''} />
                            </div>
                        </div>
                    ) : null}
                    <div className="flex flex-wrap mb-6 items-start">
                        <div className="flex-1 px-4 name-input-container">
                            <TextInput
                                name="db-update-name-input"
                                labelText={t('database.dbNameInputLabel')}
                                invalid={Boolean(inputErrors.dbName)}
                                value={inputState.dbName as string}
                                invalidText={inputErrors.dbName}
                                placeholder={t('database.dbNamePlaceholder')}
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    inputActions.validateField({
                                        field: 'dbName',
                                        skipNull: true,
                                    });
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const val = e.target.value || '';
                                    inputActions.setField('dbName', val);
                                }}
                            />
                            <span className="input-char-counter">
                                {(inputState.dbName as string)?.length ?? 0} / 30
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-wrap mb-6">
                        <div className="flex-1 px-4 relative">
                            <TextArea
                                id="db-update-description-input"
                                labelText={t('database.descriptionInputLabel')}
                                invalidText={t('database.descriptionInputInvalid')}
                                placeholder={t('database.descriptionPlaceholder')}
                                value={inputState.description}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const val = e.target.value || '';
                                    inputActions.setField('description', val);
                                    const maxLength = 100;
                                    if (val.length > maxLength) {
                                        e.target.value = val.slice(0, maxLength - 1);
                                    }
                                }}
                            />
                            <span className="input-char-counter">
                                {(inputState.description as string)?.length ?? 0} / 100
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-wrap mb0">
                        <div className="flex-1 px-4">
                            <Divider />
                        </div>
                    </div>
                    <div className="flex flex-wrap mb-6 items-start">
                        <div className="flex-1 px-4">
                            <Dropdown
                                id="db-update-type-input"
                                titleText={t('database.typeInputLabel')}
                                label={t('main.chooseOption')}
                                initialSelectedItem={{
                                    id: database.type,
                                    value: database.type,
                                    label: database.type,
                                }}
                                disabled
                                onChange={() => {}}
                                items={[
                                    {
                                        id: database.type,
                                        value: database.type,
                                        label: database.type,
                                    },
                                ]}
                            />
                        </div>
                        <div className="flex-1 px-4 flex items-center">
                            <TextInput
                                name="db-update-hostname-input"
                                labelText={t('database.hostNameInputLabel')}
                                disabled
                                value={database.hostName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {}}
                            />
                            <div className="ml-4 w-20">
                                <TextInput
                                    name="db-update-port-input"
                                    labelText={t('setup.portInputLabel')}
                                    value={database.port}
                                    arial-label={t('database.portInputLabel')}
                                    disabled
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {}}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap mb-6 items-start">
                        <div className="flex-1 px-4">
                            <TextInput
                                name="db-update-username-input"
                                labelText={t('database.dbUserInputLabel')}
                                invalidText={t(inputErrors.dbUser, { hostName: inputState.hostName })}
                                placeholder={t('database.dbUserPlaceholder')}
                                invalid={Boolean(inputErrors.dbUser)}
                                value={inputState.dbUser as string}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    inputActions.setField('dbUser', e.target.value.trim() || '')
                                }
                                onBlur={() => {
                                    inputActions.validateField({ field: 'dbUser', skipNull: true });
                                }}
                            />
                            {database.type === DatabaseEnums.POSTGRES ||
                            database.type === DatabaseEnums.AWS_REDSHIFT ? (
                                <div className="mt-6">
                                    <TextInput
                                        name="db-update-bsConfigClientDBName-input"
                                        labelText={
                                            database.type === DatabaseEnums.POSTGRES
                                                ? t('database.bsConfigClientDBNameInputLabel')
                                                : t('database.bsConfigClientRedshiftDBNameInputLabel')
                                        }
                                        invalidText={t(inputErrors.bsConfigClientDBName)}
                                        invalid={Boolean(inputErrors.bsConfigClientDBName)}
                                        value={inputState.bsConfigClientDBName as string}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            inputActions.setField('bsConfigClientDBName', e.target.value.trim() || '');
                                        }}
                                        onBlur={() => {
                                            inputActions.validateField({
                                                field: 'bsConfigClientDBName',
                                                skipNull: true,
                                            });
                                        }}
                                    />
                                </div>
                            ) : null}
                        </div>
                        <div className="flex-1 px-4">
                            <TextInput
                                name="db-update-credential-input"
                                type="password"
                                labelText={t('database.dbPassInputLabel')}
                                invalidText={t('database.dbPassInputInvalid')}
                                placeholder={t('database.dbPassPlaceholder')}
                                invalid={Boolean(inputErrors.dbPass)}
                                value={inputState.dbPass as string}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    inputActions.setField('dbPass', e.target.value.trim() || '')
                                }
                            />
                        </div>
                    </div>
                </>
            </ModalBody>
            <ModalFooter>
                <div className="flex justify-end items-center h-16 w-full">
                    <Button className="h-full w-1/2" theme="secondary" onClick={() => closeModal(true)} size="lg">
                        {t('main.cancel')}
                    </Button>
                    <Button
                        className="h-full w-1/2"
                        theme={isValid && !loading ? 'primary' : 'disabled'}
                        id="do-update-database-btn"
                        onClick={doUpdateDatabase}
                        size="lg">
                        {t('database.updateDatabase')}
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default UpdateDatabaseModal;
