import React, { useState, useEffect } from 'react';
import Header from '@baffle/components/src/nav/Header';
import Button from '@baffle/components/src/buttons/Button';
import SkeletonText from '@baffle/components/src/skeleton/SkeletonText';
import cx from 'classnames';
import { useQuery } from '@apollo/react-hooks';
import { LIST_ALL_KEYSTORES } from '@baffle/graphql';
import { t } from '@baffle/translate';
import { Tag, OverflowMenu, OverflowMenuItem, Search } from 'carbon-components-react';
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Content from '@baffle/components/src/content/Content';
import { Keystore, KeystoreListItem, KeystoreType } from '@baffle/graphql/src/models';
import './Keystores.scss';
import TH from '@baffle/components/src/table/react-data-grid/TH';
import { stringSort, boolSort } from '@baffle/utilities/src/sorters';
import { triggerResize } from '@baffle/utilities/src/resize';
import ReactDataGrid from 'react-data-grid';
import { RowCol } from '@baffle/components/src/componentTypes';
import AddKeyStoreModal from './AddKeystoreModal';
import UpdateKeystoreCertModal from './UpdateKeystoreCertModal';
import KeystoreSidePanel from '../sidepanel/KeystoreSidePanel';
import usePageHeight from '@baffle/utilities/src/usePageHeight';
import Cell from '@baffle/components/src/table/react-data-grid/Cell';
import { useHistory } from 'react-router-dom';
import keystoreClient from '@baffle/api-client/src/keystoreClient';
import { ToastStore } from '../stores/ToastStore';
import CreateIcon from '@material-ui/icons/CreateOutlined';
import useAwait from '@baffle/api-client/src/useAwait';
import { SystemEmailStore } from '@baffle/manager/src/stores';

const KeyStores = () => {
    const history = useHistory();
    const columns = [
        {
            key: 'header1',
            name: t('keystore.header1'),
            sortable: true,
            formatter: Cell,
            sortFn: (a: KeystoreListItem, b: KeystoreListItem) => stringSort(a.name, b.name),
            headerRenderer: <TH idPrefix="keystores" />,
        },
        {
            key: 'header2',
            name: t('keystore.header2'),
            width: 250,
            sortable: true,
            formatter: Cell,
            sortFn: (a: KeystoreListItem, b: KeystoreListItem) => stringSort(a.appNameSpace, b.appNameSpace),
            headerRenderer: <TH idPrefix="keystores" />,
        },
        {
            key: 'alerts',
            name: t('main.alerts'),
            width: 200,
            sortable: true,
            sortFn: (a: KeystoreListItem, b: KeystoreListItem) =>
                boolSort(Boolean(a?.details?.expiresSoon), Boolean(b?.details?.expiresSoon)),
            formatter: Cell,
            headerRenderer: <TH idPrefix="keystores" />,
        },
        {
            key: 'header3',
            name: t('keystore.header3'),
            width: 250,
            sortable: true,
            formatter: Cell,
            sortFn: (a: KeystoreListItem, b: KeystoreListItem) => stringSort(a.type, b.type),
            headerRenderer: <TH data-testid={`keystore-alert`} idPrefix="keystores" />,
        },
        {
            key: 'header4',
            formatter: Cell,
            name: '',
            width: 60,
        },
        //header6 is literally a filler for spacing
        {
            key: 'header5',
            name: ' ',
            formatter: Cell,
            width: 1,
        },
    ];

    const initialModalState = { addKeystore: false, updateKeyStore: false, updateCertificate: false, data: { ks: {} } };
    const [modalsToggled, setModalsToggled] = useState(initialModalState);
    const { run } = useAwait();
    const pageHeight = usePageHeight();
    const pageHeightOffset = 126.99; //126.99 is the header height and search

    const size = Math.floor((pageHeight - pageHeightOffset) / 35);
    const [initialized, setInitialized] = useState(false);
    const [selectedKs, setSelectedKs]: any = useState(null);

    const { loading, data, refetch: refetchKeystores } = useQuery(LIST_ALL_KEYSTORES);

    const [filtered, setFiltered] = useState({ results: [], query: '' });
    const [sort, setSort] = useState({ direction: 'NONE', key: '' });
    const [keystoreTypes, setKeystoreTypes] = useState<KeystoreType[]>([]);

    useEffect(() => {
        run(
            keystoreClient.getKeystoreTypes().then((types: KeystoreType[]) => {
                setKeystoreTypes(types ?? []);
            })
        );
    }, []);

    const skeletonCol = {
        id: <SkeletonText />,
        header1: <SkeletonText />,
        header2: <SkeletonText />,
        alerts: <SkeletonText />,
        header3: <SkeletonText />,
        header4: ' ',
        header5: ' ',
    };

    const emptyCol = {
        id: '',
        header1: '',
        header2: '',
        alerts: '',
        header3: '',
        header4: '',
        header5: '',
    };

    if (!initialized && !loading) {
        setInitialized(true);
    }
    const keystores = filtered.query && filtered.query != '' ? filtered.results : data?.keystores || [];

    //sort data
    if (sort.direction !== 'NONE') {
        const header = columns.find(h => h.key === sort.key);
        if (header && header.sortFn) {
            keystores.sort((a: KeystoreListItem, b: KeystoreListItem) => {
                if (sort.direction === 'ASC') {
                    return header.sortFn(a, b);
                } else if (sort.direction === 'DESC') {
                    return header.sortFn(b, a);
                }
            });
        }
    }
    const rows =
        loading && !initialized
            ? //add  skeleton row on initial load
              [...Array(Math.floor(1)).keys()].map(() => skeletonCol)
            : keystores.map((d: KeystoreListItem) => ({
                  id: d.id,
                  selectedId: selectedKs?.id,
                  header1:
                      SystemEmailStore.systemEnvironment === 'IBM_CLOUD' && d.name === 'baffle_credential_store'
                          ? t('keystore.dsbCredentialStore')
                          : d.name,
                  header2: d.appNameSpace || 'N/A',
                  alerts: d?.details?.expiresSoon ? (
                      <Tag type={'red'} data-testid={`keystore-alert-${d.id}`}>
                          {t('main.alert')}
                      </Tag>
                  ) : null,
                  header3: t(`keystore.${d.type}`),
                  header4: (
                      <OverflowMenu
                          menuOffset={{ left: -64 }}
                          className="datagrid-settings"
                          ariaLabel={t('application.overFlowMenu')}
                          onClick={(e: React.MouseEvent) => e.stopPropagation()}
                          renderIcon={() => <MoreVertIcon className="w-4 h-4 text-gray-70" />}>
                          {d.type === 'HASHI_VAULT' && (
                              <OverflowMenuItem
                                  className="w-48"
                                  itemText={
                                      <>
                                          <CreateIcon className="mr-1 w-4 h-4" /> {t('keystore.editModalHeading')}
                                      </>
                                  }
                                  onClick={(e: React.MouseEvent) => {
                                      e.stopPropagation();
                                      triggerResize(50);
                                      setModalsToggled({
                                          addKeystore: false,
                                          updateKeyStore: true,
                                          updateCertificate: false,
                                          data: { ks: d.details },
                                      });
                                  }}
                              />
                          )}
                          <OverflowMenuItem
                              itemText={
                                  <>
                                      <div
                                          className={cx('config-menu-delete flex items-center', {
                                              disabled: d.name === 'baffle_credential_store',
                                          })}>
                                          <DeleteOutlineIcon className="mr-1 w-4 h-4" /> {t('main.delete')}
                                      </div>
                                  </>
                              }
                              onClick={async (e: React.MouseEvent) => {
                                  e.stopPropagation();
                                  if (d.name === 'baffle_credential_store') {
                                      return;
                                  }
                                  doDeleteKeystore(d.id as string, d.name);
                              }}
                          />
                      </OverflowMenu>
                  ),
                  header5: ' ',
              }));

    // add skeleton rows as a loading indicator
    if (loading && initialized) {
        rows.push(skeletonCol, skeletonCol, skeletonCol);
    }

    //add empty rows to fill the page, if needed
    if (keystores.length < size) {
        const sizeDelta = size - keystores.length;
        rows.push(...[...Array(sizeDelta).keys()].map(() => emptyCol));
    }

    const searchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = (e.target.value || '').toLowerCase();
        const results = (data?.keystores ?? []).filter(
            (ks: KeystoreListItem) =>
                (ks.name.toLowerCase().includes(query) ||
                    ks.type.toLowerCase().includes(query) ||
                    ks.appNameSpace?.toLowerCase().includes(query)) &&
                query !== ''
        );
        setFiltered({ results, query });
    };

    //set the keystoreId id and application ID
    useEffect(() => {
        //@ts-ignore
        if (history?.location?.state?.updateCert && !loading) {
            const keystore: KeystoreListItem = keystores.find(
                //@ts-ignore
                (k: KeystoreListItem) => k.id === history?.location?.state?.keystoreId
            );
            if (keystore) {
                setSelectedKs(keystore);
                triggerResize(50);
                setModalsToggled({
                    addKeystore: false,
                    updateKeyStore: false,
                    updateCertificate: true,
                    data: { ks: keystore },
                });
            }
            //@ts-ignore
            delete history.location.state.keystoreId;
        }
    }, [history.location, loading]);

    const doDeleteKeystore = async (id: string, name: string) => {
        try {
            await keystoreClient.remove(id);
            if (selectedKs && selectedKs.id === id) {
                setSelectedKs(null);
                triggerResize(50);
            }
            refetchKeystores();
            ToastStore.push({
                type: 'success',
                title: t('keystore.deleteSuccess', { name: name }),
                'data-testid': 'keystore-delete-success-toast',
            });
            setFiltered({ results: [], query: '' });
        } catch (error) {
            ToastStore.push({
                type: 'danger',
                title: JSON.parse(error.message).errors.join(', '),
                'data-testid': 'keystore-delete-failure-toast',
            });
        }
    };

    return (
        <>
            <AddKeyStoreModal
                onSuccess={(ks: Keystore, edit = false) => {
                    refetchKeystores();
                    ToastStore.push({
                        type: 'success',
                        title: edit
                            ? t('keystore.editSuccess', { name: ks.name })
                            : t('keystore.addSuccess', { name: ks.name }),
                        'data-testid': 'keystore-add-success-toast',
                    });
                }}
                open={modalsToggled.addKeystore || modalsToggled.updateKeyStore}
                onClose={() =>
                    setModalsToggled({
                        addKeystore: false,
                        updateKeyStore: false,
                        updateCertificate: false,
                        data: { ks: {} },
                    })
                }
                selectedKS={modalsToggled.data.ks as Keystore}
                keystoreTypes={keystoreTypes}
            />
            <UpdateKeystoreCertModal
                //@ts-ignore
                keystore={modalsToggled.data.ks as Keystore}
                onSuccess={(ks: Keystore) => {
                    //TODO: re-implement this if we can support zero downtime to update a cert
                    // if (history?.location?.state?.updateCert && history?.location?.state?.applicationId) {
                    //     history.push(`/application/encryption/${history?.location?.state?.applicationId}`);
                    //     return;
                    // }
                    refetchKeystores();
                    ToastStore.push({
                        type: 'success',
                        title: t('keystore.updateCertSuccess', { name: ks.name }),
                        'data-testid': 'keystore-update-cert-success',
                    });
                }}
                open={modalsToggled.updateCertificate}
                onClose={() =>
                    setModalsToggled({
                        addKeystore: false,
                        updateKeyStore: false,
                        updateCertificate: false,
                        data: { ks: {} },
                    })
                }
            />

            <Content>
                <Header>
                    <div className="flex-1 px-4 flex justify-between">
                        <div className="flex-1 flex items-center">
                            <h1 className="text-heading-01 text-gray-100">
                                {/* ternary here to get i18n to update */}
                                {data?.keystores
                                    ? t('keystore.headerName', {
                                          count: data.keystores.length,
                                      })
                                    : t('keystore.headerName', {
                                          count: '-',
                                      })}
                            </h1>
                        </div>
                    </div>
                    <div className="mx-4 flex text-md items-center h-full">
                        <Button
                            theme="primary"
                            className="h-12 w-40 flex justify-between flex-row-reverse"
                            id="add-keystore-btn"
                            aria-label={t('keystore.ariaAddKeystore')}
                            onClick={() => {
                                setModalsToggled({
                                    addKeystore: true,
                                    updateKeyStore: false,
                                    updateCertificate: false,
                                    data: { ks: {} },
                                });
                            }}>
                            <AddIcon className="w-4 h-4" /> {t('keystore.keystore')}
                        </Button>
                    </div>
                </Header>
                <div className="flex font-normal text-sm leading-primary tracking-primary">
                    <div
                        id="table-container"
                        className={cx('datagrid-container keystore-datagrid flex-grow', {
                            'with-side-panel': Boolean(selectedKs),
                        })}>
                        <div>
                            <div className="flex flex-wrap">
                                <div className="flex-1 px-4">
                                    <Search
                                        size="sm"
                                        value={filtered.query ?? ''}
                                        closeButtonLabelText={t('main.ariaCloseSearch')}
                                        className="data-grid-search"
                                        labelText={t('keystore.ariaSearch')}
                                        placeholder={t('keystore.ariaSearch')}
                                        onChange={searchChange}
                                        id="keystore-data-grid-search"
                                        autoComplete="none"
                                    />
                                </div>
                            </div>
                        </div>
                        <ReactDataGrid
                            rowHeight={48}
                            headerFiltersHeight={48}
                            headerRowHeight={48}
                            onRowClick={(idx: number, row: RowCol) => {
                                if (row) {
                                    const keystore = keystores.find((ks: KeystoreListItem) => ks.id === row.id);
                                    if (keystore) {
                                        setSelectedKs(keystore);
                                        triggerResize(50);
                                    }
                                }
                            }}
                            enableCellSelect={false}
                            // @ts-ignore
                            enableCellAutoFocus={false}
                            onGridSort={(key: string, direction: string) => setSort({ direction, key })}
                            //@ts-ignore
                            columns={columns}
                            rowGetter={i => rows[i]}
                            rowsCount={rows.length}
                            minHeight={pageHeight - pageHeightOffset}
                            //@ts-ignore
                            enableRowSelect={null}
                        />
                    </div>
                </div>
            </Content>
            <KeystoreSidePanel
                ks={selectedKs}
                handleUpdateCert={(ks: Keystore) => {
                    setModalsToggled({
                        addKeystore: false,
                        updateKeyStore: false,
                        updateCertificate: true,
                        data: { ks },
                    });
                }}
                deleteKeystore={(id: string, name: string) => {
                    doDeleteKeystore(id, name);
                }}
                onClose={() => {
                    setSelectedKs(null);
                    triggerResize();
                }}
                onEdit={() => {
                    setModalsToggled({
                        addKeystore: false,
                        updateKeyStore: true,
                        updateCertificate: false,
                        data: { ks: selectedKs },
                    });
                }}
            />
        </>
    );
};

export default KeyStores;
