import { makeObservable, observable, action, computed, autorun } from 'mobx';
import { SessionEnums } from '@baffle/utilities/src/enums';
import sessionClient from '@baffle/api-client/src/sessionClient';
import storage from '@baffle/utilities/src/storage';
import debounce from 'lodash.debounce';
import { Second, Minute, Hour } from '@baffle/utilities/src/enums/TimeEnum';

interface auth {
    accessToken: string;
    refreshToken: string;
    expiry: number;
}

const activityEvents = ['keydown', 'mousedown', 'mousemove', 'resize', 'scroll', 'touchstart'];
class SessionStore {
    constructor() {
        makeObservable(this, {
            accessToken: observable,
            refreshToken: observable,
            tokenExpiry: observable,
            startIdleTimer: observable,
            idleTimeout: observable,
            expired: computed,
            authorized: computed,
            inactive: computed,
            setAuth: action,
            setIdleTimer: action,
        });

        //start or stop the idler any time authorized changes
        autorun(() => {
            //start idler if it isnt on and auth has changed
            if (this.authorized && !this.idlerOn) {
                this.runIdler();
            }
            //stop idler if it isnt on and auth has changed
            if (!this.authorized && this.idlerOn) {
                this.stopIdler();
            }
        });
    }
    idlerOn = false;
    startIdleTimer = 0;
    accessToken: string = storage.get(SessionEnums.NG_STORAGE_AUTH_TOKEN) || '';
    refreshToken: string = storage.get(SessionEnums.NG_STORAGE_REFRESH_TOKEN) || '';
    tokenExpiry: number = parseInt(storage.get(SessionEnums.NG_STORAGE_AUTH_TOKEN_EXPIRY) || '0');
    idleTimeout: number = parseInt(storage.get(SessionEnums.NG_STORAGE_IDLE_TIME_EXPIRY) || '0');

    get expired() {
        return this.tokenExpiry - Date.now() <= 0;
    }
    get authorized() {
        return !this.expired && this.accessToken !== '' && this.refreshToken !== '';
    }
    get inactive() {
        return !Boolean(this.idleTimeout);
    }

    setIdleTimer(timer: number) {
        this.startIdleTimer = timer;
    }

    //log user out after 1 hour of inactivity
    idleTimer = 1 * Hour;
    resetIdleTimer = () => {
        window.clearTimeout(this.startIdleTimer);
        this.setIdleTimer(
            window.setTimeout(() => {
                this.idleTimeout = 0;
            }, this.idleTimer)
        );
    };

    setAuth({ accessToken, refreshToken, expiry }: auth) {
        storage.set(SessionEnums.NG_STORAGE_AUTH_TOKEN, accessToken);
        storage.set(SessionEnums.NG_STORAGE_REFRESH_TOKEN, refreshToken);
        storage.set(SessionEnums.NG_STORAGE_AUTH_TOKEN_EXPIRY, (Date.now() + expiry * Second).toString());
        storage.set(SessionEnums.NG_STORAGE_IDLE_TIME_EXPIRY, Date.now());
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
        this.tokenExpiry = Date.now() + expiry * Second;
    }

    async validateLogin(body: any) {
        try {
            const { accessToken, refreshToken, accessExpiresIn } = await sessionClient.postLogin(body);
            this.setAuth({ accessToken, refreshToken, expiry: parseInt(accessExpiresIn) });
        } catch (error) {
            throw error;
        }
    }

    async validateIbmLogin(body: any) {
        try {
            const { accessToken, refreshToken, accessExpiresIn } = await sessionClient.postIbmLogin(body);
            this.setAuth({ accessToken, refreshToken, expiry: parseInt(accessExpiresIn) });
        } catch (error) {
            throw error;
        }
    }

    REFRESH_TOKEN_BUFFER = 2 * Minute;
    //handler to refresh token if user is active
    handleTokenRefresh = async () => {
        if (this.tokenExpiry - Date.now() <= this.REFRESH_TOKEN_BUFFER) {
            //refresh the token
            try {
                const authResponse = await sessionClient.readRefreshToken(this.refreshToken as string);
                const accessToken = authResponse.accessToken as string;
                const refreshToken = authResponse.refreshToken as string;
                const expiry = authResponse.accessExpiresIn || '0';
                this.setAuth({ accessToken, refreshToken, expiry: parseInt(expiry) });
            } catch (error) {
                console.log(error);
            }
        }
    };

    runIdler = () => {
        this.idlerOn = true;
        setInterval(() => {
            this.handleTokenRefresh();
        }, 30 * Second);
        activityEvents.forEach(event => window.addEventListener(event, debounce(this.resetIdleTimer, 10000)));
    };

    stopIdler = () => {
        this.idlerOn = false;
        activityEvents.forEach(event => window.removeEventListener(event, this.resetIdleTimer));
    };
}

export default SessionStore;
