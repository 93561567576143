import { makeObservable, observable, action } from 'mobx';
import { CertificateDetails } from '@baffle/graphql/src/models';
import certificateClient from '@baffle/api-client/src/certificateClient';
import { RequestOpts } from '../types/StoreTypes';
import { t } from '@baffle/translate';

class CertificateStore {
    constructor() {
        makeObservable(this, {
            setCertificates: action,
            setSelectedCertificate: action,
            setCertificateOverviewList: action,
        });
    }

    certificates = observable<CertificateDetails>([]);
    selectedCertificate: CertificateDetails | null = null;
    certificateOverviewList = observable<Partial<CertificateDetails>>([]);

    setCertificates(certificates: CertificateDetails[]) {
        this.certificates.replace(certificates);
    }

    setSelectedCertificate(certificate: CertificateDetails | null) {
        this.selectedCertificate = certificate;
    }

    setCertificateOverviewList(certificates: Partial<CertificateDetails[]>) {
        const certificateOverview = certificates.map(cert => ({
            id: cert?.id ?? '',
            name: cert?.name ?? '',
            subject: cert?.subject ?? '',
            expirationDate: cert?.expirationDate ?? '',
            signatureAlgorithm: cert?.signatureAlgorithm ?? '',
            serialNo: cert?.serialNo ?? '',
            keyAlgorithm: cert?.keyAlgorithm ?? '',
            certDetails: {
                name: cert?.certDetails?.name ?? '',
                link: cert?.certDetails?.link ?? '',
            },
            createdBy: cert?.createdBy ?? '',
            createTime: cert?.createTime ?? '',
        }));
        this.certificateOverviewList.replace(certificateOverview);
    }

    async list(opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first' && this.certificates.length > 0) {
            return;
        }
        try {
            const res = await certificateClient.list();
            this.setCertificates(res.data);
            this.setCertificateOverviewList(this.certificates);
        } catch (error) {
            throw error;
        }
    }

    get(id: string) {
        return this.certificates.find(c => {
            return c.id === id;
        });
    }

    async create(input: { certName: string; cert: File }) {
        if (!Boolean(input.cert)) {
            throw new Error(t('certificate.emptyCertificateFile'));
        }
        const payload = {
            cacertinfo: { name: input.certName },
            ...(input?.cert ? { cacertfile: input.cert } : {}),
        };
        try {
            await certificateClient.create(payload);
            await this.list();
        } catch (error) {
            throw error;
        }
    }

    async delete(id: string) {
        try {
            await certificateClient.remove(id);
            await this.list();
        } catch (error) {
            throw error;
        }
    }

    async edit(id: string, cert: Partial<CertificateDetails>) {
        try {
            await certificateClient.update(id, cert);
            await this.list();
        } catch (error) {
            throw error;
        }
    }
}

export default CertificateStore;
