export interface DropdownSearchComboBoxProps<T> {
    items: T[];
    defaultItems?: T[] | null;
    getToggleButtonProps?: () => any;
    getComboBoxProps?: () => any;
    getMenuProps?: () => any;
    getListItemProps?: ({ item, index }: { item: T; index: number }) => any;
}

export const getDefaultComboBoxProps: () => any = () => {
    return {
        className: 'flex flex-col',
    };
};

export const getDefaultMenuProps: () => any = () => {
    return {
        className: 'absolute overflow-y-auto z-6000 focus:outline-none focus:border-blue-60 shadow-lg',
    };
};

export interface ListItemProps {
    item: any;
    index: number;
}
export const getDefaultListItemProps: ({ item, index }: ListItemProps) => any = () => {
    return {
        className:
            'flex w-full items-center p-2 cursor-pointer border-gray-300 bg-white focus:border-blue-60 focus:outline-none',
    };
};
