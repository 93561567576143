import React from 'react';
import { RadioButton, RadioButtonGroup } from 'carbon-components-react';
import { ApplicationDetailsV2, DeploymentState, MigrationPlan, Shield } from '@baffle/graphql/src/models';
import { t } from '@baffle/translate';
import MigrationPlanDetails from './MigrationPlanDetails';
import { InputActions, InputError, InputState } from '@baffle/utilities/src/inputHooks';
import DropdownSelect from '@baffle/components/src/dropdown/Dropdown';
import SkeletonText from '@baffle/components/src/skeleton/SkeletonText';
import usePageHeight from '@baffle/utilities/src/usePageHeight';
import { DropdownItem } from '@baffle/components/src/dropdown/dropdownTypes';
import { EncryptionStore } from '../../stores';
interface MigrationSettingsPanelProps {
    deploymentPlan: DeploymentState;
    setDeploymentPlan: React.Dispatch<React.SetStateAction<DeploymentState>>;
    migrationShield: string;
    setMigrationShield: React.Dispatch<React.SetStateAction<string>>;
    application: ApplicationDetailsV2;
    shields: Shield[];
    migrationPlan: MigrationPlan;
    loading: boolean;
    isEnrolled: boolean;
    inputState: InputState;
    inputErrors: InputError;
    inputActions: InputActions;
    triggerAddShield?: () => void;
}
const MigrationSettingsPanel = ({
    migrationShield,
    setMigrationShield,
    deploymentPlan,
    setDeploymentPlan,
    inputState,
    inputErrors,
    inputActions,
    application,
    shields,
    migrationPlan,
    isEnrolled,
    loading,
    triggerAddShield,
}: MigrationSettingsPanelProps) => {
    const selectedMigrationShield = shields.find(s => s.id === migrationShield);
    const defaultMigrationShield = selectedMigrationShield
        ? { id: selectedMigrationShield.id, label: selectedMigrationShield.shieldName }
        : { id: 'chooseOption', label: t('main.chooseOption') };

    const migrationShieldItems: DropdownItem[] = shields.map(s => {
        return {
            id: s.id,
            label: s.shieldName,
            'data-testid': 'migration-shield-' + s.shieldName,
        };
    });

    const pageHeight = usePageHeight();
    //below is to set the migration settings panel height to be the same as the datagrid height so that we can set the overflow to scroll on it
    const pageHeightOffset = 230;
    const height = pageHeight - pageHeightOffset;
    return (
        <div className="flex flex-col items-start w-64 flex-shrink-0 overflow-y-auto" style={{ height: height }}>
            {loading ? (
                <SkeletonText />
            ) : (
                <div>
                    <p className="text-sm font-bold mb-4">{t('encryption.deploymentPlan')}</p>
                    <RadioButtonGroup
                        labelPosition="right"
                        name="deploymentplan-input"
                        className="mb-4 ml-2"
                        onChange={(val: DeploymentState) => setDeploymentPlan(val)}
                        orientation="vertical"
                        valueSelected={deploymentPlan}>
                        <RadioButton
                            id="deploymentplan-save_draft"
                            data-testid="deploymentplan-save_draft"
                            labelText={t(`encryption.storePolicy`)}
                            value={'save_draft'}
                        />
                        <RadioButton
                            id="deploymentplan-save_deploy"
                            data-testid="deploymentplan-save_deploy"
                            labelText={t(`encryption.deployPolicy`)}
                            value={'save_deploy'}
                        />
                        <RadioButton
                            className="hide-element"
                            id="deploymentplan-save_migrate"
                            data-testid="deploymentplan-save_migrate"
                            labelText={t(`encryption.migratePolicy`)}
                            value={'save_migrate'}
                            disabled={shields.length === 0 || EncryptionStore.disableDeployAndMigrate}
                        />
                    </RadioButtonGroup>
                </div>
            )}

            {deploymentPlan === 'save_migrate' ? (
                <>
                    <MigrationPlanDetails
                        isEnrolled={isEnrolled}
                        loading={loading}
                        inputActions={inputActions}
                        inputState={inputState}
                        inputErrors={inputErrors}
                        application={application}
                        migrationPlan={migrationPlan}
                    />
                    <div className="flex-col w-64 pr-6 mt-3">
                        {loading ? (
                            <SkeletonText />
                        ) : (
                            <DropdownSelect
                                position="top_left_align"
                                label={t('encryption.migration.shield')}
                                id="migration-shield-input"
                                placeholder={t('main.chooseOption')}
                                value={defaultMigrationShield}
                                theme="secondary"
                                handleSelectedItemChange={({ selectedItem }) => {
                                    setMigrationShield(selectedItem?.id as string);
                                }}
                                items={migrationShieldItems}
                            />
                        )}
                    </div>
                </>
            ) : null}
        </div>
    );
};
export default MigrationSettingsPanel;
