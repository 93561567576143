import React from 'react';
import { t } from '@baffle/translate';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@baffle/components/src/modal/Modal';
import Button from '@baffle/components/src/buttons/Button';
import Alert from '@baffle/components/src/alerts/Alert';
import useAwait from '@baffle/api-client/src/useAwait';
import debounce from 'lodash.debounce';
import WarningIcon from '@material-ui/icons/Warning';
import { Checkbox } from 'carbon-components-react';
import { InputActions, InputError, InputState, useInputState } from '../../../../utilities/src/inputHooks';
import Joi from '@hapi/joi';
import { SystemEmailStore } from '@baffle/manager/src/stores';

interface ModalPropsWithoutChildren {
    'aria-labelledby'?: string;
    open: boolean;
    onClose?: () => void;
    doHandleDecrypt: () => void;
    deploymentPlan: string;
}

const DecryptionConfirmationModal = ({
    open,
    onClose = () => null,
    doHandleDecrypt = () => null,
    deploymentPlan,
}: ModalPropsWithoutChildren) => {
    const { error, reset } = useAwait();
    const { inputState, inputErrors, inputActions } = useInputState({
        keys: ['decryptConfirm'],
        ns: 'decryption',
        validationSchema: {
            decryptConfirm: Joi.boolean().required(),
        },
        defaultValues: { decryptConfirm: false },
    });

    const initiateDecryption = () => {
        doHandleDecrypt();
    };

    const handleClose = () => {
        reset();
        onClose();
    };

    return (
        <Modal open={open} onClose={handleClose}>
            {deploymentPlan === 'save_deploy' ? (
                <DecryptonConfirmationDeploy
                    inputState={inputState}
                    inputActions={inputActions}
                    inputErrors={inputErrors}
                    error={error}
                    initiateDecryption={initiateDecryption}
                    handleClose={handleClose}
                />
            ) : (
                <DecryptonConfirmation
                    inputState={inputState}
                    inputActions={inputActions}
                    inputErrors={inputErrors}
                    error={error}
                    initiateDecryption={initiateDecryption}
                    handleClose={handleClose}
                />
            )}
        </Modal>
    );
};

interface DecryptonConfirmation {
    handleClose: () => void;
    initiateDecryption: () => void;
    error: Error | null;
    inputState: InputState;
    inputActions: InputActions;
    inputErrors: InputError;
}
const DecryptonConfirmation = ({
    initiateDecryption,
    inputActions,
    inputState,
    inputErrors,
    handleClose,
    error,
}: DecryptonConfirmation) => {
    return (
        <>
            <ModalHeader onClose={handleClose}>
                <div
                    className="mt-0 ml-2 text-red text-3xl h-36 flex flex-col items-center justify-center"
                    id="modal-headline">
                    <WarningIcon classes={{ root: 'text-5xl' }} />
                    <h3 className="mt-1">{t('decryption.decryptionWarning')}</h3>
                </div>
            </ModalHeader>
            <ModalBody>
                <div>
                    {error ? (
                        <div className="flex flex-wrap mb-4">
                            <div className="flex-1 px-4">
                                <Alert
                                    type="danger"
                                    title={t('main.unexpectedError')}
                                    description={error?.message || ''}
                                />
                            </div>
                        </div>
                    ) : null}
                    <div className="h-52 flex flex-col items-center">
                        <p className="text-lg font-semibold">{t('decryption.confirmationLabel')}</p>
                        <div className="mt-6">
                            <Checkbox
                                id={'decryption-enable-input'}
                                labelText={t('decryption.checkboxLabel')}
                                checked={inputState.decryptConfirm}
                                onChange={(event: any, { checked, id }: { checked: boolean; id: any }) => {
                                    inputActions.setField('decryptConfirm', checked);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex justify-end items-center h-18 w-full">
                    <Button theme="secondary" className="h-full w-1/2" onClick={handleClose} size="lg">
                        {t('main.cancel')}
                    </Button>
                    <Button
                        data-testid="modal-confirm-decryption-btn"
                        className="h-full w-1/2 capitalize"
                        onClick={debounce(() => initiateDecryption(), 300)}
                        theme={!inputState.decryptConfirm ? 'disabled' : 'danger'}
                        size="lg">
                        {t('main.confirm')}
                    </Button>
                </div>
            </ModalFooter>
        </>
    );
};

const DecryptonConfirmationDeploy = ({
    initiateDecryption,
    inputActions,
    inputState,
    inputErrors,
    handleClose,
    error,
}: DecryptonConfirmation) => {
    const env = SystemEmailStore.systemEnvironment;
    return (
        <>
            <ModalHeader onClose={handleClose}>
                <div
                    className="mt-0 ml-2 text-red text-3xl h-36 flex flex-col items-center justify-center"
                    id="modal-headline">
                    <WarningIcon classes={{ root: 'text-5xl' }} />
                    <h3 className="mt-1">{t('decryption.warning')}</h3>
                </div>
            </ModalHeader>
            <ModalBody>
                <div>
                    {error ? (
                        <div className="flex flex-wrap mb-4">
                            <div className="flex-1 px-4">
                                <Alert
                                    type="danger"
                                    title={t('main.unexpectedError')}
                                    description={error?.message || ''}
                                />
                            </div>
                        </div>
                    ) : null}
                    <div className="h-52 flex flex-col items-center">
                        <p className="text-xl text-red font-medium">{t('decryption.saveDeployWarning')}</p>
                        <p className="text-sm px-12 mt-4">{t(`decryption.saveDeployMesssage.${env}`)}</p>
                        <div className="mt-6">
                            <Checkbox
                                id={'decryption-enable-input'}
                                labelText={t('decryption.deplyCheckboxLabel')}
                                checked={inputState.decryptConfirm}
                                onChange={(event: any, { checked, id }: { checked: boolean; id: any }) => {
                                    inputActions.setField('decryptConfirm', checked);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex justify-end items-center h-18 w-full">
                    <Button theme="secondary" className="h-full w-1/2" onClick={handleClose} size="lg">
                        {t('main.cancel')}
                    </Button>
                    <Button
                        data-testid="modal-confirm-decryption-btn"
                        className="h-full w-1/2 capitalize"
                        onClick={debounce(() => initiateDecryption(), 300)}
                        theme={!inputState.decryptConfirm ? 'disabled' : 'danger'}
                        size="lg">
                        {t('main.confirm')}
                    </Button>
                </div>
            </ModalFooter>
        </>
    );
};

export default DecryptionConfirmationModal;
