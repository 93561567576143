import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Select, SelectItem } from 'carbon-components-react';
import { t } from '@baffle/translate';
import { DatabaseProxiesStore } from '@baffle/manager/src/stores';
import { Observer } from 'mobx-react-lite';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import { debugLevelConfigScheme } from './addConfigInputScheme';
import useAwait from '@baffle/api-client/src/useAwait';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';
import { DebugLevel } from '@baffle/graphql/src/models';

interface AddConfigProps {
    id: string;
    open: boolean;
    onClose: () => void;
    onSuccess?: (name: string) => void;
}

const UpdateDebugLevel = ({ open, onClose, onSuccess, id }: AddConfigProps) => {
    const { run, error } = useAwait();

    const animateModal = () => {
        return new Promise(() => {
            setTimeout(() => {
                if (onClose) {
                    onClose();
                }
            }, 200);
        });
    };

    useEffect(() => {
        if (error) {
            NotificationStore.push({
                'data-testid': 'update-debug-level-failure',
                kind: 'error',
                title: t('databaseProxies.databaseProxyConfigurations.updateConfigError'),
                description: error.message ?? '',
            });
        }
    }, [error]);

    const databaseProxyConfigSchemes = useMemo(() => debugLevelConfigScheme(), []);
    //@ts-ignore
    const { inputState, inputErrors, inputActions } = useInputState(databaseProxyConfigSchemes);
    const handleClose = async () => {
        await animateModal();
    };

    useEffect(() => {
        inputActions.setFields({
            debugLevel: DatabaseProxiesStore.databaseProxyConfiguration?.debugLevel,
        });
    }, []);

    const [edit, setEdit] = useState(false);
    const isValid = inputActions.validateAll(true) && edit;

    const handleUpdateDebugLevel = () => {
        let payload: DebugLevel = databaseProxyConfigSchemes.keys.reduce((acc: any, c: any) => {
            acc[c] = (inputState[c] as string)?.trim();
            return acc;
        }, {});

        run(
            DatabaseProxiesStore.updateDatabaseProxyConfiguration(id, { configParam: 'debugLevel' }, payload).then(
                () => {
                    if (onSuccess) {
                        onSuccess(DebugLevel[inputState.debugLevel as keyof typeof DebugLevel]);
                    }
                    handleClose();
                }
            )
        );
    };

    return (
        <Observer>
            {() => {
                return (
                    <Modal
                        id="debug-level-update-modal"
                        className="modal-container-white"
                        open={open}
                        modalHeading={t('databaseProxies.databaseProxyConfigurations.updateDebugLevelHeading')}
                        onRequestClose={onClose}
                        primaryButtonText={t('databaseProxies.databaseProxyConfigurations.update')}
                        secondaryButtonText={t('main.cancel')}
                        size="sm"
                        onRequestSubmit={() => handleUpdateDebugLevel()}
                        primaryButtonDisabled={!isValid}>
                        <Select
                            id="debug-level-input"
                            className="input-helper-text"
                            labelText={t('databaseProxies.databaseProxyConfigurations.debugLevel')}
                            helperText={t('databaseProxies.databaseProxyConfigurations.debugLevelHelperText')}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (!edit) {
                                    setEdit(true);
                                }
                                const val = e.target.value || '';
                                inputActions.setField('debugLevel', val);
                            }}
                            value={inputState.debugLevel || ''}>
                            <SelectItem disabled hidden text={t('main.chooseOption')} value="placeholder-item" />
                            {Object.entries(DebugLevel).map(([debugLevel, debugLevelName]) => {
                                return (
                                    <SelectItem
                                        datatest_id={debugLevel}
                                        key={debugLevel}
                                        text={debugLevelName}
                                        value={debugLevel}
                                    />
                                );
                            })}
                        </Select>
                    </Modal>
                );
            }}
        </Observer>
    );
};

export default UpdateDebugLevel;
