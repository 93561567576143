import { makeObservable, observable, action, computed, reaction, runInAction } from 'mobx';
import {
    CryptoActionType,
    EncryptionDatabase,
    ColumnMetaData,
    EncryptionDatabaseTable,
    SelectedColumn,
    EncryptionSchema,
    ApplicationBindingDTO,
    EncryptionDatabaseSchema,
    EditActionType,
} from '@baffle/graphql/src/models';
import { RequestOpts } from '../types/StoreTypes';
import encryptionClient, { compileColumn } from '@baffle/api-client/src/encryptionClient';
import createLookupTable from '@baffle/utilities/src/createLookupTable';
import { makeTableKey, makeDBKey } from '@baffle/utilities/src/migration/makeKeys';
import { CountLookup } from '../types/CatchAll';
import isDeployed from '@baffle/utilities/src/column/isDeployed';
const encryptionFetchers = createLookupTable();

//action to mutate column
export const mutateColumn = action(function mutateCol(col: ColumnMetaData, updates: Partial<ColumnMetaData>) {
    Object.assign(col, updates);
});

class EncryptionStore {
    constructor() {
        makeObservable(this, {
            schema: observable,
            setSchema: action,
            databases: observable,
            dbSchemas: observable,
            tables: observable,
            views: observable,
            columns: observable,
            setDatabases: action,
            setDatabaseSchemas: action,
            setTables: action,
            setViews: action,
            setColumns: action,
            schemaLoading: observable,
            databasesLoading: observable,
            tablesLoading: observable,
            columnsLoading: observable,
            setLoading: action,
            selectedEncryptionDatabase: observable,
            selectedDecryptionDatabase: observable,
            selectedEncryptionTable: observable,
            selectedDecryptionTable: observable,
            selectedEncryptionSchema: observable,
            selectedDecryptionSchema: observable,
            selectedEncryptionDatabases: computed,
            selectedDecryptionDatabases: computed,
            selectedEncryptionDatabaseTables: computed,
            selectedDecryptionDatabaseTables: computed,
            setSelectedDatabase: action,
            setSelectedSchema: action,
            setSelectedTable: action,
            selectedEncryptionColumns: observable,
            selectedDecryptionColumns: observable,
            setSelectedColumns: action,
            deleteAllSelectedColumns: action,
            deleteSelectedColumns: action,
            computeSelectedEncryptedColumnsCount: action,
            computeSelectedDecryptedColumnsCount: action,
            disableDeployAndMigrate: observable,
        });

        //anytime the selected encryption columns change, compute the count
        reaction(
            () => this.selectedEncryptionColumns.length,
            () => this.computeSelectedEncryptedColumnsCount()
        );
        //anytime the selected decryption columns change, compute the count
        reaction(
            () => this.selectedDecryptionColumns.length,
            () => this.computeSelectedDecryptedColumnsCount()
        );
        //anytime the selected decryption columns change, compute the count
        reaction(
            () => this.selectedDecryptionTable,
            () => {
                if (this.selectedDecryptionTable === '') {
                    this.setColumns([]);
                }
            }
        );
        reaction(
            () => this.selectedEncryptionTable,
            () => {
                if (this.selectedEncryptionTable === '') {
                    this.setColumns([]);
                }
            }
        );
    }

    schemaDatabases(actionType: CryptoActionType, fourLevel: boolean | undefined) {
        switch (actionType) {
            case 'encryption':
                return Array.from(
                    new Set(
                        (this.schema?.columns ?? [])
                            .filter(c => !c.touched)
                            .map(c => (fourLevel ? c.dbName : c.database))
                    )
                );
            case 'decryption':
                return Array.from(
                    new Set(
                        (this.schema?.columns ?? [])
                            .filter(c => c.touched)
                            .map(c => (fourLevel ? c.dbName : c.database))
                    )
                );
            default:
                return Array.from(new Set((this.schema?.columns ?? []).map(c => (fourLevel ? c.dbName : c.database))));
        }
    }

    schemaDatabaseSchemas(actionType: CryptoActionType) {
        switch (actionType) {
            case 'encryption':
                return Array.from(
                    new Set(
                        (this.schema?.columns ?? [])
                            .filter(c => c.dbName === this.selectedEncryptionDatabase && !c.touched)
                            .map(c => c.database)
                    )
                );
            case 'decryption':
                return Array.from(
                    new Set(
                        (this.schema?.columns ?? [])
                            .filter(c => c.dbName === this.selectedDecryptionDatabase && c.touched)
                            .map(c => c.database)
                    )
                );
            default:
                return Array.from(new Set((this.schema?.columns ?? []).map(c => c.database)));
        }
    }

    schemaTables(actionType: CryptoActionType, fourLevel: boolean | undefined, tableType = 'table') {
        switch (actionType) {
            case 'encryption':
                return Array.from(
                    new Set(
                        (this.schema?.columns ?? [])
                            .filter(
                                c =>
                                    c.database ===
                                        (fourLevel ? this.selectedEncryptionSchema : this.selectedEncryptionDatabase) &&
                                    !c.touched
                            )
                            .map(c => c.table)
                    )
                );
            case 'decryption':
                return Array.from(
                    new Set(
                        (this.schema?.columns ?? [])
                            .filter(
                                c =>
                                    c.database ===
                                        (fourLevel ? this.selectedDecryptionSchema : this.selectedDecryptionDatabase) &&
                                    c.touched
                            )
                            .filter(c => c.tableType.toLowerCase() == tableType)
                            .map(c => c.table)
                    )
                );
            default:
                return Array.from(new Set((this.schema?.columns ?? []).map(c => c.table)));
        }
    }

    schema: EncryptionSchema | null = null;
    setSchema(schema: EncryptionSchema) {
        this.setSelectedColumnsFromSchema(schema);
        this.schema = schema;
    }

    disableDeployAndMigrate: boolean = false;
    setDisableDeployAndMigrate(disableDeployAndMigrate: boolean) {
        this.disableDeployAndMigrate = disableDeployAndMigrate;
    }
    async readDeployAndMigrate(payload: ApplicationBindingDTO, cryptoAction: CryptoActionType) {
        try {
            const { deployAndMigrate, applicationBindingDTO } =
                (await encryptionClient.readDeployAndMigrate(payload, cryptoAction)) ?? {};
            this.setDisableDeployAndMigrate(!deployAndMigrate);
            applicationBindingDTO.columns.forEach((column: ColumnMetaData) => {
                compileColumn(applicationBindingDTO.id, column);
            });
            const columns = applicationBindingDTO.columns.filter((col: ColumnMetaData) => {
                if (cryptoAction === 'encryption') {
                    return getSelectedColumnIndex(col, this.selectedEncryptionColumns) !== -1;
                } else {
                    return getSelectedColumnIndex(col, this.selectedDecryptionColumns) !== -1;
                }
            });
            this.setSelectedColumns({
                actionType: cryptoAction,
                nextColumns: columns,
                replace: true,
            });
        } catch (error) {
            throw error;
        }
    }

    //DEPRECATION WARNING: we are actively removing loading logic from stores in favor of using status from useAwait
    schemaLoading: boolean = false;
    databasesLoading: boolean = false;
    tablesLoading: boolean = false;
    columnsLoading: boolean = false;
    setLoading(loading: boolean, key: 'schema' | 'databases' | 'tables' | 'columns') {
        switch (key) {
            case 'schema':
                this.schemaLoading = loading;
                break;
            case 'databases':
                this.databasesLoading = loading;
                break;
            case 'tables':
                this.tablesLoading = loading;
                break;
            case 'columns':
                this.columnsLoading = loading;
                break;
            default:
                break;
        }
    }

    databases: EncryptionDatabase[] = [];
    dbSchemas = observable<EncryptionDatabaseSchema>([]);
    views = observable<EncryptionDatabaseTable>([]);
    tables = observable<EncryptionDatabaseTable>([]);
    columns: ColumnMetaData[] = [];
    setDatabases(databases: EncryptionDatabase[]) {
        //@ts-ignore
        this.databases.replace(databases);
    }
    setDatabaseSchemas(dbSchemas: EncryptionDatabaseSchema[]) {
        this.dbSchemas.replace(dbSchemas);
    }
    setTables(tables: EncryptionDatabaseTable[]) {
        //@ts-ignore
        this.tables.replace(tables);
    }
    setViews(views: EncryptionDatabaseTable[]) {
        //@ts-ignore
        this.views.replace(views);
    }
    setColumns(columns: ColumnMetaData[]) {
        //@ts-ignore
        this.columns.replace(columns);
    }

    async getSchema(id: string, opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first' && this.schema?.id === id) {
            return;
        }
        const fetchKey = `schema_${id}`;
        if (encryptionFetchers.has(fetchKey)) {
            return;
        }
        this.setLoading(true, 'schema');
        const doFinally = () => {
            this.setLoading(false, 'schema');
            encryptionFetchers.remove(fetchKey);
        };
        try {
            const schema = (await encryptionClient.readSchema(id)) ?? {};
            this.setSchema(schema);
            return schema;
        } catch (error) {
            throw error;
        } finally {
            doFinally();
        }
    }
    //lists all of the databases
    async listDatabases(id: string, opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first' && this.databases.length) {
            return;
        }
        const fetchKey = `list_db_${id}`;
        if (encryptionFetchers.has(fetchKey)) {
            return;
        }
        encryptionFetchers.set(fetchKey, true);
        this.setLoading(true, 'databases');
        const doFinally = () => {
            this.setLoading(false, 'databases');
            encryptionFetchers.remove(fetchKey);
        };
        try {
            const databases: EncryptionDatabase[] = await encryptionClient.readDatabases(id);
            this.setDatabases(databases);
        } catch (error) {
            throw error;
        } finally {
            doFinally();
        }
    }

    //list all of the schemas
    async listDatabaseSchemas(id: string, dbName: string, opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first' && this.dbSchemas.length) {
            return;
        }
        try {
            const schemas: EncryptionDatabaseSchema[] = await encryptionClient.readDatabaseSchemas(id, dbName);
            this.setDatabaseSchemas(schemas);
        } catch (error) {
            throw error;
        }
    }

    //list all of the schema tables
    async listSchemaTables(id: string, dbSchema: string, opts: RequestOpts = {}) {
        if (
            opts.networkPolicy === 'cache-first' &&
            this.tables.filter(t => t.path === `${dbSchema}/${t.name}`).length
        ) {
            return;
        }
        try {
            const tables: EncryptionDatabaseTable[] = await encryptionClient.readSchemaTables(id, dbSchema);
            this.setTables(tables);
        } catch (error) {
            throw error;
        }
    }

    //lists all of the schema table columns
    async listSchemaTableColumns(id: string, dbSchema: string, tableName: string, opts: RequestOpts = {}) {
        if (
            opts.networkPolicy === 'cache-first' &&
            this.columns.filter(c => c.path === `${dbSchema}/${tableName}/${c.name}`).length
        ) {
            return;
        }
        try {
            const columns: ColumnMetaData[] = await encryptionClient.readSchemaTableColumns(id, dbSchema, tableName);
            this.setColumns(columns);
        } catch (error) {
            throw error;
        }
    }

    //lists all of the tables
    async listTables(id: string, dbName: string, opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first' && this.tables.filter(t => t.path === `${dbName}/${t.name}`).length) {
            return;
        }
        const fetchKey = `list_tables_${id}_${dbName}`;
        if (encryptionFetchers.has(fetchKey)) {
            return;
        }
        encryptionFetchers.set(fetchKey, true);
        this.setLoading(true, 'tables');
        const doFinally = () => {
            this.setLoading(false, 'tables');
            encryptionFetchers.remove(fetchKey);
        };
        try {
            const tables: EncryptionDatabaseTable[] = await encryptionClient.readTables(id, dbName);
            this.setTables(tables);
        } catch (error) {
            throw error;
        } finally {
            doFinally();
        }
    }

    //lists all of the columns
    async listColumns(id: string, dbName: string, tableName: string, opts: RequestOpts = {}) {
        if (
            opts.networkPolicy === 'cache-first' &&
            this.columns.filter(c => c.path === `${dbName}/${tableName}/${c.name}`).length
        ) {
            return;
        }
        const fetchKey = `list_columns_${id}_${dbName}_${tableName}`;
        if (encryptionFetchers.has(fetchKey)) {
            return;
        }
        encryptionFetchers.set(fetchKey, true);
        this.setLoading(true, 'columns');
        const doFinally = () => {
            this.setLoading(false, 'columns');
            encryptionFetchers.remove(fetchKey);
        };
        try {
            const columns: ColumnMetaData[] = await encryptionClient.readColumns(id, dbName, tableName);
            this.setColumns(columns);
        } catch (error) {
            throw error;
        } finally {
            doFinally();
        }
    }

    //lists all of the schema views
    async listViews(id: string, dbName: string, opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first' && this.tables.filter(t => t.path === `${dbName}/${t.name}`).length) {
            return;
        }
        try {
            const views: EncryptionDatabaseTable[] = await encryptionClient.readViews(id, dbName);
            this.setViews(views);
        } catch (error) {
            throw error;
        }
    }
    //lists all of the schema views
    async listSchemaViews(id: string, dbSchema: string, opts: RequestOpts = {}) {
        if (
            opts.networkPolicy === 'cache-first' &&
            this.tables.filter(t => t.path === `${dbSchema}/${t.name}`).length
        ) {
            return;
        }
        try {
            const views: EncryptionDatabaseTable[] = await encryptionClient.readSchemaViews(id, dbSchema);
            this.setViews(views);
        } catch (error) {
            throw error;
        }
    }

    selectedDecryptionDatabase = '';
    selectedEncryptionDatabase = '';
    selectedDecryptionSchema = '';
    selectedEncryptionSchema = '';
    selectedDecryptionTable = '';
    selectedEncryptionTable = '';
    get selectedEncryptionDatabaseTables() {
        return this.selectedEncryptionColumns
            .filter(c => c.database === this.selectedEncryptionDatabase)
            .map(c => c.table);
    }
    get selectedDecryptionDatabaseTables() {
        return this.selectedDecryptionColumns
            .filter(c => c.database === this.selectedDecryptionDatabase)
            .map(c => c.table);
    }
    setSelectedDatabase(cryptoType: CryptoActionType, database: string) {
        switch (cryptoType) {
            case 'encryption':
                this.selectedEncryptionDatabase = database;
                break;
            case 'decryption':
                this.selectedDecryptionDatabase = database;
                break;

            default:
                break;
        }
    }
    //sets it to dbName/schemaName
    setSelectedSchema(cryptoType: CryptoActionType, schema: string) {
        switch (cryptoType) {
            case 'encryption':
                this.selectedEncryptionSchema = schema;
                break;
            case 'decryption':
                this.selectedDecryptionSchema = schema;
                break;

            default:
                break;
        }
    }
    setSelectedTable(cryptoType: CryptoActionType, table: string) {
        switch (cryptoType) {
            case 'encryption':
                this.selectedEncryptionTable = table;
                break;
            case 'decryption':
                this.selectedDecryptionTable = table;

                break;

            default:
                break;
        }
    }

    selectedDecryptionColumns: SelectedColumn[] = [];
    selectedEncryptionColumns: SelectedColumn[] = [];

    get selectedEncryptionDatabases() {
        return Array.from(new Set(this.selectedEncryptionColumns.map(c => c.database)));
    }
    get selectedDecryptionDatabases() {
        return Array.from(new Set(this.selectedDecryptionColumns.map(c => c.database)));
    }

    selectedEncryptedColumnsByDatabaseCount = observable<CountLookup>({});
    selectedEncryptedColumnsByTableCount = observable<CountLookup>({});

    computeSelectedEncryptedColumnsCount() {
        const colsPerDatabaseCount = observable<CountLookup>({});
        const colsPerTableCount = observable<CountLookup>({});
        for (let i = 0; i < this.selectedEncryptionColumns.length; i++) {
            const col = this.selectedEncryptionColumns[i];
            const databaseKey = makeDBKey(col.database);

            if (Boolean(colsPerDatabaseCount[databaseKey])) {
                colsPerDatabaseCount[databaseKey] += 1;
            } else {
                colsPerDatabaseCount[databaseKey] = 1;
            }

            const tableKey = makeTableKey(col.database, col.table);
            if (Boolean(colsPerTableCount[tableKey])) {
                colsPerTableCount[tableKey] += 1;
            } else {
                colsPerTableCount[tableKey] = 1;
            }
        }
        this.selectedEncryptedColumnsByDatabaseCount = colsPerDatabaseCount;
        this.selectedEncryptedColumnsByTableCount = colsPerTableCount;
    }

    selectedDecryptedColumnsByDatabaseCount = observable<CountLookup>({});
    selectedDecryptedColumnsByTableCount = observable<CountLookup>({});

    computeSelectedDecryptedColumnsCount() {
        const colsPerDatabaseCount = observable<CountLookup>({});
        const colsPerTableCount = observable<CountLookup>({});
        for (let i = 0; i < this.selectedDecryptionColumns.length; i++) {
            const col = this.selectedDecryptionColumns[i];
            const databaseKey = makeDBKey(col.database);

            if (Boolean(colsPerDatabaseCount[databaseKey])) {
                colsPerDatabaseCount[databaseKey] += 1;
            } else {
                colsPerDatabaseCount[databaseKey] = 1;
            }

            const tableKey = makeTableKey(col.database, col.table);
            if (Boolean(colsPerTableCount[tableKey])) {
                colsPerTableCount[tableKey] += 1;
            } else {
                colsPerTableCount[tableKey] = 1;
            }
        }
        this.selectedDecryptedColumnsByDatabaseCount = colsPerDatabaseCount;
        this.selectedDecryptedColumnsByTableCount = colsPerTableCount;
    }

    setSelectedColumnsFromSchema = (schema: EncryptionSchema) => {
        if (!schema.columns) {
            return;
        }
        for (let k = 0; k < schema.columns.length; k++) {
            const col = schema.columns[k];
            // Check for both encryption & decryption
            if (!isDeployed(col.decryptionDeploymentState ? col.decryptionDeploymentState : col.deploymentState)) {
                this.setSelectedColumns({
                    actionType: col.decryptionDeploymentState ? 'decryption' : 'encryption',
                    nextColumns: ([col] as unknown) as ColumnMetaData[],
                    replace: false,
                });
            }
        }
    };

    setSelectedColumns = ({ actionType, nextColumns, replace }: SetSelectedColumnsArg) => {
        let updated: SelectedColumn[];
        switch (actionType) {
            case 'decryption':
                updated = this.selectedDecryptionColumns.slice();
                for (let i = 0; i < nextColumns.length; i++) {
                    const d = nextColumns[i];
                    let index = getSelectedColumnIndex(d, updated);
                    if (index === -1) {
                        let c: SelectedColumn = compileSelectedColumn(d);
                        updated.push(c);
                    } else if (replace) {
                        let c: SelectedColumn = compileSelectedColumn(d);
                        c.tableType = updated[i].tableType;
                        updated[index] = c;
                    }
                }
                this.selectedDecryptionColumns = updated;
                break;

            case 'encryption':
                updated = this.selectedEncryptionColumns.slice();
                for (let i = 0; i < nextColumns.length; i++) {
                    const d = nextColumns[i];
                    let index = getSelectedColumnIndex(d, updated);
                    if (index === -1) {
                        let c: SelectedColumn = compileSelectedColumn(d);
                        updated.push(c);
                    } else if (replace) {
                        let c: SelectedColumn = compileSelectedColumn(d);
                        c.tableType = updated[i].tableType;
                        updated[index] = c;
                    }
                }
                runInAction(() => {
                    this.selectedEncryptionColumns = updated;
                });

                break;
            default:
                console.warn('you did not set the actionType; see setSelectedColumns');
                break;
        }
    };

    updateSelectedColumns = ({ editType, newDBName, newSchemaName, newTableName }: UpdateSelectedColumnsArg) => {
        const selectedColumns = this.selectedEncryptionColumns.slice();
        const [dbName, schemaName, tableName] = [
            this.selectedEncryptionDatabase,
            this.selectedEncryptionSchema,
            this.selectedEncryptionTable,
        ];
        //edit handlers for 3-level and 4-level trees
        switch (editType) {
            case 'database-3':
                selectedColumns.map(col => {
                    if (col.dbName === dbName && newDBName) {
                        col.dbName = newDBName;
                        col.database = newDBName;
                        col.path = `${col.database}/${col.table}/${col.name}`;
                    }
                });
                runInAction(() => {
                    this.selectedEncryptionColumns = selectedColumns;
                });
                break;
            case 'database-4':
                selectedColumns.map(col => {
                    if (col.dbName === dbName && newDBName) {
                        col.dbName = newDBName;
                        col.database = newDBName + '/' + col.scName;
                        col.path = `${col.database}/${col.table}/${col.name}`;
                    }
                });
                runInAction(() => {
                    this.selectedEncryptionColumns = selectedColumns;
                });
                break;
            case 'schema':
                selectedColumns.map(col => {
                    if (col.database === schemaName && newSchemaName) {
                        col.scName = newSchemaName;
                        col.database = dbName + '/' + newSchemaName;
                        col.path = `${col.database}/${col.table}/${col.name}`;
                    }
                });
                runInAction(() => {
                    this.selectedEncryptionColumns = selectedColumns;
                });

                break;
            case 'table-3':
                selectedColumns.map(col => {
                    if (col.dbName === dbName && col.table === tableName && newTableName) {
                        col.table = newTableName;
                        col.path = `${col.database}/${col.table}/${col.name}`;
                    }
                });
                runInAction(() => {
                    this.selectedEncryptionColumns = selectedColumns;
                });
                break;
            case 'table-4':
                selectedColumns.map(col => {
                    if (col.database === schemaName && col.table === tableName && newTableName) {
                        col.table = newTableName;
                        col.path = `${col.database}/${col.table}/${col.name}`;
                    }
                });
                runInAction(() => {
                    this.selectedEncryptionColumns = selectedColumns;
                });
                break;
            default:
                console.warn('you did not set the editType; see updateSelectedColumns');
                break;
        }
    };

    deleteSelectedColumns = ({ actionType, nextColumns }: DeleteSelectedColumnsArg) => {
        let updated: SelectedColumn[];
        switch (actionType) {
            case 'decryption':
                updated = this.selectedDecryptionColumns.slice();
                nextColumns.forEach((d: ColumnMetaData) => {
                    let index = getSelectedColumnIndex(d, updated);
                    if (index > -1) {
                        updated.splice(index, 1);
                    }
                });
                this.selectedDecryptionColumns = updated;
                break;

            case 'encryption':
                updated = this.selectedEncryptionColumns.slice();
                nextColumns.forEach((d: ColumnMetaData) => {
                    let index = getSelectedColumnIndex(d, updated);
                    if (index > -1) {
                        updated.splice(index, 1);
                    }
                });
                this.selectedEncryptionColumns = updated;
                break;
            default:
                console.warn('you did not set the actionType; see setSelectedColumns');
                break;
        }
    };

    deleteAllSelectedColumns = ({ actionType }: DeleteAllSelectedColumnsArg) => {
        switch (actionType) {
            case 'decryption':
                this.selectedDecryptionColumns = [];
                break;

            case 'encryption':
                this.selectedEncryptionColumns = [];
                break;
            default:
                console.warn('you did not set the actionType; see setSelectedColumns');
                break;
        }
    };

    resetSelected = ({ actionType }: ResetSelectedArg) => {
        this.deleteAllSelectedColumns({
            actionType,
        });
        this.setSelectedDatabase(actionType, '');
        this.setSelectedSchema(actionType, '');
        this.setSelectedTable(actionType, '');
        this.setDatabases([]);
        this.setDatabaseSchemas([]);
        this.setTables([]);
    };
    resetDatabaseTableAndColumns = ({ actionType }: ResetSelectedArg) => {
        this.setSelectedDatabase(actionType, '');
        this.setSelectedSchema(actionType, '');
        this.setSelectedTable(actionType, '');
        this.setDatabases([]);
        this.setDatabaseSchemas([]);
        this.setTables([]);
    };
}

/**
 * Utility functions
 */

const getSelectedColumnIndex = (c: ColumnMetaData, d: SelectedColumn[]) => {
    return d.findIndex((sc: SelectedColumn) => sc.path === c.path);
};

const setDefaultKeyID = (c: SelectedColumn) => {
    //default KeyID to 2
    //-2 is the id for RLE
    if (c.keyID === null || c.keyID === undefined || c.keyID === -1) {
        return 2;
    }
    return c.keyID;
};

const compileSelectedColumn = (col: ColumnMetaData) => {
    let c: SelectedColumn = Object.assign({}, col);
    c.keyID = setDefaultKeyID(c);
    return c;
};

/**
 * Interfaces
 */
interface SetSelectedColumnsArg {
    actionType: CryptoActionType;
    nextColumns: ColumnMetaData[];
    replace: boolean;
}

interface UpdateSelectedColumnsArg {
    editType: EditActionType;
    newDBName?: string;
    newSchemaName?: string;
    newTableName?: string;
}

interface DeleteSelectedColumnsArg {
    actionType: CryptoActionType;
    nextColumns: ColumnMetaData[];
}

interface DeleteAllSelectedColumnsArg {
    actionType: CryptoActionType;
}
interface ResetSelectedArg {
    actionType: CryptoActionType;
}

export default EncryptionStore;
