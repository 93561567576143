import React, { useEffect } from 'react';
import { Modal } from 'carbon-components-react';
import { t } from '@baffle/translate';
import { ApplicationStore } from '@baffle/manager/src/stores';
import { Observer } from 'mobx-react-lite';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';
import useAwait from '@baffle/api-client/src/useAwait';

interface RefreshSyncIdModalProps {
    open: boolean;
    appId: string | null | undefined;
    onClose: () => void;
    onSuccess: () => void;
}

const RefreshSyncIdModal = ({ open, appId, onClose, onSuccess }: RefreshSyncIdModalProps) => {
    const { run, error, reset } = useAwait();

    useEffect(() => {
        if (error) {
            NotificationStore.push({
                'data-testid': 'refresh-sync-id-failure',
                kind: 'error',
                title: t('application.refreshSyncIdFailure'),
                description: error.message ?? t('main.genericError'),
            });
        }
    }, [error]);

    const handleClose = () => {
        reset();
        setTimeout(() => {
            if (onClose) {
                onClose();
            }
        }, 200);
    };

    const handleSave = () => {
        run(
            ApplicationStore.refreshSyncId(appId as string).then(() => {
                handleClose();
                if (onSuccess) {
                    onSuccess();
                }
            })
        );
    };

    return (
        <Observer>
            {() => {
                return (
                    <Modal
                        id="refresh-sync-id-modal"
                        className="modal-container-white"
                        open={open}
                        modalHeading={t('application.refreshSyncId')}
                        onRequestClose={onClose}
                        primaryButtonText={t('main.confirm')}
                        secondaryButtonText={t('main.cancel')}
                        size="sm"
                        onRequestSubmit={handleSave}>
                        <p>{t('application.refreshSyncIdDescription')}</p>
                    </Modal>
                );
            }}
        </Observer>
    );
};

export default RefreshSyncIdModal;
