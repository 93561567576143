import React, { useEffect } from 'react';
import { ApplicationDetailsV2, MigrationPlan } from '@baffle/graphql/src/models';
import { InputActions, InputError, InputState } from '@baffle/utilities/src/inputHooks';
import MigrationPlanForm from './MigrationPlanForm';
import { SkeletonParagraph } from '@baffle/components/src/skeleton/SkeletonText';

interface MigrationPlanProps {
    application: ApplicationDetailsV2;
    migrationPlan: MigrationPlan;
    loading: boolean;
    isEnrolled: boolean;
    inputState: InputState;
    inputErrors: InputError;
    inputActions: InputActions;
}

const MigrationPlanDetails = (props: MigrationPlanProps) => {
    const { loading, inputState, inputErrors, inputActions, application, migrationPlan, isEnrolled } = props;
    useEffect(() => {
        if (!loading && !isEnrolled) {
            inputActions.setFields({
                migrationType: migrationPlan.migrationType,
                batch: migrationPlan.batch,
                //server sends batch size as string instread of int
                batchSize: parseInt((migrationPlan.batchSize as unknown) as string),
                failScope: migrationPlan.failScope,
                parallel: migrationPlan.parallel,
                cleanTmp: migrationPlan.cleanTmp,
            });
        }
    }, [loading, isEnrolled]);
    return loading ? (
        <div className="migration-plan flex-col pr-6">
            <SkeletonParagraph />
        </div>
    ) : (
        <MigrationPlanForm
            isEnrolled={isEnrolled}
            application={application}
            inputState={inputState}
            inputErrors={inputErrors}
            inputActions={inputActions}
        />
    );
};

export default MigrationPlanDetails;
