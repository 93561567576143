import React, { useEffect, useState } from 'react';
import { Observer } from 'mobx-react-lite';
import Content from '@baffle/components/src/content/Content';
import { Overview, OverviewCard } from '@baffle/components/src/overview/Overview';
import PageHeader from '@baffle/components/src/pageheader/pageheader';
import { RoleStore } from '@baffle/manager/src/stores';
import useAwait from '@baffle/api-client/src/useAwait';
import { useHistory, useParams } from 'react-router-dom';
import { t } from '@baffle/translate';
import { useManageModals } from '@baffle/components/src/modal/Modal';
import SpinnerOverlay from '@baffle/components/src/loader/SpinnerOverlay';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';
import { RoleInfo } from '@baffle/graphql/src/models';
import DataGrid from '@baffle/components/src/grid/data-grid';
import RenameRole from './RenameRole';
import { Modal } from 'carbon-components-react';

const RoleDetails = () => {
    const { id } = useParams() as { id: string };
    const [refresh, setRefresh] = useState(true);
    const history = useHistory();
    if (!id) {
        history.push('/roles');
        return null;
    }

    const { status, run, error } = useAwait();

    useEffect(() => {
        if (refresh) {
            run(
                RoleStore.getRoles().then(() => {
                    RoleStore.getRole(id);
                })
            );

            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        if (error) {
            NotificationStore.push({
                'data-testid': 'role-update-failure',
                kind: 'error',
                title: t('roles.addRoleError'),
                description: error.message ?? t('main.unexpectedError'),
            });
        }
    }, [error]);

    const headers = [
        {
            key: 'header1',
            header: t('roles.dataPolicies'),
            sortable: true,
        },
    ];

    return (
        <Observer>
            {() => {
                const roleInfo = RoleStore.role;

                // TBD: Get the list data policies associated from the backend once the BE API is available
                const rows: any = null;

                interface RoleModals {
                    deleteRole: boolean;
                    renameRole: boolean;
                }

                const initialModalState = {
                    deleteRole: false,
                    renameRole: false,
                };

                const [modals, setModals] = useManageModals<RoleModals>(initialModalState);

                const actions = [
                    {
                        id: '1',
                        label: t('main.rename'),
                    },
                    {
                        id: '2',
                        label: t('main.delete'),
                    },
                ];

                const breadcrumbData = [
                    {
                        label: t('main.roles'),
                        href: '/roles',
                    },
                ];

                const onSelectAction = (item: any) => {
                    switch (item.selectedItem.label) {
                        case t('main.rename'):
                            setModals({ renameRole: true });
                            break;
                        case t('main.delete'):
                            setModals({ deleteRole: true });
                            break;
                        default:
                            break;
                    }
                };
                const onDelete = async () => {
                    setModals({ deleteRole: false });
                    run(
                        RoleStore.deleteRole(RoleStore.role?.id as string).then(() => {
                            if (status === 'success') {
                                NotificationStore.push({
                                    'data-testid': 'role-del-success',
                                    kind: 'success',
                                    title: t('roles.deleteRoleSuccessMsg', {
                                        name: RoleStore.role?.name,
                                    }),
                                });
                            }
                            RoleStore.setRole(null);
                        })
                    );
                    history.push('/roles');
                };

                const onRenameRole = async (roleInfo: RoleInfo) => {
                    setModals({ renameRole: false });
                    run(
                        RoleStore.updateRole(roleInfo).then(() => {
                            if (status === 'success') {
                                NotificationStore.push({
                                    'data-testid': 'role-update-success',
                                    kind: 'success',
                                    title: t('roles.updateRoleSuccessMsg', {
                                        name: roleInfo.name,
                                    }),
                                });
                                setRefresh(true);
                            }
                        })
                    );
                };

                return (
                    <>
                        <Content>
                            {modals.renameRole && (
                                <RenameRole
                                    open={modals.renameRole}
                                    onClose={() => setModals({ renameRole: false })}
                                    onSuccess={(role: RoleInfo) => {
                                        onRenameRole(role);
                                    }}
                                />
                            )}
                            <Modal
                                open={modals.deleteRole}
                                id="role-delete-modal"
                                modalHeading={t('roles.deleteConfirmationTitle', {
                                    name: RoleStore.role?.name,
                                })}
                                danger={true}
                                onRequestClose={() => setModals({ deleteRole: false })}
                                primaryButtonText={t('main.delete')}
                                secondaryButtonText={t('main.cancel')}
                                size="sm"
                                onRequestSubmit={onDelete}>
                                <p>{t('roles.deleteConfirmationMsg', { name: RoleStore.role?.name })}</p>
                            </Modal>

                            {(status === 'loading' || status === 'idle') && <SpinnerOverlay description="" />}
                            {roleInfo && (
                                <>
                                    <PageHeader
                                        title={roleInfo.name}
                                        actions={actions}
                                        breadcrumbData={breadcrumbData}
                                        onActionSelect={onSelectAction}
                                    />
                                    <div className="px-8 mt-4 text-lg">
                                        <div
                                            className={
                                                'pb-2 w-40 border-solid border-b-2 border-blue-60 cursor-pointer'
                                            }>
                                            <div className="ml-4">{t('main.overview')}</div>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="w-1/2 med:w-3/5">
                                            <Overview
                                                title={t('roles.roleDetails')}
                                                info={RoleStore.getRoleOverview(roleInfo)}
                                                topic="roles"
                                            />
                                        </div>
                                        <div className="w-1/2 med:w-2/5">
                                            <OverviewCard title={t('roles.dataPolicies')} topic="policies">
                                                <DataGrid
                                                    addMargin={false}
                                                    idPrefix="roleAssociation"
                                                    rows={rows || []}
                                                    headers={headers}></DataGrid>
                                            </OverviewCard>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Content>
                    </>
                );
            }}
        </Observer>
    );
};

export default RoleDetails;
