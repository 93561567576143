import { User, Role } from '@baffle/graphql/src/models';

export function isSuperAdmin(roles: Role[]) {
    return roles.some(role => role.name === 'ROLE_SYSTEM_ADMIN');
}

export function isAdmin(roles: Role[]) {
    return roles.some(role => role.name === 'ROLE_DOMAIN_ADMIN');
}

export function getRoleLabel(user: User) {
    if (isSuperAdmin(user.roles ?? [])) {
        return 'SUPER ADMIN';
    }
    if (isAdmin(user.roles ?? [])) {
        return 'ADMIN';
    }
    return 'MEMBER';
}
