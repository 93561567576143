import { makeObservable, observable, action } from 'mobx';
import { ApplicationDetailsV2, ApplicationListItem, ApplicationV2 } from '@baffle/graphql/src/models';
import applicationClient from '@baffle/api-client/src/applicationClient';
import { RequestOpts } from '../types/StoreTypes';

class ApplicationStore {
    constructor() {
        makeObservable(this, {
            setApplicationListItems: action,
            setApplications: action,
            replaceApplicationDetails: action,
            setApplicationsV2: action,
        });
    }

    applicationsV2 = observable<ApplicationV2>([]);
    applicationListItems = observable<ApplicationListItem>([]);
    applications = observable<ApplicationDetailsV2>([]);

    setApplicationsV2(applications: ApplicationV2[]) {
        this.applicationsV2.replace(applications);
    }
    setApplications(applications: ApplicationDetailsV2[]) {
        this.applications.replace(applications);
    }
    setApplicationListItems(applicationListItems: ApplicationListItem[]) {
        this.applicationListItems.replace(applicationListItems);
    }
    async list(opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first' && this.applicationListItems.length > 0) {
            return;
        }
        try {
            const res = await applicationClient.list();
            this.setApplicationsV2(res.data);
        } catch (error) {
            throw error;
        }
    }

    async readV2(id: string, opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first' && this.applicationsV2.findIndex(app => app.id === id) >= 0) {
            return;
        }
        try {
            const application = await applicationClient.readV2(id);
            this.replaceApplicationV2(id, application);
        } catch (error) {
            throw error;
        }
    }

    async read(id: string, opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first' && this.applications.findIndex(app => app.id === id) >= 0) {
            return;
        }
        try {
            const applicationDetails = await applicationClient.read(id);
            this.replaceApplicationDetails(id, applicationDetails);
        } catch (error) {
            throw error;
        }
    }

    getV2(id: string) {
        return this.applicationsV2.find(a => {
            return a.id === id;
        });
    }
    get(id: string) {
        return this.applications.find(a => {
            return a.id === id;
        });
    }

    replaceApplicationV2(id: string, application: ApplicationV2) {
        const index = this.applicationsV2.findIndex(item => item.id === id);
        const applications = this.applicationsV2.slice();
        if (index >= 0) {
            applications.splice(index, 1);
        }
        applications.push(application);
        this.setApplicationsV2(applications);
    }

    replaceApplicationDetails(id: string, application: ApplicationDetailsV2) {
        const index = this.applications.findIndex(item => item.id === id);
        const applications = this.applications.slice();
        if (index >= 0) {
            applications.splice(index, 1);
        }
        applications.push(application);
        this.setApplications(applications);
    }

    async refreshSyncId(id: string) {
        try {
            await applicationClient.refreshSyncId(id);
            this.list();
        } catch (error) {
            throw error;
        }
    }
}

export default ApplicationStore;
