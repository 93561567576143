import React, { useState } from 'react';
import ArrowsVerticalIcon from '@baffle/components/src/icons/ArrowsVerticalIcon';
import ArrowDownIcon from '@baffle/components/src/icons/ArrowDownIcon';
import ArrowUpIcon from '@baffle/components/src/icons/ArrowUpIcon';
interface SortArrows {
    [state: string]: JSX.Element | null;
}

//TH is to be used with react data grid
const getDefaultProps = () => {
    return {
        className: 'flex justify-between items-center datagrid-th w-full h-full',
    };
};

const TH = (props: any) => {
    const [hover, setHover] = useState(false);
    const sortArrow: SortArrows = {
        NONE: <ArrowsVerticalIcon fontSize="small" className={!hover ? 'hidden' : 'sort-icon'} />,
        ASC: <ArrowUpIcon fontSize="small" />,
        DESC: <ArrowDownIcon fontSize="small" />,
    };

    const getProps: () => any = props.getProps ?? getDefaultProps;

    const { className: customClassName, ...otherProps } = getProps();

    return props.column.flush ? null : (
        <div
            onFocus={() => {
                setHover(true);
            }}
            onBlur={() => {
                setHover(false);
            }}
            onMouseOver={() => {
                setHover(true);
            }}
            onMouseOut={() => {
                setHover(false);
            }}
            id={`${props.idPrefix}-${props.columnKey}`}
            className="h-full w-full pl-2"
            {...otherProps}>
            <div className={customClassName}>
                {props.column.name} {sortArrow[props.sortDirection]}{' '}
                {props.column.component ? props.column.component : null}
            </div>
        </div>
    );
};
export default TH;
