import React, { useState } from 'react';
import { BaseCompoundComponentProps } from '../types/react';
import ArrowsVerticalIcon from '@baffle/components/src/icons/ArrowsVerticalIcon';
import ArrowDownIcon from '@baffle/components/src/icons/ArrowDownIcon';
import ArrowUpIcon from '@baffle/components/src/icons/ArrowUpIcon';
import KeyClick from '../handlers/KeyClck';
import { SortDirection } from '@baffle/utilities/src/gridHelpers';
import cx from 'classnames';

export const THead = ({ children, ...rest }: BaseCompoundComponentProps) => {
    return (
        <div role="columnheader" aria-sort="none" className="bg-gray-20 flex flex-col h-12" {...rest}>
            {children}
        </div>
    );
};

export const THeadRow = ({ children, ...rest }: BaseCompoundComponentProps) => {
    return (
        <div role="row" className="flex items-center w-full h-full" {...rest}>
            {children}
        </div>
    );
};

interface SortArrows {
    [state: string]: JSX.Element | null;
}

export const getDefaultTHeadCellProps = ({ sortable }: { sortable: boolean } = { sortable: false }) => {
    return {
        sort: 'NONE',
        className: cx('text-left text-sm font-bold h-full pl-2', {
            'flex justify-between items-center hover:bg-gray-20-hover': sortable,
        }),
    };
};

export interface THeadCellProps extends BaseCompoundComponentProps {
    sortFn?: (direction: SortDirection) => void;
    sortKey?: string;
    currentKey?: string;
    direction?: SortDirection;
    getTHeadCellProps?: () => any;
}

export interface SortTable {
    key: string;
    direction: SortDirection;
}
export const useSortTable = () => {
    return useState<SortTable>({ key: '', direction: 'NONE' });
};

export const THeadCell = ({
    children,
    sortFn,
    sortKey,
    direction,
    currentKey,
    getTHeadCellProps = getDefaultTHeadCellProps,
    ...rest
}: THeadCellProps) => {
    const [hover, setHover] = useState(false);
    const sortArrow: SortArrows = {
        NONE: (
            <ArrowsVerticalIcon
                fontSize="small"
                className={!hover && !(currentKey === sortKey) ? 'hidden' : 'sort-icon mr-2'}
            />
        ),
        ASC: <ArrowUpIcon fontSize="small" className="mr-2" />,
        DESC: <ArrowDownIcon fontSize="small" className="mr-2" />,
    };

    if (sortFn && !Boolean(sortKey)) {
        throw new Error('please provide a THeadCell key prop when using sortFn');
    }
    if (sortFn) {
        return (
            <KeyClick
                handler={() => {
                    if (currentKey !== sortKey) {
                        sortFn('ASC');
                        return;
                    }
                    switch (direction) {
                        case 'ASC':
                            sortFn('DESC');
                            break;
                        case 'DESC':
                            sortFn('NONE');
                            break;
                        case 'NONE':
                        default:
                            sortFn('ASC');
                            break;
                    }
                }}>
                <div
                    onFocus={() => {
                        setHover(true);
                    }}
                    onBlur={() => {
                        setHover(false);
                    }}
                    onMouseOver={() => {
                        setHover(true);
                    }}
                    onMouseOut={() => {
                        setHover(false);
                    }}
                    role="header"
                    {...rest}
                    {...getTHeadCellProps()}>
                    {children} {currentKey === sortKey ? sortArrow[direction as SortDirection] : sortArrow['NONE']}
                </div>
            </KeyClick>
        );
    }

    return (
        <div role="header" {...rest} {...getTHeadCellProps()}>
            {children}
        </div>
    );
};
