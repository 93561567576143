import React from 'react';
import { CryptoActionType } from '@baffle/graphql/src/models';
import '../encryption/Encryption.scss';
import { EncryptionStore } from '../../stores';
import { Observer } from 'mobx-react-lite';
import useAwait from '@baffle/api-client/src/useAwait';
import cx from 'classnames';
import SkeletonText, { getDefaultSkeletonTextProps } from '@baffle/components/src/skeleton/SkeletonText';
import TreeDownArrowIcon from '@baffle/components/src/icons/TreeDownArrowIcon';
import TreeRightArrowIcon from '@baffle/components/src/icons/TreeRightArrowIcon';
import TreeDatabaseIcon from '@baffle/components/src/icons/TreeDatabaseIcon';
import SchemaIcon from '@baffle/components/src/icons/SchemaIcon';
import KeyClick from '@baffle/components/src/handlers/KeyClck';

import DecryptionTreeViewItem from './DecryptionTreeViewItem';

interface EncryptionTreeViewProps {
    id: string;
    dbs: string[];
    tables: string[];
    views: string[];
    dbSchemas: string[];
    cryptoAction: CryptoActionType;
    fourLevel: boolean | undefined;
    handleFilterChange: (obj: string) => void;
}

const DecryptionTreeView = ({
    id,
    dbs,
    tables,
    views,
    dbSchemas,
    cryptoAction,
    fourLevel,
    handleFilterChange,
}: EncryptionTreeViewProps) => {
    const { status: lazyStatusDB, run: lazyRunDB } = useAwait();
    const { status: lazyStatusSchema, run: lazyRunSchema } = useAwait();
    return fourLevel ? (
        <Observer>
            {() => (
                <nav
                    className="flex-1 bg-white overflow-y-auto text-xs border-solid border-r-2 border-gray-300"
                    aria-label="Sidebar">
                    {dbs.map((db, i) => {
                        const isSelected = EncryptionStore.selectedDecryptionDatabase === db;
                        return (
                            <div key={`${db}_${i}`}>
                                <KeyClick
                                    key={`keyclick_${db}_${i}`}
                                    data-testid={`database_tree_entry_${db}`}
                                    handler={() => {
                                        if (isSelected) {
                                            EncryptionStore.setSelectedDatabase(cryptoAction, '');
                                        } else {
                                            EncryptionStore.setSelectedDatabase(cryptoAction, db);
                                            EncryptionStore.setSelectedSchema(cryptoAction, '');
                                            lazyRunDB(EncryptionStore.listDatabaseSchemas(id, db));
                                            handleFilterChange('schema');
                                        }
                                    }}>
                                    <div
                                        className="group hover:bg-gray-200 hover:text-gray-900 bg-white text-gray-600 w-full flex items-center font-medium py-1"
                                        aria-controls={`schema-nav-menu-${i}`}
                                        aria-expanded={isSelected ? 'true' : 'false'}>
                                        <span
                                            className={cx('flex-1 flex justify-between items-center ml-3', {
                                                italic: '', //db.new,
                                            })}>
                                            <span className="w-56 truncate">
                                                {isSelected ? <TreeDownArrowIcon /> : <TreeRightArrowIcon />}
                                                <TreeDatabaseIcon />
                                                <span className="ml-1" title={db}>
                                                    {db}
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </KeyClick>

                                {isSelected ? (
                                    <div className="space-y-1" id={`schema-nav-menu-${i}`}>
                                        {lazyStatusDB === 'loading' ? (
                                            <SkeletonText
                                                getSkeletonProps={() => {
                                                    const { className, ...rest } = getDefaultSkeletonTextProps();
                                                    return {
                                                        ...rest,
                                                        width: 'w-40',
                                                        className: `${className} ml-6`,
                                                    };
                                                }}
                                            />
                                        ) : (
                                            dbSchemas.map((schema, i) => {
                                                const isSelectedSchema =
                                                    EncryptionStore.selectedDecryptionSchema === schema;
                                                return (
                                                    <div key={`${schema}_${i}`}>
                                                        <KeyClick
                                                            key={`keyclick_${schema}_${i}`}
                                                            data-testid={`schema_tree_entry_${schema}`}
                                                            handler={() => {
                                                                if (isSelectedSchema) {
                                                                    EncryptionStore.setSelectedSchema(cryptoAction, '');
                                                                } else {
                                                                    EncryptionStore.setSelectedSchema(
                                                                        cryptoAction,
                                                                        schema
                                                                    );
                                                                    EncryptionStore.setSelectedTable(cryptoAction, '');
                                                                    lazyRunSchema(
                                                                        EncryptionStore.listSchemaTables(
                                                                            id as string,
                                                                            schema
                                                                        )
                                                                    );
                                                                    lazyRunSchema(
                                                                        EncryptionStore.listSchemaViews(
                                                                            id as string,
                                                                            schema
                                                                        )
                                                                    );
                                                                    handleFilterChange('table');
                                                                }
                                                            }}>
                                                            <div
                                                                key={`schema_${i}_${schema}`}
                                                                className="group w-full flex items-center py-1 font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-200"
                                                                aria-controls={`db-nav-menu-${i}`}
                                                                aria-expanded={isSelectedSchema ? 'true' : 'false'}>
                                                                <span
                                                                    className={cx(
                                                                        'flex-1 flex justify-between items-center ml-7',
                                                                        {
                                                                            italic: '', //schema.new,
                                                                        }
                                                                    )}>
                                                                    <span className="w-44 truncate">
                                                                        {isSelectedSchema ? (
                                                                            <TreeDownArrowIcon />
                                                                        ) : (
                                                                            <TreeRightArrowIcon />
                                                                        )}
                                                                        <SchemaIcon />
                                                                        <span className="ml-1" title={schema}>
                                                                            {schema.split('/')[1]}
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </KeyClick>

                                                        {/* Expandable link section, show/hide based on state. */}
                                                        {isSelectedSchema ? (
                                                            <div className="space-y-1" id={`db-nav-menu-${i}`}>
                                                                {lazyStatusSchema === 'loading' ? (
                                                                    <SkeletonText
                                                                        getSkeletonProps={() => {
                                                                            const {
                                                                                className,
                                                                                ...rest
                                                                            } = getDefaultSkeletonTextProps();
                                                                            return {
                                                                                ...rest,
                                                                                width: 'w-40',
                                                                                className: `${className} ml-14`,
                                                                            };
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        <DecryptionTreeViewItem
                                                                            id={id}
                                                                            db={db}
                                                                            fourLevel={fourLevel}
                                                                            tableType={'tables'}
                                                                            items={tables}
                                                                            i={i}
                                                                            cryptoAction={cryptoAction}
                                                                            schemaName={schema}
                                                                        />
                                                                        <DecryptionTreeViewItem
                                                                            id={id}
                                                                            db={db}
                                                                            fourLevel={fourLevel}
                                                                            tableType={'views'}
                                                                            items={views}
                                                                            i={i}
                                                                            cryptoAction={cryptoAction}
                                                                            schemaName={schema}
                                                                        />
                                                                    </>
                                                                )}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                );
                                            })
                                        )}
                                    </div>
                                ) : null}
                            </div>
                        );
                    })}
                </nav>
            )}
        </Observer>
    ) : (
        <nav
            className="flex-1 bg-white overflow-y-auto text-xs border-solid border-r-2 border-gray-300"
            aria-label="Sidebar">
            {dbs.map((db, i) => {
                const isSelected = EncryptionStore.selectedDecryptionDatabase === db;
                return (
                    <div key={`${db}_${i}`}>
                        <KeyClick
                            key={`keyclick_${db}_${i}`}
                            data-testid={`database_tree_entry_${db}`}
                            handler={() => {
                                if (isSelected) {
                                    EncryptionStore.setSelectedDatabase(cryptoAction, '');
                                } else {
                                    EncryptionStore.setSelectedDatabase(cryptoAction, db);
                                    EncryptionStore.setSelectedTable(cryptoAction, '');
                                    lazyRunDB(EncryptionStore.listTables(id as string, db));
                                    lazyRunDB(EncryptionStore.listViews(id as string, db));
                                    handleFilterChange('table');
                                }
                            }}>
                            <div
                                className="group hover:bg-gray-200 hover:text-gray-900 bg-white text-gray-600 w-full flex items-center font-medium py-1"
                                aria-controls={`db-nav-menu-${i}`}
                                aria-expanded={isSelected ? 'true' : 'false'}>
                                <span
                                    className={cx('flex-1 flex justify-between items-center ml-3', {
                                        italic: '', //db.new,
                                    })}>
                                    <span className="w-56 truncate">
                                        {isSelected ? <TreeDownArrowIcon /> : <TreeRightArrowIcon />}
                                        <TreeDatabaseIcon />
                                        <span className="ml-1" title={db}>
                                            {db}
                                        </span>
                                    </span>
                                </span>
                            </div>
                        </KeyClick>

                        {/* Expandable link section, show/hide based on state. */}
                        {isSelected ? (
                            <div className="space-y-1" id={`db-nav-menu-${i}`}>
                                {lazyStatusDB === 'loading' ? (
                                    <SkeletonText
                                        getSkeletonProps={() => {
                                            const { className, ...rest } = getDefaultSkeletonTextProps();
                                            return {
                                                ...rest,
                                                width: 'w-40',
                                                className: `${className} ml-12`,
                                            };
                                        }}
                                    />
                                ) : (
                                    <>
                                        <DecryptionTreeViewItem
                                            id={id}
                                            db={db}
                                            fourLevel={fourLevel}
                                            tableType={'tables'}
                                            items={tables}
                                            i={i}
                                            cryptoAction={cryptoAction}
                                        />
                                        <DecryptionTreeViewItem
                                            id={id}
                                            db={db}
                                            fourLevel={fourLevel}
                                            tableType={'views'}
                                            items={views}
                                            i={i}
                                            cryptoAction={cryptoAction}
                                        />
                                    </>
                                )}
                            </div>
                        ) : null}
                    </div>
                );
            })}
        </nav>
    );
};

export default DecryptionTreeView;
