import React, { useState } from 'react';
import {
    EncryptionDatabase,
    EncryptionDatabaseTable,
    EncryptionDatabaseSchema,
    CryptoActionType,
} from '@baffle/graphql/src/models';
import './Encryption.scss';
import { ToastStore } from '../../stores/ToastStore';
import { EncryptionStore, DataFormatStore } from '../../stores';
import cx from 'classnames';
import TreeDownArrowIcon from '@baffle/components/src/icons/TreeDownArrowIcon';
import TreeViewIcon from '@baffle/components/src/icons/TreeViewIcon';
import TreeRightArrowIcon from '@baffle/components/src/icons/TreeRightArrowIcon';
import TreeTableIcon from '@baffle/components/src/icons/TreeTableIcon';
import TreeFolderIcon from '@baffle/components/src/icons/TreeFolderIcon';
import OverflowMenu from '@baffle/components/src/menu/OverflowMenu';
import TreeDropdownDownIcon from '@baffle/components/src/icons/TreeDropdownDownIcon';
import KeyClick from '@baffle/components/src/handlers/KeyClck';

interface EncryptionTreeViewItemProps {
    id: string;
    db: EncryptionDatabase;
    itemList: EncryptionDatabaseTable[];
    itemTitle: string;
    i: number;
    cryptoAction: CryptoActionType;
    menuItemsTable: any;
    dbSchema?: EncryptionDatabaseSchema;
    isView?: boolean;
}

const EncryptionTreeViewItem = ({
    itemList,
    itemTitle,
    db,
    i,
    cryptoAction,
    id,
    menuItemsTable,
    dbSchema,
    isView,
}: EncryptionTreeViewItemProps) => {
    const [isSelected, setIsSelected] = useState(
        Boolean(
            itemList.find(i => {
                return i.name == EncryptionStore.selectedEncryptionTable;
            })
        )
    );
    return (
        <>
            <KeyClick
                key={`keyclick_${db.dbName}_${i}_tables`}
                data-testid={`${isView ? 'view' : 'table'}_folder`}
                handler={() => {
                    setIsSelected(!isSelected);
                }}>
                <div
                    className={cx(
                        'group w-full flex items-center py-1 font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-200'
                    )}
                    aria-controls={`schema-nav-menu-${i}`}
                    aria-expanded={isSelected ? 'true' : 'false'}>
                    <span
                        className={cx('flex-1 flex justify-between items-center ml-12', {
                            italic: db.new,
                        })}>
                        <span className="w-56 truncate">
                            {isSelected ? <TreeDownArrowIcon /> : <TreeRightArrowIcon />}
                            <TreeFolderIcon />
                            <span className="ml-1" title={db.dbName}>
                                {`${itemTitle} (${itemList.length})`}
                            </span>
                        </span>
                    </span>
                </div>
            </KeyClick>
            {isSelected &&
                itemList.map((item, i) => {
                    const isSelectedTable = EncryptionStore.selectedEncryptionTable === item.name;
                    return (
                        <KeyClick
                            key={`keyclick_${item.name}_${i}`}
                            data-testid={`${isView ? 'view' : 'table'}_tree_entry_${
                                dbSchema ? dbSchema.name : db.name
                            }_${item.name}`}
                            handler={() => {
                                DataFormatStore.resetModeBuilder();
                                EncryptionStore.setSelectedTable(cryptoAction, item.name);
                                dbSchema
                                    ? EncryptionStore.listSchemaTableColumns(
                                          id as string,
                                          EncryptionStore.selectedEncryptionSchema,
                                          item.name
                                      ).catch(err => {
                                          ToastStore.push({
                                              title: err.message,
                                              type: 'danger',
                                          });
                                      })
                                    : EncryptionStore.listColumns(
                                          id as string,
                                          EncryptionStore.selectedEncryptionDatabase,
                                          item.name
                                      ).catch(err => {
                                          ToastStore.push({
                                              title: err.message,
                                              type: 'danger',
                                          });
                                      });
                            }}>
                            <div
                                key={`table_${i}_${item.name}`}
                                className={cx(
                                    'group w-full flex items-center py-1 font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-200',
                                    {
                                        'bg-blue-100': isSelectedTable,
                                    }
                                )}>
                                <span
                                    className={cx('flex-1 flex justify-between items-center ml-20', {
                                        italic: item.new,
                                    })}>
                                    <span className="w-44 truncate">
                                        {isView ? <TreeViewIcon /> : <TreeTableIcon />}
                                        <span className="ml-1" title={item.name}>
                                            {item.name}
                                        </span>
                                    </span>
                                    {menuItemsTable[item.name] ? (
                                        <OverflowMenu
                                            data-testid={`tree-table-menu-enc-${dbSchema ? dbSchema.name : db.name}-${
                                                item.name
                                            }`}
                                            actuator={{
                                                'data-testid':
                                                    'tree-table-dropdown-btn-enc-' +
                                                    (dbSchema ? dbSchema.name : db.name) +
                                                    '-' +
                                                    item.name,
                                                text: <TreeDropdownDownIcon />,
                                                className:
                                                    'mr-2 border border-gray-300 opacity-0 hover:opacity-100 group-hover:opacity-100 hover:bg-gray-300 focus:outline-none focus:opacity-100 focus:bg-white focus:shadow-lg',
                                                onClick: (e: React.MouseEvent) => e.stopPropagation(),
                                            }}
                                            menuItems={menuItemsTable[item.name]}
                                            menuClassName="z-10 mr-2"
                                        />
                                    ) : null}
                                </span>
                            </div>
                        </KeyClick>
                    );
                })}
        </>
    );
};

export default EncryptionTreeViewItem;
