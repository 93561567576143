import React from 'react';
import './Spinner.scss';
interface SpinnerOverlayProps {
    description: string;
}

const SpinnerOverlay = ({ description }: SpinnerOverlayProps) => {
    return (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full z-50 overflow-hidden bg-black bg-opacity-50 flex flex-col items-center justify-center">
            <div className="loader ease-linear rounded-full border-solid border-8 border-t-8 border-gray-200 h-12 w-12 mb-3"></div>
            <p className="text-md font-medium text-center text-white">{description}</p>
        </div>
    );
};

export default SpinnerOverlay;
