import dataProtectionClient from '@baffle/api-client/src/dataProtectionClient';
import rbacPolicyClient from '@baffle/api-client/src/rbacPolicyClient';
import { DataProtectionItem, RbacPolicyRuleV2, RbacPolicyV2 } from '@baffle/graphql/src/models';
import { observable, action, makeObservable } from 'mobx';
import { format } from '@baffle/utilities/src/date';

class RbacPolicyStore {
    constructor() {
        makeObservable(this, {
            rbacPolicies: observable,
            selectedRbacPolicy: observable,
            selectedRbacRule: observable,
            rbacPolicyRules: observable,
            selectedRbacPolicyRules: observable,
            maskingModes: observable,
            setRbacPolicies: action,
            setSelectedRbacRule: action,
            setMaskingModes: action,
            setSelectedRbacPolicy: action,
            setRbacPolicyRules: action,
            setSelectedRbacPolicyRules: action,
            moveTopRbacRule: action,
            moveUpRbacRule: action,
            moveDownRbacRule: action,
            moveBottomRbacRule: action,
            deleteRbacRule: action,
            deleteRbacPolicyRule: action,
            setRbacRuleOrder: action,
        });
    }
    rbacPolicies = observable<RbacPolicyV2>([]);
    selectedRbacPolicy: RbacPolicyV2 | null | undefined = null;
    selectedRbacRule: RbacPolicyRuleV2 | null | undefined = null;
    maskingModes = observable<DataProtectionItem>([]);
    rbacPolicyRules = observable<RbacPolicyRuleV2>([]);
    selectedRbacPolicyRules = observable<RbacPolicyRuleV2>([]);

    setRbacPolicies(policies: RbacPolicyV2[]) {
        this.rbacPolicies.replace(policies);
    }

    setSelectedRbacPolicy(policy: RbacPolicyV2 | null | undefined) {
        this.selectedRbacPolicy = policy;
    }

    setMaskingModes(maskModes: DataProtectionItem[]) {
        this.maskingModes.replace(maskModes);
    }

    setRbacPolicyRules(rbacPolicyRules: RbacPolicyRuleV2[]) {
        this.rbacPolicyRules.replace(
            rbacPolicyRules.slice().sort(function(a, b) {
                return a.order - b.order;
            })
        );
    }

    setSelectedRbacRule(rbacPolicyRule: RbacPolicyRuleV2) {
        this.selectedRbacRule = rbacPolicyRule;
    }

    setSelectedRbacPolicyRules(rbacPolicyRules: RbacPolicyRuleV2[]) {
        this.selectedRbacPolicyRules.replace(
            rbacPolicyRules.slice().sort(function(a, b) {
                return a.order - b.order;
            })
        );
    }

    setRbacRuleOrder(i: number) {
        this.rbacPolicyRules[i].order = i + 1;
    }

    moveTopRbacRule(i: number) {
        const rule = this.rbacPolicyRules[i];
        this.rbacPolicyRules.splice(i, 1);
        this.rbacPolicyRules.unshift(rule);
    }

    moveUpRbacRule(i: number) {
        if (i != 0) {
            const rule = this.rbacPolicyRules[i - 1];
            this.rbacPolicyRules[i - 1] = this.rbacPolicyRules[i];
            this.rbacPolicyRules[i] = rule;
        }
    }

    moveDownRbacRule(i: number) {
        if (i != this.rbacPolicyRules.length - 1) {
            const rule = this.rbacPolicyRules[i];
            this.rbacPolicyRules[i] = this.rbacPolicyRules[i + 1];
            this.rbacPolicyRules[i + 1] = rule;
        }
    }

    moveBottomRbacRule(i: number) {
        this.rbacPolicyRules.push(this.rbacPolicyRules.splice(i, 1)[0]);
    }

    deleteRbacRule(i: number) {
        this.rbacPolicyRules.splice(i, 1);
    }

    async listMaskingModes() {
        try {
            const res = await dataProtectionClient.listMaskingModes();
            this.setMaskingModes(res.data);
        } catch (error) {
            throw error;
        }
    }

    async readRbacPolicies() {
        try {
            const res = await rbacPolicyClient.readRbacPolicies();
            this.setRbacPolicies(res?.data);
        } catch (error) {
            throw error;
        }
    }

    async deleteRbacPolicy(id: string) {
        try {
            await rbacPolicyClient.deleteRbacPolicy(id);
        } catch (error) {
            throw error;
        }
    }

    async getSelectedRbacPolicy(id: string) {
        try {
            const rbacPolicy = await rbacPolicyClient.readRbacPolicy(id);
            this.setSelectedRbacPolicy(rbacPolicy);
        } catch (error) {
            throw error;
        }
    }

    getRbacPolicyOverview(policy: RbacPolicyV2) {
        return {
            id: policy.id,
            name: policy.name,
            defaultPermission: policy.defaultPermission,
            rules: policy.ruleRefs?.length,
            createdDate: format(policy.audit.createdDate, 'MM-dd-yyyy'),
            createdBy: policy.audit.createdBy,
        };
    }

    get(id: string) {
        return this.rbacPolicies.find(m => m.id === id);
    }

    async deleteRbacPolicyRule(id: string) {
        try {
            await rbacPolicyClient.deleteRbacRule(id);
        } catch (error) {
            throw error;
        }
    }

    async readRbacRules(id: string) {
        try {
            let res = await rbacPolicyClient.readRbacRules(id);
            if (!res) {
                res = [];
            }
            this.setRbacPolicyRules(res);
            this.setSelectedRbacPolicyRules(res);
        } catch (error) {
            throw error;
        }
    }

    async readRbacRule(id: string) {
        try {
            const res = await rbacPolicyClient.readRbacRule(id);
            this.setSelectedRbacRule(res);
        } catch (error) {
            throw error;
        }
    }
}

export default RbacPolicyStore;
