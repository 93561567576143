import React from 'react';
import { t } from '@baffle/translate';
import Button from '@baffle/components/src/buttons/Button';
import { DataFormatStore } from '@baffle/manager/src/stores';
import { Observer } from 'mobx-react-lite';
import usePageHeight from '@baffle/utilities/src/usePageHeight';

const RbacUsrGrpLanding = () => {
    const pageHeight = usePageHeight() - 64;
    return (
        <Observer>
            {() => {
                return (
                    <div className="flex-grow bg-white">
                        <div className="bg-gray-200 px-4 h-8 border-solid border-t-2 border-b-2 border-gray-300">
                            <div className="relative flex items-center h-full uppercase w-112 font-bold text-xs">
                                <span>{t('encryption.modeLibrary.rbac.userGroups')}</span>
                            </div>
                        </div>
                        <div className="overflow-y-auto" style={{ height: pageHeight }}>
                            <div className="w-116 mt-4 pl-4 mb-12">
                                {Boolean(DataFormatStore.rbacUsrGrps && DataFormatStore.rbacUsrGrps.length === 0) ? (
                                    <div
                                        className="flex flex-wrap mb-6 items-end"
                                        data-testid="rbac-usr-grp-landing-div">
                                        <div className="flex-1 name-input-container">
                                            <p className="text-xs">
                                                {t('encryption.modeLibrary.rbac.rbacUsrGrp.rbacUsrGrpCopy')}
                                            </p>
                                            <br />
                                            <p
                                                className="text-xs opacity-50"
                                                dangerouslySetInnerHTML={{
                                                    __html: t('encryption.modeLibrary.rbac.rbacConfig.rbacConfigCopy1'),
                                                }}
                                            />
                                            <br />
                                            <p
                                                className="text-xs"
                                                dangerouslySetInnerHTML={{
                                                    __html: t('encryption.modeLibrary.rbac.rbacConfig.rbacConfigCopy2'),
                                                }}
                                            />
                                            <br />
                                            <p
                                                className="text-xs"
                                                dangerouslySetInnerHTML={{
                                                    __html: t('encryption.modeLibrary.rbac.rbacConfig.rbacConfigCopy3'),
                                                }}
                                            />
                                        </div>
                                    </div>
                                ) : null}

                                <div className="flex flex-wrap mb-6 items-start">
                                    <div className="flex-1">
                                        <Button
                                            theme="primary"
                                            className="py-2 text-xs font-semibold uppercase"
                                            id="add-rbac-usr-grp-btn"
                                            onClick={() => {
                                                DataFormatStore.resetModeBuilder();
                                                DataFormatStore.setShowRbacUsrGrpCreate(true);
                                            }}>
                                            {t('encryption.modeLibrary.rbac.rbacUsrGrp.createUsrGrpsBtnLabel')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }}
        </Observer>
    );
};

export default RbacUsrGrpLanding;
