import React, { useEffect, useState } from 'react';
import { Observer } from 'mobx-react-lite';
import { t } from '@baffle/translate';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import Button from '@baffle/components/src/buttons/Button';
import AddLoggerModal from './AddLoggerModal';
import { useManageModals } from '@baffle/components/src/modal/Modal';
import { ToastStore } from '@baffle/manager/src/stores/ToastStore';
import { SystemEmailStore } from '@baffle/manager/src/stores';
import useAwait from '@baffle/api-client/src/useAwait';
import { LoggerConfiguration } from '@baffle/graphql/src/models';
import DeleteLoggerModal from './DeleteLoggerModal';

const ConfigureLogger = () => {
    const { run } = useAwait();
    return (
        <Observer>
            {() => {
                interface ConfigureLogModals {
                    addLog: boolean;
                    deleteLog: boolean;
                }
                const initialModalState = {
                    addLog: false,
                    deleteLog: false,
                };
                const [modals, setModals] = useManageModals<ConfigureLogModals>(initialModalState);
                const [deleteLoggerId, setDeleteLoggerId] = useState('');
                const [deleteLoggerName, setDeleteLoggerName] = useState('');
                useEffect(() => {
                    run(SystemEmailStore.readLoggers());
                }, [modals.addLog, modals.deleteLog]);

                const loggers: LoggerConfiguration[] = SystemEmailStore.loggers;
                return (
                    <>
                        <AddLoggerModal open={modals.addLog} onClose={() => setModals({ addLog: false })} />

                        <DeleteLoggerModal
                            open={modals.deleteLog}
                            onClose={() => setModals({ deleteLog: false })}
                            id={deleteLoggerId}
                            name={deleteLoggerName}
                            onSuccess={() => {
                                ToastStore.push({ title: t('settings.deleteLoggerSuccess'), type: 'success' });
                            }}
                        />

                        <div className="flex-col pb-18">
                            <div className="flex justify-between items-end">
                                <div className="flex flex-col">
                                    <p className="text-lg font-bold mb-1">{t('settings.logging')}</p>
                                    <p className="text-sm text-gray-500">{t('settings.configureLog')}</p>
                                </div>
                                <Button
                                    theme="primary"
                                    className="h-full w-32 justify-between flex-row-reverse"
                                    id="add-logger-btn"
                                    onClick={() => setModals({ addLog: true })}>
                                    <AddIcon className="w-4 h-4" />
                                    {t('settings.logger')}
                                </Button>
                            </div>

                            <table className="w-full mt-2 text-xsmall text-left">
                                <thead className="h-12 border-solid border border-t border-gray-300 bg-gray-20 font-bold text-sm">
                                    <tr>
                                        <th scope="col" className="p-3 w-2/3">
                                            {t('settings.logger')}
                                        </th>
                                        <th scope="col" className="p-3 w-1/3">
                                            {t('settings.logLevel')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="h-12 border-solid border-l border-r border-b border-gray-300">
                                    {loggers.length != 0 ? (
                                        loggers.map(logger => (
                                            <tr className="bg-white" key={logger.id}>
                                                <td className="p-3 w-4/6" data-testid="configure-logger-name">
                                                    {logger?.name}
                                                </td>
                                                <td className="p-3 w-2/6" data-testid="configure-log-level">
                                                    <div className="flex justify-between items-center">
                                                        {logger?.logLevel}
                                                        <DeleteOutlineIcon
                                                            id="delete-logger-btn"
                                                            onClick={() => {
                                                                setModals({ deleteLog: true });
                                                                setDeleteLoggerId(logger?.id);
                                                                setDeleteLoggerName(logger?.name);
                                                            }}
                                                            className="cursor-pointer config-menu-delete w-4 h-4"
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr className="bg-white"></tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </>
                );
            }}
        </Observer>
    );
};

export default ConfigureLogger;
