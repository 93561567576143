import React, { useMemo, useEffect } from 'react';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import { CreateModal, CreateModalBody, CreateModalHeader } from '@baffle/components/src/CreateModal/CreateModal';
import { TextInput, Dropdown } from 'carbon-components-react';
import { createTenantInputScheme } from './createTenantInputScheme';
import { TenantStore } from '@baffle/manager/src/stores';
import { TenantInfo } from '@baffle/graphql/src/models';
import { t } from '@baffle/translate';
import { KeystoreStore } from '../stores';
import useAwait from '@baffle/api-client/src/useAwait';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';

interface AddTenantModalProps {
    open: boolean;
    onClose: () => void;
    onSuccess: (tenantInfo: TenantInfo) => void;
}

const AddTenantModal = (props: AddTenantModalProps) => {
    const { run, error } = useAwait();
    const tenantInputSchemes = useMemo(
        () => createTenantInputScheme(TenantStore.tenantList, TenantStore.tenant?.id),
        []
    );
    //@ts-ignore
    const { inputState, inputErrors, inputActions } = useInputState(tenantInputSchemes);

    const keystores = KeystoreStore.keystoreListItems || [];
    // TBD: Dummy data for now, with Keystore V2 implemented, should get this data from KeystoreStore
    const cmks = [
        { name: 'cmk-1', id: 1 },
        { name: 'cmk-2', id: 2 },
    ];
    const deks = [
        { name: 'dek-1', id: 1 },
        { name: 'dek-2', id: 2 },
    ];

    useEffect(() => {
        if (error) {
            NotificationStore.push({
                'data-testid': 'tenant-ks-get-failure',
                kind: 'error',
                title: t('tenants.ksGetFailure'),
                description: error.message ?? t('main.unexpectedError'),
            });
        }
    }, [error]);

    useEffect(() => {
        run(Promise.all([KeystoreStore.clearKeystores(), KeystoreStore.list()]));
    }, []);

    const isValid = inputActions.validateAll(true);

    const onSuccess = () => {
        let tenantData = {
            name: inputState.name,
            keystore: inputState.keystore,
            cmk: inputState.cmk,
            dek: inputState.dek,
        };
        props.onSuccess(tenantData as TenantInfo);
    };

    const onClose = () => {
        inputActions.reset();
        props.onClose();
    };
    return (
        <>
            <CreateModal
                key={JSON.stringify(props)}
                open={props.open}
                onClose={onClose}
                isValid={isValid}
                modalId="add-tenant-modal"
                primaryButtonText={t('main.create')}
                primaryButtonTheme="primary"
                primaryButton={onSuccess}>
                <CreateModalHeader titleText={t('tenants.addTenantTitle')} />
                <CreateModalBody>
                    <div className="text-sm font-normal leading-5 mb-4 mt-2 mr-6">{t('tenants.addTenantHelp')}</div>
                    <div className="flex flex-wrap mb-6 items-end">
                        <div className="flex-1 text-sm name-input-container">
                            <TextInput
                                id="tenant-name-input"
                                name="tenant-name-input"
                                data-testid="tenant-name-input"
                                labelText={t('main.name')}
                                invalid={Boolean(inputErrors.name)}
                                invalidText={inputErrors.name}
                                value={(inputState.name as string) || ''}
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    inputActions.validateField({
                                        field: 'name',
                                        skipNull: true,
                                    });
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    let val = e.target.value || '';
                                    inputActions.setField('name', val);
                                }}
                            />
                            <span className="input-char-counter right-0">
                                {(inputState.name as string)?.length ?? 0} / 30
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-wrap mb-6">
                        <div className="flex-1 relative">
                            <Dropdown
                                id="tenant-ks-input"
                                name="tenant-ks-input"
                                titleText={t('application.keystoreInputLabel')}
                                label={t('main.chooseOption')}
                                onChange={({ selectedItem }: { selectedItem: { id: string; label: string } }) => {
                                    inputActions.setField('keystore', selectedItem.id);
                                }}
                                items={keystores.map((ks, i) => {
                                    return { id: ks.name, label: ks.name };
                                })}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap mb-6">
                        <div className="flex-1 relative">
                            <Dropdown
                                id="tenant-cmk-input"
                                name="tenant-cmk-input"
                                titleText={t('tenants.cmk')}
                                label={t('main.chooseOption')}
                                onChange={({ selectedItem }: { selectedItem: { id: string; label: string } }) => {
                                    inputActions.setField('cmk', selectedItem.id);
                                }}
                                items={cmks.map((cmk, i) => {
                                    return { id: cmk.name, label: cmk.name };
                                })}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap mb-6 items-end">
                        <div className="flex-1 text-sm name-input-container">
                            <Dropdown
                                id="tenant-dek-input"
                                name="tenant-dek-input"
                                titleText={t('tenants.dek')}
                                label={t('main.chooseOption')}
                                onChange={({ selectedItem }: { selectedItem: { id: string; label: string } }) => {
                                    inputActions.setField('dek', selectedItem.id);
                                }}
                                items={deks.map((dek, i) => {
                                    return { id: dek.name, label: dek.name };
                                })}
                            />
                        </div>
                    </div>
                </CreateModalBody>
            </CreateModal>
        </>
    );
};

export default AddTenantModal;
