import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { BaffleIconProps } from '../componentTypes';

const SignOutIcon = ({ color = '#868d95', ...rest }: BaffleIconProps) => {
    return (
        <SvgIcon {...rest}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-18.000000, -855.000000)" fill="#FB4B53">
                    <g transform="translate(18.000000, 855.000000)">
                        <g>
                            <path
                                d="M2,4 C3.82441266,1.5711066 6.72909671,0 10.0007324,0 C15.5235799,0 20.0007324,4.4771525 20.0007324,10 C20.0007324,15.5228475 15.5235799,20 10.0007324,20 C6.72909671,20 3.82441266,18.4288934 2,16 L3.97993497,16 C5.5189462,17.5443124 7.64823289,18.5 10.0007324,18.5 C14.6951527,18.5 18.5007324,14.6944204 18.5007324,10 C18.5007324,5.30557963 14.6951527,1.5 10.0007324,1.5 C7.64823289,1.5 5.5189462,2.45568756 3.97993497,4 L2,4 L2,4 Z"
                                fillRule="nonzero"></path>
                            <polygon
                                fillRule="nonzero"
                                points="15 9.16666667 3.19166667 9.16666667 6.175 6.175 5 5 0 10 5 15 6.175 13.825 3.19166667 10.8333333 15 10.8333333"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

export default SignOutIcon;
