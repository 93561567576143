import { makeObservable, observable, action } from 'mobx';
import {
    AppConfigs,
    ConfigIdentifierParams,
    DeploymentState,
    ConfigFileAssociations,
} from '@baffle/graphql/src/models';
import applicationConfigClient from '@baffle/api-client/src/applicationConfigClient';
import { RequestOpts } from '../types/StoreTypes';

interface ConfigForPayload extends ConfigIdentifierParams {
    config: string;
    deploymentState?: DeploymentState;
}

export const createFileNameKey = (fileName: string, shieldId?: string) => {
    return `${fileName}${shieldId ? `${shieldId}` : ''}`;
};

class AppConfigStore {
    loading: boolean = false;
    configs = observable<AppConfigs>({});
    fileNames = observable<ConfigFileAssociations>([]);

    constructor() {
        makeObservable(this, {
            setConfig: action,
        });
    }

    setConfig({ appId, fileName, shieldId, config }: ConfigForPayload) {
        const fileNameKey = createFileNameKey(fileName, shieldId);
        if (!this.configs[appId]) {
            this.configs[appId] = Object.assign({}, { [fileNameKey]: config ?? '' });
        } else {
            this.configs[appId][fileNameKey] = config ?? '';
        }
    }

    setFileNames(appId: string, incomingFiles: ConfigFileAssociations[]) {
        //explain that the FE has to compile data structures in order to present ui based on associations to appid, shieldid, etc
        //ideally the backend would deliver the associations in the payload
        const files = this.fileNames.slice().filter(f => f.appId !== appId);
        files.push(...incomingFiles);
        this.fileNames.replace(files);
    }

    async list(appId: string, opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first' && this.fileNames.filter(f => f.appId === appId).length) {
            return;
        }
        try {
            const fileNames = await applicationConfigClient.list(appId);
            this.setFileNames(appId, fileNames);
        } catch (error) {
            throw error;
        }
    }

    async read({ appId, fileName, shieldId }: ConfigIdentifierParams, opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first' && this.configs[appId] && this.configs[appId][fileName]) {
            return;
        }
        try {
            const config = await applicationConfigClient.read(appId, fileName, shieldId);
            this.setConfig({ appId, fileName, shieldId, config });
        } catch (error) {
            throw error;
        }
    }

    get({ appId, fileName, shieldId }: ConfigIdentifierParams) {
        const fileNameKey = createFileNameKey(fileName, shieldId);
        return this.configs[appId] ? this.configs[appId][fileNameKey] : undefined;
    }
    configFilesListByApp(appId: string): ConfigFileAssociations[] {
        return this.fileNames.filter(f => f.appId === appId);
    }
    async save({ appId, fileName, shieldId, config, deploymentState }: ConfigForPayload) {
        try {
            await applicationConfigClient.create(appId, { fileName, shieldId, config, deploymentState });
            this.setConfig({ appId, fileName, config, shieldId });
        } catch (error) {
            throw error;
        }
    }
}

export default AppConfigStore;
