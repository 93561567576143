import { t } from '@baffle/translate';
import React from 'react';
import { Link } from 'carbon-components-react';

export interface BaseProps {
    children: React.ReactNode | React.ReactFragment | React.ReactPortal | null | undefined;
}

interface Info {
    [key: string]: any;
}

export interface CertificateRowDetailsProps {
    info: Info;
    downloadCertificate: (link: string, fieldName: string) => void;
}

export const CertificateRowDetails = (props: CertificateRowDetailsProps) => {
    const { info, downloadCertificate } = props;
    return (
        <div className="flex-1 py-8">
            <div className="flex flex-wrap w-full gap-y-6">
                {info &&
                    Object.entries(info).map(([fieldName, fieldValue]) => (
                        <div className="w-1/2 flex flex-row gap-x-2" key={`certificate-info-${fieldName}`}>
                            <div className="text-gray-600 font-bold w-40">{t(`certificate.${fieldName}`)}</div>
                            <span>
                                {![null, undefined, ''].includes(fieldValue) ? (
                                    <>
                                        {fieldName === 'certDetails' ? (
                                            <Link
                                                className="cursor-pointer"
                                                onClick={() =>
                                                    downloadCertificate(fieldValue.link ?? '', fieldValue.name ?? '')
                                                }
                                                id="download-certificate-link">
                                                {fieldValue.name ?? ''}
                                            </Link>
                                        ) : (
                                            String(fieldValue)
                                        )}
                                    </>
                                ) : (
                                    <>- -</>
                                )}
                            </span>
                        </div>
                    ))}
            </div>
        </div>
    );
};
