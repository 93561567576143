import client from '.';
import { DatabaseInput, DatabasePayload, DatabasePayloadPUT, DatabaseTypeEnum } from '@baffle/graphql/src/models';
import { BaseCredentials } from '../../graphql/src/models/credentials';
import { RequestOpts } from '../../manager/src/types/StoreTypes';

export async function create(db: DatabaseInput) {
    const payload = serializeDatabasePayload(db);
    if (db.ssl) {
        return client('api/v1.0/database/ssl', {
            body: {
                cert: db.cert as File,
                data: payload,
            },
            useForm: true,
            method: 'POST',
        });
    } else {
        return client('api/v1.0/database', {
            body: payload,
            method: 'POST',
        });
    }
}

export async function update(db: DatabasePayloadPUT) {
    return client('api/v1.0/database', {
        body: db,
        method: 'PUT',
    });
}

const serializeDatabasePayload = (db: DatabaseInput) => {
    if (
        db.type !== DatabaseTypeEnum.POSTGRES &&
        db.type !== DatabaseTypeEnum.AWS_REDSHIFT &&
        db.type !== DatabaseTypeEnum.GREENPLUM
    ) {
        db.bsConfigClientDBName = '';
    }
    const port = parseInt((db.port as unknown) as string);
    if (isNaN(port)) {
        throw new Error('database.portInputInvalid');
    }
    const payload: DatabasePayload = {
        dbName: db.dbName,
        dbPass: db.dbPass,
        dbUser: db.dbUser,
        bsConfigClientDBName: db.bsConfigClientDBName,
        description: db.description,
        hostName: db.hostName,
        ssl: db.ssl,
        port: port,
        type: db.type,
    };
    return payload;
};

export async function updateCert(id: string, cert: File) {
    return client('api/v1.0/database/ssl/uploadCert', { body: { cert, id }, useForm: true, method: 'POST' });
}

export async function list() {
    return client(`api/v1.0/database/all`);
}

export async function read(id: string, opts: RequestOpts) {
    return client(`api/v1.0/database/${id}`);
}

export async function readGrants(applicationID: string, databases: string) {
    return client(`api/v1.0/database/${applicationID}/grant/check?databases=${databases}`);
}

export async function updateGrants(applicationID: string, databases: string, credentials: BaseCredentials) {
    return client(`api/v1.0/database/${applicationID}/grant`, {
        method: 'POST',
        queryParams: { databases },
        body: credentials,
    });
}

export async function getDatabaseTypes() {
    return client('api/v1.0/database/type');
}

export default {
    create,
    list,
    read,
    readGrants,
    update,
    updateCert,
    updateGrants,
    getDatabaseTypes,
};
