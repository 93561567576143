import { TYPE_NAMES } from '.';
import gql from 'graphql-tag';

export const ADD_DECRYPTION = gql`
    mutation AddDecryption($id: String!, $input: Object!) {
        decryption(id: $id, input: $input) @rest(type: "[${TYPE_NAMES.EncryptionDatabase}]", path: "application/decrypt/columns/{args.id}?isMigrationAllowed=true", method: "POST", endpoint: "v1") {
            id
        }
    }
`;

export const GET_APPLICATION_DECRYPTION_CONFIRMATION = gql`
    query GetApplicationDecryptionConfirmation($id: String!){
        application(id: $id) @rest(type: "${TYPE_NAMES.ApplicationDetails}", path: "application/{args.id}", endpoint: "v1") {
            id @export(as: "id")
            keystore @export(as: "keystoreId")
            db @export(as: "databaseId")
            appName
            migrations
                @rest(type: "${TYPE_NAMES.MigrationStatus}", path: "application/mgrstatus/{exportVariables.id}", endpoint: "v1") {
                status
                mgrTableList
            }
            databaseDetails(id: $id) @rest(type: "${TYPE_NAMES.Database}", path: "database/{exportVariables.databaseId}", endpoint: "v1") {
                id
                type
            }
        }
    }
`;
