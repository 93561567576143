import React, { useEffect, useState, InputHTMLAttributes, forwardRef } from 'react';
import cx from 'classnames';
import ErrorIcon from '@material-ui/icons/Error';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

export const getDefaultInputContainerProps: () => any = () => {
    return {
        className: 'w-full',
    };
};

export const getDefaultInputProps: () => any = () => {
    return {
        className:
            'bg-gray-10 shadow-sm focus:ring-blue focus:border-blue-60 focus:text-gray-700 block w-full text-xs pl-4 pr-8 py-2 rounded-none focus:rounded-none focus:outline-none',
    };
};

interface props extends Partial<InputHTMLAttributes<HTMLInputElement>> {
    labelHidden?: boolean;
    labelText?: string;
    className?: string;
    name: string;
    id?: string;
    invalid?: boolean;
    invalidText?: string;
    placeholder?: string;
    defaultValue?: string; //triggers an on change event when the component mounts
    getInputProps?: () => any;
    getInputContainerProps?: () => any;
}

const PasswordToggleInput = forwardRef<HTMLInputElement, props>(
    (
        {
            labelText = '',
            placeholder = '',
            className = '',
            labelHidden = false,
            name,
            invalid = false,
            invalidText = '',
            defaultValue = '',
            getInputProps = getDefaultInputProps,
            getInputContainerProps = getDefaultInputContainerProps,
            ...rest
        }: props,
        ref
    ) => {
        const { id = name } = rest;
        const { className: classNameFromInputProps, ...props } = getInputProps();
        const [showPassword, setShowPassword] = useState(false);

        useEffect(() => {
            //@ts-ignore
            //TODO: what type will recognize that ref.current is valid?
            if (Boolean(defaultValue) && ref?.current) {
                //@ts-ignore
                ref.current.value = defaultValue;
            }
        }, []);
        const labelClasses = cx({
            'mb-2 block text-xs text-gray-600': !labelHidden,
            'sr-only': labelHidden,
        });
        const inputClasses = cx(
            classNameFromInputProps,
            {
                'border-b border-gray-50': !invalid,
                'border-b border-red text-red placeholder-red': invalid,
            },
            className
        );
        const togglePasswordVisiblity = () => {
            setShowPassword(showPassword ? false : true);
        };
        return (
            <div {...getInputContainerProps()}>
                <div className="flex justify-between items-center">
                    <label htmlFor={name} className={labelClasses}>
                        {labelText}
                    </label>
                    {showPassword ? (
                        <VisibilityIcon
                            className="mb-2 text-sm text-gray-600 cursor-pointer"
                            onClick={togglePasswordVisiblity}
                        />
                    ) : (
                        <VisibilityOffIcon
                            className="mb-2 text-sm text-gray-600 cursor-pointer"
                            onClick={togglePasswordVisiblity}
                        />
                    )}
                </div>
                <div className="relative">
                    {invalid ? <ErrorIcon className="text-red w-4 h-4 absolute right-2 top-1/4" /> : null}
                    <input
                        ref={ref}
                        type={showPassword ? 'text' : 'password'}
                        className={inputClasses}
                        id={id}
                        placeholder={placeholder}
                        name={name}
                        {...rest}
                        {...props}
                    />
                </div>
                {invalid ? <p className="text-xs text-red">{invalidText}</p> : null}
            </div>
        );
    }
);

export default PasswordToggleInput;
