import React, { useState } from 'react';
import { t } from '@baffle/translate';
import { Search } from 'carbon-components-react';
import { MigrationStatus, MigrationStatusTable } from '@baffle/graphql/src/models';
import { stringSort, numberSort } from '@baffle/utilities/src/sorters';
import { sortGrid, SortInfo, SortDirection } from '@baffle/utilities/src/gridHelpers';
import usePageHeight from '@baffle/utilities/src/usePageHeight';
import ReactDataGrid from 'react-data-grid';
import TH from '@baffle/components/src/table/react-data-grid/TH';
import cx from 'classnames';
import shortid from 'shortid';
import { StatusMaps } from '@baffle/utilities/src/enums';
import { RequestStatus } from '@baffle/api-client/src/useAwait';
import Cell from '@baffle/components/src/table/react-data-grid/Cell';
import Status from '@baffle/components/src/status/Status';

interface Props {
    migrations: MigrationStatus | undefined;
    selectedTable: MigrationStatusTable | null;
    onRowClick: (row: any) => void;
    status: RequestStatus;
}

const MigrationDatabaseTable = ({ migrations, onRowClick, selectedTable, status }: Props) => {
    const pageHeight = usePageHeight();
    const rowHeight = 35;
    const pageHeightOffset = 260;
    const sizeOffset = pageHeightOffset + 18; //sets how much space is used to calculate empty rows
    const size = Math.floor((pageHeight - sizeOffset) / rowHeight);

    const initialDBGridSort: SortInfo = { direction: 'NONE', key: '' };
    const [sort, setSort] = useState(initialDBGridSort);

    const skeletonCol = {
        id: '',
        dbName: ' ',
        status: ' ',
        tableName: ' ',
    };

    const emptyCol = {
        id: '',
        dbName: '',
        status: '',
        tableName: '',
    };

    const initialResults: MigrationStatusTable[] = [];
    const [filtered, setFiltered] = useState({ results: initialResults, query: '' });
    const mgrTableList = migrations ? migrations.mgrTableList || {} : {};
    const tables: MigrationStatusTable[] =
        filtered.query !== ''
            ? filtered.results
            : Object.keys(mgrTableList).map((k: string) => {
                  const table = Object.assign({}, mgrTableList[k], { id: shortid.generate() });
                  return table;
              }) || [];
    const filterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = (e.target.value || '').toLowerCase();
        const results = tables.filter(
            (d: MigrationStatusTable) =>
                d.dbName.toLowerCase().includes(query) || d.tableName.toLowerCase().includes(query)
        );

        setFiltered({ results, query });
    };

    const CustomRowRenderer = ({
        //@ts-ignore
        renderBaseRow,
        ...canvasProps
    }) => {
        return (
            <div
                className={cx('', {
                    'text-gray-100 bg-teal-100':
                        selectedTable &&
                        canvasProps.row.dbName === selectedTable.dbName &&
                        canvasProps.row.tableName === selectedTable.tableName,
                })}>
                {renderBaseRow({ ...canvasProps })}
            </div>
        );
    };

    const cols = [
        {
            key: 'dbName',
            name: t('encryption.dbGrid.header1'),
            sortable: true,
            formatter: Cell,
            sortFn: (a: MigrationStatusTable, b: MigrationStatusTable) => stringSort(a.dbName, b.dbName),
            headerRenderer: <TH idPrefix="mgstatusdatabasetable" />,
        },
        {
            key: 'tableName',
            name: t('encryption.tableGrid.header1'),
            sortable: true,
            formatter: Cell,
            sortFn: (a: MigrationStatusTable, b: MigrationStatusTable) => stringSort(a.tableName, b.tableName),
            headerRenderer: <TH idPrefix="mgstatusdatabasetable" />,
        },
        {
            key: 'migrated',
            name: t('encryption.migration.migrated'),
            sortable: true,
            formatter: Cell,
            sortFn: (a: MigrationStatusTable, b: MigrationStatusTable) => numberSort(a.mgrPercent, b.mgrPercent),
            headerRenderer: <TH idPrefix="mgstatusdatabasetable" />,
        },
        {
            key: 'status',
            name: t('application.header2'),
            sortable: true,
            sortFn: (a: MigrationStatusTable, b: MigrationStatusTable) => stringSort(a.status, b.status),
            headerRenderer: <TH idPrefix="mgstatusdatabasetable" />,
        },
        {
            key: 'space',
            name: ' ',
            formatter: Cell,
            width: 1,
        },
    ];

    sortGrid(sort, cols, tables);

    const rows =
        status === 'idle' || status === 'loading'
            ? //add  skeleton row on initial load
              [...Array(Math.floor(1)).keys()].map(() => skeletonCol)
            : tables.map((d: MigrationStatusTable, i: number) => ({
                  id: d.id,
                  dbName: d.dbName,
                  tableName: d.tableName,
                  space: ' ',
                  migrated: `${d.mgrRows}/${d.totalRows}`,
                  idx: i,
                  selectedId: selectedTable ? selectedTable?.id : undefined,
                  status: (
                      <Status
                          title={t(`application.${StatusMaps.appStatusMap[d.status]}`)}
                          type={StatusMaps.statusIconMap[d.status]}
                      />
                  ),
                  table: d,
              }));

    //add empty rows to fill the page, if needed
    if (tables.length < size) {
        const sizeDelta = size - tables.length;
        //@ts-ignore
        rows.push(...[...Array(sizeDelta).keys()].map(() => emptyCol));
    }

    const minHeight = pageHeight - pageHeightOffset;
    return (
        <>
            <Search
                size="sm"
                closeButtonLabelText={t('main.ariaCloseSearch')}
                className="encryption-grid-filter mb2"
                labelText={t('encryption.migration.ariaSearchDbTable')}
                placeholder={t('encryption.migration.ariaSearchDbTable')}
                onChange={filterChange}
                id="mgstatus-databasetable-filter"
                autoComplete="none"
            />
            <div className="relative" style={{ minHeight }}>
                <div className="absolute w-full">
                    <ReactDataGrid
                        onRowClick={(idx: number, row: any) => {
                            if (row) {
                                onRowClick(row);
                            }
                        }}
                        // @ts-ignore
                        enableCellAutoFocus={false}
                        rowRenderer={CustomRowRenderer}
                        onGridSort={(key: string, direction: SortDirection) => setSort({ direction, key })}
                        columns={cols}
                        rowGetter={i => rows[i]}
                        rowsCount={rows.length}
                        minHeight={minHeight}
                    />
                </div>
            </div>
        </>
    );
};

export default MigrationDatabaseTable;
