import { Associations, Audit, DataProtectionItem, KeyValuePairsMap, RbacConfigV2 } from './encryption';
import { MigrationStatusTypes } from './migration';

export interface DatabaseProxyPayload {
    name: string;
    database: DataProtectionItem;
    keystore: DataProtectionItem;
}

export interface DatabaseProxy {
    name: string;
    id: string;
    associations: Associations[];
    audit: Audit;
    database: DataProtectionItem;
    keystore: DataProtectionItem;
    syncId: string;
    status: MigrationStatusTypes;
    protectedColumns: number;
}

export interface SslParameters {
    subjectName: string;
    subjectAlternativeName: string;
    expiryDate: number;
}

export interface DatabaseProxyShield {
    name: string;
    id: string;
    associations: Associations[];
    audit: Audit;
    hostname: string;
    port: number;
    status: string;
    sslParameters: SslParameters;
}

export interface DatabaseProxyConfiguration {
    debugLevel: string;
    rle: boolean;
    rbacConfig: RbacConfigV2;
    advancedConfig?: KeyValuePairsMap;
}

export interface AdvanceConfigurations {
    advancedConfig: KeyValuePairsMap;
}

export interface RbacConfigurations {
    rbacConfig: RbacConfigV2;
}

export interface AdvanceConfiguration {
    key: string;
    value: string;
}

export enum DebugLevel {
    NONE = 'None',
    INBOUND_SQL = 'Inbound SQL',
    TRANSFORMED_SQL = 'Transformed SQL',
    PACKET_LOGGING = 'Packet Logging',
}

export enum DefaultConfig {
    debugLevel = 'Debug Level',
    rle = 'RLE',
    rbacConfig = 'RBAC',
}

export interface JwtClaimInfo {
    key: string;
    value: string;
    jwtClaimData: string;
}

export type RbacConfigModes = 'Disabled' | 'Supported' | 'Required';

export enum RbacConfigMode {
    Disabled = 'DISABLED',
    Supported = 'SUPPORTED',
    Required = 'REQUIRED',
}

export type RbacConfigUserDeterminations = 'Session' | 'SQL_COMMENT_RAW' | 'SQL_COMMENT_JWT';

export enum RbacConfigUserDetermination {
    Session = 'SESSION',
    SQL_COMMENT_RAW = 'SQL_COMMENT_RAW',
    SQL_COMMENT_JWT = 'SQL_COMMENT_JWT',
}

export type Rle = 'Enable' | 'Disable';

export const RleToBoolMap: { [key in Rle]: boolean } = {
    Enable: true,
    Disable: false,
};
