import { TextInput } from 'carbon-components-react';
import { InputActions } from '@baffle/utilities/src/inputHooks';
import React from 'react';
import { t } from '@baffle/translate';
import { JwtClaimInfo } from '@baffle/graphql/src/models';
//@ts-ignore
import { Close } from '@carbon/icons-react';

interface JwtClaimsProps {
    inputActions: InputActions;
    jwtClaim: JwtClaimInfo;
    jwtClaims: Array<JwtClaimInfo>;
    index: number;
}

const JwtClaims = ({ inputActions, jwtClaim, jwtClaims, index }: JwtClaimsProps) => {
    const handleDeleteJwtClaim = (index: number) => {
        jwtClaims.splice(index, 1);
        inputActions.setField('jwtClaims', jwtClaims);
    };

    const handleUpdateJwtClaim = (index: number, val: string) => {
        const data = val.split(':');
        jwtClaims[index] = { key: data[0], value: data[1], jwtClaimData: val };
        inputActions.setField('jwtClaims', jwtClaims);
    };

    return (
        <div>
            <div className="flex flex-wrap items-end">
                <div className="flex-1 text-sm mb-2 sql-comment-prefix-input-container">
                    <TextInput
                        id="jwt-claim-input"
                        name="jwt-claim-input"
                        data-testid="jwt-claim-input"
                        labelText=""
                        placeholder={t('databaseProxies.databaseProxyConfigurations.jwtClaimPlaceholder')}
                        value={jwtClaim.jwtClaimData}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            inputActions.validateField({
                                field: 'jwtClaims',
                                skipNull: true,
                            });
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value || '';
                            handleUpdateJwtClaim(index, val);
                        }}
                    />
                </div>
                <div className="mb-4 ml-2 delete-container">
                    <button data-testid={`remove-jwt-claim-btn-${index}`} onClick={() => handleDeleteJwtClaim(index)}>
                        <Close size="22" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default JwtClaims;
