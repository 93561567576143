import useAwait from '@baffle/api-client/src/useAwait';
import Content from '@baffle/components/src/content/Content';
import { useManageModals } from '@baffle/components/src/modal/Modal';
import { Overview } from '@baffle/components/src/overview/Overview';
import PageHeader from '@baffle/components/src/pageheader/pageheader';
import { t } from '@baffle/translate';
import { Observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DeleteRbacPolicyModal from './DeleteRbacPolicy';
import EditRbacPolicyModal from './EditRbacPolicyModal';
import { RbacPolicyStore } from '@baffle/manager/src/stores';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';
import DataGrid from '@baffle/components/src/grid/data-grid';
import { DataProtectionItem, RbacPolicyRuleV2, RbacRuleOrder } from '@baffle/graphql/src/models';
import usePageHeight from '@baffle/utilities/src/usePageHeight';
import AddRbacRuleModal from './AddRbacRuleModal';
import DeleteRbacPolicyRuleModal from './DeleteRbacPolicyRuleModal';
import rbacPolicyClient from '@baffle/api-client/src/rbacPolicyClient';
import { Link } from 'carbon-components-react';

const RbacPolicyDetails = () => {
    const history = useHistory();
    let { id } = useParams() as { id: string };
    const pageHeight = usePageHeight() - 196;
    const [deleteRule, setDeleteRule] = useState({ name: '', id: '' });
    const [saveValue, setSaveValue] = useState(false);
    if (!id) {
        history.push('/rbac');
        return null;
    }

    const { run } = useAwait();

    useEffect(() => {
        run(
            RbacPolicyStore.getSelectedRbacPolicy(id).then(() => {
                run(RbacPolicyStore.readRbacRules(RbacPolicyStore.selectedRbacPolicy?.id as string));
            })
        );
        run(RbacPolicyStore.readRbacPolicies());
    }, []);

    interface RbacPolicyModals {
        editRbacPolicy: boolean;
        deleteRbacPolicy: boolean;
    }
    const initialModalState = {
        editRbacPolicy: false,
        deleteRbacPolicy: false,
    };
    interface RbacPolicyRulesModals {
        addRbacPolicyRule: boolean;
        editRbacPolicyRule: boolean;
        deleteRbacPolicyRule: boolean;
    }
    const initialRulesModalState = {
        addRbacPolicyRule: false,
        editRbacPolicyRule: false,
        deleteRbacPolicyRule: false,
    };
    const [modals, setModals] = useManageModals<RbacPolicyModals>(initialModalState);
    const [rulesModals, setRulesModals] = useManageModals<RbacPolicyRulesModals>(initialRulesModalState);
    const onSelectAction = (item: any) => {
        switch (item.selectedItem.label) {
            case 'Edit':
                setModals({ editRbacPolicy: true });
                break;
            case 'Delete':
                setModals({ deleteRbacPolicy: true });
                break;
            default:
                break;
        }
    };

    const breadcrumbData = [
        {
            label: t('main.rbac'),
            href: '/rbac',
        },
    ];
    const actions = [
        {
            id: '1',
            label: t('main.edit'),
        },
        {
            id: '2',
            label: t('main.delete'),
        },
    ];

    const headers = [
        {
            key: 'header1',
            header: t('rbac.rbacPolicy.rulesHeader1'),
            sortable: false,
        },
        {
            key: 'header2',
            header: t('rbac.rbacPolicy.rulesHeader2'),
            sortable: false,
        },
        {
            key: 'header3',
            header: t('rbac.rbacPolicy.rulesHeader3'),
            sortable: false,
        },
        {
            key: 'header4',
            header: t('rbac.rbacPolicy.rulesHeader4'),
            sortable: false,
        },
    ];

    return (
        <Observer>
            {() => {
                const selectRbacPolicy = RbacPolicyStore.selectedRbacPolicy;
                const rules = RbacPolicyStore.selectedRbacPolicyRules as RbacPolicyRuleV2[];

                const rbacPolicyRules: RbacPolicyRuleV2[] = RbacPolicyStore.rbacPolicyRules;
                const rows =
                    rbacPolicyRules.length == 0
                        ? []
                        : rbacPolicyRules.map((rule: RbacPolicyRuleV2, i: number) => {
                              return {
                                  id: (rule.order + rule.name) as string,
                                  header1: rule.name,
                                  header2: rule.userGroups?.map((userGroup: DataProtectionItem, i: number) => {
                                      return userGroup.name + ' ';
                                  }),
                                  header3: rule.permission,
                                  header4: (
                                      <Link target="_blank" href={'/masking-policies/' + rule.maskPolicy?.id}>
                                          {rule.maskPolicy?.name}
                                      </Link>
                                  ),
                                  overFlowMenu: [
                                      {
                                          name: t('main.edit'),
                                          onClick: () => {
                                              RbacPolicyStore.setSelectedRbacRule(rule);
                                              setRulesModals({ addRbacPolicyRule: true, editRbacPolicyRule: true });
                                          },
                                      },
                                      {
                                          name: t('rbac.rbacPolicy.moveToTop'),
                                          onClick: () => {
                                              RbacPolicyStore.moveTopRbacRule(i);
                                          },
                                      },
                                      {
                                          name: t('rbac.rbacPolicy.moveUp'),
                                          onClick: () => {
                                              RbacPolicyStore.moveUpRbacRule(i);
                                          },
                                      },
                                      {
                                          name: t('rbac.rbacPolicy.moveDown'),
                                          onClick: () => {
                                              RbacPolicyStore.moveDownRbacRule(i);
                                          },
                                      },
                                      {
                                          name: t('rbac.rbacPolicy.moveToBottom'),
                                          onClick: () => {
                                              RbacPolicyStore.moveBottomRbacRule(i);
                                          },
                                      },
                                      {
                                          name: t('main.delete'),
                                          onClick: () => {
                                              setDeleteRule({ name: rule.name, id: rule.id });
                                              setRulesModals({ deleteRbacPolicyRule: true });
                                          },
                                      },
                                  ],
                              };
                          });

                const onAdd = () => {
                    setRulesModals({ addRbacPolicyRule: true });
                };

                const onSave = () => {
                    let payload: RbacRuleOrder[] = rbacPolicyRules.map((rule: RbacPolicyRuleV2, i: number) => {
                        RbacPolicyStore.setRbacRuleOrder(i);
                        return {
                            id: rule.id,
                            order: rule.order,
                        };
                    });
                    run(
                        rbacPolicyClient.updateRbacRulesList(id, payload).then(() => {
                            NotificationStore.push({
                                title: t('rbac.rbacPolicy.updateRbacRulesListSuccess'),
                                kind: 'success',
                                'data-testid': 'rbac-rules-list-update-success',
                            });
                            run(RbacPolicyStore.readRbacRules(id));
                        })
                    );
                };

                if (JSON.stringify(rbacPolicyRules) != JSON.stringify(rules)) {
                    setSaveValue(true);
                } else {
                    setSaveValue(false);
                }

                return (
                    <>
                        {selectRbacPolicy ? (
                            <Content>
                                <PageHeader
                                    title={selectRbacPolicy.name}
                                    actions={actions}
                                    breadcrumbData={breadcrumbData}
                                    onActionSelect={onSelectAction}
                                />
                                <div className="px-10 mt-4 text-sm">
                                    <div
                                        className={
                                            'pb-2 pl-2 w-40 border-solid border-b-2 border-blue-60 cursor-pointer'
                                        }>
                                        {t('rbac.rbacPolicy.overview')}
                                    </div>
                                </div>

                                <div className="overflow-y-auto" style={{ height: pageHeight }}>
                                    <div className="px-5">
                                        <Overview
                                            title={t('rbac.rbacPolicy.rbacPolicyOverviewHeading')}
                                            info={RbacPolicyStore.getRbacPolicyOverview(selectRbacPolicy)}
                                            topic="rbac.rbacPolicy"
                                        />
                                    </div>
                                    <div className="flex-1 mb-6 px-10 flex justify-between">
                                        <div className="flex-1 flex items-center">
                                            <h3 className="text-xl">{t('rbac.rbacPolicy.rules')}</h3>
                                        </div>
                                    </div>

                                    <div className="mb-12">
                                        <DataGrid
                                            idPrefix="rbacPolicyRules"
                                            rows={rows}
                                            headers={headers}
                                            showSearch={true}
                                            onAdd={onAdd}
                                            onSave={onSave}
                                            addButtonCaption={t('rbac.rbacPolicy.addRuleButtonText')}
                                            saveButtonCaption={
                                                saveValue ? t('rbac.rbacPolicy.saveOrderButtonText') : undefined
                                            }></DataGrid>
                                    </div>
                                </div>

                                {modals.editRbacPolicy ? (
                                    <EditRbacPolicyModal
                                        open={modals.editRbacPolicy}
                                        onClose={() => setModals({ editRbacPolicy: false })}
                                        id={id}
                                        onSuccess={(name: string) => {
                                            NotificationStore.push({
                                                title: t('rbac.rbacPolicy.editRbacPolicySuccess', { name: name }),
                                                kind: 'success',
                                                'data-testid': 'rbac-policy-update-success',
                                            });
                                            run(RbacPolicyStore.getSelectedRbacPolicy(id));
                                        }}
                                    />
                                ) : null}

                                {modals.deleteRbacPolicy ? (
                                    <DeleteRbacPolicyModal
                                        open={modals.deleteRbacPolicy}
                                        onClose={() => setModals({ deleteRbacPolicy: false })}
                                        id={id}
                                        name={selectRbacPolicy.name}
                                        onSuccess={(name: string) => {
                                            NotificationStore.push({
                                                title: t('rbac.rbacPolicy.deleteRbacPolicySuccess', { name: name }),
                                                kind: 'success',
                                                'data-testid': 'rbac-policy-delete-success',
                                            });
                                        }}
                                    />
                                ) : null}

                                {rulesModals.addRbacPolicyRule ? (
                                    <AddRbacRuleModal
                                        open={rulesModals.addRbacPolicyRule}
                                        onClose={() => {
                                            setRulesModals({ addRbacPolicyRule: false, editRbacPolicyRule: false });
                                        }}
                                        onSuccess={() => {
                                            setRulesModals({ addRbacPolicyRule: false, editRbacPolicyRule: false });
                                            run(RbacPolicyStore.readRbacRules(id));
                                            run(RbacPolicyStore.getSelectedRbacPolicy(id));
                                        }}
                                        rules={rules}
                                        editMode={rulesModals.editRbacPolicyRule}
                                        selectedRbacPolicyId={id}
                                        datatypeGroup={selectRbacPolicy.datatypeGroup}
                                    />
                                ) : null}

                                {rulesModals.deleteRbacPolicyRule ? (
                                    <DeleteRbacPolicyRuleModal
                                        open={rulesModals.deleteRbacPolicyRule}
                                        name={deleteRule.name}
                                        onClose={() => {
                                            setRulesModals({ deleteRbacPolicyRule: false });
                                        }}
                                        onSubmit={() => {
                                            RbacPolicyStore.deleteRbacPolicyRule(deleteRule.id).then(() => {
                                                run(RbacPolicyStore.readRbacRules(id));
                                                run(RbacPolicyStore.getSelectedRbacPolicy(id));
                                                setRulesModals({ deleteRbacPolicyRule: false });
                                                NotificationStore.push({
                                                    title: t('rbac.rbacPolicy.deleteRbacRuleSuccess', {
                                                        name: deleteRule.name,
                                                    }),
                                                    kind: 'success',
                                                    'data-testid': 'rbac-rule-delete-success',
                                                });
                                            });
                                        }}
                                    />
                                ) : null}
                            </Content>
                        ) : null}
                    </>
                );
            }}
        </Observer>
    );
};

export default RbacPolicyDetails;
