import React, { useState, useEffect } from 'react';
import { Observer } from 'mobx-react-lite';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';
import './Notification.scss';
import { ToastNotification } from 'carbon-components-react';

type NotificationType = 'info' | 'success' | 'error';

export type GlobalNotificationState = {
    title: string;
    kind: NotificationType;
    description?: string;
    timeout?: number;
    'data-testid'?: string;
};

const GlobalNotification = () => {
    return (
        <Observer>
            {() => {
                const [dismiss, setDismiss] = useState(false);

                const [notification, setNotification] = useState<Partial<GlobalNotificationState>>({});
                const { kind, title, timeout = 3000, description } = notification;

                const doDismiss = () => {
                    setDismiss(true);
                    setNotification({});
                };

                useEffect(() => {
                    if (NotificationStore.size > 0) {
                        setNotification(NotificationStore.shift() as GlobalNotificationState);
                        setDismiss(false);
                    }
                    const dismissTimeout = setTimeout(() => {
                        if (kind != 'error') {
                            doDismiss();
                        }
                    }, timeout);
                    return () => {
                        clearTimeout(dismissTimeout);
                    };
                }, [NotificationStore.size]);

                if (dismiss || !Boolean(title)) {
                    return <div className="hidden" />;
                }

                return (
                    <ToastNotification
                        data-testid={notification['data-testid'] ?? 'global-notification'}
                        className={`${!description ? 'notification' : ''} top-14 fixed right-0 z-50`}
                        kind={kind}
                        onCloseButtonClick={doDismiss}
                        onClose={doDismiss}
                        title={title}
                        timeout={kind == 'error' ? undefined : timeout}
                        subtitle={description}
                    />
                );
            }}
        </Observer>
    );
};

export default GlobalNotification;
