import {
    ApplicationBindingDTO,
    ApplicationEncryptionPayload,
    ColumnMetaData,
    CryptoActionType,
    DeploymentState,
    EncryptionSchema,
    SelectedColumn,
} from '@baffle/graphql/src/models';
import getPath from '../column/getPath';

export const compileColumnDTOPayload = (
    cryptoAction: CryptoActionType,
    schema: EncryptionSchema,
    selectedColumns: ColumnMetaData[],
    deploymentState: DeploymentState
) => {
    let columns = [...(schema?.columns ?? [])];
    const columnsToRemove: ColumnMetaData[] = [];
    columns.forEach(c => {
        if (c.__typename) {
            delete c.__typename;
        }
        if (c.deploymentState === 'save_draft' && selectedColumns.findIndex(sc => getPath(sc) === getPath(c)) === -1) {
            //remove draft from schema because user unselected it
            columnsToRemove.push(c);
        }
        //set all the migration in the existing schema to none initially
        c.migration = 'None';
    });

    //remove draft from schema because user unselected it
    for (let index = 0; index < columnsToRemove.length; index++) {
        const col = columnsToRemove[index];
        const colIndex = columns.findIndex(c => getPath(c) === getPath(col));
        columns.splice(colIndex, 1);
    }

    //replace or push selected column to the schema
    for (let i = 0; i < selectedColumns.length; i++) {
        const c = selectedColumns[i];
        const selectedCol = Object.assign({
            name: c.name,
            application: c.application,
            database: c.database,
            dbName: c.dbName,
            scName: c.scName,
            table: c.table,
            tableType: c.tableType,
            encryption: cryptoAction === 'encryption' ? true : false,
            dataType: c.dataType,
            touched: c.touched,
            policy: c.policy,
            deploymentState: c.deploymentState,
            encSupport: c.encSupport,
            keyID: c.keyID,
            migration: c.migration,
            charset: c.charset,
            collation: c.collation,
            defaultValue: c.defaultValue,
            primaryKey: c.primaryKey,
            nullable: Boolean(c.nullable),
            new: c.new,
            ...(cryptoAction === 'decryption' ? { decryptionDeploymentState: deploymentState } : {}),
            policies: c.policies,
            encryptionModes: c.encryptionModes,
            maskingModes: c.maskingModes,
            jsonPolicies: c.jsonPolicies,
            selections: c.selections,
        }) as ColumnMetaData;
        //override any properties as needed
        switch (cryptoAction) {
            case 'encryption':
                switch (deploymentState) {
                    case 'save_deploy':
                        selectedCol.touched = true;
                        selectedCol.migration = 'None';
                        break;
                    case 'save_migrate':
                        selectedCol.touched = true;
                        selectedCol.migration = 'MGR_ENC';
                        break;
                    case 'save_draft':
                        selectedCol.touched = false;
                        selectedCol.migration = 'None';
                        break;
                    default:
                        selectedCol.touched = false;
                }
                break;
            case 'decryption':
                switch (deploymentState) {
                    case 'save_deploy':
                        selectedCol.touched = false;
                        selectedCol.migration = 'None';
                        break;
                    case 'save_migrate':
                        selectedCol.touched = false;
                        selectedCol.migration = 'MGR_CLEAR';
                        break;
                    case 'save_draft':
                        selectedCol.touched = true;
                        selectedCol.migration = 'None';
                        break;
                    default:
                        selectedCol.touched = true;
                }
            default:
                break;
        }
        //replace existing columns or add to the schema
        const existingIndex = columns.findIndex(
            col =>
                col.name === selectedCol.name &&
                col.database === selectedCol.database &&
                col.table === selectedCol.table
        );
        if (existingIndex > -1) {
            columns[existingIndex] = selectedCol;
        } else {
            columns.push(selectedCol);
        }
    }

    return columns;
};

type ActionType = 'checkDeployAndMigrate' | 'createSchema';

export const compileEncryptionPayload = (
    deploymentState: DeploymentState,
    schema: EncryptionSchema,
    selectedColumns: SelectedColumn[],
    id: string,
    cryptoAction: CryptoActionType,
    action: ActionType,
    migrationShield?: string
) => {
    const applicationBindingDTO: ApplicationBindingDTO = {
        //migration is set by selected column resolvers in resolvers.ts
        columns: compileColumnDTOPayload(cryptoAction, schema, selectedColumns, deploymentState),
        id,
    };

    const payload: ApplicationEncryptionPayload = {
        id,
        applicationBindingDTO,
        ...(action === 'createSchema' ? { isDataSync: false, deploymentState } : {}),
    };
    //only add migration shield if it is specificied and the deploymentState is save_migrate
    if (migrationShield !== '' && deploymentState === 'save_migrate') {
        payload.applicationBindingDTO.mgrShieldId = migrationShield;
    }
    return payload;
};

export default { compileColumnDTOPayload, compileEncryptionPayload };
