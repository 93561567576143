import gql from 'graphql-tag';

export const makeGql = (gqlText: string) => {
    return gql(gqlText);
};

export const MAX_PAGE_SIZE = 2147483647;

/**
 * types
 */

export const TYPE_NAMES = {
    AdminUser: 'AdminUser',
    Tenant: 'Tenant',
    TLS: 'TLS',
    InitialKeystore: 'InitialKeystore',
    InitialConfiguration: 'InitialConfiguration',
    InitialRegister: 'InitialRegister',
    SystemInfo: 'SystemInfo',
    SystemInfoEmail: 'SystemInfoEmail',
    SystemInfoRes: 'SystemInfoRes',
    AddRotateKeyRes: 'AddRotateKeyRes',
    ApplicationAccess: 'ApplicationAccess',
    CurrentUser: 'CurrentUser',
    Roles: 'Roles',
    ChangePassword: 'ChangePassword',
    EncryptionSchema: 'EncryptionSchema',
    MigrationStatus: 'MigrationStatus',
    MigrationPlan: 'MigrationPlan',
    ApplicationBindingDTO: 'ApplicationBindingDTO',
    EncryptionDatabase: 'EncryptionDatabase',
    EncryptionDatabaseTable: 'EncryptionDatabaseTable',
    ColumnMetaData: 'ColumnMetaData',
    Application: 'Application',
    ApplicationPost: 'ApplicationPost',
    ApplicationDetails: 'ApplicationDetails',
    CloudTemplateName: 'CloudTemplateName',
    SubnetList: 'SubnetList',
    VpcList: 'VpcList',
    DatabaseListItem: 'DatabaseListItem',
    Database: 'Database',
    DatabaseGrants: 'DatabaseGrants',
    KeynameList: 'KeynameList',
    CloudTemplate: 'CloudTemplate',
    ShieldListItem: 'ShieldListItem',
    Shield: 'Shield',
    ShieldConfig: 'ShieldConfig',
    Report: 'Report',
    ReportsResponse: 'ReportsResponse',
    Keystore: 'Keystore',
    KSNamespace: 'KSNamespace',
    DatabaseCount: 'DatabaseCount',
    EncryptionKey: 'EncryptionKey',
    AWSCloudTemplate: 'AWSCloudTemplate',
    SecurityGroup: 'SecurityGroup',
    SSH: 'SSH',
    VALID_SSH: 'VALID_SSH',
    ApplicationDetailsShield: 'ApplicationDetailsShield',
};

/**
 * fragments
 */
export const COLUMN = {
    fragments: {
        column: gql`
            fragment Column on ColumnMetaData {
                name
                dataType
                touched
                policy
                encMode
                deterministic
                encSupport
                keyID
                migration
                defaultValue
                primaryKey
                nullable
            }
        `,
    },
};

/**
 * user queries
 */

export const GET_CURRENT_USER = gql`
    query CurrentUser {
        currentUser @rest(type: "[${TYPE_NAMES.CurrentUser}]", path: "user", endpoint: "v1") {
            id
            title
            firstName
            lastName
            suffix
            email
            phone
            password
            countryCode
            roles
            active
        }
    }
`;

export const ADD_ROLES = gql`
    mutation AddRoles($input: Object!) {
        roles(input: $input) @rest(type: "${TYPE_NAMES.Roles}", path: "user/role", method: "put", endpoint: "v1") {
            NoResponse
        }
    }
`;

export const UPDATE_PASSWORD = gql`
    mutation UpdatePassword($input: Object!) {
        password(input: $input) @rest(type: "${TYPE_NAMES.ChangePassword}", path: "user/password", method: "put", endpoint: "v1") {
            NoResponse
        }
    }
`;
