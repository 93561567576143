import React, { useEffect } from 'react';
import TextInput from '@baffle/components/src/forms/TextInput';
import { FileUploader } from 'carbon-components-react';
import { t } from '@baffle/translate';
const AddSafeNetKeystore = ({ inputState, inputErrors, inputActions }: any) => {
    useEffect(() => {
        inputActions.setField('hostPort', 5696);
    }, []);
    return (
        <>
            <div className="flex flex-wrap mb-6 items-start">
                <div className="flex-1 pr-4">
                    <div className="flex flex-wrap items-start">
                        <div className="flex-1 flex items-center">
                            <TextInput
                                name="ks-hostname-input"
                                labelText={t('database.hostNameInputLabel')}
                                invalid={Boolean(inputErrors.hostName)}
                                invalidText={t('database.hostNameInputInvalid')}
                                placeholder={t('database.hostNamePlaceholder')}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    inputActions.setField('hostName', e.target.value.trim() || '')
                                }
                                onBlur={() => {
                                    inputActions.validateField({ field: 'hostName', skipNull: true });
                                }}
                            />
                            <div className="ml-2 w-24">
                                <TextInput
                                    name="ks-hostport-input"
                                    labelText={t('keystore.hostPortInputLabel')}
                                    invalid={Boolean(inputErrors.port)}
                                    invalidText={t('keystore.hostPortInputInvalid')}
                                    placeholder={t('keystore.hostPortPlaceholder')}
                                    value={inputState.hostPort || 0}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        inputActions.setField('hostPort', parseInt(e.target.value))
                                    }
                                    onBlur={() => {
                                        inputActions.validateField({ field: 'hostPort', skipNull: true });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-1 px-4" />
            </div>
            <div className="flex flex-wrap mb-6 items-start">
                <div className="flex-1 pr-4">
                    <TextInput
                        name="ks-hostuser-input"
                        labelText={t('keystore.hostUserInputLabel')}
                        invalidText={t('keystore.hostUserInputInvalid')}
                        placeholder={t('keystore.hostUserPlaceholder')}
                        type="text"
                        defaultValue={inputState.hostUser}
                        invalid={Boolean(inputErrors.hostUser)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputActions.setField('hostUser', e.target.value.trim() || '')
                        }
                        onBlur={() => {
                            inputActions.validateField({ field: 'hostUser', skipNull: true });
                        }}
                    />
                </div>
                <div className="flex-1 pl-4">
                    <TextInput
                        name="ks-hostpassword-input"
                        labelText={t('keystore.hostPasswordInputLabel')}
                        invalidText={t('keystore.hostPasswordInputInvalid')}
                        placeholder={t('keystore.hostPasswordPlaceholder')}
                        type="password"
                        defaultValue={inputState.hostPassword}
                        invalid={Boolean(inputErrors.hostPassword)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputActions.setField('hostPassword', e.target.value.trim() || '')
                        }
                        onBlur={() => {
                            inputActions.validateField({ field: 'hostPassword', skipNull: true });
                        }}
                    />
                </div>
            </div>
            <div className="flex flex-wrap mb-6 items-start">
                <div className="flex-1 pr-4">
                    <FileUploader
                        aria-label={t('keystore.certInputLabel')}
                        buttonLabel={t('keystore.addCertBtn')}
                        accept={['.jks']}
                        name="ks-cert-input"
                        id="ks-cert-input"
                        filenameStatus="edit"
                        buttonKind="tertiary"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputActions.setField('cert', e.target.files ? e.target.files[0] : null)
                        }
                    />
                </div>
                <div className="flex-1 pl-4">
                    <TextInput
                        name="ks-keystorepassword-input"
                        labelText={t('keystore.keyStorePasswordInputLabel')}
                        invalidText={t('keystore.keyStorePasswordInputInvalid')}
                        placeholder={t('keystore.keyStorePasswordPlaceholder')}
                        type="password"
                        defaultValue={inputState.keyStorePassword}
                        invalid={Boolean(inputErrors.keyStorePassword)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputActions.setField('keyStorePassword', e.target.value.trim() || '')
                        }
                        onBlur={() => {
                            inputActions.validateField({ field: 'keyStorePassword', skipNull: true });
                        }}
                    />
                </div>
            </div>
            <div className="flex flex-wrap items-start">
                <div className="flex-1 pr-4">
                    <TextInput
                        name="ks-keystore-alias-input"
                        labelText={t('keystore.keyStoreAliasInputLabel')}
                        placeholder={t('keystore.keyStoreAliasPlaceholder')}
                        type="text"
                        invalid={Boolean(inputErrors.keyStoreAlias)}
                        invalidText={t('keystore.keyStoreAliasInputInvalid')}
                        defaultValue={inputState.keyStoreAlias}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputActions.setField('keyStoreAlias', e.target.value.trim() || '')
                        }
                        onBlur={() => {
                            inputActions.validateField({ field: 'keyStoreAlias', skipNull: true });
                        }}
                    />
                </div>
                <div className="flex-1 pl-4">
                    <TextInput
                        name="ks-appnamespace-input"
                        labelText={t('keystore.appNamespaceInputLabel')}
                        placeholder={t('keystore.appNamespacePlaceholder')}
                        type="text"
                        invalid={Boolean(inputErrors.appNamespace)}
                        invalidText={t('keystore.appNamespaceInputInvalid')}
                        defaultValue={inputState.appNamespace}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputActions.setField('appNamespace', e.target.value.trim() || '')
                        }
                        onBlur={() => {
                            inputActions.validateField({ field: 'appNamespace', skipNull: true });
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default AddSafeNetKeystore;
