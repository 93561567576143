import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { BaffleIconProps } from '../componentTypes';

const BaffleShieldIcon = ({ color = '#868d95', ...rest }: BaffleIconProps) => {
    return (
        <SvgIcon {...rest}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-16.000000, -275.000000)" fill={color} fillRule="nonzero">
                    <g transform="translate(19.000000, 275.000000)">
                        <path d="M17.9941113,2.83522347 C12.9462985,2.29883875 9.36714315,0.0766208442 8.99997879,0 C8.63288415,0.0766208442 5.05358936,2.29883875 0.00589275881,2.83522347 L0.00589275881,11.6474549 C0.00589275881,11.6474549 -0.544760827,15.938649 8.99997879,20 C18.5446255,15.938649 17.9941113,11.6474549 17.9941113,11.6474549 L17.9941113,2.83522347 Z M16.4941113,11.6474549 L16.5063046,11.8383245 C16.5020296,11.8017403 16.5017419,11.7992786 16.5005744,11.783576 C16.502522,11.8423198 16.490366,11.9718053 16.4409772,12.1688865 C16.3459629,12.5480314 16.1523113,12.9840399 15.8266454,13.4710133 C14.826803,14.9660936 12.8858809,16.5228175 9.73344027,18.0253256 C9.49513692,18.1389051 9.25067005,18.251712 8.99997591,18.3637142 C8.74927786,18.2517113 8.50480734,18.1389038 8.26650056,18.0253236 C5.11407093,16.5228331 3.17316292,14.9661291 2.17333773,13.471069 C1.84767635,12.9841006 1.6540293,12.5480956 1.55901931,12.1689522 C1.5085861,11.9676953 1.49697872,11.8369222 1.49946762,11.7840254 L1.49881495,11.7915453 C1.49799975,11.8015286 1.49714755,11.8088196 1.49369336,11.8383721 L1.50589276,11.6474549 L1.50589276,4.14449396 C3.12872548,3.87569235 4.68870028,3.44190918 6.19287345,2.87941058 C7.0615777,2.55455108 7.83430656,2.21744917 8.62551775,1.83620598 C8.71242077,1.79433198 8.86169595,1.72125629 8.99998881,1.65333464 C9.13827197,1.72125154 9.28754348,1.79432553 9.37445655,1.83620461 C10.1656811,2.21745629 10.9383886,2.55455182 11.8070836,2.87940963 C13.3112598,3.44191248 14.8712467,3.87569694 16.4941113,4.14449802 L16.4941113,11.6474549 Z"></path>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

export default BaffleShieldIcon;
