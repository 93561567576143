import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { BaffleIconProps } from '../componentTypes';

const DataBaseIcon = ({ color = '#868d95', ...rest }: BaffleIconProps) => {
    return (
        <SvgIcon {...rest}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-18.000000, -105.000000)" stroke={color} strokeWidth="1.5">
                    <g id="Group-2" transform="translate(18.000000, 105.000000)">
                        <ellipse id="Oval" cx="10" cy="3.33333333" rx="9.25" ry="2.58333333"></ellipse>
                        <path
                            d="M19.1666667,14.845679 C19.1666667,13.1581423 15.0626102,11.7901235 10,11.7901235 C4.93738979,11.7901235 0.833333333,13.1581423 0.833333333,14.845679"
                            id="Oval-Copy-9"
                            transform="translate(10.000000, 13.317901) scale(1, -1) translate(-10.000000, -13.317901) "></path>
                        <path
                            d="M19.1666667,10.5555556 C19.1666667,8.86801882 15.0626102,7.5 10,7.5 C4.93738979,7.5 0.833333333,8.86801882 0.833333333,10.5555556"
                            id="Oval-Copy-11"
                            transform="translate(10.000000, 9.027778) scale(1, -1) translate(-10.000000, -9.027778) "></path>
                        <path
                            d="M19.1666667,19.3518519 C19.1666667,11.834901 19.1666667,7.51391338 19.1666667,6.38888889 C19.1666667,4.70135215 15.0626102,3.33333333 10,3.33333333 C4.93738979,3.33333333 0.833333333,4.70135215 0.833333333,6.38888889 L0.833333333,19.3518519"
                            id="Oval-Copy-10"
                            transform="translate(10.000000, 11.342593) scale(1, -1) translate(-10.000000, -11.342593) "></path>
                    </g>
                </g>
            </g>{' '}
        </SvgIcon>
    );
};

export default DataBaseIcon;
