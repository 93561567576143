import { useState, useCallback, useRef, useEffect } from 'react';

export type RequestStatus = 'loading' | 'success' | 'error' | 'idle';

interface Results {
    data: any;
    error: Error | null;
    status: RequestStatus;
    violations?: [{ fieldName: string; message: string }] | null;
}

export type RunOptions = {
    skipStatus?: boolean; // allows the setting of status to be skipped, if UI just needs to reload without showing loading state
};

const initialResults: Results = {
    data: null,
    error: null,
    status: 'idle',
    violations: null,
};

export default function useAwait() {
    const [results, setResults] = useState<Results>(initialResults);
    const prevResults = useRef<Results>(results);
    const cancel = useRef(false);

    //allows consumer to control when to run the promise
    const run = useCallback((promise, { skipStatus }: RunOptions = { skipStatus: false }) => {
        const loadingResults = {
            ...initialResults,
            data: null,
            status: skipStatus ? prevResults.current.status : 'loading',
        };
        setResults(loadingResults);
        prevResults.current = loadingResults;
        promise.then(
            (data: any) => {
                if (cancel.current) {
                    return;
                }
                const successResults = {
                    ...initialResults,
                    data,
                    status: skipStatus ? prevResults.current.status : 'success',
                };
                setResults(successResults);
                prevResults.current = successResults;
            },
            (error: Error) => {
                if (cancel.current) {
                    return;
                }
                let violations;
                if (error?.message && error.message.includes('violations')) {
                    violations = JSON.parse(error.message).violations;
                    error.message = '';
                }

                if (error?.message && error.message.includes('errors')) {
                    error.message = JSON.parse(error.message).errors.join(', ');
                }

                const errorResults = {
                    ...initialResults,
                    error,
                    status: skipStatus ? prevResults.current.status : 'error',
                    ...(violations ? { violations } : {}),
                };
                setResults(errorResults);
                prevResults.current = errorResults;
            }
        );
    }, []);
    useEffect(() => {
        return () => {
            cancel.current = true;
        };
    }, []);

    const reset = () => {
        setResults(initialResults);
    };

    return { ...results, run, reset };
}
