import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import cx from 'classnames';
import { BaseCompoundComponentProps } from '../types/react';

type AlertType = 'danger' | 'success' | 'warning';

interface AlertProps extends BaseCompoundComponentProps {
    title?: string;
    description?: string;
    type: AlertType;
    'data-testid'?: string;
}

interface CssType {
    [key: string]: string;
}

interface IconType {
    [key: string]: React.Component | React.ReactFragment | React.ReactPortal | null | undefined;
}
const Alert = ({ title, description, type, children, 'data-testid': dataTestId }: AlertProps) => {
    const cssTypes: CssType = {
        danger: 'danger text-red border-red bg-red-alert',
        warning: 'warning text-orange border-orange',
    };
    const iconTypes: IconType = {
        danger: <ErrorIcon />,
        warning: <WarningIcon />,
    };

    return (
        <div
            className={cx(
                'alert px-4 py-4 flex flex-wrap items-center bg-white border border-solid mb-4 w-full',
                cssTypes[type]
            )}
            data-testid={dataTestId ?? ''}>
            <div className="mr-2 ">{iconTypes[type]}</div>
            <div className="alert-message-container flex-1 relative">
                {title ? <p className="font-bold text-sm">{title}</p> : null}
                {description ? <p>{description}</p> : null}
            </div>
            {children ? children : null}
        </div>
    );
};

export default Alert;
