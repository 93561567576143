import databaseProxiesClient from '@baffle/api-client/src/databaseProxiesClient';
import {
    DatabaseProxy,
    DatabaseProxyConfiguration,
    DatabaseProxyPayload,
    DatabaseProxyShield,
    KeyValuePairsMap,
    AdvanceConfiguration,
    RbacConfigurations,
    AdvanceConfigurations,
    DebugLevel,
} from '@baffle/graphql/src/models';
import { action, makeObservable, observable } from 'mobx';
import { format } from '@baffle/utilities/src/date';

class DatabaseProxiesStore {
    constructor() {
        makeObservable(this, {
            databaseProxy: observable,
            databaseProxies: observable,
            databaseProxyShields: observable,
            setDatabaseProxy: action,
            setDatabaseProxies: action,
            setDatabaseProxyShields: action,
            setDatabaseProxyShieldsList: action,
        });
    }

    databaseProxy: DatabaseProxy | null | undefined = null;
    databaseProxies = observable<DatabaseProxy>([]);
    databaseProxyShields = observable<DatabaseProxyShield>([]);
    databaseProxyShieldsList = observable<Partial<DatabaseProxyShield>>([]);
    databaseProxyConfiguration: DatabaseProxyConfiguration | null | undefined = null;
    advanceConfiguration: AdvanceConfiguration | null | undefined = null;
    advanceConfigurations: KeyValuePairsMap = {};

    setDatabaseProxy(databaseProxyPayload: DatabaseProxy) {
        this.databaseProxy = databaseProxyPayload;
    }

    setDatabaseProxies(databaseProxies: DatabaseProxy[]) {
        this.databaseProxies.replace(databaseProxies ?? []);
    }

    setDatabaseProxyShields(databaseProxyShields: DatabaseProxyShield[]) {
        this.databaseProxyShields.replace(databaseProxyShields ?? []);
    }

    setDatabaseProxyShieldsList(shields: Partial<DatabaseProxyShield[]>) {
        const DatabaseProxyShieldsList = shields.map(shield => ({
            id: shield?.id ?? '',
            createdBy: shield?.audit.createdBy ?? '',
            subjectName: shield?.sslParameters.subjectName ?? '',
            createdDate: shield?.audit.createdDate ?? '',
            subjectAlternativeName: shield?.sslParameters.subjectAlternativeName ?? '',
            expiryDate: (shield?.sslParameters.expiryDate as number) ?? '',
        }));
        this.databaseProxyShieldsList.replace(DatabaseProxyShieldsList);
    }

    setDatabaseProxyConfiguration(databaseProxyConfiguration: DatabaseProxyConfiguration) {
        this.databaseProxyConfiguration = databaseProxyConfiguration;
        this.advanceConfigurations = databaseProxyConfiguration?.advancedConfig as KeyValuePairsMap;
    }

    setAdvanceConfiguration(config: AdvanceConfiguration | null) {
        this.advanceConfiguration = config;
    }

    async createDatabaseProxy(databaseProxy: DatabaseProxyPayload) {
        try {
            await databaseProxiesClient.createDatabaseProxy(databaseProxy);
            await this.readDatabaseProxies();
        } catch (error) {
            throw error;
        }
    }

    async readDatabaseProxy(id: string) {
        try {
            const databaseProxy = await databaseProxiesClient.readDatabaseProxy(id);
            this.setDatabaseProxy(databaseProxy);
        } catch (error) {
            throw error;
        }
    }

    async readDatabaseProxies() {
        try {
            const res = await databaseProxiesClient.readDatabaseProxies();
            this.setDatabaseProxies(res.data);
        } catch (error) {
            throw error;
        }
    }

    async readDatabaseProxyShields(id: string) {
        try {
            const res = await databaseProxiesClient.readDatabaseProxyShields(id);
            this.setDatabaseProxyShields(res.data);
            this.setDatabaseProxyShieldsList(res.data);
        } catch (error) {
            throw error;
        }
    }

    async readDatabaseProxyConfiguration(id: string) {
        try {
            const databaseProxyConfiguration = await databaseProxiesClient.readDatabaseProxyConfiguration(id);
            this.setDatabaseProxyConfiguration(databaseProxyConfiguration);
        } catch (error) {
            throw error;
        }
    }

    async updateDatabaseProxyConfiguration(
        id: string,
        configParam: ConfigTypeArguments,
        payload: AdvanceConfigurations | DebugLevel | RbacConfigurations
    ) {
        try {
            const databaseProxyConfiguration = await databaseProxiesClient.updateDatabaseProxyConfiguration(
                id,
                configParam,
                payload
            );
            this.setDatabaseProxyConfiguration(databaseProxyConfiguration);
        } catch (error) {
            throw error;
        }
    }

    async deleteDatabaseProxyAdvanceConfiguration(id: string, configKeys: string[]) {
        try {
            await databaseProxiesClient.deleteDatabaseProxyAdvanceConfiguration(id, configKeys);
        } catch (error) {
            throw error;
        }
    }

    getDatabaseProxyAdvanceConfiguration(configKey: string) {
        try {
            let config: AdvanceConfiguration = {
                key: configKey,
                value: this.advanceConfigurations[configKey],
            };
            this.setAdvanceConfiguration(config);
            return config;
        } catch (error) {
            throw error;
        }
    }

    get(id: string) {
        return this.databaseProxies.find(d => d.id === id);
    }

    getDatabaseProxyOverview(databaseProxy: DatabaseProxy) {
        return {
            syncId: databaseProxy.syncId,
            id: databaseProxy.id,
            name: databaseProxy.name,
            database: databaseProxy.database.name,
            keystore: databaseProxy.keystore.name,
            createdDate: format(databaseProxy.audit.createdDate, 'MM-dd-yyyy'),
            createdBy: databaseProxy.audit.createdBy,
        };
    }
}

interface ConfigTypeArguments {
    configParam: string;
}

export default DatabaseProxiesStore;
