import client from '.';
import { LoggerConfiguration, SystemInfo, SystemInfoEmail } from '@baffle/graphql/src/models';

export async function readEmail() {
    return client('/api/v2/configurations/email-server');
}

export async function createEmail(payload: SystemInfoEmail) {
    return client('/api/v2/configurations/email-server', { body: payload, method: 'POST' });
}

export async function updateEmail(id: string, payload: SystemInfoEmail) {
    return client(`/api/v2/configurations/email-server/${id}`, { body: payload, method: 'PUT' });
}

export async function deleteEmail(id: string) {
    return client(`/api/v2/configurations/email-server/${id}`, { method: 'DELETE' });
}

export async function readVersion() {
    return client('/api/v1.0/version');
}

export async function readApplicationAccess() {
    return client('/api/public/v1.0/init/application_access_check');
}

export async function updateSystemSettings(id: string, data: SystemInfo) {
    return client(`/api/v2/configurations/tenant/${id}`, { body: data, method: 'PUT' });
}

export async function getSystemSettings() {
    return client('/api/v2/configurations/tenant');
}

export async function readLoggers() {
    return client(`/api/v2/configurations/logger`);
}

export async function createLogger(data: LoggerConfiguration) {
    return client(`/api/v2/configurations/logger`, { body: data, method: 'POST' });
}

export async function deleteLogger(id: string) {
    return client(`/api/v2/configurations/logger/${id}`, { method: 'DELETE' });
}

export async function getHttpsCertificate() {
    return client('/api/v2/configurations/certificate');
}

export async function refreshHttpsCertificate() {
    return client('/api/v2/configurations/certificate', { method: 'PUT' });
}

export default {
    readEmail,
    createEmail,
    updateEmail,
    deleteEmail,
    readVersion,
    readApplicationAccess,
    updateSystemSettings,
    getSystemSettings,
    readLoggers,
    createLogger,
    deleteLogger,
    getHttpsCertificate,
    refreshHttpsCertificate,
};
