import React, { useState, useEffect, useMemo } from 'react';
import '../../Encryption.scss';
import {
    DataProtectionItem,
    RbacPolicy,
    RbacPolicyDefaultPermission,
    RbacPolicyRule,
} from '@baffle/graphql/src/models';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import { t } from '@baffle/translate';
import cx from 'classnames';
import Alert from '@baffle/components/src/alerts/Alert';
import Button from '@baffle/components/src/buttons/Button';
import DropdownSelect, {
    getDefaultMenuProps,
    getDefaultToggleButtonProps,
} from '@baffle/components/src/dropdown/Dropdown';
import { DataFormatStore } from '@baffle/manager/src/stores';
import dataProtectionClient from '@baffle/api-client/src/dataProtectionClient';
import useAwait from '@baffle/api-client/src/useAwait';
import createRbacPolicyScheme from '../modeInputSchemes/createRbacPolicyScheme';
import { useParams } from 'react-router';
import TextInput from '@baffle/components/src/forms/TextInput';
import TextArea from '@baffle/components/src/forms/TextArea';
import { ToastStore } from '../../../../stores/ToastStore';
import { Observer } from 'mobx-react-lite';
import usePageHeight from '@baffle/utilities/src/usePageHeight';
import TreeAddIcon from '@baffle/components/src/icons/TreeAddIcon';
import EditIcon from '@baffle/components/src/icons/EditIcon';
import Divider from '@baffle/components/src/content/Divider';
import RbacPolicyRules from './RbacPolicyRules';

const AddRbacPolicy = () => {
    return (
        <Observer>
            {() => {
                const { id: appId } = useParams() as { id: string };
                const [editMode, setEditMode] = useState(Boolean(false));
                const { run, error, reset } = useAwait();
                const [validationError, setValidationError] = useState(0);
                const existingPolicy = DataFormatStore.selectedRbacPolicy;
                const rbacPolicyInputSchemes = useMemo(
                    () => createRbacPolicyScheme(DataFormatStore.rbacPolicies, existingPolicy?.id),
                    [DataFormatStore.rbacPolicies, existingPolicy]
                );
                const onSuccess = (action: string) => {
                    ToastStore.push({
                        type: 'success',
                        title: t('encryption.modeLibrary.rbac.rbacPolicy.rbacPolicyAddEditSuccess', {
                            action,
                        }),
                    });
                };

                const permissionItems = Object.keys(RbacPolicyDefaultPermission).map(key => ({
                    id: key,
                    label: key,
                    'data-testid': `policy-permission-li-${key}`,
                }));

                //@ts-ignore
                const { inputState, inputErrors, inputActions } = useInputState(rbacPolicyInputSchemes);
                const isValid = inputActions.validateAll(true);
                const handleSave = () => {
                    let payload: RbacPolicy = rbacPolicyInputSchemes.keys.reduce((acc: any, c: any) => {
                        //This creates a new object mapping the keys from the input scheme
                        //to the key/values from the inputState. And send only those values which are present
                        if (inputState[c]) {
                            if (c === 'rules') {
                                acc['rules'] = (inputState.rules as Array<RbacPolicyRule>).map(rule => ({
                                    name: rule.name,
                                    order: +rule.order,
                                    userGroups: rule.userGroups,
                                    permission: rule.permission,
                                    maskMode: rule.maskMode,
                                }));
                            } else if (c === 'defaultMaskMode') {
                                acc[c] = inputState[c];
                            } else {
                                acc[c] = (inputState[c] as string).trim();
                            }
                        }
                        return acc;
                    }, {});
                    payload.appId = appId;
                    run(
                        (existingPolicy
                            ? dataProtectionClient.updateRbacPolicy(existingPolicy?.id as string, payload)
                            : dataProtectionClient.createRbacPolicy(payload)
                        ).then(() => {
                            onSuccess(existingPolicy ? 'updated' : 'added');
                            DataFormatStore.listAll(appId as string);
                            DataFormatStore.resetModeBuilder();
                            DataFormatStore.setShowRbacPolicyView(true);
                            inputActions.reset();
                        })
                    );
                };
                const addNewField = () => {
                    const newRule: RbacPolicyRule = {
                        name: '',
                        order: policyRules.length + 1,
                        permission: '',
                        maskMode: null,
                        userGroups: [],
                    };
                    policyRules.push(newRule);
                    inputActions.setField('rules', policyRules);
                };
                const handleClose = () => {
                    DataFormatStore.resetModeBuilder();
                    inputActions.reset();
                    reset();
                };
                const handleEdit = () => {
                    setEditMode(false);
                };

                //Fetch supported modes for dropdown
                //Sync state from props
                useEffect(() => {
                    if (existingPolicy) {
                        setEditMode(true);
                        const { name, description, defaultPermission, rules, defaultMaskMode } = existingPolicy;
                        inputActions.setFields({
                            name,
                            description,
                            defaultPermission,
                            rules,
                            defaultMaskMode,
                        });
                    } else {
                        setEditMode(false);
                        inputActions.setFields({
                            name: null,
                            description: null,
                            defaultPermission: null,
                            defaultMaskMode: null,
                            rules: [],
                        });
                    }
                }, [existingPolicy]);
                useEffect(() => {
                    let validationErrorCount = 0;
                    Object.keys(inputErrors).forEach(key => {
                        if (inputErrors[key] != null) {
                            validationErrorCount += 1;
                        }
                    });
                    setValidationError(validationErrorCount);
                }, [inputErrors]);

                // When component is unmounted, reset the flag
                useEffect(() => {
                    return () => {
                        DataFormatStore.resetModeBuilder();
                        DataFormatStore.setShowRbacPolicyCreate(false);
                        inputActions.reset();
                    };
                }, []);
                const pageHeight = usePageHeight() - 120;
                const policyRules = (inputState.rules as Array<RbacPolicyRule>) ?? [];
                const maskModeItems = DataFormatStore.maskingModes.map(mode => ({
                    id: mode.id,
                    label: mode.name,
                    'data-testid': `policy-mask-li-${mode.name}`,
                }));
                return (
                    <div className="flex-grow bg-white">
                        <div className="bg-gray-200 px-4 h-8 border-solid border-t-2 border-b-2 border-gray-300">
                            <div className="relative flex items-center h-full uppercase w-112 font-bold text-xs">
                                {DataFormatStore.selectedRbacPolicy ? (
                                    <div className="flex-1 flex justify-between items-center">
                                        <span>{t('encryption.modeLibrary.rbac.rbacPolicyHeader')}</span>
                                        <button
                                            aria-label={t('main.edit')}
                                            onClick={handleEdit}
                                            data-testid="rbac-policy-edit-btn">
                                            <EditIcon />
                                        </button>
                                    </div>
                                ) : (
                                    <span>{t('encryption.modeLibrary.rbac.rbacPolicyHeader')}</span>
                                )}
                            </div>
                        </div>
                        <div className="overflow-y-auto" style={{ height: pageHeight }}>
                            <div className="w-120 mt-4 pl-4 mb-4">
                                {error ? (
                                    <div className="flex flex-wrap">
                                        <div className="flex-1">
                                            <Alert
                                                type="danger"
                                                title={t('encryption.modeLibrary.rbac.rbacPolicy.rbacPolicyAddError')}
                                                description={error.message ?? ''}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                                {validationError ? (
                                    <div className="flex flex-wrap">
                                        <div className="flex-1">
                                            <Alert
                                                type="danger"
                                                title={t('keystore.inputError', { count: validationError })}
                                                data-testid="alert-field-validation-error"
                                            />
                                        </div>
                                    </div>
                                ) : null}

                                <div>
                                    <div className="flex flex-wrap mb-6 items-end">
                                        <div className="flex-1 name-input-container">
                                            <TextInput
                                                id="rbac-policy-name-input"
                                                name="rbac-policy-name-input"
                                                data-testid="rbac-policy-name-input"
                                                labelText={t('encryption.modeLibrary.rbac.rbacPolicy.policyNameLabel')}
                                                placeholder={t(
                                                    'encryption.modeLibrary.rbac.rbacPolicy.policyNamePlaceholder'
                                                )}
                                                invalid={Boolean(inputErrors.name)}
                                                invalidText={inputErrors.name}
                                                value={(inputState.name as string) || ''}
                                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    inputActions.validateField({
                                                        field: 'name',
                                                        skipNull: true,
                                                    });
                                                }}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    const val = e.target.value || '';
                                                    inputActions.setField('name', val);
                                                }}
                                                size={30}
                                                disabled={editMode}
                                            />
                                            <span className="input-char-counter right-0">
                                                {(inputState.name as string)?.length ?? 0} / 30
                                            </span>{' '}
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap mb-6">
                                        <div className="flex-1 relative">
                                            <TextArea
                                                id="description-input"
                                                name="description-input"
                                                data-testid="description-input"
                                                labelText={t('encryption.modeLibrary.rbac.rbacUsrGrp.descriptionLabel')}
                                                placeholder={t(
                                                    'encryption.modeLibrary.rbac.rbacUsrGrp.descriptionPlaceholder'
                                                )}
                                                value={(inputState.description as string) || ''}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    const val = e.target.value || '';
                                                    if (val.length > 100) {
                                                        e.target.value = val.slice(0, 100);
                                                    }
                                                    inputActions.setField('description', e.target.value);
                                                }}
                                                size={100}
                                                disabled={editMode}
                                            />
                                            <span className="input-char-counter right-0">
                                                {(inputState.description as string)?.length ?? 0}/ 100
                                            </span>{' '}
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap mb-6 items-start">
                                        <div className="flex-1">
                                            <DropdownSelect
                                                id="rbac-policy-permission"
                                                placeholder={t('main.chooseOption')}
                                                label={t('encryption.modeLibrary.rbac.rbacPolicy.defaultPermission')}
                                                value={
                                                    inputState.defaultPermission
                                                        ? permissionItems.find(
                                                              m => m.id === inputState.defaultPermission
                                                          )
                                                        : { id: 'chooseOption', label: t('main.chooseOption') }
                                                }
                                                getMenuProps={() => {
                                                    const { className: ulClasses, ...rest } = getDefaultMenuProps();
                                                    return {
                                                        className: `${ulClasses} w-full`,
                                                        ...rest,
                                                    };
                                                }}
                                                handleSelectedItemChange={({ selectedItem }) => {
                                                    inputActions.setField(
                                                        'defaultPermission',
                                                        selectedItem?.id as string
                                                    );
                                                }}
                                                getToggleButtonProps={() => {
                                                    const {
                                                        className: btnClasses,
                                                        ...rest
                                                    } = getDefaultToggleButtonProps();
                                                    return {
                                                        disabled: editMode,
                                                        className: cx(btnClasses, {
                                                            disabled: editMode,
                                                        }),
                                                        ...rest,
                                                    };
                                                }}
                                                items={permissionItems}
                                            />
                                        </div>
                                    </div>
                                    {inputState.defaultPermission === RbacPolicyDefaultPermission.MASK ? (
                                        <div className="flex flex-wrap mb-6 items-start">
                                            <div className="flex-1">
                                                <DropdownSelect
                                                    id="rbac-policy-masking-mode"
                                                    placeholder={t('main.chooseOption')}
                                                    label={t('encryption.modeLibrary.rbac.rbacPolicy.ruleMask')}
                                                    value={
                                                        inputState.defaultMaskMode
                                                            ? maskModeItems.find(
                                                                  m =>
                                                                      m.id ===
                                                                      (inputState.defaultMaskMode as DataProtectionItem)
                                                                          ?.id
                                                              )
                                                            : { id: 'chooseOption', label: t('main.chooseOption') }
                                                    }
                                                    getMenuProps={() => {
                                                        const { className: ulClasses, ...rest } = getDefaultMenuProps();
                                                        return {
                                                            className: `${ulClasses} w-full`,
                                                            ...rest,
                                                        };
                                                    }}
                                                    handleSelectedItemChange={({ selectedItem }) => {
                                                        const value = DataFormatStore.maskingModes.find(
                                                            mode => mode.id === selectedItem?.id
                                                        ) as DataProtectionItem;
                                                        inputActions.setField('defaultMaskMode', value);
                                                    }}
                                                    getToggleButtonProps={() => {
                                                        const {
                                                            className: btnClasses,
                                                            ...rest
                                                        } = getDefaultToggleButtonProps();
                                                        return {
                                                            disabled: editMode,
                                                            className: cx(btnClasses, {
                                                                disabled: editMode,
                                                            }),
                                                            ...rest,
                                                        };
                                                    }}
                                                    items={maskModeItems}
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                    <div className="max-h-96 overflow-y-auto">
                                        {policyRules.map((rule, index) => (
                                            <RbacPolicyRules
                                                inputActions={inputActions}
                                                inputState={inputState}
                                                existingPolicy={existingPolicy}
                                                editMode={editMode}
                                                permissionItems={permissionItems}
                                                policyRules={policyRules}
                                                rule={rule}
                                                index={index}
                                                key={index}
                                            />
                                        ))}
                                    </div>
                                    <div className="mt-3 flex">
                                        <button
                                            data-testid="add-new-rule-btn"
                                            className={cx('flex items-center justify-center focus:outline-none', {
                                                'cursor-not-allowed': editMode,
                                            })}
                                            onClick={addNewField}
                                            disabled={editMode}>
                                            <TreeAddIcon className="w-4 h-4" color={editMode ? '#C1C7CD' : '#0058A1'} />
                                            <p
                                                className={cx('text-xs ml-1 text-blue', {
                                                    'text-gray-disable': editMode,
                                                })}>
                                                {t('encryption.modeLibrary.rbac.rbacPolicy.addRule')}
                                            </p>
                                        </button>
                                    </div>
                                    <Divider />
                                    {editMode ? (
                                        <div className="flex w-full justify-end h-8">
                                            <Button
                                                theme="primary"
                                                className="py-2 w-24 font-semibold text-xs uppercase"
                                                onClick={handleEdit}
                                                data-testid="edit-rbac-policy-btn">
                                                {t('main.edit')}
                                            </Button>
                                        </div>
                                    ) : (
                                        <div className="flex w-full justify-end h-8">
                                            <Button
                                                theme="secondary"
                                                className="py-2 w-24 font-semibold text-xs uppercase"
                                                onClick={() => {
                                                    handleClose();
                                                }}>
                                                {t('main.cancel')}
                                            </Button>
                                            <Button
                                                className="py-2 w-24 font-semibold ml-2 text-xs uppercase"
                                                id="save-rbac-policy-btn"
                                                theme={!isValid ? 'disabled' : 'primary'}
                                                onClick={handleSave}>
                                                {t('main.save')}
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }}
        </Observer>
    );
};

export default AddRbacPolicy;
