import React, { useState } from 'react';
import { Checkbox } from 'carbon-components-react';
import usePageHeight from '@baffle/utilities/src/usePageHeight';
import ReactDataGrid from 'react-data-grid';
import SkeletonText from '@baffle/components/src/skeleton/SkeletonText';
import { sortGrid, SortInfo, SortDirection } from '@baffle/utilities/src/gridHelpers';
import { stringSort, numberSort } from '@baffle/utilities/src/sorters';
import { SelectedColumn, CryptoActionType } from '@baffle/graphql/src/models';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import TH from '@baffle/components/src/table/react-data-grid/TH';
import { t } from '@baffle/translate';
import setKeyDisplay from '@baffle/utilities/src/setKeyDisplay';
import Cell from '@baffle/components/src/table/react-data-grid/Cell';

interface EncryptionConfirmationTableProps {
    type: CryptoActionType;
    selectedColumns: SelectedColumn[];
    loading: boolean;
}

const EncryptionConfirmationTable = ({ selectedColumns, loading, type }: EncryptionConfirmationTableProps) => {
    const initialGridSort: SortInfo = { direction: 'NONE', key: '' };
    const [sort, setSort] = useState(initialGridSort);
    const skeletonCol = {
        id: '',
        select: '',
        dbName: <SkeletonText />,
        tableName: <SkeletonText />,
        objectType: <SkeletonText />,
        header1: <SkeletonText />,
        header2: <SkeletonText />,
        header3: <SkeletonText />,
        header4: <SkeletonText />,
        header5: <SkeletonText />,
        header6: ' ',
    };

    const emptyCol = {
        id: '',
        select: '',
        dbName: '',
        tableName: '',
        objectType: '',
        header1: '',
        header2: '',
        header3: '',
        header4: '',
        header5: '',
        header6: '',
    };

    const cols = [
        {
            key: 'select',
            name:
                type === 'decryption' ? (
                    <LockOpenIcon className="text-red w-4 h-4" />
                ) : (
                    <LockIcon className="w-4 h-4" />
                ),
            width: 40,
            headerRenderer: <TH idPrefix="encConfGrid" />,
            formatter: Cell,
        },
        {
            key: 'dbName',
            name: t('encryption.columnGrid.dbName'),
            sortable: true,
            sortFn: (a: SelectedColumn, b: SelectedColumn) => stringSort(a.database, b.database),
            headerRenderer: <TH idPrefix="encConfGrid" />,
            formatter: Cell,
        },
        {
            key: 'tableName',
            name: t('encryption.columnGrid.tableName'),
            sortable: true,
            sortFn: (a: SelectedColumn, b: SelectedColumn) => stringSort(a.table, b.table),
            headerRenderer: <TH idPrefix="encConfGrid" />,
            formatter: Cell,
        },
        {
            key: 'objectType',
            name: t('encryption.columnGrid.objectType'),
            sortable: true,
            sortFn: (a: SelectedColumn, b: SelectedColumn) => stringSort(a.table, b.table),
            headerRenderer: <TH idPrefix="encConfGrid" />,
            formatter: Cell,
        },
        {
            key: 'header1',
            name: t('encryption.columnGrid.header1'),
            sortable: true,
            sortFn: (a: SelectedColumn, b: SelectedColumn) => stringSort(a.name, b.name),
            headerRenderer: <TH idPrefix="encConfGrid" />,
            formatter: Cell,
        },
        {
            key: 'header2',
            name: t('encryption.columnGrid.header2'),
            sortable: true,
            sortFn: (a: SelectedColumn, b: SelectedColumn) => stringSort(a.dataType, b.dataType),
            headerRenderer: <TH idPrefix="encConfGrid" />,
            formatter: Cell,
        },
        {
            key: 'header3',
            name: t('encryption.columnGrid.header3'),
            sortable: true,
            sortFn: (a: SelectedColumn, b: SelectedColumn) =>
                stringSort(a.selections.encryptionMode?.name || '', b.selections.encryptionMode?.name || ''),
            headerRenderer: <TH idPrefix="encConfGrid" />,
            formatter: Cell,
        },
        {
            key: 'header4',
            name: t('encryption.columnGrid.header4'),
            sortable: true,
            sortFn: (a: SelectedColumn, b: SelectedColumn) =>
                stringSort(a.selections.rbacPolicy?.name || '', b.selections.rbacPolicy?.name || ''),
            headerRenderer: <TH idPrefix="encConfGrid" />,
            formatter: Cell,
        },
        {
            key: 'header5',
            name: t('encryption.columnGrid.header5'),
            sortable: false,
            sortFn: (a: SelectedColumn, b: SelectedColumn) => numberSort(a.keyID, b.keyID),
            headerRenderer: <TH idPrefix="encConfGrid" />,
            formatter: Cell,
            width: 60,
        },
        {
            key: 'header6',
            name: ' ',
            width: 1,
            formatter: Cell,
        },
    ];

    sortGrid(sort, cols, selectedColumns);

    const rows = loading
        ? //add  skeleton row on initial load
          [...Array(Math.floor(1)).keys()].map(() => skeletonCol)
        : selectedColumns.map((d: SelectedColumn, i: number) => ({
              id: d.path,
              select: (
                  <Checkbox id={i + '_column'} aria-label={t('encyption.selectCell')} checked disabled labelText="" />
              ),
              dbName: d.database,
              tableName: d.table,
              objectType: d.tableType,
              header1: d.name,
              header2: d.dataType,
              header3: d.selections.encryptionMode?.name || 'N/A',
              header4: d.selections.rbacPolicy?.name || 'N/A',
              header5: setKeyDisplay(d.keyID),
              header6: ' ',
              columnIndex: i,
          }));

    const pageHeight = usePageHeight();
    const size = Math.floor((pageHeight - 250) / 35); //250 offsets the footer, etc
    //add empty rows to fill the page, if needed
    if (selectedColumns.length < size) {
        const sizeDelta = size - selectedColumns.length;
        //@ts-ignore
        rows.push(...[...Array(sizeDelta).keys()].map(() => emptyCol));
    }
    const pageHeightOffset = 270;
    const minHeight = pageHeight - pageHeightOffset;
    return (
        <div className="relative" style={{ minHeight }}>
            <div className="absolute w-full" data-testid={`${type}-confirmation-table`}>
                <ReactDataGrid
                    onGridSort={(key: string, direction: SortDirection) => setSort({ direction, key })}
                    //@ts-ignore
                    columns={cols}
                    rowGetter={i => rows[i]}
                    rowsCount={rows.length}
                    minHeight={minHeight}
                    rowHeight={48}
                    headerFiltersHeight={48}
                    headerRowHeight={48}
                />
            </div>
        </div>
    );
};

export default EncryptionConfirmationTable;
