import { TYPE_NAMES } from '.';
import gql from 'graphql-tag';

export const LIST_ALL_KEYSTORES = gql`
    query Keystores {
        keystores @rest(type: "[${TYPE_NAMES.Keystore}]", path: "keystores", endpoint: "v1") {
            id @export(as: "id")
            name
            appNameSpace
            type
            details (id: $id) @rest(type: "${TYPE_NAMES.Keystore}", path: "keystore/{exportVariables.id}", endpoint: "v1") {
                id
                name
                description
                appNameSpace
                keys
                type
                createTime
                createdBy
                ssl
                certIssuerDN
                certSubjectSAN
                certSubjectDN
                certExpiry
                expiresSoon
                expired
            }
        }
    }
`;

export const GET_KEYSTORE = gql`
    query Keystore($id: String!) {
        keystore(id: $id) @rest(type: "${TYPE_NAMES.Keystore}", path: "keystore/{args.id}", endpoint: "v1") {
            id
            name
            description
            appNameSpace
            keys
            type
            createTime
            createdBy
            ssl
            certIssuerDN
            certSubjectSAN
            certSubjectDN
            certExpiry
            expiresSoon
        }
    }
`;

export const ADD_KEYSTORE = gql`
    mutation AddKeystore($input: Object!) {
        keystore(input: $input) @rest(type: "${TYPE_NAMES.Keystore}", path: "keystore", method: "POST", endpoint: "v1") {
            id
        }
    }
`;

export const ADD_ROTATE_KEY = gql`
    query AddRotateKey($id: String!, $key: String!, $keyToRotate: String!) {
        addRotateKeyRes(id: $id, key: $key, keyToRotate: $keyToRotate) @rest(type: "${TYPE_NAMES.AddRotateKeyRes}", path: "keystore/rotateKey/{args.id}/{args.key}/{args.keyToRotate}", endpoint: "v1") {
            data
        }
    }
`;

export const ADD_ROTATE_MASTER_KEY = gql`
    query AddRotateMasterKey($id: String! ) {
        addRotateKeyMasterRes(id: $id) @rest(type: "${TYPE_NAMES.AddRotateKeyRes}", path: "keystore/rotateMasterKey/{args.id}", endpoint: "v1") {
            data
        }
    }
`;

export const DELETE_KEYSTORE = gql`
    mutation DeleteKeystore($id: ID!) {
        deleteKeystore(id: $id)
            @rest(type: "${TYPE_NAMES.Keystore}", path: "keystore/unenroll/{args.id}", method: "DELETE", endpoint: "v1") {
            NoResponse
        }
    }
`;

export const CHECK_KS_NAMESPACE = gql`
    query KSNamespace($name: String!) {
        ksNamespace(name: $name)
            @rest(type: "${TYPE_NAMES.KSNamespace}", path: "keystore/ks_check?name={args.name}", endpoint: "v1") {
            data
        }
    }
`;

export const GET_KEYSLIST = gql`
    query Keyslist($id: String!) {
        keyslist(id: $id) @rest(type: "[${TYPE_NAMES.EncryptionKey}]", path: "keystore/keysList/{args.id}", endpoint: "v1") {
            keyId
            keyType
            keyUUID
            creationDate
        }
    }
`;
