export interface Bounds {
    left: number;
    top: number;
    right: number;
    bottom: number;
    width: number;
    height: number;
    x: number;
    y: number;
}

const getBounds = (el: HTMLElement | null) => {
    if (!el) {
        return {
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            width: 0,
            height: 0,
            x: 0,
            y: 0,
        };
    }
    const { x, y, left, top, right, bottom, width, height } = el.getBoundingClientRect();
    return {
        x: x || left,
        y: y || top,
        left,
        top,
        right,
        bottom,
        width,
        height,
    };
};

export default getBounds;
