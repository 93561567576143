import React, { useState, useEffect, useMemo } from 'react';
import { Observer } from 'mobx-react-lite';
import Header from '@baffle/components/src/nav/Header';
import Button from '@baffle/components/src/buttons/Button';
import { t } from '@baffle/translate';
import HistoryIcon from '@material-ui/icons/History';
import Content from '@baffle/components/src/content/Content';
import { useParams, useHistory } from 'react-router-dom';
import {
    ColumnMetaData,
    SelectedColumn,
    KeyDetails,
    ApplicationDetailsV2,
    Keystore,
    DatabaseTypeEnum,
    EncryptionDatabase,
    EncryptionDatabaseTable,
    EncryptionDatabaseView,
    EncryptionDatabaseSchema,
} from '@baffle/graphql/src/models';
import './Encryption.scss';
import ColumnsTable from './column/ColumnsTable';
import ColumnPills from './ColumnPills';
import Toast, { ToastType } from '@baffle/components/src/toasts/Toast';
import {
    EncryptionStore,
    KeyDetailsStore,
    ApplicationStore,
    KeystoreStore,
    DataFormatStore,
    FeatureFlagStore,
} from '../../stores';
import GrantsModal from './GrantsModal';
import databaseClient from '@baffle/api-client/src/databaseClient';
import Alert from '@baffle/components/src/alerts/Alert';
import { ToastStore } from '../../stores/ToastStore';
import useAwait from '@baffle/api-client/src/useAwait';
import { useManageModals } from '@baffle/components/src/modal/Modal';
import usePageHeight from '@baffle/utilities/src/usePageHeight';
import TreeDatabaseIcon from '@baffle/components/src/icons/TreeDatabaseIcon';
import SchemaIcon from '@baffle/components/src/icons/SchemaIcon';
import TreeTableIcon from '@baffle/components/src/icons/TreeTableIcon';
import OverflowMenu from '@baffle/components/src/menu/OverflowMenu';
import TreeAddIcon from '@baffle/components/src/icons/TreeAddIcon';
import SkeletonText from '@baffle/components/src/skeleton/SkeletonText';
import Search from '@baffle/components/src/search/Search';
import TreeDeleteIcon from '../../../../components/src/icons/TreeDeleteIcon';
import AddNewDatabaseModal from './AddNewDatabaseModal';
import DeleteNewDatabaseModal from './DeleteNewDatabaseModal';
import AddNewSchemaModal from './AddNewSchemaModal';
import DeleteNewSchemaModal from './DeleteNewSchemaModal';
import AddNewTableModal from './AddNewTableModal';
import DeleteNewTableModal from './DeleteNewTableModal';
import AddDbOwnerModal from './AddDbOwnerModal';
import EncryptionTreeView from './EncryptionTreeView';
import ModeLibrary from './modebuilder/ModeLibrary';
import AddEncryptionMode from './modebuilder/AddEncryptionMode';
import AddMaskingMode from './modebuilder/AddMaskingMode';
import AddRbacConfig from './modebuilder/rbac/AddRbacConfig';
import RbacUsrGrpLanding from './modebuilder/rbac/RbacUsrGrpLanding';
import AddRbacUsrGrp from './modebuilder/rbac/AddRbacUsrGrp';
import AddJsonPolicy from './modebuilder/jsonPolicy/AddJsonPolicy';
import RbacPolicyLanding from './modebuilder/rbac/RbacPolicyLanding';
import AddRbacPolicy from './modebuilder/rbac/AddRbacPolicy';

const Encryption = () => {
    let { id } = useParams() as { id: string };
    let history = useHistory();
    if (!id) {
        location.replace('/');
        return null;
    }
    const { status, run, error } = useAwait();
    const { error: lazyError } = useAwait();
    const initialState: {
        results: EncryptionDatabase[];
        query: string;
    } = { results: [], query: '' };
    const initialStateSchema: {
        resultsSchema: EncryptionDatabaseSchema[];
        querySchema: string;
    } = { resultsSchema: [], querySchema: '' };
    const initialStateTable: {
        resultsTable: EncryptionDatabaseTable[];
        queryTable: string;
    } = { resultsTable: [], queryTable: '' };
    const initialStateView: {
        resultsView: EncryptionDatabaseView[];
        queryView: string;
    } = { resultsView: [], queryView: '' };
    const initialToastState: { show: boolean; title: string; type: ToastType } = {
        show: false,
        title: '',
        type: 'success',
    };
    const initialStateColumn: {
        resultsColumn: ColumnMetaData[];
        queryColumn: string;
    } = { resultsColumn: [], queryColumn: '' };
    const [toast, setToast] = useState(initialToastState);
    const [filtered, setFiltered] = useState(initialState);
    const [filteredSchemas, setFilteredSchemas] = useState(initialStateSchema);
    const [filteredTables, setFilteredTables] = useState(initialStateTable);
    const [filteredViews, setFilteredViews] = useState(initialStateView);
    const [filteredColumns, setFilteredColumns] = useState(initialStateColumn);

    interface EncryptionModals {
        databaseGrants: boolean;
        addDataFormat: boolean;
        addNewDatabase: boolean;
        deleteNewDatabase: boolean;
        addDbOwner: boolean;
        addNewSchema: boolean;
        deleteNewSchema: boolean;
        addNewTable: boolean;
        deleteNewTable: boolean;
        data: any;
    }
    const initialModalState = {
        databaseGrants: false,
        addDataFormat: false,
        addNewDatabase: false,
        deleteNewDatabase: false,
        addNewSchema: false,
        deleteNewSchema: false,
        addDbOwner: false,
        addNewTable: false,
        deleteNewTable: false,
        data: {},
    };
    const [modals, setModals] = useManageModals<EncryptionModals>(initialModalState);

    const handleUpdateCert = (id: string, appId: string) => {
        history.push('/keystores', { keystoreId: id, applicationId: appId, updateCert: true });
    };

    useEffect(() => {
        if (error) {
            ToastStore.push({ type: 'danger', title: error.message ?? t('main.unexpectedError') });
        }
        if (lazyError) {
            ToastStore.push({ type: 'danger', title: lazyError.message });
        }
    }, [error, lazyError]);

    useEffect(() => {
        //clear previous databases, table and columns on mount
        //https://baffle.atlassian.net/browse/BM3-1885
        EncryptionStore.resetDatabaseTableAndColumns({
            actionType: 'encryption',
        });
        //clear mode builder
        DataFormatStore.resetModeBuilder();
        run(
            Promise.all([
                EncryptionStore.listDatabases(id),
                EncryptionStore.getSchema(id as string),
                ApplicationStore.read(id).then(() => {
                    const application = ApplicationStore.get(id);
                    return Promise.all([
                        KeystoreStore.read(application?.keystore as string),
                        KeyDetailsStore.read(application?.keystore as string),
                    ]);
                }),
                DataFormatStore.listAll(id as string),
                DataFormatStore.readRbacConfig(id, true),
            ])
        );
    }, []);

    return (
        <Observer>
            {() => {
                const application: ApplicationDetailsV2 | undefined = ApplicationStore.get(id);
                const handleCancel = () => {
                    history.replace('/applications');
                    EncryptionStore.resetSelected({
                        actionType: 'encryption',
                    });
                };
                const setSelectedPath = async (c: SelectedColumn) => {
                    try {
                        if (
                            !Boolean(EncryptionStore.selectedEncryptionDatabase) ||
                            !Boolean(EncryptionStore.selectedEncryptionTable) ||
                            EncryptionStore.selectedEncryptionDatabase !== c.dbName ||
                            EncryptionStore.selectedEncryptionTable !== c.table
                        ) {
                            if (fourLevel) {
                                await EncryptionStore.listDatabaseSchemas(id, c.dbName);
                                await EncryptionStore.listSchemaTables(id, c.database);
                                await EncryptionStore.listSchemaTableColumns(id as string, c.database, c.table);
                                await EncryptionStore.listSchemaViews(id as string, c.database);
                                EncryptionStore.setSelectedSchema('encryption', c.database);
                            } else {
                                await EncryptionStore.listTables(id as string, c.database);
                                await EncryptionStore.listViews(id as string, c.database);
                                await EncryptionStore.listColumns(id as string, c.database, c.table);
                            }
                            EncryptionStore.setSelectedTable('encryption', c.table);
                            EncryptionStore.setSelectedDatabase('encryption', c.dbName);
                        }
                    } catch (error) {
                        setToast({
                            title: JSON.parse(error.message).errors.join(', '),
                            type: 'danger',
                            show: true,
                        });
                    }
                };

                const handleNext = async () => {
                    const colWithoutProtectionSelected = EncryptionStore.selectedEncryptionColumns.find(
                        c =>
                            !Boolean(c.selections.policy) &&
                            !Boolean(c.selections.rbacPolicy) &&
                            !Boolean(c.selections.maskingMode) &&
                            !Boolean(c.selections.encryptionMode)
                    );
                    if (Boolean(colWithoutProtectionSelected)) {
                        const col = colWithoutProtectionSelected as SelectedColumn;
                        ToastStore.push({
                            type: 'danger',
                            title: t('encryption.selectDataProtection', {
                                db: col.database,
                                table: col.table,
                                column: col.name,
                            }),
                        });
                        return;
                    }
                    try {
                        const grantsNeeded = await databaseClient.readGrants(
                            application?.db as string,
                            encodeURIComponent(EncryptionStore.selectedEncryptionDatabases.join(','))
                        );
                        if (Array.isArray(grantsNeeded)) {
                            if (grantsNeeded.length === 0) {
                                history.push(`/application/encryption/${id}/confirm`);
                            } else {
                                setModals({ databaseGrants: true });
                            }
                        }
                    } catch (error) {
                        setToast({
                            title: t('encryption.fetchGrantsFailed'),
                            type: 'danger',
                            show: true,
                        });
                    }
                };
                const databases = EncryptionStore.databases.slice();
                const dbs: EncryptionDatabase[] = filtered.query !== '' ? filtered.results : databases;
                const databaseTables = EncryptionStore.tables.slice();
                const databaseViews = EncryptionStore.views.slice();
                const tables = filteredTables.queryTable !== '' ? filteredTables.resultsTable : databaseTables;
                const views = filteredViews.queryView !== '' ? filteredViews.resultsView : databaseViews;
                const schemas = EncryptionStore.dbSchemas.slice();
                const dbSchemas = filteredSchemas.querySchema !== '' ? filteredSchemas.resultsSchema : schemas;
                const tableColumns = EncryptionStore.columns.slice();
                const columns = filteredColumns.queryColumn !== '' ? filteredColumns.resultsColumn : tableColumns;
                const fourLevel = application?.fourLevel;
                const filterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                    const query = (e.target.value || '').toLowerCase();
                    if (!query) {
                        setFiltered({ query: query, results: [] });
                        setFilteredSchemas({ querySchema: query, resultsSchema: [] });
                        setFilteredTables({ queryTable: query, resultsTable: [] });
                        setFilteredViews({ queryView: query, resultsView: [] });
                    }
                    // Search for table(s) within database if 3-level database or schema is already selected
                    else if (
                        (EncryptionStore.selectedEncryptionDatabase && !fourLevel) ||
                        EncryptionStore.selectedEncryptionSchema
                    ) {
                        const resultsTable = databaseTables.filter(table => table.name.toLowerCase().includes(query));
                        setFilteredTables({ queryTable: query, resultsTable });
                        const resultViews = databaseViews.filter(view => view.name.toLowerCase().includes(query));
                        setFilteredViews({ queryView: query, resultsView: resultViews });
                    }
                    // Search for schema(s) within database
                    else if (EncryptionStore.selectedEncryptionDatabase && fourLevel) {
                        const resultsSchema = schemas.filter(schema => schema.schemaName.toLowerCase().includes(query));
                        setFilteredSchemas({ querySchema: query, resultsSchema });
                    }
                    // Search for database(s)
                    else {
                        const results = databases.filter(d => d.name.toLowerCase().includes(query));
                        setFiltered({ results, query });
                    }
                };
                const filterChangeColumns = (e: React.ChangeEvent<HTMLInputElement>) => {
                    const queryColumn = e.target.value || '';
                    const resultsColumn =
                        columns.filter((d: ColumnMetaData) =>
                            d.name.toLowerCase().includes(queryColumn.toLowerCase())
                        ) || [];

                    setFilteredColumns({ resultsColumn, queryColumn });
                };
                useEffect(() => {
                    setFilteredColumns({ resultsColumn: [], queryColumn: '' });
                }, [EncryptionStore.selectedEncryptionTable]);
                const selectedEncryptionColumns = EncryptionStore.selectedEncryptionColumns.filter(
                    d => d.application === application?.id
                );

                const keystore: Keystore | undefined = KeystoreStore.get(application?.keystore as string);
                const keys: KeyDetails[] = KeyDetailsStore.get(application?.keystore as string, {
                    forEncryption: true,
                });

                const pageHeight = usePageHeight();
                const navHeight = pageHeight - 86; //86 is height of buttons + topnav;
                const menuItemsHeader = [];
                const menuItemsDatabase = useMemo(() => {
                    const menuItemsDatabase: any = {};
                    dbs.forEach((db, index) => {
                        if (FeatureFlagStore.futureBpsEntry) {
                            if (fourLevel) {
                                (menuItemsDatabase[db.name] = menuItemsDatabase[db.name] || []).push({
                                    text: (
                                        <>
                                            <TreeTableIcon /> {t('encryption.addSchema')}
                                        </>
                                    ),
                                    onClick: (e: React.MouseEvent) => {
                                        e.stopPropagation();
                                        EncryptionStore.setSelectedDatabase('encryption', db.name);
                                        setModals({
                                            addNewSchema: true,
                                            data: {
                                                schemas: dbSchemas.map(schema => schema.schemaName),
                                            },
                                        });
                                    },
                                    'data-testid': 'add-schema-' + db.name,
                                });
                            } else {
                                (menuItemsDatabase[db.name] = menuItemsDatabase[db.name] || []).push({
                                    text: (
                                        <>
                                            <TreeTableIcon /> {t('encryption.addTable')}
                                        </>
                                    ),
                                    onClick: (e: React.MouseEvent) => {
                                        e.stopPropagation();
                                        EncryptionStore.setSelectedDatabase('encryption', db.name);
                                        setModals({
                                            addNewTable: true,
                                            data: { tables: tables.map(table => table.name) },
                                        });
                                    },
                                    'data-testid': 'add-table-' + db.name,
                                });
                            }
                            if (db?.new) {
                                menuItemsDatabase[db.name].push(
                                    {
                                        text: (
                                            <>
                                                <TreeDatabaseIcon /> {t('database.edit')}
                                            </>
                                        ),
                                        onClick: (e: React.MouseEvent) => {
                                            e.stopPropagation();
                                            setModals({
                                                addNewDatabase: true,
                                                data: { existingDbName: db.name },
                                            });
                                        },
                                        'data-testid': 'edit-database-' + db.name,
                                    },
                                    {
                                        text: (
                                            <>
                                                <TreeDeleteIcon color="#DC2626" /> {t('database.delete')}
                                            </>
                                        ),
                                        onClick: (e: React.MouseEvent) => {
                                            e.stopPropagation();
                                            setModals({
                                                deleteNewDatabase: true,
                                                data: { dbName: db.name, touched: db.touched },
                                            });
                                        },
                                        className: 'text-red-500',
                                        'data-testid': 'delete-database-' + db.name,
                                    }
                                );
                            }
                        }
                    });
                    return menuItemsDatabase;
                }, [dbs]);

                const menuItemsSchema = useMemo(() => {
                    const menuItemsSchema: any = {};
                    dbSchemas.forEach((schema, index) => {
                        if (
                            (application?.dbType === DatabaseTypeEnum.POSTGRES ||
                                application?.dbType === DatabaseTypeEnum.RDS_POSTGRES) &&
                            !schema.new
                        ) {
                            menuItemsSchema[schema.name] = [
                                {
                                    text: (
                                        <>
                                            <TreeAddIcon /> {t('database.addOwner')}
                                        </>
                                    ),
                                    onClick: (e: React.MouseEvent) => {
                                        e.stopPropagation();
                                        setModals({
                                            addDbOwner: true,
                                            data: { dbName: schema.name, dbId: application?.db },
                                        });
                                    },
                                    'data-testid': 'add-db-owner-' + schema.name,
                                },
                            ];
                        }
                        if (FeatureFlagStore.futureBpsEntry) {
                            (menuItemsSchema[schema.name] = menuItemsSchema[schema.name] || []).push({
                                text: (
                                    <>
                                        <TreeTableIcon /> {t('encryption.addTable')}
                                    </>
                                ),
                                onClick: (e: React.MouseEvent) => {
                                    e.stopPropagation();
                                    EncryptionStore.setSelectedSchema('encryption', schema.name);
                                    setModals({
                                        addNewTable: true,
                                        data: { tables: tables.map(table => table.name) },
                                    });
                                },
                                'data-testid': 'add-table-' + schema.name,
                            });
                            if (schema?.new) {
                                menuItemsSchema[schema.name].push(
                                    {
                                        text: (
                                            <>
                                                <SchemaIcon /> {t('schema.edit')}
                                            </>
                                        ),
                                        onClick: (e: React.MouseEvent) => {
                                            e.stopPropagation();
                                            setModals({
                                                addNewSchema: true,
                                                data: { existingSchemaName: schema.schemaName },
                                            });
                                        },
                                        'data-testid': 'edit-schema-' + schema.name,
                                    },
                                    {
                                        text: (
                                            <>
                                                <TreeDeleteIcon color="#DC2626" /> {t('schema.delete')}
                                            </>
                                        ),
                                        onClick: (e: React.MouseEvent) => {
                                            e.stopPropagation();
                                            setModals({
                                                deleteNewSchema: true,
                                                data: { schemaName: schema.name, touched: schema.touched },
                                            });
                                        },
                                        className: 'text-red-500',
                                        'data-testid': 'delete-schema-' + schema.name,
                                    }
                                );
                            }
                        }
                    });
                    return menuItemsSchema;
                }, [dbSchemas]);

                const menuItemsTable = useMemo(() => {
                    const menuItemsTable: any = {};
                    tables.forEach((table, index) => {
                        if (FeatureFlagStore.futureBpsEntry && table.new) {
                            menuItemsTable[table.name] = [
                                {
                                    text: (
                                        <>
                                            <TreeTableIcon /> {t('encryption.editTable')}
                                        </>
                                    ),
                                    onClick: (e: React.MouseEvent) => {
                                        e.stopPropagation();
                                        setModals({ addNewTable: true, data: { existingTableName: table.name } });
                                    },
                                    'data-testid': 'edit-table-' + table.name,
                                },
                                {
                                    text: (
                                        <>
                                            <TreeDeleteIcon color="#DC2626" /> {t('encryption.deleteTable')}
                                        </>
                                    ),
                                    onClick: (e: React.MouseEvent) => {
                                        e.stopPropagation();
                                        setModals({
                                            deleteNewTable: true,
                                            data: { tableName: table.name, touched: table.touched },
                                        });
                                    },
                                    className: 'text-red-500',
                                    'data-testid': 'delete-table-' + table.name,
                                },
                            ];
                        }
                    });
                    return menuItemsTable;
                }, [tables]);
                if (FeatureFlagStore.futureBpsEntry) {
                    menuItemsHeader.push({
                        text: (
                            <>
                                <TreeDatabaseIcon /> {t('database.addDatabase')}
                            </>
                        ),
                        onClick: (e: React.MouseEvent) => {
                            e.stopPropagation();
                            setModals({ addNewDatabase: true, data: { dbs: dbs.map(db => db.name) } });
                        },
                        'data-testid': 'add-database',
                    });
                }
                return (
                    <>
                        {toast.show ? (
                            <Toast
                                title={toast.title}
                                type={toast.type}
                                onDismiss={() => setToast({ title: '', show: false, type: 'success' })}
                            />
                        ) : null}
                        <GrantsModal
                            id={application?.db as string}
                            databases={EncryptionStore.selectedEncryptionDatabases.join(',')}
                            onSuccess={() => {
                                handleNext();
                            }}
                            open={modals.databaseGrants}
                            onClose={() =>
                                setModals({
                                    databaseGrants: false,
                                })
                            }
                        />
                        {keystore?.expired ? (
                            <div className="absolute left-1/2 top-0 z-100 transform -translate-x-1/2 mt-1">
                                <Alert type="danger">
                                    <div
                                        className="alert-message-container flex items-center"
                                        dangerouslySetInnerHTML={{
                                            __html: t('encryption.keystoreCertExpired', {
                                                name: keystore?.name,
                                            }),
                                        }}></div>
                                    <div className="w-48 font-light flex justify-end items-center h-full">
                                        <button
                                            onClick={() => {
                                                handleUpdateCert(keystore?.id as string, application?.id as string);
                                            }}
                                            data-testid={`update-keystore-cert-btn`}
                                            id="update-keystore-cert-btn"
                                            className="bx--btn bx--btn--icon-only-transparent text-blue">
                                            <HistoryIcon fontSize="small" /> {t('main.update')}
                                        </button>
                                    </div>
                                </Alert>
                            </div>
                        ) : null}
                        <Content className="flex flex-col">
                            <Header>
                                <div className="flex-1 px-4 flex items-center">
                                    <h1 className="text-xl">
                                        <span className="text-gray-100">{t('encryption.schemaBuilder')}:</span>{' '}
                                        {application?.appName ?? ''}
                                    </h1>
                                </div>
                            </Header>
                            <div className="encryption flex flex-1 items-stretch">
                                <div
                                    style={{ height: navHeight }}
                                    className="flex flex-col flex-shrink-0 overflow-y-auto bg-white w-76">
                                    <header
                                        className="relative z-10 flex-shrink-0 flex shadow h-8 text-xs"
                                        role="banner">
                                        <div className="flex-1 px-4 flex justify-between bg-gray-200 items-center border-solid border-t-2 border-r-2 border-gray-300">
                                            <span className="text-black font-bold">
                                                {t('encryption.dbGrid.headerTreeMenu')}
                                            </span>
                                            {FeatureFlagStore.futureBpsEntry ? (
                                                <OverflowMenu
                                                    data-testid="tree-header-menu"
                                                    actuator={{
                                                        'data-testid': 'tree-header-add-btn',
                                                        text: <TreeAddIcon />,
                                                        className:
                                                            'p-1 focus:outline-none focus:bg-white hover:bg-gray-300',
                                                        onClick: (e: React.MouseEvent) => {
                                                            e.stopPropagation();
                                                        },
                                                    }}
                                                    menuItems={menuItemsHeader}
                                                />
                                            ) : null}
                                        </div>
                                    </header>

                                    <Search
                                        className="encryption-grid-filter"
                                        placeholder={t('encryption.dbGrid.ariaSearch')}
                                        onChange={filterChange}
                                        name="encryption-dbgrid-filter"
                                        autoComplete="none"
                                    />

                                    <EncryptionTreeView
                                        id={id as string}
                                        dbs={dbs}
                                        tables={tables}
                                        views={views}
                                        dbSchemas={dbSchemas}
                                        menuItemsDatabase={menuItemsDatabase}
                                        menuItemsSchema={menuItemsSchema}
                                        menuItemsTable={menuItemsTable}
                                        cryptoAction="encryption"
                                        fourLevel={fourLevel}
                                        handleFilterChange={(obj: String) => {
                                            if (obj === 'table') {
                                                setFilteredTables({ queryTable: '', resultsTable: [] });
                                                setFilteredViews({ queryView: '', resultsView: [] });
                                            } else {
                                                setFilteredSchemas({ querySchema: '', resultsSchema: [] });
                                            }
                                        }}
                                    />

                                    <ModeLibrary />

                                    <div className="flex border-t-2 border-r-2 border-solid border-gray-300 items-center h-18">
                                        <Button
                                            theme="secondary"
                                            className="w-36 h-10 my-4 ml-4 mr-1 text-sm"
                                            onClick={() => {
                                                handleCancel();
                                            }}>
                                            {t('main.cancel')}
                                        </Button>
                                        <Button
                                            className="w-36 h-10 my-4 mr-4 ml-2 text-sm"
                                            id="do-encrypt-next-btn"
                                            theme={
                                                selectedEncryptionColumns.length === 0 ||
                                                //if column is unprotected
                                                !selectedEncryptionColumns?.every(
                                                    col =>
                                                        col.selections.policy ||
                                                        col.selections.encryptionMode ||
                                                        col.selections.maskingMode ||
                                                        col.selections.rbacPolicy ||
                                                        col.selections.jsonPolicy
                                                )
                                                    ? 'disabled'
                                                    : 'primary'
                                            }
                                            onClick={handleNext}>
                                            {t('encryption.review')}
                                        </Button>
                                    </div>
                                </div>

                                {!DataFormatStore.showEncModeView &&
                                    !DataFormatStore.showMaskModeView &&
                                    !DataFormatStore.showRbacConfigView &&
                                    !DataFormatStore.showRbacUsrGrpView &&
                                    !DataFormatStore.showRbacUsrGrpCreate &&
                                    !DataFormatStore.showRbacPolicyView &&
                                    !DataFormatStore.showRbacPolicyCreate &&
                                    !DataFormatStore.showJsonPolicyView && (
                                        <div className="flex-grow">
                                            <div className="flex w-full flex-grow bg-gray-200 px-3 h-8 items-center border-solid border border-gray-300">
                                                {status === 'loading' || status === 'idle' ? (
                                                    <SkeletonText />
                                                ) : (
                                                    <p className="font-thin uppercase">
                                                        <span
                                                            data-testid="selected-columns-count"
                                                            className="font-bold text-xs">
                                                            columns ({selectedEncryptionColumns.length})
                                                        </span>{' '}
                                                    </p>
                                                )}
                                            </div>
                                            <Search
                                                className="encryption-grid-filter"
                                                disabled={!Boolean(EncryptionStore.selectedEncryptionTable)}
                                                value={filteredColumns.queryColumn}
                                                placeholder={t('encryption.columnGrid.ariaSearch')}
                                                onChange={filterChangeColumns}
                                                name="encryption-columngrid-filter"
                                                autoComplete="none"
                                            />
                                            <div className="flex flex-wrap">
                                                <div className="flex-grow">
                                                    <ColumnPills
                                                        type="encryption"
                                                        loading={EncryptionStore.schemaLoading}
                                                        onPathSelect={(c: SelectedColumn) => {
                                                            EncryptionStore.setSelectedDatabase('encryption', '');
                                                            setSelectedPath(c);
                                                        }}
                                                        selectedColumns={selectedEncryptionColumns}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="flex-1 encryption-columns-datagrid">
                                                    <ColumnsTable
                                                        keys={keys}
                                                        addKey={() => {
                                                            const k = KeyDetailsStore.incrementKeyDetailsList(
                                                                application?.keystore as string
                                                            );
                                                            setToast({
                                                                show: true,
                                                                type: 'success',
                                                                title: t('encryption.addKeyIdSuccess', {
                                                                    id: k.keyId,
                                                                }),
                                                            });
                                                        }}
                                                        cryptoAction="encryption"
                                                        sendDeselected={(d: ColumnMetaData) => {
                                                            EncryptionStore.deleteSelectedColumns({
                                                                actionType: 'encryption',
                                                                nextColumns: [d],
                                                            });
                                                        }}
                                                        sendSelected={(d: ColumnMetaData, replace = false) => {
                                                            EncryptionStore.setSelectedColumns({
                                                                actionType: 'encryption',
                                                                nextColumns: [d],
                                                                replace,
                                                            });
                                                        }}
                                                        sendSelectedMultiple={(data: ColumnMetaData[]) => {
                                                            EncryptionStore.setSelectedColumns({
                                                                actionType: 'encryption',
                                                                nextColumns: data,
                                                                replace: false,
                                                            });
                                                        }}
                                                        sendDeselectedMultiple={(data: ColumnMetaData[]) => {
                                                            EncryptionStore.deleteSelectedColumns({
                                                                actionType: 'encryption',
                                                                nextColumns: data,
                                                            });
                                                        }}
                                                        table={EncryptionStore.selectedEncryptionTable}
                                                        database={EncryptionStore.selectedEncryptionDatabase}
                                                        columns={columns}
                                                        selectedColumns={selectedEncryptionColumns}
                                                        requestStatus={status}
                                                        isRecordLevel={application?.recordLevel ?? false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                {DataFormatStore.showEncModeView ? (
                                    <AddEncryptionMode
                                        onSuccess={(name: string, action: string) => {
                                            DataFormatStore.resetModeBuilder();
                                            ToastStore.push({
                                                type: 'success',
                                                title: t('encryption.modeLibrary.addEditSuccessEnc', {
                                                    name,
                                                    action,
                                                }),
                                            });
                                        }}
                                        onClose={() => {
                                            DataFormatStore.resetModeBuilder();
                                        }}
                                        existingMode={DataFormatStore.selectedEncMode}
                                    />
                                ) : null}

                                {DataFormatStore.showMaskModeView ? (
                                    <AddMaskingMode
                                        onSuccess={(name: string, action: string) => {
                                            DataFormatStore.resetModeBuilder();
                                            ToastStore.push({
                                                type: 'success',
                                                title: t('encryption.modeLibrary.addEditSuccessMask', {
                                                    name,
                                                    action,
                                                }),
                                            });
                                        }}
                                        onClose={() => {
                                            DataFormatStore.resetModeBuilder();
                                        }}
                                        existingMode={DataFormatStore.selectedMaskMode}
                                    />
                                ) : null}
                                {DataFormatStore.showRbacConfigView ? (
                                    <AddRbacConfig
                                        onSuccess={(action: string) => {
                                            ToastStore.push({
                                                type: 'success',
                                                title: t(
                                                    'encryption.modeLibrary.rbac.rbacConfig.rbacConfigAddEditSuccess',
                                                    {
                                                        action,
                                                    }
                                                ),
                                            });
                                            DataFormatStore.resetModeBuilder();
                                        }}
                                        onClose={() => {
                                            DataFormatStore.resetModeBuilder();
                                        }}
                                        existingConfig={DataFormatStore.rbacConfig}
                                    />
                                ) : null}
                                {DataFormatStore.showRbacUsrGrpView ? <RbacUsrGrpLanding /> : null}
                                {DataFormatStore.showRbacUsrGrpCreate ? <AddRbacUsrGrp /> : null}
                                {DataFormatStore.showJsonPolicyView ? <AddJsonPolicy /> : null}
                                {DataFormatStore.showRbacPolicyView ? <RbacPolicyLanding /> : null}
                                {DataFormatStore.showRbacPolicyCreate ? <AddRbacPolicy /> : null}
                            </div>
                        </Content>
                        <div>
                            <AddNewDatabaseModal
                                databases={dbs.map(db => db.name)}
                                existingDbName={modals.data.existingDbName}
                                open={modals.addNewDatabase}
                                onClose={() => {
                                    setModals({ addNewDatabase: false, data: {} });
                                }}
                                onSuccess={(dbName: string, action: string) => {
                                    setModals({ addNewDatabase: false, data: {} });
                                    ToastStore.push({
                                        type: 'success',
                                        title: t('database.addEditSuccess', { name: dbName, action: action }),
                                    });
                                }}
                                fourLevel={fourLevel}
                            />
                            <DeleteNewDatabaseModal
                                dbName={modals.data.dbName}
                                open={modals.deleteNewDatabase}
                                onClose={() => {
                                    setModals({ deleteNewDatabase: false, data: {} });
                                }}
                                onSuccess={(dbName: string) => {
                                    setModals({ deleteNewDatabase: false, data: {} });
                                    ToastStore.push({
                                        type: 'success',
                                        title: t('database.deleteSuccess', { name: dbName }),
                                    });
                                }}
                                touched={modals.data.touched}
                            />
                            <AddNewSchemaModal
                                schemas={dbSchemas.map(schema => schema.schemaName)}
                                existingSchemaName={modals.data.existingSchemaName}
                                open={modals.addNewSchema}
                                onClose={() => {
                                    setModals({ addNewSchema: false, data: {} });
                                }}
                                onSuccess={(schemaName: string, action: string) => {
                                    setModals({ addNewSchema: false, data: {} });
                                    ToastStore.push({
                                        type: 'success',
                                        title: t('database.addEditSuccess', { name: schemaName, action: action }),
                                    });
                                }}
                                selectedDatabase={EncryptionStore.selectedEncryptionDatabase}
                            />
                            <DeleteNewSchemaModal
                                schema={modals.data.schemaName}
                                open={modals.deleteNewSchema}
                                onClose={() => {
                                    setModals({ deleteNewSchema: false, data: {} });
                                }}
                                onSuccess={(schemaName: string) => {
                                    setModals({ deleteNewSchema: false, data: {} });
                                    ToastStore.push({
                                        type: 'success',
                                        title: t('schema.deleteSuccess', { name: schemaName }),
                                    });
                                }}
                                selectedDatabase={EncryptionStore.selectedEncryptionDatabase}
                                touched={modals.data.touched}
                            />
                            <AddNewTableModal
                                tables={tables.map(table => table.name)}
                                existingTableName={modals.data.existingTableName}
                                open={modals.addNewTable}
                                onClose={() => {
                                    setModals({ addNewTable: false, data: {} });
                                }}
                                onSuccess={(tableName: string, action: string) => {
                                    setModals({ addNewTable: false, data: {} });
                                    ToastStore.push({
                                        type: 'success',
                                        title: t('encryption.addEditSuccessTable', {
                                            name: tableName,
                                            action: action,
                                        }),
                                    });
                                }}
                                fourLevel={fourLevel}
                                selectedDatabase={EncryptionStore.selectedEncryptionDatabase}
                                selectedSchema={EncryptionStore.selectedEncryptionSchema}
                            />
                            <DeleteNewTableModal
                                tableName={modals.data.tableName}
                                open={modals.deleteNewTable}
                                onClose={() => {
                                    setModals({ deleteNewTable: false, data: {} });
                                }}
                                onSuccess={(tableName: string) => {
                                    setModals({ deleteNewTable: false, data: {} });
                                    ToastStore.push({
                                        type: 'success',
                                        title: t('encryption.deleteTableSuccess', {
                                            name: tableName,
                                            dbName: EncryptionStore.selectedEncryptionSchema
                                                ? EncryptionStore.selectedEncryptionSchema
                                                : EncryptionStore.selectedEncryptionDatabase,
                                        }),
                                    });
                                }}
                                selectedDatabase={EncryptionStore.selectedEncryptionDatabase}
                                selectedSchema={EncryptionStore.selectedEncryptionSchema}
                                touched={modals.data.touched}
                            />
                            <AddDbOwnerModal
                                open={modals.addDbOwner}
                                dbName={modals.data.dbName}
                                dbId={modals.data.dbId}
                                onClose={() => {
                                    setModals({ addDbOwner: false, data: {} });
                                }}
                                onSuccess={(dbName: string) => {
                                    setModals({ addDbOwner: false, data: {} });
                                    ToastStore.push({
                                        type: 'success',
                                        title: t('database.saveDbOwnerCredSuccess', { name: dbName }),
                                    });
                                }}
                            />
                        </div>
                    </>
                );
            }}
        </Observer>
    );
};

export default Encryption;
