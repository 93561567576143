import React from 'react';
import { Observer } from 'mobx-react-lite';
import Content from '@baffle/components/src/content/Content';
import Header from '@baffle/components/src/nav/Header';
import { t } from '@baffle/translate';
import { useManageModals } from '@baffle/components/src/modal/Modal';
import CaCertificates from './cert-store/CaCertificates';
import GlobalSettings from './global-settings/GlobalSettings';
import { FeatureFlagStore } from '../stores/';

const BaffleSettings = () => {
    const initialModalState = {
        globalSettings: true,
        caCertificates: false,
    };
    const [modalsToggled, setModalsToggled] = useManageModals(initialModalState);
    return (
        <Observer>
            {() => {
                return (
                    <>
                        <Content>
                            <Header>
                                <div className="flex-1 my-5 px-10 flex justify-between">
                                    <div className="flex-1 flex items-center">
                                        <h1 className="text-heading-01 text-gray-100">{t('main.settings')}</h1>
                                    </div>
                                </div>
                            </Header>
                            <div className="flex flex-row px-10 my-5 text-sm">
                                <div
                                    data-testid="global-settings-tab"
                                    onClick={() => {
                                        setModalsToggled({
                                            globalSettings: true,
                                            caCertificates: false,
                                        });
                                    }}
                                    className={
                                        modalsToggled.globalSettings
                                            ? 'pb-2 w-40 text-center border-solid border-b-2 border-blue-60 cursor-pointer'
                                            : 'pb-2 w-40 text-center border-solid border-b border-gray-300 cursor-pointer'
                                    }>
                                    {t('main.general')}
                                </div>
                                {FeatureFlagStore.caCertificates ? (
                                    <div
                                        data-testid="ca-certificates-tab"
                                        onClick={() => {
                                            setModalsToggled({
                                                globalSettings: false,
                                                caCertificates: true,
                                            });
                                        }}
                                        className={
                                            modalsToggled.caCertificates
                                                ? 'pb-2 w-40 text-center border-solid border-b-2 border-blue-60 cursor-pointer'
                                                : 'pb-2 w-40 text-center border-solid border-b border-gray-300 cursor-pointer'
                                        }>
                                        {t('certificate.caCertificates')}
                                    </div>
                                ) : null}
                            </div>
                            {modalsToggled.globalSettings ? <GlobalSettings /> : null}
                            {modalsToggled.caCertificates ? <CaCertificates /> : null}
                        </Content>
                    </>
                );
            }}
        </Observer>
    );
};

export default BaffleSettings;
