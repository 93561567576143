import React, { useState, useEffect, useMemo } from 'react';
import { t } from '@baffle/translate';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import { CreateModal, CreateModalBody, CreateModalHeader } from '@baffle/components/src/CreateModal/CreateModal';
import { TextInput, Select, SelectItem } from 'carbon-components-react';
import { MaskingPolicyStore } from '@baffle/manager/src/stores';
import { datatypeGroup, DatatypeGroup, MaskingPolicy } from '@baffle/graphql/src/models';
import useAwait from '@baffle/api-client/src/useAwait';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';
import { createMaskingPolicyInputScheme } from './createMaskingPolicyInputScheme';

interface AddMaskingPolicyProps {
    open: boolean;
    onClose?: () => void;
    onSuccess?: (name: string) => void;
}

const AddMaskingPolicy = ({ open, onClose, onSuccess }: AddMaskingPolicyProps) => {
    const [openModal, setOpen] = useState(open);
    const { run, error, reset } = useAwait();
    const existingMaskingPolicy = MaskingPolicyStore.maskingPolicy;
    const maskingPolicyInputSchemes = useMemo(
        () => createMaskingPolicyInputScheme(MaskingPolicyStore.maskingPolicies, existingMaskingPolicy?.id),
        [MaskingPolicyStore.maskingPolicies, existingMaskingPolicy]
    );

    //@ts-ignore
    const { inputState, inputErrors, inputActions } = useInputState(maskingPolicyInputSchemes);
    const datatypeGroupItems = Object.keys(DatatypeGroup).map(key => ({
        id: key,
        label: key,
        'data-testid': `datatype-li-${key}`,
    }));

    useEffect(() => {
        if (error) {
            NotificationStore.push({
                'data-testid': 'masking-policy-add-failure',
                kind: 'error',
                title: t('globalPolicies.maskingPolicy.addMaskingPolicyError'),
                description: error.message ?? '',
            });
        }
    }, [error]);

    const animateModal = () => {
        return new Promise(() => {
            setTimeout(() => {
                if (onClose) {
                    onClose();
                }
            }, 200);
        });
    };

    const handleClose = async () => {
        if (!openModal) {
            return;
        }
        setOpen(false);
        reset();
        await animateModal();
        inputActions.reset();
    };

    //Sync state from props
    useEffect(() => {
        setOpen(open);
    }, [open]);

    const isValid = inputActions.validateAll(true);

    const handleSave = () => {
        let payload: MaskingPolicy = maskingPolicyInputSchemes.keys.reduce((acc: any, c: any) => {
            acc[c] = (inputState[c] as string)?.trim();
            return acc;
        }, {});

        run(
            MaskingPolicyStore.createMaskingPolicy(payload).then(() => {
                if (onSuccess) {
                    onSuccess(payload.name as string);
                }
                handleClose();
            })
        );
    };

    return (
        <CreateModal
            open={openModal}
            onClose={handleClose}
            isValid={isValid}
            modalId="add-masking-policy-modal"
            primaryButtonText={t('globalPolicies.maskingPolicy.createMaskingPolicy')}
            primaryButtonTheme="primary"
            primaryButton={() => handleSave()}>
            <CreateModalHeader titleText={t('globalPolicies.maskingPolicy.addMaskingPolicyModalHealine')} />
            <CreateModalBody>
                <div className="text-sm font-normal leading-5 mb-4 mt-2 mr-6">
                    {t('globalPolicies.maskingPolicy.maskingPolicyModalMessage')}
                </div>
                <div id="masking-policy-name" className="flex flex-wrap mb-6 items-end">
                    <div className="flex-1 text-sm name-input-container">
                        <TextInput
                            id="masking-policy-name-input"
                            name="masking-policy-name-input"
                            data-testid="masking-policy-name-input"
                            labelText={t('globalPolicies.maskingPolicy.maskingPolicyNameLabel')}
                            placeholder={t('globalPolicies.maskingPolicy.maskingPolicyNamePlaceholder')}
                            invalid={Boolean(inputErrors.name)}
                            invalidText={inputErrors.name}
                            value={(inputState.name as string) || ''}
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                inputActions.validateField({
                                    field: 'name',
                                    skipNull: true,
                                });
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                let val = e.target.value || '';
                                inputActions.setField('name', val);
                            }}
                        />
                    </div>
                </div>
                <div className="flex flex-wrap mb-6 items-end">
                    <div className="flex-1 datatype-input-container">
                        <Select
                            id="masking-policy-datatype-input"
                            labelText={t('globalPolicies.maskingPolicy.maskingPolicyDatatypeLabel')}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                inputActions.setFields({
                                    datatypeGroup: val as string,
                                    mode: 'FIXED',
                                });
                            }}
                            value={inputState.datatypeGroup || ''}>
                            <SelectItem disabled hidden text={t('main.chooseOption')} value="placeholder-item" />
                            {datatypeGroupItems.map((datatype, i) => {
                                return (
                                    <SelectItem
                                        datatest_id={datatype['data-testid']}
                                        key={i}
                                        text={datatype.label}
                                        value={datatype.id}
                                    />
                                );
                            })}
                        </Select>
                    </div>
                </div>
                <div className="flex flex-wrap mb-6 items-end">
                    <div className="flex-1 mode-input-container">
                        <Select
                            id="masking-policy-mode-input"
                            labelText={t('globalPolicies.maskingPolicy.maskingPolicyModeLabel')}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                if (val === 'CHARACTER' || val === 'NUMERIC') {
                                    inputActions.setFields({
                                        value: null,
                                        mode: val as string,
                                    });
                                } else {
                                    inputActions.setFields({
                                        value: '',
                                        mode: val as string,
                                    });
                                }
                            }}
                            value={inputState.mode || ''}>
                            <SelectItem disabled hidden text={t('main.chooseOption')} value="placeholder-item" />
                            {inputState.datatypeGroup
                                ? DatatypeGroup[inputState.datatypeGroup as datatypeGroup].map((mode: string, i) => (
                                      <SelectItem id={mode} key={i} text={mode} value={mode} />
                                  ))
                                : []}
                        </Select>
                    </div>
                </div>
                {(inputState.mode === 'FIXED' || inputState.mode === 'PATTERN') && (
                    <div id="masking-policy-value" className="flex flex-wrap mb-6 items-end">
                        <div className="flex-1 text-sm value-input-container">
                            <TextInput
                                id="masking-policy-value-input"
                                name="masking-policy-value-input"
                                data-testid="masking-policy-value-input"
                                labelText={
                                    inputState.mode === 'FIXED'
                                        ? t('globalPolicies.maskingPolicy.maskingPolicyValueLabel')
                                        : t('globalPolicies.maskingPolicy.maskingPolicyPatternLabel')
                                }
                                placeholder={t('globalPolicies.maskingPolicy.maskingPolicyValuePlaceholder')}
                                invalid={Boolean(inputErrors.value)}
                                invalidText={
                                    inputErrors.value + `${inputState.mode == 'FIXED' ? ' value' : ' pattern'}`
                                }
                                value={(inputState.value as string) || ''}
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    inputActions.validateField({
                                        field: 'value',
                                        skipNull: true,
                                    });
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    let val = e.target.value || '';
                                    inputActions.setField('value', val);
                                }}
                            />
                            {inputState.mode === 'FIXED' && (
                                <p className="text-gray-700 text-xxxs">{t('globalPolicies.helpTextFixed')}</p>
                            )}
                            {inputState.mode === 'PATTERN' && (
                                <>
                                    <p className="text-gray-700 text-xxxs">{t('globalPolicies.helpTextPattern1')}</p>
                                    <b className="text-gray-700 text-xxxs">d</b>
                                    <span className="text-gray-500 text-xxxs">
                                        {' '}
                                        = {t('globalPolicies.helpTextPattern2')}
                                    </span>
                                    <br />
                                    <b className="text-gray-700 text-xxxs">c</b>
                                    <span className="text-gray-500 text-xxxs">
                                        {' '}
                                        = {t('globalPolicies.helpTextPattern3')}
                                    </span>
                                    <br />
                                    <b className="text-gray-700 text-xxxs">C</b>
                                    <span className="text-gray-500 text-xxxs">
                                        {' '}
                                        = {t('globalPolicies.helpTextPattern4')}
                                    </span>
                                    <br />
                                    <b className="text-gray-700 text-xxxs">v</b>
                                    <span className="text-gray-500 text-xxxs">
                                        {' '}
                                        = {t('globalPolicies.helpTextPattern5')}
                                    </span>
                                    <br />
                                    <b className="text-gray-700 text-xxxs">{t('globalPolicies.helpTextOtherChar')}</b>
                                    <span className="text-gray-500 text-xxxs">
                                        {' '}
                                        = {t('globalPolicies.helpTextPattern6')}
                                    </span>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </CreateModalBody>
        </CreateModal>
    );
};

export default AddMaskingPolicy;
