import React, { useEffect } from 'react';
import { Observer } from 'mobx-react-lite';
import { t } from '@baffle/translate';
import DataGrid from '@baffle/components/src/grid/data-grid';
import useAwait from '@baffle/api-client/src/useAwait';
import { DatabaseStore } from '@baffle/manager/src/stores';
import { useManageModals } from '@baffle/components/src/modal/Modal';
import { DatabasePayloadV2 } from '@baffle/graphql/src/models';
import { useHistory } from 'react-router-dom';
import Content from '@baffle/components/src/content/Content';
import Header from '@baffle/components/src/nav/Header';
import databaseClient from '@baffle/api-client/src/databaseClientV2';
import SpinnerOverlay from '@baffle/components/src/loader/SpinnerOverlay';
import DeleteDatabaseModal from './DeleteDatabaseModal';
import RenameDatabaseModal from './RenameDatabaseModal';
import EditDatabaseModal from './EditDatabaseModal';
import { NotificationStore } from '../../stores/NotificationStore';

const DatabasesV2 = () => {
    const { status, error, run } = useAwait();

    useEffect(() => {
        run(DatabaseStore.readDatabases());
    }, []);

    const emptyCol = {
        id: '',
        header1: '',
        header2: '',
        header3: '',
        header4: '',
        header5: '',
        header6: '',
    };

    const headers = [
        {
            key: 'header1',
            header: t('database.databaseHeader1'),
            sortable: true,
        },
        {
            key: 'header2',
            header: t('database.databaseHeader2'),
            sortable: true,
        },
        {
            key: 'header3',
            header: t('database.databaseHeader3'),
            sortable: true,
        },
        {
            key: 'header4',
            header: t('database.header4'),
            sortable: false,
        },
        {
            key: 'header5',
            header: t('database.databaseHeader5'),
            sortable: false,
        },
    ];

    return (
        <Observer>
            {() => {
                const history = useHistory();

                interface DatabaseModals {
                    editDatabase: boolean;
                    deleteDatabase: boolean;
                    renameDatabase: boolean;
                }

                const initialModalState = {
                    editDatabase: false,
                    deleteDatabase: false,
                    renameDatabase: false,
                };

                const [modals, setModals] = useManageModals<DatabaseModals>(initialModalState);

                useEffect(() => {
                    if (error) {
                        setModals(initialModalState);
                        NotificationStore.push({ title: error.message, kind: 'error' });
                    }
                }, [error]);

                const databases: DatabasePayloadV2[] = DatabaseStore.databases;

                const onAdd = () => {
                    history.push('/databasesV2/create');
                };

                const onDelete = (id: string) => {
                    DatabaseStore.setDatabase(DatabaseStore.databases.find(db => db.id === id) as DatabasePayloadV2);
                    setModals({ deleteDatabase: true });
                };

                const onRename = (id: string) => {
                    DatabaseStore.setDatabase(DatabaseStore.databases.find(db => db.id === id) as DatabasePayloadV2);
                    setModals({ renameDatabase: true });
                };
                const onEdit = (id: string) => {
                    DatabaseStore.setDatabase(DatabaseStore.databases.find(db => db.id === id) as DatabasePayloadV2);
                    setModals({ editDatabase: true });
                };

                const deleteDatabase = () => {
                    run(
                        databaseClient.deleteDatabase(DatabaseStore.database?.id as string).then(() => {
                            DatabaseStore.readDatabases().then(() => {
                                setModals({ deleteDatabase: false });
                                NotificationStore.push({
                                    title: t('database.deleteSuccess', { name: DatabaseStore.database?.name }),
                                    kind: 'success',
                                });
                            });
                        })
                    );
                };

                const renameDatabase = (name: string) => {
                    run(
                        databaseClient
                            .renameDatabase(DatabaseStore.database?.id as string, { newName: name })
                            .then(() => {
                                DatabaseStore.readDatabases().then(() => {
                                    setModals({ renameDatabase: false });
                                    NotificationStore.push({
                                        title: t('database.renameSuccess', { name }),
                                        kind: 'success',
                                    });
                                });
                            })
                    );
                };

                const rows =
                    status === 'loading' || status === 'idle'
                        ? //add  skeleton row on initial load
                          [...Array(Math.floor(1)).keys()].map(() => emptyCol)
                        : databases.map((database: DatabasePayloadV2, i: number) => {
                              return {
                                  selectedId: database?.id,
                                  id: database.id,
                                  header1: database.name,
                                  header2: database.environment,
                                  header3: database.hostname,
                                  header4: database.ssl ? 'Yes' : 'No',
                                  link: '/databasesV2/' + database.id,
                                  overFlowMenu: [
                                      {
                                          name: t('database.rename'),
                                          onClick: (id: string) => onRename(id as string),
                                      },
                                      {
                                          name: t('main.edit'),
                                          onClick: (id: string) => onEdit(id as string),
                                      },
                                      {
                                          name: t('main.delete'),
                                          onClick: (id: string) => onDelete(id as string),
                                      },
                                  ],
                              };
                          });

                return (
                    <>
                        {(status === 'loading' || status === 'idle') && <SpinnerOverlay description="" />}
                        <Content>
                            <Header>
                                <div className="flex-1 my-5 px-10 flex justify-between">
                                    <div className="flex-1 flex items-center">
                                        <h1 className="text-heading-01 text-gray-100">{t('main.databases')}</h1>
                                    </div>
                                </div>
                            </Header>

                            {modals.editDatabase ? (
                                <EditDatabaseModal
                                    open={modals.editDatabase}
                                    onClose={() => setModals({ editDatabase: false })}
                                />
                            ) : null}

                            {modals.renameDatabase ? (
                                <RenameDatabaseModal
                                    open={modals.renameDatabase}
                                    onClose={() => setModals({ renameDatabase: false })}
                                    onSubmit={renameDatabase}
                                />
                            ) : null}

                            {modals.deleteDatabase ? (
                                <DeleteDatabaseModal
                                    open={modals.deleteDatabase}
                                    onClose={() => setModals({ deleteDatabase: false })}
                                    onSubmit={deleteDatabase}
                                />
                            ) : null}

                            <DataGrid
                                idPrefix="databases"
                                rows={rows}
                                headers={headers}
                                onAdd={onAdd}
                                showSearch={true}
                                addButtonCaption={t('database.create')}></DataGrid>
                        </Content>
                    </>
                );
            }}
        </Observer>
    );
};

export default DatabasesV2;
