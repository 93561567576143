import { DatabaseProxy } from '@baffle/graphql/src/models';
import { t } from '@baffle/translate';
import Joi from '@hapi/joi';

export default function CreateDataBaseProxyInputScheme(databaseProxies: DatabaseProxy[], id: string | undefined) {
    const dataBaseProxyInputSchema = {
        ns: 'databaseProxies',
        keys: ['name', 'database', 'keystore'],
        validationSchema: {
            name: Joi.string()
                .trim()
                .min(1)
                .max(30)
                .pattern(/^[a-zA-Z0-9_-\s]+$/)
                .custom((val, helper) => {
                    if (
                        databaseProxies.some(
                            (m: DatabaseProxy) =>
                                m.id !== id && m.name.trim().toLowerCase() === val.trim().toLowerCase()
                        )
                    ) {
                        return helper.message({
                            custom: t('databaseProxies.duplicateDataBaseProxyError'),
                        });
                    }
                })
                .messages({
                    'string.max': t('main.invalidNameLength'),
                    'string.pattern.base': t('databaseProxies.nameInputInvalid'),
                    'string.empty': t('databaseProxies.nameInputInvalid'),
                }),
            database: Joi.object()
                .min(1)
                .required(),
            keystore: Joi.object()
                .min(1)
                .required(),
        },
    };
    return dataBaseProxyInputSchema;
}

export function DatabaseProxyRenameScheme(databaseProxies: DatabaseProxy[], id: string | undefined) {
    const databaseProxyRenameSchema = {
        ns: 'database',
        keys: ['name'],
        validationSchema: {
            name: Joi.string()
                .trim()
                .min(1)
                .max(30)
                .required()
                .pattern(/^[a-zA-Z0-9_-\s]+$/)
                .custom((val, helper) => {
                    if (
                        databaseProxies.some(
                            (p: DatabaseProxy) =>
                                p.id !== id && p.name.trim().toLowerCase() === val.trim().toLowerCase()
                        )
                    ) {
                        return helper.message({
                            custom: t('databaseProxies.duplicateDataBaseProxyError'),
                        });
                    } else if (
                        databaseProxies.some(
                            (p: DatabaseProxy) => p.id == id && p.name.trim().toLowerCase() === val.trim().toLowerCase()
                        )
                    ) {
                        return helper.message({
                            custom: t('databaseProxies.sameDatabaseProxyNameError'),
                        });
                    }
                })
                .messages({
                    'string.max': t('main.invalidNameLength'),
                    'string.empty': t('databaseProxies.nameInputInvalid'),
                    'string.pattern.base': t('databaseProxies.nameInputInvalid'),
                }),
        },
    };
    return databaseProxyRenameSchema;
}
