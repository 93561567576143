import React from 'react';
import cx from 'classnames';
import Badge, { getDefaultBadgeProps } from './Badge';
import { BaseTheme } from '../types/theme';

interface props {
    count?: number;
    style?: any;
    theme?: BaseTheme;
}

const BadgeCount = ({ count = 0, style = {}, theme, ...rest }: props) => {
    const largeCount = Boolean(count > 9);
    // count is getting converted to string, hence +count
    return !Boolean(+count) ? null : (
        <Badge
            theme={theme}
            getBadgeProps={() => {
                return {
                    ...getDefaultBadgeProps(),
                    className: cx('text-white rounded-full w-4 h-4 text-xs absolute flex items-center justify-center'),
                    style,
                };
            }}>
            {largeCount ? '9+' : count}
        </Badge>
    );
};

export default BadgeCount;
