import React from 'react';
import { TextInput } from 'carbon-components-react';
import { t } from '@baffle/translate';
import { SystemEmailStore } from '../stores';
const AddLocalKeystore = ({ inputState, inputErrors, inputActions }: any) => {
    const env = SystemEmailStore.systemEnvironment;
    return (
        <div className="flex flex-wrap mb-6 items-start">
            <div className="flex-1">
                <TextInput
                    id="baffle-secret-input"
                    labelText={t(`keystore.secretKeyInputLabel.${env}`)}
                    invalidText={t('keystore.secretKeyInputInvalid')}
                    placeholder={t('keystore.secretKeyPlaceholder')}
                    type="password"
                    defaultValue={inputState.secretKey}
                    invalid={Boolean(inputErrors.secretKey)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        inputActions.setField('secretKey', e.target.value.trim() || '')
                    }
                    onBlur={() => {
                        inputActions.validateField({ field: 'secretKey', skipNull: true });
                    }}
                />
                <p className="text-gray-500 mt-2 italic text-xs">{t('setup.passwordCharDisclaimer')}</p>
            </div>
        </div>
    );
};

export default AddLocalKeystore;
