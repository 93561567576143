import { makeObservable, action, observable } from 'mobx';
import { CredentialStoreInfo, SuperAdminInfo, SystemInfo } from '@baffle/graphql/src/models';
class SetupStore {
    constructor() {
        makeObservable(this, {
            systemData: observable,
            superAdminData: observable,
            credentialStoreData: observable,
            setSystemData: action,
            setSuperAdminData: action,
            setCredentialStoreData: action,
        });
    }
    systemData: SystemInfo | null = null;
    superAdminData: SuperAdminInfo | null = null;
    credentialStoreData: CredentialStoreInfo | null = null;

    setSystemData(systemData: SystemInfo) {
        this.systemData = systemData;
    }

    setSuperAdminData(superAdminData: SuperAdminInfo) {
        this.superAdminData = superAdminData;
    }

    setCredentialStoreData(credentialStoreData: CredentialStoreInfo) {
        this.credentialStoreData = credentialStoreData;
    }
}

export default SetupStore;
