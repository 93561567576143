import React, { useEffect } from 'react';
import { t } from '@baffle/translate';
import {
    getDefaultRootModalProps,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '@baffle/components/src/modal/Modal';
import Button from '@baffle/components/src/buttons/Button';
import useAwait from '@baffle/api-client/src/useAwait';
import WarningIcon from '@material-ui/icons/Warning';
import { Checkbox } from 'carbon-components-react';
import { InputActions, InputError, InputState, useInputState } from '../../../utilities/src/inputHooks';
import Joi from '@hapi/joi';
import { Shield } from '@baffle/graphql/src/models';
import shieldClient from '@baffle/api-client/src/shieldClient';
import { ToastStore } from '../stores/ToastStore';
import cx from 'classnames';

interface RestartShieldModalProps {
    open: boolean;
    shield?: Shield;
    subHeader?: string;
    body?: string;
    customRestartFunc?: () => void;
    onClose?: () => void;
    onSuccess?: (inputState: Shield) => void;
}

const RestartShieldModal = ({
    open,
    onClose = () => null,
    customRestartFunc = () => null,
    shield,
    subHeader,
    body,
}: RestartShieldModalProps) => {
    const { error: restartError, reset, run } = useAwait();

    useEffect(() => {
        if (restartError) {
            ToastStore.push({
                type: 'danger',
                title: t('shield.restartFailure'),
            });
        }
    }, [restartError]);

    const { inputState, inputErrors, inputActions } = useInputState({
        keys: ['restartConfirm'],
        ns: 'shield',
        validationSchema: {
            restartConfirm: Joi.boolean().required(),
        },
        defaultValues: { restartConfirm: false },
    });

    const handleClose = () => {
        reset();
        inputActions.setField('restartConfirm', false);
        onClose();
    };

    const restartShield = () => {
        if (shield)
            run(
                shieldClient.createRestartShield(shield.id).then(() => {
                    ToastStore.push({
                        type: 'success',
                        title: t('shield.restartSuccess'),
                    });
                    handleClose();
                })
            );
    };
    const { className: defaultClassName } = getDefaultRootModalProps();
    return (
        <Modal
            open={open}
            onClose={handleClose}
            getRootModalProps={() => {
                return {
                    className: cx(defaultClassName, 'sm:w-164 h-auto'),
                };
            }}>
            <RestartShield
                inputState={inputState}
                inputActions={inputActions}
                inputErrors={inputErrors}
                error={restartError}
                handleClose={handleClose}
                shield={shield}
                restartShield={shield ? restartShield : customRestartFunc}
                subHeader={subHeader}
                body={body}
            />
        </Modal>
    );
};

interface RestartShield {
    handleClose: () => void;
    restartShield: () => void;
    error: Error | null;
    inputState: InputState;
    inputActions: InputActions;
    inputErrors: InputError;
    shield?: Shield;
    subHeader?: string;
    body?: string;
}
const RestartShield = ({
    inputActions,
    inputState,
    handleClose,
    shield,
    restartShield,
    subHeader,
    body,
}: RestartShield) => {
    return (
        <>
            <ModalHeader onClose={handleClose}>
                <div
                    className="mt-0 ml-2 text-orange text-3xl h-36 flex flex-col items-center justify-center"
                    id="modal-headline">
                    <WarningIcon classes={{ root: 'text-5xl text-orange' }} />
                    <h3 className="mt-1">
                        {t('shield.restartShield', {
                            shieldName: shield ? shield.shieldName : t('application.baffleshields'),
                        })}
                    </h3>
                </div>
            </ModalHeader>
            <ModalBody>
                <div className="h-full flex flex-col items-center">
                    <p className="text-lg font-medium">{subHeader ? subHeader : t('decryption.confirmationLabel')}</p>
                    <div className="mt-2 ml-32 mr-32">
                        <Checkbox
                            id={'restart-shield-checkbox'}
                            labelText={body ? body : t('shield.restartShieldCheckboxLabel')}
                            checked={inputState.restartConfirm}
                            onChange={(event: any, { checked, id }: { checked: boolean; id: any }) => {
                                inputActions.setField('restartConfirm', checked);
                            }}
                            className="text-xs"
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex justify-end items-center h-16 w-full">
                    <Button theme="secondary" className="h-full w-1/2" onClick={handleClose} size="lg">
                        {t('main.cancel')}
                    </Button>
                    <Button
                        data-testid="modal-shield-restart-btn"
                        className="h-full w-1/2"
                        onClick={restartShield}
                        theme={!inputState.restartConfirm ? 'disabled' : 'orange'}
                        size="lg">
                        {t('shield.restartShieldConfirm')}
                    </Button>
                </div>
            </ModalFooter>
        </>
    );
};

export default RestartShieldModal;
