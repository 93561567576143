import { observable, action } from 'mobx';
import { GlobalToastState } from '@baffle/components/src/toasts/GlobalToast';

export const ToastStore = observable(
    {
        toasts: observable<GlobalToastState>([]),
        shift() {
            const toasts = this.toasts.slice();
            const toast = toasts.shift();
            this.toasts.replace(toasts);
            return toast;
        },
        push(toast: GlobalToastState) {
            const toasts = this.toasts.slice();
            toasts.push(toast);
            this.toasts.replace(toasts);
        },
        get size() {
            return this.toasts.length;
        },
    },
    {
        shift: action,
        push: action,
    }
);
