import { SessionEnums } from '@baffle/utilities/src/enums';
import storage from '@baffle/utilities/src/storage';
const htmlAttr = (attr: string) => window.document.getElementsByTagName('html')[0].getAttribute(attr);
export const baffleApi = storage.get('baffle-api') ?? (htmlAttr('data-baffle-api') || '');

export const getAuthToken = () => {
    const authToken = storage.get('ngStorage-authToken') || '';
    return authToken;
};

export const signOut = () => {
    storage.remove(SessionEnums.NG_STORAGE_AUTH_TOKEN);
    storage.remove(SessionEnums.NG_STORAGE_REFRESH_TOKEN);
    storage.remove(SessionEnums.NG_STORAGE_AUTH_TOKEN_EXPIRY);
    storage.remove(SessionEnums.NG_STORAGE_USER);
    storage.remove(SessionEnums.NG_STORAGE_IDLE_TIME_EXPIRY);
    document.location.href = `/login`;
};
