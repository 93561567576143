import React, { useEffect, useMemo, useState } from 'react';
import Header from '@baffle/components/src/nav/Header';
import { t } from '@baffle/translate';
import { OverflowMenu, OverflowMenuItem, Search, Tag } from 'carbon-components-react';
import AddIcon from '@material-ui/icons/Add';
import LockOpenOutlined from '@material-ui/icons/LockOpenOutlined';
import Content from '@baffle/components/src/content/Content';
import ApplicationSidePanel from '../sidepanel/ApplicationSidePanel';
import Toast, { ToastState } from '@baffle/components/src/toasts/Toast';
import usePageHeight from '@baffle/utilities/src/usePageHeight';
import { sortGrid, SortInfo, SortDirection } from '@baffle/utilities/src/gridHelpers';
import { stringSort, numberSort } from '@baffle/utilities/src/sorters';
import ReactDataGrid from 'react-data-grid';
import TH from '@baffle/components/src/table/react-data-grid/TH';
import {
    ApplicationDetails,
    EncryptionSchema,
    ConfigFileNameEnum,
    ApplicationV2,
    ShieldForApplicationDetails,
} from '@baffle/graphql/src/models';
import cx from 'classnames';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddApplicationModal from './AddApplicationModal';
import DeleteApplicationModal from './DeleteApplicationModal';
import { StatusMaps } from '@baffle/utilities/src/enums';
import { triggerResize } from '@baffle/utilities/src/resize';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import LockOutlined from '@material-ui/icons/LockOutlined';
import CreateIcon from '@material-ui/icons/CreateOutlined';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import './Applications.scss';
import { useHistory } from 'react-router';
import { buildApplicationCanViewV2 } from '@baffle/utilities/src/buildApplicationCanView';
import Cell from '@baffle/components/src/table/react-data-grid/Cell';
import applicationClient from '@baffle/api-client/src/applicationClient';
import { ApplicationStore, EncryptionStore } from '../stores';
import isDeployed from '@baffle/utilities/src/column/isDeployed';
import Button from '@baffle/components/src/buttons/Button';
import Status from '@baffle/components/src/status/Status';
import DeleteBaffleShieldFromApplicationModal from './DeleteBaffleShieldFromApplicationModal';
import { useManageModals } from '@baffle/components/src/modal/Modal';
import SkeletonText from '@baffle/components/src/skeleton/SkeletonText';
import useAwait from '@baffle/api-client/src/useAwait';
import { Observer } from 'mobx-react-lite';
import DismissAllShieldNotificationsModal from '../shields/DismissAllShieldNotifications';
import { ToastStore } from '../stores/ToastStore';
import agentClient from '@baffle/api-client/src/agentClient';
import usePoller from '@baffle/api-client/src/usePoller';
import RefreshIcon from '@material-ui/icons/Refresh';
import RefreshSyncIdModal from './RefreshSyncIdModal';

export interface ApplicationGridColumn extends ReactDataGrid.Column<any> {
    sortFn?: (a: ApplicationV2, b: ApplicationV2) => 0 | 1 | -1;
}
/**
 *
 * @param schema
 * canDeleteApplication will traverse the encryption schema object to see
 * if there are any columns that have been encrypted
 * it will return true if the user can delete the application safely
 */
const canDeleteApplication = (schema: EncryptionSchema) => {
    for (let i = 0; i < (schema.columns ?? []).length; i++) {
        const column = (schema.columns ?? [])[i];
        if (Boolean(column.touched) && isDeployed(column.deploymentState)) {
            return false;
        }
    }
    return true;
};

const Applications = () => {
    const { run, status, error } = useAwait();
    const fetch = ({ skipStatus } = { skipStatus: false }) => {
        run(ApplicationStore.list(), { skipStatus });
    };

    useEffect(() => {
        fetch();
    }, []);

    usePoller(() => fetch({ skipStatus: true }));

    useEffect(() => {
        if (error) {
            ToastStore.push({ type: 'danger', title: error.message });
        }
    }, [error]);

    return (
        <Observer>
            {() => {
                const [deletedApps, setDeletedApps] = useState([]);

                const initialGridSort: SortInfo = { direction: 'NONE', key: '' };
                const [sort, setSort] = useState(initialGridSort);
                const [selected, setSelected]: any = useState(null);

                interface ApplicationModals {
                    addApplication: boolean;
                    deleteShield: boolean;
                    deleteApplication: boolean;
                    dismissNotifications: boolean;
                    refreshSyncId: boolean;
                    data: {
                        id?: string;
                        name?: string;
                        forceDelete?: boolean;
                        application?: ApplicationV2;
                        appId?: string;
                        shield?: ShieldForApplicationDetails;
                    };
                }
                const initialModalState = {
                    addApplication: false,
                    deleteShield: false,
                    deleteApplication: false,
                    dismissNotifications: false,
                    refreshSyncId: false,
                    data: {},
                };

                const [modals, setModals] = useManageModals<ApplicationModals>(initialModalState);
                const initialResults: ApplicationV2[] = [];
                const [filtered, setFiltered] = useState({ results: initialResults, query: '' });
                const initialToastState: ToastState = {
                    show: false,
                    title: '',
                    type: 'success',
                };
                const [toast, setToast] = useState(initialToastState);
                const pageHeight = usePageHeight();
                const size = Math.floor(pageHeight / 35);
                const pageHeightOffset = 126.99; //126.99 is the header height and search
                let history = useHistory();
                const closeSidePanel = () => {
                    setSelected(null);
                    triggerResize();
                };

                const tryDeleteApplication = (app: ApplicationV2, force?: boolean) => {
                    if (!force) {
                        EncryptionStore.getSchema(app.id)
                            .then(schema => {
                                const canDelete = schema ? canDeleteApplication(schema) : false;

                                if (!canDelete) {
                                    setToast({
                                        show: true,
                                        type: 'danger',
                                        title: t('application.cannotDeleteAppEnc', { name: app.name }),
                                    });
                                    return;
                                }
                                setModals({
                                    deleteApplication: true,
                                    data: { id: app.id, name: app.name, forceDelete: true },
                                });
                            })
                            .catch(err => {
                                setToast({
                                    show: true,
                                    type: 'danger',
                                    title: err.message,
                                });
                            });
                    } else {
                        setModals({
                            deleteApplication: true,
                            data: { id: app.id, name: app.name, forceDelete: true },
                        });
                    }
                };
                const doDeleteApplication = async (app: ApplicationV2) => {
                    try {
                        await applicationClient.remove(app.id, { force: Boolean(modals?.data?.forceDelete) });
                        const deletedAppsUpdated = deletedApps.slice();
                        //@ts-ignore
                        deletedAppsUpdated.push(app.id);
                        setDeletedApps(deletedAppsUpdated);
                        if (selected && selected.id === app.id) {
                            closeSidePanel();
                        }
                        setToast({
                            show: true,
                            type: 'success',
                            title: t('application.deleteSuccess'),
                        });
                        //clear query after deleting
                        setFiltered({ results: [], query: '' });
                        fetch();
                    } catch (error) {
                        setToast({ show: true, type: 'danger', title: JSON.parse(error.message).errors.join(', ') });
                    }
                };

                const applicationsUnfiltered = ApplicationStore.applicationsV2;

                const applications: ApplicationV2[] = useMemo(
                    () => (filtered.query && filtered.query != '' ? filtered.results : applicationsUnfiltered),
                    [filtered, applicationsUnfiltered]
                ).slice();

                const skeletonCol = {
                    id: '',
                    header1: <SkeletonText />,
                    header2: <SkeletonText />,
                    header3: <SkeletonText />,
                    header4: <SkeletonText />,
                    header5: <SkeletonText />,
                    header6: <SkeletonText />,
                    header7: ' ',
                    header8: ' ',
                };

                const selectedApp = useMemo(
                    () => applicationsUnfiltered.find((a: ApplicationV2) => a.id === selected?.id),
                    [selected, applicationsUnfiltered]
                );

                const emptyCol = {
                    id: '',
                    header1: '',
                    header2: '',
                    header3: '',
                    header4: '',
                    header5: '',
                    header6: '',
                    header7: '',
                    header8: '',
                };

                const cols: ApplicationGridColumn[] = [
                    {
                        key: 'header1',
                        name: t('application.header1'),
                        sortable: true,
                        formatter: Cell,
                        sortFn: (a: ApplicationV2, b: ApplicationV2) => stringSort(a.name, b.name),
                        headerRenderer: <TH idPrefix="applications" />,
                    },
                    {
                        key: 'header2',
                        name: t('application.header2'),
                        formatter: Cell,
                        sortable: true,
                        sortFn: (a: ApplicationV2, b: ApplicationV2) => {
                            return stringSort(a.migrationDetails.status, b.migrationDetails.status);
                        },
                        headerRenderer: <TH idPrefix="applications" />,
                    },
                    {
                        key: 'header3',
                        name: t('application.header3'),
                        sortable: true,
                        formatter: Cell,
                        sortFn: (a: ApplicationV2, b: ApplicationV2) => numberSort(a.errorCount, b.errorCount),
                        headerRenderer: <TH idPrefix="applications" />,
                        width: 170,
                    },
                    {
                        key: 'header4',
                        name: t('application.header4'),
                        formatter: Cell,
                        sortable: true,
                        sortFn: (a: ApplicationV2, b: ApplicationV2) => stringSort(a.encType, b.encType),
                        headerRenderer: <TH idPrefix="applications" />,
                        width: 170,
                    },
                    {
                        key: 'header5',
                        name: t('application.header5'),
                        formatter: Cell,
                        sortable: true,
                        sortFn: (a: ApplicationV2, b: ApplicationV2) => stringSort(a.encMode, b.encMode),
                        headerRenderer: <TH idPrefix="applications" />,
                        width: 170,
                    },
                    {
                        key: 'header7',
                        formatter: Cell,
                        name: '', //datagrid typesupport isnt exactly great
                        width: 60,
                    },

                    {
                        key: 'header8',
                        formatter: Cell,
                        name: ' ',
                        width: 10,
                    },
                ];

                sortGrid(sort, cols, applications);

                const rows =
                    status === 'loading' || status === 'idle'
                        ? //add  skeleton row on initial load
                          [...Array(Math.floor(1)).keys()].map(() => skeletonCol)
                        : applications.map((a: ApplicationV2, i: number) => {
                              const canView = buildApplicationCanViewV2(a);
                              const alertMsg =
                                  a.errorCount === 1
                                      ? t(`application.alertSingular`, { 0: a.errorCount })
                                      : t(`application.alertPlural`, { 0: a.errorCount });
                              return {
                                  selectedId: selectedApp?.id,
                                  id: a.id,
                                  header1: a.name,
                                  header2: (
                                      <div data-testid="application-status">
                                          <Status
                                              title={t(
                                                  `application.${
                                                      StatusMaps.appStatusMap[a.migrationDetails.status ?? 'NA']
                                                  }`
                                              )}
                                              type={StatusMaps.statusIconMap[a.migrationDetails.status ?? 'NA']}
                                          />
                                          <span
                                              data-testid="app-rows"
                                              className="text-xxs"
                                              dangerouslySetInnerHTML={{
                                                  __html: t('application.rows', {
                                                      0: `${a.migrationDetails.mgrRows ?? 0}/${a.migrationDetails
                                                          .totalRows ?? 0}`,
                                                  }),
                                              }}
                                          />{' '}
                                          <span
                                              data-testid="app-cols"
                                              className="text-xxs"
                                              dangerouslySetInnerHTML={{
                                                  __html: t('application.totalColumns', {
                                                      0: a.encColumns,
                                                  }),
                                              }}
                                          />
                                      </div>
                                  ),
                                  header3: a.errorCount > 0 ? <Tag type={'red'}>{alertMsg}</Tag> : null,
                                  header4: a.encType,
                                  header5: t(`application.${(a.encMode || '').toLowerCase()}`),
                                  header7: (
                                      <OverflowMenu
                                          menuOffset={{ left: -64 }}
                                          className="datagrid-settings"
                                          ariaLabel={t('application.overFlowMenu')}
                                          onClick={(e: React.MouseEvent) => e.stopPropagation()}
                                          renderIcon={() => <MoreVertIcon className="w-4 h-4 text-gray-70" />}>
                                          <OverflowMenuItem
                                              className="w-100"
                                              itemText={
                                                  <>
                                                      <CreateIcon className="mr-1 w-4 h-4" />{' '}
                                                      {t('application.editConfig')}
                                                  </>
                                              }
                                              onClick={(e: React.MouseEvent) => {
                                                  e.stopPropagation();
                                                  history.push(
                                                      `/application/configuration/${a.id}/${ConfigFileNameEnum.BPS}`
                                                  );
                                              }}
                                          />
                                          {canView.migration ? (
                                              <OverflowMenuItem
                                                  itemText={
                                                      <>
                                                          <RemoveRedEyeIcon className="mr-1 w-4 h-4" />{' '}
                                                          {t('encryption.migrationDetails')}
                                                      </>
                                                  }
                                                  onClick={(e: React.MouseEvent) => {
                                                      e.stopPropagation();
                                                      history.push(`/application/migrationstatus/${a.id}`);
                                                  }}
                                              />
                                          ) : null}
                                          {canView.encryption ? (
                                              <OverflowMenuItem
                                                  itemText={
                                                      <>
                                                          <LockOutlined className="mr-1 w-4 h-4" />{' '}
                                                          {t('encryption.encrypt')}
                                                      </>
                                                  }
                                                  onClick={(e: React.MouseEvent) => {
                                                      e.stopPropagation();
                                                      history.push(`/application/encryption/${a.id}`);
                                                  }}
                                              />
                                          ) : null}
                                          {canView.decryption ? (
                                              <OverflowMenuItem
                                                  onClick={() => history.push(`application/decryption/${a.id}`)}
                                                  itemText={
                                                      <>
                                                          <LockOpenOutlined className="mr-1 w-4 h-4" />{' '}
                                                          {t('encryption.decrypt')}
                                                      </>
                                                  }
                                              />
                                          ) : null}
                                          <OverflowMenuItem
                                              itemText={
                                                  <>
                                                      <div className="config-menu-refresh-sync flex items-center">
                                                          <RefreshIcon className="mr-1 w-4 h-4" />{' '}
                                                          {t('application.refreshSyncId')}
                                                      </div>
                                                  </>
                                              }
                                              onClick={(e: React.MouseEvent) => {
                                                  setModals({ refreshSyncId: true, data: { appId: a.id } });
                                              }}
                                          />
                                          <OverflowMenuItem
                                              itemText={
                                                  <>
                                                      <div className="config-menu-delete flex items-center">
                                                          <DeleteOutlineIcon className="w-4 h-4" />{' '}
                                                          {t('application.delete')}
                                                      </div>
                                                  </>
                                              }
                                              onClick={(e: React.MouseEvent) => {
                                                  e.stopPropagation();
                                                  tryDeleteApplication(a);
                                              }}
                                          />
                                      </OverflowMenu>
                                  ),
                                  header8: '',
                              };
                          });

                //add empty rows to fill the page, if needed
                if (applications.length < size) {
                    const sizeDelta = size - applications.length;
                    //@ts-ignore
                    rows.push(...[...Array(sizeDelta).keys()].map(() => emptyCol));
                }

                const searchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                    const query = e.target.value || '';
                    const results = applicationsUnfiltered.filter((a: ApplicationV2) =>
                        a.name.toLowerCase().includes(query.toLowerCase())
                    );

                    setFiltered({ results, query });
                };

                return (
                    <>
                        {toast.show ? (
                            <Toast
                                title={toast.title}
                                type={toast.type}
                                onDismiss={() => setToast({ title: '', show: false, type: 'success' })}
                            />
                        ) : null}
                        <DeleteBaffleShieldFromApplicationModal
                            open={modals.deleteShield}
                            shield={modals?.data?.shield}
                            application={modals?.data?.application}
                            onSuccess={({
                                shield,
                            }: {
                                application: ApplicationV2;
                                shield: ShieldForApplicationDetails;
                            }) => {
                                fetch();
                                setToast({
                                    show: true,
                                    type: 'success',
                                    title: t('application.removeShieldSuccess', { name: shield.name }),
                                });
                            }}
                            onClose={() => setModals(initialModalState)}
                        />
                        <AddApplicationModal
                            applications={applicationsUnfiltered}
                            onSuccess={(application: ApplicationDetails) => {
                                fetch();
                                setToast({
                                    show: true,
                                    type: 'success',
                                    title: t('application.addSuccess', { name: application.appName }),
                                });
                            }}
                            open={modals.addApplication}
                            onClose={() => setModals(initialModalState)}
                        />
                        <DeleteApplicationModal
                            onSuccess={({ id, name }: { id: string; name: string }) => {
                                const app = applicationsUnfiltered.find((a: ApplicationV2) => a.id === id);
                                if (app) {
                                    doDeleteApplication(app);
                                }
                            }}
                            id={modals.data.id}
                            name={modals.data.name}
                            open={modals.deleteApplication}
                            onClose={() => setModals(initialModalState)}
                        />
                        <RefreshSyncIdModal
                            appId={modals.data?.appId ?? null}
                            onSuccess={() => {
                                setToast({
                                    show: true,
                                    type: 'success',
                                    title: t('application.refreshSyncIdSucces'),
                                });
                            }}
                            open={modals.refreshSyncId}
                            onClose={() => {
                                setModals(initialModalState);
                            }}
                        />
                        <DismissAllShieldNotificationsModal
                            onSuccess={async (appId?: string) => {
                                try {
                                    await agentClient.update({ appId });
                                    fetch();
                                } catch (error) {
                                    ToastStore.push({
                                        title: t('shield.dismissAllFailure'),
                                        type: 'danger',
                                    });
                                }
                            }}
                            resourceId={modals.data?.appId ?? ''}
                            open={modals.dismissNotifications}
                            onClose={() => setModals(initialModalState)}
                            prefix="applications-"
                        />

                        <Content>
                            <Header>
                                <div className="flex-1 px-4 flex justify-between">
                                    <div className="flex-1 flex items-center">
                                        <h1 className="text-heading-01 text-gray-100">
                                            {/* ternary here to get i18n to update */}
                                            {ApplicationStore.applicationsV2 && ApplicationStore.applicationsV2
                                                ? t('application.headerName', {
                                                      count: ApplicationStore.applicationsV2.length,
                                                  })
                                                : t('application.headerName', {
                                                      count: '-',
                                                  })}
                                        </h1>
                                    </div>
                                </div>
                                <div className="mx-4 flex text-md items-center h-full">
                                    <Button
                                        theme="primary"
                                        className="h-12 w-48 flex justify-between flex-row-reverse"
                                        id="add-application-btn"
                                        aria-label={t('application.ariaAddApplication')}
                                        onClick={() => setModals({ addApplication: true })}>
                                        <AddIcon className="w-4 h-4" /> {t('application.application')}
                                    </Button>
                                </div>
                            </Header>
                            <div className="flex application-datagrid text-sm leading-primary tracking-primary">
                                <div
                                    id="table-container"
                                    className={cx('datagrid-container application-datagrid flex-grow', {
                                        'with-side-panel': Boolean(selected),
                                    })}>
                                    <div>
                                        <div className="flex flex-wrap">
                                            <div className="flex-1 px-4">
                                                <Search
                                                    value={filtered.query ?? ''}
                                                    size="sm"
                                                    closeButtonLabelText={t('main.ariaCloseSearch')}
                                                    className="data-grid-search"
                                                    labelText={t('application.ariaSearch')}
                                                    placeholder={t('application.ariaSearch')}
                                                    onChange={searchChange}
                                                    id="data-grid-search"
                                                    autoComplete="none"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <ReactDataGrid
                                        rowHeight={48}
                                        headerFiltersHeight={48}
                                        headerRowHeight={48}
                                        onRowClick={(idx: number, row: any) => {
                                            if (row) {
                                                const app = applicationsUnfiltered.find(
                                                    (a: ApplicationV2) => a.id === row.id
                                                );
                                                if (app && deletedApps.every((id: string) => id !== app.id)) {
                                                    setSelected(app);
                                                    triggerResize(50);
                                                }
                                            }
                                        }}
                                        onGridSort={(key: string, direction: SortDirection) =>
                                            setSort({ direction, key })
                                        }
                                        columns={cols}
                                        rowGetter={i => rows[i]}
                                        rowsCount={rows.length}
                                        minHeight={pageHeight - pageHeightOffset}
                                        //@ts-ignore
                                        enableRowSelect={null}
                                    />
                                </div>
                            </div>
                        </Content>
                        <ApplicationSidePanel
                            setToast={setToast}
                            appId={selectedApp?.id}
                            doRefetch={() => {
                                fetch();
                            }}
                            doRemoveShieldFromApplication={({
                                application,
                                shield,
                            }: {
                                application: ApplicationV2;
                                shield: ShieldForApplicationDetails;
                            }) => {
                                setModals({ deleteShield: true, data: { application, shield } });
                            }}
                            deleteApplication={(args: { id: string; name: string; force?: boolean }) => {
                                const { id, force = false } = args;
                                const app = applicationsUnfiltered.find((a: ApplicationV2) => a.id === id);
                                if (app) {
                                    tryDeleteApplication(app, force);
                                }
                            }}
                            triggerDismissAllNotifications={(appId: string) => {
                                setModals(
                                    Object.assign({}, initialModalState, {
                                        dismissNotifications: true,
                                        data: { appId },
                                    })
                                );
                            }}
                            onClose={() => closeSidePanel()}
                        />
                    </>
                );
            }}
        </Observer>
    );
};

export default Applications;
