import React, { useState } from 'react';
import Search from '@baffle/components/src/search/Search';
import { Observer } from 'mobx-react-lite';
import TreeDownArrowIcon from '@baffle/components/src/icons/TreeDownArrowIcon';
import TreeRightArrowIcon from '@baffle/components/src/icons/TreeRightArrowIcon';
import KeyClick from '@baffle/components/src/handlers/KeyClck';
import { t } from '@baffle/translate';
import cx from 'classnames';
import { DataFormatStore } from '@baffle/manager/src/stores';
import TreeAddIcon from '@baffle/components/src/icons/TreeAddIcon';
import { DataProtectionItem } from '@baffle/graphql/src/models';
import { useParams } from 'react-router';

interface ModeLibraryTreeProps {}

const ModeLibraryTree = ({}: ModeLibraryTreeProps) => {
    const { id: appId } = useParams() as { id: string };
    const [toggleMode, setToggleMode] = useState({
        dataPolicies: false,
        encryptionModes: false,
        maskingModes: false,
        rbac: false,
        jsonPolicy: false,
    });
    const [rbacToggle, setRbacToggle] = useState({
        userGroups: false,
        policies: false,
    });

    const initialState: {
        results: DataProtectionItem[];
        query: string;
    } = { results: [], query: '' };

    const initStateRbacUsrGrp: {
        results: DataProtectionItem[];
        query: string;
    } = { results: [], query: '' };

    const initStateJsonPolicy: {
        results: DataProtectionItem[];
        query: string;
    } = { results: [], query: '' };

    const initStateRbacPolicy: {
        results: DataProtectionItem[];
        query: string;
    } = { results: [], query: '' };

    const [filterdPolicies, setFilteredPolicies] = useState(initialState);
    const [filteredMaskModes, setFilteredMaskModes] = useState(initialState);
    const [filteredEncModes, setFilteredEncModes] = useState(initialState);
    const [filteredUsrGrps, setFilteredUsrGrps] = useState(initStateRbacUsrGrp);
    const [filteredJsonPolicies, setFilteredJsonPolicies] = useState(initStateJsonPolicy);

    const [filteredRbacPolicies, setFilteredRbacPolicies] = useState(initStateRbacPolicy);
    return (
        <Observer>
            {() => {
                const dataPolicies = DataFormatStore.protectionPolicies.slice();
                const policies: DataProtectionItem[] =
                    filterdPolicies.query !== '' ? filterdPolicies.results : dataPolicies;
                const encryptionModes = DataFormatStore.encryptionModes.slice();
                const encModes: DataProtectionItem[] =
                    filteredEncModes.query !== '' ? filteredEncModes.results : encryptionModes;
                const maskingModes = DataFormatStore.maskingModes.slice();
                const maskModes: DataProtectionItem[] =
                    filteredMaskModes.query !== '' ? filteredMaskModes.results : maskingModes;
                const rbacUserGroups = DataFormatStore.rbacUsrGrps.slice();
                const rbacUsrGrps: DataProtectionItem[] =
                    filteredUsrGrps.query !== '' ? filteredUsrGrps.results : rbacUserGroups;
                const rbacPolicies: DataProtectionItem[] = DataFormatStore.rbacPolicies.slice();
                const rbacPoliciesToShow =
                    filteredRbacPolicies.query !== '' ? filteredRbacPolicies.results : rbacPolicies;
                const jsonPolicies: DataProtectionItem[] = DataFormatStore.jsonPolicies.slice();
                const jsonPoliciesToShow =
                    filteredJsonPolicies.query !== '' ? filteredJsonPolicies.results : jsonPolicies;

                const filterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                    const query = (e.target.value || '').toLowerCase();
                    if (!query) {
                        setFilteredPolicies({ query: query, results: [] });
                        setFilteredMaskModes({ query: query, results: [] });
                        setFilteredEncModes({ query: query, results: [] });
                        setFilteredUsrGrps({ query: query, results: [] });
                        setFilteredJsonPolicies({ query: query, results: [] });
                        setFilteredRbacPolicies({ query: query, results: [] });
                    }
                    // Search for policies if expanded
                    if (toggleMode.dataPolicies) {
                        const results = dataPolicies.filter(policy => policy.name.toLowerCase().includes(query));
                        setFilteredPolicies({ results, query });
                    }
                    // Search for user groups if expanded
                    if (rbacToggle.userGroups) {
                        const results = rbacUserGroups.filter(usrGrp => usrGrp.name.toLowerCase().includes(query));
                        setFilteredUsrGrps({ results, query });
                    }
                    // Search for json policies if expanded
                    if (toggleMode.jsonPolicy) {
                        const results = jsonPolicies.filter(policy => policy.name.toLowerCase().includes(query));
                        setFilteredJsonPolicies({ results, query });
                    }
                    // Search for rbac policies if expanded
                    if (rbacToggle.policies) {
                        const results = rbacPolicies.filter(policy => policy.name.toLowerCase().includes(query));
                        setFilteredRbacPolicies({ results, query });
                    }
                    // Search for enc modes if expanded
                    if (toggleMode.encryptionModes) {
                        const results = encryptionModes.filter(encMode => encMode.name.toLowerCase().includes(query));
                        setFilteredEncModes({ results, query });
                    }
                    // Search for mask modes if expanded
                    if (toggleMode.maskingModes) {
                        const results = maskingModes.filter(maskMode => maskMode.name.toLowerCase().includes(query));
                        setFilteredMaskModes({ results, query });
                    }
                };

                return (
                    <>
                        <Search
                            className="modelibrary-grid-filter"
                            placeholder={t('encryption.modeLibrary.ariaSearch')}
                            name="modelibrary-dbgrid-filter"
                            onChange={filterChange}
                            autoComplete="none"
                        />
                        <nav
                            className="flex-1 max-h-1/2 bg-white overflow-y-auto text-xs border-solid border-r-2 border-gray-300 pb-2 text-black-100"
                            aria-label="Sidebar">
                            <div>
                                <KeyClick
                                    key={'keyclick_data_policies'}
                                    data-testid={'keyclick_data_policies'}
                                    handler={() => {
                                        setToggleMode({ ...toggleMode, dataPolicies: !toggleMode.dataPolicies });
                                    }}>
                                    <div
                                        className="group hover:bg-gray-200 hover:text-gray-900 w-full flex items-center py-1"
                                        aria-controls={`keyclick_data_policies`}
                                        aria-expanded={toggleMode.dataPolicies ? 'true' : 'false'}>
                                        <span className="flex-1 flex items-center ml-4 w-56 truncate">
                                            {toggleMode.dataPolicies ? <TreeDownArrowIcon /> : <TreeRightArrowIcon />}
                                            <span
                                                className="ml-1 font-bold"
                                                title={t('encryption.modeLibrary.dataProtection')}>
                                                {t('encryption.modeLibrary.dataProtection')}
                                            </span>
                                        </span>
                                    </div>
                                </KeyClick>
                                {toggleMode.dataPolicies
                                    ? policies.map((policy, i) => (
                                          <div
                                              className="py-2 w-full"
                                              key={`policy_${policy.name}_${i}`}
                                              data-testid={`policy_tree_entry_${policy.name}`}>
                                              <span title={policy.name} className="ml-10 px-2">
                                                  {policy.name}
                                              </span>
                                          </div>
                                      ))
                                    : null}
                            </div>
                            <div>
                                <KeyClick
                                    key={'keyclick_rbac'}
                                    data-testid={'keyclick_rbac'}
                                    handler={() => {
                                        setToggleMode({ ...toggleMode, rbac: !toggleMode.rbac });
                                    }}>
                                    <div
                                        className="group hover:bg-gray-200 hover:text-gray-900 w-full flex items-center py-1"
                                        aria-controls={`keyclick_rbac`}
                                        aria-expanded={toggleMode.rbac ? 'true' : 'false'}>
                                        <span className="flex-1 flex items-center ml-4 w-56 truncate">
                                            {toggleMode.rbac ? <TreeDownArrowIcon /> : <TreeRightArrowIcon />}
                                            <span
                                                className="ml-1 font-bold"
                                                title={t('encryption.modeLibrary.rbac.rbacLabel')}>
                                                {t('encryption.modeLibrary.rbac.rbacLabel')}
                                            </span>
                                        </span>
                                    </div>
                                </KeyClick>
                                {toggleMode.rbac ? (
                                    <div>
                                        <KeyClick
                                            key={'rbac_config_tree_entry_key'}
                                            data-testid={'rbac_config_tree_entry'}
                                            handler={() => {
                                                DataFormatStore.resetModeBuilder();
                                                DataFormatStore.readRbacConfig(appId, true);
                                                DataFormatStore.setView(true, 'rbacConfigView');
                                            }}>
                                            <div
                                                className={cx(
                                                    'w-full py-2 text-gray-700 hover:text-gray-900 hover:bg-gray-200',
                                                    {
                                                        'bg-blue-100': DataFormatStore.showRbacConfigView,
                                                    }
                                                )}>
                                                <span
                                                    title={t('encryption.modeLibrary.rbac.rbacConfiguration')}
                                                    className="ml-10 px-2">
                                                    {t('encryption.modeLibrary.rbac.rbacConfiguration')}
                                                </span>
                                            </div>
                                        </KeyClick>
                                        <KeyClick
                                            key={'keyclick_rbac_usr_grp'}
                                            data-testid={'keyclick_rbac_usr_grp'}
                                            handler={() => {
                                                if (Boolean(DataFormatStore.rbacConfig)) {
                                                    DataFormatStore.resetModeBuilder();
                                                    DataFormatStore.setShowRbacUsrGrpView(true);
                                                    setRbacToggle({
                                                        ...rbacToggle,
                                                        userGroups: !rbacToggle.userGroups,
                                                    });
                                                }
                                            }}>
                                            <div
                                                aria-controls={`keyclick_rbac_usr_grp`}
                                                aria-expanded={rbacToggle.userGroups ? 'true' : 'false'}
                                                className={cx(
                                                    'group w-full flex flex-1 justify-between items-center py-1',
                                                    {
                                                        'pointer-events-none opacity-50': !DataFormatStore.rbacConfig,
                                                        'text-gray-700 hover:text-gray-900 hover:bg-gray-200': Boolean(
                                                            DataFormatStore.rbacConfig
                                                        ),
                                                        'bg-blue-100': DataFormatStore.showRbacUsrGrpView,
                                                    }
                                                )}>
                                                <span className="flex-1 flex items-center w-56 truncate ml-7">
                                                    {!Boolean(DataFormatStore.rbacConfig) ? (
                                                        <TreeRightArrowIcon />
                                                    ) : rbacToggle.userGroups ? (
                                                        <TreeDownArrowIcon />
                                                    ) : (
                                                        <TreeRightArrowIcon />
                                                    )}
                                                    <span title={t('encryption.modeLibrary.rbac.userGroups')}>
                                                        {t('encryption.modeLibrary.rbac.userGroups')}
                                                    </span>
                                                </span>
                                                {Boolean(DataFormatStore.rbacConfig) ? (
                                                    <button
                                                        className="inline-flex mr-5 focus:outline-none opacity-0 hover:opacity-100 group-hover:opacity-100 hover:bg-gray-300"
                                                        data-testid="add-rbac-usr-grp-btn-tree"
                                                        onClick={(e: React.MouseEvent) => {
                                                            e.stopPropagation();
                                                            DataFormatStore.resetModeBuilder();
                                                            DataFormatStore.setShowRbacUsrGrpCreate(true);
                                                        }}>
                                                        <TreeAddIcon />
                                                    </button>
                                                ) : null}
                                            </div>
                                        </KeyClick>
                                        {rbacToggle.userGroups
                                            ? rbacUsrGrps.map((userGroup, i) => {
                                                  const isSelectedUsrGrp =
                                                      DataFormatStore.selectedRbacUsrGrp?.id === userGroup.id;
                                                  return (
                                                      <KeyClick
                                                          key={`rbac_usr_grp_${userGroup.name}_${i}`}
                                                          data-testid={`rbac_usr_grp_tree_entry_${userGroup.name}`}
                                                          handler={() => {
                                                              DataFormatStore.resetModeBuilder();
                                                              DataFormatStore.getSelectedUserGroup(userGroup.id);
                                                              DataFormatStore.setShowRbacUsrGrpCreate(true);
                                                          }}>
                                                          <div
                                                              className={cx(
                                                                  'w-full py-2 text-gray-700 hover:text-gray-900 hover:bg-gray-200',
                                                                  {
                                                                      'bg-blue-100': isSelectedUsrGrp,
                                                                  }
                                                              )}>
                                                              <span title={userGroup.name} className="ml-14 px-2">
                                                                  {userGroup.name}
                                                              </span>
                                                          </div>
                                                      </KeyClick>
                                                  );
                                              })
                                            : null}
                                        <KeyClick
                                            key={'keyclick_rbac_policies'}
                                            data-testid={'keyclick_rbac_policies'}
                                            handler={() => {
                                                if (DataFormatStore.rbacUsrGrps.length) {
                                                    DataFormatStore.resetModeBuilder();
                                                    DataFormatStore.setShowRbacPolicyView(true);
                                                    setRbacToggle({
                                                        ...rbacToggle,
                                                        policies: !rbacToggle.policies,
                                                    });
                                                }
                                            }}>
                                            <div
                                                aria-controls={`keyclick_rbac_policies`}
                                                aria-expanded={rbacToggle.policies ? 'true' : 'false'}
                                                className={cx(
                                                    'group w-full flex flex-1 justify-between items-center py-1',
                                                    {
                                                        'pointer-events-none opacity-50':
                                                            !DataFormatStore.rbacUsrGrps.length ||
                                                            !DataFormatStore.rbacConfig,
                                                        'text-gray-700 hover:text-gray-900 hover:bg-gray-200':
                                                            DataFormatStore.rbacUsrGrps.length &&
                                                            DataFormatStore.rbacConfig,
                                                        'bg-blue-100': DataFormatStore.showRbacPolicyView,
                                                    }
                                                )}>
                                                <span className="flex-1 flex items-center w-56 truncate ml-7">
                                                    {rbacToggle.policies ? (
                                                        <TreeDownArrowIcon />
                                                    ) : (
                                                        <TreeRightArrowIcon />
                                                    )}
                                                    <span title={t('encryption.modeLibrary.rbac.policies')}>
                                                        {t('encryption.modeLibrary.rbac.policies')}
                                                    </span>
                                                </span>
                                                {DataFormatStore.rbacUsrGrps.length && DataFormatStore.rbacConfig ? (
                                                    <button
                                                        className="inline-flex mr-5 focus:outline-none opacity-0 hover:opacity-100 group-hover:opacity-100 hover:bg-gray-300"
                                                        data-testid="add-rbac-policy-btn-tree"
                                                        onClick={(e: React.MouseEvent) => {
                                                            e.stopPropagation();
                                                            DataFormatStore.resetModeBuilder();
                                                            DataFormatStore.setShowRbacPolicyCreate(true);
                                                        }}>
                                                        <TreeAddIcon />
                                                    </button>
                                                ) : null}
                                            </div>
                                        </KeyClick>
                                        {rbacToggle.policies
                                            ? rbacPoliciesToShow.map((policy, i) => {
                                                  const isSelectedPolicy =
                                                      DataFormatStore.selectedRbacPolicy?.id === policy.id;
                                                  return (
                                                      <KeyClick
                                                          key={`rbac_policy_${policy.name}_${i}`}
                                                          data-testid={`rbac_policy_tree_entry_${policy.name}`}
                                                          handler={() => {
                                                              DataFormatStore.resetModeBuilder();
                                                              DataFormatStore.getSelectedRbacPolicy(policy.id);
                                                              DataFormatStore.setShowRbacPolicyCreate(true);
                                                          }}>
                                                          <div
                                                              className={cx(
                                                                  'w-full py-2 text-gray-700 hover:text-gray-900 hover:bg-gray-200',
                                                                  {
                                                                      'bg-blue-100': isSelectedPolicy,
                                                                  }
                                                              )}>
                                                              <span title={policy.name} className="ml-14 px-2">
                                                                  {policy.name}
                                                              </span>
                                                          </div>
                                                      </KeyClick>
                                                  );
                                              })
                                            : null}
                                    </div>
                                ) : null}
                            </div>
                            {DataFormatStore.enableJsonPolicies ? (
                                <div>
                                    <KeyClick
                                        key={'keyclick_json_policy'}
                                        data-testid={'keyclick_json_policy'}
                                        handler={() => {
                                            setToggleMode({ ...toggleMode, jsonPolicy: !toggleMode.jsonPolicy });
                                        }}>
                                        <div
                                            className="group hover:bg-gray-200 hover:text-gray-900 w-full flex items-center py-1"
                                            aria-controls={`keyclick_json_policy`}
                                            aria-expanded={toggleMode.jsonPolicy ? 'true' : 'false'}>
                                            <span className="flex-1 flex items-center ml-4 w-56 truncate">
                                                {toggleMode.jsonPolicy ? <TreeDownArrowIcon /> : <TreeRightArrowIcon />}
                                                <span
                                                    className="ml-1 font-bold"
                                                    title={t('encryption.modeLibrary.jsonPolicy.jsonPolicyLabel')}>
                                                    {t('encryption.modeLibrary.jsonPolicy.jsonPolicyLabel')}
                                                </span>
                                            </span>
                                            <button
                                                className="inline-flex mr-5 focus:outline-none opacity-0 hover:opacity-100 group-hover:opacity-100 hover:bg-gray-300"
                                                data-testid="add-json-policy-btn-tree"
                                                onClick={(e: React.MouseEvent) => {
                                                    e.stopPropagation();
                                                    DataFormatStore.resetModeBuilder();
                                                    DataFormatStore.setShowJsonPolicyView(true);
                                                }}>
                                                <TreeAddIcon />
                                            </button>
                                        </div>
                                    </KeyClick>
                                    {toggleMode.jsonPolicy
                                        ? jsonPoliciesToShow.map((jsonPolicy, i) => {
                                              const isSelectedJsonPolicy =
                                                  DataFormatStore.selectedJsonPolicy?.id === jsonPolicy.id;
                                              return (
                                                  <KeyClick
                                                      key={`json_policy_${jsonPolicy.name}_${i}`}
                                                      data-testid={`json_policy_tree_entry_${jsonPolicy.name}`}
                                                      handler={() => {
                                                          DataFormatStore.resetModeBuilder();
                                                          DataFormatStore.readSelectedJsonPolicy(
                                                              jsonPolicy.id as string
                                                          );
                                                          DataFormatStore.setShowJsonPolicyView(true);
                                                      }}>
                                                      <div
                                                          className={cx(
                                                              'w-full py-2 text-gray-700 hover:text-gray-900 hover:bg-gray-200',
                                                              {
                                                                  'bg-blue-100': isSelectedJsonPolicy,
                                                              }
                                                          )}>
                                                          <span title={jsonPolicy.name} className="ml-10 px-2">
                                                              {jsonPolicy.name}
                                                          </span>
                                                      </div>
                                                  </KeyClick>
                                              );
                                          })
                                        : null}
                                </div>
                            ) : null}
                            <div>
                                <KeyClick
                                    key={'keyclick_encryption_modes'}
                                    data-testid={'keyclick_encryption_modes'}
                                    handler={() => {
                                        setToggleMode({ ...toggleMode, encryptionModes: !toggleMode.encryptionModes });
                                    }}>
                                    <div
                                        className="group hover:bg-gray-200 hover:text-gray-900 w-full flex items-center py-1"
                                        aria-controls={`keyclick_encryption_modes`}
                                        aria-expanded={toggleMode.encryptionModes ? 'true' : 'false'}>
                                        <span className="flex-1 flex items-center ml-4">
                                            {toggleMode.encryptionModes ? (
                                                <TreeDownArrowIcon />
                                            ) : (
                                                <TreeRightArrowIcon />
                                            )}
                                            <span
                                                className="ml-1 font-bold"
                                                title={t('encryption.modeLibrary.encryptionModes')}>
                                                {t('encryption.modeLibrary.encryptionModes')}
                                            </span>
                                        </span>
                                    </div>
                                </KeyClick>
                                {toggleMode.encryptionModes
                                    ? encModes.map((mode, i) => {
                                          const isSelectedEnc = DataFormatStore.selectedEncMode?.name === mode.name;
                                          return (
                                              <KeyClick
                                                  key={`encmode_${mode.name}_${i}`}
                                                  data-testid={`encmode_tree_entry_${mode.name}`}
                                                  handler={() => {
                                                      DataFormatStore.getSelectedMode(mode.id, 'encryption');
                                                      DataFormatStore.resetModeBuilder();
                                                      DataFormatStore.setView(true, 'encryption');
                                                  }}>
                                                  <div
                                                      className={cx(
                                                          'w-full py-2 text-gray-700 hover:text-gray-900 hover:bg-gray-200',
                                                          {
                                                              'bg-blue-100': isSelectedEnc,
                                                          }
                                                      )}>
                                                      <span title={mode.name} className="ml-10 px-2">
                                                          {mode.name}
                                                      </span>
                                                  </div>
                                              </KeyClick>
                                          );
                                      })
                                    : null}
                            </div>
                            <div>
                                <KeyClick
                                    key={'keyclick_masking_modes'}
                                    data-testid={'keyclick_masking_modes'}
                                    handler={() => {
                                        setToggleMode({ ...toggleMode, maskingModes: !toggleMode.maskingModes });
                                    }}>
                                    <div
                                        className="group hover:bg-gray-200 hover:text-gray-900 w-full flex items-center py-1"
                                        aria-controls={`keyclick_masking_modes`}
                                        aria-expanded={toggleMode.maskingModes ? 'true' : 'false'}>
                                        <span className="flex-1 flex items-center ml-4">
                                            {toggleMode.maskingModes ? <TreeDownArrowIcon /> : <TreeRightArrowIcon />}
                                            <span
                                                className="ml-1 font-bold"
                                                title={t('encryption.modeLibrary.maskingModes')}>
                                                {t('encryption.modeLibrary.maskingModes')}
                                            </span>
                                        </span>
                                    </div>
                                </KeyClick>
                                {toggleMode.maskingModes
                                    ? maskModes.map((mode, i) => {
                                          const isSelectedMask = DataFormatStore.selectedMaskMode?.name === mode.name;
                                          return (
                                              <KeyClick
                                                  key={`maskmode_${mode.name}_${i}`}
                                                  data-testid={`maskmode_tree_entry_${mode.name}`}
                                                  handler={() => {
                                                      DataFormatStore.getSelectedMode(mode.id, 'masking');
                                                      DataFormatStore.resetModeBuilder();
                                                      DataFormatStore.setView(true, 'masking');
                                                  }}>
                                                  <div
                                                      className={cx(
                                                          'w-full py-2 text-gray-700 hover:text-gray-900 hover:bg-gray-200',
                                                          {
                                                              'bg-blue-100': isSelectedMask,
                                                          }
                                                      )}>
                                                      <span title={mode.name} className="ml-10 px-2">
                                                          {mode.name}
                                                      </span>
                                                  </div>
                                              </KeyClick>
                                          );
                                      })
                                    : null}
                            </div>
                        </nav>
                    </>
                );
            }}
        </Observer>
    );
};

export default ModeLibraryTree;
