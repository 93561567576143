import dataProtectionClient from '@baffle/api-client/src/dataProtectionClient';
import {
    DataProtectionResponse,
    EncryptionMode,
    MaskingMode,
    DataProtectionItem,
    RbacConfig,
    RbacUsrGrp,
    JsonPolicy,
    RbacPolicy,
} from '@baffle/graphql/src/models';
import { observable, action, makeObservable, runInAction } from 'mobx';
import { RequestOpts } from '../types/StoreTypes';

class DataFormatStore {
    constructor() {
        makeObservable(this, {
            protectionPolicies: observable,
            encryptionModes: observable,
            maskingModes: observable,
            showMaskModeView: observable,
            showEncModeView: observable,
            showRbacConfigView: observable,
            selectedMaskMode: observable,
            selectedEncMode: observable,
            rbacConfig: observable,
            showRbacUsrGrpView: observable,
            showRbacUsrGrpCreate: observable,
            rbacUsrGrps: observable,
            rbacPolicies: observable,
            showRbacPolicyView: observable,
            showRbacPolicyCreate: observable,
            selectedRbacUsrGrp: observable,
            showJsonPolicyView: observable,
            jsonPolicies: observable,
            selectedJsonPolicy: observable,
            selectedRbacPolicy: observable,
            enableJsonPolicies: observable,
            setView: action,
            setProtectionPolicies: action,
            setEncModes: action,
            setMaskingModes: action,
            setSelectedMaskMode: action,
            setSelectedEncMode: action,
            setShowEncModeView: action,
            setShowMaskModeView: action,
            setRbacConfig: action,
            setShowRbacConfigView: action,
            setShowRbacUsrGrpView: action,
            setShowRbacUsrGrpCreate: action,
            setRbacUsrGrps: action,
            setSelectedRbacUsrGrp: action,
            setShowJsonPolicyView: action,
            setJsonPolicies: action,
            setSelectedJsonPolicy: action,
            setShowRbacPolicyView: action,
            setShowRbacPolicyCreate: action,
            setSelectedRbacPolicy: action,
            setRbacPolicies: action,
        });
    }
    protectionPolicies = observable<DataProtectionItem>([]);
    encryptionModes = observable<DataProtectionItem>([]);
    maskingModes = observable<DataProtectionItem>([]);
    showMaskModeView: boolean = false;
    showEncModeView: boolean = false;
    showRbacConfigView: boolean = false;
    selectedMaskMode: MaskingMode | null = null;
    selectedEncMode: EncryptionMode | null = null;
    rbacConfig: RbacConfig | null | undefined = null;
    showRbacUsrGrpView: boolean = false;
    showRbacUsrGrpCreate: boolean = false;
    rbacUsrGrps = observable<DataProtectionItem>([]);
    selectedRbacUsrGrp: RbacUsrGrp | null | undefined = null;
    showJsonPolicyView: boolean = false;
    jsonPolicies = observable<DataProtectionItem>([]);
    selectedJsonPolicy: JsonPolicy | null | undefined = null;
    rbacPolicies = observable<DataProtectionItem>([]);
    selectedRbacPolicy: RbacPolicy | null | undefined = null;
    showRbacPolicyView: boolean = false;
    showRbacPolicyCreate: boolean = false;
    enableJsonPolicies: boolean = false;

    setProtectionPolicies(policies: DataProtectionItem[]) {
        this.protectionPolicies.replace(policies);
    }
    setEncModes(encModes: DataProtectionItem[]) {
        this.encryptionModes.replace(encModes);
    }
    setMaskingModes(maskModes: DataProtectionItem[]) {
        this.maskingModes.replace(maskModes);
    }
    setView(view: boolean, type: string) {
        switch (type) {
            case 'masking':
                this.setShowMaskModeView(view);
                break;
            case 'encryption':
                this.setShowEncModeView(view);
                break;
            case 'rbacConfigView':
                this.setShowRbacConfigView(view);
                break;
            default:
                console.warn('Incorrect params passed!');
                break;
        }
    }
    setSelectedMaskMode(maskMode: MaskingMode | null) {
        this.selectedMaskMode = maskMode;
    }
    setSelectedEncMode(encMode: EncryptionMode | null) {
        this.selectedEncMode = encMode;
    }
    setRbacConfig(rbacConfig: RbacConfig | null | undefined) {
        this.rbacConfig = rbacConfig;
    }
    setShowRbacConfigView(showRbacConfigView: boolean) {
        this.showRbacConfigView = showRbacConfigView;
    }
    setShowRbacUsrGrpView(showRbacUsrGrpView: boolean) {
        this.showRbacUsrGrpView = showRbacUsrGrpView;
    }
    setShowRbacUsrGrpCreate(showRbacUsrGrpCreate: boolean) {
        this.showRbacUsrGrpCreate = showRbacUsrGrpCreate;
    }
    setRbacUsrGrps(rbacUserGroups: DataProtectionItem[]) {
        this.rbacUsrGrps.replace(rbacUserGroups);
    }
    setSelectedRbacUsrGrp(usrGrp: RbacUsrGrp | null | undefined) {
        this.selectedRbacUsrGrp = usrGrp;
    }
    setShowRbacPolicyView(showRbacPolicyView: boolean) {
        this.showRbacPolicyView = showRbacPolicyView;
    }
    setShowRbacPolicyCreate(showRbacPolicyCreate: boolean) {
        this.showRbacPolicyCreate = showRbacPolicyCreate;
    }
    setShowEncModeView(showEncModeView: boolean) {
        this.showEncModeView = showEncModeView;
    }
    setShowMaskModeView(showMaskModeView: boolean) {
        this.showMaskModeView = showMaskModeView;
    }
    setShowJsonPolicyView(showJsonPolicyView: boolean) {
        this.showJsonPolicyView = showJsonPolicyView;
    }
    setJsonPolicies(jsonPolicies: DataProtectionItem[]) {
        this.jsonPolicies.replace(jsonPolicies);
    }
    setSelectedJsonPolicy(jsonPolicy: JsonPolicy | null | undefined) {
        this.selectedJsonPolicy = jsonPolicy;
    }
    setSelectedRbacPolicy(policy: RbacPolicy | null | undefined) {
        this.selectedRbacPolicy = policy;
    }
    setRbacPolicies(policies: DataProtectionItem[]) {
        this.rbacPolicies.replace(policies);
    }

    async listAll(appId: string) {
        try {
            const modes: DataProtectionResponse = await dataProtectionClient.listAll({ appId });
            runInAction(() => {
                this.enableJsonPolicies = modes.enableJsonPolicies;
                this.setProtectionPolicies(modes.policies);
                this.setMaskingModes(modes.maskingModes ?? []);
                this.setEncModes(modes.encryptionModes ?? []);
                this.setRbacUsrGrps(modes.rbacUserGroups ?? []);
                this.setRbacPolicies(modes.rbacPolicies ?? []);
                this.setJsonPolicies(modes.jsonPolicies ?? []);
            });
            return modes;
        } catch (error) {
            throw error;
        }
    }

    async getSelectedMode(modeID: string, type: string, opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first') {
            return;
        }
        try {
            if (type === 'masking') {
                const maskMode = await dataProtectionClient.getSelectedMode(modeID, 'masking');
                this.setSelectedMaskMode(maskMode);
            } else if (type === 'encryption') {
                const encMode = await dataProtectionClient.getSelectedMode(modeID, 'encryption');
                this.setSelectedEncMode(encMode);
            }
        } catch (error) {
            throw error;
        }
    }

    async getSelectedUserGroup(groupID: string, opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first') {
            return;
        }
        try {
            const userGroup = await dataProtectionClient.readRbacUsrGrp(groupID);
            this.setSelectedRbacUsrGrp(userGroup);
        } catch (error) {
            throw error;
        }
    }

    async getSelectedRbacPolicy(policyID: string, opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first') {
            return;
        }
        try {
            const rbacPolicy = await dataProtectionClient.readRbacPolicy(policyID);
            this.setSelectedRbacPolicy(rbacPolicy);
        } catch (error) {
            throw error;
        }
    }

    resetModeBuilder = () => {
        this.setShowRbacConfigView(false);
        this.setShowEncModeView(false);
        this.setShowMaskModeView(false);
        this.setSelectedEncMode(null);
        this.setSelectedMaskMode(null);
        this.setShowRbacUsrGrpView(false);
        this.setShowRbacUsrGrpCreate(false);
        this.setSelectedRbacUsrGrp(null);
        this.setShowJsonPolicyView(false);
        this.setSelectedJsonPolicy(null);
        this.setShowRbacPolicyView(false);
        this.setShowRbacPolicyCreate(false);
        this.setSelectedRbacPolicy(null);
    };

    async readRbacConfig(id: string, appId: boolean) {
        let rbacConfig;
        try {
            if (appId) {
                const res = await dataProtectionClient.readRbacConfigByAppId({ appId: id });
                rbacConfig = res?.data ? res.data[0] : null;
            } else {
                rbacConfig = await dataProtectionClient.readRbacConfig(id);
            }
            this.setRbacConfig(rbacConfig);
        } catch (error) {
            throw error;
        }
    }

    async readRbacUsrGrps() {
        try {
            const res = await dataProtectionClient.readRbacUsrGrps();
            this.setRbacUsrGrps(res?.data ?? []);
        } catch (error) {
            throw error;
        }
    }

    async readJsonPolicies(appId: string) {
        try {
            const res = await dataProtectionClient.readJsonPolicyByAppId({ appId });
            this.setJsonPolicies(res?.data ?? []);
        } catch (error) {
            throw error;
        }
    }

    async readSelectedJsonPolicy(id: string) {
        try {
            const jsonPolicy = await dataProtectionClient.readJsonPolicy(id);
            this.setSelectedJsonPolicy(jsonPolicy);
        } catch (error) {
            throw error;
        }
    }
}

export default DataFormatStore;
