import useAwait from '@baffle/api-client/src/useAwait';
import { Modal } from 'carbon-components-react';
import { t } from '@baffle/translate';
import React, { useEffect } from 'react';
import { UserGroupStore } from '@baffle/manager/src/stores';
import { RbacUsrGrpV2 } from '@baffle/graphql/src/models';
import { useHistory } from 'react-router-dom';
import { NotificationStore } from '../../../stores/NotificationStore';

interface DeleteUserGroupProps {
    open: boolean;
    onClose?: () => void;
    onSuccess?: (name: string) => void;
}
const DeleteUserGroup = ({ open, onSuccess, onClose }: DeleteUserGroupProps) => {
    const { run, error } = useAwait();
    const { id, name } = UserGroupStore.rbacUserGroup as RbacUsrGrpV2;
    const history = useHistory();

    const animateModal = () => {
        return new Promise(() => {
            setTimeout(() => {
                if (onClose) {
                    onClose();
                }
                history.push('/rbac');
            }, 200);
        });
    };

    useEffect(() => {
        if (error) {
            NotificationStore.push({
                'data-testid': 'user-group-delete-failure',
                kind: 'error',
                title: t('globalPolicies.rbacUserGroups.deleteUserGroupError'),
                description: error.message ?? '',
            });
        }
    }, [error]);

    const handleClose = async () => {
        await animateModal();
    };

    const deleteUserGroup = () => {
        run(
            UserGroupStore.deleteRbacUserGroup(id as string).then(() => {
                if (onSuccess) {
                    onSuccess(name);
                }
                handleClose();
            })
        );
    };

    return (
        <Modal
            id="user-group-delete-modal"
            open={open}
            modalHeading={t('globalPolicies.rbacUserGroups.deleteUserGroupHeading', { name: name })}
            danger={true}
            onRequestClose={onClose}
            primaryButtonText={t('main.delete')}
            secondaryButtonText={t('main.cancel')}
            size="sm"
            onRequestSubmit={deleteUserGroup}>
            <p>{t('globalPolicies.rbacUserGroups.deleteUserGroupConfirmationMsg')}</p>
        </Modal>
    );
};

export default DeleteUserGroup;
