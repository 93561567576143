import React, { useEffect, useState } from 'react';
import { Observer } from 'mobx-react-lite';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import shieldClient from '@baffle/api-client/src/shieldClient';
import { ToastStore } from '../stores/ToastStore';
import Joi from '@hapi/joi';
import { t } from '@baffle/translate';
import { Shield } from '@baffle/graphql/src/models';
import { PanelModule } from '@baffle/components/src/content/RightPanel';
import TextInput from '@baffle/components/src/forms/TextInput';
import Button from '@baffle/components/src/buttons/Button';
import RestartShieldModal from '@baffle/manager/src/shields/RestartShieldModal';

interface ShieldAdvanceConfigProps {
    shield: Partial<Shield>;
}

const ShieldAdvanceConfiguration = ({ shield }: ShieldAdvanceConfigProps) => {
    const [restartModal, setRestartModal] = useState(false);

    return (
        <Observer>
            {() => {
                useEffect(() => {
                    inputActions.setField('xssValue', shield?.xssValue);
                    inputErrors.xssValue = '';
                }, [shield]);

                const { inputState, inputActions, inputErrors } = useInputState({
                    keys: ['xssValue'],
                    ns: 'shield',
                    defaultValues: {
                        xssValue: 0,
                    },
                    validationSchema: {
                        xssValue: Joi.number()
                            .integer()
                            .allow(0)
                            .greater(0)
                            .messages({
                                'number.base': t('shield.xssValueInvalidError'),
                                'number.integer': t('shield.xssValueInvalidError'),
                                'number.greater': t('shield.xssValueInvalidError'),
                            }),
                    },
                });
                const isValid = inputActions.validateAll(true);

                const addXssValue = async () => {
                    try {
                        await shieldClient.updateXssValue(shield?.id as string, {
                            xssValue: +(inputState.xssValue as string),
                        });
                        setRestartModal(false);
                        ToastStore.push({
                            title: t('shield.xssSuccess', {
                                name: inputState.xssValue,
                            }),
                            type: 'success',
                        });
                    } catch (error) {
                        ToastStore.push({
                            title: t('shield.xssFailure'),
                            type: 'danger',
                        });
                    }
                };

                return (
                    <>
                        <PanelModule
                            data-testid="shield-advanced-configuration"
                            title={t('shield.advancedConfiguration')}>
                            <div className="flex flex row">
                                <div className="w-18">
                                    <TextInput
                                        id="shield-xss-input"
                                        name="shield-xss-input"
                                        labelText={t('shield.xss')}
                                        invalid={Boolean(inputErrors.xssValue)}
                                        invalidText={inputErrors.xssValue}
                                        value={inputState.xssValue}
                                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            inputActions.validateField({
                                                field: 'xssValue',
                                                skipNull: true,
                                            });
                                        }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const val = e.target.value || '';
                                            inputActions.setField('xssValue', val.trim());
                                        }}
                                    />
                                </div>
                                <div className="px-1">
                                    <Button
                                        className="py-2 font-bold ml-2 text-xs uppercase h-8 w-18 mt-5"
                                        id="add-xss"
                                        theme={!isValid ? 'disabled' : 'primary'}
                                        onClick={() => {
                                            setRestartModal(true);
                                        }}>
                                        {t('main.save')}
                                    </Button>
                                </div>
                            </div>
                        </PanelModule>
                        <RestartShieldModal
                            customRestartFunc={() => addXssValue()}
                            open={restartModal}
                            onClose={() => setRestartModal(false)}
                            subHeader={t('shield.restartShieldHeader')}
                            body={t('encryption.modeLibrary.rbac.rbacConfig.restartShieldBody')}
                        />
                    </>
                );
            }}
        </Observer>
    );
};

export default ShieldAdvanceConfiguration;
