import React, { useEffect } from 'react';
import { Observer } from 'mobx-react-lite';
import DataGrid from '@baffle/components/src/grid/data-grid';
import { t } from '@baffle/translate';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';
import useAwait from '@baffle/api-client/src/useAwait';
import { CertificateStore } from '@baffle/manager/src/stores';
import { useManageModals } from '@baffle/components/src/modal/Modal';
import { CertificateDetails } from '@baffle/graphql/src/models';
import AddCACertificate from './AddCACertModal';
import RenameCACertificate from './RenameCACertModal';
import DeleteCACertificate from './DeleteCACertModal';
import { CertificateRowDetails } from './CertificateRowDetails';
import certificateClient from '@baffle/api-client/src/certificateClient';
import usePageHeight from '@baffle/utilities/src/usePageHeight';

const CaCertificates = () => {
    const { status, run } = useAwait();

    useEffect(() => {
        run(CertificateStore.list());
    }, []);

    const emptyCol = {
        id: '',
        name: '',
        header2: '',
        header3: '',
    };

    const headers = [
        {
            key: 'name',
            header: t('main.name'),
            sortable: true,
        },
        {
            key: 'header2',
            header: t('certificate.subjectName'),
            sortable: true,
        },
        {
            key: 'header3',
            header: t('certificate.expiry'),
            sortable: true,
        },
    ];

    interface CertModals {
        addCert: boolean;
        renameCert: boolean;
        deleterCert: boolean;
    }

    const initialModalState = {
        addCert: false,
        renameCert: false,
        deleterCert: false,
    };

    return (
        <Observer>
            {() => {
                // to offset the header areas
                const pageHeight = usePageHeight() - 200;

                const [modals, setModals] = useManageModals<CertModals>(initialModalState);
                const certificates: CertificateDetails[] = CertificateStore.certificates;
                const certificateDetails: Partial<CertificateDetails>[] = CertificateStore.certificateOverviewList;
                const onAdd = () => {
                    CertificateStore.setSelectedCertificate(null);
                    setModals({ addCert: true });
                };

                const onRename = (id: string) => {
                    CertificateStore.setSelectedCertificate(CertificateStore.get(id) as CertificateDetails);
                    setModals({ renameCert: true });
                };

                const onDelete = (id: string) => {
                    CertificateStore.setSelectedCertificate(CertificateStore.get(id) as CertificateDetails);
                    setModals({ deleterCert: true });
                };

                const downloadCertificate = (link: string, fileName: string) => {
                    run(
                        certificateClient.downloadCertificate(link as string, fileName as string).then(() => {
                            CertificateStore.list().then(() => {
                                setModals(initialModalState);
                                NotificationStore.push({
                                    kind: 'success',
                                    title: t('certificate.downloadCertificateSuccess', {
                                        name: fileName ?? '',
                                    }),
                                    'data-testid': 'certificate-download-success',
                                });
                            });
                        })
                    );
                };

                const rows =
                    status === 'loading' || status === 'idle'
                        ? //add  skeleton row on initial load
                          [...Array(Math.floor(1)).keys()].map(() => emptyCol)
                        : certificates.map((cert: CertificateDetails, i: number) => {
                              return {
                                  id: cert.id,
                                  name: cert.name,
                                  header2: cert.subject,
                                  header3: cert.expirationDate,
                                  overFlowMenu: [
                                      {
                                          name: t('main.rename'),
                                          onClick: (id: string) => {
                                              onRename(id);
                                          },
                                      },
                                      {
                                          name: t('main.delete'),
                                          onClick: (id: string) => {
                                              onDelete(id);
                                          },
                                      },
                                  ],
                              };
                          });
                return (
                    <div className="overflow-y-auto" style={{ height: pageHeight }}>
                        {modals.addCert ? (
                            <AddCACertificate
                                open={modals.addCert}
                                onClose={() => setModals(initialModalState)}
                                onSuccess={(name: string) => {
                                    NotificationStore.push({
                                        'data-testid': 'certificate-add-success',
                                        kind: 'success',
                                        title: t('certificate.createCertificateSuccess', {
                                            name: name,
                                        }),
                                    });
                                }}
                            />
                        ) : null}

                        {modals.renameCert ? (
                            <RenameCACertificate
                                open={modals.renameCert}
                                onClose={() => setModals(initialModalState)}
                                onSuccess={(name: string) => {
                                    NotificationStore.push({
                                        kind: 'success',
                                        title: t('certificate.renameCertificateSuccess', { name: name }),
                                        'data-testid': 'certificate-rename-success',
                                    });
                                }}
                            />
                        ) : null}

                        {modals.deleterCert ? (
                            <DeleteCACertificate
                                open={modals.deleterCert}
                                onClose={() => setModals(initialModalState)}
                                onSuccess={(name: string) => {
                                    NotificationStore.push({
                                        kind: 'success',
                                        title: t('certificate.deleteCertificateSuccess', {
                                            name: name,
                                        }),
                                        'data-testid': 'certificate-delete-success',
                                    });
                                }}
                            />
                        ) : null}

                        <DataGrid
                            idPrefix="certificates"
                            rows={rows}
                            headers={headers}
                            showSearch={true}
                            onAdd={onAdd}
                            addButtonCaption={t('main.create')}
                            rowExpansionItems={certificateDetails ?? []}
                            rowExpansionMethod={info => (
                                <CertificateRowDetails
                                    info={info}
                                    downloadCertificate={(link: string, fileName: string) => {
                                        downloadCertificate(link, fileName);
                                    }}
                                />
                            )}></DataGrid>
                    </div>
                );
            }}
        </Observer>
    );
};

export default CaCertificates;
