import React, { useState } from 'react';
import { t } from '@baffle/translate';
import { Observer } from 'mobx-react-lite';
import TextInput from '@baffle/components/src/forms/TextInput';
import cx from 'classnames';
import DropdownSelect, { getDefaultMenuProps } from '@baffle/components/src/dropdown/Dropdown';
import { ApplicationStore, DataFormatStore, KeyDetailsStore } from '../../../../stores';
import {
    ApplicationDetailsV2,
    DataProtectionItem,
    JsonKeyDetail,
    JsonPolicy,
    KeyDetails,
} from '@baffle/graphql/src/models';
import { useParams } from 'react-router-dom';
import setKeyDisplay from '@baffle/utilities/src/setKeyDisplay';
import { InputActions, InputState } from '@baffle/utilities/src/inputHooks';
import JsonPolicyAddIcon from '@baffle/components/src/icons/JsonPolicyAddIcon';
import MinusIcon from '@baffle/components/src/icons/MinusIcon';
import { ToastStore } from '../../../../stores/ToastStore';

interface JsonFieldsProps {
    inputState: InputState;
    inputActions: InputActions;
    defaultEncMode?: DataProtectionItem;
    existingPolicy?: JsonPolicy | null;
    editMode: boolean;
    jsonFieldsErr: any;
}

const JsonFields = ({
    inputActions,
    inputState,
    defaultEncMode,
    existingPolicy,
    editMode,
    jsonFieldsErr,
}: JsonFieldsProps) => {
    const dataTypes = [
        {
            id: 'string',
            label: 'string',
            'data-testid': 'id-string',
        },
        {
            id: 'number',
            label: 'number',
            'data-testid': 'id-number',
        },
        {
            id: 'boolean',
            label: 'boolean',
            'data-testid': 'id-boolean',
        },
    ];
    return (
        <Observer>
            {() => {
                const textInputClass =
                    'shadow-sm bg-gray-10 focus:ring-blue focus:border-blue focus:text-gray-700 block w-full text-xs pl-2 pr-2 py-2 focus:rounded-none focus:outline-none';
                const dropdownBtnClass =
                    'flex px-2 py-2 items-center text-gray-100 border-b border-gray-50 bg-gray-10 focus:outline-none focus:border-blue justify-between truncate h-8 text-xs';
                const encModes = DataFormatStore.encryptionModes.map(mode => {
                    return {
                        id: mode.id,
                        label: mode.name,
                        'data-testid': mode.id,
                    };
                });
                const { id: appId } = useParams() as { id: string };
                const application: ApplicationDetailsV2 | undefined = ApplicationStore.get(appId);
                const keys: KeyDetails[] =
                    KeyDetailsStore.get(application?.keystore as string, {
                        forEncryption: true,
                    }) ?? [];
                const keyIdItems = keys.map((k: KeyDetails) => ({
                    id: k.keyId + '',
                    label: setKeyDisplay(k.keyId) as string,
                }));

                const handleJsonField = (fieldKey: string, value: string | undefined, fieldIndex: number) => {
                    const jsonFields = inputState.jsonFields as Array<JsonKeyDetail>;
                    const field = jsonFields[fieldIndex];
                    if (fieldKey === 'encryptionMode') {
                        field[fieldKey] = DataFormatStore.encryptionModes.find(
                            mode => mode.id === value
                        ) as DataProtectionItem;
                    } else {
                        //@ts-ignore
                        field[fieldKey] = value;
                    }
                    inputActions.setField('jsonFields', jsonFields);
                };

                const addNewField = () => {
                    const jsonFields = (inputState.jsonFields as Array<JsonKeyDetail>) ?? [];
                    const newField: JsonKeyDetail = {
                        jsonKeyName: '',
                        datatype: '',
                        encryptionMode: defaultEncMode as DataProtectionItem,
                        keyId: 2,
                    };
                    jsonFields.push(newField);
                    inputActions.setField('jsonFields', jsonFields);
                };

                const removeField = (index: number) => {
                    const jsonFields = inputState.jsonFields as Array<JsonKeyDetail>;
                    jsonFields.splice(index, 1);
                    inputActions.setField('jsonFields', jsonFields);
                };

                const jsonFields = (inputState.jsonFields as Array<JsonKeyDetail>) ?? [];
                return (
                    <div>
                        {jsonFields.length > 0 ? (
                            <div className={cx({ 'mt-4': existingPolicy })}>
                                <div className="flex text-xs items-end">
                                    <div className="w-32 flex-shrink-0">
                                        {t('encryption.modeLibrary.jsonPolicy.jsonFieldLabel')}
                                    </div>
                                    <div className="w-24 ml-2 flex-shrink-0">
                                        {t('encryption.modeLibrary.jsonPolicy.datatypeLabel')}
                                    </div>
                                    <div className="w-28 ml-2 flex-shrink-0">
                                        {t('encryption.modeLibrary.jsonPolicy.encryptionModeLabel')}
                                    </div>
                                    <div className="w-16 ml-2 flex items-end flex-shrink-0">
                                        <span> {t('encryption.modeLibrary.jsonPolicy.keyIdLabel')} </span>
                                        <span
                                            className="cursor-pointer ml-2"
                                            data-testid="add-new-keyid"
                                            onClick={() => {
                                                const k = KeyDetailsStore.incrementKeyDetailsList(
                                                    application?.keystore as string
                                                );
                                                ToastStore.push({
                                                    type: 'success',
                                                    title: t('encryption.addKeyIdSuccess', {
                                                        id: k.keyId,
                                                    }),
                                                });
                                            }}>
                                            <JsonPolicyAddIcon width={14} height={14} />
                                        </span>
                                    </div>
                                </div>
                                {jsonFields.map((field, index) => {
                                    return (
                                        <JsonField
                                            index={index}
                                            removeField={removeField}
                                            handleJsonField={handleJsonField}
                                            jsonFieldsErr={jsonFieldsErr}
                                            field={field}
                                            textInputClass={textInputClass}
                                            dataTypes={dataTypes}
                                            dropdownBtnClass={dropdownBtnClass}
                                            encModes={encModes}
                                            keyIdItems={keyIdItems}
                                            jsonFields={jsonFields}
                                        />
                                    );
                                })}
                            </div>
                        ) : null}
                        <div className="mt-3 flex">
                            <button
                                data-testid="add-new-field-btn"
                                className={cx('flex items-center justify-center focus:outline-none', {
                                    'cursor-not-allowed': editMode,
                                })}
                                onClick={addNewField}>
                                <JsonPolicyAddIcon
                                    className="w-4 h-4"
                                    color={editMode ? '#C1C7CD' : '#0058A1'}
                                    strokeWidth={1}
                                />
                                <p
                                    className={cx('text-xs ml-1.5 text-blue', {
                                        'text-gray-disable': editMode,
                                    })}>
                                    {t('encryption.modeLibrary.jsonPolicy.addJsonField')}
                                </p>
                            </button>
                        </div>
                    </div>
                );
            }}
        </Observer>
    );
};

interface JsonFieldProps {
    removeField: (a: number) => void;
    handleJsonField: (a: string, b: any, c: number) => void;
    index: number;
    jsonFieldsErr: any;
    field: JsonKeyDetail;
    textInputClass: any;
    dataTypes: any;
    dropdownBtnClass: any;
    encModes: any;
    keyIdItems: any;
    jsonFields: JsonKeyDetail[];
}

const JsonField = ({
    index,
    removeField,
    handleJsonField,
    jsonFieldsErr,
    field,
    textInputClass,
    dataTypes,
    dropdownBtnClass,
    encModes,
    keyIdItems,
    jsonFields,
}: JsonFieldProps) => {
    const [isHover, setIsHover] = useState<boolean>(false);
    return (
        <div className="flex" key={index}>
            <div className="w-32 flex-shrink-0">
                <TextInput
                    id={`json-field-name-input-${index}`}
                    name={`json-field-name-input-${index}`}
                    data-testid={`json-field-name-input-${index}`}
                    invalid={jsonFieldsErr[index]?.jsonKeyName}
                    invalidText={jsonFieldsErr[index]?.jsonKeyName}
                    value={field.jsonKeyName || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const val = e.target.value || '';
                        handleJsonField('jsonKeyName', val, index);
                    }}
                    className="h-8"
                    overrideInputClass={textInputClass}
                    showErrorIcon={false}
                />
            </div>
            <div className="w-24 ml-2 flex-shrink-0">
                <DropdownSelect
                    id={`data-type-select-${index}`}
                    placeholder="Select"
                    invalid={jsonFieldsErr[index]?.datatype}
                    invalidText={jsonFieldsErr[index]?.datatype}
                    label=""
                    value={
                        field.datatype
                            ? dataTypes.find((m: { id: string }) => m.id === field.datatype)
                            : { id: 'chooseOption', label: t('main.select') }
                    }
                    getMenuProps={() => {
                        const { className: ulClasses, ...rest } = getDefaultMenuProps();
                        return {
                            className: `${ulClasses} w-full`,
                            ...rest,
                        };
                    }}
                    handleSelectedItemChange={({ selectedItem }) => {
                        handleJsonField('datatype', selectedItem?.id, index);
                    }}
                    items={dataTypes}
                    overrideToggleBtnClass={dropdownBtnClass}
                    iconSize="small"
                />
            </div>
            <div className="w-32 ml-2 flex-shrink-0">
                <DropdownSelect
                    id={`encryption-mode-select-${index}`}
                    placeholder="Select"
                    invalid={jsonFieldsErr[index]?.encryptionMode}
                    invalidText={jsonFieldsErr[index]?.encryptionMode}
                    label=""
                    value={
                        field.encryptionMode
                            ? encModes.find((m: { id: string }) => m.id === field.encryptionMode.id)
                            : { id: 'chooseOption', label: t('main.chooseOption') }
                    }
                    getMenuProps={() => {
                        const { className: ulClasses, ...rest } = getDefaultMenuProps();
                        return {
                            className: `${ulClasses} w-full`,
                            ...rest,
                        };
                    }}
                    handleSelectedItemChange={({ selectedItem }) => {
                        handleJsonField('encryptionMode', selectedItem?.id, index);
                    }}
                    items={encModes}
                    overrideToggleBtnClass={dropdownBtnClass}
                    iconSize="small"
                />
            </div>
            <div className="w-14 ml-2 flex-shrink-0">
                <DropdownSelect
                    id={`key-id-select-${index}`}
                    placeholder=""
                    invalid={jsonFieldsErr[index]?.keyId}
                    invalidText={jsonFieldsErr[index]?.keyId}
                    label=""
                    value={
                        field.keyId
                            ? keyIdItems.find((m: { id: string }) => m.id === field.keyId + '')
                            : { id: 'chooseOption', label: t('main.chooseOption') }
                    }
                    getMenuProps={() => {
                        const { className: ulClasses, ...rest } = getDefaultMenuProps();
                        return {
                            className: `${ulClasses} w-full`,
                            ...rest,
                        };
                    }}
                    handleSelectedItemChange={({ selectedItem }) => {
                        handleJsonField('keyId', selectedItem?.id, index);
                    }}
                    items={keyIdItems}
                    overrideToggleBtnClass={dropdownBtnClass}
                    iconSize="small"
                />
            </div>
            <div className="w-6 ml-2 mt-3">
                <button
                    data-testid={`remove-field-btn-${index}`}
                    disabled={jsonFields.length === 1}
                    className={cx('flex focus:outline-none', {
                        'cursor-not-allowed': jsonFields.length === 1,
                    })}
                    onClick={() => removeField(index)}
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}>
                    <MinusIcon color={jsonFields.length === 1 || !isHover ? '#C1C7CD' : '#0058A1'} />
                </button>
            </div>
        </div>
    );
};

export default JsonFields;
