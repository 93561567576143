import React, { useState, useEffect, useMemo } from 'react';
import '../../Encryption.scss';
import { RbacConfig, RbacConfigSchema, RbacModesEnum } from '@baffle/graphql/src/models';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import { t } from '@baffle/translate';
import Alert from '@baffle/components/src/alerts/Alert';
import Button from '@baffle/components/src/buttons/Button';
import DropdownSelect, {
    getDefaultContainerClasses,
    getDefaultMenuProps,
    getDefaultToggleButtonProps,
} from '@baffle/components/src/dropdown/Dropdown';
import { DataFormatStore } from '@baffle/manager/src/stores';
import dataProtectionClient from '@baffle/api-client/src/dataProtectionClient';
import useAwait from '@baffle/api-client/src/useAwait';
import EditIcon from '@baffle/components/src/icons/EditIcon';
import cx from 'classnames';
import createRbacConfigInputScheme from '../modeInputSchemes/createRbacConfigInputScheme';
import usePageHeight from '@baffle/utilities/src/usePageHeight';
import RbacConfigSqlComment from './RbacConfigSqlComment';
import RbacConfigSqlCommentJwt from './RbacConfigSqlCommentJwt';
import { useParams } from 'react-router';
import RbacConfigLanding from './RbacConfigLanding';
import useEscapeKeyPress from '@baffle/utilities/src/useEscapePress';
import useMouseClick from '@baffle/utilities/src/useMouseClick';
import RestartShieldModal from '@baffle/manager/src/shields/RestartShieldModal';
import RbacConfigModeHelp from './RbacConfigModeHelp';
import RbacConfigDeterminationHelp from './RbacConfigDeterminationHelp';
import { jsonToString, stringToJson } from '@baffle/utilities/src/column/dataFormat';

interface AddRbacConfigProps {
    onClose?: () => void;
    onSuccess?: (action: string) => void;
    existingConfig: RbacConfig | null | undefined;
}

const AddRbacConfig = ({
    onClose = () => null,
    onSuccess = (action: string) => null,
    existingConfig = null,
}: AddRbacConfigProps) => {
    const { id: appId } = useParams() as { id: string };
    const modeItems = Object.keys(RbacModesEnum).map(key => ({
        id: key,
        label: key,
        'data-testid': `rbacMode-li-${key}`,
    }));
    const userDeterminationItems = Object.keys(RbacConfigSchema).map(key => ({
        id: key,
        label: key,
        'data-testid': `usrDetermination-li-${key}`,
    }));
    const rbacConfigInputSchemes = useMemo(() => createRbacConfigInputScheme(), []);
    const [shieldModal, setShieldModal] = useState(false);
    const [editMode, setEditMode] = useState(Boolean(false));
    const initialHelpCopyState = { showModeHelp: false, showDeterminationHelp: false };
    const [showHelp, setShowHelp] = useState(initialHelpCopyState);
    useEscapeKeyPress(() => {
        setShowHelp(initialHelpCopyState);
    });
    useMouseClick(() => {
        setShowHelp(initialHelpCopyState);
    });
    const { run, error, reset } = useAwait();
    const [validationError, setValidationError] = useState(0);
    const [customErrMsg, setCustomErrMsg] = useState('');
    const [addNewConfig, setAddNewConfig] = useState(false);
    const [configType, setConfigType] = useState(
        existingConfig ? existingConfig.userDetermination : RbacConfigSchema.SESSION
    );
    //@ts-ignore
    const { inputState, inputErrors, inputActions } = useInputState(rbacConfigInputSchemes[configType]);
    const isValid = inputActions.validateAll(true);
    const handleSave = () => {
        const rbacJwtProperties = ['tid', 'aud', 'keyValuePairs', 'secretKey', 'providerUrl', 'cacheCapacity'];
        if (configType === RbacConfigSchema.SQL_COMMENT_JWT) {
            if (!Boolean(inputState.secretKey) && !Boolean(inputState.providerUrl)) {
                setShieldModal(false);
                setCustomErrMsg('Enter either JWT Secret Key or JWKS Provider URL');
                return;
            }
        }
        let payload: RbacConfig = rbacConfigInputSchemes[configType].keys.reduce((acc: any, c: any) => {
            //This creates a new object mapping the keys from the input scheme
            //to the key/values from the inputState. And send only those values which are present
            if (Boolean(inputState[c])) {
                if (rbacJwtProperties.indexOf(c) != -1) {
                    if (!Boolean(acc['rbacJwtProperties'])) {
                        acc['rbacJwtProperties'] = {};
                    }
                    switch (c) {
                        case 'cacheCapacity':
                            // Convert string to number
                            acc['rbacJwtProperties'][c] = +(inputState[c] as string);
                            break;
                        case 'keyValuePairs':
                            acc['rbacJwtProperties'][c] = stringToJson(inputState[c] as string);
                            break;
                        default:
                            acc['rbacJwtProperties'][c] = (inputState[c] as string).trim();
                            break;
                    }
                } else {
                    acc[c] = (inputState[c] as string).trim();
                }
            }
            return acc;
        }, {});
        payload.appId = appId;
        if (Boolean(existingConfig)) {
            payload.id = existingConfig?.id;
        }
        run(
            (Boolean(existingConfig)
                ? dataProtectionClient.updateRbacConfig(existingConfig?.id as string, payload)
                : dataProtectionClient.createRbacConfig(payload)
            ).then((rbacConfig: RbacConfig) => {
                DataFormatStore.setRbacConfig(rbacConfig);
                inputActions.reset();
                reset();
                onSuccess(Boolean(existingConfig) ? 'updated' : 'added');
                if (Boolean(existingConfig)) {
                    DataFormatStore.setShowRbacConfigView(true);
                } else {
                    DataFormatStore.setShowRbacUsrGrpView(true);
                }
            })
        );
    };

    const handleClose = () => {
        inputActions.reset();
        reset();
        onClose();
    };

    const handleEdit = () => {
        setEditMode(false);
    };

    //Fetch supported modes for dropdown
    //Sync state from props
    useEffect(() => {
        if (existingConfig) {
            setEditMode(true);
            const { mode, userDetermination, sqlCommentFormat, rbacJwtProperties } = existingConfig;
            inputActions.setFields({
                mode,
                userDetermination,
                sqlCommentFormat,
                tid: rbacJwtProperties?.tid,
                aud: rbacJwtProperties?.aud,
                keyValuePairs: rbacJwtProperties?.keyValuePairs ? jsonToString(rbacJwtProperties?.keyValuePairs) : '',
                secretKey: rbacJwtProperties?.secretKey,
                providerUrl: rbacJwtProperties?.providerUrl,
                cacheCapacity: rbacJwtProperties?.cacheCapacity,
            });
        } else {
            setEditMode(false);
            inputActions.reset();
        }
    }, [existingConfig]);

    useEffect(() => {
        let validationErrorCount = 0;
        Object.keys(inputErrors).forEach(key => {
            if (inputErrors[key] != null) {
                validationErrorCount += 1;
            }
        });
        setValidationError(validationErrorCount);
        setShowHelp(initialHelpCopyState);
    }, [inputErrors]);

    useEffect(() => {
        inputActions.clearErrors();
        setCustomErrMsg('');
    }, [configType]);

    const pageHeight = usePageHeight() - 64;
    const { className: btnClasses, ...rest } = getDefaultToggleButtonProps();
    return (
        <div className="flex-grow bg-white">
            <div className="bg-gray-200 px-4 h-8 border-solid border-t-2 border-b-2 border-gray-300">
                <div className="relative flex items-center h-full uppercase w-112 font-bold text-xs">
                    {existingConfig ? (
                        <div className="flex-1 flex justify-between items-center">
                            <span>{t('encryption.modeLibrary.rbac.rbacConfiguration')}</span>
                            <button aria-label={t('main.edit')} onClick={handleEdit} data-testid="rbac-config-edit-btn">
                                <EditIcon />
                            </button>
                        </div>
                    ) : (
                        <span>{t('encryption.modeLibrary.rbac.rbacConfiguration')}</span>
                    )}
                </div>
            </div>
            <div className="overflow-y-auto" style={{ height: pageHeight }}>
                <div className="w-116 mt-5 pl-4 mb-12">
                    {error ? (
                        <div className="flex flex-wrap">
                            <div className="flex-1">
                                <Alert
                                    type="danger"
                                    title={t('encryption.modeLibrary.rbac.rbacConfig.rbacConfigError')}
                                    description={error?.message || ''}
                                />
                            </div>
                        </div>
                    ) : null}
                    {validationError ? (
                        <div className="flex flex-wrap">
                            <div className="flex-1">
                                <Alert
                                    type="danger"
                                    title={t('keystore.inputError', { count: validationError })}
                                    data-testid="alert-field-validation-error"
                                />
                            </div>
                        </div>
                    ) : null}
                    {customErrMsg ? (
                        <div className="flex flex-wrap">
                            <div className="flex-1">
                                <Alert type="danger" title={customErrMsg} data-testid="secretkey-providerurl-alert" />
                            </div>
                        </div>
                    ) : null}
                    {!addNewConfig && !Boolean(existingConfig) ? (
                        <RbacConfigLanding setAddNewConfig={setAddNewConfig} />
                    ) : null}

                    {addNewConfig || Boolean(existingConfig) ? (
                        <div>
                            <div className="flex flex-wrap mb-6">
                                <div className="flex-1 relative">
                                    <DropdownSelect
                                        id="rbac-config-mode-select-input"
                                        placeholder={t('main.chooseOption')}
                                        label={t('encryption.modeLibrary.rbac.rbacConfig.modeLabel')}
                                        value={
                                            inputState.mode
                                                ? modeItems.find(m => m.id === inputState.mode)
                                                : { id: 'chooseOption', label: t('main.chooseOption') }
                                        }
                                        getMenuProps={() => {
                                            const { className: ulClasses, ...rest } = getDefaultMenuProps();
                                            return {
                                                className: `${ulClasses} w-full`,
                                                ...rest,
                                            };
                                        }}
                                        handleSelectedItemChange={({ selectedItem }) => {
                                            inputActions.setField('mode', selectedItem?.id as string);
                                        }}
                                        items={modeItems}
                                        getToggleButtonProps={() => {
                                            return {
                                                disabled: editMode,
                                                className: cx(btnClasses, {
                                                    disabled: editMode,
                                                }),
                                                ...rest,
                                            };
                                        }}
                                        containerClasses={getDefaultContainerClasses() + ' h-14'}
                                    />
                                    <RbacConfigModeHelp
                                        onRefClick={() => {
                                            setShowHelp({
                                                showModeHelp: !showHelp.showModeHelp,
                                                showDeterminationHelp: false,
                                            });
                                        }}
                                        showHelp={showHelp}
                                        customErrMsg={customErrMsg}
                                        validationError={validationError}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap mb-6 items-start">
                                <div className="flex-1 relative">
                                    <DropdownSelect
                                        id="user-determination-select-input"
                                        placeholder={t('main.chooseOption')}
                                        label={t('encryption.modeLibrary.rbac.rbacConfig.usrDeterminationLabel')}
                                        value={
                                            inputState.userDetermination
                                                ? userDeterminationItems.find(
                                                      m => m.id === inputState.userDetermination
                                                  )
                                                : { id: 'chooseOption', label: t('main.chooseOption') }
                                        }
                                        getMenuProps={() => {
                                            const { className: ulClasses, ...rest } = getDefaultMenuProps();
                                            return {
                                                className: `${ulClasses} w-full`,
                                                ...rest,
                                            };
                                        }}
                                        handleSelectedItemChange={({ selectedItem }) => {
                                            setConfigType(selectedItem?.id as RbacConfigSchema);
                                            inputActions.setField('userDetermination', selectedItem?.id as string);
                                        }}
                                        items={userDeterminationItems}
                                        getToggleButtonProps={() => {
                                            return {
                                                disabled: editMode,
                                                className: cx(btnClasses, {
                                                    disabled: editMode,
                                                }),
                                                ...rest,
                                            };
                                        }}
                                        containerClasses={getDefaultContainerClasses() + ' h-14'}
                                    />
                                    <RbacConfigDeterminationHelp
                                        onRefClick={() => {
                                            setShowHelp({
                                                showDeterminationHelp: !showHelp.showDeterminationHelp,
                                                showModeHelp: false,
                                            });
                                        }}
                                        showHelp={showHelp}
                                        customErrMsg={customErrMsg}
                                        validationError={validationError}
                                    />
                                </div>
                            </div>
                            {inputState.userDetermination === RbacConfigSchema.SQL_COMMENT_RAW ? (
                                <RbacConfigSqlComment
                                    inputState={inputState}
                                    inputActions={inputActions}
                                    inputErrors={inputErrors}
                                    editMode={editMode}
                                />
                            ) : null}
                            {inputState.userDetermination === RbacConfigSchema.SQL_COMMENT_JWT ? (
                                <RbacConfigSqlCommentJwt
                                    inputState={inputState}
                                    inputActions={inputActions}
                                    inputErrors={inputErrors}
                                    editMode={editMode}
                                />
                            ) : null}
                            {editMode ? (
                                <div className="flex w-full justify-end h-8">
                                    <Button
                                        theme="primary"
                                        className="py-2 w-24 font-semibold text-xs uppercase"
                                        onClick={handleEdit}>
                                        {t('main.edit')}
                                    </Button>
                                </div>
                            ) : (
                                <div className="flex w-full justify-end h-8">
                                    <Button
                                        theme="secondary"
                                        className="py-2 w-24 font-semibold text-xs uppercase"
                                        onClick={() => {
                                            handleClose();
                                        }}>
                                        {t('main.cancel')}
                                    </Button>
                                    <Button
                                        className="py-2 w-24 font-semibold ml-2 text-xs uppercase"
                                        id="save-rbac-config-btn"
                                        theme={!isValid ? 'disabled' : 'primary'}
                                        onClick={() => setShieldModal(true)}>
                                        {t('main.save')}
                                    </Button>
                                </div>
                            )}
                        </div>
                    ) : null}
                </div>
            </div>
            <RestartShieldModal
                customRestartFunc={handleSave}
                open={shieldModal}
                onClose={() => setShieldModal(false)}
                subHeader={t('encryption.modeLibrary.rbac.rbacConfig.restartShieldHeader')}
                body={t('encryption.modeLibrary.rbac.rbacConfig.restartShieldBody')}
            />
        </div>
    );
};

export default AddRbacConfig;
