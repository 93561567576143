import maskingPolicyClient from '@baffle/api-client/src/maskingPolicyClient';
import { MaskingPolicy } from '@baffle/graphql/src/models';
import { observable, action, makeObservable } from 'mobx';
import { RequestOpts } from '../types/StoreTypes';
import { format } from '@baffle/utilities/src/date';

class MaskingPolicyStore {
    constructor() {
        makeObservable(this, {
            maskingPolicies: observable,
            maskingPolicy: observable,
            setMaskingPolicies: action,
            setMaskingPolicy: action,
        });
    }
    maskingPolicies = observable<MaskingPolicy>([]);
    maskingPolicy: MaskingPolicy | null = null;

    setMaskingPolicies(maskingPolicies: MaskingPolicy[]) {
        this.maskingPolicies.replace(maskingPolicies);
    }

    setMaskingPolicy(maskingPolicy: MaskingPolicy | null) {
        this.maskingPolicy = maskingPolicy;
    }

    async createMaskingPolicy(maskingPolicy: MaskingPolicy) {
        try {
            await maskingPolicyClient.createMaskingPolicy(maskingPolicy);
            await this.getMaskingPolicies();
        } catch (error) {
            throw error;
        }
    }

    async getMaskingPolicies(opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first' && this.maskingPolicies.length > 0) {
            return;
        }
        try {
            const res = await maskingPolicyClient.getMaskingPolicies();
            this.setMaskingPolicies(res.data);
        } catch (error) {
            throw error;
        }
    }

    async getMaskingPolicy(modeID: string, opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first') {
            return;
        }
        try {
            const maskingPolicy = await maskingPolicyClient.readMaskingPolicy(modeID);
            this.setMaskingPolicy(maskingPolicy);
        } catch (error) {
            throw error;
        }
    }

    async updateMaskingPolicy(id: string, maskingPolicy: MaskingPolicy) {
        try {
            await maskingPolicyClient.updateMaskingPolicy(id, maskingPolicy);
            await this.getMaskingPolicies();
            await this.getMaskingPolicy(id);
        } catch (error) {
            throw error;
        }
    }

    async deleteMaskingPolicy(id: string) {
        try {
            await maskingPolicyClient.deleteMaskingPolicy(id);
            await this.getMaskingPolicies();
        } catch (error) {
            throw error;
        }
    }

    async renameMaskingPolicy(id: string, payload: { newName: string }) {
        try {
            await maskingPolicyClient.renameMaskingPolicy(id, payload);
            await this.getMaskingPolicy(id);
            await this.getMaskingPolicies();
        } catch (error) {
            throw error;
        }
    }

    get(id: string) {
        return this.maskingPolicies.find(m => m.id === id);
    }

    getMaskingPolicyOverview(maskingPolicy: MaskingPolicy) {
        return {
            id: maskingPolicy.id,
            name: maskingPolicy.name,
            mode: maskingPolicy.mode,
            value: maskingPolicy.value,
            datatypeGroup: maskingPolicy.datatypeGroup,
            default: maskingPolicy.isDefault,
            ...(!maskingPolicy.isDefault && {
                createdDate: format(maskingPolicy.audit.createdDate, 'MM-dd-yyyy'),
                createdBy: maskingPolicy.audit.createdBy,
            }),
        };
    }
}

export default MaskingPolicyStore;
