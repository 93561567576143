import { MigrationPlan, MigrationPlanInput } from '../../../graphql/src/models';

export const migrationPlanHasChanged = (migrationPlan: MigrationPlan, input: MigrationPlanInput) => {
    return (
        migrationPlan.batchSize !== input.batchSize ||
        migrationPlan.batch !== input.batch ||
        migrationPlan.parallel !== input.parallel ||
        migrationPlan.cleanTmp !== input.cleanTmp ||
        migrationPlan.failScope !== input.failScope
    );
};
