import React, { useEffect, useState } from 'react';
import useAwait from '@baffle/api-client/src/useAwait';
import Content from '@baffle/components/src/content/Content';
import DataGrid from '@baffle/components/src/grid/data-grid';
import { useManageModals } from '@baffle/components/src/modal/Modal';
import Header from '@baffle/components/src/nav/Header';
import { t } from '@baffle/translate';
import usePageHeight from '@baffle/utilities/src/usePageHeight';
import { Observer } from 'mobx-react-lite';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';
import AddDataBaseProxy from './AddDataBaseProxy';
import { DatabaseProxy } from '@baffle/graphql/src/models';
import { DatabaseProxiesStore } from '@baffle/manager/src/stores';
import Status from '@baffle/components/src/status/Status';
import { StatusMaps } from '@baffle/utilities/src/enums';
import RenameDataBaseProxyModal from './RenameDataBaseProxyModal';
import DeleteDataBaseProxyModal from './DeleteDataBaseProxyModal';

const DatabaseProxies = () => {
    const pageHeight = usePageHeight() - 136;
    const { status, run } = useAwait();
    const [update, setUpdate] = useState(false);

    useEffect(() => {
        run(DatabaseProxiesStore.readDatabaseProxies());
    }, []);

    useEffect(() => {
        if (update === true) {
            run(DatabaseProxiesStore.readDatabaseProxies());
            setUpdate(false);
        }
    }, [update]);

    const onSyncButtonClick = () => {
        setUpdate(true);
    };

    const headers = [
        {
            key: 'header1',
            header: t('databaseProxies.header1'),
        },
        {
            key: 'header2',
            header: t('databaseProxies.header2'),
        },
        {
            key: 'header3',
            header: t('databaseProxies.header3'),
        },
        {
            key: 'header4',
            header: t('databaseProxies.header4'),
        },
        {
            key: 'header5',
            header: t('databaseProxies.header5'),
        },
    ];

    return (
        <Observer>
            {() => {
                interface DataBaseProxiesModals {
                    addDataBaseProxy: boolean;
                    renameDataBaseProxy: boolean;
                    deleteDataBaseProxy: boolean;
                }

                const initialModalState = {
                    addDataBaseProxy: false,
                    renameDataBaseProxy: false,
                    deleteDataBaseProxy: false,
                };

                const [modals, setModals] = useManageModals<DataBaseProxiesModals>(initialModalState);

                const databaseProxies: DatabaseProxy[] = DatabaseProxiesStore.databaseProxies;

                const onAdd = () => {
                    setModals({ addDataBaseProxy: true });
                };

                const onRename = (id: string) => {
                    DatabaseProxiesStore.setDatabaseProxy(DatabaseProxiesStore.get(id) as DatabaseProxy);
                    setModals({ renameDataBaseProxy: true });
                };

                const onDelete = (id: string) => {
                    DatabaseProxiesStore.setDatabaseProxy(DatabaseProxiesStore.get(id) as DatabaseProxy);
                    setModals({ deleteDataBaseProxy: true });
                };

                const rows =
                    status === 'loading' || status === 'idle'
                        ? null
                        : databaseProxies.map((databaseProxy: DatabaseProxy, i: number) => {
                              return {
                                  id: databaseProxy.id,
                                  header1: databaseProxy.name,
                                  header2: databaseProxy.database?.name,
                                  header3: databaseProxy.keystore?.name,
                                  header4: databaseProxy.protectedColumns,
                                  header5: (
                                      <Status
                                          title={t(
                                              `application.${StatusMaps.appStatusMap[databaseProxy.status ?? 'NA']}`
                                          )}
                                          type={StatusMaps.statusIconMap[databaseProxy.status ?? 'NA']}
                                      />
                                  ),
                                  link: '/database-proxies/' + databaseProxy.id,
                                  overFlowMenu: [
                                      {
                                          name: t('main.rename'),
                                          onClick: (id: string) => onRename(id as string),
                                      },
                                      {
                                          name: t('main.delete'),
                                          onClick: (id: string) => onDelete(id as string),
                                      },
                                  ],
                              };
                          });

                return (
                    <>
                        <Content>
                            <Header>
                                <div className="flex-1 my-5 px-10 flex justify-between">
                                    <div className="flex-1 flex items-center">
                                        <h1 className="text-heading-01 text-gray-100">{t('main.databaseProxies')}</h1>
                                    </div>
                                </div>
                            </Header>
                            <div className="overflow-y-auto" style={{ height: pageHeight }}>
                                <DataGrid
                                    idPrefix="databaseProxies"
                                    rows={rows}
                                    headers={headers}
                                    showSearch={true}
                                    onAdd={onAdd}
                                    addButtonCaption={t('main.create')}
                                    onSync={onSyncButtonClick}></DataGrid>
                            </div>

                            {modals.addDataBaseProxy ? (
                                <AddDataBaseProxy
                                    open={modals.addDataBaseProxy}
                                    onClose={() => setModals({ addDataBaseProxy: false })}
                                    onSuccess={(name: string) => {
                                        NotificationStore.push({
                                            'data-testid': 'database-proxy-add-success',
                                            kind: 'success',
                                            title: t('databaseProxies.addDataBaseSuccess', {
                                                name: name,
                                            }),
                                        });
                                    }}
                                />
                            ) : null}

                            {modals.renameDataBaseProxy ? (
                                <RenameDataBaseProxyModal
                                    open={modals.renameDataBaseProxy}
                                    onClose={() => setModals({ renameDataBaseProxy: false })}
                                    onSuccess={(name: string) => {
                                        NotificationStore.push({
                                            'data-testid': 'database-proxy-rename-success',
                                            title: t('databaseProxies.renameDataBaseSuccess', { name }),
                                            kind: 'success',
                                        });
                                    }}
                                />
                            ) : null}

                            {modals.deleteDataBaseProxy ? (
                                <DeleteDataBaseProxyModal
                                    open={modals.deleteDataBaseProxy}
                                    onClose={() => setModals({ deleteDataBaseProxy: false })}
                                    onSuccess={(name: string) => {
                                        run(DatabaseProxiesStore.readDatabaseProxies());
                                        NotificationStore.push({
                                            kind: 'success',
                                            'data-testid': 'database-proxy-delete-success',
                                            title: t('databaseProxies.deleteDataBaseSuccess', {
                                                name: name,
                                            }),
                                        });
                                    }}
                                />
                            ) : null}
                        </Content>
                    </>
                );
            }}
        </Observer>
    );
};

export default DatabaseProxies;
