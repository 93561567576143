import React from 'react';
export const SkeletonCardWithText = () => (
    <div className="border border-light-blue-300 shadow rounded-md p-4 max-w-sm w-full mx-auto">
        <div className="animate-pulse flex flex-col space-x-4">
            <div className="flex-1 space-y-4 py-1">
                <div className="h-4  bg-gray-300 rounded w-3/4"></div>
                <div className="space-y-2">
                    <div className="h-4  bg-gray-300 rounded"></div>
                    <div className="h-4  bg-gray-300 rounded w-5/6"></div>
                </div>
            </div>
        </div>
    </div>
);

export const SkeletonUserCard = () => (
    <div className="col-span-1 border bg-gray-600 shadow p-4 max-w-sm w-full mx-auto">
        <div className="animate-pulse flex flex-col space-x-4">
            <div className="rounded-full w-32 h-32 mx-auto bg-blue-300 mb-8"></div>
            <div className="flex-1 space-y-4 py-1">
                <div className="h-4  bg-white w-3/4"></div>
                <div className="space-y-2">
                    <div className="h-4  bg-white "></div>
                    <div className="h-4  bg-white w-5/6"></div>
                </div>
            </div>
        </div>
    </div>
);
