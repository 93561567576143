import React, { useMemo } from 'react';
import cx from 'classnames';
import { ButtonTheme } from '../types/theme';

export const getDefaultButtonProps: () => any = () => ({
    className: 'flex border-solid border',
});

type Size = 'none' | 'sm' | 'default' | 'lg';

interface Props extends Partial<React.ButtonHTMLAttributes<HTMLElement>> {
    theme: ButtonTheme;
    className?: string;
    disabled?: boolean;
    tabIndex?: number;
    getButtonProps?: () => any;
    children: React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
    rest?: any;
    size?: Size;
}
const Button = ({
    theme,
    className = '',
    disabled = theme === 'disabled' ? true : false,
    tabIndex = 0,
    children,
    size = 'default',
    getButtonProps = getDefaultButtonProps,
    ...rest
}: Props) => {
    const buttonProps = useMemo(() => getButtonProps(), [getButtonProps]);
    if (!buttonProps) {
        throw new Error('did not return button props value from getButtonProps');
    }

    //buttonClassName preserves backwards compatibility
    const { className: buttonClassName, ...props } = buttonProps;
    const classes = cx(
        buttonClassName,
        {
            'text-white bg-blue-60 border-blue-60 hover:bg-blue-60-hover active:bg-blue-80 focus:bg-blue-60 focus: border-white':
                theme === 'primary',
            'text-white bg-gray-80 border-gray-80 hover:bg-gray-80-hover active:bg-gray-60 focus:bg-gray-80 focus: border-white':
                theme === 'secondary',
            'text-white bg-red-60 border-red-60 hover:bg-red-60-hover active:bg-red-80 focus:bg-red-60 focus: border-white':
                theme === 'danger',
            'border-blue-60 text-blue-60 hover:bg-blue-60-hover hover:text-white active:bg-blue-80 active:text-white focus:bg-blue-60 focus:text-white focus: border-white':
                theme === 'tertiary',
            'border-red-60 text-red-60 hover:bg-red-60-hover hover:text-white active:bg-red-80 active:text-white focus:bg-red-60 focus:text-white focus: border-white':
                theme === 'tertiary-danger',
            'text-white cursor-not-allowed bg-gray-30 pointer-events-none focus: border-white': theme === 'disabled',
            // DEPRECATION WARNING: use newer themes defined above instead
            'border-blue text-white bg-blue': theme === 'blue',
            'border-red text-white bg-red': theme === 'red',
            'border-black text-white bg-black': theme === 'black',
            'text-blue border-none': theme === 'blue-link',
            'text-red border-none': theme === 'red-link',
            'border-none bg-transparent hover:bg-gray-50-hover active:bg-gray-50-active focus:border-blue-60':
                theme === 'icon-transparent',
            'border-gray-300 bg-gray-200': theme === 'border-gray',
            'border-none text-white bg-orange': theme === 'orange',
            'border-blue text-blue': theme === 'blue-ghost',
        },
        {
            'items-center justify-center': size === 'none',
            'p-2 items-center justify-center': size === 'sm',
            'px-4 py-2 items-center': size === 'default',
            'p-4': size === 'lg',
        },
        className
    );
    return (
        <button
            role="button"
            tabIndex={disabled ? -1 : tabIndex || 0}
            disabled={disabled}
            aria-disabled={disabled}
            className={classes}
            {...rest}
            {...props}>
            {children}
        </button>
    );
};

export default Button;
