import client from '.';
import {
    ColumnMetaData,
    EncryptionDatabaseTable,
    ApplicationEncryptionPayload,
    EncryptionSchema,
    DeploymentState,
    CreateNewDatabaseDTO,
    CreateNewSchemaDTO,
    CreateNewTableDTO,
    CreateNewDbOwner,
    CryptoActionType,
    ApplicationBindingDTO,
} from '@baffle/graphql/src/models';

//gets a list of databases available for encryption
export async function readSchema(id: string) {
    try {
        const schema: EncryptionSchema = (await client(`api/v1.0/application/encschema/${id}`)) ?? {};
        const columns = schema.columns ?? [];
        for (let index = 0; index < columns.length; index++) {
            const column = columns[index];
            compileColumn(id, column);
        }
        return schema;
    } catch (error) {
        throw error;
    }
}

//gets a list of databases available for encryption
export async function readDatabases(id: string) {
    try {
        return (await client(`api/v1.0/application/encryption/${id}`)) ?? [];
    } catch (error) {
        throw error;
    }
}

//gets a list of schemas for a given database
export async function readDatabaseSchemas(id: string, dbName: string) {
    try {
        return (await client(`api/v1.0/application/encryption/database/schema/${id}/${dbName}`)) ?? [];
    } catch (error) {
        throw error;
    }
}

const compileTable = (id: string, dbName: string, table: EncryptionDatabaseTable) => {
    table.path = `${dbName}/${table.name}`;
    table.application = id;
};

//gets a list of tables for a given schema
export async function readSchemaTables(id: string, dbSchema: string) {
    try {
        const tables = (await client(`api/v1.0/application/encryption/tables/${id}/${dbSchema}`)) ?? [];
        for (let index = 0; index < tables.length; index++) {
            const table = tables[index];
            compileTable(id, dbSchema, table);
        }
        return tables;
    } catch (error) {
        throw error;
    }
}

//gets a list of views for a given schema
export async function readSchemaViews(id: string, dbSchema: string) {
    try {
        const views = (await client(`api/v1.0/application/encryption/views/${id}/${dbSchema}`)) ?? [];
        for (let index = 0; index < views.length; index++) {
            const view = views[index];
            compileTable(id, dbSchema, view);
        }
        return views;
    } catch (error) {
        throw error;
    }
}

//gets a list of tables for a given database
export async function readTables(id: string, dbName: string) {
    try {
        const tables = (await client(`api/v1.0/application/encryption/tables/${id}/${dbName}`)) ?? [];
        for (let index = 0; index < tables.length; index++) {
            const table = tables[index];
            compileTable(id, dbName, table);
        }
        return tables;
    } catch (error) {
        throw error;
    }
}

//gets a list of views for a given database
export async function readViews(id: string, dbName: string) {
    try {
        const views = (await client(`api/v1.0/application/encryption/views/${id}/${dbName}`)) ?? [];
        for (let index = 0; index < views.length; index++) {
            const view = views[index];
            compileTable(id, dbName, view);
        }
        return views;
    } catch (error) {
        throw error;
    }
}

export const compileColumn = (id: string, col: ColumnMetaData) => {
    col.deploymentState = (col.deploymentState ?? 'none').toLowerCase() as DeploymentState;
    //this is a little odd, but at present the BE sends an object id, we need an actual string and this
    //solves that
    col.application = id;
    col.path = `${col.database}/${col.table}/${col.name}`;
};

//gets a list of columns for a given table
export async function readSchemaTableColumns(id: string, dbSchema: string, tableName: string) {
    try {
        const columns = (await client(`api/v1.0/application/encryption/columns/${id}/${dbSchema}/${tableName}`)) ?? [];
        for (let index = 0; index < columns.length; index++) {
            const column = columns[index];
            compileColumn(id, column);
        }
        return columns;
    } catch (error) {
        throw error;
    }
}

//gets a list of columns for a given table
export async function readColumns(id: string, dbName: string, tableName: string) {
    try {
        const columns = (await client(`api/v1.0/application/encryption/columns/${id}/${dbName}/${tableName}`)) ?? [];
        for (let index = 0; index < columns.length; index++) {
            const column = columns[index];
            compileColumn(id, column);
        }
        return columns;
    } catch (error) {
        throw error;
    }
}

export async function createSchema(payload: ApplicationEncryptionPayload) {
    return client('api/v1.0/application/encryption', { useForm: true, body: payload, method: 'POST' });
}

export async function createNewDatabase(payload: CreateNewDatabaseDTO) {
    return client('api/v1.0/application/encryption/database', { body: payload, method: 'POST' });
}

export async function removeNewDatabase(appID: string, databaseName: string) {
    return client(`api/v1.0/application/encryption/database/${appID}/${databaseName}`, { method: 'DELETE' });
}

export async function editNewDatabase(payload: CreateNewDatabaseDTO, databaseName: string) {
    return client(`api/v1.0/application/encryption/database/${databaseName}`, {
        body: payload,
        method: 'PUT',
    });
}

export async function createNewSchema(payload: CreateNewSchemaDTO) {
    return client('api/v1.0/application/encryption/database/schema', { body: payload, method: 'POST' });
}

export async function editNewSchema(payload: CreateNewSchemaDTO, schemaName: string) {
    return client(`api/v1.0/application/encryption/database/schema/${schemaName}`, {
        body: payload,
        method: 'PUT',
    });
}

export async function removeNewSchema(appID: string, schemaName: string) {
    return client(`api/v1.0/application/encryption/database/schema/${appID}/${schemaName}`, {
        method: 'DELETE',
    });
}

export async function createNewTable(payload: CreateNewTableDTO) {
    return client('api/v1.0/application/encryption/table', { body: payload, method: 'POST' });
}

export async function editNewTable(payload: CreateNewTableDTO, tableName: string) {
    return client(`api/v1.0/application/encryption/table/${tableName}`, {
        body: payload,
        method: 'PUT',
    });
}

export async function removeNewTable(appID: string, databaseName: string, schemaName: string, tableName: string) {
    if (schemaName) {
        return client(`api/v1.0/application/encryption/table/${appID}/${schemaName}/${tableName}`, {
            method: 'DELETE',
        });
    } else {
        return client(`api/v1.0/application/encryption/table/${appID}/${databaseName}/${tableName}`, {
            method: 'DELETE',
        });
    }
}

export async function getNewColumns(appID: string, databaseName: string, tableName: string) {
    return client(`/api/v1.0/application/encryption/new/columns/${appID}/${databaseName}/${tableName}`);
}

export async function createDbOwnerCredentials(payload: CreateNewDbOwner) {
    return client('/api/v1.0/database/owner', {
        body: payload,
        method: 'POST',
    });
}

export async function readDeployAndMigrate(payload: ApplicationBindingDTO, cryptoAction: CryptoActionType) {
    if (cryptoAction === 'encryption') {
        return client('api/v1.0/application/encryption/review', {
            body: payload,
            method: 'POST',
        });
    } else {
        return client('api/v1.0/application/decryption/review', {
            body: payload,
            method: 'POST',
        });
    }
}

export default {
    readSchema,
    readDatabases,
    readDatabaseSchemas,
    readSchemaTables,
    readSchemaViews,
    readTables,
    readViews,
    readSchemaTableColumns,
    readColumns,
    createSchema,
    createNewDatabase,
    removeNewDatabase,
    editNewDatabase,
    createNewSchema,
    editNewSchema,
    removeNewSchema,
    createNewTable,
    editNewTable,
    removeNewTable,
    getNewColumns,
    createDbOwnerCredentials,
    readDeployAndMigrate,
    compileColumn,
};
