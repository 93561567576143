import React, { useState, useEffect } from 'react';
import { t } from '@baffle/translate';
import { SystemEmailStore } from '@baffle/manager/src/stores';
import Alert from '@baffle/components/src/alerts/Alert';
import { SystemInfoEmail } from '@baffle/graphql/src/models';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@baffle/components/src/modal/Modal';
import Button from '@baffle/components/src/buttons/Button';
import useAwait from '@baffle/api-client/src/useAwait';

interface DeleteSmtpModalProps {
    open: boolean;
    onClose?: () => void;
    onSuccess?: () => void;
}

const DeleteSmtpModal = ({ open, onClose, onSuccess }: DeleteSmtpModalProps) => {
    const [openModal, setOpen] = useState(open);
    const systemEmail: SystemInfoEmail | null = SystemEmailStore.systemEmail;
    const { run, reset, error } = useAwait();

    const handleClose = () => {
        if (!openModal) {
            return;
        }
        setOpen(false);
        reset();
        setTimeout(() => {
            if (onClose) {
                onClose();
            }
        }, 200);
    };

    const doDeleteSystemEmail = () => {
        run(
            SystemEmailStore.removeSystemEmail(systemEmail?.id as string).then(() => {
                handleClose();
                if (onSuccess) {
                    onSuccess();
                }
            })
        );
    };

    //Sync state from props
    useEffect(() => {
        setOpen(open);
    }, [open]);

    return (
        <Modal open={openModal} onClose={handleClose}>
            <ModalHeader onClose={handleClose}>
                <div className="flex items-start">
                    <div className="mt-0 ml-2">
                        <h3 className="text-xl font-medium text-gray-100" id="modal-headline">
                            {t('settings.deleteEmailSettingsModalHeading')}
                        </h3>
                    </div>
                </div>
            </ModalHeader>
            <ModalBody>
                <div className="flex flex-col px-4">
                    {error ? (
                        <div className="flex flex-wrap mb-4">
                            <div className="flex-1 px-4">
                                <Alert
                                    type="danger"
                                    title={t('settings.deleteEmailConfigError')}
                                    description={error?.message || ''}
                                />
                            </div>
                        </div>
                    ) : null}
                    <p
                        className="text-lg mb-4 py-8"
                        dangerouslySetInnerHTML={{
                            __html: t('settings.deleteSmtpConfigConfirmationMsg', {
                                configName: 'SMTP Configuration?',
                            }),
                        }}></p>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex justify-end items-center h-16 w-full">
                    <Button theme="secondary" className="h-full w-1/2" onClick={() => handleClose()} size="lg">
                        {t('main.cancel')}
                    </Button>
                    <Button
                        className="h-full w-1/2"
                        data-testid="do-delete-email-settings-btn"
                        theme="danger"
                        id="do-delete-email-settings-btn"
                        onClick={doDeleteSystemEmail}
                        size="lg">
                        {t('main.delete')}
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default DeleteSmtpModal;
