import React, { useEffect } from 'react';
import { t } from '@baffle/translate';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@baffle/components/src/modal/Modal';
import Button from '@baffle/components/src/buttons/Button';
import Alert from '@baffle/components/src/alerts/Alert';
import useAwait from '@baffle/api-client/src/useAwait';
import { ApplicationV2, ShieldForApplicationDetails } from '@baffle/graphql/src/models';
import debounce from 'lodash.debounce';
import applicationClient from '@baffle/api-client/src/applicationClient';

interface ModalPropsWithoutChildren {
    'aria-labelledby'?: string;
    open: boolean;
    application?: ApplicationV2;
    shield?: ShieldForApplicationDetails;
    onSuccess?: ({ application, shield }: { application: ApplicationV2; shield: ShieldForApplicationDetails }) => void;
    onClose?: () => void;
}

const DeleteBaffleShieldFromApplicationModal = ({
    application,
    shield,
    open,
    onSuccess = ({ application, shield }: { application: ApplicationV2; shield: ShieldForApplicationDetails }) => null,
    onClose = () => null,
}: ModalPropsWithoutChildren) => {
    const { status, run, error, reset } = useAwait();

    const doRemoveShield = () => {
        run(applicationClient.removeShield({ appId: application?.id as string, shieldId: shield?.id as string }));
    };
    useEffect(() => {
        if (status === 'success') {
            onSuccess({ shield: shield as ShieldForApplicationDetails, application: application as ApplicationV2 });
            onClose();
        }
    }, [status]);

    const handleClose = () => {
        reset();
        onClose();
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <DeleteShieldFromApplication
                application={application}
                shield={shield}
                error={error}
                doRemoveShield={doRemoveShield}
                handleClose={handleClose}
            />
        </Modal>
    );
};

interface DeleteShieldProps {
    handleClose: () => void;
    doRemoveShield: () => void;
    application?: ApplicationV2;
    shield?: ShieldForApplicationDetails;
    error: Error | null;
}
const DeleteShieldFromApplication = ({
    doRemoveShield,
    handleClose,
    application,
    shield,
    error,
}: DeleteShieldProps) => {
    if (!application || !shield) {
        return null;
    }
    return (
        <>
            <ModalHeader onClose={handleClose}>
                <div className="flex items-start">
                    <h3 className="text-xl font-medium text-red" id="modal-headline">
                        {t('application.removeShield')}
                    </h3>
                </div>
            </ModalHeader>
            <ModalBody>
                <div className="flex flex-wrap items-start">
                    {error ? (
                        <div className="flex flex-wrap mb-4">
                            <div className="flex-1">
                                <Alert
                                    type="danger"
                                    title={t('main.unexpectedError')}
                                    description={error?.message || ''}
                                />
                            </div>
                        </div>
                    ) : null}
                    <div className="flex-1">
                        <p
                            dangerouslySetInnerHTML={{
                                __html: t('application.deleteShieldConfirmationMsg', {
                                    applicationName: application?.name,
                                    shieldName: shield?.name,
                                }),
                            }}></p>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex justify-end items-center h-16 w-full">
                    <Button theme="secondary" className="h-full w-1/2" onClick={handleClose} size="lg">
                        {t('main.cancel')}
                    </Button>
                    <Button
                        theme="danger"
                        data-testid="remove-shield-from-application-btn"
                        className="h-full w-1/2 capitalize"
                        onClick={debounce(() => doRemoveShield(), 300)}
                        size="lg">
                        {t('main.remove')}
                    </Button>
                </div>
            </ModalFooter>
        </>
    );
};

export default DeleteBaffleShieldFromApplicationModal;
