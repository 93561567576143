import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { SelectedColumn, CryptoActionType } from '@baffle/graphql/src/models';
import { t } from '@baffle/translate';
import cx from 'classnames';
import { Manager, Reference, Popper } from '@baffle/components/src/poppers/Popover';
import { TwoColTableModule, TwoColTableModuleRow } from '@baffle/components/src/content/RightPanel';
import useEscapeKeyPress from '@baffle/utilities/src/useEscapePress';
import setKeyDisplay from '@baffle/utilities/src/setKeyDisplay';
import LockOpenOutlined from '@material-ui/icons/LockOpenOutlined';
import Lock from '@material-ui/icons/Lock';
interface ColumnPillsProps {
    loading?: boolean;
    selectedColumns: SelectedColumn[];
    type: CryptoActionType;
    onPathSelect: (c: SelectedColumn) => void;
}
const ColumnPills = ({ selectedColumns, onPathSelect, type, loading = false }: ColumnPillsProps) => {
    const initialSelectedPill: Partial<SelectedColumn> = {};
    const [selectedPill, setPill] = useState(initialSelectedPill);

    useEscapeKeyPress(() => setPill({}));

    return (
        <>
            <div className="flex w-full flex-wrap bg-white border-solid border border-gray-300">
                <div className="flex w-full flex-grow flex-wrap py-4 px-3 h-24 overflow-y-scroll">
                    {selectedColumns.map((c: SelectedColumn, i: number) => {
                        const isSelected = selectedPill.path === c.path;
                        const rows: TwoColTableModuleRow[] = [
                            {
                                cols: [
                                    t('encryption.path'),
                                    <button
                                        data-testid={`${c.path?.replace('/', '-')}-selected-column-pill-path`}
                                        onClick={() => onPathSelect(c)}
                                        className="text-blue">
                                        {c.path}
                                    </button>,
                                ],
                            },
                            {
                                cols: [t('encryption.dataType'), c.dataType],
                            },
                            {
                                cols: [t('encryption.encMode'), c.selections.encryptionMode?.name || 'n/a'],
                            },
                            {
                                cols: [t('encryption.maskMode'), c.selections.maskingMode?.name || 'n/a'],
                            },
                            {
                                cols: [t('encryption.keyId'), setKeyDisplay(c.keyID)],
                            },
                        ];
                        return (
                            <Manager key={`${i}-selected-column`}>
                                <Reference>
                                    {({ ref }) => (
                                        <button
                                            ref={ref}
                                            onClick={() => {
                                                if (isSelected) {
                                                    setPill({});
                                                } else {
                                                    setPill(c);
                                                }
                                            }}
                                            data-testid={`${c.path?.replace(/\//g, '-')}-selected-column-pill`}
                                            id={`${c.path?.replace(/\//g, '-')}-selected-column-pill`}
                                            className={cx(
                                                'selected-column-pill text-xs h-8 px-2 border-solid border border-black front-thin mr-3 mb-3 flex items-center',
                                                {
                                                    'border-blue': isSelected,
                                                    'text-blue': isSelected,
                                                    'bg-blue-100': isSelected,
                                                }
                                            )}>
                                            {type === 'encryption' ? (
                                                <LockOpenOutlined className="w-4 h-4" />
                                            ) : (
                                                <Lock className="w-4 h-4" />
                                            )}
                                            <span className="ml-2">{c.name}</span>
                                        </button>
                                    )}
                                </Reference>
                                {ReactDOM.createPortal(
                                    <Popper placement="bottom" eventsEnabled={false}>
                                        {({ ref, style, placement, arrowProps }) => {
                                            return (
                                                <div
                                                    ref={ref}
                                                    style={Object.assign({}, style, {
                                                        top: '15px',
                                                        left: '-18px',
                                                    })}
                                                    data-testid={`${i}-column-pill-popover`}
                                                    className={cx(
                                                        'bg-white border-solid border border-gray-300 shadow p-2 rounded baffle-popover z-10',
                                                        {
                                                            hidden: !isSelected,
                                                        }
                                                    )}
                                                    data-placement={'bottom'}>
                                                    <div>
                                                        <p className="font-bold mb-4 ">
                                                            {t('encryption.columnDetails')}
                                                        </p>
                                                        <TwoColTableModule rows={rows} />
                                                    </div>
                                                    <div
                                                        ref={arrowProps.ref}
                                                        style={Object.assign({}, arrowProps.style, {
                                                            top: '-2px',
                                                        })}
                                                        data-placement={'bottom'}
                                                        className="popover-arrow"
                                                    />
                                                </div>
                                            );
                                        }}
                                    </Popper>,
                                    document.getElementById('root') as HTMLElement
                                )}
                            </Manager>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default ColumnPills;
