import React, { useState, useEffect, useMemo } from 'react';
import '../../Encryption.scss';
import { RbacUsrGrp } from '@baffle/graphql/src/models';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import { t } from '@baffle/translate';
import Alert from '@baffle/components/src/alerts/Alert';
import Button from '@baffle/components/src/buttons/Button';
import { DataFormatStore } from '@baffle/manager/src/stores';
import dataProtectionClient from '@baffle/api-client/src/dataProtectionClient';
import useAwait from '@baffle/api-client/src/useAwait';
import createRbacUsrGrpInputScheme from '../modeInputSchemes/createRbacUsrGrpInputScheme';
import { useParams } from 'react-router';
import TextInput from '@baffle/components/src/forms/TextInput';
import TextArea from '@baffle/components/src/forms/TextArea';
import { Checkbox } from 'carbon-components-react';
import { ToastStore } from '../../../../stores/ToastStore';
import { Observer } from 'mobx-react-lite';
import usePageHeight from '@baffle/utilities/src/usePageHeight';
import EditIcon from '@baffle/components/src/icons/EditIcon';
import useEscapeKeyPress from '@baffle/utilities/src/useEscapePress';
import useMouseClick from '@baffle/utilities/src/useMouseClick';
import RbacUsrGrpUsersHelp from './RbacUsrGrpUsersHelp';

const AddRbacUsrGrp = () => {
    return (
        <Observer>
            {() => {
                const { id: appId } = useParams() as { id: string };
                const [editMode, setEditMode] = useState(Boolean(false));
                const { run, error, reset } = useAwait();
                const [validationError, setValidationError] = useState(0);
                const existingUsrGrp = DataFormatStore.selectedRbacUsrGrp;
                const rbacUsrGrpInputSchemes = useMemo(
                    () => createRbacUsrGrpInputScheme(DataFormatStore.rbacUsrGrps, existingUsrGrp?.id),
                    [DataFormatStore.rbacUsrGrps, existingUsrGrp]
                );
                const [globalFlag, setGlobalFlag] = useState(
                    Boolean(existingUsrGrp) && existingUsrGrp?.global ? 'GLOBAL' : 'NON_GLOBAL'
                );
                const [showHelp, setShowHelp] = useState(false);
                useEscapeKeyPress(() => {
                    setShowHelp(false);
                });
                useMouseClick(() => {
                    setShowHelp(false);
                });
                const onSuccess = (action: string) => {
                    ToastStore.push({
                        type: 'success',
                        title: t('encryption.modeLibrary.rbac.rbacUsrGrp.rbacUsrGrpAddEditSuccess', {
                            action,
                        }),
                    });
                };
                //@ts-ignore
                const { inputState, inputErrors, inputActions } = useInputState(rbacUsrGrpInputSchemes[globalFlag]);
                const isValid = inputActions.validateAll(true);
                const handleSave = () => {
                    let payload: RbacUsrGrp = rbacUsrGrpInputSchemes[globalFlag].keys.reduce((acc: any, c: any) => {
                        //This creates a new object mapping the keys from the input scheme
                        //to the key/values from the inputState. And send only those values which are present
                        if (Boolean(inputState[c])) {
                            switch (c) {
                                case 'global':
                                    acc[c] = inputState[c];
                                    break;
                                case 'users':
                                case 'subnets':
                                    acc[c] = (inputState[c] as string).split(',').map(str => str.trim());
                                    break;
                                default:
                                    acc[c] = (inputState[c] as string).trim();
                                    break;
                            }
                        }
                        return acc;
                    }, {});
                    payload.appId = appId;
                    if (existingUsrGrp) {
                        payload.id = existingUsrGrp?.id;
                    }
                    // If global is true, then set users to null
                    if (payload.global) {
                        payload.users = null;
                    }
                    run(
                        (Boolean(existingUsrGrp)
                            ? dataProtectionClient.updateRbacUsrGrp(existingUsrGrp?.id as string, payload)
                            : dataProtectionClient.createRbacUsrGrp(payload)
                        ).then(() => {
                            onSuccess(existingUsrGrp ? 'updated' : 'added');
                            DataFormatStore.listAll(appId as string);
                            DataFormatStore.resetModeBuilder();
                            DataFormatStore.setShowRbacUsrGrpView(true);
                            inputActions.reset();
                            reset();
                        })
                    );
                };

                const handleClose = () => {
                    DataFormatStore.resetModeBuilder();
                    DataFormatStore.setShowRbacUsrGrpView(true);
                    inputActions.reset();
                    reset();
                };

                const handleEdit = () => {
                    setEditMode(false);
                };

                //Sync state from props
                useEffect(() => {
                    inputActions.reset();
                    setValidationError(0);
                    reset();
                    if (existingUsrGrp) {
                        setEditMode(true);
                        const { name, description, global, users, subnets } = existingUsrGrp;
                        inputActions.setFields({
                            name,
                            description,
                            global: global === null || global === undefined ? false : global,
                            users: Boolean(users) ? users?.join(',') : '',
                            subnets: Boolean(subnets) ? subnets?.join(',') : '',
                        });
                        if (global) {
                            setGlobalFlag('GLOBAL');
                        }
                    } else {
                        setEditMode(false);
                    }
                }, [existingUsrGrp]);

                useEffect(() => {
                    let validationErrorCount = 0;
                    Object.keys(inputErrors).forEach(key => {
                        if (inputErrors[key] != null) {
                            validationErrorCount += 1;
                        }
                    });
                    setValidationError(validationErrorCount);
                    setShowHelp(false);
                }, [inputErrors]);

                // When component is unmounted, reset the flag
                useEffect(() => {
                    return () => {
                        DataFormatStore.setShowRbacUsrGrpCreate(false);
                    };
                }, []);

                useEffect(() => {
                    if (globalFlag === 'GLOBAL') {
                        inputActions.clearError('users');
                    }
                }, [globalFlag]);
                const pageHeight = usePageHeight() - 64;
                return (
                    <div className="flex-grow bg-white">
                        <div className="bg-gray-200 px-4 h-8 border-solid border-t-2 border-b-2 border-gray-300">
                            <div className="relative flex items-center h-full uppercase w-112 font-bold text-xs">
                                {DataFormatStore.selectedRbacUsrGrp ? (
                                    <div className="flex-1 flex justify-between items-center">
                                        <span>{t('encryption.modeLibrary.rbac.userGroups')}</span>
                                        <button
                                            aria-label={t('main.edit')}
                                            onClick={handleEdit}
                                            data-testid="rbac-usr-grp-edit-btn">
                                            <EditIcon />
                                        </button>
                                    </div>
                                ) : (
                                    <span>{t('encryption.modeLibrary.rbac.userGroups')}</span>
                                )}
                            </div>
                        </div>
                        <div className="overflow-y-auto" style={{ height: pageHeight }}>
                            <div className="w-116 mt-4 pl-4 mb-12">
                                {error ? (
                                    <div className="flex flex-wrap">
                                        <div className="flex-1">
                                            <Alert
                                                type="danger"
                                                title={t('encryption.modeLibrary.rbac.rbacUsrGrp.rbacUsrGrpError')}
                                                description={error.message ?? ''}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                                {validationError ? (
                                    <div className="flex flex-wrap">
                                        <div className="flex-1">
                                            <Alert
                                                type="danger"
                                                title={t('keystore.inputError', { count: validationError })}
                                                data-testid="alert-field-validation-error"
                                            />
                                        </div>
                                    </div>
                                ) : null}

                                <div>
                                    <div className="flex flex-wrap mb-6 items-end">
                                        <div className="flex-1 name-input-container">
                                            <TextInput
                                                id="usr-grp-name-input"
                                                name="usr-grp-name-input"
                                                data-testid="usr-grp-name-input"
                                                labelText={t('encryption.modeLibrary.rbac.rbacUsrGrp.usrGrpNameLabel')}
                                                placeholder={t(
                                                    'encryption.modeLibrary.rbac.rbacUsrGrp.usrGrpNamePlaceholder'
                                                )}
                                                invalid={Boolean(inputErrors.name)}
                                                invalidText={inputErrors.name}
                                                value={(inputState.name as string) || ''}
                                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    inputActions.validateField({
                                                        field: 'name',
                                                        skipNull: true,
                                                    });
                                                }}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    let val = e.target.value || '';
                                                    inputActions.setField('name', val);
                                                }}
                                                size={30}
                                                disabled={editMode}
                                            />
                                            <span className="input-char-counter right-0">
                                                {(inputState.name as string)?.length ?? 0} / 30
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap mb-6">
                                        <div className="flex-1 relative">
                                            <TextArea
                                                id="description-input"
                                                name="description-input"
                                                data-testid="description-input"
                                                labelText={t('encryption.modeLibrary.rbac.rbacUsrGrp.descriptionLabel')}
                                                placeholder={t(
                                                    'encryption.modeLibrary.rbac.rbacUsrGrp.descriptionPlaceholder'
                                                )}
                                                value={(inputState.description as string) || ''}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    let val = e.target.value || '';
                                                    if (val.length > 100) {
                                                        val = val.slice(0, 100);
                                                    }
                                                    inputActions.setField('description', val);
                                                }}
                                                size={100}
                                                disabled={editMode}
                                            />
                                            <span className="input-char-counter right-0">
                                                {(inputState.description as string)?.length ?? 0}/ 100
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap mb-6">
                                        <div className="flex-1 relative">
                                            <Checkbox
                                                id="global-enable-checkbox"
                                                labelText={t('encryption.modeLibrary.rbac.rbacUsrGrp.globalLabel')}
                                                checked={inputState.global ? true : false}
                                                onChange={(
                                                    event: any,
                                                    { checked, id }: { checked: boolean; id: any }
                                                ) => {
                                                    setGlobalFlag(checked ? 'GLOBAL' : 'NON_GLOBAL');
                                                    inputActions.setField('global', checked);
                                                }}
                                                className="text-xs font-medium text-gray-600"
                                                disabled={editMode}
                                            />
                                        </div>
                                    </div>
                                    {!inputState.global ? (
                                        <div className="flex flex-wrap mb-6">
                                            <div className="flex-1 relative">
                                                <TextArea
                                                    id="users-input"
                                                    name="users-input"
                                                    data-testid="users-input"
                                                    labelText={t('encryption.modeLibrary.rbac.rbacUsrGrp.usersLabel')}
                                                    placeholder={t(
                                                        'encryption.modeLibrary.rbac.rbacUsrGrp.usersPlaceholder'
                                                    )}
                                                    invalid={Boolean(inputErrors.users)}
                                                    invalidText={inputErrors.users}
                                                    value={(inputState.users as string) || ''}
                                                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        inputActions.validateField({
                                                            field: 'users',
                                                            skipNull: true,
                                                        });
                                                    }}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        const val = e.target.value || '';
                                                        inputActions.setField('users', val);
                                                    }}
                                                    disabled={editMode}
                                                />
                                                <RbacUsrGrpUsersHelp
                                                    onRefClick={() => {
                                                        setShowHelp(!showHelp);
                                                    }}
                                                    showHelp={showHelp}
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                    <div className="flex flex-wrap mb-6">
                                        <div className="flex-1 relative">
                                            <TextArea
                                                id="subnets-input"
                                                name="subnets-input"
                                                data-testid="subnets-input"
                                                labelText={t(
                                                    'encryption.modeLibrary.rbac.rbacUsrGrp.allowedSubnetsLabel'
                                                )}
                                                placeholder={t(
                                                    'encryption.modeLibrary.rbac.rbacUsrGrp.allowedSubnetsPlaceholder'
                                                )}
                                                invalid={Boolean(inputErrors.subnets)}
                                                invalidText={inputErrors.subnets}
                                                value={(inputState.subnets as string) || ''}
                                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    inputActions.validateField({
                                                        field: 'subnets',
                                                        skipNull: true,
                                                    });
                                                }}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    const val = e.target.value || '';
                                                    inputActions.setField('subnets', val);
                                                }}
                                                disabled={editMode}
                                            />
                                        </div>
                                    </div>
                                    {editMode ? (
                                        <div className="flex w-full justify-end h-8">
                                            <Button
                                                theme="primary"
                                                className="py-2 w-24 uppercase font-semibold text-xs"
                                                onClick={handleEdit}
                                                data-testid="rbac-usr-grp-edit-btn-form">
                                                {t('main.edit')}
                                            </Button>
                                        </div>
                                    ) : (
                                        <div className="flex w-full justify-end h-8">
                                            <Button
                                                theme="secondary"
                                                className="py-2 w-24 font-semibold text-xs uppercase"
                                                onClick={() => {
                                                    handleClose();
                                                }}>
                                                {t('main.cancel')}
                                            </Button>
                                            <Button
                                                className="py-2 w-24 font-semibold ml-2 text-xs uppercase"
                                                id="save-rbac-usr-grp-btn"
                                                theme={!isValid ? 'disabled' : 'primary'}
                                                onClick={handleSave}>
                                                {t('main.save')}
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }}
        </Observer>
    );
};

export default AddRbacUsrGrp;
