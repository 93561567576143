import rbacPolicyClient from '@baffle/api-client/src/rbacPolicyClient';
import { RbacUsrGrpV2 } from '@baffle/graphql/src/models';
import { observable, action, makeObservable } from 'mobx';
import { format } from '@baffle/utilities/src/date';

class UserGroupStore {
    constructor() {
        makeObservable(this, {
            setRbacUserGroups: action,
            setRbacUserGroup: action,
        });
    }

    rbacUserGroups = observable<RbacUsrGrpV2>([]);
    rbacUserGroup: RbacUsrGrpV2 | null = null;
    selectedRbacUserGroup: RbacUsrGrpV2 | null | undefined = null;

    setRbacUserGroups(rbacUserGroups: RbacUsrGrpV2[]) {
        this.rbacUserGroups.replace(rbacUserGroups);
    }

    setRbacUserGroup(rbacUserGroup: RbacUsrGrpV2 | null) {
        this.rbacUserGroup = rbacUserGroup;
    }

    async createRbacUserGroup(userGroup: RbacUsrGrpV2) {
        try {
            await rbacPolicyClient.createRbacUserGroup(userGroup);
            await this.getRbacUserGroups();
        } catch (error) {
            throw error;
        }
    }

    async getRbacUserGroups() {
        try {
            const res = await rbacPolicyClient.readRbacUserGroups();
            this.setRbacUserGroups(res.data);
        } catch (error) {
            throw error;
        }
    }

    async getRbacUserGroup(groupID: string) {
        try {
            const userGroup = await rbacPolicyClient.readRbacUserGroup(groupID);
            this.setRbacUserGroup(userGroup);
        } catch (error) {
            throw error;
        }
    }

    async deleteRbacUserGroup(id: string) {
        try {
            await rbacPolicyClient.deleteRbacUserGroup(id);
            await this.getRbacUserGroups();
        } catch (error) {
            throw error;
        }
    }

    async updateRbacUserGroup(id: string, userGroup: RbacUsrGrpV2) {
        try {
            await rbacPolicyClient.updateRbacUserGroup(id, userGroup);
            await this.getRbacUserGroups();
            await this.getRbacUserGroup(id);
        } catch (error) {
            throw error;
        }
    }

    getUserGroupOverview(userGroup: RbacUsrGrpV2) {
        return {
            id: userGroup.id,
            name: userGroup.name,
            subnets: userGroup.subnets,
            global: userGroup.global ? 'Yes' : 'No',
            users: userGroup.users,
            createdBy: userGroup.audit.createdBy,
            createdDate: format(userGroup.audit.createdDate, 'MM-dd-yyyy'),
        };
    }

    get(id: string) {
        return this.rbacUserGroups.find(m => m.id === id);
    }
}

export default UserGroupStore;
