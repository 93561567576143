import { RoleInfo } from '@baffle/graphql/src/models';
import client from '.';

export async function addRole(payload: RoleInfo) {
    return client('/api/v2/role', { body: payload, method: 'POST' });
}

export async function getRoles() {
    return client('/api/v2/roles');
}

export async function getRole(id: string) {
    return client(`/api/v2/role/${id}`);
}

export function deleteRole(id: string) {
    return client(`/api/v2/role/${id}`, { method: 'DELETE' });
}

export async function updateRole(payload: RoleInfo) {
    return client('/api/v2/role', { body: payload, method: 'PUT' });
}

export default {
    addRole,
    getRoles,
    getRole,
    deleteRole,
    updateRole,
};
