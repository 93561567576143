import React, { useEffect, useState } from 'react';
import { Observer } from 'mobx-react-lite';
import Content from '@baffle/components/src/content/Content';
import Header from '@baffle/components/src/nav/Header';
import { t } from '@baffle/translate';
import useAwait from '@baffle/api-client/src/useAwait';
import { TenantStore } from '@baffle/manager/src/stores';
import { TenantInfo } from '@baffle/graphql/src/models';
import DataGrid from '@baffle/components/src/grid/data-grid';
import { Modal } from 'carbon-components-react';
import AddTenantModal from './AddTenantModal';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';
import { useManageModals } from '@baffle/components/src/modal/Modal';
import RenameTenant from './RenameTenant';

const Tenants = () => {
    const { status, run, error } = useAwait();
    const [update, setUpdate] = useState(true);

    useEffect(() => {
        if (update === true) {
            run(TenantStore.getTenants());
            setUpdate(false);
        }
    }, [update]);

    useEffect(() => {
        if (error) {
            NotificationStore.push({
                'data-testid': 'tenant-add-failure',
                kind: 'error',
                title: t('tenants.addTenantError'),
                description: error.message ?? t('main.unexpectedError'),
            });
        }
    }, [error]);

    const onSyncButtonClick = () => {
        setUpdate(true);
    };

    const headers = [
        {
            key: 'header1',
            header: t('main.name'),
        },
        {
            key: 'header2',
            header: t('tenants.keystore'),
        },
        {
            key: 'header3',
            header: t('tenants.cmk'),
        },
        {
            key: 'header4',
            header: t('tenants.dek'),
        },
        {
            key: 'header5',
            header: t('tenants.associatedRoles'),
        },
    ];

    return (
        <Observer>
            {() => {
                const tenants: TenantInfo[] = TenantStore.tenantList;
                interface TenantModals {
                    addTenant: boolean;
                    deleteTenant: boolean;
                    renameTenant: boolean;
                }

                const initialModalState = {
                    addTenant: false,
                    deleteTenant: false,
                    renameTenant: false,
                };

                const [modals, setModals] = useManageModals<TenantModals>(initialModalState);
                const rows =
                    status === 'loading' || status === 'idle'
                        ? null //add  skeleton row on initial load
                        : tenants.map((tenant: TenantInfo, i: number) => {
                              return {
                                  selectedId: tenant?.id,
                                  id: tenant.id,
                                  header1: tenant.name,
                                  header2: tenant.keystore,
                                  header3: tenant.cmk,
                                  header4: tenant.dek,
                                  header5: tenant.roles,
                                  link: '/tenant/' + tenant.id,
                                  overFlowMenu: [
                                      {
                                          name: t('main.rename'),
                                          onClick: (id: string) => {
                                              TenantStore.setTenant(TenantStore.getById(id) as TenantInfo);
                                              setModals({ renameTenant: true });
                                          },
                                      },
                                      {
                                          name: t('main.delete'),
                                          onClick: (id: string) => {
                                              TenantStore.setTenant(TenantStore.getById(id) as TenantInfo);
                                              setModals({ deleteTenant: true });
                                          },
                                      },
                                  ],
                              };
                          });

                const onAdd = () => {
                    TenantStore.setTenant(null);
                    setModals({ addTenant: true });
                };
                const onDelete = async () => {
                    setModals({ deleteTenant: false });
                    run(
                        TenantStore.deleteTenant(TenantStore.tenant?.id as string).then(() => {
                            if (status === 'success') {
                                NotificationStore.push({
                                    'data-testid': 'tenant-del-success',
                                    kind: 'success',
                                    title: t('tenants.deleteTenantSuccessMsg', {
                                        name: TenantStore.tenant?.name,
                                    }),
                                });
                            }
                            TenantStore.setTenant(null);
                        })
                    );
                };

                const onAddTenant = async (tenantInfo: TenantInfo) => {
                    setModals({ addTenant: false });
                    run(
                        TenantStore.addTenant(tenantInfo).then(() => {
                            if (status === 'success') {
                                NotificationStore.push({
                                    'data-testid': 'tenant-add-success',
                                    kind: 'success',
                                    title: t('tenants.addTenantSuccessMsg', {
                                        name: tenantInfo.name,
                                    }),
                                });
                            }
                        })
                    );
                };
                const onRename = async (tenantInfo: TenantInfo) => {
                    setModals({ renameTenant: false });
                    run(
                        TenantStore.updateTenant(tenantInfo).then(() => {
                            if (status === 'success') {
                                NotificationStore.push({
                                    'data-testid': 'tenant-update-success',
                                    kind: 'success',
                                    title: t('tenants.updateTenantSuccessMsg', {
                                        name: tenantInfo.name,
                                    }),
                                });
                            }
                        })
                    );
                };
                return (
                    <>
                        <Content>
                            <Header>
                                <div className="flex-1 my-5 px-10 flex justify-between">
                                    <div className="flex-1 flex items-center">
                                        <h1 className="text-heading-01 text-gray-100">{t('main.tenants')}</h1>
                                    </div>
                                </div>
                            </Header>
                            {modals.renameTenant && (
                                <RenameTenant
                                    open={modals.renameTenant}
                                    onClose={() => setModals({ renameTenant: false })}
                                    onSuccess={(tenant: TenantInfo) => {
                                        onRename(tenant);
                                    }}
                                />
                            )}
                            <Modal
                                open={modals.deleteTenant}
                                id="tenant-delete-modal"
                                modalHeading={t('tenants.deleteConfirmationTitle', {
                                    name: TenantStore.tenant?.name,
                                })}
                                danger={true}
                                onRequestClose={() => {
                                    setModals({ deleteTenant: false });
                                }}
                                primaryButtonText={t('main.delete')}
                                secondaryButtonText={t('main.cancel')}
                                size="sm"
                                onRequestSubmit={onDelete}>
                                <p>{t('tenants.deleteConfirmationMsg', { name: TenantStore.tenant?.name })}</p>
                            </Modal>
                            {modals.addTenant && (
                                <AddTenantModal
                                    open={modals.addTenant}
                                    onClose={() => {
                                        setModals({ addTenant: false });
                                    }}
                                    onSuccess={onAddTenant}
                                />
                            )}
                            <DataGrid
                                idPrefix="tenants"
                                rows={rows}
                                headers={headers}
                                onAdd={onAdd}
                                showSearch={true}
                                onSync={onSyncButtonClick}
                                addButtonCaption={t('main.create')}></DataGrid>
                        </Content>
                    </>
                );
            }}
        </Observer>
    );
};

export default Tenants;
