import React, { useEffect, useMemo } from 'react';
import { Modal, TextInput } from 'carbon-components-react';
import { t } from '@baffle/translate';
import { Observer } from 'mobx-react-lite';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import useAwait from '@baffle/api-client/src/useAwait';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';
import { DatabaseProxyRenameScheme } from './CreateDataBaseProxyInputScheme';
import { DatabaseProxiesStore } from '@baffle/manager/src/stores';
import databaseProxiesClient from '@baffle/api-client/src/databaseProxiesClient';

interface RenameDataBaseProxyModalProps {
    open: boolean;
    onClose: () => void;
    onSuccess?: (name: string) => void;
}

const RenameDataBaseProxyModal = ({ open, onClose, onSuccess }: RenameDataBaseProxyModalProps) => {
    const { run, error } = useAwait();

    useEffect(() => {
        if (error) {
            NotificationStore.push({
                'data-testid': 'database-proxy-rename-failure',
                kind: 'error',
                title: t('databaseProxies.renameDataBaseProxyError'),
                description: error.message ?? '',
            });
        }
    }, [error]);

    const RenameDataBaseProxy = (name: string) => {
        run(
            databaseProxiesClient
                .renameDatabaseProxy(DatabaseProxiesStore.databaseProxy?.id as string, { newName: name })
                .then(() => {
                    Promise.all([
                        DatabaseProxiesStore.readDatabaseProxy(DatabaseProxiesStore.databaseProxy?.id as string),
                        DatabaseProxiesStore.readDatabaseProxies(),
                    ]).then(() => {
                        if (onSuccess) {
                            onSuccess(name);
                        }
                        onClose();
                    });
                })
        );
    };

    return (
        <Observer>
            {() => {
                const databaseProxyInputSchemes = useMemo(
                    () =>
                        DatabaseProxyRenameScheme(
                            DatabaseProxiesStore.databaseProxies,
                            DatabaseProxiesStore.databaseProxy?.id
                        ),
                    [DatabaseProxiesStore.databaseProxies, DatabaseProxiesStore.databaseProxy]
                );
                //@ts-ignore
                const { inputState, inputErrors, inputActions } = useInputState(databaseProxyInputSchemes);
                const isValid = inputActions.validateAll(true);

                return (
                    <Modal
                        id="database-proxy-rename-modal"
                        className="modal-container-white"
                        open={open}
                        modalHeading={t('databaseProxies.renameDatabaseProxyTitle')}
                        onRequestClose={onClose}
                        primaryButtonText={t('main.rename')}
                        secondaryButtonText={t('main.cancel')}
                        size="sm"
                        onRequestSubmit={() => RenameDataBaseProxy(inputState.name as string)}
                        primaryButtonDisabled={!isValid}>
                        <TextInput
                            id="database-proxy-rename-input"
                            className="text-input-gray10"
                            name="database-proxy-rename-input"
                            data-testid="database-proxy-rename-input"
                            labelText={t('main.name')}
                            placeholder={t('databaseProxies.dataBaseProxyNamePlaceholder')}
                            invalid={Boolean(inputErrors.name)}
                            invalidText={inputErrors.name}
                            value={(inputState.name as string) || ''}
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                inputActions.validateField({
                                    field: 'name',
                                    skipNull: true,
                                });
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                inputActions.setField('name', val);
                            }}
                        />
                    </Modal>
                );
            }}
        </Observer>
    );
};

export default RenameDataBaseProxyModal;
