import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { t } from '@baffle/translate';
import cx from 'classnames';
import DropdownSelect, {
    getDefaultMenuProps,
    getDefaultToggleButtonProps,
} from '@baffle/components/src/dropdown/Dropdown';
import Button from '@baffle/components/src/buttons/Button';
import TextInput from '@baffle/components/src/forms/TextInput';
import { InputActions, InputState } from '@baffle/utilities/src/inputHooks';
import TreeUpArrowIcon from '@baffle/components/src/icons/TreeUpArrowIcon';
import TreeDownArrowIcon from '@baffle/components/src/icons/TreeDownArrowIcon';
import TreeDropdownDownIcon from '@baffle/components/src/icons/TreeDropdownDownIcon';
import TreeDropdownUpIcon from '@baffle/components/src/icons/TreeDropdownUpIcon';
import { DataFormatStore } from '@baffle/manager/src/stores';
import { RbacPolicy, RbacPolicyRule, DataProtectionItem } from '@baffle/graphql/src/models';
import { Observer } from 'mobx-react-lite';
import MinusIcon from '@baffle/components/src/icons/MinusIcon';

interface RbacPolicyRuleProps {
    inputState: InputState;
    inputActions: InputActions;
    policyRules: Array<RbacPolicyRule>;
    rule: RbacPolicyRule;
    index: number;
    existingPolicy: RbacPolicy | null | undefined;
    editMode: boolean;
    permissionItems: any[];
}

const RbacPolicyRules = ({
    inputState,
    inputActions,
    policyRules,
    rule,
    index,
    editMode,
    existingPolicy,
    permissionItems,
}: RbacPolicyRuleProps) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(true);
    const [isHover, setIsHover] = useState<boolean>(false);
    useEffect(() => {
        setIsExpanded(Boolean(!existingPolicy) || !editMode);
    }, [existingPolicy]);
    return (
        <Observer>
            {() => {
                const handlePolicyRule = (ruleKey: string, value: any, ruleIndex: number) => {
                    const rule = policyRules[ruleIndex];
                    const rules = policyRules;
                    switch (ruleKey) {
                        case 'maskMode':
                            rule[ruleKey] = DataFormatStore.maskingModes.find(
                                mode => mode.id === value
                            ) as DataProtectionItem;
                            break;
                        case 'incrementOrder':
                            value < policyRules.length ? (rule['order'] += 1) : null;
                            break;
                        case 'decrementOrder':
                            value > 1 ? (rule['order'] -= 1) : null;
                            break;
                        case 'userGroups':
                            rule[ruleKey] = value.length
                                ? value.map((group: any) => ({
                                      id: group.value,
                                      name: group.label,
                                  }))
                                : [];
                            break;
                        case 'deleteRule':
                            rules.splice(ruleIndex, 1);
                            break;
                        default:
                            //@ts-ignore
                            rule[ruleKey] = value;
                    }
                    inputActions.setField('rules', policyRules);
                };

                const maskModeItems = DataFormatStore.maskingModes.map(mode => ({
                    id: mode.id,
                    label: mode.name,
                    'data-testid': `policy-permission-li-${mode.name}`,
                }));

                const { className: btnClasses, ...rest } = getDefaultToggleButtonProps();
                return (
                    <div className="flex w-106 items-center">
                        <div
                            key={`policy-rule-${index}`}
                            className="flex flex-wrap mb-4 p-4 w-full items-start border border-solid border-gray-300">
                            <div className="flex flex-wrap mb-6 w-106 items-end justify-between">
                                <div className="w-60 name-input-container">
                                    <TextInput
                                        id={`rule-name-input-${index}`}
                                        name={`rule-name-input-${index}`}
                                        data-testid={`rule-name-input-${index}`}
                                        labelText={t('encryption.modeLibrary.rbac.rbacPolicy.ruleName')}
                                        placeholder={t('encryption.modeLibrary.rbac.rbacPolicy.ruleNamePlaceholder')}
                                        value={(rule.name as string) || ''}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const val = e.target.value || '';
                                            handlePolicyRule('name', val, index);
                                        }}
                                        disabled={editMode}
                                    />
                                    <span className="input-char-counter right-0">
                                        {(rule.name as string)?.length ?? 0} / 30
                                    </span>
                                </div>

                                <div className="relative h-8 flex">
                                    <TextInput
                                        name={`rule-order-input-${index}`}
                                        id={`rule-order-input-${index}`}
                                        labelHidden
                                        readOnly
                                        labelText={t('main.chooseOption')}
                                        value={rule.order}
                                        getInputContainerProps={() => {
                                            return {
                                                className: 'w-10 flex',
                                            };
                                        }}
                                        getInputProps={() => {
                                            return {
                                                className:
                                                    'shadow-sm focus:ring-blue focus:border-blue focus:text-gray-700 block w-full text-xs border text-center focus:rounded-none flex-grow focus: outline-none ',
                                            };
                                        }}
                                    />
                                    <Button
                                        data-testid={`rule-order-increment-${index}`}
                                        theme="border-gray"
                                        className="text-xs w-8 justify-center items-center flex"
                                        disabled={editMode}
                                        onClick={() => handlePolicyRule('incrementOrder', rule.order, index)}>
                                        <TreeDropdownUpIcon color={editMode ? '#D5D9E0' : '#000000'} />
                                    </Button>
                                    <Button
                                        data-testid={`rule-order-decrement-${index}`}
                                        theme="border-gray"
                                        className="text-xs w-8 justify-center items-center flex"
                                        disabled={editMode}
                                        onClick={() => handlePolicyRule('decrementOrder', rule.order, index)}>
                                        <TreeDropdownDownIcon color={editMode ? '#D5D9E0' : '#000000'} />
                                    </Button>
                                </div>
                                <div
                                    className="text-sm w-4 h-8 justify-center items-center flex cursor-pointer"
                                    onClick={() => setIsExpanded(!isExpanded)}>
                                    <span>{isExpanded ? <TreeUpArrowIcon /> : <TreeDownArrowIcon />}</span>
                                </div>
                            </div>

                            {isExpanded && (
                                <div className="accordion-body w-full">
                                    <div className="flex flex-wrap w-full mb-6 items-start">
                                        <div className="flex-1 relative">
                                            <label className="mb-2 block text-xxs">
                                                {t('encryption.modeLibrary.rbac.rbacPolicy.userGroupsLabel')}
                                            </label>
                                            {rule.userGroups ? (
                                                <Select
                                                    inputId={`user-groups-dropdown-${index}`}
                                                    value={rule.userGroups.map(group => ({
                                                        value: group.id,
                                                        label: group.name,
                                                    }))}
                                                    isMulti
                                                    maxMenuHeight={98}
                                                    name="policy-user-groups"
                                                    options={DataFormatStore.rbacUsrGrps.map(group => ({
                                                        value: group.id,
                                                        label: group.name,
                                                        'data-testid': `policy-permission-li-${group.name}`,
                                                    }))}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    isClearable={false}
                                                    isSearchable={true}
                                                    placeholder={t('main.chooseOption')}
                                                    isDisabled={editMode}
                                                    onChange={(selected: any) => {
                                                        handlePolicyRule('userGroups', selected, index);
                                                    }}
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap w-full mb-6 items-start">
                                        <div className="flex-1 relative">
                                            <DropdownSelect
                                                id={`policy-rule-permission-${index}`}
                                                placeholder={t('main.chooseOption')}
                                                label={t('encryption.modeLibrary.rbac.rbacPolicy.rulePermission')}
                                                value={
                                                    rule.permission
                                                        ? permissionItems.find(m => m.id === rule.permission)
                                                        : { id: 'chooseOption', label: t('main.chooseOption') }
                                                }
                                                getMenuProps={() => {
                                                    const { className: ulClasses, ...rest } = getDefaultMenuProps();
                                                    return {
                                                        className: `${ulClasses} w-full`,
                                                        ...rest,
                                                    };
                                                }}
                                                handleSelectedItemChange={({ selectedItem }) => {
                                                    handlePolicyRule('permission', selectedItem?.id as string, index);
                                                }}
                                                items={permissionItems}
                                                getToggleButtonProps={() => {
                                                    return {
                                                        disabled: editMode,
                                                        className: cx(btnClasses, {
                                                            disabled: editMode,
                                                        }),
                                                        ...rest,
                                                    };
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {(inputState.rules as Array<RbacPolicyRule>)[index].permission === 'MASK' ? (
                                        <div className="flex flex-wrap w-full mb-6 items-start">
                                            <div className="flex-1 relative">
                                                <DropdownSelect
                                                    id={`rule-masking-mode-${index}`}
                                                    placeholder={t('main.chooseOption')}
                                                    label={t('encryption.modeLibrary.rbac.rbacPolicy.ruleMask')}
                                                    value={
                                                        rule.maskMode
                                                            ? maskModeItems.find(m => m.id === rule.maskMode?.id)
                                                            : { id: 'chooseOption', label: t('main.chooseOption') }
                                                    }
                                                    getMenuProps={() => {
                                                        const { className: ulClasses, ...rest } = getDefaultMenuProps();
                                                        return {
                                                            className: `${ulClasses} w-full`,
                                                            ...rest,
                                                        };
                                                    }}
                                                    handleSelectedItemChange={({ selectedItem }) => {
                                                        handlePolicyRule('maskMode', selectedItem?.id as string, index);
                                                    }}
                                                    items={maskModeItems}
                                                    getToggleButtonProps={() => {
                                                        return {
                                                            disabled: editMode,
                                                            className: cx(btnClasses, {
                                                                disabled: editMode,
                                                            }),
                                                            ...rest,
                                                        };
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            )}
                        </div>
                        <div className="mb-4 ml-2 delete-container">
                            <button
                                className={cx('flex focus:outline-none', {
                                    'cursor-not-allowed': editMode,
                                })}
                                data-testid={`remove-rule-btn-${index}`}
                                disabled={editMode}
                                onMouseEnter={() => setIsHover(true)}
                                onMouseLeave={() => setIsHover(false)}
                                onClick={() => handlePolicyRule('deleteRule', rule.order, index)}>
                                <MinusIcon className="w-4 h-4" color={editMode || !isHover ? '#C1C7CD' : '#0058A1'} />
                            </button>
                        </div>
                    </div>
                );
            }}
        </Observer>
    );
};

export default RbacPolicyRules;
