import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { BaffleIconProps } from '../componentTypes';

const TreeRightArrowIcon = ({
    color = '#000000',
    width = 24,
    height = 24,
    viewBox = '0 0 24 24',
    fontSize = 'small',
}: BaffleIconProps) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} fontSize={fontSize}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Arrows, Diagrams/Arrow">
                    <g id="Group">
                        <path
                            id="Path"
                            d="M10 16L14 12L10 8"
                            stroke="#323232"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </g>
                </g>
            </svg>
        </SvgIcon>
    );
};

export default TreeRightArrowIcon;
