import React, { useEffect, useState } from 'react';
import { Observer } from 'mobx-react-lite';
import Header from '@baffle/components/src/nav/Header';
import Button from '@baffle/components/src/buttons/Button';
import { t } from '@baffle/translate';
import Content from '@baffle/components/src/content/Content';
import { useParams, useHistory } from 'react-router-dom';
import { ColumnMetaData, SelectedColumn, EncryptionSchema } from '@baffle/graphql/src/models';
import '../encryption/Encryption.scss';
import ColumnsTable from '../encryption/column/ColumnsTable';
import ColumnPills from '../encryption/ColumnPills';
import { EncryptionStore, ApplicationStore } from '../../stores';
import useAwait from '@baffle/api-client/src/useAwait';
import { ToastStore } from '../../stores/ToastStore';
import Search from '@baffle/components/src/search/Search';
import usePageHeight from '../../../../utilities/src/usePageHeight';
import DecryptionTreeView from './DecryptionTreeView';
import SkeletonText from '@baffle/components/src/skeleton/SkeletonText';

const Decryption = () => {
    let { id } = useParams() as { id: string | undefined };
    if (!id) {
        return null;
    }
    let history = useHistory();
    const initialState: {
        results: string[];
        query: string;
    } = { results: [], query: '' };
    const initialStateSchema: {
        resultsSchema: string[];
        querySchema: string;
    } = { resultsSchema: [], querySchema: '' };
    const initialStateTable: {
        resultsTable: string[];
        queryTable: string;
    } = { resultsTable: [], queryTable: '' };
    const initialStateView: {
        resultsView: string[];
        queryView: string;
    } = { resultsView: [], queryView: '' };
    const initialStateColumn: {
        resultsColumn: ColumnMetaData[];
        queryColumn: string;
    } = { resultsColumn: [], queryColumn: '' };
    const [filtered, setFiltered] = useState(initialState);
    const [filteredSchemas, setFilteredSchemas] = useState(initialStateSchema);
    const [filteredTables, setFilteredTables] = useState(initialStateTable);
    const [filteredViews, setFilteredViews] = useState(initialStateView);
    const [filteredColumns, setFilteredColumns] = useState(initialStateColumn);
    return (
        <Observer>
            {() => {
                const { run, status, error } = useAwait();
                useEffect(() => {
                    if (error) {
                        ToastStore.push({ type: 'danger', title: t('main.unexpectedError') });
                    }
                }, [error]);

                useEffect(() => {
                    //clear previous databases, table and columns on mount
                    //https://baffle.atlassian.net/browse/BM3-1885
                    EncryptionStore.resetDatabaseTableAndColumns({
                        actionType: 'decryption',
                    });
                    run(
                        Promise.all([
                            EncryptionStore.getSchema(id as string),
                            ApplicationStore.read(id as string, {
                                networkPolicy: 'cache-first',
                            }),
                        ])
                    );
                }, []);

                const schema: EncryptionSchema = (EncryptionStore.schema as EncryptionSchema) ?? {};

                const application = ApplicationStore.get(id as string);

                const selectedDecryptionColumns = EncryptionStore.selectedDecryptionColumns.filter(
                    d => d.application === application?.id
                );
                const fourLevel = application?.fourLevel;
                const databases = EncryptionStore.schemaDatabases('decryption', fourLevel);
                const dbs = filtered.query !== '' ? filtered.results : databases;
                const schemas = EncryptionStore.schemaDatabaseSchemas('decryption');
                const dbSchemas = filteredSchemas.querySchema !== '' ? filteredSchemas.resultsSchema : schemas;
                const databaseTables = EncryptionStore.schemaTables('decryption', fourLevel, 'table');
                const databaseViews = EncryptionStore.schemaTables('decryption', fourLevel, 'view');
                const tables = filteredTables.queryTable !== '' ? filteredTables.resultsTable : databaseTables;
                const views = filteredViews.queryView !== '' ? filteredViews.resultsView : databaseViews;
                const tableColumns: ColumnMetaData[] = application?.fourLevel
                    ? (schema?.columns ?? []).filter(
                          c =>
                              EncryptionStore.selectedDecryptionDatabase === c.dbName &&
                              EncryptionStore.selectedDecryptionSchema === c.database &&
                              EncryptionStore.selectedDecryptionTable === c.table
                      )
                    : (schema?.columns ?? []).filter(
                          c =>
                              EncryptionStore.selectedDecryptionDatabase === c.database &&
                              EncryptionStore.selectedDecryptionTable === c.table
                      );
                const columns = filteredColumns.queryColumn !== '' ? filteredColumns.resultsColumn : tableColumns || [];
                const pageHeight = usePageHeight();
                const navHeight = pageHeight - 86; //86 is height of buttons + topnav;

                const filterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                    const query = (e.target.value || '').toLowerCase();
                    if (!query) {
                        setFilteredTables({ queryTable: query, resultsTable: [] });
                        setFilteredViews({ queryView: query, resultsView: [] });
                        setFilteredSchemas({ querySchema: query, resultsSchema: [] });
                        setFiltered({ query: query, results: [] });
                    }
                    // Search for table(s) within database if database is already selected
                    else if (
                        (EncryptionStore.selectedDecryptionDatabase && !fourLevel) ||
                        EncryptionStore.selectedDecryptionSchema
                    ) {
                        const resultsTable = databaseTables.filter(table => table.toLowerCase().includes(query));
                        setFilteredTables({ queryTable: query, resultsTable });
                        const resultViews = databaseViews.filter(view => view.toLowerCase().includes(query));
                        setFilteredViews({ queryView: query, resultsView: resultViews });
                    }
                    // Search for schema(s) within database
                    else if (EncryptionStore.selectedDecryptionDatabase && fourLevel) {
                        const resultsSchema = schemas.filter(s => s.toLowerCase().includes(query));
                        setFilteredSchemas({ querySchema: query, resultsSchema });
                    }
                    // Search for database(s)
                    else {
                        const results = databases.filter(d => d.toLowerCase().includes(query));
                        setFiltered({ results, query });
                    }
                };
                const filterChangeColumns = (e: React.ChangeEvent<HTMLInputElement>) => {
                    const queryColumn = e.target.value || '';
                    const resultsColumn =
                        columns.filter((d: ColumnMetaData) =>
                            d.name.toLowerCase().includes(queryColumn.toLowerCase())
                        ) || [];

                    setFilteredColumns({ resultsColumn, queryColumn });
                };
                useEffect(() => {
                    setFilteredColumns({ resultsColumn: [], queryColumn: '' });
                }, [EncryptionStore.selectedDecryptionTable]);

                return (
                    <>
                        <Content className="flex flex-col">
                            <Header>
                                <div className="flex-1 px-4 flex items-center">
                                    <h1 className="text-xl">
                                        <span className="text-gray-100">{t('encryption.schemaBuilder')}:</span>{' '}
                                        {application?.appName ?? ''}
                                    </h1>
                                </div>
                            </Header>
                            <div className="encryption flex flex-1 items-stretch">
                                <div
                                    style={{ height: navHeight }}
                                    className="flex flex-col flex-shrink-0 overflow-y-auto bg-white w-76">
                                    <header
                                        className="relative z-10 flex-shrink-0 flex shadow h-8 text-xs"
                                        role="banner">
                                        <div className="flex-1 px-4 flex justify-between bg-gray-200 items-center border-solid border border-gray-300">
                                            <span className="text-black uppercase font-bold">
                                                {t('encryption.dbGrid.headerTreeMenu')}
                                            </span>
                                        </div>
                                    </header>

                                    <Search
                                        className="encryption-grid-filter"
                                        placeholder={t('encryption.dbGrid.ariaSearch')}
                                        onChange={filterChange}
                                        name="encryption-dbgrid-filter"
                                        id="encryption-dbgrid-filter"
                                        autoComplete="none"
                                    />

                                    <DecryptionTreeView
                                        id={id as string}
                                        dbs={dbs}
                                        tables={tables}
                                        views={views}
                                        dbSchemas={dbSchemas}
                                        cryptoAction="decryption"
                                        fourLevel={application?.fourLevel}
                                        handleFilterChange={(obj: String) => {
                                            if (obj === 'table') {
                                                setFilteredTables({ queryTable: '', resultsTable: [] });
                                                setFilteredViews({ queryView: '', resultsView: [] });
                                            } else {
                                                setFilteredSchemas({ querySchema: '', resultsSchema: [] });
                                            }
                                        }}
                                    />
                                    <div className="flex border-t-2 border-r-2 border-solid border-gray-300 items-center h-18">
                                        <Button
                                            theme="secondary"
                                            className="w-36 h-10 my-4 ml-4 mr-1 text-sm"
                                            onClick={() => {
                                                EncryptionStore.resetSelected({
                                                    actionType: 'decryption',
                                                });
                                                history.replace('/applications');
                                            }}>
                                            {t('main.cancel')}
                                        </Button>
                                        <Button
                                            className="w-36 h-10 my-4 mr-4 ml-2 text-sm"
                                            id="do-decrypt-next-btn"
                                            theme={selectedDecryptionColumns.length === 0 ? 'disabled' : 'primary'}
                                            onClick={() => {
                                                history.push(`/application/decryption/${id}/confirm`);
                                            }}>
                                            {t('encryption.review')}
                                        </Button>
                                    </div>
                                </div>
                                <div className="flex-grow">
                                    <div className="flex w-full flex-grow bg-gray-200 px-3 h-8 items-center border-solid border border-gray-300">
                                        {status === 'loading' || status === 'idle' ? (
                                            <SkeletonText />
                                        ) : (
                                            <p className="font-thin uppercase">
                                                <span
                                                    data-testid="selected-columns-count"
                                                    className="font-bold text-xs">
                                                    columns ({selectedDecryptionColumns.length})
                                                </span>{' '}
                                            </p>
                                        )}
                                    </div>

                                    <Search
                                        className="encryption-grid-filter"
                                        disabled={!Boolean(EncryptionStore.selectedDecryptionTable)}
                                        value={filteredColumns.queryColumn}
                                        placeholder={t('encryption.columnGrid.ariaSearch')}
                                        onChange={filterChangeColumns}
                                        id="encryption-columngrid-filter"
                                        name="encryption-columngrid-filter"
                                        autoComplete="none"
                                    />

                                    <div className="flex flex-wrap">
                                        <div className="flex-grow">
                                            <ColumnPills
                                                type="decryption"
                                                onPathSelect={(c: SelectedColumn) => {
                                                    if (fourLevel) {
                                                        EncryptionStore.setSelectedSchema('decryption', c.database);
                                                    }
                                                    EncryptionStore.setSelectedTable('decryption', c.table);
                                                    EncryptionStore.setSelectedDatabase('decryption', c.dbName);
                                                }}
                                                selectedColumns={selectedDecryptionColumns}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex justify-between">
                                        <div className="flex-1 encryption-columns-datagrid">
                                            <ColumnsTable
                                                cryptoAction="decryption"
                                                table={EncryptionStore.selectedDecryptionTable}
                                                database={EncryptionStore.selectedDecryptionDatabase}
                                                columns={columns}
                                                selectedColumns={selectedDecryptionColumns}
                                                sendDeselected={(d: ColumnMetaData) => {
                                                    EncryptionStore.deleteSelectedColumns({
                                                        actionType: 'decryption',
                                                        nextColumns: [d],
                                                    });
                                                }}
                                                sendSelected={(d: ColumnMetaData, replace) => {
                                                    EncryptionStore.setSelectedColumns({
                                                        actionType: 'decryption',
                                                        nextColumns: [d],
                                                        replace: replace as boolean,
                                                    });
                                                }}
                                                sendSelectedMultiple={(data: ColumnMetaData[]) => {
                                                    EncryptionStore.setSelectedColumns({
                                                        actionType: 'decryption',
                                                        nextColumns: data,
                                                        replace: false,
                                                    });
                                                }}
                                                sendDeselectedMultiple={(data: ColumnMetaData[]) => {
                                                    EncryptionStore.deleteSelectedColumns({
                                                        actionType: 'decryption',
                                                        nextColumns: data,
                                                    });
                                                }}
                                                requestStatus={status}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Content>
                    </>
                );
            }}
        </Observer>
    );
};

export default Decryption;
