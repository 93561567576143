import BAP from './BaffleAppStore';
import MP from './MigrationPlanStore';
import Mig from './MigrationStore';
import ACS from './AppConfigStore';
import AS from './ApplicationStore';
import ES from './EncryptionStore';
import KDS from './KeyDetailsStore';
import SS from './SessionStore';
import ShieldS from './ShieldStore';
import AES from './AgentErrorStore';
import DBSS from './DatabaseServerStore';
import KSS from './KeystoreStore';
import FFS from './FeatureFlagStore';
import US from './UsersStore';
import EFS from './ElementFocusStore';
import DFS from './DataFormatStore';
import VS from './VersionStore';
import PS from './PolicyStore';
import SES from './SystemEmailStore';
import RPS from './RbacPolicyStore';
import UGS from './UserGroupStore';
import MPS from './MaskingPolicyStore';
import STS from './SetupStore';
import DS from './DatabaseStore';
import CS from './CertificateStore';
import TENANT from './TenantStore';
import ROLE from './RoleStore';
import DPS from './DatabaseProxiesStore';

export const BaffleAppStore = new BAP();
export const AppConfigStore = new ACS();
export const ApplicationStore = new AS();
export const EncryptionStore = new ES();
export const KeyDetailsStore = new KDS();
export const SessionStore = new SS();
export const ShieldStore = new ShieldS();
export const AgentErrorStore = new AES();
export const MigrationPlanStore = new MP();
export const MigrationStore = new Mig();
export const DatabaseServerStore = new DBSS();
export const KeystoreStore = new KSS();
export const FeatureFlagStore = new FFS();
export const UsersStore = new US();
export const ElementFocusStore = new EFS();
export const DataFormatStore = new DFS();
export const VersionStore = new VS();
export const PolicyStore = new PS();
export const SystemEmailStore = new SES();
export const RbacPolicyStore = new RPS();
export const UserGroupStore = new UGS();
export const MaskingPolicyStore = new MPS();
export const SetupStore = new STS();
export const DatabaseStore = new DS();
export const CertificateStore = new CS();
export const TenantStore = new TENANT();
export const RoleStore = new ROLE();
export const DatabaseProxiesStore = new DPS();

export default {
    BaffleAppStore,
    ApplicationStore,
    MigrationStore,
    MigrationPlanStore,
    DatabaseServerStore,
    AppConfigStore,
    EncryptionStore,
    KeyDetailsStore,
    SessionStore,
    ShieldStore,
    AgentErrorStore,
    KeystoreStore,
    FeatureFlagStore,
    UsersStore,
    ElementFocusStore,
    DataFormatStore,
    SystemEmailStore,
    VersionStore,
    PolicyStore,
    RbacPolicyStore,
    UserGroupStore,
    MaskingPolicyStore,
    SetupStore,
    DatabaseStore,
    CertificateStore,
    TenantStore,
    RoleStore,
    DatabaseProxiesStore,
};
