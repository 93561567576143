import React from 'react';
import { t } from '@baffle/translate';
import { Manager, Reference, Popper } from '@baffle/components/src/poppers/Popover';
import NewHelpIcon from '@baffle/components/src/icons/NewHelpIcon';
import ReactDOM from 'react-dom';

interface JsonPolicyHelpProps {
    onRefClick: () => void;
    showHelp: boolean;
}

const JsonPolicyHelp = ({ onRefClick, showHelp }: JsonPolicyHelpProps) => {
    return (
        <Manager>
            <Reference>
                {({ ref }) => (
                    <span
                        id="help-json-policy"
                        ref={ref}
                        onClick={() => {
                            onRefClick();
                        }}
                        className="input-char-counter right-0 cursor-pointer">
                        <NewHelpIcon />
                    </span>
                )}
            </Reference>
            {showHelp
                ? ReactDOM.createPortal(
                      <Popper placement="right" eventsEnabled={false}>
                          {({ ref, style, placement, arrowProps }) => (
                              <div
                                  ref={ref}
                                  style={style}
                                  className="bg-white border-solid border border-gray-300 shadow-rbacHelp p-2 rounded-sm baffle-popover z-10 text-left cursor-auto top-8 left-4 w-56"
                                  data-placement="right">
                                  <p className="text-xs">{t('encryption.modeLibrary.jsonPolicy.jsonPolicyHelpCopy')}</p>
                                  <div
                                      ref={arrowProps.ref}
                                      style={Object.assign({}, arrowProps.style, {
                                          top: '12px',
                                      })}
                                      data-placement={'right'}
                                      className="popover-arrow"
                                  />
                              </div>
                          )}
                      </Popper>,
                      document.getElementById('help-json-policy') as HTMLElement
                  )
                : null}
        </Manager>
    );
};

export default JsonPolicyHelp;
