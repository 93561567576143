import Joi from '@hapi/joi';
import { t } from '@baffle/translate';
import { SystemInfoEmail } from '@baffle/graphql/src/models';

export default function createSettingsInputScheme(systemEmail?: SystemInfoEmail | null) {
    const SettingsInputScheme = {
        SMTP: {
            keys: ['host', 'password', 'port', 'protocol', 'retryCount', 'username'],
            ns: 'emailSetup',
            defaultValues: {
                host: systemEmail?.host,
                port: systemEmail?.port,
                username: systemEmail?.username,
                protocol: 'smtp',
                retryCount: 3,
            },
            validationSchema: {
                host: Joi.string()
                    .min(1)
                    .required()
                    .error(new Error(t('setup.hostInputInvalid'))),
                password: Joi.string()
                    .min(1)
                    .required()
                    .error(new Error(t('setup.passwordInputInvalid'))),
                port: Joi.number()
                    .port()
                    .required()
                    .error(new Error(t('setup.portInputInvalid'))),
                username: Joi.string()
                    .min(1)
                    .required()
                    .error(new Error(t('setup.usernameInputInvalid'))),
            },
        },
        System: {
            keys: ['domain', 'publicAccess', 'proxyAccess', 'organization'],
            ns: 'systemSetup',
            defaultValues: {},
            validationSchema: {
                domain: Joi.string()
                    .domain()
                    .min(1)
                    .required()
                    .error(new Error(t('setup.domainInputInvalid'))),
                publicAccess: Joi.string()
                    .min(1)
                    .required()
                    .error(new Error(t('setup.publicAccessInputInvalid'))),
                proxyAccess: Joi.string()
                    .min(1)
                    .required()
                    .error(new Error(t('setup.proxyAccessInputInvalid'))),
                organization: Joi.string()
                    .min(1)
                    .required()
                    .error(new Error(t('setup.organizationInputInvalid'))),
            },
        },
        Logger: {
            keys: ['name', 'logLevel'],
            ns: 'loggerSetup',
            validationSchema: {
                name: Joi.string()
                    .domain()
                    .min(1)
                    .required()
                    .error(new Error(t('settings.loggerNameInputInvalid'))),
                logLevel: Joi.string()
                    .min(1)
                    .required(),
            },
        },
    };
    return SettingsInputScheme;
}
