import React from 'react';
import { Observer } from 'mobx-react-lite';
import Content from '@baffle/components/src/content/Content';
import Header from '@baffle/components/src/nav/Header';
import { t } from '@baffle/translate';
import { useManageModals } from '@baffle/components/src/modal/Modal';
import RbacPolicies from './rbacPolicy/RbacPolicies';
import UserGroups from './usergroup/UserGroups';

const Rbac = () => {
    const initialModalState = {
        rbacPolicies: true,
        userGroups: false,
    };
    const [modalsToggled, setModalsToggled] = useManageModals(initialModalState);
    return (
        <Observer>
            {() => {
                return (
                    <>
                        <Content>
                            <Header>
                                <div className="flex-1 my-5 px-10 flex justify-between">
                                    <div className="flex-1 flex items-center">
                                        <h1 className="text-heading-01 text-gray-100">{t('main.rbac')}</h1>
                                    </div>
                                </div>
                            </Header>
                            <div className="flex flex-row px-10 my-5 text-sm">
                                <div
                                    data-testid="policy-tab"
                                    onClick={() => {
                                        setModalsToggled({
                                            rbacPolicies: true,
                                            userGroups: false,
                                        });
                                    }}
                                    className={
                                        modalsToggled.rbacPolicies
                                            ? 'pb-2 w-40 text-center border-solid border-b-2 border-blue-60 cursor-pointer'
                                            : 'pb-2 w-40 text-center border-solid border-b border-gray-300 cursor-pointer'
                                    }>
                                    {t('main.policies')}
                                </div>
                                <div
                                    data-testid="user-group-tab"
                                    onClick={() => {
                                        setModalsToggled({
                                            rbacPolicies: false,
                                            userGroups: true,
                                        });
                                    }}
                                    className={
                                        modalsToggled.userGroups
                                            ? 'pb-2 w-40 text-center border-solid border-b-2 border-blue-60 cursor-pointer'
                                            : 'pb-2 w-40 text-center border-solid border-b border-gray-300 cursor-pointer'
                                    }>
                                    {t('encryption.modeLibrary.rbac.userGroups')}
                                </div>
                            </div>
                            {modalsToggled.rbacPolicies ? <RbacPolicies /> : null}
                            {modalsToggled.userGroups ? <UserGroups /> : null}
                        </Content>
                    </>
                );
            }}
        </Observer>
    );
};

export default Rbac;
