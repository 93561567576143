import React, { useEffect, useState } from 'react';
import { t } from '@baffle/translate';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@baffle/components/src/modal/Modal';
import Button from '@baffle/components/src/buttons/Button';
import Joi from '@hapi/joi';
import debounce from 'lodash.debounce';
// import applicationClient from '@baffle/api-client/src/applicationClient';
import TextInput from '@baffle/components/src/forms/TextInput';
import { useInputState } from '@baffle/utilities/src/inputHooks';

interface ModalPropsWithoutChildren {
    'aria-labelledby'?: string;
    open: boolean;
    onSuccess?: (fileName: string) => void;
    onClose?: () => void;
}

const AddConfigFileModal = ({ open, onSuccess = () => null, onClose = () => null }: ModalPropsWithoutChildren) => {
    const { inputState, inputErrors, inputActions } = useInputState({
        keys: ['fileName'],
        defaultValues: {},
        ns: 'application',
        validationSchema: {
            fileName: Joi.string()
                .min(1)
                .max(30)
                .pattern(/^[\.a-zA-Z0-9_-]+$/)
                .required()
                .messages({
                    'string.max': t('main.invalidLength', { max: 30 }),
                    'string.empty': t('main.invalidEmpty'),
                    'string.pattern.base': t('main.noSpecialCharacters', { specialChars: '!#$%&!\\/' }),
                }),
        },
    });
    const [closeCompleted, setCloseCompleted] = useState(false);

    //clean up state when modal closes
    const onCloseCompleted = () => {
        setCloseCompleted(true);
    };

    //clean up state when modal finishes closing
    useEffect(() => {
        if (!open && closeCompleted) {
            inputActions.reset();
            setCloseCompleted(false);
        }
    }, [open, closeCompleted]);

    const doAddConfigFile = () => {
        onSuccess(inputState.fileName as string);
    };

    const valid = inputActions.validateAll(true);

    return (
        <Modal open={open} onClose={onClose} onCloseCompleted={onCloseCompleted}>
            <ModalHeader onClose={onClose}>
                <div className="flex items-start">
                    <h3 className="text-xl font-medium text-gray-100" id="modal-headline">
                        {t('application.addConfigFile')}
                    </h3>
                </div>
            </ModalHeader>
            <ModalBody>
                <div className="mb-4">
                    <TextInput
                        labelText={t('application.configFileName')}
                        value={(inputState.fileName as string) ?? ''}
                        invalid={Boolean(inputErrors.fileName)}
                        invalidText={inputErrors.fileName}
                        onChange={e => {
                            const val = e.target.value;
                            inputActions.setField('fileName', val);
                        }}
                        onBlur={() => inputActions.validateField({ field: 'fileName', skipNull: true })}
                        topRightHelperText={t('main.maxCharLabel', { max: 30 })}
                        name="config-file-name-input"
                        data-testid="config-file-name-input"
                        maxLength={30}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex justify-end items-center h-16 w-full">
                    <Button theme="secondary" className="h-full w-1/2" onClick={onClose} size="lg">
                        {t('main.cancel')}
                    </Button>
                    <Button
                        theme={!valid ? 'disabled' : 'primary'}
                        className="h-full w-1/2"
                        data-testid="save-config-file-btn"
                        onClick={debounce(doAddConfigFile, 300)}
                        size="lg">
                        {t('main.save')}
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default AddConfigFileModal;
