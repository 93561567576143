import React from 'react';
import { CodeSnippet, Link, Button } from 'carbon-components-react';
import { t } from '@baffle/translate';
import './Overview.scss';
import AddIcon from '@material-ui/icons/Add';

export interface BaseProps {
    children?: React.ReactNode | React.ReactFragment | React.ReactPortal | null | undefined;
}

interface Info {
    [key: string]: any;
}

/*
    Description :- 
        * cn_list - Require array of field names to be shown with code snippet.
        * wm_list - Require array of field names to be shown with width max.
        * nv_list - Require array of object [fieldName]:url to be shown in link form.
    Examples :- 
        Simple Usage -
            <Overview
                title={t('database.dbDetailsTitle')}
                info={DatabaseStore.getDbOverviewPayload(databaseDetails)}
                topic="databaseDetail"
            />
        Advance Usage - 
            <Overview
                title={t('databaseProxies.databaseProxy.databaseProxyDetails')}
                info={DatabaseProxiesStore.getDatabaseProxyOverview(databaseProxy)}
                topic="databaseProxyDetail"
                cn_list={['syncId']}
                wm_list={['syncId']}
                nv_list={{
                    database: `/databasesV2/${databaseProxy?.database?.id}`,
                    keystore: `/keystore/${databaseProxy?.keystore?.id}`,
                }}
            />
*/
export interface OverviewProps {
    title: string;
    info: Info;
    topic: string;
    cn_list?: string[];
    wm_list?: string[];
    nv_list?: Info;
}

export interface OverviewCardProps extends BaseProps {
    topic: string;
    title: string;
    onAdd?: () => void;
    addButtonCaption?: string;
}

export interface OverviewFieldProps extends BaseProps {
    widthFull: boolean;
}

export const Overview = (props: OverviewProps) => {
    const { info, title, topic, cn_list, wm_list, nv_list } = props;
    return (
        <OverviewCard title={title} topic={topic}>
            <div className="flex flex-wrap w-full gap-y-6 gap-x-30 small:gap-x-30 med:gap-x-34 large:gap-x-40">
                {Object.entries(info).map(([fieldName, fieldValue]) => {
                    return (
                        <OverviewField key={fieldName} widthFull={wm_list?.includes(fieldName)!}>
                            <OverviewFieldName>{t(`${topic}.${fieldName}`)}</OverviewFieldName>
                            <OverviewFieldValue>
                                {![null, undefined, ''].includes(fieldValue) ? (
                                    cn_list?.includes(fieldName) ? (
                                        <CodeSnippet
                                            className="code-snippet"
                                            type="single"
                                            copyButtonDescription=""
                                            feedback="">
                                            {fieldValue}
                                        </CodeSnippet>
                                    ) : nv_list && fieldName in nv_list ? (
                                        <Link target="_blank" className="underline" href={nv_list[fieldName]}>
                                            {String(fieldValue)}
                                        </Link>
                                    ) : (
                                        <>
                                            {typeof fieldValue === 'object'
                                                ? fieldValue.join(', ')
                                                : String(fieldValue)}
                                        </>
                                    )
                                ) : (
                                    <>- -</>
                                )}
                            </OverviewFieldValue>
                        </OverviewField>
                    );
                })}
            </div>
        </OverviewCard>
    );
};

export const OverviewField = ({ children, widthFull }: OverviewFieldProps) => {
    return <div className={`${widthFull == true ? 'w-full' : 'w-72'} flex flex-col gap-y-2`}>{children}</div>;
};

export const OverviewFieldName = ({ children }: BaseProps) => {
    return <div className="text-gray-600">{children}</div>;
};

export const OverviewFieldValue = ({ children }: BaseProps) => {
    return <span>{children}</span>;
};

export const OverviewCard = ({ title, topic, addButtonCaption, onAdd, children }: OverviewCardProps) => {
    return (
        <div data-testid={`${topic}-Overview`} className="flex-1 m-4">
            <div className="bg-white p-4">
                <div className="h-7 flex mb-4 justify-between items-center">
                    <p className="text-lg">{title}</p>
                    {addButtonCaption && (
                        <Button id="add-button" className="px-5" tabIndex={0} onClick={onAdd} size="sm" kind="primary">
                            {addButtonCaption}
                            <AddIcon className="pl-2" />
                        </Button>
                    )}
                </div>
                {children}
            </div>
        </div>
    );
};
