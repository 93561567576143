import { useState } from 'react';
import useShiftKeyPress from './useShiftKeyPress';

const useShiftClickColumns = () => {
    const [lastSelected, setLastSelected] = useState(0);

    return {
        lastSelected,
        setLastSelected,
        shiftPress: useShiftKeyPress(),
    };
};

export default useShiftClickColumns;
