import Joi from '@hapi/joi';
import { makeAutoObservable, observable } from 'mobx';

type InputErrors<T> = {
    [Property in keyof T]: string | null;
};

type InputSchema<T> = {
    [Property in keyof T]: Joi.Schema;
};

export default class FormInputStore<T, TSchema> {
    values = observable<T>(<T>{});
    errors = observable<InputErrors<T>>(<InputErrors<T>>{});
    schema: InputSchema<T>;
    constructor(defaultFields: T, schema: InputSchema<T>) {
        makeAutoObservable(this);
        this.setValues(defaultFields);
        this.schema = schema;
    }
    setSchema(schema: InputSchema<T>) {
        this.schema = schema;
    }
    setValues(values: Partial<T>) {
        this.values = Object.assign({}, this.values, values);
    }
    setValue(field: keyof T, value: T[keyof T]) {
        this.values[field] = value;
    }
    setValueAndValidate(field: keyof T, value: T[keyof T]) {
        this.values[field] = value;
    }
    setError(field: keyof T, value: string | null) {
        this.errors[field] = value;
    }

    validate(opts: { field: keyof T; skipNull?: boolean }) {
        const { field, skipNull = true } = opts;
        if (skipNull) {
            if (this.values[field] === null || this.values[field] === undefined) {
                return;
            }
        }
        const schema = this.schema[field];
        const result = schema.validate(this.values[field]);
        if (result.error) {
            this.setError(field, result.error.message);
        } else if (this.errors[field]) {
            this.setError(field, null);
        }
    }
    get isValid() {
        const result = Joi.object(this.schema).validate(this.values, { allowUnknown: true });
        if (result.error) {
            return false;
        } else {
            return true;
        }
    }
}
