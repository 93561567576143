import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { BaffleIconProps } from '../componentTypes';

const ChevronBoldIcon = ({ color = '#868d95', ...rest }: BaffleIconProps) => {
    return (
        <SvgIcon {...rest}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g>
                    <polygon
                        fill={color}
                        points="13.5163613 5 19.75 12.1070823 13.5163613 19 4 19 10.2336387 12.1070823 4 5"></polygon>
                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                </g>
            </g>
        </SvgIcon>
    );
};

export default ChevronBoldIcon;
