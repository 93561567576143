import { User } from '@baffle/graphql/src/models';

const filterByQuery = (query: string, users: User[]) => {
    const q = (query ?? '').toLowerCase();
    if (q === '') {
        return users;
    }
    return users.filter(
        (user: User) =>
            (user.firstName ?? '').toLowerCase().includes(q) ||
            (user.email ?? '').toLowerCase().includes(q) ||
            (user.lastName ?? '').toLowerCase().includes(q)
    );
};

export { filterByQuery };
