import React from 'react';
import { User } from '@baffle/graphql/src/models';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { t } from '@baffle/translate';
import { format } from '@baffle/utilities/src/date';
import { getRoleLabel, isSuperAdmin } from '@baffle/utilities/src/user/roles';
import OverflowMenu from '@baffle/components/src/menu/OverflowMenu';
import { Observer } from 'mobx-react-lite';
import PersonIcon from '@material-ui/icons/Person';

interface props {
    user: User;
    onResendInvite: () => void;
    onRemoveUser: () => void;
}
const UserCard = ({ user, onResendInvite, onRemoveUser }: props) => {
    const initials =
        user.firstName && user.lastName ? (
            `${user.firstName[0]}${user.lastName[0]}`
        ) : (
            <PersonIcon className="w-12 h-12" />
        );

    return (
        <li
            data-testid={`user-card-${user.id}`}
            className="col-span-1 flex flex-col text-center bg-gray-600 shadow divide-y divide-gray-200 max-w-md">
            <div className="flex-1 flex flex-col px-4 py-4">
                <div className="flex-1 flex justify-end">
                    <Observer>
                        {() => {
                            const userActionMenuItems = [
                                {
                                    text: t('users.remove'),
                                    onClick: () => {
                                        onRemoveUser();
                                    },
                                },
                            ];
                            if (!user.active) {
                                userActionMenuItems.unshift({
                                    onClick: () => {
                                        onResendInvite();
                                    },
                                    text: t('users.resendInvite'),
                                });
                            }

                            return !isSuperAdmin(user.roles) && userActionMenuItems.length ? (
                                <OverflowMenu
                                    menuItems={userActionMenuItems}
                                    actuator={{
                                        'data-testid': 'more-user-actions-actuator',
                                        'aria-label': t('users.moreUserActions'),
                                        className: 'p-1 focus:outline-none focus:bg-white  focus:text-blue text-white',
                                        activeClassName: 'bg-white text-gray-700',
                                        text: <MoreHorizIcon />,
                                    }}
                                />
                            ) : (
                                <></>
                            );
                        }}
                    </Observer>
                </div>
                <div className="w-32 h-32 flex-shrink-0 mx-auto bg-blue-300 rounded-full text-4xl text-white flex items-center justify-center font-bold">
                    {initials}
                </div>
                <h3 className="mt-4 text-white text-lg font-bold">{`${user.firstName ?? ''} ${user.lastName ??
                    ''}`}</h3>
                <dl className="mt flex-grow flex flex-col justify-between">
                    <dt className="sr-only">{t('users.title')}</dt>
                    <dd className="text-white">{user.email}</dd>
                    <dt className="sr-only">{t('users.email')}</dt>
                    <dd className="mt-4">
                        <span className="px-2 py-1 text-white uppercase font-medium bg-teal rounded-full">
                            {getRoleLabel(user)}
                        </span>
                    </dd>
                </dl>
            </div>
            <div className="flex flex-col justify-center items-center pb-8">
                <dl className="mt-1 flex-grow flex flex-col justify-between">
                    <dd className="text-white text-sm">
                        {!Boolean(user.active) ? t('users.inviteSent') : t('users.joinedOn')}{' '}
                        {format(user.createTime, 'MMM d, yyyy')}
                    </dd>
                    <dt className="sr-only">{t('users.joinedOn')}</dt>
                </dl>
            </div>
        </li>
    );
};

export default UserCard;
