import React from 'react';
import RightPanel, {
    PanelModule,
    TwoColTableModule,
    TwoColTableModuleRow,
} from '@baffle/components/src/content/RightPanel';
import { t } from '@baffle/translate';
import { Database } from '@baffle/graphql/src/models';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CreateIcon from '@material-ui/icons/CreateOutlined';
import ErrorIcon from '@material-ui/icons/Error';
import { format } from '@baffle/utilities/src/date';
import SkeletonText, { SkeletonParagraph } from '@baffle/components/src/skeleton/SkeletonText';

interface DatabaseSidePanelProps {
    db: Database;
    onClose?: () => void;
    deleteDatabase?: (id: string, name: string) => void;
    onCloseImmediate?: () => void;
    handleUpdateCert?: (db: Database) => void;
    handleUpdateDatabase?: (db: Database) => void;
}
const DatabaseSidePanel = ({
    db,
    deleteDatabase = () => null,
    onClose = () => null,
    onCloseImmediate = () => null,
    handleUpdateCert = (db: Database) => null,
    handleUpdateDatabase = (db: Database) => null,
}: DatabaseSidePanelProps) => {
    if (!db) {
        return null;
    }

    const detailsRows: TwoColTableModuleRow[] = [
        {
            'data-testid': 'database-added-on-text',
            cols: [t('main.addedOn'), format(db.createTime, 'yyyy-M-d H:mm:ss')],
        },
        {
            'data-testid': 'database-created-by-text',
            cols: [t('main.createdBy'), db.createdBy],
        },
    ];

    const dbDetailsRows: TwoColTableModuleRow[] = [
        {
            'data-testid': 'database-type-text',
            cols: [t('database.header1'), db.type],
        },
        {
            'data-testid': 'database-hostName-text',
            cols: [t('database.header3'), db.hostName],
        },
        {
            'data-testid': 'database-dbUser-text',
            cols: [t('database.user'), db.dbUser ?? ''],
        },
        {
            'data-testid': 'database-port-text',
            cols: [t('database.portInputLabel'), db.port?.toString()],
        },
    ];

    const configMenuItems = [
        {
            item: (
                <div className="flex items-center" data-testid="edit-database-menu-item">
                    <CreateIcon /> {t('database.edit')}
                </div>
            ),
            onClick: () => {
                handleUpdateDatabase(db);
            },
        },
        {
            item: (
                <div className="config-menu-delete flex items-center" data-testid="delete-database-menu-item">
                    <DeleteOutlineIcon /> {t('main.delete')}
                </div>
            ),
            onClick: () => {
                deleteDatabase(db.id as string, db.dbName);
            },
        },
    ];

    const showSkeleton = false;
    return (
        <RightPanel
            configMenuItems={configMenuItems}
            skeleton={showSkeleton}
            title={db.dbName}
            onClose={onClose}
            onCloseImmediate={onCloseImmediate}>
            <PanelModule title={t('main.details')}>
                {showSkeleton ? <SkeletonParagraph /> : <TwoColTableModule rows={detailsRows} />}
            </PanelModule>
            <PanelModule
                title={t('main.description')}
                data-testid="database-description"
                description={showSkeleton ? <SkeletonText /> : db.description}
            />
            <PanelModule title={t('database.details')} data-testid="database-details">
                {showSkeleton ? <SkeletonParagraph /> : <TwoColTableModule rows={dbDetailsRows} />}
            </PanelModule>
            {db.ssl ? (
                <PanelModule title={t('database.certificate')}>
                    <div className="flex justify-between">
                        <div>
                            {db.expiresSoon ? (
                                <div
                                    className="text-red mb-2 flex items-center justify-content"
                                    data-testid="database-expires-soon">
                                    <ErrorIcon className="w-4 h-4" />
                                    <p className="ml-1 text-xs truncate">
                                        {t('database.certExpiresOn', {
                                            expires: format(db?.certExpiry ?? '', 'M-d-yyyy'),
                                        })}
                                    </p>
                                </div>
                            ) : null}
                            <table className="table-fixed">
                                <tbody>
                                    <tr>
                                        <td className="text-gray-600 px-1">{t('database.certIssuerDN')}:</td>
                                        <td className="px-1" data-testid="database-certIssuerDN-text">
                                            {db.certIssuerDN}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-gray-600 px-1">{t('database.certSubjectSAN')}:</td>
                                        <td className="px-1" data-testid="database-certSubjectSAN-text">
                                            {db.certSubjectSAN}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-gray-600 px-1">{t('database.certSubjectDN')}:</td>
                                        <td className="px-1" data-testid="database-certSubjectDN-text">
                                            {db.certSubjectDN}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-gray-600 px-1">{t('main.expiresOn')}:</td>
                                        <td className="px-1" data-testid="database-certExpiry-text">
                                            {format(db?.certExpiry ?? '', 'M-d-yyyy')}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="w-48 font-light flex justify-end items-center h-full">
                            <button
                                onClick={() => {
                                    handleUpdateCert(db);
                                }}
                                data-testid={`update-database-cert-btn`}
                                id="update-database-cert-btn"
                                className="bx--btn bx--btn--icon-only-transparent text-blue-60">
                                {t('main.update')}
                            </button>
                        </div>
                    </div>
                </PanelModule>
            ) : null}
        </RightPanel>
    );
};

export default DatabaseSidePanel;
