import React, { useEffect, useMemo } from 'react';
import { Observer } from 'mobx-react-lite';
import Content from '@baffle/components/src/content/Content';
import { t } from '@baffle/translate';
import { TextInput, Dropdown, Checkbox, Button } from 'carbon-components-react';
import { databaseInputScheme } from './createDbInputScheme';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import { DatabasePayloadV2, DbType, EnvType } from '@baffle/graphql/src/models';
import { useHistory } from 'react-router-dom';
import useAwait from '@baffle/api-client/src/useAwait';
import databaseClient from '@baffle/api-client/src/databaseClientV2';
import { DatabaseStore } from '@baffle/manager/src/stores';
import PageHeader from '@baffle/components/src/pageheader/pageheader';
import '../../styles/theme-g10.scss';
import '../../styles/theme-white.scss';
import usePageHeight from '@baffle/utilities/src/usePageHeight';
import SpinnerOverlay from '@baffle/components/src/loader/SpinnerOverlay';
import { NotificationStore } from '../../stores/NotificationStore';

const CreateDatabase = () => {
    return (
        <Observer>
            {() => {
                const history = useHistory();
                const { run, status, error } = useAwait();
                const databaseInputSchema = useMemo(
                    () => databaseInputScheme(DatabaseStore.databases, DatabaseStore.database?.id),
                    [DatabaseStore.databases, DatabaseStore.database?.id]
                );
                //@ts-ignore
                const { inputState, inputErrors, inputActions } = useInputState(databaseInputSchema);
                const isValid = inputActions.validateAll(true);

                const envItems = Object.keys(DatabaseStore.dbEnvTypes).map(env => ({
                    id: env,
                    label: env,
                    'data-testid': `env-li-${env}`,
                }));

                const dbTypesItems = (DatabaseStore.dbEnvTypes[(inputState.environment as string) ?? ''] ?? []).map(
                    (envType: EnvType) => ({
                        id: envType.type,
                        label: envType.type,
                        'data-testid': `dbType-li-${envType.type}`,
                        dbType: envType.dbType,
                        port: envType.port,
                    })
                );

                const versionItems =
                    (DatabaseStore.dbEnvTypes[(inputState.environment as string) ?? ''] ?? [])
                        .find(envType => envType.type === inputState.type)
                        ?.versions?.map(version => ({
                            id: version,
                            label: version,
                            'data-testid': `version-li-${version}`,
                        })) ?? [];

                useEffect(() => {
                    if (error) {
                        NotificationStore.push({ title: error.message, kind: 'error' });
                    }
                }, [error]);

                useEffect(() => {
                    run(DatabaseStore.readDbEnvTypes());
                }, []);

                const handleSave = (testConnection: boolean = false) => {
                    const payload: DatabasePayloadV2 = databaseInputSchema.keys.reduce((acc: any, c: any) => {
                        //This creates a new object mapping the keys from the input scheme
                        //to the key/values from the inputState. And send only those values which are present.
                        if (inputState[c]) {
                            switch (c) {
                                case 'ssl':
                                    acc[c] = true;
                                    break;
                                case 'port':
                                    acc[c] = +(inputState[c] as string);
                                    break;
                                case 'password':
                                    acc[c] = inputState[c];
                                    break;
                                default:
                                    acc[c] = (inputState[c] as string).trim();
                                    break;
                            }
                        }
                        return acc;
                    }, {});
                    run(
                        testConnection
                            ? databaseClient.testConnection(payload).then(res => {
                                  if (res) {
                                      NotificationStore.push({
                                          title: t('database.connectionSuccess'),
                                          kind: 'success',
                                      });
                                  } else {
                                      NotificationStore.push({
                                          title: t('database.connectionFailure'),
                                          kind: 'error',
                                      });
                                  }
                              })
                            : databaseClient.createDatabase(payload).then((res: DatabasePayloadV2) => {
                                  NotificationStore.push({
                                      title: t('database.databaseSuccess', { name: res.name }),
                                      kind: 'success',
                                  });
                                  inputActions.reset();
                                  history.push('/databasesV2');
                              })
                    );
                };

                const breadcrumbData = [
                    {
                        label: t('main.databases'),
                        href: '/databasesV2',
                    },
                ];
                const pageHeight = usePageHeight() - 198;
                return (
                    <>
                        {(status === 'loading' || status === 'idle') && <SpinnerOverlay description="" />}
                        <Content className="bg-white">
                            <PageHeader
                                title={t('database.createDbHeader')}
                                breadcrumbData={breadcrumbData}></PageHeader>
                            <div className="flex-1 px-10 flex justify-between bg-white">
                                <div className="items-center w-40 py-4 px-4 bg-gray-10 cursor-pointer">
                                    {t('database.create')}
                                </div>
                            </div>
                            <div className="overflow-y-auto bg-gray-10" style={{ height: pageHeight }}>
                                <div className="flex flex-wrap mt-8 px-10">
                                    <div className="name-input-container w-96">
                                        <TextInput
                                            id="database-name-input"
                                            name="database-name-input"
                                            data-testid="database-name-input"
                                            labelText={t('database.databaseHeader1')}
                                            placeholder={t('database.namePlaceholder')}
                                            invalid={Boolean(inputErrors.name)}
                                            invalidText={inputErrors.name}
                                            value={(inputState.name as string) || ''}
                                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                inputActions.validateField({
                                                    field: 'name',
                                                    skipNull: true,
                                                });
                                            }}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                const val = e.target.value || '';
                                                inputActions.setField('name', val);
                                            }}
                                            className="text-input-white"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-wrap mt-4 px-10">
                                    <div className="name-input-container w-96">
                                        <Dropdown
                                            className="dropdown-white dropdown-menu-white"
                                            id="environment-select"
                                            name="environment-select"
                                            data-testid="environment-select"
                                            titleText={t('database.environmentLabel')}
                                            items={envItems}
                                            label={t('main.chooseOption')}
                                            onChange={({
                                                selectedItem,
                                            }: {
                                                selectedItem: { id: string; label: string };
                                            }) => {
                                                inputActions.setFields({
                                                    environment: selectedItem?.id,
                                                    type: '',
                                                    dbType: '',
                                                    version: '',
                                                    port: '',
                                                });
                                            }}
                                            size="lg"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-wrap mt-4 px-10">
                                    <div className="flex-initial name-input-container w-96">
                                        <Dropdown
                                            className="dropdown-white dropdown-menu-white"
                                            id="dbType-select"
                                            name="dbType-select"
                                            data-testid="dbType-select"
                                            titleText={t('database.dbTypeLabel')}
                                            items={dbTypesItems}
                                            label={t('main.chooseOption')}
                                            onChange={({
                                                selectedItem,
                                            }: {
                                                selectedItem: {
                                                    id: string;
                                                    label: string;
                                                    dbType: DbType;
                                                    port: number;
                                                };
                                            }) => {
                                                inputActions.setFields({
                                                    type: selectedItem?.id,
                                                    dbType: selectedItem?.dbType,
                                                    version: '',
                                                    port: selectedItem?.port,
                                                });
                                            }}
                                            selectedItem={inputState.type}
                                            disabled={dbTypesItems.length == 0}
                                        />
                                    </div>
                                    <div className="flex-initial ml-8 name-input-container w-96">
                                        <Dropdown
                                            className="dropdown-white dropdown-menu-white"
                                            id="version-select"
                                            name="version-select"
                                            data-testid="version-select"
                                            titleText={t('database.versionLabel')}
                                            items={versionItems}
                                            label={t('main.chooseOption')}
                                            onChange={({
                                                selectedItem,
                                            }: {
                                                selectedItem: { id: string; label: string };
                                            }) => {
                                                inputActions.setField('version', selectedItem.id);
                                            }}
                                            selectedItem={inputState.version}
                                            disabled={versionItems.length == 0}
                                        />
                                    </div>
                                </div>
                                <div className="flex-1 mt-4 px-10 flex justify-between">
                                    <div className="flex-1 flex items-center">
                                        <h3 className="text-xl">{t('database.server')}</h3>
                                    </div>
                                </div>
                                <div className="flex flex-wrap mt-4 px-10">
                                    <div className="flex-initial w-96">
                                        <TextInput
                                            id="hostname-input"
                                            name="hostname-input"
                                            data-testid="hostname-input"
                                            labelText={t('database.databaseHeader3')}
                                            placeholder={t('database.hostNamePlaceholder')}
                                            invalid={Boolean(inputErrors.hostname)}
                                            invalidText={inputErrors.hostname}
                                            value={(inputState.hostname as string) || ''}
                                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                inputActions.validateField({
                                                    field: 'hostname',
                                                    skipNull: true,
                                                });
                                            }}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                const val = e.target.value || '';
                                                inputActions.setField('hostname', val);
                                            }}
                                            className="text-input-white"
                                        />
                                    </div>
                                    <div className="flex-initial ml-8 w-96">
                                        <TextInput
                                            id="port-input"
                                            name="port-input"
                                            data-testid="port-input"
                                            labelText={t('database.portInputLabel')}
                                            placeholder={t('database.portPlaceholder')}
                                            invalid={Boolean(inputErrors.port)}
                                            invalidText={inputErrors.port}
                                            value={inputState.port || ''}
                                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                inputActions.validateField({
                                                    field: 'port',
                                                    skipNull: true,
                                                });
                                            }}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                const val = e.target.value || '';
                                                inputActions.setField('port', val);
                                            }}
                                            className="text-input-white"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-wrap mt-4 px-10">
                                    <div className="flex-initial w-96">
                                        <TextInput
                                            id="configDbName-input"
                                            name="configDbName-input"
                                            data-testid="configDbName-input"
                                            labelText={t('database.configDbNameLabel')}
                                            placeholder={t('database.configDbNamePlaceholder')}
                                            invalid={Boolean(inputErrors.configDbName)}
                                            invalidText={inputErrors.configDbName}
                                            value={(inputState.configDbName as string) || ''}
                                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                inputActions.validateField({
                                                    field: 'configDbName',
                                                    skipNull: true,
                                                });
                                            }}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                const val = e.target.value || '';
                                                inputActions.setField('configDbName', val);
                                            }}
                                            className="text-input-white"
                                        />
                                    </div>
                                </div>
                                <div className="flex-1 mt-4 px-10 flex justify-between">
                                    <div className="flex-1 flex items-center">
                                        <h3 className="text-xl">{t('database.authentication')}</h3>
                                    </div>
                                </div>
                                <div className="flex flex-wrap mt-4 px-10">
                                    <div className="flex-initial w-96">
                                        <TextInput
                                            id="username-input"
                                            name="username-input"
                                            data-testid="username-input"
                                            labelText={t('database.usernameLabel')}
                                            placeholder={t('database.dbUserPlaceholder')}
                                            invalid={Boolean(inputErrors.username)}
                                            invalidText={inputErrors.username}
                                            value={(inputState.username as string) || ''}
                                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                inputActions.validateField({
                                                    field: 'username',
                                                    skipNull: true,
                                                });
                                            }}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                const val = e.target.value || '';
                                                inputActions.setField('username', val);
                                            }}
                                            className="text-input-white"
                                        />
                                    </div>
                                    <div className="flex-initial ml-8 w-96">
                                        <TextInput
                                            id="password-input"
                                            name="password-input"
                                            data-testid="password-input"
                                            labelText={t('database.passwordLabel')}
                                            placeholder={t('database.dbPassPlaceholder')}
                                            invalid={Boolean(inputErrors.password)}
                                            invalidText={inputErrors.password}
                                            value={(inputState.password as string) || ''}
                                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                inputActions.validateField({
                                                    field: 'password',
                                                    skipNull: true,
                                                });
                                            }}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                const val = e.target.value || '';
                                                inputActions.setField('password', val);
                                            }}
                                            className="text-input-white"
                                            type={'password'}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-wrap mt-4 px-10">
                                    <Checkbox
                                        id="ssl-checkbox"
                                        labelText={t('database.useSSL')}
                                        onChange={(event: any, { checked }: { checked: boolean }) => {
                                            inputActions.setField('ssl', checked);
                                        }}
                                    />
                                </div>
                                <div className="mt-4 px-10">
                                    <a href="/settings">{t('database.viewCaCertificates')}</a>
                                </div>
                                <div className="flex flex-wrap my-12 px-10">
                                    <div className="w-96">
                                        <Button
                                            kind="tertiary"
                                            disabled={!isValid}
                                            onClick={() => {
                                                handleSave(true);
                                            }}
                                            id="test-connection-btn">
                                            {t('database.testConnection')}
                                        </Button>
                                    </div>
                                    <div className="flex justify-end gap-x-5 ml-8 w-96">
                                        <Button
                                            kind="secondary"
                                            onClick={() => {
                                                history.push('/databasesV2');
                                            }}
                                            id="database-cancel-btn">
                                            {t('main.cancel')}
                                        </Button>
                                        <Button
                                            kind="primary"
                                            disabled={!isValid}
                                            onClick={() => {
                                                handleSave();
                                            }}
                                            id="create-database-btn">
                                            {t('database.create')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Content>
                    </>
                );
            }}
        </Observer>
    );
};

export default CreateDatabase;
