import React from 'react';

import a11yClickEvent from '@baffle/utilities/src/a11y/a11yClickEvent';

interface props {
    children: React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
    handler: (e: React.ChangeEvent<React.MouseEvent>) => void;
    className?: string;
    ['data-testid']?: string;
}

const KeyClick = (props: props) => {
    const eventHandler = a11yClickEvent((e: React.ChangeEvent<React.MouseEvent>) => {
        props.handler(e);
    });
    const child = React.Children.only(props.children);
    //@ts-ignore
    return React.cloneElement(child, {
        tabIndex: 0,
        onClick: eventHandler,
        onKeyDown: eventHandler,
        //@ts-ignore
        className: `cursor-pointer ${child?.props?.className ?? ''} ${props?.className ?? ''}`,
        ...(props['data-testid'] && { ['data-testid']: props['data-testid'] }),
    });
};

export default KeyClick;
