import client from '.';
import { MigrationPlan, MigrationPlanInput } from '@baffle/graphql/src/models';

const serializePayload = (input: MigrationPlanInput) => {
    return Object.assign({}, input, { batchSize: parseInt((input.batchSize as unknown) as string) });
};
export function create(id: string, plan: MigrationPlanInput) {
    return client(`api/v1.0/application/migrationProperties?applicationId=${id}`, {
        body: serializePayload(plan),
        method: 'PUT',
    });
}

export async function get(id: string) {
    try {
        const mp = (await client(`api/v1.0/application/migrationProperties/${id}`)) as MigrationPlan;
        mp.applicationId = id;
        return mp;
    } catch (error) {
        throw error;
    }
}
export default { create, get };
