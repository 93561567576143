import React, { useEffect, useRef, useState } from 'react';
import { t } from '@baffle/translate';
import { Modal, ModalBody, ModalFooter, ModalHeader, SkeletonModal } from '@baffle/components/src/modal/Modal';
import Button from '@baffle/components/src/buttons/Button';
import TextInput from '@baffle/components/src/forms/TextInput';
import { InputActions, InputError, useInputState } from '@baffle/utilities/src/inputHooks';
import Joi from '@hapi/joi';
import inviteUserClient from '@baffle/api-client/src/inviteUserClient';
import Alert from '@baffle/components/src/alerts/Alert';
import useAwait from '@baffle/api-client/src/useAwait';
import { ActivationLinkResponse } from '@baffle/graphql/src/models';
import copyTextToClipboard from '@baffle/utilities/src/copyTextToClipboard';
import { ToastStore } from '../stores/ToastStore';
import { SystemEmailStore } from '../stores';

interface ModalPropsWithoutChildren {
    'aria-labelledby'?: string;
    open: boolean;
    onClose?: () => void;
    onSuccess?: () => void;
}

const AddUserModal = ({ open, onSuccess = () => null, onClose = () => null }: ModalPropsWithoutChildren) => {
    const { status, run } = useAwait();
    useEffect(() => {
        run(SystemEmailStore.getSystemEmail());
    }, []);
    const { inputState, inputActions, inputErrors } = useInputState({
        ns: 'users',
        defaultValues: {},
        keys: ['email'],
        validationSchema: {
            email: Joi.string()
                .email({ tlds: false })
                .min(1)
                .required(),
        },
    });
    const [{ error, activationLink }, setInviteData] = useState({
        error: null as Error | null,
        activationLink: '',
    });
    const handleClose = () => {
        onClose();
        setInviteData({ error: null, activationLink: '' });
    };
    const doCreateInvite = async ({ sendEmail }: { sendEmail: boolean }) => {
        if (sendEmail && !SystemEmailStore.systemEmailSetup()) {
            setInviteData({
                activationLink: '',
                error: new Error("STMP server not configured. Please configure it or choose 'Get Invite Link' instead"),
            });
        } else {
            try {
                const data = (await inviteUserClient.createUserInvite({
                    data: { email: inputState.email as string, sendEmail },
                })) as ActivationLinkResponse;
                setInviteData({ activationLink: data.activationLink, error: null });
                onSuccess();
            } catch (error) {
                setInviteData({ activationLink: '', error });
            }
        }
    };
    return (
        <Modal open={open} onClose={handleClose}>
            {status === 'loading' || status === 'idle' ? (
                <SkeletonModal />
            ) : Boolean(activationLink) ? (
                <InviteUser activationLink={activationLink} handleClose={handleClose} />
            ) : (
                <AddUser
                    handleClose={handleClose}
                    error={error}
                    inputErrors={inputErrors}
                    inputActions={inputActions}
                    doCreateInvite={doCreateInvite}
                />
            )}
        </Modal>
    );
};

interface AddUserProps {
    handleClose: () => void;
    error: Error | null;
    inputErrors: InputError;
    inputActions: InputActions;
    doCreateInvite: ({ sendEmail }: { sendEmail: boolean }) => Promise<void>;
}
const AddUser = ({ handleClose, error, inputErrors, inputActions, doCreateInvite }: AddUserProps) => {
    const valid = inputActions.validateAll(true);
    return (
        <>
            <ModalHeader onClose={handleClose}>
                <div className="flex items-start">
                    <div>
                        <h3 className="text-xl font-medium text-gray-100" id="modal-headline">
                            {t('users.addUsers')}
                        </h3>
                        <p className="text-xs leading-5 text-gray-500 mb-4">{t('users.inviteDescription')}</p>
                    </div>
                </div>
            </ModalHeader>
            <ModalBody>
                <div className="h-64">
                    {error ? (
                        <div className="flex flex-wrap mb-4">
                            <div className="flex-1 px-4">
                                <Alert
                                    type="danger"
                                    title={t('users.inviteError')}
                                    description={error?.message || ''}
                                />
                            </div>
                        </div>
                    ) : null}
                    <TextInput
                        labelText={t('users.email')}
                        placeholder={t('users.typeEmail')}
                        invalid={Boolean(inputErrors.email)}
                        invalidText={t('users.emailInputInvalid')}
                        onChange={e => {
                            const val = (e.target.value ?? '').trim();
                            inputActions.setField('email', val);
                        }}
                        onBlur={() => {
                            inputActions.validateField({ field: 'email', skipNull: true });
                        }}
                        id="add-user-email-input"
                        name="add-user-email-input"
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex justify-between items-center h-16 w-full">
                    <Button
                        className="h-full"
                        theme={!valid ? 'disabled' : 'tertiary'}
                        onClick={() => {
                            doCreateInvite({ sendEmail: false });
                        }}
                        size="lg">
                        {t('users.getInviteLink')}
                    </Button>
                    <div className="h-full flex">
                        <Button theme="secondary" className="h-full w-40" onClick={handleClose} size="lg">
                            {t('main.cancel')}
                        </Button>
                        <Button
                            theme={!valid ? 'disabled' : 'primary'}
                            className="h-full w-40"
                            onClick={() => {
                                doCreateInvite({ sendEmail: true });
                            }}
                            size="lg">
                            {t('users.sendInvite')}
                        </Button>
                    </div>
                </div>
            </ModalFooter>
        </>
    );
};

interface InviteUserProps {
    handleClose: () => void;
    activationLink: string;
}
const InviteUser = ({ handleClose, activationLink }: InviteUserProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const doCopy = async () => {
        try {
            await copyTextToClipboard(activationLink);
            ToastStore.push({ type: 'success', title: t('users.copySuccess') });
        } catch (error) {
            ToastStore.push({ type: 'danger', title: t('users.copyFail') });
        }
    };
    return (
        <>
            <ModalHeader onClose={handleClose}>
                <div className="flex items-start">
                    <h3 className="text-xl font-medium text-gray-100" id="modal-headline">
                        {t('users.inviteLink')}
                    </h3>
                </div>
            </ModalHeader>
            <ModalBody>
                <div className="h-64">
                    <p className="text-sm text-center leading-5 text-gray-500 mb-6">{t('users.shareDescription')}</p>
                    <div className="flex items-center">
                        <div className="flex flex-grow">
                            <TextInput
                                ref={inputRef}
                                labelText={t('users.inviteLink')}
                                readOnly
                                labelHidden
                                value={activationLink}
                                onClick={e => {
                                    inputRef.current?.select();
                                    doCopy();
                                }}
                                className="border-r-0 h-10"
                                name="select-activation-link-input"
                            />
                        </div>
                        <Button
                            theme="tertiary"
                            className="w-48 h-10"
                            onClick={() => {
                                inputRef.current?.select();
                                doCopy();
                            }}>
                            {t('users.copyLink')}
                        </Button>
                    </div>
                    <p className="text-sm text-center leading-5 text-gray-500 mt-6">{t('users.linkExpires')}</p>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex justify-end items-center h-16 w-full">
                    <Button theme="secondary" className="h-full w-1/2" onClick={handleClose} size="lg">
                        {t('main.cancel')}
                    </Button>
                    <Button theme="primary" className="h-full w-1/2" onClick={handleClose} size="lg">
                        {t('users.done')}
                    </Button>
                </div>
            </ModalFooter>
        </>
    );
};

export default AddUserModal;
