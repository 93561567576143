import Joi from '@hapi/joi';
import { DataProtectionItem, ModeBuilderType } from '@baffle/graphql/src/models';
import { t } from '@baffle/translate';

export default function createModeInputScheme(modes: DataProtectionItem[], id: string | undefined) {
    const keystoreInputSchemes = {
        [ModeBuilderType.MASKING]: {
            ns: 'modebuilder',
            keys: ['name', 'description', 'mode', 'format'],
            validationSchema: {
                name: Joi.string()
                    .min(1)
                    .max(30)
                    .pattern(new RegExp(/^[^~`!@#$%^&()={}[\]:;,.<>+\/\\?]+$/))
                    .required()
                    .custom((val, helper) => {
                        if (
                            modes.some(
                                (m: DataProtectionItem) =>
                                    m.id !== id && m.name.trim().toLowerCase() === val.trim().toLowerCase()
                            )
                        ) {
                            return helper.message({ custom: t('encryption.modeLibrary.duplicateMaskModeError') });
                        }
                    })
                    .messages({
                        'string.max': t('main.invalidNameLength'),
                        'string.pattern.base': t('encryption.modeLibrary.nameInputInvalid'),
                        'string.empty': t('encryption.modeLibrary.nameInputInvalid'),
                    }),
                mode: Joi.string()
                    .min(1)
                    .required(),
                format: Joi.string()
                    .min(1)
                    .required()
                    .messages({
                        'string.empty': t('encryption.modeLibrary.formatInputInvalid'),
                    }),
            },
        },
        [ModeBuilderType.ENCRYPTION]: {
            ns: 'modebuilder',
            keys: ['name', 'description', 'mode', 'type', 'pfpeStart', 'pfpeEnd'],
            validationSchema: {
                name: Joi.string()
                    .min(1)
                    .max(30)
                    .pattern(new RegExp(/^[^~`!@#$%^&()={}[\]:;,.<>+\/\\?]+$/))
                    .required()
                    .custom((val, helper) => {
                        if (
                            modes.some(
                                (m: DataProtectionItem) =>
                                    m.id !== id && m.name.trim().toLowerCase() === val.trim().toLowerCase()
                            )
                        ) {
                            return helper.message({ custom: t('encryption.modeLibrary.duplicateEncModeError') });
                        }
                    })
                    .messages({
                        'string.max': t('main.invalidNameLength'),
                        'string.pattern.base': t('encryption.modeLibrary.nameInputInvalid'),
                        'string.empty': t('encryption.modeLibrary.nameInputInvalid'),
                    }),
                mode: Joi.string()
                    .min(1)
                    .required(),
                type: Joi.string()
                    .min(1)
                    .required(),
                pfpeStart: Joi.number()
                    .allow(null, 0)
                    .required(),
                pfpeEnd: Joi.number()
                    .allow(null, 0)
                    .required(),
            },
        },
    };
    return keystoreInputSchemes;
}
