export type SortDirection = 'NONE' | 'ASC' | 'DESC';
export interface SortInfo {
    direction: SortDirection;
    key: string;
}
/**
 *
 * sortGrid will sort a set of data for data grids
 * @param sortInfo
 * @param columns
 * @param data
 */
export const sortGrid = (sortInfo: SortInfo, columns: any, data: any) => {
    if (sortInfo.direction !== 'NONE') {
        const header = columns.find((h: any) => h.key === sortInfo.key);
        if (header && header.sortFn) {
            data.sort((a: any, b: any) => {
                if (sortInfo.direction === 'ASC') {
                    return header.sortFn(a, b);
                } else if (sortInfo.direction === 'DESC') {
                    return header.sortFn(b, a);
                }
            });
        }
    }
};
