import React, { useState, useEffect } from 'react';
import { FileUploader } from 'carbon-components-react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@baffle/components/src/modal/Modal';
import Button from '@baffle/components/src/buttons/Button';
import { t } from '@baffle/translate';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import Alert from '@baffle/components/src/alerts/Alert';
import { Keystore } from '@baffle/graphql/src/models';
import keystoreClient from '@baffle/api-client/src/keystoreClient';
import SpinnerOverlay from '@baffle/components/src/loader/SpinnerOverlay';

interface UpdateKeystoreCertModalProps {
    keystore: Keystore;
    open: boolean;
    onClose?: () => void;
    onSuccess?: (inputState: Keystore) => void;
}

const UpdateKeystoreCertModal = (props: UpdateKeystoreCertModalProps) => {
    const [open, setOpen] = useState(props.open);
    const [teardownModal, setTeardownModal] = useState(false);
    const [postError, setPostError] = useState(null);
    const [loading, setLoading] = useState(false);

    const { inputState, inputActions } = useInputState({
        ns: 'keystore',
        defaultValues: {},
        keys: ['cert'],
        validationSchema: {},
    });

    useEffect(() => {
        //reset the initial keystore type and set the default value
        if (teardownModal) {
            inputActions.setField('cert', null);
        }
    }, [teardownModal]);

    //Sync state from props
    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const initClose = (clearState?: boolean) => {
        if (loading || !open) {
            return;
        }
        setOpen(false);
        //allow the modal to animate out
        setTimeout(() => {
            closeModal(clearState);
        }, 200);
    };
    const closeModal = (clearState?: boolean) => {
        if (loading) {
            return;
        }
        if (clearState) {
            inputActions.reset();
            setPostError(null);
            setTeardownModal(true);
            setTimeout(() => setTeardownModal(false), 0);
        }
        if (props.onClose) {
            props.onClose();
        }
    };
    const doUpdateKeystoreCert = async () => {
        setLoading(true);
        try {
            await keystoreClient.updateCert(props.keystore.id as string, inputState.cert as File);
        } catch (error) {
            setPostError(JSON.parse(error.message).errors.join(', '));
            setLoading(false);
            return;
        }
        props.onSuccess && props.onSuccess(props.keystore);
        initClose(true);
        setLoading(false);
    };
    const isValid = inputActions.validateAll(true);
    //hack to remove the input components state
    if (teardownModal) {
        return null;
    }
    return (
        <Modal open={open} onClose={() => initClose(true)} aria-label="modal">
            {loading && <SpinnerOverlay description={t('keystore.uploadingKeystoreCert')} />}
            <ModalHeader onClose={() => initClose(true)}>
                <div className="flex items-start">
                    <div className="mt-0 ml-2">
                        <h3 className="text-xl font-medium text-gray-100" id="modal-headline">
                            {t('keystore.updateKeystoreCert')}
                        </h3>
                    </div>
                </div>
            </ModalHeader>
            <ModalBody>
                <>
                    {postError ? (
                        <div className="flex flex-wrap">
                            <div className="flex-1 px-4">
                                <Alert
                                    type="danger"
                                    title={t('keystore.updateCertError')}
                                    description={postError || ''}
                                />
                            </div>
                        </div>
                    ) : null}
                    <div className="mb-6">
                        <Alert type="warning" description={t('keystore.updateCertWarning')} />
                    </div>
                    <p className="text-xs">{t('keystore.certInputLabel')}</p>
                    <FileUploader
                        buttonLabel={t('keystore.addCertBtn')}
                        id="ks-safenet-cert-input"
                        aria-label={t('keystore.addCertBtn')}
                        accept={['.pem']}
                        name="ks-safenet-cert-input"
                        buttonKind="tertiary"
                        filenameStatus="edit"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputActions.setField('cert', e.target.files ? e.target.files[0] : null)
                        }
                    />
                </>
            </ModalBody>
            <ModalFooter>
                <div className="flex justify-end items-center h-16 w-full">
                    <Button className="h-full w-1/2" theme="secondary" onClick={() => initClose(true)} size="lg">
                        {t('main.cancel')}
                    </Button>
                    <Button
                        className="h-full w-1/2"
                        theme={isValid ? 'primary' : 'disabled'}
                        id="do-add-keystore-cert-btn"
                        onClick={doUpdateKeystoreCert}
                        size="lg">
                        {t('main.save')}
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default UpdateKeystoreCertModal;
