import React from 'react';
import LoginLogo from '@baffle/components/src/logo/LoginLogo';
import './Session.scss';
import { BaseCompoundComponentProps } from '@baffle/components/src/types/react';

const Session = ({ children }: BaseCompoundComponentProps) => {
    return (
        <div className="session flex flex-col min-h-screen justify-center items-center bg-blue-900">
            <div className="w-120">
                <div className="flex justify-center mb-8">
                    <LoginLogo />
                </div>
            </div>
            {children}
        </div>
    );
};

export default Session;
