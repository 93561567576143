import KeyClick from '@baffle/components/src/handlers/KeyClck';
import React, { useState } from 'react';
import cx from 'classnames';
import TreeDownArrowIcon from '@baffle/components/src/icons/TreeDownArrowIcon';
import TreeRightArrowIcon from '@baffle/components/src/icons/TreeRightArrowIcon';
import TreeFolderIcon from '@baffle/components/src/icons/TreeFolderIcon';
import { EncryptionStore } from '../../stores';
import { CryptoActionType } from '@baffle/graphql/src/models';
import TreeTableIcon from '@baffle/components/src/icons/TreeTableIcon';
import TreeViewIcon from '@baffle/components/src/icons/TreeViewIcon';
import { ToastStore } from '../../stores/ToastStore';

interface DecryptionTreeViewItemProp {
    id: string;
    tableType: string;
    items: any[];
    db: string;
    i: number;
    cryptoAction: CryptoActionType;
    fourLevel?: boolean;
    schemaName?: string;
}

const DecryptionTreeViewItem = ({
    id,
    tableType,
    items,
    db,
    i,
    cryptoAction,
    fourLevel,
    schemaName,
}: DecryptionTreeViewItemProp) => {
    const [isSelected, setIsSelected] = useState(
        Boolean(
            items.find(i => {
                return i == EncryptionStore.selectedDecryptionTable;
            })
        )
    );
    return (
        <>
            <KeyClick
                key={`keyclick_${db}_${i}_tables`}
                data-testid={`${tableType == 'views' ? 'view' : 'table'}_folder`}
                handler={() => {
                    setIsSelected(!isSelected);
                }}>
                <div
                    className={cx(
                        'group w-full flex items-center py-1 font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-200'
                    )}
                    aria-controls={`schema-nav-menu-${i}`}
                    aria-expanded={isSelected ? 'true' : 'false'}>
                    <span
                        className={cx('flex-1 flex justify-between items-center ml-12', {
                            italic: db,
                        })}>
                        <span className="w-56 truncate">
                            {isSelected ? <TreeDownArrowIcon /> : <TreeRightArrowIcon />}
                            <TreeFolderIcon />
                            <span className="ml-1" title={db}>
                                {`${tableType} (${items.length})`}
                            </span>
                        </span>
                    </span>
                </div>
            </KeyClick>
            {isSelected &&
                items.map((table, i) => {
                    const isSelectedTable = EncryptionStore.selectedDecryptionTable === table;
                    return (
                        <KeyClick
                            key={`keyclick_${table}_${i}`}
                            data-testid={`${tableType == 'views' ? 'view' : 'table'}_tree_entry_${
                                schemaName ? schemaName : db
                            }_${table}`}
                            handler={() => {
                                EncryptionStore.setSelectedTable(cryptoAction, table);

                                if (fourLevel) {
                                    EncryptionStore.listSchemaTableColumns(
                                        id as string,
                                        EncryptionStore.selectedDecryptionSchema,
                                        table
                                    ).catch(err => {
                                        ToastStore.push({
                                            title: err.message,
                                            type: 'danger',
                                        });
                                    });
                                } else {
                                    EncryptionStore.listColumns(
                                        id as string,
                                        EncryptionStore.selectedDecryptionDatabase,
                                        table
                                    ).catch(err => {
                                        ToastStore.push({
                                            title: err.message,
                                            type: 'danger',
                                        });
                                    });
                                }
                            }}>
                            <div
                                key={`table_${i}_${table}`}
                                className={cx(
                                    'group w-full flex items-center py-1 font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-200',
                                    {
                                        'bg-blue-100': isSelectedTable,
                                    }
                                )}>
                                <span
                                    className={cx('flex-1 flex justify-between items-center ml-20', {
                                        italic: '', //table.new,
                                    })}>
                                    <span className="w-44 truncate">
                                        {tableType == 'tables' ? <TreeTableIcon /> : <TreeViewIcon />}
                                        <span className="ml-1" title={table}>
                                            {table}
                                        </span>
                                    </span>
                                </span>
                            </div>
                        </KeyClick>
                    );
                })}
        </>
    );
};

export default DecryptionTreeViewItem;
