import React from 'react';
import { Observer } from 'mobx-react-lite';
import { t } from '@baffle/translate';
import { useManageModals } from '@baffle/components/src/modal/Modal';
import DatabaseProxyOverview from './DatabaseProxyOverview';
import DatabaseProxyShields from './DatabaseProxyShields';

const DatabaseProxyTabs = () => {
    const initialModalState = {
        overview: true,
        shields: false,
    };
    const [modalsToggled, setModalsToggled] = useManageModals(initialModalState);
    return (
        <Observer>
            {() => {
                return (
                    <>
                        <div className="flex flex-row px-10 mt-5 mb-1 text-sm">
                            <div
                                data-testid="database-proxy-overview-tab"
                                onClick={() => {
                                    setModalsToggled({
                                        overview: true,
                                        shields: false,
                                    });
                                }}
                                className={
                                    modalsToggled.overview
                                        ? 'pb-2 w-40 text-center border-solid border-b-2 border-blue-60 cursor-pointer'
                                        : 'pb-2 w-40 text-center border-solid border-b border-gray-300 cursor-pointer'
                                }>
                                {t('databaseProxies.databaseProxy.overview')}
                            </div>

                            <div
                                data-testid="database-proxy-shields-tab"
                                onClick={() => {
                                    setModalsToggled({
                                        overview: false,
                                        shields: true,
                                    });
                                }}
                                className={
                                    modalsToggled.shields
                                        ? 'pb-2 w-40 text-center border-solid border-b-2 border-blue-60 cursor-pointer'
                                        : 'pb-2 w-40 text-center border-solid border-b border-gray-300 cursor-pointer'
                                }>
                                {t('databaseProxies.databaseProxyShields.shields')}
                            </div>
                        </div>
                        {modalsToggled.overview ? <DatabaseProxyOverview /> : null}
                        {modalsToggled.shields ? <DatabaseProxyShields /> : null}
                    </>
                );
            }}
        </Observer>
    );
};

export default DatabaseProxyTabs;
