import { TYPE_NAMES, MAX_PAGE_SIZE } from '.';
import gql from 'graphql-tag';

export const GET_APPLICATION = gql`
    query GetApplication($id: String!) {
        application(id: $id) @rest(type: "${TYPE_NAMES.ApplicationDetails}", path: "application/{args.id}", endpoint: "v1") {
            id 
            keystore 
            db 
            appName
            description
            createTime
            createdBy
            mode
            recordLevel
            lastKeyRotation
        }
    }
`;

interface ApplicationWith {
    withKeystore?: boolean;
}

export const GET_APPLICATION_WITH = ({ withKeystore }: ApplicationWith) => gql`
    query GetApplication($id: String!) {
        application(id: $id) @rest(type: "${
            TYPE_NAMES.ApplicationDetails
        }", path: "application/{args.id}", endpoint: "v1") {
            id @export(as: "id")
            keystore @export(as: "keystoreId")
            db 
            appName
            description
            createTime
            createdBy
            mode
            recordLevel
            lastKeyRotation
            ${
                withKeystore
                    ? `keystoreDetails @rest(type: "${TYPE_NAMES.Keystore}", path: "keystore/{exportVariables.keystoreId}", endpoint: "v1") {
                name
                ssl
                certExpiry
                expiresSoon
                expired
            }`
                    : ''
            }
        }
    }
`;

export const DELETE_APPLICATION = gql`
    mutation DeleteApplication($id: ID!) {
        deleteApplication(id: $id)
            @rest(type: "${TYPE_NAMES.Application}", path: "application/unenroll/{args.id}", method: "DELETE", endpoint: "v1") {
                data
        }
    }
`;

export const LIST_APPLICATIONS = gql`
    query ListApplications {
        applications @rest(type: "[${TYPE_NAMES.Application}]", path: "application?pagenum=1&pagesize=${MAX_PAGE_SIZE}", endpoint: "v1") {
            id @export(as: "id")
            name
            mode
            status
            details @rest(type: "${TYPE_NAMES.ApplicationDetails}", path: "application/{exportVariables.id}", endpoint: "v1") {
                recordLevel
                db
                enable
                lastKeyRotation
                wlc
                shields {
                    id @export(as: "shieldId")
                    shieldInfo @rest(type: "${TYPE_NAMES.Shield}", path: "shield/{exportVariables.shieldId}", endpoint: "v1") {
                        hostName
                        state
                    }
                }
            }
            migrations
                @rest(type: "${TYPE_NAMES.MigrationStatus}", path: "application/mgrstatus/{exportVariables.id}", endpoint: "v1") {
                status
                mgrTableList
            }
        }
    }
`;

export const ADD_APPLICATION = gql`
    mutation AddApplication($input: Object!) {
        application(input: $input) @rest(type: "${TYPE_NAMES.ApplicationPost}", path: "application", method: "POST", endpoint: "v1") {
            id
        }
    }
`;

export const ENABLE_WLC = gql`
    mutation EnableWLC($id: String!, $input: Object!) {
        application(id: $id, input: $input) @rest(type: "${TYPE_NAMES.ApplicationDetails}", path: "application/enableWlc/{args.id}", method: "PUT", endpoint: "v1") {
            data
        }
    }
`;
export const DISABLE_WLC = gql`
    mutation DisableWLC($id: String!, $input: Object!) {
        application(id: $id, input: $input) @rest(type: "${TYPE_NAMES.ApplicationDetails}", path: "application/disableWlc/{args.id}", method: "PUT", endpoint: "v1") {
            data
        }
    }
`;
