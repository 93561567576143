const createLookupTable = () => {
    interface Lookup {
        [key: string]: any;
    }
    const lookup: Lookup = {};
    return {
        get: (key: string) => lookup[key],
        set: (key: string, val: any) => {
            lookup[key] = val;
        },
        copyToObject: () => JSON.parse(JSON.stringify(lookup)),
        entries: () => Object.entries(lookup),
        has: (key: string) => Boolean(lookup[key] !== undefined),
        remove: (key: string) => delete lookup[key],
    };
};

export default createLookupTable;
