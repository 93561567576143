import useAwait from '@baffle/api-client/src/useAwait';
import { Modal } from 'carbon-components-react';
import { t } from '@baffle/translate';
import { Observer } from 'mobx-react-lite';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { RbacPolicyStore } from '@baffle/manager/src/stores';

interface DeleteRbacPolicyModalProps {
    open: boolean;
    id: string;
    name: string;
    onClose?: () => void;
    onSuccess?: (name: string) => void;
}
const DeleteRbacPolicyModal = ({ open, id, name, onClose, onSuccess }: DeleteRbacPolicyModalProps) => {
    const [openModal, setOpen] = useState(open);
    const { run } = useAwait();
    const history = useHistory();

    const animateModal = () => {
        return new Promise(() => {
            setTimeout(() => {
                if (onClose) {
                    onClose();
                }
                history.push('/rbac');
            }, 200);
        });
    };

    const handleClose = async () => {
        await animateModal();
    };

    const doDeleteRbacPolicy = () => {
        run(
            RbacPolicyStore.deleteRbacPolicy(id as string).then(() => {
                handleClose();
                if (onSuccess) {
                    onSuccess(name);
                }
            })
        );
    };

    //Sync state from props
    useEffect(() => {
        setOpen(open);
    }, [open]);
    return (
        <Observer>
            {() => {
                return (
                    <Modal
                        open={openModal}
                        className="modal-container-white"
                        modalHeading={t('rbac.rbacPolicy.deleteRbacPolicyModalHeading', {
                            name: name,
                        })}
                        danger={true}
                        id="delete-rbac-policy-modal"
                        onRequestClose={onClose}
                        primaryButtonText={t('main.delete')}
                        secondaryButtonText={t('main.cancel')}
                        size="sm"
                        onRequestSubmit={doDeleteRbacPolicy}>
                        <p>{t('rbac.rbacPolicy.deleteLoggerConfirmationMsg')}</p>
                    </Modal>
                );
            }}
        </Observer>
    );
};

export default DeleteRbacPolicyModal;
