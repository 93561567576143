import { HTTP_POLL_INTERVAL } from '@baffle/utilities/src/enums/TimeEnum';
import { useEffect } from 'react';

interface PollerOpts {
    interval?: number;
    depsArray?: any[];
}

export default function usePoller(
    pollFn: () => void,
    { interval = HTTP_POLL_INTERVAL, depsArray = [] }: PollerOpts = {}
) {
    useEffect(() => {
        const poller = setInterval(() => {
            pollFn();
        }, interval);
        return () => clearInterval(poller);
    }, [pollFn, ...depsArray]);
}
