import React from 'react';
import { t } from '@baffle/translate';
import { FormGroup, RadioButtonGroup, RadioButton } from 'carbon-components-react';
import { Shield, DebugLevels } from '@baffle/graphql/src/models';
import shieldClient from '@baffle/api-client/src/shieldClient';
interface props {
    shield: Partial<Shield>;
    requestDebugLevelStarted?: () => void;
    requestDebugLevelCompleted?: () => void;
}
const ShieldLogsAction = ({
    shield,
    requestDebugLevelStarted = () => null,
    requestDebugLevelCompleted = () => null,
}: props) => {
    const allDisabled = (shield?.applications || []).length === 0 || shield.state !== 'Running';

    const requestDebugLevelHandler = async (debugLevel: number) => {
        if (!allDisabled && Boolean(shield?.debugProps?.enable)) {
            requestDebugLevelStarted();
            try {
                await shieldClient.readDebugLevel(shield.id as string, debugLevel);
            } catch (error) {
                console.log(error);
            } finally {
                requestDebugLevelCompleted();
            }
        }
    };
    return (
        <div className="flex flex-col">
            <FormGroup>
                <RadioButtonGroup
                    legend="Group Legend"
                    name="debug-level"
                    defaultSelected={shield?.debugLevel ?? 0}
                    className="flex-col items-start shield-debug-level-list"
                    onChange={(debugLevel: DebugLevels) => {
                        requestDebugLevelHandler(debugLevel);
                    }}
                    orientation="vertical">
                    <RadioButton
                        disabled={allDisabled || !Boolean(shield?.debugProps?.enable)}
                        value={0}
                        id={`debug-level-0`}
                        labelText={t(`shield.debugLevel0`)}
                    />
                    <RadioButton
                        disabled={allDisabled || !Boolean(shield?.debugProps?.enable)}
                        value={8}
                        id={`debug-level-8`}
                        labelText={t(`shield.debugLevel8`)}
                    />
                    <RadioButton
                        disabled={allDisabled || !Boolean(shield?.debugProps?.enable)}
                        value={24}
                        id={`debug-level-24`}
                        labelText={t(`shield.debugLevel24`)}
                    />
                    <RadioButton
                        disabled={allDisabled || !Boolean(shield?.debugProps?.enable)}
                        value={27}
                        id={`debug-level-27`}
                        labelText={t(`shield.debugLevel27`)}
                    />
                </RadioButtonGroup>
            </FormGroup>
        </div>
    );
};

export default ShieldLogsAction;
