export interface RoleInfo {
    id: string;
    name: string;
    effects: string[];
    dataPolicies: number;
    createdTime: string;
    createdBy: string;
}

export enum RoleEffects {
    ENCRYPT = 'encrypt',
    DECRYPT = 'decrypt',
    FPE_ENCRYPT = 'fpe-encrypt',
    FPE_DECRYPT = 'fpe-decrypt',
    MASK = 'mask',
}
