import systemClient from '@baffle/api-client/src/systemClient';
import { SystemVersionInfo } from '@baffle/graphql/src/models';
import { makeObservable, action, observable } from 'mobx';
import { RequestOpts } from '../types/StoreTypes';

class VersionStore {
    constructor() {
        makeObservable(this, {
            currentVersion: observable,
            setCurrentVersion: action,
        });
    }
    currentVersion: SystemVersionInfo | undefined = undefined;

    setCurrentVersion(currentVersion: SystemVersionInfo) {
        this.currentVersion = currentVersion;
    }
    async readCurrentVersion(opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first' && this.currentVersion) {
            return;
        }
        try {
            let currentVersion: SystemVersionInfo = await systemClient.readVersion();
            // Adding following condition as a fix for BM3-1373 as BE filters are removing x-auth-token from request for the first request
            // if (!currentUser || Object.keys(currentUser).length === 0) currentUser = await currentUserClient.read();
            this.setCurrentVersion(currentVersion);
        } catch (error) {
            throw error;
        }
    }
}

export default VersionStore;
