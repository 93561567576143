import React, { useEffect } from 'react';
import { Observer } from 'mobx-react-lite';
import Content from '@baffle/components/src/content/Content';
import { Overview } from '@baffle/components/src/overview/Overview';
import PageHeader from '@baffle/components/src/pageheader/pageheader';
import { DatabaseStore } from '@baffle/manager/src/stores';
import useAwait from '@baffle/api-client/src/useAwait';
import { useHistory, useParams } from 'react-router-dom';
import { t } from '@baffle/translate';
import { useManageModals } from '@baffle/components/src/modal/Modal';
import SpinnerOverlay from '@baffle/components/src/loader/SpinnerOverlay';
import databaseClient from '@baffle/api-client/src/databaseClientV2';
import EditDatabaseModal from './EditDatabaseModal';
import DeleteDatabaseModal from './DeleteDatabaseModal';
import RenameDatabaseModal from './RenameDatabaseModal';
import { NotificationStore } from '../../stores/NotificationStore';

const DatabaseDetails = () => {
    let { id } = useParams() as { id: string };
    const history = useHistory();
    if (!id) {
        history.push('/databasesV2');
        return null;
    }

    const { run, error, status } = useAwait();

    useEffect(() => {
        run(DatabaseStore.readDatabase(id));
    }, []);

    useEffect(() => {
        if (error) {
            NotificationStore.push({ title: error.message, kind: 'error' });
        }
    }, [error]);

    return (
        <Observer>
            {() => {
                const databaseDetails = DatabaseStore.database;

                interface DatabaseModals {
                    editDatabase: boolean;
                    deleteDatabase: boolean;
                    renameDatabase: boolean;
                }

                const initialModalState = {
                    editDatabase: false,
                    deleteDatabase: false,
                    renameDatabase: false,
                };
                const [modals, setModals] = useManageModals<DatabaseModals>(initialModalState);

                useEffect(() => {
                    if (error) {
                        setModals({ deleteDatabase: false, editDatabase: false, renameDatabase: false });
                        NotificationStore.push({ title: error.message, kind: 'error' });
                    }
                }, [error]);

                const deleteDatabase = () => {
                    run(
                        databaseClient.deleteDatabase(DatabaseStore.database?.id as string).then(() => {
                            DatabaseStore.readDatabases().then(() => {
                                setModals({ deleteDatabase: false });
                                NotificationStore.push({
                                    title: t('database.deleteSuccess', { name: DatabaseStore.database?.name }),
                                    kind: 'success',
                                });
                                history.push('/databasesV2');
                            });
                        })
                    );
                };

                const renameDatabase = (name: string) => {
                    run(
                        DatabaseStore.renameDatabase(DatabaseStore.database?.id as string, { newName: name }).then(
                            () => {
                                setModals({ renameDatabase: false });
                                NotificationStore.push({
                                    title: t('database.renameSuccess', { name }),
                                    kind: 'success',
                                });
                            }
                        )
                    );
                };

                const actions = [
                    {
                        id: '1',
                        label: t('database.rename'),
                    },
                    {
                        id: '2',
                        label: t('main.edit'),
                    },
                    {
                        id: '3',
                        label: t('main.delete'),
                    },
                ];

                const breadcrumbData = [
                    {
                        label: t('main.databases'),
                        href: '/databasesV2',
                    },
                ];

                const onSelectAction = (item: any) => {
                    switch (item.selectedItem.label) {
                        case t('database.rename'):
                            setModals({ renameDatabase: true });
                            break;
                        case t('main.edit'):
                            setModals({ editDatabase: true });
                            break;
                        case t('main.delete'):
                            setModals({ deleteDatabase: true });
                            break;
                        default:
                            break;
                    }
                };

                return (
                    <>
                        <Content>
                            {(status === 'loading' || status === 'idle') && <SpinnerOverlay description="" />}

                            {modals.renameDatabase ? (
                                <RenameDatabaseModal
                                    open={modals.renameDatabase}
                                    onClose={() => setModals({ renameDatabase: false })}
                                    onSubmit={renameDatabase}
                                />
                            ) : null}

                            {modals.editDatabase ? (
                                <EditDatabaseModal
                                    open={modals.editDatabase}
                                    onClose={() => setModals({ editDatabase: false })}
                                />
                            ) : null}

                            {modals.deleteDatabase ? (
                                <DeleteDatabaseModal
                                    open={modals.deleteDatabase}
                                    onClose={() => setModals({ deleteDatabase: false })}
                                    onSubmit={deleteDatabase}
                                />
                            ) : null}

                            {databaseDetails && (
                                <>
                                    <PageHeader
                                        title={databaseDetails.name}
                                        actions={actions}
                                        breadcrumbData={breadcrumbData}
                                        onActionSelect={onSelectAction}
                                    />
                                    <div className="px-8 mt-4 text-sm">
                                        <div
                                            className={
                                                'pb-2 w-40 border-solid border-b-2 border-blue-50 cursor-pointer'
                                            }>
                                            <div className="ml-4">{t('globalPolicies.rbacUserGroups.overview')}</div>
                                        </div>
                                    </div>

                                    <Overview
                                        title={t('database.dbDetailsTitle')}
                                        info={DatabaseStore.getDbOverviewPayload(databaseDetails)}
                                        topic="databaseDetail"
                                    />
                                </>
                            )}
                        </Content>
                    </>
                );
            }}
        </Observer>
    );
};

export default DatabaseDetails;
