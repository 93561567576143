import React, { useEffect, useMemo, useState } from 'react';
import { ENABLE_WLC, DISABLE_WLC } from '@baffle/graphql';
import { useMutation } from '@apollo/react-hooks';
import RightPanel, { PanelModule, PanelTitle, TwoColTableModule } from '@baffle/components/src/content/RightPanel';
import CreateIcon from '@material-ui/icons/CreateOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Toggle, ToggleSkeleton } from 'carbon-components-react';
import { t } from '@baffle/translate';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import {
    ShieldStatusThemeEnum,
    ConfigFileNameEnum,
    ApplicationV2,
    ShieldForApplicationDetails,
} from '@baffle/graphql/src/models';
import './ApplicationSidePanel.scss';
import { useHistory } from 'react-router';
import useFlush from '@baffle/utilities/src/useFlush';
import { ToastState } from '@baffle/components/src/toasts/Toast';
import ShieldErrors from '../shields/ShieldErrors';
import applicationClient from '@baffle/api-client/src/applicationClient';
import { Observer } from 'mobx-react-lite';
import { AgentErrorStore, ApplicationStore, FeatureFlagStore } from '../stores';
import { format } from '@baffle/utilities/src/date';
import useAwait from '@baffle/api-client/src/useAwait';
import Badge, { getDefaultBadgeProps } from '@baffle/components/src/badges/Badge';
import Divider from '@baffle/components/src/content/Divider';
import Button, { getDefaultButtonProps } from '@baffle/components/src/buttons/Button';
import SkeletonText, { SkeletonParagraph } from '@baffle/components/src/skeleton/SkeletonText';
import ApplicationButtonActions from '../application/ApplicationButtonActions';
import usePoller from '@baffle/api-client/src/usePoller';
import TextInput, { getDefaultInputProps } from '@baffle/components/src/forms/TextInput';
import Joi from '@hapi/joi';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import cx from 'classnames';
import RefreshIcon from '@material-ui/icons/Refresh';
import RefreshSyncIdModal from '@baffle/manager/src/application/RefreshSyncIdModal';

interface ApplicationSidePanelProps {
    appId: string | null | undefined;
    setToast?: (state: ToastState) => void;
    onClose?: () => void;
    deleteApplication?: (args: { id: string; name: string; force?: boolean }) => void;
    onCloseImmediate?: () => void;
    doRefetch?: () => void;
    triggerDismissAllNotifications?: (appId: string) => void;
    doRemoveShieldFromApplication: ({
        application,
        shield,
    }: {
        application: ApplicationV2;
        shield: ShieldForApplicationDetails;
    }) => void;
}

const ApplicationSidePanel = ({
    appId,
    setToast = (state: ToastState) => null,
    onClose = () => null,
    deleteApplication = () => null,
    onCloseImmediate = () => null,
    doRefetch = () => null,
    doRemoveShieldFromApplication,
    triggerDismissAllNotifications = (appId = '') => null,
}: ApplicationSidePanelProps) => {
    const history = useHistory();
    const { run, status } = useAwait();
    const [refreshSyncModal, setRefreshSyncModal] = useState(false);
    const refetch = async ({ skipStatus }: { skipStatus?: boolean } = { skipStatus: false }) => {
        if (appId) {
            run(AgentErrorStore.list({ appId }), {
                skipStatus,
            });
            doRefetch();
        }
    };
    usePoller(
        () => {
            if (appId) {
                AgentErrorStore.list({ appId });
            }
        },
        { depsArray: [appId] }
    );

    useEffect(() => {
        if (appId) {
            refetch();
        }
    }, [appId]);

    if (!appId) {
        return null;
    }

    return (
        <Observer>
            {() => {
                const application = ApplicationStore.getV2(appId);
                if (!application) {
                    return <div />;
                }

                useEffect(() => {
                    inputActions.setField('filterMode', application?.configuration.filterMode ?? 0);
                }, [appId]);
                const { inputState, inputActions, inputErrors } = useInputState({
                    keys: ['filterMode'],
                    ns: 'application',
                    validationSchema: {
                        filterMode: Joi.number()
                            .integer()
                            .allow(0)
                            .greater(0)
                            .messages({
                                'number.base': t('application.filterModeInvalidError'),
                                'number.integer': t('application.filterModeInvalidError'),
                                'number.greater': t('application.filterModeInvalidError'),
                            }),
                    },
                });
                const isValid = inputActions.validateAll(true);

                const [showSave, setShowSave] = useState(false);

                const agentErrors = AgentErrorStore.get({ appId });
                const [enableWlc] = useMutation(ENABLE_WLC);
                const [disableWlc] = useMutation(DISABLE_WLC);

                //Hack to clear the toggle state when the user clicks on another application
                const flushToggle = useFlush(application.id);

                const shieldsAreRunning = useMemo(
                    () =>
                        application.shieldDetails.total > 0 &&
                        application.shieldDetails.running === application.shieldDetails.total,
                    [application]
                );
                const canToggleWlc = Boolean(application.migrationDetails.status === 'NA' && shieldsAreRunning);
                const configMenuItems = [
                    {
                        item: (
                            <div className="flex items-center">
                                <CreateIcon className="w-4 h-4 mr-1" /> {t('application.editConfig')}
                            </div>
                        ),
                        onClick: () => {
                            history.push(`/application/configuration/${application.id}/${ConfigFileNameEnum.BPS}`);
                        },
                    },
                    {
                        item: (
                            <div className="config-menu-refresh-sync flex items-center">
                                <RefreshIcon className="mr-1 w-4 h-4" /> {t('application.refreshSyncId')}
                            </div>
                        ),
                        onClick: () => {
                            setRefreshSyncModal(true);
                        },
                    },
                    {
                        item: (
                            <div
                                className="config-menu-delete flex items-center"
                                data-testid="application-delete-menu-item">
                                <DeleteOutlineIcon className="w-4 h-4 mr-1" /> {t('application.delete')}
                            </div>
                        ),
                        onClick: () => {
                            deleteApplication({ id: application?.id as string, name: application.name });
                        },
                    },
                ];
                if (FeatureFlagStore.forceDelete) {
                    configMenuItems.push({
                        item: (
                            <div
                                className="config-menu-delete flex items-center"
                                data-testid="application-force-delete-menu-item">
                                <DeleteForeverIcon /> {t('application.forceDelete')}
                            </div>
                        ),
                        onClick: () => {
                            deleteApplication({
                                id: application?.id as string,
                                name: application.name as string,
                                force: true,
                            });
                        },
                    });
                }
                return (
                    <>
                        <RightPanel
                            data-testid="application-sidepanel"
                            skeleton={status === 'idle' || status === 'loading'}
                            title={application.name ?? ''}
                            onClose={onClose}
                            configMenuItems={configMenuItems}
                            onCloseImmediate={onCloseImmediate}>
                            <PanelModule>
                                <div className="flex items-center justify-left">
                                    <div className="enc-action w-full">
                                        <ApplicationButtonActions
                                            application={application}
                                            loading={status === 'idle' || status === 'loading'}
                                        />
                                    </div>
                                </div>
                            </PanelModule>
                            <PanelModule title={t('main.details')} data-testid="application-details">
                                {status === 'idle' || status === 'loading' ? (
                                    <SkeletonText />
                                ) : (
                                    <TwoColTableModule
                                        rows={[
                                            {
                                                'data-testid': 'application-added-on-text',
                                                cols: [
                                                    t('main.addedOn'),
                                                    format(application?.createTime, 'yyyy-M-d H:mm:ss'),
                                                ],
                                            },
                                            {
                                                'data-testid': 'application-created-by-text',
                                                cols: [t('main.createdBy'), application?.createdBy],
                                            },
                                        ]}
                                    />
                                )}
                            </PanelModule>
                            <PanelModule
                                title={t('main.description')}
                                data-testid="application-description"
                                description={
                                    status === 'idle' || status === 'loading' ? <SkeletonText /> : application.desc
                                }
                            />
                            <PanelModule
                                title={t('main.shieldSyncID')}
                                data-testid="application-shieldSyncID"
                                description={
                                    status === 'idle' || status === 'loading' ? <SkeletonText /> : application.syncId
                                }
                            />
                            <PanelModule
                                title={t('application.encryptionDetails')}
                                data-testid="application-encryption-details">
                                {status === 'idle' || status === 'loading' ? (
                                    <SkeletonParagraph />
                                ) : (
                                    <TwoColTableModule
                                        rows={[
                                            {
                                                'data-testid': 'application-recordLevel-text',
                                                cols: [t('application.header4'), application.encType],
                                            },
                                            {
                                                'data-testid': 'application-mode-text',
                                                cols: [t('application.header5'), application.encMode],
                                            },
                                            {
                                                'data-testid': 'application-dbName-text',
                                                cols: [t('database.header2'), application.databaseDetails.name],
                                            },
                                            {
                                                'data-testid': 'application-keystore-text',
                                                cols: [t('keystore.keystore'), application.keystoreDetails.name],
                                            },
                                            {
                                                'data-testid': 'application-keyLength-text',
                                                cols: [t('application.keyLength'), application?.keyLength],
                                            },
                                        ]}
                                    />
                                )}
                            </PanelModule>
                            {FeatureFlagStore.advancedConfiguration ? (
                                <PanelModule title={t('main.advancedConfig')} data-testid="application-advanced-config">
                                    <div className="flex flex-col items-left justify-around">
                                        <div className="wlc mt-2">
                                            {status === 'idle' || status === 'loading' || flushToggle ? (
                                                <ToggleSkeleton />
                                            ) : (
                                                <Toggle
                                                    className="toggle_override"
                                                    aria-label={t('application.ariaWlcInputLabel')}
                                                    labelText={t('application.wlcInputLabel')}
                                                    toggled={application.configuration.wlc}
                                                    disabled={canToggleWlc == true ? false : true}
                                                    id="application-wlc-input-sidepanel"
                                                    labelA={t('application.off')}
                                                    labelB={t('application.on')}
                                                    onChange={() => {}}
                                                    onToggle={async (val: boolean) => {
                                                        if (val) {
                                                            try {
                                                                await enableWlc({
                                                                    variables: { id: application.id, input: {} },
                                                                });
                                                                await refetch({ skipStatus: true });
                                                                setToast({
                                                                    show: true,
                                                                    type: 'success',
                                                                    title: t('application.enableWlcSuccess', {
                                                                        name: application.name,
                                                                    }),
                                                                });
                                                            } catch (error) {
                                                                setToast({
                                                                    show: true,
                                                                    type: 'danger',
                                                                    title: t('application.wlcError', {
                                                                        name: application.name,
                                                                    }),
                                                                });
                                                            }
                                                        } else {
                                                            try {
                                                                await disableWlc({
                                                                    variables: { id: application.id, input: {} },
                                                                });
                                                                await refetch({ skipStatus: true });
                                                                setToast({
                                                                    show: true,
                                                                    type: 'success',
                                                                    title: t('application.disableWlcSuccess', {
                                                                        name: application.name,
                                                                    }),
                                                                });
                                                            } catch (error) {
                                                                setToast({
                                                                    show: true,
                                                                    type: 'danger',
                                                                    title: t('application.wlcError', {
                                                                        name: application.name,
                                                                    }),
                                                                });
                                                            }
                                                        }
                                                    }}
                                                />
                                            )}
                                        </div>

                                        <div className="filtermode my-4">
                                            {status === 'idle' || status === 'loading' ? (
                                                <ToggleSkeleton />
                                            ) : (
                                                <div
                                                    className={cx('flex w-18 justify-start', {
                                                        'w-44': showSave,
                                                    })}>
                                                    <div className="w-18">
                                                        <TextInput
                                                            id="filter-mode-input"
                                                            name="filter-mode-input"
                                                            data-testid="filter-mode-input"
                                                            labelText={t('application.filterModeInputLabel')}
                                                            invalid={Boolean(inputErrors.filterMode)}
                                                            invalidText={inputErrors.filterMode}
                                                            value={inputState.filterMode}
                                                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                inputActions.validateField({
                                                                    field: 'filterMode',
                                                                    skipNull: true,
                                                                });
                                                            }}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                const val = e.target.value || '';
                                                                inputActions.setField('filterMode', val.trim());
                                                                setShowSave(true);
                                                            }}
                                                            disabled={!shieldsAreRunning}
                                                            getInputProps={() => {
                                                                return {
                                                                    className: cx(
                                                                        getDefaultInputProps().className,
                                                                        'h-8'
                                                                    ),
                                                                };
                                                            }}
                                                        />
                                                    </div>
                                                    {showSave ? (
                                                        <Button
                                                            theme="primary"
                                                            className="border-none py-2 font-bold ml-2 text-xs uppercase h-8 w-18 mt-5"
                                                            id="save-filter-mode-btn"
                                                            disabled={!isValid || !shieldsAreRunning}
                                                            onClick={async () => {
                                                                try {
                                                                    await applicationClient.update(
                                                                        application?.id as string,
                                                                        {
                                                                            filterMode: +(inputState.filterMode as string),
                                                                        }
                                                                    );
                                                                    await refetch({ skipStatus: true });
                                                                    setShowSave(false);
                                                                    setToast({
                                                                        show: true,
                                                                        type: 'success',
                                                                        title: t('application.filterModeSuccess', {
                                                                            name: application.name,
                                                                        }),
                                                                    });
                                                                } catch (error) {
                                                                    setToast({
                                                                        show: true,
                                                                        type: 'danger',
                                                                        title: t('application.filterModeFailure', {
                                                                            name: application.name,
                                                                        }),
                                                                    });
                                                                }
                                                            }}>
                                                            {t('main.save')}
                                                        </Button>
                                                    ) : null}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </PanelModule>
                            ) : null}
                            <PanelModule data-testid="application-baffleshields">
                                <PanelTitle>
                                    {`${t('application.baffleshields')} (${application.shieldDetails.total})`}{' '}
                                </PanelTitle>
                                <div className="flex flex-wrap mt-4">
                                    {application.shieldDetails.shields.map((shield, i) => {
                                        return (
                                            <div
                                                key={shield.id + '_applictionsidepanel'}
                                                data-testid="application-shield-item"
                                                className="flex flex-wrap min-w-0">
                                                <div className="flex min-w-0">
                                                    {/* min-w-0 allows text to be truncated in flex layout */}
                                                    <div className="flex flex-wrap min-w-0">
                                                        <div className="flex mb-2 w-full min-w-0">
                                                            <div className="text-gray-600 mr-2 w-20">
                                                                {t('shield.shieldName')}:{' '}
                                                            </div>
                                                            <div
                                                                title={shield.name}
                                                                data-testid={`${i}-application-shield-name`}
                                                                className="truncate flex-1">
                                                                {shield.name}
                                                            </div>
                                                        </div>
                                                        {shield?.tag ? (
                                                            <div
                                                                className="flex mb-2 w-full"
                                                                id="div-shield-tag-section">
                                                                <div className="text-gray-600 mr-2 w-20">
                                                                    {t('shield.tag')}:{' '}
                                                                </div>
                                                                <div
                                                                    title={shield?.tag}
                                                                    data-testid={`${i}-application-shield-tag`}
                                                                    className="truncate flex-1">
                                                                    {shield.tag}
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                        <div className="flex mb-2 w-full">
                                                            <div className="text-gray-600 mr-2 w-20">
                                                                {t('shield.ipHost')}:{' '}
                                                            </div>
                                                            <div
                                                                title={shield.host}
                                                                className="truncate flex-1"
                                                                data-testid={`${i}-application-shield-host`}>
                                                                {shield.host}
                                                            </div>
                                                        </div>
                                                        <div className="flex mb-2 w-full">
                                                            <div
                                                                className="text-gray-600 mr-2 w-20"
                                                                data-testid={`${i}-application-shield-port`}>
                                                                {t('shield.port')}:{' '}
                                                            </div>
                                                            <div
                                                                title={shield?.port?.toString()}
                                                                className="truncate flex-1">
                                                                {shield.port}
                                                            </div>
                                                        </div>
                                                        <div className="flex items-center w-full">
                                                            <div
                                                                className="text-gray-600 mr-2 w-20"
                                                                data-testid={`${i}-application-shield-status`}>
                                                                {t('shield.status')}:{' '}
                                                            </div>
                                                            <div
                                                                className="flex items-center"
                                                                data-testid={`${i}-application-shield-status`}>
                                                                <Badge
                                                                    getBadgeProps={() => {
                                                                        const badgeProps = {
                                                                            ...getDefaultBadgeProps(),
                                                                            className:
                                                                                'inline-flex items-center px-3 py-1 text-xs',
                                                                        };
                                                                        return badgeProps;
                                                                    }}
                                                                    theme={ShieldStatusThemeEnum[shield.status]}>
                                                                    {shield.status}
                                                                </Badge>
                                                                <div
                                                                    className="ml-2"
                                                                    data-testid={`${i}-application-migration-shield`}>
                                                                    {application.migrationDetails.mgrShieldId ===
                                                                    shield.id
                                                                        ? t('shield.migration')
                                                                        : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {application.shieldDetails.total > 1 ? (
                                                        <div>
                                                            <Button
                                                                theme="icon-transparent"
                                                                onClick={() => {
                                                                    doRemoveShieldFromApplication({
                                                                        application: application,
                                                                        shield,
                                                                    });
                                                                }}
                                                                getButtonProps={() => {
                                                                    const btnProps = {
                                                                        ...getDefaultButtonProps(),
                                                                        'data-testid':
                                                                            'toggle-remove-shield-from-application-btn',
                                                                        className:
                                                                            'hover:text-red-60 focus:text-red-60',
                                                                        'aria-label': t('application.removeShield'),
                                                                        title: t('application.removeShieldBtnTitle', {
                                                                            name: shield.name,
                                                                        }),
                                                                    };
                                                                    return btnProps;
                                                                }}
                                                                size="none">
                                                                <RemoveCircleOutlineIcon className="text-base" />
                                                            </Button>
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {i !== application.shieldDetails.total - 1 ? (
                                                    <Divider classNames="my-2" />
                                                ) : null}
                                            </div>
                                        );
                                    })}
                                </div>
                                {/* //add shield here */}
                            </PanelModule>
                            <PanelModule>
                                <PanelTitle>
                                    {`${t('shield.alerts')} (${agentErrors.length})`}
                                    <Button
                                        theme="red-link"
                                        className="pr-0 text-xs"
                                        data-testid="asp-dismiss-all-shield-errors-btn"
                                        onClick={() => {
                                            triggerDismissAllNotifications(application.id);
                                        }}>
                                        {t('shield.clearAll')}
                                    </Button>
                                </PanelTitle>
                                {status === 'loading' || status === 'idle' ? (
                                    <SkeletonParagraph />
                                ) : (
                                    <div className="overflow-y-scroll">
                                        <ShieldErrors
                                            idPrefix="application-sidepanel"
                                            didClearNotification={() => refetch({ skipStatus: true })}
                                            agentErrors={agentErrors}
                                        />
                                    </div>
                                )}
                            </PanelModule>
                        </RightPanel>
                        <RefreshSyncIdModal
                            appId={appId}
                            onSuccess={() => {
                                onClose();
                                setToast({
                                    show: true,
                                    type: 'success',
                                    title: t('application.refreshSyncIdSucces'),
                                });
                            }}
                            open={refreshSyncModal}
                            onClose={() => {
                                setRefreshSyncModal(false);
                            }}
                        />
                    </>
                );
            }}
        </Observer>
    );
};

export default ApplicationSidePanel;
