import React from 'react';
import { t } from '@baffle/translate';
import {
    getDefaultRootModalProps,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '@baffle/components/src/modal/Modal';
import Button from '@baffle/components/src/buttons/Button';
import debounce from 'lodash.debounce';
import WarningIcon from '@material-ui/icons/Warning';

interface ModalPropsWithoutChildren {
    'aria-labelledby'?: string;
    open: boolean;
    onClose?: () => void;
    doClearFields: () => void;
}

const WarningModal = ({ open, onClose = () => null, doClearFields = () => null }: ModalPropsWithoutChildren) => {
    const initiateClear = () => {
        doClearFields();
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            getRootModalProps={() => {
                return {
                    ...getDefaultRootModalProps(),
                };
            }}>
            <DecryptonConfirmation initiateClear={initiateClear} handleClose={handleClose} />
        </Modal>
    );
};

interface DecryptonConfirmation {
    handleClose: () => void;
    initiateClear: () => void;
}
const DecryptonConfirmation = ({ initiateClear, handleClose }: DecryptonConfirmation) => {
    return (
        <>
            <ModalHeader onClose={handleClose}>
                <div
                    className="mt-0 ml-2 text-red text-3xl flex flex-col items-center justify-center"
                    id="modal-headline">
                    <WarningIcon classes={{ root: 'text-2xl' }} />
                    <p className="mt-1 text-2xl font-semibold">{t('encryption.modeLibrary.jsonPolicy.warning')}</p>
                </div>
            </ModalHeader>
            <ModalBody>
                <div className='flex-col justify-center items-center"'>
                    <p className="text-center text-lg text-red">{t('encryption.modeLibrary.jsonPolicy.warningCopy')}</p>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex justify-end items-center w-full h-20">
                    <Button
                        theme="secondary"
                        className="h-full w-1/2"
                        onClick={handleClose}
                        data-testid="modal-cancel-btn"
                        size="lg">
                        {t('main.cancel')}
                    </Button>
                    <Button
                        theme="danger"
                        data-testid="modal-confirm-clear-btn"
                        className="w-1/2 h-full"
                        onClick={debounce(() => initiateClear(), 300)}
                        size="lg">
                        {t('encryption.modeLibrary.jsonPolicy.confirm')}
                    </Button>
                </div>
            </ModalFooter>
        </>
    );
};

export default WarningModal;
