import { makeAutoObservable } from 'mobx';

class ElementFocusStore {
    previousElement: EventTarget | null = null;
    currentElement: EventTarget | null = null;

    constructor() {
        makeAutoObservable(this);
        document.addEventListener('focusin', e => {
            this.setCurrentElement(e.target);
        });
        document.addEventListener('focusout', e => {
            this.setPreviousElement(e.target);
        });
    }
    setCurrentElement(target: EventTarget | null) {
        this.currentElement = target;
    }
    setPreviousElement(target: EventTarget | null) {
        this.previousElement = target;
    }
}

export default ElementFocusStore;
