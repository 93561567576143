import { useEffect } from 'react';

const useMouseClick = (cb: () => void) => {
    const onEscape = (e: MouseEvent) => {
        if (e.type === 'click') {
            cb();
        }
    };
    useEffect(() => {
        document.addEventListener('click', onEscape);
        return () => {
            document.removeEventListener('click', onEscape);
        };
    });
};

export default useMouseClick;
