import React from 'react';
import { t } from '@baffle/translate';
import { Manager, Reference, Popper } from '@baffle/components/src/poppers/Popover';
import NewHelpIcon from '@baffle/components/src/icons/NewHelpIcon';
import ReactDOM from 'react-dom';
import cx from 'classnames';

interface RbacConfigModeHelpProps {
    onRefClick: () => void;
    showHelp: { showModeHelp: boolean; showDeterminationHelp: boolean };
    customErrMsg: string;
    validationError: number;
}

const RbacConfigModeHelp = ({ onRefClick, showHelp, customErrMsg, validationError }: RbacConfigModeHelpProps) => {
    return (
        <Manager>
            <Reference>
                {({ ref }) => (
                    <span
                        id="help-mode"
                        ref={ref}
                        onClick={() => onRefClick()}
                        className="input-char-counter right-0 cursor-pointer">
                        <NewHelpIcon />
                    </span>
                )}
            </Reference>
            {showHelp.showModeHelp
                ? ReactDOM.createPortal(
                      <Popper placement="right" eventsEnabled={false}>
                          {({ ref, style, placement, arrowProps }) => (
                              <div
                                  ref={ref}
                                  style={style}
                                  className={cx(
                                      'bg-white border-solid border border-gray-300 shadow-rbacHelp p-2 rounded-sm baffle-popover z-10 text-left cursor-auto top-1 left-4 w-92',
                                      {
                                          'top-18': Boolean(customErrMsg || validationError),
                                      }
                                  )}
                                  data-placement="right">
                                  <p className="text-xs">{t('encryption.modeLibrary.rbac.rbacConfig.modeHelpCopy1')}</p>
                                  <ul className="list-disc ml-4">
                                      <li>
                                          <p
                                              className="text-xs"
                                              dangerouslySetInnerHTML={{
                                                  __html: t('encryption.modeLibrary.rbac.rbacConfig.modeHelpCopyList1'),
                                              }}></p>
                                      </li>
                                      <li>
                                          <p className="text-xs">
                                              {t('encryption.modeLibrary.rbac.rbacConfig.modeHelpCopyList2')}
                                          </p>
                                      </li>
                                      <li>
                                          <p className="text-xs">
                                              {t('encryption.modeLibrary.rbac.rbacConfig.modeHelpCopyList3')}
                                          </p>
                                      </li>
                                  </ul>
                                  <br />
                                  <div
                                      ref={arrowProps.ref}
                                      style={Object.assign({}, arrowProps.style, {
                                          top: '12px',
                                      })}
                                      data-placement={'right'}
                                      className="popover-arrow"
                                  />
                              </div>
                          )}
                      </Popper>,
                      document.getElementById('help-mode') as HTMLElement
                  )
                : null}
        </Manager>
    );
};

export default RbacConfigModeHelp;
