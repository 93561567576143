import React, { useState, useEffect } from 'react';
import { AriaLabelPropType } from '../componentTypes';
import cx from 'classnames';

interface NavButtonProps extends AriaLabelPropType {
    color?: string;
    activeColor?: string;
    onClick: () => void;
    hoverColor?: string;
    children: any;
    btnClass?: string;
    //to hardset a nav item
    override?: boolean;
    'data-testid'?: string;
}
const NavLinkIcon = ({
    color = '#c6c6c6',
    activeColor = '#78a9ff',
    hoverColor = '#fff',
    onClick,
    children,
    override = false,
    btnClass = '',
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    'data-testid': dataTestId = '',
    ...rest
}: NavButtonProps) => {
    const [c, setColor] = useState(color);
    const [hover, setHover] = useState(false);
    const isMatch = color === activeColor;
    useEffect(() => {
        if (isMatch) {
            setColor(activeColor);
        }
        if (!hover && !isMatch) {
            setColor(color);
        }
        if (hover && !isMatch) {
            setColor(hoverColor);
        }
    }, [color, hover]);

    const childrenWithProps = React.Children.map(children, (child: React.ReactElement) =>
        React.cloneElement(child, {
            color: c,
            className: cx(
                child.props.className ?? '',
                'focus:text-white',
                !override
                    ? {
                          'text-blue-40': isMatch,
                          'text-gray-400': !isMatch && !hover,
                          'text-white': !isMatch && hover,
                      }
                    : null
            ),
        })
    );
    return (
        <button
            data-testid={dataTestId}
            aria-label={ariaLabel}
            aria-labelledby={ariaLabelledBy}
            onFocus={() => {
                setHover(true);
            }}
            onBlur={() => {
                setHover(false);
            }}
            onMouseOver={() => {
                setHover(true);
            }}
            onMouseOut={() => {
                setHover(false);
            }}
            onClick={onClick}
            className={cx(
                'group flex items-center px-2 py-2 text-sm font-medium rounded-md anchor-button outline-none',
                btnClass
            )}
            {...rest}>
            {childrenWithProps}
        </button>
    );
};

export default NavLinkIcon;
