import React, { useEffect, useMemo, useState } from 'react';
import { Observer } from 'mobx-react-lite';
import Content from '@baffle/components/src/content/Content';
import { t } from '@baffle/translate';
import { TextInput, Button, Select, SelectItem, Link } from 'carbon-components-react';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import {
    DataProtectionItem,
    DatatypeGroup,
    RbacPolicyDefaultPermission,
    RbacPolicyRuleV2,
    RbacPolicyV2Payload,
} from '@baffle/graphql/src/models';
import DataGrid from '@baffle/components/src/grid/data-grid';
import { useHistory } from 'react-router-dom';
import useAwait from '@baffle/api-client/src/useAwait';
import rbacPolicyClient from '@baffle/api-client/src/rbacPolicyClient';
import PageHeader from '@baffle/components/src/pageheader/pageheader';
import RbacPolicyInputScheme from './RbacPolicyInputScheme';
import { MaskingPolicyStore, RbacPolicyStore } from '@baffle/manager/src/stores';
import usePageHeight from '@baffle/utilities/src/usePageHeight';
import { NotificationStore } from '@baffle/manager/src/stores/NotificationStore';
import { useManageModals } from '@baffle/components/src/modal/Modal';
import RbacPolicyRulesModal from './RbacPolicyRulesModal';
import DeleteRbacPolicyRuleModal from './DeleteRbacPolicyRuleModal';

const CreateRbacPolicy = () => {
    const headers = [
        {
            key: 'header1',
            header: t('rbac.rbacPolicy.rulesHeader1'),
            sortable: false,
        },
        {
            key: 'header2',
            header: t('rbac.rbacPolicy.rulesHeader2'),
            sortable: false,
        },
        {
            key: 'header3',
            header: t('rbac.rbacPolicy.rulesHeader3'),
            sortable: false,
        },
        {
            key: 'header4',
            header: t('rbac.rbacPolicy.rulesHeader4'),
            sortable: false,
        },
    ];
    return (
        <Observer>
            {() => {
                const history = useHistory();
                const { run } = useAwait();
                const pageHeight = usePageHeight() - 194;
                const [deleteRule, setDeleteRule] = useState({ name: '', index: 0 });
                const rbacPolicyInputSchemes = useMemo(
                    () => RbacPolicyInputScheme(RbacPolicyStore.rbacPolicies, RbacPolicyStore.selectedRbacPolicy?.id),
                    [RbacPolicyStore.rbacPolicies, RbacPolicyStore.selectedRbacPolicy?.id]
                );

                interface RbacPolicyRulesModals {
                    addRbacPolicyRule: boolean;
                    editRbacPolicyRule: boolean;
                    deleteRbacPolicyRule: boolean;
                }

                const initialModalState = {
                    addRbacPolicyRule: false,
                    editRbacPolicyRule: false,
                    deleteRbacPolicyRule: false,
                };

                const permissionItems = Object.keys(RbacPolicyDefaultPermission).map(key => ({
                    id: key,
                    label: key,
                    'data-testid': `policy-permission-li-${key}`,
                }));
                //@ts-ignore
                const { inputState, inputErrors, inputActions } = useInputState(rbacPolicyInputSchemes);
                const isValid = inputActions.validateAll(true);
                const [modals, setModals] = useManageModals<RbacPolicyRulesModals>(initialModalState);

                const maskModeItems = MaskingPolicyStore.maskingPolicies.map(mode => ({
                    id: mode.id,
                    label: mode.name,
                    datatypeGroup: mode.datatypeGroup,
                    'data-testid': `policy-mask-li-${mode.name}`,
                }));

                const datatypeGroupItems = Object.keys(DatatypeGroup).map(key => ({
                    id: key,
                    label: key,
                    'data-testid': `datatype-li-${key}`,
                }));

                useEffect(() => {
                    run(MaskingPolicyStore.getMaskingPolicies());
                    run(RbacPolicyStore.readRbacPolicies());
                    RbacPolicyStore.setRbacPolicyRules([]);
                }, []);

                const rbacPolicyRules: RbacPolicyRuleV2[] = RbacPolicyStore.rbacPolicyRules;

                const rows =
                    rbacPolicyRules.length == 0
                        ? []
                        : rbacPolicyRules.map((rule: RbacPolicyRuleV2, i: number) => {
                              return {
                                  id: (rule.order + rule.name) as string,
                                  header1: rule.name,
                                  header2: rule.userGroups?.map((userGroup: DataProtectionItem, i: number) => {
                                      return userGroup.name + ' ';
                                  }),
                                  header3: rule.permission,
                                  header4: (
                                      <Link target="_blank" href={'/masking-policies/' + rule.maskPolicy?.id}>
                                          {rule.maskPolicy?.name}
                                      </Link>
                                  ),
                                  overFlowMenu: [
                                      {
                                          name: t('rbac.rbacPolicy.moveToTop'),
                                          onClick: () => {
                                              RbacPolicyStore.moveTopRbacRule(i);
                                              const rule = policyRules[i];
                                              policyRules.splice(i, 1);
                                              policyRules.unshift(rule);
                                          },
                                      },
                                      {
                                          name: t('rbac.rbacPolicy.moveUp'),
                                          onClick: () => {
                                              RbacPolicyStore.moveUpRbacRule(i);
                                              if (i != 0) {
                                                  const rule = policyRules[i - 1];
                                                  policyRules[i - 1] = policyRules[i];
                                                  policyRules[i] = rule;
                                              }
                                          },
                                      },
                                      {
                                          name: t('rbac.rbacPolicy.moveDown'),
                                          onClick: () => {
                                              RbacPolicyStore.moveDownRbacRule(i);
                                              if (i != policyRules.length - 1) {
                                                  const rule = policyRules[i];
                                                  policyRules[i] = policyRules[i + 1];
                                                  policyRules[i + 1] = rule;
                                              }
                                          },
                                      },
                                      {
                                          name: t('rbac.rbacPolicy.moveToBottom'),
                                          onClick: () => {
                                              RbacPolicyStore.moveBottomRbacRule(i);
                                              policyRules.push(policyRules.splice(i, 1)[0]);
                                          },
                                      },
                                      {
                                          name: t('main.delete'),
                                          onClick: () => {
                                              setDeleteRule({ name: rule.name, index: i as number });
                                              setModals({ deleteRbacPolicyRule: true });
                                          },
                                      },
                                  ],
                              };
                          });

                const policyRules = (inputState.rules as Array<RbacPolicyRuleV2>) ?? [];

                const onAdd = () => {
                    const newRule: RbacPolicyRuleV2 = {
                        name: '',
                        order: policyRules.length + 1,
                        permission: '',
                        maskPolicy: null,
                        userGroups: [],
                        rbacPolicyId: '',
                        id: '',
                        associations: [],
                        audit: { createdBy: '', createdDate: 0, lastModifiedBy: '', lastModifiedDate: 0 },
                    };
                    policyRules.push(newRule);
                    inputActions.setField('rules', policyRules);
                    setModals({ addRbacPolicyRule: true });
                };

                const handleSave = () => {
                    let payload: RbacPolicyV2Payload = rbacPolicyInputSchemes.keys.reduce((acc: any, c: any) => {
                        //This creates a new object mapping the keys from the input scheme
                        //to the key/values from the inputState. And send only those values which are present
                        if (inputState[c]) {
                            if (c === 'rules') {
                                acc['rules'] = (rbacPolicyRules as Array<RbacPolicyRuleV2>).map((rule, index) => ({
                                    name: rule.name,
                                    order: index + 1,
                                    userGroups: rule.userGroups,
                                    permission: rule.permission,
                                    maskPolicy: { id: rule.maskPolicy?.id, name: rule.maskPolicy?.name },
                                }));
                            } else if (c === 'defaultMaskPolicy') {
                                acc[c] = {
                                    id: (inputState[c] as DataProtectionItem)?.id,
                                    name: (inputState[c] as DataProtectionItem)?.name,
                                };
                            } else {
                                acc[c] = (inputState[c] as string).trim();
                            }
                        }
                        return acc;
                    }, {});
                    run(
                        rbacPolicyClient.createRbacPolicy(payload).then(() => {
                            inputActions.reset();
                            NotificationStore.push({
                                kind: 'success',
                                title: t('rbac.rbacPolicy.createRbacPolicySuccess', { name: payload.name }),
                                'data-testid': 'rbac-policy-create-success',
                            });
                            history.push('/rbac');
                        })
                    );
                };

                const breadcrumbData = [
                    {
                        label: t('main.rbac'),
                        href: '/rbac',
                    },
                ];

                return (
                    <>
                        <Content className="bg-white">
                            <PageHeader title={t('main.policies')} breadcrumbData={breadcrumbData}></PageHeader>
                            <div className="flex-1 px-10 flex justify-between bg-white">
                                <div className="items-center w-40 py-4 px-4 bg-gray-10 cursor-pointer">
                                    {t('rbac.rbacPolicy.createButtonText')}
                                </div>
                            </div>
                            <div className="overflow-y-auto bg-gray-10" style={{ height: pageHeight }}>
                                <div className="flex flex-wrap my-6 w-140 px-10 items-end">
                                    <div className="flex-1 name-input-container">
                                        <TextInput
                                            id="rbac-policy-name-input"
                                            className="text-input-white"
                                            data-testid="rbac-policy-name-input"
                                            labelText={t('encryption.modeLibrary.rbac.rbacPolicy.policyNameLabel')}
                                            placeholder={t(
                                                'encryption.modeLibrary.rbac.rbacPolicy.policyNamePlaceholder'
                                            )}
                                            invalid={Boolean(inputErrors.name)}
                                            invalidText={inputErrors.name}
                                            value={(inputState.name as string) || ''}
                                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                inputActions.validateField({
                                                    field: 'name',
                                                    skipNull: true,
                                                });
                                            }}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                const val = e.target.value || '';
                                                inputActions.setField('name', val);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-wrap mb-6 w-140 px-10 items-start">
                                    <div className="flex-1">
                                        <Select
                                            id="rbac-policy-data-type-groups"
                                            className="select-white select-menu-white"
                                            labelText={t('globalPolicies.maskingPolicy.maskingPolicyDatatypeLabel')}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                const val = e.target.value || '';
                                                inputActions.setFields({
                                                    datatypeGroup: val as string,
                                                    defaultMaskPolicy: null,
                                                });
                                            }}
                                            defaultValue="placeholder-item">
                                            <SelectItem
                                                disabled
                                                hidden
                                                text={t('main.chooseOption')}
                                                value="placeholder-item"
                                            />
                                            {datatypeGroupItems.map((datatype, i) => {
                                                return <SelectItem key={i} text={datatype.label} value={datatype.id} />;
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="flex flex-wrap mb-6 w-140 px-10 items-start">
                                    <div className="flex-1">
                                        <Select
                                            id="rbac-policy-permission"
                                            className="select-white select-menu-white"
                                            labelText={t('encryption.modeLibrary.rbac.rbacPolicy.defaultPermission')}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                const val = e.target.value || '';
                                                inputActions.setFields({
                                                    defaultPermission: val as string,
                                                    defaultMaskPolicy: null,
                                                });
                                            }}
                                            defaultValue="placeholder-item">
                                            <SelectItem
                                                disabled
                                                hidden
                                                text={t('main.chooseOption')}
                                                value="placeholder-item"
                                            />
                                            {permissionItems.map((permissionItem, i) => {
                                                return (
                                                    <SelectItem
                                                        key={i}
                                                        text={permissionItem.label}
                                                        value={permissionItem.id}
                                                    />
                                                );
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                {inputState.defaultPermission === RbacPolicyDefaultPermission.MASK ? (
                                    <div className="flex flex-wrap mb-6 w-140 px-10 items-start">
                                        <div className="flex-1">
                                            <Select
                                                id="rbac-policy-masking-mode"
                                                className="select-white select-menu-white"
                                                labelText={t('rbac.rbacPolicy.maskPolicy')}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    const val = e.target.value || '';
                                                    const value = MaskingPolicyStore.maskingPolicies.find(
                                                        mode => mode.id === val
                                                    ) as DataProtectionItem;
                                                    inputActions.setField('defaultMaskPolicy', value);
                                                }}
                                                value={
                                                    (inputState.defaultMaskPolicy as DataProtectionItem)?.id ||
                                                    'placeholder-item'
                                                }>
                                                <SelectItem
                                                    disabled
                                                    hidden
                                                    text={t('main.chooseOption')}
                                                    value="placeholder-item"
                                                />
                                                {maskModeItems
                                                    .filter((maskMode: any) => {
                                                        return inputState.datatypeGroup == maskMode.datatypeGroup;
                                                    })
                                                    .map((maskModeItem, i) => {
                                                        return (
                                                            <SelectItem
                                                                key={i}
                                                                text={maskModeItem.label}
                                                                value={maskModeItem.id}
                                                            />
                                                        );
                                                    })}
                                            </Select>
                                        </div>
                                    </div>
                                ) : null}
                                <div className="flex-1 mb-6 px-10 flex justify-between">
                                    <div className="flex-1 flex items-center">
                                        <h3 className="text-xl">{t('rbac.rbacPolicy.rules')}</h3>
                                    </div>
                                </div>
                                <div className="mb-12">
                                    <DataGrid
                                        idPrefix="rbacPolicyRules"
                                        rows={rows}
                                        headers={headers}
                                        showSearch={true}
                                        onAdd={onAdd}
                                        addButtonCaption={t('rbac.rbacPolicy.addRuleButtonText')}></DataGrid>
                                </div>
                                <div className="flex flex-row px-10 gap-x-5 pb-10">
                                    <Button
                                        theme={isValid ? 'primary' : 'disabled'}
                                        className="h-12 w-60"
                                        disabled={!isValid}
                                        onClick={() => {
                                            handleSave();
                                        }}
                                        id="create-rbac-policy-create-btn">
                                        {t('rbac.rbacPolicy.createButtonText')}
                                    </Button>
                                    <Button
                                        kind="secondary"
                                        className="h-12 w-60"
                                        onClick={() => {
                                            history.push('/rbac');
                                        }}
                                        id="create-rbac-policy-cancel-btn">
                                        {t('main.cancel')}
                                    </Button>
                                </div>
                            </div>
                            {modals.addRbacPolicyRule ? (
                                <RbacPolicyRulesModal
                                    open={modals.addRbacPolicyRule}
                                    onClose={() => {
                                        setModals({ addRbacPolicyRule: false });
                                        if (!isValid || (isValid && policyRules.length != rbacPolicyRules?.length)) {
                                            policyRules.splice(policyRules.length - 1, 1);
                                        }
                                        inputActions.setField('rules', policyRules);
                                    }}
                                    inputActions={inputActions}
                                    rule={policyRules[policyRules.length - 1]}
                                    policyRules={policyRules}
                                    permissionItems={permissionItems}
                                    isValid={isValid}
                                    datatypeGroup={inputState.datatypeGroup as string}
                                />
                            ) : null}
                            {modals.deleteRbacPolicyRule ? (
                                <DeleteRbacPolicyRuleModal
                                    open={modals.deleteRbacPolicyRule}
                                    name={deleteRule.name}
                                    onClose={() => {
                                        setModals({ deleteRbacPolicyRule: false });
                                    }}
                                    onSubmit={() => {
                                        RbacPolicyStore.deleteRbacRule(deleteRule.index as number);
                                        policyRules.splice(deleteRule.index, 1);
                                        setModals({ deleteRbacPolicyRule: false });
                                    }}
                                />
                            ) : null}
                        </Content>
                    </>
                );
            }}
        </Observer>
    );
};

export default CreateRbacPolicy;
