import migrationPlanClient from '@baffle/api-client/src/migrationPlanClient';
import { MigrationPlan } from '@baffle/graphql/src/models';
import { makeObservable, action, observable } from 'mobx';
import { RequestOpts } from '../types/StoreTypes';

class MigrationPlanStore {
    loading = false;
    setLoading = (loading: boolean) => {
        this.loading = loading;
    };
    migrationPlans = observable<MigrationPlan>([]);
    setMigrationPlan = (migrationPlan: MigrationPlan) => {
        const plans = this.migrationPlans.slice();
        for (let i = 0; i < plans.length; i++) {
            const plan = plans[i];
            if (plan.applicationId === migrationPlan.applicationId) {
                plans[i] = migrationPlan;
                this.migrationPlans.replace(plans);
                return;
            }
        }
        plans.push(migrationPlan);
        this.migrationPlans.replace(plans);
    };

    constructor() {
        makeObservable(this, {
            loading: observable,
            setLoading: action,
            setMigrationPlan: action,
        });
    }

    async read(id: string, opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first' && this.migrationPlans.findIndex(mp => mp.applicationId === id) >= 0) {
            return;
        }
        const doFinally = () => {
            this.setLoading(false);
        };
        this.setLoading(true);
        try {
            const mp = await migrationPlanClient.get(id);
            this.setMigrationPlan(mp);
        } catch (error) {
            throw error;
        } finally {
            doFinally();
        }
    }
    get(id: string) {
        return this.migrationPlans.find(m => m.applicationId === id);
    }
}

export default MigrationPlanStore;
