//Why KeyDetailsStore?
//KeyIDs are created locally and then persisted on the backend.
//So the FE needs a way to combine and manage a list of both remote and local KeyDetails

import { makeObservable, observable, action, runInAction } from 'mobx';
import { KeyDetails } from '@baffle/graphql/src/models';
import keystoreClient from '@baffle/api-client/src/keystoreClient';
import { RequestOpts } from '../types/StoreTypes';

interface KeyDetailsLookup {
    [key: string]: KeyDetails[];
}

class KeyDetailsStore {
    keyDetailsLookup: KeyDetailsLookup = {}; //Used to list all keys from server
    //Note: maintaining a list of keys created locally and trying to merge and sync with the server
    //creates UX issues, where since the state is ephemeral, the user will have to start over on the UI in some cases where the UI gets refreshed
    keyDetailsTemp: KeyDetailsLookup = {}; //Used to create new keys locally during encryption flows and merge with server
    loading: boolean = false;

    constructor() {
        makeObservable(this, {
            keyDetailsLookup: observable,
            keyDetailsTemp: observable,
            loading: observable,
            incrementKeyDetailsList: action,
            read: action,
            setLoading: action,
        });
    }

    setLoading(loading: boolean) {
        this.loading = loading;
    }

    async read(id: string, opts: RequestOpts = {}) {
        if (opts.networkPolicy === 'cache-first' && this.keyDetailsLookup[id] !== null) {
            return;
        }
        const doFinally = () => {
            this.setLoading(false);
        };
        try {
            const keyDetails = (await keystoreClient.readKeyDetails(id)) as KeyDetails[];
            //todo: move this to a setKeyDetails function and mark as an action
            runInAction(() => {
                this.keyDetailsLookup[id] = keyDetails;
                //always use the cache of tmp key details for encryption so newly added keys do not get blown away when the page changes
                if (!this.keyDetailsTemp[id]) {
                    this.keyDetailsTemp[id] = this.compileEncryptionKeyDetailsList(keyDetails);
                }
            });
        } catch (error) {
            throw error;
        } finally {
            doFinally();
        }
    }

    /**
     *
     * @param keystoreID - used to scope and operate on keyDetailsList
     * @param kdl - an array of KeyDetails
     * compileEncryptionKeyDetailsList takes an array of KeyDetails and creates a new list to present the user
     * with available keyIDs for encrypting columns
     *
     */
    compileEncryptionKeyDetailsList(kdl: KeyDetails[]) {
        const list = kdl.filter((k: KeyDetails) => k.keyId !== -2 && k.keyId > 1).slice();
        //set the default keyId to 2
        if (list.findIndex(k => k.keyId === 2) === -1) {
            const defaultKey: KeyDetails = {
                keyId: 2,
                //properties get ignored locally, only need the keyId to send to the server
                keyUUID: '',
                keyType: '',
                creationDate: new Date().getTime(),
            };
            list.unshift(defaultKey);
        }
        return list;
    }

    incrementKeyDetailsList(keystoreID: string) {
        const prevList = this.get(keystoreID, { forEncryption: true });
        const newKey = this.nextKey(keystoreID, { forEncryption: true });
        const next = prevList.slice();
        next.push(newKey);
        this.keyDetailsTemp[keystoreID] = next;
        return newKey;
    }

    nextKey(keystoreID: string, opts: GetKeylistOpts = {}) {
        const prevList = this.get(keystoreID, opts);
        const prevKeys = prevList.length > 0 ? prevList.map((k: KeyDetails) => k.keyId) : [2];
        let nextKey = Math.max(...prevKeys) + 1;

        if (nextKey < 2) {
            nextKey = 2;
        }

        const newKey: KeyDetails = {
            keyId: nextKey,
            //properties get ignored locally, only need the keyId to send to the server
            keyUUID: '',
            keyType: '',
            creationDate: new Date().getTime(),
        };
        return newKey;
    }

    /**
     *
     * @param keystoreID
     * @param opts
     * ** @param forRotation - is to show keys only that the user can rotate
     * ** @param forEncryption - is to show keys only that the user can use to encrypt columns
     */
    get(keystoreID: string, { forRotation = false, forEncryption = false }: GetKeylistOpts = {}) {
        if (forEncryption) {
            return this.keyDetailsTemp[keystoreID];
        }

        const list = this.keyDetailsLookup[keystoreID] ?? [];
        if (forRotation) {
            return list.filter((k: KeyDetails) => k.keyId !== 1 && k.keyId > -1).slice();
        }
        return list as KeyDetails[];
    }
}

interface GetKeylistOpts {
    forRotation?: boolean;
    forEncryption?: boolean;
}

export default KeyDetailsStore;
