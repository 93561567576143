import React, { useState, useEffect } from 'react';
import { NavLink, useRouteMatch, useLocation } from 'react-router-dom';
import { AriaLabelPropType } from '../componentTypes';
import cx from 'classnames';

type ToFunc = () => string;
interface NavIconProps extends AriaLabelPropType {
    color?: string;
    activeColor?: string;
    to: string | ToFunc;
    hoverColor?: string;
    children: any;
}
const NavLinkIcon = ({
    color = '#c6c6c6',
    activeColor = '#78a9ff',
    hoverColor = '#fff',
    to,
    children,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    ...rest
}: NavIconProps) => {
    const [c, setColor] = useState(color);
    const [hover, setHover] = useState(false);
    const location = useLocation();
    //this is to account for the landing page
    const match = useRouteMatch(to as string) || (location.pathname === '/' && to === '/applications');
    useEffect(() => {
        if (match) {
            setColor(activeColor);
        }
        if (!hover && !match) {
            setColor(color);
        }
        if (hover && !match) {
            setColor(hoverColor);
        }
    }, [match, hover, location]);

    const isActive = (matchFromNavLink: any, location: any) => {
        if (location.pathname === '/' && to === '/applications') {
            return true;
        }

        return Boolean(matchFromNavLink);
    };

    const childrenWithProps = React.Children.map(children, (child: React.ReactElement) =>
        React.cloneElement(child, {
            color: c,
            className: cx(child.props.className ?? '', 'focus:text-white', {
                'text-blue-40': match,
                'text-gray-400': !match && !hover,
                'text-white': !match && hover,
            }),
        })
    );
    return (
        <NavLink
            to={to}
            onFocus={() => {
                setHover(true);
            }}
            onBlur={() => {
                setHover(false);
            }}
            onMouseOver={() => {
                setHover(true);
            }}
            onMouseOut={() => {
                setHover(false);
            }}
            isActive={isActive}
            activeClassName="active"
            className="group flex items-center px-2 py-2 rounded-md"
            aria-label={ariaLabel}
            aria-labelledby={ariaLabelledBy}
            {...rest}>
            {childrenWithProps}
        </NavLink>
    );
};

export default NavLinkIcon;
