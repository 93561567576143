import client from '.';
import saveFile from '@baffle/utilities/src/saveFile';
import { MAX_PAGE_SIZE } from '@baffle/graphql';

export async function create(body: any) {
    return client('/api/v2/resources/certstore/ca-certs', { body, useFormWithJson: true, method: 'POST' });
}

export async function read(id: string) {
    return client(`/api/v2/resources/certstore/ca-certs/${id}`);
}

export async function list() {
    return client(`/api/v2/resources/certstore/ca-certs?page=0&size=${MAX_PAGE_SIZE}`);
}

export async function remove(id: string) {
    return client(`/api/v2/resources/certstore/ca-certs/${id}`, { method: 'DELETE' });
}

export async function update(id: string, body: any) {
    return client(`/api/v2/resources/certstore/ca-certs/${id}`, { body, method: 'PUT' });
}

export async function downloadCertificate(link: string, fileName: string) {
    try {
        const blob = (await client(link, {
            responseContentType: 'application/octet-stream',
        })) as Blob;
        await saveFile(blob, `${fileName}`);
    } catch (error) {
        throw error;
    }
}

export default {
    create,
    read,
    list,
    remove,
    update,
    downloadCertificate,
};
