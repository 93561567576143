import React, { useState, useEffect, useMemo } from 'react';
import { t } from '@baffle/translate';
import { useInputState } from '@baffle/utilities/src/inputHooks';
import { CreateModal, CreateModalBody, CreateModalHeader } from '@baffle/components/src/CreateModal/CreateModal';
import { TextInput, TextArea, Checkbox } from 'carbon-components-react';
import { UserGroupStore } from '@baffle/manager/src/stores';
import createRbacUsrGrpInputScheme from './createRbacUsrGrpInputScheme';
import { RbacUsrGrpV2 } from '@baffle/graphql/src/models';
import useAwait from '@baffle/api-client/src/useAwait';
import { NotificationStore } from '../../../stores/NotificationStore';

interface AddUserGroupsProps {
    open: boolean;
    onClose?: () => void;
    onSuccess?: (name: string) => void;
}

const AddUserGroup = ({ open, onClose, onSuccess }: AddUserGroupsProps) => {
    const [openModal, setOpen] = useState(open);
    const { run, error, reset } = useAwait();
    const existingUsrGrp = UserGroupStore.rbacUserGroup;
    const rbacUsrGrpInputSchemes = useMemo(
        () => createRbacUsrGrpInputScheme(UserGroupStore.rbacUserGroups, existingUsrGrp?.id),
        [UserGroupStore.rbacUserGroups, existingUsrGrp]
    );

    const [globalFlag, setGlobalFlag] = useState(existingUsrGrp?.global ? 'GLOBAL' : 'NON_GLOBAL');

    //@ts-ignore
    const { inputState, inputErrors, inputActions } = useInputState(rbacUsrGrpInputSchemes[globalFlag]);

    useEffect(() => {
        if (error) {
            NotificationStore.push({
                'data-testid': 'user-group-add-failure',
                kind: 'error',
                title: t('globalPolicies.rbacUserGroups.addUserGroupError'),
                description: error.message ?? '',
            });
        }
    }, [error]);

    const handleClose = () => {
        if (!openModal) {
            return;
        }
        setOpen(false);
        reset();
        inputActions.reset();
        setTimeout(() => {
            if (onClose) {
                onClose();
            }
        }, 200);
    };

    //Sync state from props
    useEffect(() => {
        setOpen(open);
    }, [open]);

    const isValid = inputActions.validateAll(true);

    const handleSave = () => {
        let payload: RbacUsrGrpV2 = rbacUsrGrpInputSchemes[globalFlag].keys.reduce((acc: any, c: any) => {
            if (Boolean(inputState[c])) {
                switch (c) {
                    case 'global':
                        acc[c] = inputState[c];
                        break;
                    case 'users':
                    case 'subnets':
                        acc[c] = (inputState[c] as string).split(',').map(str => str.trim());
                        break;
                    default:
                        acc[c] = (inputState[c] as string).trim();
                        break;
                }
            }
            return acc;
        }, {});
        // If global is true, then set users to null
        if (payload.global) {
            payload.users = null;
        }
        run(
            UserGroupStore.createRbacUserGroup(payload).then(() => {
                handleClose();
                if (onSuccess) {
                    onSuccess(payload.name as string);
                }
            })
        );
    };

    return (
        <CreateModal
            open={openModal}
            onClose={handleClose}
            isValid={isValid}
            modalId="add-user-group-modal"
            primaryButtonText={t('globalPolicies.rbacUserGroups.createRbacUserGroup')}
            primaryButtonTheme="primary"
            primaryButton={() => handleSave()}>
            <CreateModalHeader titleText={t('globalPolicies.rbacUserGroups.addUserGroupModalHealine')} />
            <CreateModalBody>
                <div className="text-sm font-normal leading-5 mb-4 mt-2 mr-6">
                    {t('globalPolicies.rbacUserGroups.userGroupModalMessage')}
                </div>
                <div className="flex flex-wrap mb-6 items-end">
                    <div className="flex-1 text-sm name-input-container">
                        <TextInput
                            id="usr-grp-name-input"
                            name="usr-grp-name-input"
                            data-testid="usr-grp-name-input"
                            labelText={t('globalPolicies.rbacUserGroups.userGroupNameLabel')}
                            placeholder={t('globalPolicies.rbacUserGroups.userGroupNamePlaceholder')}
                            invalid={Boolean(inputErrors.name)}
                            invalidText={inputErrors.name}
                            value={(inputState.name as string) || ''}
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                inputActions.validateField({
                                    field: 'name',
                                    skipNull: true,
                                });
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                let val = e.target.value || '';
                                inputActions.setField('name', val);
                            }}
                        />
                    </div>
                </div>
                <div className="flex flex-wrap mb-6">
                    <div className="flex-1 relative">
                        <Checkbox
                            id="global-enable-checkbox"
                            labelText={t('globalPolicies.rbacUserGroups.userGroupGlobalLabel')}
                            checked={inputState.global ? true : false}
                            onChange={(event: any, { checked, id }: { checked: boolean; id: any }) => {
                                setGlobalFlag(checked ? 'GLOBAL' : 'NON_GLOBAL');
                                inputActions.setField('global', checked);
                            }}
                            className="text-xs font-medium text-gray-600"
                        />
                    </div>
                </div>
                {!inputState.global ? (
                    <div className="flex flex-wrap mb-6">
                        <div className="flex-1 relative">
                            <TextArea
                                id="users-input"
                                name="users-input"
                                data-testid="users-input"
                                labelText={t('globalPolicies.rbacUserGroups.usersLabel')}
                                placeholder={t('globalPolicies.rbacUserGroups.usersPlaceholder')}
                                invalid={Boolean(inputErrors.users)}
                                invalidText={inputErrors.users}
                                value={(inputState.users as string) || ''}
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    inputActions.validateField({
                                        field: 'users',
                                        skipNull: true,
                                    });
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const val = e.target.value || '';
                                    inputActions.setField('users', val);
                                }}
                            />
                        </div>
                    </div>
                ) : null}
                <div className="flex flex-wrap mb-6">
                    <div className="flex-1 relative">
                        <TextArea
                            id="subnets-input"
                            name="subnets-input"
                            data-testid="subnets-input"
                            labelText={t('globalPolicies.rbacUserGroups.allowedSubnetsLabel')}
                            placeholder={t('globalPolicies.rbacUserGroups.allowedSubnetsPlaceholder')}
                            invalid={Boolean(inputErrors.subnets)}
                            invalidText={inputErrors.subnets}
                            value={(inputState.subnets as string) || ''}
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                inputActions.validateField({
                                    field: 'subnets',
                                    skipNull: true,
                                });
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const val = e.target.value || '';
                                inputActions.setField('subnets', val);
                            }}
                        />
                    </div>
                </div>
            </CreateModalBody>
        </CreateModal>
    );
};

export default AddUserGroup;
