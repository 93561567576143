export type RoleType =
    | 'ROLE_DOMAIN_ADMIN'
    | 'ROLE_APPLICATION_ADMIN'
    | 'ROLE_KEY_STORE_ADMIN'
    | 'ROLE_DATABASE_ADMIN'
    | 'ROLE_SECURITY_ADMIN'
    | 'ROLE_DOMAIN_AUDITOR'
    | 'ROLE_SYSTEM_ADMIN'
    | 'ROLE_AUDITOR';

export enum RoleEnum {
    ROLE_DOMAIN_ADMIN = 'Domain Admin',
    ROLE_APPLICATION_ADMIN = 'Application Administrator',
    ROLE_KEY_STORE_ADMIN = 'Key Store Administrator',
    ROLE_DATABASE_ADMIN = 'Database Administrator',
    ROLE_SECURITY_ADMIN = 'Security Administrator',
    ROLE_DOMAIN_AUDITOR = 'Domain Auditor',
    ROLE_SYSTEM_ADMIN = 'System Administrator',
    ROLE_AUDITOR = 'Auditor',
}

export interface Role {
    id: string;
    name: RoleType;
}

export interface CurrentUser {
    id: string;
    title: string;
    firstName: string;
    lastName: string;
    suffix: string;
    email: string;
    phone: string;
    password?: string;
    countryCode: string;
    roles: Role[];
    active: boolean;
    createTime: number;
}

export interface User {
    id: string;
    title: string;
    firstName: string;
    lastName: string;
    suffix: string;
    email: string;
    phone: string;
    password?: string;
    countryCode: string;
    roles: Role[];
    active: boolean;
    createTime: number;
}

export interface InitialRegister {
    confirmPassword: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    password: string;
    countryCode: string;
}

export interface ActivationLinkResponse {
    activationLink: string;
}
